import { FinancialConfirmationSectionEnum, useFinancialConfirm, useFriendlyURL } from '@harmoney/hooks';
import { useGetUserProfileQuery, useSaveExpensesMutation } from '@harmoney/redux';
import {
  AmountFrequency,
  ArrowCircleRightIcon,
  Button,
  Card,
  Divider,
  Form,
  Label,
  ToggleGroup,
  useForm,
} from '@harmoney/ui-design-system';
import { frequencyOptions, stringToBool, toggleYesNoOptions } from '@harmoney/ui-utils';
import { Icon } from '@iconify/react';
import { RelationshipStatusEnum } from '@prisma/client';
import classNames from 'classnames';

import { CommonProps, ExtraAdminProps } from '../../common-props';

import { defaultValues, FormSchema, getFormSchema } from './form-config';
import * as utils from './utils';

interface LivingExpenseV3Props extends CommonProps, ExtraAdminProps {}

const IconWithDescription = ({
  icon,
  description,
  status,
  className = '',
}: {
  icon: string;
  description: string;
  status: 'success' | 'error';
  className?: string;
}) => {
  return (
    <div className={classNames(className || 'flex items-center')}>
      <div
        className={classNames(
          'h-8 w-8 rounded-full flex items-center justify-center mt-2',
          status === 'success' && 'bg-tertiary-lighter-3 border-tertiary-lighter-3',
          status === 'error' && 'bg-error-fill border-error-fill'
        )}
      >
        <Icon
          icon={icon}
          width={20}
          height={20}
          className={classNames(status === 'success' && 'text-success', status === 'error' && 'text-error')}
        />
      </div>
      <small className="mt-2 ml-2 max-w-[100px] sm:max-w-[120px]">{description}</small>
    </div>
  );
};

export function LivingExpenseV3({
  taskId,
  completeTaskWithData,
  isCustomerFacing = true,
  taskFriendlyURL,
}: LivingExpenseV3Props) {
  useFriendlyURL(taskFriendlyURL);

  const { confirmSection } = useFinancialConfirm();
  const { data: userProfile } = useGetUserProfileQuery();

  const [saveExpenses] = useSaveExpensesMutation();

  const form = useForm({
    mode: 'onTouched',
    schema: getFormSchema(userProfile?.relationshipStatus),
    defaultValues,
  });

  const {
    register,
    watch,
    resetField,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;

  const watchFormValues = watch();

  const handleTotalExpensesSharedChange = () => {
    resetField('totalEssentialExpenses.withSharedExpense.declaredAmount', { defaultValue: null });
    resetField('totalEssentialExpenses.withSharedExpense.frequency', { defaultValue: '' });
  };

  const handleOtherEssentialExpensesChange = () => {
    resetField('otherEssentialExpenses.declaredTotalAmount', { defaultValue: null });
    resetField('otherEssentialExpenses.frequencyForTotalAmount', { defaultValue: '' });
    resetField('otherEssentialExpenses.isExpenseShared', { defaultValue: null });
  };

  const handleOtherEssentialExpensesSharedChange = () => {
    resetField('otherEssentialExpenses.withSharedExpense.declaredAmount', { defaultValue: null });
    resetField('otherEssentialExpenses.withSharedExpense.frequency', { defaultValue: '' });
  };

  const handleSubmit = async (data: FormSchema) => {
    const totalEssentialExpenseData = utils.buildTotalEssentialExpenseData(data);
    const expensesPayloadArray = [totalEssentialExpenseData];

    const hasOtherEssentialExpenses = stringToBool(data.otherEssentialExpenses.hasOtherEssentialExpenses);
    const otherRegularExpenseData = utils.buildOtherRegularExpenseData(data, hasOtherEssentialExpenses);

    expensesPayloadArray.push(otherRegularExpenseData);

    if (isCustomerFacing) {
      await saveExpenses({
        taskId,
        expenses: expensesPayloadArray,
      });
      await completeTaskWithData({ taskId });
      confirmSection(FinancialConfirmationSectionEnum.EssentialExpenses);
    }
  };

  return (
    <>
      {isCustomerFacing && (
        <h1>
          What are your <span className="text-primary">essential expenses?</span>
        </h1>
      )}

      <Form form={form} onSubmit={handleSubmit}>
        <Card className="!p-0">
          <div className="p-4">
            <Label className="mb-2">
              How much are your <span className="font-medium">total essential expenses</span>?
            </Label>

            <AmountFrequency
              register={register}
              inputKey="totalEssentialExpenses.declaredTotalAmount"
              selectKey="totalEssentialExpenses.frequencyForTotalAmount"
              name="total-essential-expenses"
              options={frequencyOptions}
            />
            <div className="flex flex-col mt-4">
              <div className="flex flex-col">
                <span className="font-medium text-sm">Include these:</span>
                <small className="text-grey-4">Costs needed for your basic living.</small>
              </div>
              <div className="grid grid-cols-2 gap-2 text-sm">
                <div className="flex flex-col">
                  <IconWithDescription
                    icon="material-symbols:receipt-long-outline"
                    status="success"
                    description="Utility bills"
                  />
                  <IconWithDescription
                    icon="material-symbols:directions-bus-outline"
                    status="success"
                    description="Transport"
                  />
                </div>
                <div className="flex flex-col">
                  <IconWithDescription icon="ic:outline-local-grocery-store" status="success" description="Groceries" />
                  <IconWithDescription
                    icon="material-symbols:pending-outline"
                    status="success"
                    description="Other essentials like insurance, medical etc."
                    className="flex items-start"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <span className="font-medium text-sm">Don&rsquo;t include</span>
              <div className="grid grid-cols-2 gap-2">
                <div className="flex flex-col">
                  <IconWithDescription icon="mdi:house-off" status="error" description="Rent/mortgage" />
                  <IconWithDescription icon="mdi:money-off" status="error" description="Loan repayments" />
                </div>
                <div className="flex flex-col">
                  <IconWithDescription
                    icon="material-symbols:credit-card-off"
                    status="error"
                    description="Credit card"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            {(userProfile?.relationshipStatus === RelationshipStatusEnum.MARRIED ||
              userProfile?.relationshipStatus === RelationshipStatusEnum.DE_FACTO) && (
              <div>
                <Divider className="text-grey-2" />
                <ToggleGroup
                  {...register('totalEssentialExpenses.isExpenseShared', {
                    onChange: handleTotalExpensesSharedChange,
                  })}
                  options={toggleYesNoOptions}
                  label="Do you share these expenses with your partner/spouse?"
                  className="p-4"
                />
              </div>
            )}
            {stringToBool(watchFormValues.totalEssentialExpenses.isExpenseShared) && (
              <div className="px-4 pt-0 pb-4">
                <Label className="mb-2">
                  What is <span className="font-medium">your</span> share?
                </Label>
                <AmountFrequency
                  register={register}
                  inputKey="totalEssentialExpenses.withSharedExpense.declaredAmount"
                  selectKey="totalEssentialExpenses.withSharedExpense.frequency"
                  name="spouse-shared-amount"
                  options={frequencyOptions}
                />
              </div>
            )}
          </div>
        </Card>
        <Card className="!p-0">
          <div className="p-4">
            <Label className="mb-2">
              <span className="inline-block">
                Do you have any <span className="font-medium"> other regular expenses</span> that you consider
                essential?
              </span>
            </Label>
            <ToggleGroup
              {...register('otherEssentialExpenses.hasOtherEssentialExpenses', {
                onChange: handleOtherEssentialExpensesChange,
              })}
              options={toggleYesNoOptions}
            />
            <div className="flex flex-col mt-4">
              <div className="flex flex-col">
                <span className="font-medium text-sm">Include these:</span>
                <small className="text-grey-4">Regular recurring costs, that are important to you.</small>
              </div>
              <div className="grid grid-cols-2 gap-2 text-sm">
                <div className="flex flex-col">
                  <IconWithDescription
                    icon="material-symbols:school-outline"
                    status="success"
                    description="Private school"
                  />
                  <IconWithDescription
                    icon="material-symbols:payments-outline"
                    status="success"
                    description="Charitable donations"
                  />
                </div>
                <div className="flex flex-col">
                  <IconWithDescription
                    icon="material-symbols:exercise-outline"
                    status="success"
                    description="Gym subscription"
                  />
                  <IconWithDescription
                    icon="material-symbols:pending-outline"
                    status="success"
                    description="Other regular expenses"
                  />
                </div>
              </div>
            </div>
            {watchFormValues.otherEssentialExpenses.hasOtherEssentialExpenses &&
              stringToBool(watchFormValues.otherEssentialExpenses.hasOtherEssentialExpenses) && (
                <div className="mt-4">
                  <AmountFrequency
                    register={register}
                    inputKey="otherEssentialExpenses.declaredTotalAmount"
                    selectKey="otherEssentialExpenses.frequencyForTotalAmount"
                    name="other-essential-expenses"
                    options={frequencyOptions}
                    label="How much are they?"
                  />
                </div>
              )}
          </div>
          <div>
            {(userProfile?.relationshipStatus === RelationshipStatusEnum.MARRIED ||
              userProfile?.relationshipStatus === RelationshipStatusEnum.DE_FACTO) &&
              stringToBool(watchFormValues.otherEssentialExpenses.hasOtherEssentialExpenses) && (
                <>
                  <Divider className="text-grey-2 mt-4 w-full" />
                  <ToggleGroup
                    {...register('otherEssentialExpenses.isExpenseShared', {
                      onChange: handleOtherEssentialExpensesSharedChange,
                    })}
                    options={toggleYesNoOptions}
                    label="Do you share these expenses with your partner/spouse?"
                    className="p-4"
                  />
                </>
              )}
            {stringToBool(watchFormValues.otherEssentialExpenses.isExpenseShared) && (
              <div className="px-4 pb-4">
                <Label className="mb-2">
                  What is <span className="font-medium">your</span> share?
                </Label>
                <AmountFrequency
                  register={register}
                  inputKey="otherEssentialExpenses.withSharedExpense.declaredAmount"
                  selectKey="otherEssentialExpenses.withSharedExpense.frequency"
                  name="other-expenses-shared-amount"
                  options={frequencyOptions}
                />
              </div>
            )}
          </div>
        </Card>
        <Button
          type="submit"
          variant="primary"
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          isLoading={isSubmitting || isSubmitSuccessful}
        >
          Continue
        </Button>
      </Form>
    </>
  );
}

import React, { useEffect, useMemo, useState } from 'react';
import { ProcessId } from '@harmoney/api-interfaces';
import { useGetSimulationStatusQuery, useSimulateLoanApplicationsMutation } from '@harmoney/redux';
import { Button } from '@harmoney/ui-design-system';
import { itemsPerPageOptions } from '@harmoney/ui-utils';
import { Icon } from '@iconify/react';
import { BPMNSimulationStatusEnum } from '@prisma/client';
import { rankItem } from '@tanstack/match-sorter-utils';
import {
  ColumnDef,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  OnChangeFn,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';

import GlobalFilter from './GlobalFilter';

interface DataTableProps {
  title: string | React.ReactNode;
  data: any;
  columns: ColumnDef<any>[];
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  total: number;
  setPagination: OnChangeFn<PaginationState>;
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank,
  });
  return itemRank.passed;
};

export const DataTableForSimulation = ({
  title,
  columns,
  data,
  pageIndex,
  pageSize,
  total,
  setPagination,
  globalFilter,
  setGlobalFilter,
  pageCount,
}: DataTableProps) => {
  const defaultData = useMemo(() => [], []);

  const [stopPolling, setStopPolling] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [enabledSimulation, setEnabledSimulation] = useState(false);
  const [isSimulating, setIsSimulating] = useState(false);
  const [batchId, setBatchId] = useState<string | null>(null);

  const [simulateLoanApplications] = useSimulateLoanApplicationsMutation();
  const { data: simulationStatus } = useGetSimulationStatusQuery(batchId, {
    skip: !batchId || stopPolling,
    pollingInterval: 5000,
  });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    columns: columns,
    data: data ?? defaultData,
    pageCount,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      globalFilter,
      pagination,
    },
    onSortingChange: setSorting,
    manualPagination: true,
    manualFiltering: true,
    globalFilterFn: fuzzyFilter,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedRowModel: getFacetedRowModel(),
  });

  useEffect(() => {
    const timeoutId = setTimeout(
      () => {
        setStopPolling(true);
      },
      60 * 3 * 1000
    );

    return () => clearTimeout(timeoutId);
  }, [batchId]);

  useEffect(() => {
    const rows = table.getSelectedRowModel().rows;
    if (rows.length > 0) {
      setEnabledSimulation(true);
    } else {
      setEnabledSimulation(false);
    }
  }, [table, table.getSelectedRowModel()]);

  useEffect(() => {
    if (!simulationStatus) return;

    if (simulationStatus[BPMNSimulationStatusEnum.error] > 0) {
      setIsSimulating(true);
    }
    if (simulationStatus[BPMNSimulationStatusEnum.in_progress] > 0) {
      setIsSimulating(true);
    }
    if (simulationStatus[BPMNSimulationStatusEnum.success] > 0) {
      setIsSimulating(false);
    }
  }, [simulationStatus]);

  const handleSimulation = async () => {
    setIsSimulating(true);

    const rows = table.getSelectedRowModel().rows;
    const simulationInput = rows.map((row) => ({
      loanApplicationId: row.original.id as string,
      variables: {},
    }));

    const batchId = dayjs().format('YYYYMMDDHHmmss');

    await simulateLoanApplications({
      loanApplications: simulationInput,
      bpmnProcessId: ProcessId.ORIGINATION,
      batchId,
    });

    setBatchId(batchId);
  };

  return (
    <>
      <div className="mb-2 flex flex-row items-center justify-between gap-4">
        <h1 className="mb-0">{title}</h1>
        <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} totalItems={total} />
      </div>
      <div className="m-1 overflow-x-auto">
        <table className="mb-4 w-full border-collapse bg-white text-sm rounded-lg shadow-drop">
          <thead className="bg-grey-1 border-separate text-grey-5">
            {table?.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b border-grey-2">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="p-2 text-left first-of-type:rounded-tl-lg last-of-type:rounded-tr-lg"
                  >
                    <div
                      {...{
                        className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <Icon icon="mi:sort" />,
                        desc: <Icon icon="mi:sort" />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white">
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id} className="border-b border-grey-2">
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className="p-2">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="text-right mb-4">
        <Button
          variant="outline-secondary"
          size="medium"
          className="my-4 !min-w-fit"
          onClick={handleSimulation}
          disabled={!enabledSimulation}
          isLoading={isSimulating}
        >
          {isSimulating ? 'Simulating' : 'Start simulation'}
        </Button>

        <div>
          You have selected {table.getSelectedRowModel()?.rows?.length} loan applications
          <ul>
            {simulationStatus &&
              Object.entries(simulationStatus).map(([key, value]) => (
                <li key={key}>
                  {key}: {value}
                </li>
              ))}
          </ul>
        </div>
      </div>

      <div className="flex flex-col justify-between gap-4 md:flex-row md:items-center">
        <div className="flex flex-row gap-4">
          <Button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} size="small">
            First Page
          </Button>
          <Button
            onClick={() => {
              table.previousPage();
            }}
            disabled={!table.getCanPreviousPage()}
            size="small"
          >
            Previous
          </Button>
          <Button
            onClick={() => {
              table.nextPage();
            }}
            disabled={!table.getCanNextPage()}
            size="small"
          >
            Next
          </Button>
          <Button
            onClick={() => {
              table.setPageIndex(table.getPageCount() - 1);
            }}
            disabled={!table.getCanNextPage()}
            size="small"
          >
            Last Page
          </Button>
        </div>
        <div className="flex flex-row gap-4">
          <span>
            {`Page `}
            <strong>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </strong>
          </span>
          <span>
            {`| Go to page: `}
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className="w-6 rounded-md border border-gray-300 text-center"
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {itemsPerPageOptions.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

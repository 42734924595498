import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { TimeZoneEnum } from './date';

dayjs.extend(utc);
dayjs.extend(timezone);

export const dayjsSydney = (date?: string | Date) => dayjs.tz(date, TimeZoneEnum.AUSTRALIA_SYDNEY);

export const dayjsUTCToSydney = (date?: string | Date) => dayjs.utc(date).tz(TimeZoneEnum.AUSTRALIA_SYDNEY);

import { CollapsibleHeader, Label } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';
import { AssetAndLiability, NetworthSource } from '@prisma/client';

export function HarmoneyDebtCard({
  isDebtCon,
  liabilities,
}: {
  isDebtCon: boolean;
  liabilities: (Partial<AssetAndLiability> & {
    networthSource: Partial<NetworthSource>;
  })[];
}) {
  return (
    <CollapsibleHeader title={'Harmoney loans'} withIconify="hmy:harmoney" open={true}>
      {isDebtCon && <span className="inline-block text-sm text-grey-4 pl-4 pb-4">Status of your loans today.</span>}
      {liabilities.map((liability, index) => (
        <div className="border-grey-3 border-1 mx-4 mb-3 rounded-lg	border border-inherit p-6 pb-0" key={liability.id}>
          <div className="flex flex-col gap-y-2" key={`hmy_${liability.networthSource.code}_${index}`}>
            <div className="mb-2 flex flex-col gap-y-1">
              <span className="heading font-medium">{liability.provider}</span>
              <span className="text-sm text-grey-4">{liability.otherProvider}</span>
            </div>
            <div className="flex items-center justify-between">
              <Label>Repayment</Label>
              <span className="font-medium">
                {formatCurrency(liability.repaymentAmount) + '/' + liability.frequency}
              </span>
            </div>
            <div className="flex items-center justify-between">
              <Label>Outstanding balance</Label>
              <span className="font-medium">{formatCurrency(liability.outstandingBalance)}</span>
            </div>
            <div className="flex items-center justify-between pb-4">
              <Label>Interest rate</Label>
              <span className="font-medium">{liability.interestRate ? liability.interestRate + '%' : 'N/A'}</span>
            </div>
          </div>
        </div>
      ))}
    </CollapsibleHeader>
  );
}

import { errors } from '@harmoney/ui-utils';
import { AgreementRangeOptionsEnum, COAuditDecisionEnum, MoreLessEnum } from '@prisma/client';
import { z } from 'zod';

const minOneOption = 'You must select at least 1 option';

const rangeType = z.nativeEnum(AgreementRangeOptionsEnum, {
  required_error: errors.defaultRequiredField,
  invalid_type_error: minOneOption,
});

export const creditAuditFormSchema = z.object({
  requirementsAndObjectives: rangeType,
  requirementsAndObjectivesTags: z.array(z.string()),
  eligibilityIdentityAndFraudMitigation: rangeType,
  eligibilityIdentityAndFraudMitigationTags: z.array(z.string()),
  creditRisk: rangeType,
  creditRiskTags: z.array(z.string()),
  affordability: rangeType,
  affordabilityTags: z.array(z.string()),
  responsibleLending: rangeType,
  responsibleLendingTags: z.array(z.string()),
  additionalEnquiries: z.boolean(),
  additionalEnquiriesTags: z.array(z.string()),
  additionalEnquiriesNotes: z.string().optional(),
  coAuditDecision: z.nativeEnum(COAuditDecisionEnum, {
    required_error: errors.defaultRequiredField,
  }),
  decisionBasis: z
    .array(z.string(), {
      invalid_type_error: minOneOption,
    })
    .min(1, minOneOption),
  mightHaveApprovedIf: z.array(z.string()).optional(),
  wouldHaveApprovedFor: z.nativeEnum(MoreLessEnum, {
    required_error: errors.defaultRequiredField,
  }),
  maxOffer: z.number(),
  notes: z.string().optional(),
  newTagsSuggestions: z.string().optional(),
  tagsToBeRemoved: z.array(z.string()).optional(),
});

export type CreditAuditFormValues = z.infer<typeof creditAuditFormSchema>;

import {
  branchCountryMapper,
  branchEmojiMapper,
  branchMapper,
  preQualifiedQuoteEmojiMapper,
  preQualifiedQuoteLabelMapper,
} from '@harmoney/api-interfaces';
import { UncontrolledCheckbox } from '@harmoney/ui-design-system';
import { BranchEnum, PreQualifiedQuoteStatusEnum } from '@prisma/client';

type FilterGroupProps = {
  name: string;
  items: {
    label: string;
    value: string;
  }[];
};

const FilterGroup = ({ name, items }: FilterGroupProps) => {
  return (
    <div className="mb-4">
      <span className="text-base font-medium mb-2 block">{name}</span>
      {items.map((item) => (
        <div key={item.value}>
          <UncontrolledCheckbox
            name={item.value}
            label={item.label}
            checked={false}
            onCheckedChange={() => console.log()}
            className="text-sm mb-1"
            checkIconSize="tiny"
          />
        </div>
      ))}
    </div>
  );
};

export const PreQualifiedQuoteFilters = () => {
  return (
    <div className="p-6 border-r border-r-grey-2 w-[250px] min-h-dvh">
      <FilterGroup
        name="Branch"
        items={Object.values(BranchEnum).map((branch) => ({
          label: `${branchEmojiMapper[branch]} ${branchCountryMapper[branch]}`,
          value: branchMapper[branch],
        }))}
      />
      <FilterGroup
        name="Quote status"
        items={Object.values(PreQualifiedQuoteStatusEnum).map((status) => ({
          label: `${preQualifiedQuoteEmojiMapper[status]} ${preQualifiedQuoteLabelMapper[status]}`,
          value: status,
        }))}
      />
    </div>
  );
};

import { COMPONENT_NAME } from './component-name.enum';

export const componentDefaultCopyText = {
  [COMPONENT_NAME.MONEY_DISBURSING]: {
    title: 'Congratulations',
    subtitle: 'is coming your way!',
    ctaText: 'go to dashboard',
    tipText: 'Most customers will receive their money in minutes.',
  },
};

export const componentDebtConsDefaultCopyText = {
  [COMPONENT_NAME.MONEY_DISBURSING]: {
    title: 'Congratulations',
    subtitle: 'is on its way!',
    ctaText: 'go to dashboard',
    tipText: 'Most customers debts will be paid off in minutes.',
  },
};

export const componentDefaultCopyArray = Object.keys(componentDefaultCopyText).map((key) => ({
  key,
  value: componentDefaultCopyText[key],
}));

export const bankStatementErrorsMapper = ({ errorCode, errorMessage }) => {
  if (!errorCode) {
    return;
  }

  const sameMessageErrorCodes = [110000, 1100012, 20008, 30003, 50101, 60006];
  if (sameMessageErrorCodes.includes(errorCode)) {
    return errorMessage;
  }

  const connectionErrorsCodes = [30005, 30006, 30009, 19008];
  const connectionErrorsMessage = 'There was an issue connecting with your bank. Please try again shortly.';
  if (connectionErrorsCodes.includes(errorCode)) {
    return connectionErrorsMessage;
  }

  const unknownErrorCodes = [30024, 90002, 40010];
  const unknownErrorMessage = 'It looks like something went wrong. Please try again later.';
  if (unknownErrorCodes.includes(errorCode)) {
    return unknownErrorMessage;
  }

  const sessionExpiredErrorCodes = [19002];
  const sessionExpiredErrorMessage = 'Your bank session has expired. Please try again.';
  if (sessionExpiredErrorCodes.includes(errorCode)) {
    return sessionExpiredErrorMessage;
  }

  const exportErrorCodes = [30004];
  const exportErrorMessage =
    '<strong>Unable to export your bank statement data.</strong> Please log in to your online banking (desktop site) and complete the action requested.';
  if (exportErrorCodes.includes(errorCode)) {
    return exportErrorMessage;
  }

  const accountErrorCodes = [30016];
  const accountErrorMessage =
    "We couldn't find any accounts that were successfully processed. Please try adding another account.";
  if (accountErrorCodes.includes(errorCode)) {
    return accountErrorMessage;
  }

  const temporaryErrorCodes = [30014];
  const temporaryErrorMessage = 'There was a temporary error while collecting data. Please try again later.';
  if (temporaryErrorCodes.includes(errorCode)) {
    return temporaryErrorMessage;
  }

  const networthErrorCodes = [50001];
  const networthErrorMessage =
    'Network issues are causing problems when trying to connect to your Bank. Please try again later.';
  if (networthErrorCodes.includes(errorCode)) {
    return networthErrorMessage;
  }
};

import { useCallback, useState } from 'react';
import { LoanPurposeQuestionDto } from '@harmoney/api-interfaces';
import { useFriendlyURL, useTransformLoanPurposes } from '@harmoney/hooks';
import { useGetLoanPurposesQuery, useGetVariablesQuery, useUpdateLoanPurposesMutation } from '@harmoney/redux';
import {
  ArrowCircleRightIcon,
  Button,
  Card,
  Checkbox,
  Form,
  Label,
  RadioSelectSelector,
  ToggleGroup,
  useForm,
} from '@harmoney/ui-design-system';
import { LoanPurposeQuestionType } from '@prisma/client';
import { z } from 'zod';

import { CommonProps } from '../../common-props';

export function LoanPurpose({ taskId, loanProductData, completeTask, taskFriendlyURL }: CommonProps) {
  const formSchema = z.object({
    loanPurpose: z
      .object({
        primary: z.string(),
        secondary: z.string(),
      })
      .refine((data) => data.primary, {
        path: ['primary'],
      })
      .refine((data) => data.primary, {
        message: 'Please select a loan purpose',
        path: ['secondary'],
      }),
    questions: z
      .object({
        optionId: z.union([z.string(), z.boolean()]),
      })
      .refine(({ optionId }) => optionId, {
        message: 'Required',
        path: ['optionId'],
      })
      .array(),
  });
  type FormValues = z.infer<typeof formSchema>;
  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues: {
      loanPurpose: { primary: '', secondary: '' },
      questions: [],
    },
  });
  const {
    watch,
    resetField,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;
  const watchLoanPurpose = watch('loanPurpose');

  const [questions, setQuestions] = useState<LoanPurposeQuestionDto[]>([]);
  const { data: loanPurposes, isLoading: isLoanPurposesLoading } = useGetLoanPurposesQuery(loanProductData.id);
  const { primaryLoanPurposes, secondaryLoanPurposes } = useTransformLoanPurposes(loanPurposes);
  const { data: variables } = useGetVariablesQuery(taskId);
  const [updateLoanPurposes] = useUpdateLoanPurposesMutation();

  const handleSelectedLoanPurpose = useCallback(() => {
    if (!watchLoanPurpose.primary) return;
    const { questions } = loanPurposes.find((loanPurpose) => loanPurpose.id === watchLoanPurpose.primary);
    if (questions.length > 0) {
      setQuestions(questions);
    } else {
      setQuestions([]);
      resetField('questions', { defaultValue: [] });
    }
  }, [loanPurposes, resetField, watchLoanPurpose.primary]);

  const handleSubmit = async (data: FormValues) => {
    const loanPurposeId = watchLoanPurpose.primary;
    const applicationId = variables.loanApplicationId.toString();

    const loanPurposeData = {
      id: loanPurposeId,
      answers: data.questions.map(({ optionId }, index) => ({
        questionId: questions[index].id,
        optionId: typeof optionId === 'boolean' ? null : optionId,
      })),
    };

    await updateLoanPurposes({ applicationId, loanPurposes: [loanPurposeData] });
    completeTask({ taskId });
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      <h1>
        What will you <span className="text-primary">use the money</span> for?
      </h1>
      <Form form={form} onSubmit={handleSubmit}>
        {!isLoanPurposesLoading && primaryLoanPurposes.length > 0 && secondaryLoanPurposes.length > 0 && (
          <Card>
            <RadioSelectSelector
              name="loanPurpose"
              radioOptions={primaryLoanPurposes}
              selectOptions={secondaryLoanPurposes}
              onChange={handleSelectedLoanPurpose}
            />
          </Card>
        )}

        {questions.length > 0 &&
          questions.map(({ id, questionType, name, options }, index) => (
            <Card key={id}>
              {questionType === LoanPurposeQuestionType.YESNO && (
                <>
                  <Label className="mb-2 font-medium">{name}</Label>
                  <ToggleGroup
                    name={`questions.${index}.optionId`}
                    options={options.map((option) => ({ label: option.name, value: option.id }))}
                  />
                </>
              )}
              {questionType === LoanPurposeQuestionType.CHECK && (
                <Checkbox name={`questions.${index}.optionId`} label={name} />
              )}
            </Card>
          ))}

        <Button
          type="submit"
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          isLoading={isSubmitting || isSubmitSuccessful}
          hasShadow
        >
          Continue
        </Button>
      </Form>
    </>
  );
}

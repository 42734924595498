import { EssentialExpenseDetailDto, NetworthSourceEnum } from '@harmoney/api-interfaces';
import { booleanToYesNo, CO_MIN_RENT_OVERRIDE, errors } from '@harmoney/ui-utils';
import { convertToMonthlyAmount } from '@harmoney/utilities';
import { IncomeAndExpenseFrequencyEnum } from '@prisma/client';
import { isEmpty } from 'lodash';
import { z } from 'zod';

export enum FormSchemaTypeEnum {
  BASE = 'base',
  WITH_SHARED_EXPENSE = 'withSharedExpense',
  WITHOUT_SHARED_EXPENSE = 'withoutSharedExpense',
  EXPENSE_NOT_LISTED = 'expenseNotListed',
  EXPENSE_NOT_LISTED_WITH_SHARED_EXPENSE = 'expenseNotListedWithSharedExpense',
  EXPENSE_NOT_LISTED_WITHOUT_SHARED_EXPENSE = 'expenseNotListedWithoutSharedExpense',
}

export const errorMessageForAmountComparison =
  'Please check the amount entered. Your share must be lower than the total cost.';

const errorMessageForUnavailableDebtType = "Don't add your debts here, we will ask for them later";

// TODO: Temporary solution, this would be replaced by a fuzzy search
const unavailableExpenseTypes = ['personal loan', 'credit card', 'mortgage', 'loan'];

export const getInitialDefaultValues = (expenseFormDetail: EssentialExpenseDetailDto) => ({
  id: '',
  networthSourceId: expenseFormDetail?.networthSourceId,
  isExpenseShared: '',
  expenseNotListed: { otherExpenseType: '' },
  withSharedExpense: {
    declaredTotalAmount: null,
    frequencyForTotalAmount: '',
    declaredAmount: null,
    frequency: '',
  },
  withoutSharedExpense: {
    declaredAmount: null,
    frequency: '',
  },
});

export const prePopulateDefaultValues = (expenseFormDetail: EssentialExpenseDetailDto) => {
  return {
    id: expenseFormDetail?.id,
    networthSourceId: expenseFormDetail.networthSourceId,
    isExpenseShared: booleanToYesNo(expenseFormDetail.isExpenseShared) ?? '',
    expenseNotListed: { otherExpenseType: expenseFormDetail?.otherExpenseType ?? '' },
    withSharedExpense: {
      declaredTotalAmount: expenseFormDetail?.declaredTotalAmount ?? null,
      frequencyForTotalAmount: expenseFormDetail?.frequencyForTotalAmount ?? '',
      declaredAmount: expenseFormDetail?.declaredAmount ?? null,
      frequency: expenseFormDetail?.frequency ?? '',
    },
    withoutSharedExpense: {
      declaredAmount: expenseFormDetail?.declaredAmount ?? null,
      frequency: expenseFormDetail?.frequency ?? '',
    },
  };
};

export const baseFormSchema = z.object({
  type: z.literal(FormSchemaTypeEnum.BASE),
  id: z.string().nullable().optional(),
  networthSourceId: z.number().nullable().optional(),
  isExpenseShared: z
    .string({ required_error: errors.defaultRequiredField })
    .min(1, { message: errors.defaultRequiredField }),
});

export const formSchemaForSharedExpense = z.object({
  withSharedExpense: z
    .object({
      declaredAmount: z
        .number({
          invalid_type_error: errors.defaultValidAmount,
          required_error: errors.defaultValidAmount,
        })
        .nullable()
        .optional(),
      frequency: z.string().nullable().optional(),
      declaredTotalAmount: z.number({ invalid_type_error: '' }).nullable().optional(),
      frequencyForTotalAmount: z.string().nullable().optional(),
    })
    .refine(
      (data) => {
        if (data.declaredAmount > 0 && isEmpty(data.frequency)) return false;
        return true;
      },
      {
        message: errors.defaultRequiredField,
        path: ['frequency'],
      }
    )
    .refine(
      (data) => {
        if (data.declaredTotalAmount > 0 && isEmpty(data.frequencyForTotalAmount)) return false;
        return true;
      },
      {
        message: errors.defaultRequiredField,
        path: ['frequencyForTotalAmount'],
      }
    )
    .refine(
      (data) => {
        if (
          (data.declaredTotalAmount === null || data.declaredTotalAmount === 0) &&
          (data.declaredAmount === null || data.declaredAmount === 0)
        ) {
          return false;
        }
        return true;
      },
      {
        message: errors.defaultRequiredField,
        path: ['declaredTotalAmount'],
      }
    )
    .refine(
      (data) => {
        // allow user to only input total amount
        if (data.declaredTotalAmount > 0 && data.declaredAmount === null) {
          return true;
        }
        // allow user to only input shared amount
        if (data.declaredTotalAmount === null && data.declaredAmount > 0) {
          return true;
        }
        // allow user to input both total amount and shared amount
        if (
          data.declaredTotalAmount > 0 &&
          data.declaredAmount >= 0 &&
          convertToMonthlyAmount(
            data.declaredTotalAmount,
            data.frequencyForTotalAmount as IncomeAndExpenseFrequencyEnum
          ) > convertToMonthlyAmount(data.declaredAmount, data.frequency as IncomeAndExpenseFrequencyEnum)
        ) {
          return true;
        }
        return false;
      },
      {
        message: errorMessageForAmountComparison,
        path: ['declaredAmount'],
      }
    ),
});

export const formSchemaForNoSharedExpense = z.object({
  withoutSharedExpense: z
    .object({
      declaredAmount: z
        .number({
          invalid_type_error: errors.defaultValidAmount,
          required_error: errors.defaultValidAmount,
        })
        .gt(0),
      frequency: z.string().nullable().optional(),
    })
    .refine(
      (data) => {
        if (data.declaredAmount > 0 && isEmpty(data.frequency)) return false;
        return true;
      },
      {
        message: errors.defaultRequiredField,
        path: ['frequency'],
      }
    ),
});

const formSchemaForExpenseNotListed = z.object({
  expenseNotListed: z
    .object({
      otherExpenseType: z.string(),
    })
    .refine(
      (data) => {
        if (isEmpty(data.otherExpenseType)) return false;
        return true;
      },
      {
        message: errors.requiredField('Expense'),
        path: ['otherExpenseType'],
      }
    )
    .refine(
      (data) => {
        if (unavailableExpenseTypes.includes(data.otherExpenseType.toLocaleLowerCase())) return false;
        return true;
      },
      {
        message: errorMessageForUnavailableDebtType,
        path: ['otherExpenseType'],
      }
    ),
});

const formSchemaWithSharedExpense = baseFormSchema.extend({
  type: z.literal(FormSchemaTypeEnum.WITH_SHARED_EXPENSE),
  ...formSchemaForSharedExpense.shape,
});

const formSchemaWithoutSharedExpense = baseFormSchema.extend({
  type: z.literal(FormSchemaTypeEnum.WITHOUT_SHARED_EXPENSE),
  ...formSchemaForNoSharedExpense.shape,
});

const formSchemaWithExpenseNotListed = baseFormSchema.extend({
  type: z.literal(FormSchemaTypeEnum.EXPENSE_NOT_LISTED),
  ...formSchemaForExpenseNotListed.shape,
});

const formSchemaForExpenseNotListedWithSharedExpense = formSchemaWithSharedExpense.extend({
  type: z.literal(FormSchemaTypeEnum.EXPENSE_NOT_LISTED_WITH_SHARED_EXPENSE),
  ...formSchemaForExpenseNotListed.shape,
});

const formSchemaForExpenseNotListedWithoutSharedExpense = formSchemaWithoutSharedExpense.extend({
  type: z.literal(FormSchemaTypeEnum.EXPENSE_NOT_LISTED_WITHOUT_SHARED_EXPENSE),
  ...formSchemaForExpenseNotListed.shape,
});

export const formSchema = z.union([
  baseFormSchema,
  formSchemaWithSharedExpense,
  formSchemaWithoutSharedExpense.superRefine((data, { addIssue }) => {
    if (
      data.networthSourceId === NetworthSourceEnum.EXPENSE_RENT_ID &&
      convertToMonthlyAmount(
        data.withoutSharedExpense.declaredAmount,
        data.withoutSharedExpense.frequency as IncomeAndExpenseFrequencyEnum
      ) < CO_MIN_RENT_OVERRIDE
    ) {
      addIssue({
        code: z.ZodIssueCode.custom,
        message: `Please enter a minimum of $${CO_MIN_RENT_OVERRIDE} per month`,
        path: ['withoutSharedExpense', 'declaredAmount'],
      });
    }
  }),
  formSchemaWithExpenseNotListed,
  formSchemaForExpenseNotListedWithSharedExpense,
  formSchemaForExpenseNotListedWithoutSharedExpense,
]);

export type FormSchema = z.infer<typeof formSchema>;
export type FormSchemaWithSharedExpense = z.infer<typeof formSchemaWithSharedExpense>;

import { ProcessDto, ProcessInstanceDto } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const processApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getProcessDetail: builder.query<ProcessDto, string>({
      query: (id) => ({
        url: `/processes/${id}`,
        method: 'GET',
      }),
    }),
    getLatestOriginationProcessId: builder.query({
      query: () => ({
        url: `/processes/latest-origination-process-id`,
        method: 'GET',
      }),
    }),
    getProcessDefinition: builder.query<XMLDocument, string>({
      query: (processDefinitionKey) => ({
        url: `/processes/process-definition`,
        method: 'GET',
        params: {
          processDefinitionKey,
        },
        responseHandler: (response) => response.text(),
      }),
    }),
    getProcessInstance: builder.query<ProcessInstanceDto, string>({
      query: (processInstanceId) => ({
        url: `processes/process-instance`,
        method: 'GET',
        params: {
          processInstanceId,
        },
      }),
    }),
  }),
});

export const {
  useGetProcessDetailQuery,
  useGetLatestOriginationProcessIdQuery,
  useGetProcessDefinitionQuery,
  useGetProcessInstanceQuery,
} = processApi;

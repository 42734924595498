/* tslint:disable */
/* eslint-disable */
/**
 * Frankie Financial API
 * ------   This API allows developers to integrate the Frankie Financial Compliance Utility into their applications. The API allows:   - Checking name, address, date of birth against national databases   - Validating Australian driver's licences, passports, medicare, visas and other Australian national ID documents   - Validating Australian electricity bills   - Validating NZ driver's licences   - Validating Chinese bank cards and national ID card   - Validating International passports and national ID documents   - PEP, Sanctions, Watchlist and adverse media checking   - Australian visa checks    - Fraud list and fraud background checks   - ID validation and selfie check comparisons.    ------     Industry specific services    - Comparing Australian electricity retailers for a better deal.  ------     KYB specific services    - Query organisation ownership   - Perform KYC & AML checks on shareholders, beneficial owners and office bearers.   - Query credit score and credit reports   - International company searches   - International company profiles    ------   The full version of this documentation along with supplemental articles can be found here:   - https://apidocs.frankiefinancial.com/  ------ Sandbox base URL is:   - https://api.demo.frankiefinancial.io/compliance/v1.2      - All calls are the same as production, only with test data. You can download the latest test data here: https://apidocs.frankiefinancial.com/docs/test-data    - Full Swagger definition, along with test data for playing in the sandbox can be obtained once initial commercial discussions have commenced.    - Production and optional UAT access will be opened up only to those with a signed commercial contract.    ------   Contact us at hello@frankiefinancial.com to speak with a sales rep about issuing a Customer ID and Sandbox api key.
 *
 * OpenAPI spec version: 1.14.0
 * Contact: dev-support@frankiefinancial.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DisplayMarkUpParameters } from './display-mark-up-parameters';
/**
 *
 * @export
 * @interface DisplayMarkUp
 */
export interface DisplayMarkUp {
  /**
   * Comma seperated list of attributes to apply to value when rendering.
   * @type {string}
   * @memberof DisplayMarkUp
   */
  attribute?: DisplayMarkUpAttributeEnum;
  /**
   * Children of element. This data structure is recursive with a DisplayMarkup  element having 0 or more DisplayMarkup children
   * @type {Array<DisplayMarkUp>}
   * @memberof DisplayMarkUp
   */
  children?: Array<DisplayMarkUp>;
  /**
   * Does this statement need to be confirmed (with a checkbox)? Confirmation can be mandatory or optional. When the confirmation element is present a key element must also be present. The keys of all confirmed statements must be sent in the switch request. The absence of any mandatory confirmation will result in an error response from the switch request.
   * @type {string}
   * @memberof DisplayMarkUp
   */
  confirmation?: DisplayMarkUpConfirmationEnum;
  /**
   * For elements that require confirmation, the key of each element that the user has accepted must be send in the switch request. The absence of any mandatory confirmation will result in an error response from the switch request.
   * @type {string}
   * @memberof DisplayMarkUp
   */
  key?: string;
  /**
   * Text to display as header/title of value.
   * @type {string}
   * @memberof DisplayMarkUp
   */
  name?: string;
  /**
   * Optional element which has a keyvalue pair associated with every data binding contained in the value element of the current object.
   * @type {{ [key: string]: DisplayMarkUpParameters; }}
   * @memberof DisplayMarkUp
   */
  parameters?: { [key: string]: DisplayMarkUpParameters };
  /**
   * Type of component to be used for rendering
   * @type {string}
   * @memberof DisplayMarkUp
   */
  type?: DisplayMarkUpTypeEnum;
  /**
   * Data to be rendered. This data can contain data bindings (contained in {{ }}). If present in the string the parameters object will contain a key with the same name and the associated data (e.g a link).<br><br><div style=\"background-color:black;color:white;\">{<br>&nbsp;&nbsp;\"type\":&nbsp;\"text\",<br>&nbsp;&nbsp;\"value\":&nbsp;\"I&nbsp;accept&nbsp;the&nbsp;{{Terms&nbsp;and&nbsp;Conditions}}.\",<br>&nbsp;&nbsp;\"parameters\":&nbsp;{<br>&nbsp;&nbsp;&nbsp;&nbsp;\"Terms&nbsp;and&nbsp;Conditions\":&nbsp;{<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"type\":&nbsp;\"link\",<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"value\":&nbsp;\"https://www.accurassi.com.au/sample-terms-and-conditions/\"<br>&nbsp;&nbsp;&nbsp;&nbsp;}<br>&nbsp;&nbsp;}<br>}</div>
   * @type {string}
   * @memberof DisplayMarkUp
   */
  value?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum DisplayMarkUpAttributeEnum {
  Bold = 'bold',
}
/**
 * @export
 * @enum {string}
 */
export enum DisplayMarkUpConfirmationEnum {
  Optional = 'optional',
  Mandatory = 'mandatory',
}
/**
 * @export
 * @enum {string}
 */
export enum DisplayMarkUpTypeEnum {
  Text = 'text',
  Unorderedlist = 'unorderedlist',
  Orderedlist = 'orderedlist',
}

import { Card, Spinner } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';

interface QuoteIntroductionProps {
  preferredName: string;
  quotePresentedAmount: number;
  interestRate: number;
}

export function QuoteIntroductionV2({ preferredName, quotePresentedAmount, interestRate }: QuoteIntroductionProps) {
  if (!preferredName) return <Spinner />;

  return (
    <>
      <h1 className="text-white">Congratulations {preferredName}, here&rsquo;s your quote</h1>
      <Card className="flex">
        <div className="basis-1/2 text-center">
          <span className="text-sm font-medium">Borrow up to</span>
          <h2 className="text-h1 text-primary mb-0">{formatCurrency(quotePresentedAmount)}</h2>
        </div>
        <div className="border border-grey-2" />
        <div className="basis-1/2 text-center">
          <span className="text-sm font-medium">Interest rate</span>
          <h2 className="text-h1 text-primary mb-0">{interestRate}%</h2>
          <span className="text-xs text-grey-4 font-medium">p.a.(fixed)</span>
        </div>
      </Card>
    </>
  );
}

import Image from 'next/image';
import { useEffect, useState } from 'react';
import { useFriendlyURL } from '@harmoney/hooks';
import {
  useGetEligibleDebtsForConsolidationQuery,
  useGetLoanApplicationQuery,
  useGetVariablesQuery,
} from '@harmoney/redux';
import { Spinner } from '@harmoney/ui-design-system';
import { dayjsSydney, isDebtCon } from '@harmoney/utilities';
import { Player } from '@lottiefiles/react-lottie-player';
import { AssetAndLiability, NetworthSource } from '@prisma/client';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CommonProps } from '../../common-props';

import { QuoteReadyGeneric } from './QuoteReadyGeneric';
import { QuoteReadySingleDebt } from './QuoteReadySingleDebt';

import 'swiper/css';

export const TimeDisplayBySydneyTimeZone = () => {
  const sydneyTime = dayjsSydney();
  const hour = sydneyTime.hour();

  if (hour >= 8 && hour < 17) {
    return (
      <span>
        in <span className="font-medium">15 mins</span>
      </span>
    );
  } else {
    return (
      <span>
        by <span className="font-medium">10am tomorrow</span>
      </span>
    );
  }
};

const cards = [
  {
    imageSrc: '/assets/images/repayment-fees.svg',
    text: 'No early repayment fees',
  },
  {
    imageSrc: '/assets/images/money-in-minutes.svg',
    text: 'Money in minutes',
  },
  {
    imageSrc: '/assets/images/fair-rates.svg',
    text: 'Fair rates',
  },
];

export function QuoteReadyV2({ loanProductData, taskId, completeTask, taskFriendlyURL }: CommonProps) {
  const { data: variables, isLoading } = useGetVariablesQuery(taskId);
  const { data: loanApplicationData } = useGetLoanApplicationQuery(variables?.loanApplicationId.toString(), {
    skip: !variables,
    refetchOnMountOrArgChange: true,
  });

  const { data: eligibleDebts } = useGetEligibleDebtsForConsolidationQuery(variables?.financialProfileId.toString(), {
    skip: !variables || !isDebtCon(loanProductData?.name),
    refetchOnMountOrArgChange: true,
  });

  const [hasOneDebt, setOneDebt] = useState(false);

  const [selectedDebt, setSelectedDebt] = useState<
    Partial<AssetAndLiability> & {
      networthSource: Partial<NetworthSource>;
    }
  >(null);
  useFriendlyURL(taskFriendlyURL);

  useEffect(() => {
    if (eligibleDebts && isDebtCon(loanProductData?.name)) {
      const maximumBorrowingLimit =
        (loanApplicationData?.quoteMaxBorrowingLimit as number) ||
        ((variables?.quote as any)?.maximumBorrowingLimit as number);
      const filteredEligibleDebts = eligibleDebts?.filter((debt) => debt.outstandingBalance <= maximumBorrowingLimit);

      if (filteredEligibleDebts?.length === 1) {
        const debt = filteredEligibleDebts[0];
        setOneDebt(true);
        setSelectedDebt(debt);
      }
    }
  }, [eligibleDebts, variables, loanProductData?.name, loanApplicationData]);

  if (isLoading) {
    return <Spinner size="large" />;
  }

  return (
    <>
      <div className="fixed top-0 left-0 w-full bg-gradient-to-b from-secondary-lighter-2 to-gradient-grey h-[20rem]"></div>

      <div className="relative mt-12">
        <Player
          autoplay
          loop
          controls={false}
          src="https://lottie.host/74413bf1-c7cd-40aa-9fb3-903a936fd756/K6ArNCmwIK.json"
          style={{ position: 'absolute', width: '100%', top: '-100px' }}
        />
      </div>

      <div className="relative">
        {isDebtCon(loanProductData?.name) && hasOneDebt ? (
          <QuoteReadySingleDebt
            loanApplicationData={loanApplicationData}
            establishmentFee={{
              establishmentFeeOverThreshold: (variables?.quote as any)?.establishmentFeeOverThreshold as number,
              establishmentFeeUnderThreshold: (variables?.quote as any)?.establishmentFeeUnderThreshold as number,
              establishmentFeeThreshold: (variables?.quote as any)?.establishmentFeeThreshold as number,
            }}
            selectedDebt={selectedDebt}
            taskId={taskId}
            completeTask={completeTask}
          />
        ) : (
          <QuoteReadyGeneric
            loanApplicationData={loanApplicationData}
            loanProductData={loanProductData}
            taskId={taskId}
            completeTask={completeTask}
          />
        )}
        <h3 className="font-body text-base font-medium">Why Harmoney</h3>
        <div className="md:hidden">
          <Swiper slidesPerView={2} className="md:hidden">
            {cards.map(({ imageSrc, text }, index) => (
              <SwiperSlide
                key={index}
                className="shadow-drop mr-4 flex flex-col items-center justify-center rounded-lg bg-white p-2 "
              >
                <Image src={imageSrc} alt={text} width={200} height={200} quality={100} />
                <h4 className="text-h5 my-2 text-center !font-medium">{text}</h4>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <ul className="hidden grid-cols-2 gap-4 md:grid md:grid-cols-3">
          {cards.map(({ imageSrc, text }, index) => (
            <li key={index} className="shadow-drop flex flex-col items-center justify-center rounded-lg bg-white p-2">
              <Image src={imageSrc} alt={text} width={200} height={200} quality={100} />
              <h4 className="my-2 text-center text-sm !font-medium">{text}</h4>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

import { useEffect, useMemo, useState } from 'react';
import { Frequency, RepaymentFrequency } from '@harmoney/api-interfaces';
import { useFormatQuoteOptions, useFriendlyURL, useQuoteData } from '@harmoney/hooks';
import {
  useAppSelector,
  useGetLoanApplicationQuery,
  useGetLoanPurposesForPersonalLoanQuery,
  useGetPostApprovalConsolidatedDebtsQuery,
  useGetQuoteOptionsQuery,
  useGetUserQuery,
  useGetVariablesQuery,
  useSubmitLoanApplicationMutation,
  useUpdateLoanPurposesMutation,
} from '@harmoney/redux';
import { eventAnalytics, LOAN_APPLICATION_SUBMITTED } from '@harmoney/ui-app-shell';
import { Button, Form, Spinner, useForm } from '@harmoney/ui-design-system';
import { LoanApplicationStatusEnum } from '@prisma/client';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { isUndefined } from 'lodash';

import { CommonProps } from '../../common-props';
import {
  calculateEstablishmentFee,
  formatLoanPurposes,
  getLoanPurposeName,
  LoanPeriodAlert,
  QuoteDisclaimer,
  QuoteIntroduction,
  QuoteSummary,
  RepaymentOptions,
} from '../shared';

import { DebtConBenefits, DebtsSummary, MultiLoanPurposes } from './components';
import { ExtraLimitOption } from './ExtraLimit';
import { createFormSchema, defaultValues, validationMessages } from './form-config';

dayjs.extend(relativeTime);

export function DebtConsolidationQuoteV3({ loanProductData, taskId, completeTask, taskFriendlyURL }: CommonProps) {
  useFriendlyURL(taskFriendlyURL);
  const userId = useAppSelector((state) => state.userId.value);
  const [borrowingLimit, setBorrowingLimit] = useState<{ lowerLimit: number; upperLimit: number }>({
    lowerLimit: 0,
    upperLimit: 0,
  });
  const [uiState, setUiState] = useState({
    isSecondaryPurposeFormOpen: false,
    isSubmitting: false,
    errorMsgForSecondaryPurposeAmount: null,
  });

  const formSchema = createFormSchema(borrowingLimit.lowerLimit, borrowingLimit.upperLimit);
  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues,
  });
  const {
    watch,
    register,
    unregister,
    setError,
    clearErrors,
    formState: { errors },
  } = form;
  const watchFields = watch();

  const { data: userData } = useGetUserQuery();
  const { data: variables } = useGetVariablesQuery(taskId);
  const { quoteData } = useQuoteData(taskId);
  const { data: loanPurposesData } = useGetLoanPurposesForPersonalLoanQuery(null, {
    skip: !loanProductData,
  });
  const { data: loanApplicationData } = useGetLoanApplicationQuery(variables?.loanApplicationId.toString(), {
    skip: !variables,
    refetchOnMountOrArgChange: true,
  });

  const { totalAcceptedAmount, establishmentFee, totalLoanAmount } = useMemo(() => {
    const totalAccepted = watchFields.primaryPurposeAmount + (watchFields.secondaryPurposeAmount || 0);
    const fee = calculateEstablishmentFee(totalAccepted, quoteData);
    const totalLoan = totalAccepted + fee;
    return { totalAcceptedAmount: totalAccepted, establishmentFee: fee, totalLoanAmount: totalLoan };
  }, [watchFields.primaryPurposeAmount, watchFields.secondaryPurposeAmount, quoteData]);

  const loanApplicationPurposes = useMemo(() => {
    return loanApplicationData?.loanApplicationPurposes || [];
  }, [loanApplicationData?.loanApplicationPurposes]);

  const availableSecondaryPurposes = useMemo(() => {
    if (!loanPurposesData || !loanApplicationPurposes) return [];
    const excludeId = loanApplicationPurposes[0]?.loanPurposeId;
    return formatLoanPurposes(loanPurposesData, excludeId);
  }, [loanApplicationPurposes, loanPurposesData]);

  const maxExtraLimitForSecondaryPurpose = useMemo(() => {
    return loanApplicationData ? loanApplicationData.applicationBorrowingLimit - watchFields.primaryPurposeAmount : 0;
  }, [watchFields.primaryPurposeAmount, loanApplicationData]);

  const isEligibleForExtraLimit = useMemo(() => {
    return (
      loanApplicationData?.isEligibleForExtraLimit &&
      maxExtraLimitForSecondaryPurpose >= loanApplicationData.minExtraLimit &&
      loanApplicationData?.fundedAmount ===
        (loanApplicationData?.quoteMaxBorrowingLimit || variables?.quote['maximumBorrowingLimit'])
    );
  }, [loanApplicationData, maxExtraLimitForSecondaryPurpose, variables]);

  const showExtraLimitOption = useMemo(() => {
    const showExtraLimitOption = isEligibleForExtraLimit;

    return showExtraLimitOption;
  }, [isEligibleForExtraLimit]);

  const hasOneDebt = variables?.hasOneDebt as boolean;

  const { data: quoteOptionsData } = useGetQuoteOptionsQuery(
    {
      loanApplicationId: variables?.loanApplicationId.toString(),
      loanAmount: totalAcceptedAmount,
      establishmentFee,
    },
    {
      skip: !variables || !totalAcceptedAmount || !establishmentFee,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: postApprovalDebtsData } = useGetPostApprovalConsolidatedDebtsQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const formattedQuoteOptions = useFormatQuoteOptions(
    watchFields.repaymentFrequency as RepaymentFrequency,
    quoteOptionsData
  );

  const hmyRepaymentAmount = useMemo(() => {
    const result = quoteOptionsData?.find(
      (option) => option.termInMonths === 84 && option.repaymentFrequency === RepaymentFrequency.MONTHLY
    )?.repaymentAmount;
    return result || undefined;
  }, [quoteOptionsData]);

  const isSameLoanLimitAcrossOptions = useMemo(() => {
    return (
      quoteOptionsData?.every(({ loanLimitFromUmi }) => loanLimitFromUmi === quoteOptionsData[0].loanLimitFromUmi) ||
      false
    );
  }, [quoteOptionsData]);

  const [updateLoanPurposes] = useUpdateLoanPurposesMutation();
  const [submitLoanApplication] = useSubmitLoanApplicationMutation();

  useEffect(() => {
    if (!quoteData || !loanApplicationData) return;

    setBorrowingLimit({
      lowerLimit: quoteData.minimumBorrowingLimit,
      upperLimit: loanApplicationData.quotePresentedAmount,
    });

    if (loanApplicationPurposes[0].fundedAmount) {
      if (!isUndefined(watchFields.primaryPurposeAmount)) {
        form.setValue('primaryPurposeAmount', watchFields.primaryPurposeAmount);
      } else {
        form.setValue('primaryPurposeAmount', variables?.totalToConsolidate as number);
      }
    } else {
      form.setValue('primaryPurposeAmount', variables?.totalToConsolidate as number);
    }

    form.setValue('termInMonths', +quoteData.termInMonths);
  }, [loanApplicationPurposes, form, loanApplicationData, quoteData, variables, availableSecondaryPurposes]);

  useEffect(() => {
    const { lowerLimit, upperLimit } = borrowingLimit;

    const getErrorMessage = () => {
      const totalAmount = watchFields.primaryPurposeAmount + watchFields.secondaryPurposeAmount;
      if (totalAmount < lowerLimit) {
        return validationMessages.lowerLimitError(lowerLimit);
      }
      if (totalAmount > upperLimit) {
        return validationMessages.upperLimitError(upperLimit - watchFields.primaryPurposeAmount);
      }
      if (!watchFields.secondaryPurposeAmount) {
        return validationMessages.secondaryPurposeAmountError;
      }
      return null;
    };

    const errorMessage = getErrorMessage();
    if (
      uiState.isSecondaryPurposeFormOpen &&
      errorMessage &&
      (errors.secondaryPurposeAmount || watchFields.secondaryPurposeAmount !== undefined)
    ) {
      setUiState((prevState) => ({
        ...prevState,
        errorMsgForSecondaryPurposeAmount: errorMessage,
      }));
    } else {
      setUiState((prevState) => ({
        ...prevState,
        errorMsgForSecondaryPurposeAmount: '',
      }));
    }
    if (uiState.isSecondaryPurposeFormOpen) {
      form.setValue('hasAddedAdditionalPurpose', true);
    } else {
      form.setValue('hasAddedAdditionalPurpose', false);
    }
  }, [
    form,
    borrowingLimit,
    loanApplicationPurposes,
    watchFields.primaryPurposeAmount,
    watchFields.secondaryPurposeAmount,
    watchFields.secondaryPurposeId,
    uiState.isSecondaryPurposeFormOpen,
    setError,
    clearErrors,
    errors,
  ]);

  const handleBorrowMoreClick = () => {
    setUiState((prevState) => ({
      ...prevState,
      isSecondaryPurposeFormOpen: true,
    }));
  };

  const handleDeleteClick = () => {
    unregister(['secondaryPurposeId', 'secondaryPurposeAmount']);
    setUiState((prevState) => ({
      ...prevState,
      isSecondaryPurposeFormOpen: false,
      errorMsgForSecondaryPurposeAmount: '',
    }));
  };

  const submitForm = async () => {
    await submitLoanApplication({
      id: variables.loanApplicationId.toString(),
      termInMonths: +form.getValues('termInMonths'),
      fundedAmount: +totalAcceptedAmount,
      repaymentFrequency: form.getValues('repaymentFrequency') as unknown as Frequency,
      status: LoanApplicationStatusEnum.application_in_progress,
    });

    const primaryLoanPurpose = {
      id: loanApplicationPurposes[0].loanPurposeId,
      requestedAmount: +watchFields.primaryPurposeAmount,
      fundedAmount: +watchFields.primaryPurposeAmount,
    };

    const secondaryLoanPurpose = {
      id: watchFields.secondaryPurposeId,
      requestedAmount: +watchFields.secondaryPurposeAmount,
      fundedAmount: +watchFields.secondaryPurposeAmount,
    };

    await updateLoanPurposes({
      applicationId: variables.loanApplicationId.toString(),
      loanPurposes: watchFields.secondaryPurposeAmount
        ? [primaryLoanPurpose, secondaryLoanPurpose]
        : [primaryLoanPurpose],
    });

    eventAnalytics.track(LOAN_APPLICATION_SUBMITTED, {
      userid_str: userId,
      taskid_str: taskId,
    });

    completeTask({ taskId });
  };

  const handleSubmit = async () => {
    setUiState((prevState) => ({
      ...prevState,
      isSubmitting: true,
    }));

    await submitForm();
  };

  if (!quoteData) return <Spinner />;

  return (
    <>
      <QuoteIntroduction preferredName={userData?.preferredName} isDebtCon={true} />

      <DebtsSummary
        hasOneDebt={hasOneDebt}
        canGoBack
        debts={postApprovalDebtsData}
        totalToConsolidate={loanApplicationData?.loanApplicationPurposes?.[0]?.requestedAmount}
        taskId={taskId}
      />
      {hasOneDebt && variables && loanApplicationData && quoteOptionsData && hmyRepaymentAmount && (
        <DebtConBenefits
          interestRate={+loanApplicationData?.finalInterestRate}
          maximumBorrowingLimit={variables?.quote['maximumBorrowingLimit']}
          financialProfileId={variables?.financialProfileId as string}
          hmyRepaymentAmount={hmyRepaymentAmount}
        />
      )}

      {showExtraLimitOption && !uiState.isSecondaryPurposeFormOpen && !errors?.primaryPurposeAmount?.message && (
        <ExtraLimitOption
          {...{ loanApplicationData, maxExtraLimitForSecondaryPurpose, onBorrowMoreClick: handleBorrowMoreClick }}
        />
      )}

      <Form form={form} onSubmit={handleSubmit}>
        {loanApplicationPurposes.length > 0 && (
          <MultiLoanPurposes
            {...{
              loanApplicationData,
              loanPurposesData,
              availableSecondaryPurposes,
              loanApplicationPurposes,
              uiState,
              primaryPurposeAmount: watchFields.primaryPurposeAmount,
              secondaryPurposeId: watchFields.secondaryPurposeId,
              secondaryPurposeAmount: watchFields.secondaryPurposeAmount,
              maxExtraLimitForSecondaryPurpose,
              register,
              onDeleteClick: handleDeleteClick,
            }}
          />
        )}

        {!isSameLoanLimitAcrossOptions && <LoanPeriodAlert className="mb-6" />}

        <RepaymentOptions formattedQuoteOptions={formattedQuoteOptions} register={register} />

        {watchFields.repaymentFrequency && (
          <QuoteSummary
            primaryPurpose={loanApplicationData?.loanApplicationPurposes[0]?.loanPurpose?.displayName}
            primaryPurposeAmount={watchFields.primaryPurposeAmount}
            secondaryPurpose={getLoanPurposeName(loanPurposesData, watchFields.secondaryPurposeId)?.displayName}
            secondaryPurposeAmount={watchFields.secondaryPurposeAmount}
            establishmentFee={establishmentFee}
            totalLoanAmount={totalLoanAmount}
            interestRate={loanApplicationData?.finalInterestRate as unknown as number}
          />
        )}

        <QuoteDisclaimer isDebtCon={true} />

        <div className="flex justify-center">
          <Button variant="primary" isLoading={uiState.isSubmitting} type="submit" hasShadow>
            Submit application
          </Button>
        </div>
      </Form>
    </>
  );
}

export enum Frequency {
  WEEKLY = 'weekly',
  FORTNIGHTLY = 'fortnightly',
  MONTHLY = 'monthly',
  HOURLY = 'hourly',
  YEARLY = 'yearly',
  ONE_OFF_PAYMENT = 'oneOffPayment',
}

/**
 * @deprecated Use RepaymentFrequencyEnum from prisma instead
 */
export enum RepaymentFrequency {
  WEEKLY = 'weekly',
  FORTNIGHTLY = 'fortnightly',
  MONTHLY = 'monthly',
}

export enum PaymentType {
  BPAY = 'bpay',
  BSB = 'bsb',
}

import React, { forwardRef, useEffect, useRef } from 'react';

import { PropsWithHTMLElement } from '../../types';
import { BaseFormFieldProps, FormField } from '../FormField';
import { HelpText } from '../HelpText';
import { Label } from '../Label';

interface InputInternalProps extends BaseFormFieldProps {
  type?: string;
  name: string;
  placeholder?: string;
  formFieldClass?: string;
  isNumeric?: boolean;
}

export type InputProps = PropsWithHTMLElement<InputInternalProps, 'input'>;

export const numberOnlyRegEx = /[0-9]+/;
export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      label,
      name,
      helpText,
      placeholder,
      prefix,
      suffix,
      readOnly,
      disabled,
      isDirty,
      isTouched,
      isInvalid,
      isFilled,
      isNumeric = false,
      formFieldClass,
      type = 'text',
      ...restProps
    }: InputProps,
    forwardRef: React.Ref<HTMLInputElement>
  ) => {
    const inputRef = useRef(null);

    // TODO: Not an ideal solution to prevent scrolling on number input using useEffect
    // Need to find a better solution to prevent scrolling on number input or create a custom NumberInput component
    useEffect(() => {
      const handleScroll = (event: React.WheelEvent<HTMLInputElement>) => {
        event.preventDefault();
      };
      const inputElement = inputRef.current;
      (inputElement as any)?.addEventListener('wheel', handleScroll, { passive: false });
      return () => {
        (inputElement as any)?.removeEventListener('wheel', handleScroll);
      };
    }, []);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault();
      }
      if (
        isNumeric &&
        !numberOnlyRegEx.test(event.key) &&
        !['Backspace', 'Delete'].includes(event.key) &&
        !(event.ctrlKey || event.metaKey) &&
        !['c', 'v'].includes(event.key.toLowerCase())
      ) {
        event.preventDefault();
      }
    };

    return (
      <div className={className}>
        {label && (
          <Label htmlFor={name} className="mb-2">
            {label}
          </Label>
        )}
        {helpText && <HelpText>{helpText}</HelpText>}
        <FormField
          isDirty={isDirty}
          className={formFieldClass}
          isTouched={isTouched}
          isInvalid={isInvalid}
          isFilled={isFilled}
          disabled={disabled}
          readOnly={readOnly}
          prefix={prefix}
          suffix={suffix}
        >
          <input
            type={type}
            id={name}
            data-testid={name}
            ref={inputRef}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readOnly}
            aria-invalid={isInvalid ? 'true' : undefined}
            aria-readonly={readOnly ? 'true' : undefined}
            onKeyDown={handleKeyDown}
            {...restProps}
          />
        </FormField>
      </div>
    );
  }
);

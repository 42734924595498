import { useRouter } from 'next/router';
import React from 'react';
import { OriginationType } from '@harmoney/api-interfaces';
import { ArrowCircleRightIcon, Button, Card } from '@harmoney/ui-design-system';
import { clearLocalStorageByPattern, PATTERN_LOAN_QUOTE_DETAILS_KEY } from '@harmoney/ui-utils';

type Props = {
  ctaText: string;
  mainProcessData: { processId: string };
};

const RepeatEligibleCard = ({ mainProcessData }: Props) => {
  const subtitle = 'Borrow more';
  const description = 'Need more money? Get a quote now';
  const ctaText = 'Get a quote';

  const router = useRouter();

  const handleClick = () => {
    clearLocalStorageByPattern(PATTERN_LOAN_QUOTE_DETAILS_KEY);
    router.push(`journey?originationType=${OriginationType.REPEAT}&journeyId=${mainProcessData?.processId}`);
  };

  return (
    <Card className="flex flex-col items-center justify-center md:px-16">
      <div className="flex flex-col gap-1 pb-4">
        <p className="m-0 text-center text-lg font-semibold">{subtitle}</p>
        <p className="text-center text-sm text-grey-4">{description}</p>
      </div>
      {mainProcessData?.processId && (
        <Button onClick={handleClick} alignIcon="end" icon={<ArrowCircleRightIcon size="large" />} variant="primary">
          {ctaText}
        </Button>
      )}
    </Card>
  );
};

export default RepeatEligibleCard;

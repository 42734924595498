import { useFriendlyURL } from '@harmoney/hooks';
import { Spinner } from '@harmoney/ui-design-system';

import { CommonProps } from '../../common-props';

export function IdentityLoading({ taskFriendlyURL }: CommonProps) {
  useFriendlyURL(taskFriendlyURL);

  return <Spinner size="large" />;
}

import { generateIcon } from '../../generateIcon';
import { IconVariant } from '../../Icon';

export const DebtConIcon = function ({ variant = 'secondary' }: { variant?: IconVariant }) {
  const Component = generateIcon({
    viewBox: '0 0 24 24',
    variant: variant,
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18.9297C14.3912 17.5465 16 14.9611 16 12C16 9.03887 14.3912 6.4535 12 5.07026C13.1767 4.38958 14.5429 4 16 4C20.4183 4 24 7.58172 24 12C24 16.4183 20.4183 20 16 20C14.5429 20 13.1767 19.6104 12 18.9297ZM12 18.9297C10.8233 19.6104 9.45715 20 8 20C3.58172 20 0 16.4183 0 12C0 7.58172 3.58172 4 8 4C9.45715 4 10.8233 4.38958 12 5.07026C9.6088 6.4535 8 9.03887 8 12C8 14.9611 9.6088 17.5465 12 18.9297Z"
        fill="#206EEE"
      />
    ),
  });
  return <Component />;
};

import { CreateUserInstanceDto, ProcessId, UiQuoteStatus } from '@harmoney/api-interfaces';
import type { LoanApplicationStatusEnum, OriginationTypeEnum, UserInstance } from '@prisma/client';

import { emptySplitApi } from './empty-api';

type TaskStatusDTO = {
  id: string;
  taskDefinitionId: string;
  status: string;
};

export type UserInstanceQueryResponse = {
  uiQuoteStatusMap?: UiQuoteStatus[];
  uiApplicationStatusMap?: UiQuoteStatus[];
  completedTasks?: TaskStatusDTO[];
  incompleteTasks?: TaskStatusDTO[];
  applicationStatus?: LoanApplicationStatusEnum;
  originationType?: OriginationTypeEnum;
};

const userInstanceApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    createUserInstance: builder.mutation<{ id: string }, CreateUserInstanceDto>({
      query: (data) => ({
        url: `/user-instances`,
        method: 'POST',
        body: data,
      }),
    }),
    createInternalUserInstance: builder.mutation<{ id: string }, CreateUserInstanceDto>({
      query: (data) => ({
        url: `/user-instances/internal`,
        method: 'POST',
        body: data,
      }),
    }),
    getUserInstancesByUserIdApplicationIdAndProcessIds: builder.query<
      UserInstance & { process: { processDefinitionKey: string } },
      { userId: string; loanApplicationId: string; processIds: ProcessId[] }
    >({
      query: ({ userId, loanApplicationId, processIds }) => ({
        url: `/user-instances/${userId}/${loanApplicationId}/processes?bpmnProcessIds=${processIds.join(',')}`,
        method: 'GET',
      }),
    }),
    getAllUserInstancesByUserIdApplicationIdAndProcessIds: builder.query<
      (UserInstance & { process: { processDefinitionKey: string } })[],
      { userId: string; loanApplicationId: string; processIds: ProcessId[] }
    >({
      query: ({ userId, loanApplicationId, processIds }) => ({
        url: `/user-instances/all/${userId}/${loanApplicationId}/processes?bpmnProcessIds=${processIds.join(',')}`,
        method: 'GET',
      }),
    }),
    getDashboardProgressByUserIdAndLoanApplicationId: builder.query<
      UserInstanceQueryResponse,
      { userId: string; loanApplicationId: string }
    >({
      query: ({ userId, loanApplicationId }) => ({
        url: `/user-instances/${userId}/${loanApplicationId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useCreateUserInstanceMutation,
  useCreateInternalUserInstanceMutation,
  useGetUserInstancesByUserIdApplicationIdAndProcessIdsQuery,
  useGetAllUserInstancesByUserIdApplicationIdAndProcessIdsQuery,
  useGetDashboardProgressByUserIdAndLoanApplicationIdQuery,
} = userInstanceApi;

import { GroupedPaymentHistoryForPeriodResult, PaymentHistoryForPeriodQuery } from '@harmoney/api-interfaces';
import { VaultAccountSnapshot } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const paymentHistoryApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentTransactions: builder.query<
      GroupedPaymentHistoryForPeriodResult,
      { loanApplicationId: string; params: PaymentHistoryForPeriodQuery }
    >({
      query: ({ loanApplicationId, params }) => ({
        url: `/payment-history/${loanApplicationId}`,
        method: 'GET',
        params,
      }),
    }),
    getAccountTransactionsForTimePeriod: builder.query<
      Partial<VaultAccountSnapshot>[],
      { loanApplicationId: string; startDate: string; endDate: string; order: string }
    >({
      query: ({ loanApplicationId, startDate, endDate, order }) => ({
        url: `/payment-history/${loanApplicationId}/transactions`,
        method: 'GET',
        params: { startDate, endDate, order },
      }),
    }),
  }),
});

export const {
  useGetPaymentTransactionsQuery,
  useLazyGetPaymentTransactionsQuery,
  useGetAccountTransactionsForTimePeriodQuery,
} = paymentHistoryApi;

import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { LoanApplicationDto } from '@harmoney/api-interfaces';
import { AmountInput, Label } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';

import { FormSchema } from '../form-config';

interface PrimaryPurposeFormProps {
  loanApplicationData: LoanApplicationDto;
  secondaryPurposeAmount: number;
  register: UseFormRegister<FormSchema>;
}

export const PrimaryPurposeForm: React.FC<PrimaryPurposeFormProps> = ({
  loanApplicationData,
  secondaryPurposeAmount,
  register,
}) => {
  return (
    <fieldset>
      <Label className="mb-2">
        Borrow up to{' '}
        <strong>{formatCurrency(loanApplicationData?.quotePresentedAmount - (secondaryPurposeAmount || 0))}</strong> for{' '}
        {loanApplicationData?.loanApplicationPurposes[0]?.loanPurpose?.displayName?.toLowerCase()}
      </Label>
      <AmountInput {...register('primaryPurposeAmount')} maxLength={6} />
    </fieldset>
  );
};

export default PrimaryPurposeForm;

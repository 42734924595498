import Link from 'next/link';
import { useEffect, useMemo, useState } from 'react';
import {
  AML_DECISION_TYPE_ID,
  BIOMETRIC_DECISION_TYPE_ID,
  DISBURSEMENT_DECISION_TYPE_ID,
  DisbursementDto,
} from '@harmoney/api-interfaces';
import {
  useGetAuditDecisionsByLoanApplicationIdQuery,
  useGetAvailableAuditDecisionTypesQuery,
  useSaveAuditDecisionMutation,
} from '@harmoney/redux';
import {
  Badge,
  Button,
  CollapsibleCard,
  Form,
  Label,
  Textarea,
  ToggleGroup,
  useForm,
} from '@harmoney/ui-design-system';
import { errors } from '@harmoney/ui-utils';
import { AuditDecisionOption } from '@prisma/client';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { z } from 'zod';

import { Field } from '../shared';

dayjs.extend(LocalizedFormat);

export interface DisbursementDecisionProps {
  loanApplicationId: string;
  userId: string;
  disbursalData: DisbursementDto[];
}

const formatOptions = (auditDecisionOptions: AuditDecisionOption[] = []) =>
  auditDecisionOptions.map((option) => ({
    label: option.name,
    value: option.id,
  }));

const formSchema = z.object({
  auditDecisions: z
    .object({
      optionId: z.string({ required_error: errors.defaultRequiredField }),
    })
    .array(),
  note: z.string().min(1, { message: 'Please enter the reason for decision' }),
});

export const DisbursementDecision = ({ userId, loanApplicationId, disbursalData }: DisbursementDecisionProps) => {
  const frankiePortalLink = process.env.NEXT_PUBLIC_FRANKIEONE_PORTAL_LINK;

  const { data: availableQuestions, isLoading: isAvailableQuestionsLoading } = useGetAvailableAuditDecisionTypesQuery(
    {
      userId,
      applicationId: loanApplicationId,
    },
    { skip: !userId || !loanApplicationId }
  );
  const { data: answers, isLoading: isAnswerLoading } = useGetAuditDecisionsByLoanApplicationIdQuery(loanApplicationId);
  const [saveAuditDecision] = useSaveAuditDecisionMutation();

  const [showCheckStatus, setShowCheckStatus] = useState({
    AML: false,
    biometric: false,
  });

  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues: {
      auditDecisions: [],
      note: '',
    },
  });

  const disbursalTransaction = useMemo(() => {
    if (!disbursalData || disbursalData.length === 0) {
      return null;
    }
    return disbursalData[0];
  }, [disbursalData]);

  const {
    register,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;

  const getStyleForAuditAnswer = (answer) => {
    if (answer.auditDecisionTypeId !== DISBURSEMENT_DECISION_TYPE_ID) {
      return 'text-body first-letter:capitalize';
    }

    if (answer.auditDecisionOption.value === 'no') {
      return 'text-error first-letter:capitalize';
    }

    if (answer.auditDecisionOption.value === 'yes') {
      return 'text-success first-letter:capitalize';
    }
  };

  useEffect(() => {
    if (!availableQuestions) {
      return;
    }

    availableQuestions.forEach((question) => {
      if (question.id === AML_DECISION_TYPE_ID) {
        setShowCheckStatus((prevState) => ({ ...prevState, AML: true }));
      }

      if (question.id === BIOMETRIC_DECISION_TYPE_ID) {
        setShowCheckStatus((prevState) => ({ ...prevState, biometric: true }));
      }
    });
  }, [availableQuestions]);

  const getAuditDecisionTypeId = (optionId: string): string | null => {
    for (const question of availableQuestions) {
      const matchingOption = question.auditDecisionOptions.find((option) => option.id === optionId);

      if (matchingOption) {
        return matchingOption.auditDecisionTypeId;
      }
    }

    return null;
  };

  const handleSubmit = async (formValues: z.infer<typeof formSchema>) => {
    const auditDecisionAnswers = {
      auditDecisions: formValues.auditDecisions.map(({ optionId }) => ({
        auditDecisionTypeId: getAuditDecisionTypeId(optionId),
        auditDecisionOptionId: optionId,
      })),
    };

    try {
      await saveAuditDecision({ loanApplicationId, note: formValues.note, ...auditDecisionAnswers });
    } catch (e) {
      console.error(e);
    }
  };

  if (isAvailableQuestionsLoading || isAnswerLoading) {
    return null;
  }

  return (
    <>
      {(!answers || answers.length === 0) &&
        (disbursalTransaction === null || disbursalTransaction.status !== 'success') && (
          <CollapsibleCard
            title="Disbursement decision"
            className="mb-6"
            description={<Badge label={'ACTION REQUIRED'} variant="action" />}
            defaultOpen={true}
          >
            <Form form={form} onSubmit={handleSubmit} className="p-6">
              {showCheckStatus.AML && (
                <span className="mb-2 flex justify-between">
                  <span>AML Check Status</span>
                  <span className="text-error">Needs attention</span>
                </span>
              )}

              {showCheckStatus.biometric && (
                <span className="mb-2 flex justify-between">
                  <span>Biometrics Check Status</span>
                  <span className="text-error">Failed, requires review</span>
                </span>
              )}

              {(showCheckStatus.AML || showCheckStatus.biometric) && (
                <span className="mb-2 flex justify-between">
                  <span>Link to admin portal:</span>
                  <Link href={`${frankiePortalLink}`} passHref>
                    FrankieOne portal
                  </Link>
                </span>
              )}

              {availableQuestions &&
                availableQuestions.length > 0 &&
                availableQuestions.map(({ id, name, auditDecisionOptions }, index) => (
                  <div className="mb-2 flex flex-col" key={id}>
                    <Label className={`mb-2 ${id === DISBURSEMENT_DECISION_TYPE_ID ? 'font-medium' : ''}`}>
                      {name}
                    </Label>
                    <ToggleGroup
                      {...register(`auditDecisions.${index}.optionId`)}
                      options={formatOptions(auditDecisionOptions)}
                    />
                  </div>
                ))}

              <Label className="mb-2 pt-2">Reason for decision</Label>
              <Textarea {...register('note')}></Textarea>
              <div className="flex justify-end pt-2">
                <Button
                  type="submit"
                  alignIcon="end"
                  variant="primary"
                  isLoading={isSubmitting || isSubmitSuccessful}
                  hasShadow
                >
                  Submit
                </Button>
              </div>
            </Form>
          </CollapsibleCard>
        )}

      {(!answers || answers.length === 0) && disbursalTransaction && disbursalTransaction.status === 'success' && (
        <CollapsibleCard
          title="Disbursement decision"
          className="mb-6"
          description={`this is a loan disbursed before disbursal decision feature being released`}
          defaultOpen={true}
        >
          <div className="p-6">No disbursal decision made for this loan application.</div>
        </CollapsibleCard>
      )}

      {answers && answers.length !== 0 && (
        <CollapsibleCard
          title="Disbursement decision"
          className="mb-6"
          description={`Submitted by ${answers[0]?.user.email} at ${dayjs(answers[0]?.createdAt).format('LLL')}`}
          defaultOpen={true}
        >
          <div className="p-6">
            {showCheckStatus.AML && (
              <Field>
                <span>AML Check Status</span>
                <span>Needs attention</span>
              </Field>
            )}

            {showCheckStatus.biometric && (
              <Field>
                <span>Biometrics Check Status</span>
                <span>Failed, requires review </span>
              </Field>
            )}

            {answers.map((answer) => (
              <Field key={answer.id}>
                <span>{answer.auditDecisionType.name}</span>
                <span className={getStyleForAuditAnswer(answer)}>{answer.auditDecisionOption.name}</span>
              </Field>
            ))}

            <Field>
              <Label>Reason for decision</Label>
              <span>{answers[0]?.note}</span>
            </Field>
          </div>
        </CollapsibleCard>
      )}
    </>
  );
};

import { generateIcon } from '../generateIcon';

/**
 * @deprecated Check if it can be replaced with Iconify. If not, add it to icon.json.
 * Refer to icon document for details: https://63be80b51f526a24fc7865c5-tslqcdnosl.chromatic.com/?path=/docs/docs-icons--docs
 */
export const VerificationSuccessIcon = generateIcon({
  viewBox: '0 0 82 82',
  variant: 'none',
  path: (
    <>
      <circle cx="41" cy="41" r="39.8" stroke="#45D06C" stroke-width="2.4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.0278 28.4786C62.4618 27.9778 62.4077 27.2199 61.9069 26.7859C61.406 26.3518 60.6482 26.406 60.2141 26.9068L36.6443 54.1027L23.5936 39.3119C23.1551 38.8149 22.3968 38.7675 21.8998 39.206C21.4029 39.6445 21.3555 40.4028 21.794 40.8998L35.2061 56.1001C35.3223 56.2319 35.461 56.332 35.6114 56.3999C35.6803 56.5432 35.779 56.6751 35.9069 56.7859C36.4077 57.2199 37.1656 57.1658 37.5996 56.665L62.0278 28.4786Z"
        fill="#45D06C"
      />
    </>
  ),
});

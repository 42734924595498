import { BranchRecord } from '@harmoney/api-interfaces';
import { BranchEnum } from '@prisma/client';

interface FooterProps {
  branch: BranchEnum;
}

export const Footer = ({ branch }: FooterProps) => {
  const footerContent: BranchRecord<string> = {
    [BranchEnum.AU]:
      'Harmoney Australia Pty Ltd ABN 12 604 342 823, holds Australian Credit Licence Number 474726 and Australian Financial Services Licence Number 474726. Harmoney Australia Pty Ltd and Harmoney Services Australia Pty Ltd are part of the Harmoney Corp Limited group.',

    [BranchEnum.NZ]:
      'Harmoney Services Limited (FSP593769) is registered under the Financial Service Providers (Registration and Dispute Resolution) Act 2008.',
  };

  return (
    <footer className="sticky top-full bottom-0 bg-inherit p-4 mx-auto max-w-xl my-auto ">
      <p className="text-xs text-center text-grey-4">{footerContent[branch]}</p>
    </footer>
  );
};

import { ChangeEvent, useEffect, useState } from 'react';
import { AccommodationExpenseEnum, NetworthSourceEnum, ResidentialStatusEnum } from '@harmoney/api-interfaces';
import { FinancialConfirmationSectionEnum, useFinancialConfirm, useFriendlyURL } from '@harmoney/hooks';
import {
  useAppSelector,
  useGetUserProfileQuery,
  useSaveExpensesMutation,
  useSubmitIncomeMutation,
  useUpdateAddressMutation,
  useUpdateUserProfileMutation,
} from '@harmoney/redux';
import { eventAnalytics, FINANCIALPROFILE_LIVING_SITUATION_PROVIDED } from '@harmoney/ui-app-shell';
import { ArrowCircleRightIcon, Button, Card, Form, RadioSelectSelector, useForm } from '@harmoney/ui-design-system';
import { otherLivingSituationOptions, primaryLivingSituationOptions, stringToBool } from '@harmoney/ui-utils';
import { useScrollIntoView } from '@mantine/hooks';
import { IncomeAndExpenseFrequencyEnum, RelationshipStatusEnum } from '@prisma/client';
import { isEmpty } from 'lodash';

import { CommonProps } from '../../common-props';

import { AccommodationExpenseCard, ChildSupportCard, DependantsCard } from './components';
import { getNetWorthSourceId, shouldDisplaySharedExpense } from './form.util';
import {
  defaultValues,
  formSchema,
  FormSchemaTypeEnum,
  householdV2FormFieldKeys,
  sortedHouseholdV2FormFieldKeys,
} from './form-config';

type HouseholdV2Props = {
  prefilledData?: any;
  onCancel?: () => void;
} & CommonProps;

export function HouseholdV2({
  taskId,
  taskFriendlyURL,
  prefilledData,
  onCancel,
  completeTaskWithData,
}: HouseholdV2Props) {
  const userId = useAppSelector((state) => state.userId.value);
  const { confirmSection } = useFinancialConfirm();
  const [isSubmitting, setIsSubmitting] = useState({ update: false, cancel: false });
  const { scrollIntoView: scrollToAccomExpenses, targetRef: accomExpensesRef } = useScrollIntoView<HTMLDivElement>({
    offset: 100,
  });
  const { scrollIntoView: scrollToChildSupport, targetRef: childSupportRef } = useScrollIntoView<HTMLDivElement>({
    offset: 100,
  });
  const { scrollIntoView: scrollToDependants, targetRef: dependantsRef } = useScrollIntoView<HTMLDivElement>({
    offset: 100,
  });

  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues,
  });

  const {
    register,
    watch,
    resetField,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = form;
  const formChanges = watch();
  const watchAccommodationExpense = watch('accommodationExpense');

  const { data: userProfile } = useGetUserProfileQuery(null, { refetchOnMountOrArgChange: true });
  const [saveExpenses] = useSaveExpensesMutation();
  const [submitIncome] = useSubmitIncomeMutation();
  const [updateAddress] = useUpdateAddressMutation();
  const [updateUserProfile] = useUpdateUserProfileMutation();

  useEffect(() => {
    if (!isEmpty(errors)) {
      const firstFormFieldKeyWithError = sortedHouseholdV2FormFieldKeys.find((formFieldKey) =>
        Object.keys(errors).includes(formFieldKey)
      );
      if (firstFormFieldKeyWithError === householdV2FormFieldKeys.ACCOMMODATION_EXPENSE) {
        scrollToAccomExpenses();
      } else if (firstFormFieldKeyWithError === householdV2FormFieldKeys.DEPENDANTS) {
        scrollToDependants();
      } else if (firstFormFieldKeyWithError === householdV2FormFieldKeys.CHILD_SUPPORT) {
        scrollToChildSupport();
      }
    }
  }, [errors]);

  useEffect(() => {
    setValue('type', FormSchemaTypeEnum.BASE);
    setValue('accommodationExpense.relationshipStatus', userProfile?.relationshipStatus);
  }, [setValue, userProfile?.relationshipStatus]);

  useEffect(() => {
    const updateLivingTypeAndSituationFormValuesOnChange = () => {
      const { situation } = watchAccommodationExpense || {};
      setValue('accommodationExpense.livingType', formChanges?.livingType?.primary);
      setValue('accommodationExpense.situation', situation);
    };

    updateLivingTypeAndSituationFormValuesOnChange();
  }, [setValue, formChanges?.livingType?.primary, watchAccommodationExpense]);

  useEffect(() => {
    if (!prefilledData || isSubmitting.update) return;
    reset(prefilledData);
    if (prefilledData.dependants?.hasDependants === 'Yes') {
      setValue('type', FormSchemaTypeEnum.ACCOMMODATION_EXPENSE_WITH_CHILD_SUPPORT);
    } else {
      setValue('type', FormSchemaTypeEnum.ACCOMMODATION_EXPENSE_WITHOUT_CHILD_SUPPORT);
    }
  }, [prefilledData, setValue, reset, isSubmitting]);

  const isAccommodationExpenseFormType =
    formChanges?.type === FormSchemaTypeEnum.ACCOMMODATION_EXPENSE_WITH_CHILD_SUPPORT ||
    formChanges?.type === FormSchemaTypeEnum.ACCOMMODATION_EXPENSE_WITHOUT_CHILD_SUPPORT;

  const isChildSupportRelatedFormType =
    formChanges?.type === FormSchemaTypeEnum.CHILD_SUPPORT ||
    formChanges?.type === FormSchemaTypeEnum.ACCOMMODATION_EXPENSE_WITH_CHILD_SUPPORT;

  const handleFormChange = (event: ChangeEvent<HTMLButtonElement | HTMLSelectElement>) => {
    const target = event.target;
    const { name, value } = target;
    const hasDependants =
      stringToBool(formChanges?.dependants?.hasDependants) ||
      (name === 'dependants.hasDependants' && value.toLowerCase() === 'yes');
    const isHomeOwner =
      formChanges?.livingType?.primary === ResidentialStatusEnum.HOME_OWNER ||
      (name === 'livingType.primary' && value === ResidentialStatusEnum.HOME_OWNER);

    setValue('accommodationExpense.livingType', formChanges?.livingType?.primary);
    setValue('dependants.livingType', formChanges?.livingType?.primary);

    if (!isHomeOwner && (name === 'livingType.primary' || name === 'livingType.secondary')) {
      resetField('accommodationExpense.situation', { keepError: false });
      resetField('accommodationExpense.declaredAmount', { keepError: false });
      resetField('accommodationExpense.declaredFrequency', { keepError: false });
      resetField('accommodationExpense.declaredTotalAmount', { keepError: false });
      resetField('accommodationExpense.declaredTotalFrequency', { keepError: false });
    }

    setValue('type', determineFormSchemaType(isHomeOwner, hasDependants));
  };

  const determineFormSchemaType = (isHomeOwner: boolean, hasDependants: boolean) => {
    if (isHomeOwner) return hasDependants ? FormSchemaTypeEnum.CHILD_SUPPORT : FormSchemaTypeEnum.BASE;
    return hasDependants
      ? FormSchemaTypeEnum.ACCOMMODATION_EXPENSE_WITH_CHILD_SUPPORT
      : FormSchemaTypeEnum.ACCOMMODATION_EXPENSE_WITHOUT_CHILD_SUPPORT;
  };

  const handleSubmit = async (data) => {
    try {
      setIsSubmitting({ update: true, cancel: false });
      const activeAddressId = getActiveAddressId();
      const updatedCurrentAddress = getUpdatedCurrentAddress(data, activeAddressId);
      const updatedUserProfile = getUpdatedUserProfile(data);

      await updateUserProfile({ data: updatedUserProfile }).unwrap();
      await updateAddress({ id: activeAddressId, data: updatedCurrentAddress });

      const expensesData = getExpensesData(data, activeAddressId);
      await saveExpenses({ taskId, expenses: expensesData }).unwrap();
      if (stringToBool(data?.childSupport?.hasChildSupportReceived)) {
        await submitChildSupportIncome(data).unwrap();
      } else {
        const childSupportIncomeId = prefilledData?.childSupport?.childSupportIncomeId || undefined;
        if (childSupportIncomeId) {
          await submitIncome({
            taskId,
            incomes: [
              {
                id: childSupportIncomeId,
                needDelete: true,
                networthSourceId: NetworthSourceEnum.INCOME_CHILD_SUPPORT_ID,
              },
            ],
          }).unwrap();
        }
      }

      eventAnalytics.track(FINANCIALPROFILE_LIVING_SITUATION_PROVIDED, {
        userid_str: userId,
        taskid_str: taskId,
      });

      completeTaskWithData && (await completeTaskWithData({ taskId }));
      if (prefilledData) {
        confirmSection(FinancialConfirmationSectionEnum.LivingSituation);
      }
    } catch (error) {
      console.error(`Failed to complete task [taskId: ${taskId}], error: ${error}`);
      setIsSubmitting({ update: false, cancel: false });
    }
  };

  const handleCancel = async () => {
    try {
      setIsSubmitting({ update: false, cancel: true });
      await onCancel();
    } catch (error) {
      console.error(`Failed to complete task [taskId: ${taskId}], error: ${error}`);
      setIsSubmitting({ update: false, cancel: false });
    }
  };

  const getActiveAddressId = () => {
    const currentAddress = userProfile?.addresses?.find((address) => address.isCurrent);
    return currentAddress?.id;
  };

  const getUpdatedCurrentAddress = (data, activeAddressId: string) => {
    const currentAddress = userProfile?.addresses?.find((address) => address.id === activeAddressId);

    return {
      ...currentAddress,
      residentialStatus: data.livingType.primary,
    };
  };

  const getUpdatedUserProfile = (data) => {
    return {
      numberOfDependants: stringToBool(data?.dependants?.hasDependants) ? +data?.dependants?.numberOfDependants : 0,
    };
  };

  const getExpensesData = (data, activeAddressId: string) => {
    const expensesData = [];
    const { livingType, accommodationExpense, childSupport } = data || {};

    if (!livingType) return expensesData;

    const networthSourceId = getNetWorthSourceId(livingType.primary);

    if (livingType.primary !== ResidentialStatusEnum.HOME_OWNER && accommodationExpense) {
      const { situation, declaredFrequency, declaredAmount, declaredTotalAmount, declaredTotalFrequency } =
        accommodationExpense;
      const isExpenseShared = situation === AccommodationExpenseEnum.SHARED;

      const expenseData = {
        networthSourceId,
        addressId: activeAddressId,
        isExpenseShared,
        id: prefilledData?.accommodationExpense?.id || undefined,
      };

      if (isExpenseShared) {
        expensesData.push({
          ...expenseData,
          declaredAmount: declaredAmount || undefined,
          frequency: declaredFrequency || undefined,
          declaredTotalAmount: declaredTotalAmount || undefined,
          frequencyForTotalAmount: declaredTotalFrequency || undefined,
        });
      } else {
        if (situation === AccommodationExpenseEnum.NO_COVERAGE) {
          expensesData.push({
            ...expenseData,
            declaredAmount: null,
            frequency: null,
            declaredTotalAmount: null,
            frequencyForTotalAmount: null,
          });
        } else {
          expensesData.push({
            ...expenseData,
            declaredAmount: declaredAmount || undefined,
            frequency: declaredFrequency || undefined,
            declaredTotalAmount: null,
            frequencyForTotalAmount: null,
          });
        }
      }
    } else {
      const accommodationExpenseId = prefilledData?.accommodationExpense?.id || undefined;
      if (accommodationExpenseId) {
        expensesData.push({
          needDelete: true,
          id: accommodationExpenseId,
        });
      }
    }

    const childSupportExpenseId = prefilledData?.childSupport?.id || undefined;
    if (!childSupport) {
      if (childSupportExpenseId) {
        expensesData.push({
          needDelete: true,
          id: childSupportExpenseId,
        });
      }
      return expensesData;
    }

    if (stringToBool(childSupport.hasChildSupportPaid)) {
      expensesData.push({
        networthSourceId: NetworthSourceEnum.EXPENSE_CHILD_SUPPORT_ID,
        declaredAmount: childSupport.childSupportPaidAmount,
        frequency: childSupport.childSupportPaidFrequency as IncomeAndExpenseFrequencyEnum,
        deductedFromIncome: stringToBool(childSupport.hasChildSupportDeducted),
        id: childSupportExpenseId,
      });
    } else {
      if (childSupportExpenseId) {
        expensesData.push({
          needDelete: true,
          id: childSupportExpenseId,
        });
      }
    }

    return expensesData;
  };

  const submitChildSupportIncome = (data) => {
    const id = prefilledData?.childSupport?.childSupportIncomeId || undefined;
    return submitIncome({
      taskId,
      incomes: [
        {
          id,
          networthSourceId: NetworthSourceEnum.INCOME_CHILD_SUPPORT_ID,
          declaredAmount: data?.childSupport?.childSupportReceivedAmount,
          frequency: data?.childSupport?.childSupportReceivedFrequency as IncomeAndExpenseFrequencyEnum,
        },
      ],
    });
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      <h1>
        Tell us about your <span className="text-primary">household</span>
      </h1>

      <Form form={form} onSubmit={handleSubmit}>
        <Card>
          <RadioSelectSelector
            name="livingType"
            label="What is your living situation?"
            radioOptions={primaryLivingSituationOptions}
            selectOptions={otherLivingSituationOptions}
            onChange={handleFormChange}
          />
        </Card>
        {formChanges?.livingType?.primary && (
          <>
            {isAccommodationExpenseFormType &&
              formChanges?.livingType?.primary !== ResidentialStatusEnum.HOME_OWNER && (
                <div ref={accomExpensesRef}>
                  <AccommodationExpenseCard
                    register={register}
                    livingType={formChanges?.livingType?.primary as ResidentialStatusEnum}
                    watchAccommodationExpense={watchAccommodationExpense}
                    shouldDisplaySharedExpense={shouldDisplaySharedExpense(
                      userProfile?.relationshipStatus as RelationshipStatusEnum
                    )}
                  />
                </div>
              )}
            <div ref={dependantsRef}>
              <DependantsCard
                register={register}
                hasDependants={stringToBool(formChanges?.dependants?.hasDependants)}
                onFormChange={handleFormChange}
              />
            </div>

            {isChildSupportRelatedFormType && stringToBool(formChanges?.dependants?.hasDependants) && (
              <div ref={childSupportRef}>
                <ChildSupportCard
                  register={register}
                  hasChildSupportReceived={stringToBool(formChanges?.childSupport?.hasChildSupportReceived)}
                  hasChildSupportPaid={stringToBool(formChanges?.childSupport?.hasChildSupportPaid)}
                />
              </div>
            )}
          </>
        )}
        <div className="flex flex-col gap-2 items-center">
          <Button
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
            variant="primary"
            isLoading={isSubmitting.update}
            disabled={isSubmitting.cancel}
            hasShadow
            type="submit"
          >
            {prefilledData ? 'Save' : 'Continue'}
          </Button>
          {prefilledData && (
            <Button
              variant="tertiary"
              onClick={handleCancel}
              isLoading={isSubmitting.cancel}
              disabled={isSubmitting.update}
            >
              Cancel
            </Button>
          )}
        </div>
      </Form>
    </>
  );
}

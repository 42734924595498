import React from 'react';

import { CommonProps } from '../../common-props';
import { Wizard } from '../../Wizard';
import LoanAgreementGeneralTerms from '../LoanAgreementGeneralTerms/LoanAgreementGeneralTerms';
import LoanDisclosure from '../LoanAgreementGeneralTerms/LoanDisclosure/LoanDisclosure';
import { Payment } from '../Payment/Payment';

export function LoanDocWizard({ ...props }: CommonProps) {
  const components = [
    { component: Payment, urlPart: 'payment' },
    { component: LoanAgreementGeneralTerms, urlPart: 'loan-agreement-general-terms' },
    { component: LoanDisclosure, urlPart: 'loan-agreement-details' },
  ];

  return (
    <Wizard>
      {components.map(({ component: Component, urlPart }, index) => (
        <Component key={index} {...props} taskFriendlyURL={`${props.taskFriendlyURL}/${urlPart}`} />
      ))}
    </Wizard>
  );
}

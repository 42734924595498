import { useEffect } from 'react';
import { useFriendlyURL } from '@harmoney/hooks';
import { DeclinePage, DeclineTypeEnum } from '@harmoney/ui-design-system';

import { CommonProps } from '../../common-props';

export interface DeclineProps extends CommonProps {
  type: DeclineTypeEnum;
}

export function Decline({ taskId, type, completeTask, taskFriendlyURL }: DeclineProps) {
  useEffect(() => {
    return () => {
      if (type === DeclineTypeEnum.POST_QUOTE) {
        return;
      }
      completeTask({ taskId });
    };
  }, [type, taskId, completeTask]);

  useFriendlyURL(taskFriendlyURL);

  return <DeclinePage type={type} />;
}

export function PreQuoteDecline({ taskId, loanProductData, completeTask, taskFriendlyURL }) {
  return (
    <Decline
      completeTask={completeTask}
      type={DeclineTypeEnum.PRE_QUOTE}
      taskId={taskId}
      loanProductData={loanProductData}
      taskFriendlyURL={taskFriendlyURL}
    />
  );
}

export function PostQuoteDecline({ taskId, loanProductData, completeTask, taskFriendlyURL }) {
  return (
    <Decline
      completeTask={completeTask}
      type={DeclineTypeEnum.POST_QUOTE}
      taskId={taskId}
      loanProductData={loanProductData}
      taskFriendlyURL={taskFriendlyURL}
    />
  );
}

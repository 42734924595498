import DOMPurify from 'dompurify';

export interface SanitizeContentProps {
  content: string;
  tag?: string;
  className?: string;
  testId?: string;
}

export const sanitizeContent = ({ content, className, testId, tag = 'p' }: SanitizeContentProps) => {
  const sanitizedTemplate = DOMPurify.sanitize(content);
  const Element = tag as keyof JSX.IntrinsicElements;
  return <Element className={className} data-testid={testId} dangerouslySetInnerHTML={{ __html: sanitizedTemplate }} />;
};

import React, { useEffect } from 'react';
import { EssentialExpenseDetailDto, essentialExpenseMapper, NetworthSourceEnum } from '@harmoney/api-interfaces';
import {
  Alert,
  AmountFrequency,
  ArrowCircleRightIcon,
  Button,
  ControlledInput,
  Dialog,
  Form,
  ToggleGroup,
  useForm,
} from '@harmoney/ui-design-system';
import { frequencyOptions, stringToBool, toggleYesNoOptions } from '@harmoney/ui-utils';
import { convertToMonthlyAmount } from '@harmoney/utilities';
import { IncomeAndExpenseFrequencyEnum, NetworthSource } from '@prisma/client';

import {
  errorMessageForAmountComparison,
  FormSchema,
  formSchema,
  FormSchemaTypeEnum,
  FormSchemaWithSharedExpense,
  getInitialDefaultValues,
  prePopulateDefaultValues,
} from '../form-config';

interface ExpenseFormModalProps {
  expenseFormDetail: EssentialExpenseDetailDto;
  isFormModalOpen: boolean;
  isEditable: boolean;
  onFormModalOpen: (state: boolean) => void;
  onExpenseRemove: (id: string) => void;
  onSubmit: (data: FormSchema) => void;
  isCustomerFacing?: boolean;
  expenseCategories: NetworthSource[];
}

export const ExpenseFormModal: React.FC<ExpenseFormModalProps> = ({
  expenseFormDetail,
  isFormModalOpen,
  isEditable,
  onFormModalOpen,
  onSubmit,
  onExpenseRemove,
  isCustomerFacing = true,
  expenseCategories,
}) => {
  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues: getInitialDefaultValues(expenseFormDetail),
  });

  const {
    register,
    watch,
    reset,
    resetField,
    setValue,
    setError,
    clearErrors,
    trigger,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;
  const formChanges = watch();

  const isRentalExpense = expenseFormDetail.networthSourceId === NetworthSourceEnum.EXPENSE_RENT_ID;

  const isExpenseNotListed = expenseFormDetail.networthSourceId === NetworthSourceEnum.EXPENSE_NOT_LISTED_ID;
  const modalTitle =
    isExpenseNotListed && isEditable
      ? expenseFormDetail.otherExpenseType
      : essentialExpenseMapper[expenseFormDetail.networthSourceId];

  const handleSharedExpenseToggleChange = () => {
    resetField('withSharedExpense.declaredTotalAmount', { defaultValue: null });
    resetField('withSharedExpense.frequencyForTotalAmount', { defaultValue: '' });
    resetField('withSharedExpense.declaredAmount');
    resetField('withoutSharedExpense.declaredAmount');
  };

  const handleSubmitClick = async () => {
    if (isEditable) {
      const isValid = await trigger();
      isValid && onSubmit(form.getValues());
    }
  };

  useEffect(() => {
    const defaultValuesFunction = isEditable ? prePopulateDefaultValues : getInitialDefaultValues;
    reset(defaultValuesFunction(expenseFormDetail));
  }, [expenseFormDetail, isEditable, reset]);

  useEffect(() => {
    if (isRentalExpense) {
      setValue('type', FormSchemaTypeEnum.WITHOUT_SHARED_EXPENSE);
      setValue('isExpenseShared', 'No');
      return;
    }
    if (!formChanges.isExpenseShared) return;

    const isShared = stringToBool(formChanges.isExpenseShared);

    if (isExpenseNotListed) {
      setValue(
        'type',
        isShared
          ? FormSchemaTypeEnum.EXPENSE_NOT_LISTED_WITH_SHARED_EXPENSE
          : FormSchemaTypeEnum.EXPENSE_NOT_LISTED_WITHOUT_SHARED_EXPENSE
      );
    } else {
      setValue('type', isShared ? FormSchemaTypeEnum.WITH_SHARED_EXPENSE : FormSchemaTypeEnum.WITHOUT_SHARED_EXPENSE);
    }
  }, [formChanges.isExpenseShared, isExpenseNotListed, setValue, isRentalExpense]);

  useEffect(() => {
    const handleAmountComparisonError = () => {
      if (
        formChanges.type === FormSchemaTypeEnum.WITH_SHARED_EXPENSE &&
        formChanges.withSharedExpense.declaredAmount &&
        formChanges.withSharedExpense.declaredTotalAmount &&
        convertToMonthlyAmount(
          formChanges.withSharedExpense.declaredAmount,
          formChanges.withSharedExpense.frequency as IncomeAndExpenseFrequencyEnum
        ) >=
          convertToMonthlyAmount(
            formChanges.withSharedExpense.declaredTotalAmount,
            formChanges.withSharedExpense.frequencyForTotalAmount as IncomeAndExpenseFrequencyEnum
          )
      ) {
        setError('withSharedExpense.declaredAmount', {
          type: 'custom',
          message: errorMessageForAmountComparison,
        });
      } else {
        clearErrors('withSharedExpense.declaredAmount');
      }
    };
    handleAmountComparisonError();
  }, [
    clearErrors,
    formChanges.type,
    (formChanges as FormSchemaWithSharedExpense).withSharedExpense.declaredAmount,
    (formChanges as FormSchemaWithSharedExpense).withSharedExpense.declaredTotalAmount,
    (formChanges as FormSchemaWithSharedExpense).withSharedExpense.frequency,
    (formChanges as FormSchemaWithSharedExpense).withSharedExpense.frequencyForTotalAmount,
    setError,
  ]);

  return (
    <Dialog title={modalTitle} open={isFormModalOpen} onOpenChange={onFormModalOpen} modal>
      <div className="p-4">
        {!isCustomerFacing && (
          <p>
            Expense category:{' '}
            <span className="font-medium capitalize">
              {expenseCategories.find(({ id }) => id === formChanges.networthSourceId).code}
            </span>
          </p>
        )}
        {isExpenseNotListed && (
          <>
            <Alert variant="info" className="mb-4">
              <p className="text-sm">Add your essentials such as donations, private school fees etc.</p>
              <p className="text-sm">
                Don&rsquo;t add your mortgage, credit card or loan repayments. We&rsquo;ll discuss debts later.
              </p>
            </Alert>
          </>
        )}
        <Form form={form} onSubmit={onSubmit}>
          {isExpenseNotListed && (
            <ControlledInput
              {...register('expenseNotListed.otherExpenseType')}
              label="What expense is it?"
              className="mb-4"
            />
          )}
          {!isRentalExpense && (
            <ToggleGroup
              {...register('isExpenseShared', { onChange: handleSharedExpenseToggleChange })}
              options={toggleYesNoOptions}
              label="Do you share this expense?"
            />
          )}
          {(formChanges.isExpenseShared || isRentalExpense) && (
            <>
              {stringToBool(formChanges.isExpenseShared) ? (
                <div className="mt-4">
                  <AmountFrequency
                    register={register}
                    inputKey="withSharedExpense.declaredTotalAmount"
                    selectKey="withSharedExpense.frequencyForTotalAmount"
                    name="shared-expense-total-amount"
                    label="Total cost"
                    options={frequencyOptions}
                  />
                  <AmountFrequency
                    register={register}
                    inputKey="withSharedExpense.declaredAmount"
                    selectKey="withSharedExpense.frequency"
                    name="shared-expense-declared-amount"
                    label="Your share"
                    options={frequencyOptions}
                    className="mt-4"
                  />
                </div>
              ) : (
                <AmountFrequency
                  register={register}
                  inputKey="withoutSharedExpense.declaredAmount"
                  selectKey="withoutSharedExpense.frequency"
                  name="declared-amount"
                  label={isCustomerFacing ? 'How much do you pay?' : "Customer's share"}
                  options={frequencyOptions}
                  className="mt-4"
                />
              )}
            </>
          )}
          <div className="shrink-1 mb-4 mt-6 flex w-full flex-col items-center justify-start gap-2 sm:flex-col md:flex-row-reverse">
            <Button
              type="submit"
              variant="primary"
              className="sm:!min-w-full md:!min-w-fit md:!max-w-fit"
              alignIcon="end"
              icon={<ArrowCircleRightIcon size="large" />}
              isLoading={isSubmitting || isSubmitSuccessful}
              onClick={handleSubmitClick}
            >
              {isEditable ? 'Save' : 'Add'}
            </Button>
            <Button
              variant="secondary"
              className="!min-w-fit !max-w-fit"
              onClick={() => onExpenseRemove(expenseFormDetail.id)}
            >
              {isEditable ? 'Remove' : 'Cancel'}
            </Button>
          </div>
        </Form>
      </div>
    </Dialog>
  );
};

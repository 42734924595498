import Script from 'next/script';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

type Props = {
  GTM_ID: string;
};

export const GTMScript = ({ GTM_ID }: Props) => {
  return (
    <Script id="google-tag-manager" strategy="afterInteractive">
      {`
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '',
          domain = w.location.hostname.includes('nz') ? 'analytics.harmoney.co.nz' : 'analytics.harmoney.com.au';
        j.async = true;
        j.src = 'https://' + domain + '/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${GTM_ID}');
      `}
    </Script>
  );
};

import { useMemo } from 'react';
import { LoanApplicationRepaymentDetailDto } from '@harmoney/api-interfaces';
import { CommonOptionProps, ControlledSelect, Label, useFormContext } from '@harmoney/ui-design-system';

import { normalizeBankAccount } from '../utils';

import { BankAccountConnectionIssueAlert } from './BankAccountConnectIssueAlert';

type BankAccountType = 'loanToBankAccount' | 'repaymentFromBankAccount';

interface BankAccountSelectorProps {
  repaymentDetail: LoanApplicationRepaymentDetailDto;
  bankAccountType: BankAccountType;
}

const BankAccountSelectorLabel = ({ bankAccountType }: { bankAccountType: BankAccountType }) =>
  bankAccountType === 'loanToBankAccount' ? (
    <Label htmlFor="loanToBankAccount" className="font-medium mb-2">
      Paid to
    </Label>
  ) : (
    <>
      <Label htmlFor="repaymentFromBankAccount" className="font-medium mb-2">
        Repaid from
      </Label>
      <p className="text-sm text-grey-4">We will automatically set up recurring repayments via direct debit.</p>
    </>
  );

export const BankAccountSelector = ({ repaymentDetail, bankAccountType }: BankAccountSelectorProps) => {
  const { register } = useFormContext();
  const bankAccountOptions = useMemo(() => {
    const options: CommonOptionProps[] = [{ label: 'Select a bank account', value: '', disabled: true }];
    if (!repaymentDetail || !repaymentDetail.bankAccounts) return options;

    const bankAccounts: CommonOptionProps[] = repaymentDetail?.bankAccounts?.map(
      ({ accountNumber, bankName, bsb, bankLogoUrl }) => {
        return {
          imageSrc: bankLogoUrl,
          label: (
            <span className="flex flex-col">
              <span>{bankName}</span>
              <span className="text-sm">{normalizeBankAccount(bsb, accountNumber)}</span>
            </span>
          ),
          value: normalizeBankAccount(bsb, accountNumber),
          width: 100,
        };
      }
    );
    return [...options, ...bankAccounts];
  }, [repaymentDetail]);

  if (bankAccountOptions && bankAccountOptions.length <= 1) {
    return <BankAccountConnectionIssueAlert />;
  }

  return (
    <>
      <BankAccountSelectorLabel bankAccountType={bankAccountType} />
      {bankAccountOptions && <ControlledSelect {...register(bankAccountType)} options={bankAccountOptions} />}
    </>
  );
};

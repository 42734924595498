import React from 'react';
import { useAppSelector, useGetLoanApplicationCountQuery } from '@harmoney/redux';
import { Button } from '@harmoney/ui-design-system';
import { LoanApplicationStatusEnum } from '@prisma/client';

export const ApplicationsCount = () => {
  const token = useAppSelector((state) => state.accessToken.value);
  const { data, isLoading, isError, refetch } = useGetLoanApplicationCountQuery(null, {
    skip: !token,
  });

  if (isLoading) {
    return (
      <div className="my-16 animate-pulse justify-center text-center">
        Counting the number of Applications for you, a moment please
      </div>
    );
  }

  if (isError) {
    return (
      <div className="my-16 flex flex-col items-center justify-center gap-4 text-center">
        <span>Error while counting the number of Applications</span>
        <Button className="ml-4" onClick={() => refetch()} variant="primary" size="small">
          Retry
        </Button>
      </div>
    );
  }

  return (
    <>
      <div className="mt-4 grid grid-cols-12 grid-rows-6 items-center gap-y-1 text-right">
        <div className="col-span-4 row-start-1">{''}</div>
        <div className="bg-secondary-lighter-3 col-span-4 row-start-2 text-left font-medium">Applications</div>
        <div className="col-span-4 row-start-3 text-left ">⏳ (Quote in progress)</div>
        <div className="bg-secondary-lighter-3 col-span-4 row-start-4 text-left ">🤔 (Quote in review)</div>
        <div className="col-span-4 row-start-5 text-left">
          😢 (Quote and Applications cancelled | unsuccessful | expired)
        </div>
        <div className="bg-secondary-lighter-3 col-span-4 row-start-6 text-left">
          🚀 (Quote offered + Application in progress)
        </div>
        <div className="col-span-4 row-start-7 text-left">💰 (Application successful)</div>
        {data &&
          Object.entries(data).map(([key, value]) => (
            <>
              <div className="font-medium capitalize">{key.replace('-', ' -')}</div>
              <div className="bg-secondary-lighter-3 row-start-2">
                {Object.values(value).reduce((acc, curr) => acc + curr, 0)}
              </div>
              <div className="row-start-3">
                {Object.entries(value)
                  .filter(([key]) => key === LoanApplicationStatusEnum.quote_in_progress)
                  .reduce((acc, [_, value]) => acc + value, 0)}
              </div>
              <div className="bg-secondary-lighter-3 row-start-4">
                {Object.entries(value)
                  .filter(([key]) => key === LoanApplicationStatusEnum.quote_in_review)
                  .reduce((acc, [_, value]) => acc + value, 0)}
              </div>
              <div className="row-start-5">
                {Object.entries(value)
                  .filter(
                    ([key]) =>
                      key === LoanApplicationStatusEnum.quote_unsuccessful ||
                      key === LoanApplicationStatusEnum.quote_expired ||
                      key === LoanApplicationStatusEnum.quote_cancelled ||
                      key === LoanApplicationStatusEnum.application_cancelled ||
                      key === LoanApplicationStatusEnum.application_expired ||
                      key === LoanApplicationStatusEnum.application_unsuccessful
                  )
                  .reduce((acc, [_, value]) => acc + value, 0)}
              </div>
              <div className="bg-secondary-lighter-3 row-start-6">
                {Object.entries(value)
                  .filter(
                    ([key]) =>
                      key === LoanApplicationStatusEnum.quote_offered ||
                      key === LoanApplicationStatusEnum.application_in_progress
                  )
                  .reduce((acc, [_, value]) => acc + value, 0)}
              </div>
              <div className="row-start-7">
                {Object.entries(value)
                  .filter(([key]) => key === LoanApplicationStatusEnum.application_successful)
                  .reduce((acc, [_, value]) => acc + value, 0)}
              </div>
            </>
          ))}
      </div>
      <div className="text-grey-4 mt-2 text-right text-xs">(Date of event) - (Based on Sydney&apos;s Timezone)</div>
    </>
  );
};

import {
  LoanApplicationPaymentDetailDto,
  LoanApplicationPaymentPlanDto,
  LoanApplicationRepaymentDetailDto,
  RepaymentScheduleRequestDto,
  RepaymentScheduleResponseDto,
  SaveDebtConPaymentDetailDto,
  SavePaymentDetailDto,
} from '@harmoney/api-interfaces';
import { PaymentStatusEnum } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const paymentApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getRepaymentDetail: builder.query<LoanApplicationRepaymentDetailDto, string>({
      query: (loanApplicationId) => ({
        url: `/loan-applications/${loanApplicationId}/repayment-detail`,
        method: 'GET',
      }),
    }),
    getRepaymentSchedule: builder.query<RepaymentScheduleResponseDto, RepaymentScheduleRequestDto>({
      query: ({ loanApplicationId, startDate, frequency }) => ({
        url: `loan-applications/${loanApplicationId}/repayment-schedule?startDate=${startDate}&frequency=${frequency}`,
        method: 'GET',
      }),
    }),
    getPaymentDetail: builder.query<LoanApplicationPaymentDetailDto, string>({
      query: (applicationId) => ({
        url: `/loan-applications/payment-detail`,
        method: 'GET',
        params: {
          applicationId,
        },
      }),
    }),
    getPaymentPlan: builder.query<LoanApplicationPaymentPlanDto, string>({
      query: (loanApplicationId) => ({
        url: `/loan-applications/${loanApplicationId}/payment-plan`,
        method: 'GET',
      }),
    }),
    savePayment: builder.mutation<void, SavePaymentDetailDto>({
      query: (paymentDetail) => ({
        url: `/loan-applications/${paymentDetail.repaymentSchedule.loanApplicationId}/payment`,
        method: 'POST',
        body: paymentDetail,
      }),
    }),
    saveDebtConPayment: builder.mutation<void, SaveDebtConPaymentDetailDto>({
      query: (paymentDetail) => ({
        url: `/loan-applications/${paymentDetail.repaymentSchedule.loanApplicationId}/debt-consolidation/payment`,
        method: 'POST',
        body: paymentDetail,
      }),
    }),
    getDirectDebitStatus: builder.query<PaymentStatusEnum, string>({
      query: (applicationId) => ({
        url: `/loan-applications/${applicationId}/payment-status`,
        method: 'GET',
        responseHandler: 'text',
      }),
    }),
  }),
});

export const {
  useGetRepaymentDetailQuery,
  useGetRepaymentScheduleQuery,
  useLazyGetRepaymentScheduleQuery,
  useGetPaymentDetailQuery,
  useGetPaymentPlanQuery,
  useSavePaymentMutation,
  useSaveDebtConPaymentMutation,
  useGetDirectDebitStatusQuery,
} = paymentApi;

import { useEffect, useState } from 'react';
import { DebtConsolidationLiabilityDto, LoanApplicationDto, RepaymentFrequency } from '@harmoney/api-interfaces';
import {
  useLazyGetQuoteOptionsQuery,
  useLazyReprocessObjectiveQuery,
  useUpdateDebtConsolidationBenefitAndFundedAmountMutation,
  useUpdatePostApprovalConsolidatedLiabilitiesMutation,
} from '@harmoney/redux';
import { eventAnalytics, QUOTE_READY } from '@harmoney/ui-app-shell';
import { ArrowCircleRightIcon, Button, Card, ClockLightningIcon } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';
import { AssetAndLiability, DebtConsolidationBenefitEnum, NetworthSource } from '@prisma/client';

import { CommonProps } from '../../common-props';

import { TimeDisplayBySydneyTimeZone } from './QuoteReadyV2';

interface QuoteReadySingleDebtProps extends CommonProps {
  loanApplicationData: LoanApplicationDto;
  establishmentFee: {
    establishmentFeeOverThreshold: number;
    establishmentFeeUnderThreshold: number;
    establishmentFeeThreshold: number;
  };
  selectedDebt: Partial<AssetAndLiability> & {
    networthSource: Partial<NetworthSource>;
  };
}

export function QuoteReadySingleDebt({
  loanApplicationData,
  establishmentFee,
  selectedDebt,
  taskId,
  completeTask,
}: QuoteReadySingleDebtProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [defaultConsolidatedDebt, setDefaultConsolidatedDebt] = useState<DebtConsolidationLiabilityDto>();
  const [benefits, setBenefits] = useState([]);

  const [trigger, result] = useLazyGetQuoteOptionsQuery();
  const [reprocessObjectiveTrigger, results] = useLazyReprocessObjectiveQuery();
  const [updateDebtConPurposeAndFundedAmount] = useUpdateDebtConsolidationBenefitAndFundedAmountMutation();
  const [updateLiabilities] = useUpdatePostApprovalConsolidatedLiabilitiesMutation();

  const applicationId = loanApplicationData?.id as string;
  const interestRate = loanApplicationData?.finalInterestRate as unknown as number;

  const schedule = result?.data?.find(
    (option) => option.repaymentFrequency === RepaymentFrequency.MONTHLY && option.termInMonths === 84
  );
  useEffect(() => {
    if (selectedDebt) {
      const debt: DebtConsolidationLiabilityDto = {
        liability: {
          ...selectedDebt,
        },
        isEligibleForLowerInterest: false,
        isEligibleForPaylessMonthly: false,
        isEligibleForDebtCombination: false,
      };

      setDefaultConsolidatedDebt(debt);
    }
  }, [selectedDebt]);

  useEffect(() => {
    if (defaultConsolidatedDebt) {
      const estFee =
        defaultConsolidatedDebt?.liability.outstandingBalance > establishmentFee.establishmentFeeThreshold
          ? establishmentFee.establishmentFeeOverThreshold
          : establishmentFee.establishmentFeeUnderThreshold;
      if (estFee && applicationId) {
        trigger(
          {
            loanAmount: defaultConsolidatedDebt?.liability.outstandingBalance,
            establishmentFee: estFee,
            loanApplicationId: applicationId,
          },
          true
        );
      }
    }
  }, [defaultConsolidatedDebt, establishmentFee, applicationId]);

  useEffect(() => {
    if (results?.data?.liabilities) {
      results?.data?.liabilities.forEach((liability) => {
        liability.isEligibleForLowerInterest && benefits.push(DebtConsolidationBenefitEnum.LOWER_INTEREST);
        liability.isEligibleForPaylessMonthly && benefits.push(DebtConsolidationBenefitEnum.PAY_LESS);
        liability.isEligibleForDebtCombination && benefits.push(DebtConsolidationBenefitEnum.COMBINE);
      });
      benefits.push(DebtConsolidationBenefitEnum.CHANGE_PROVIDER, DebtConsolidationBenefitEnum.NO_FEES);
      setBenefits(benefits);
    }
  }, [results]);

  useEffect(() => {
    if (defaultConsolidatedDebt && selectedDebt && schedule && interestRate) {
      reprocessObjectiveTrigger({
        debts: [defaultConsolidatedDebt],
        interestRate,
        maximumBorrowingLimit: selectedDebt?.outstandingBalance,
        hmyRepaymentAmount: schedule?.repaymentAmount,
      });
    }
  }, [defaultConsolidatedDebt, selectedDebt, schedule, interestRate]);

  const handleContinueClick = async () => {
    setIsSubmitting(true);

    await updateDebtConPurposeAndFundedAmount({
      applicationId: loanApplicationData.id,
      debtConsolidationBenefits: benefits,
      fundedAmount: selectedDebt?.outstandingBalance,
    });
    await updateLiabilities({ [selectedDebt?.id]: true });

    await completeTask({
      taskId,
      variables: { hasOneDebt: true, totalToConsolidate: selectedDebt?.outstandingBalance },
    });
    eventAnalytics.track(QUOTE_READY, {
      taskid_str: taskId,
    });
  };
  return (
    <>
      <Card>
        <h1>
          Congratulations, here&rsquo;s your <span className="text-primary">quote</span>
        </h1>
        {loanApplicationData && (
          <>
            <p className="mb-2">
              Fixed interest rate of{' '}
              <span className="font-medium">{loanApplicationData?.finalInterestRate as unknown as number}% p.a.</span>
            </p>

            {
              <p className="mb-0">
                You&rsquo;re approved for up to{' '}
                <span className="font-medium">{formatCurrency(loanApplicationData?.quotePresentedAmount)}</span>
              </p>
            }
          </>
        )}
      </Card>
      <Card className="flex items-center !p-4">
        <span className="mr-2 flex">
          <ClockLightningIcon />
        </span>
        <span className="text-sm">
          Hit continue and your debts could be paid off <TimeDisplayBySydneyTimeZone />
        </span>
      </Card>
      <div className="mb-6 text-center">
        <Button
          onClick={handleContinueClick}
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          isLoading={isSubmitting}
        >
          Continue
        </Button>
      </div>
    </>
  );
}

import { RepaymentFrequency } from '@harmoney/api-interfaces';
import { formatCurrency } from '@harmoney/utilities';
import { z } from 'zod';

export const validationMessages = {
  amount: (lowerLimit: number, upperLimit: number) =>
    lowerLimit === upperLimit
      ? `You can borrow a maximum of ${formatCurrency(upperLimit)}`
      : `The borrowing amount should be between ${formatCurrency(lowerLimit)} and ${formatCurrency(upperLimit)}`,
  repaymentFrequency: 'Please select a repayment frequency',
  termOption: 'Please select a term option',
};

export const defaultValues = { termInMonths: 84, repaymentFrequency: RepaymentFrequency.MONTHLY };

export const createFormSchema = (lowerLimit: number, upperLimit: number) => {
  return z.object({
    amount: z.coerce
      .number()
      .gte(lowerLimit, {
        message: validationMessages.amount(lowerLimit, upperLimit),
      })
      .lte(upperLimit, {
        message: validationMessages.amount(lowerLimit, upperLimit),
      }),
    repaymentFrequency: z.nativeEnum(RepaymentFrequency, {
      invalid_type_error: validationMessages.repaymentFrequency,
    }),
    termInMonths: z.coerce
      .number({
        invalid_type_error: validationMessages.termOption,
        required_error: validationMessages.termOption,
      })
      .refine((data) => [36, 60, 84].includes(data), { message: validationMessages.termOption }),
  });
};

export type FormSchema = z.infer<ReturnType<typeof createFormSchema>>;

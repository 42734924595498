/* eslint-disable @next/next/no-img-element */
import { useRef, useState } from 'react';
import { useFriendlyURL } from '@harmoney/hooks';
import {
  useAcceptGeneralLoanAgreementMutation,
  useAppSelector,
  useGetLoanApplicationQuery,
  useGetRefreshedLoansQuery,
  useGetVariablesQuery,
  useLazyGetLoanDocumentIdentifiersQuery,
} from '@harmoney/redux';
import { eventAnalytics, LOAN_CONTRACT_ACCEPTED } from '@harmoney/ui-app-shell';
import { ArrowCircleRightIcon, Button, Card, LoanAgreementGeneralTermsTemplate } from '@harmoney/ui-design-system';
import dayjs from 'dayjs';

import { CommonProps } from '../../common-props';
import { WizardChildProps } from '../../Wizard';

import { LoanAgreementGenerationError } from './LoanAgreementGenerationError';
import { ScrollEventHandler } from './ScrollEventHandler';

export interface LoanAgreementGeneralTermsProps extends CommonProps, WizardChildProps {}

export function LoanAgreementGeneralTerms({
  taskId,
  onNext,
  completeTask,
  completeTaskWithData,
  taskFriendlyURL,
}: LoanAgreementGeneralTermsProps) {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const userId = useAppSelector((state) => state.userId.value);
  const { data: variables } = useGetVariablesQuery(taskId);
  const { data: loanApplicationData } = useGetLoanApplicationQuery(variables?.loanApplicationId.toString(), {
    skip: !variables,
    refetchOnMountOrArgChange: true,
  });
  const [trigger] = useLazyGetLoanDocumentIdentifiersQuery();
  const [acceptGeneralLoanAgreement] = useAcceptGeneralLoanAgreementMutation();
  const scrollDivRef = useRef(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: refreshedLoanData } = useGetRefreshedLoansQuery(variables?.loanApplicationId?.toString(), {
    skip: !variables,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const handleSubmit = async () => {
    eventAnalytics.track(LOAN_CONTRACT_ACCEPTED, {
      userid_str: userId,
      taskid_str: taskId,
    });
    const LoanDisclosureData = await trigger(loanApplicationData?.id);
    if (LoanDisclosureData.isError) {
      setShowErrorModal(true);
      return;
    }
    await acceptGeneralLoanAgreement(loanApplicationData.id);
    onNext ? onNext() : completeTask({ taskId });
  };

  const handleScrollToBottom = (isBottom) => {
    if (isBottom) {
      setIsScrolledToBottom(isBottom);
    }
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      {showErrorModal && <LoanAgreementGenerationError />}
      <h1>
        <strong className="text-primary">Important information </strong> to read
      </h1>
      <p className="pb-2"> A copy will be sent to your email</p>
      <Card className="mb-4 px-4 py-4">
        <div className="leading-lg flex items-center p-0 text-sm font-normal">
          <p className="leading-base text-sm">Loan Agreement General Terms</p>
        </div>
        <ScrollEventHandler divRef={scrollDivRef} onScroll={handleScrollToBottom}>
          <div
            className="border-grey-2 custom-list-style mt-3 h-[27rem] overflow-auto rounded-md border p-4"
            ref={scrollDivRef}
          >
            <style>
              {`.custom-list-style ol li ol ol {
                list-style-type:decimal;
              }`}
            </style>
            <LoanAgreementGeneralTermsTemplate withHtml={false} />
          </div>
        </ScrollEventHandler>
      </Card>
      <p className="text-sm">
        Your application expires on{' '}
        <span className="font-bold">
          {dayjs(loanApplicationData?.quoteExpiryAt).subtract(2, 'days').format('DD MMMM YYYY')}
        </span>{' '}
        at 11:59PM.
      </p>
      <div className="flex justify-center">
        <Button
          type="submit"
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          className="mt-6"
          disabled={!isScrolledToBottom}
          onClick={handleSubmit}
          hasShadow
        >
          {!isScrolledToBottom ? 'Scroll to Accept' : 'Accept'}
        </Button>
      </div>
    </>
  );
}

export default LoanAgreementGeneralTerms;

import { UploadDocumentRequestDto } from '@harmoney/api-interfaces';
import { SupportingDocument } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const supportingDocumentApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    uploadDocuments: builder.mutation<null, UploadDocumentRequestDto>({
      query: (uploadRequest) => ({
        url: `/supporting-document/upload`,
        method: 'POST',
        body: uploadRequest.files,
      }),
    }),
    getDocuments: builder.query<SupportingDocument[], { userId: string; loanApplicationId: string }>({
      query: ({ userId, loanApplicationId }) => ({
        url: `/supporting-document?userId=${userId}&loanApplicationId=${loanApplicationId}`,
        method: 'GET',
      }),
    }),
    getSupportingDocumentUrl: builder.query<string, string>({
      query: (fileName) => ({
        url: '/supporting-document/path',
        method: 'GET',
        params: {
          fileName: encodeURIComponent(fileName),
        },
      }),
    }),
  }),
});

export const { useUploadDocumentsMutation, useGetDocumentsQuery, useLazyGetSupportingDocumentUrlQuery } =
  supportingDocumentApi;

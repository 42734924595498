import { type ReactElement } from 'react';

import { Icon, type IconProps, IconVariant } from './Icon';

export type GeneratedIconProps = Omit<IconProps, 'children' | 'name' | 'viewBox' | 'variant'> & { children?: never };

export type GenerateIconParameters = {
  /**
   * Icon name is used as the generated icon's component display name
   */
  name?: string;
  /**
   * The SVG path(s) to render in the icon wrapper
   */
  path: ReactElement;
  /**
   * A collection of default props to set on the generated icon
   */
  props?: GeneratedIconProps;
  /**
   * Whether or not to trim the icon width, i.e. set `width` to `auto`
   */
  trimmed?: IconProps['trimmed'];
  /**
   * Custom SVG viewBox attribute to use for the generated icon
   */
  viewBox?: IconProps['viewBox'];
  /**
   * Custom SVG fill attribute to use for the generated icon
   */
  variant?: IconVariant;
};

export function generateIcon({ path, props: defaultProps, trimmed, viewBox, variant }: GenerateIconParameters) {
  const Component = function (props: IconProps) {
    return (
      <Icon {...defaultProps} {...props} viewBox={viewBox} variant={variant} trimmed={trimmed}>
        {path}
      </Icon>
    );
  };

  return Component;
}

import { RelationshipStatusEnum } from '@prisma/client';

export const relationshipStatusOptions = [
  {
    label: 'Select an option',
    value: '',
  },
  {
    label: 'Married',
    value: RelationshipStatusEnum.MARRIED,
  },
  {
    label: 'Defacto',
    value: RelationshipStatusEnum.DE_FACTO,
  },
  {
    label: 'Single',
    value: RelationshipStatusEnum.SINGLE,
  },
  {
    label: 'Divorced',
    value: RelationshipStatusEnum.DIVORCED,
  },
  {
    label: 'Separated',
    value: RelationshipStatusEnum.SEPARATED,
  },
  {
    label: 'Widowed',
    value: RelationshipStatusEnum.WIDOWED,
  },
];

import { NetworthSourceEnum } from '@harmoney/api-interfaces';
import { randomId } from '@mantine/hooks';

export const getDefaultValues = (networthSourceId: NetworthSourceEnum) => {
  const commonValues = {
    networthSourceId: networthSourceId,
    declaredAmount: null,
    elementId: randomId(),
  };
  if (networthSourceId === NetworthSourceEnum.ASSET_OTHER_ID) {
    return { ...commonValues, assetName: '' };
  }
  return commonValues;
};

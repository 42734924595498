import { forwardRef, useState } from 'react';
import * as RadixSelect from '@radix-ui/react-select';
import classNames from 'classnames';

import { CommonOptionProps } from '../../types';
import { BaseFormFieldProps, FormField } from '../FormField';
import { ChevronIcon } from '../Icon';
import { getIconComponent } from '../Icon/Icons/IconsWithVariant';
import { Label } from '../Label';

import styles from './Select.module.scss';

export interface UncontrolledSelectProps extends BaseFormFieldProps {
  /**
   * The items to display in the select component.
   * @param label The label to display for the item.
   * @param value The value of the item.
   * @param imageSrc The image to display to the left of the item.
   */
  options: CommonOptionProps[];
  /**
   * Select name
   */
  name?: string;
  /**
   * The label to display above the select group.
   */
  label?: string;
  className?: string;
  reference?: any;
  formFieldClassName?: string;
  value?: string;
  onChange?: (value: string) => void;
}

export const UncontrolledSelect = forwardRef<HTMLButtonElement, UncontrolledSelectProps>(
  (
    {
      label,
      className,
      name,
      formFieldClassName,
      options = [],
      disabled = false,
      reference,
      isDirty,
      isTouched,
      isInvalid,
      onChange,
      value,
      ...restProps
    }: UncontrolledSelectProps,
    ref
  ) => {
    // TODO: This is a hack to fix this issue https://github.com/radix-ui/primitives/issues/1658.
    // Need to remove this when the fix is merged https://github.com/radix-ui/primitives/pull/2085
    const [open, setOpen] = useState(false);
    const [fieldValue, setFieldValue] = useState(value);

    const onValueChange = (value: string) => {
      setFieldValue(value);
      if (onChange) {
        onChange(value);
      }
    };

    const scrollToNearest = () => {
      const element = reference?.current;
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    };

    const renderSelectedOption = ({ label, value, imageSrc, iconName }: CommonOptionProps) => (
      <span key={value} className={fieldValue === value ? styles['selected-option'] : styles['selected-option-empty']}>
        {imageSrc && renderIcon(imageSrc)}
        {iconName && <span className="mr-2 flex items-center">{getIconComponent(iconName, 'secondary')}</span>}
        <span>{label}</span>
      </span>
    );

    const renderIcon = (imageSrc: string) => (
      <img
        className="mr-4 flex items-center"
        src={imageSrc}
        alt="icon"
        aria-hidden={true}
        width={18}
        height={15}
        draggable={false}
      />
    );

    return (
      <div className={className}>
        {label && (
          <Label className="mb-2" aria-labelledby={label}>
            {label}
          </Label>
        )}

        <RadixSelect.Root
          onValueChange={onValueChange}
          value={fieldValue}
          disabled={disabled}
          open={open}
          onOpenChange={() => {
            setTimeout(() => {
              scrollToNearest();
              setOpen(!open);
            }, 10);
          }}
          {...restProps}
        >
          <RadixSelect.Trigger ref={ref} className={styles['select-trigger']} data-testid={name}>
            <FormField
              className={classNames(styles['form-field'], formFieldClassName)}
              isDirty={isDirty}
              isTouched={isTouched}
              isInvalid={isInvalid}
              disabled={disabled}
            >
              <RadixSelect.Value>
                <>
                  {options.length > 0 && fieldValue
                    ? renderSelectedOption(options.find(({ value }) => value === fieldValue)!)
                    : renderSelectedOption(options[0])}
                  <RadixSelect.Icon className={styles['icon']}>
                    <ChevronIcon size="tiny" />
                  </RadixSelect.Icon>
                </>
              </RadixSelect.Value>
            </FormField>
          </RadixSelect.Trigger>

          <RadixSelect.Content className={styles['select-content']} position="popper" sideOffset={4}>
            {options.length > 0 &&
              options.map(
                ({ label, value, imageSrc, iconName, onClick }) =>
                  value && (
                    <RadixSelect.Item
                      className={styles['select-item']}
                      key={value}
                      value={value}
                      onClick={() => onClick && onClick()}
                    >
                      {imageSrc && renderIcon(imageSrc)}
                      {iconName && (
                        <span className="mr-[0.6875rem] flex items-center">
                          {getIconComponent(iconName, value === fieldValue ? 'secondary' : 'grey-4')}
                        </span>
                      )}
                      <RadixSelect.ItemText>{label}</RadixSelect.ItemText>
                    </RadixSelect.Item>
                  )
              )}
          </RadixSelect.Content>
        </RadixSelect.Root>
      </div>
    );
  }
);

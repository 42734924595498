import React, { useMemo, useState } from 'react';
import {
  DateRange,
  LoanPurposeDisplayNameEnum,
  loanStatusMapper,
  preQualifiedQuoteEmojiMapper,
  preQualifiedQuoteStatusMapper,
} from '@harmoney/api-interfaces';
import { useAppSelector, useGetPreQualifiedQuoteStatsQuery } from '@harmoney/redux';
import { Card, IconV2 } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';
import { isEmpty, lowerCase } from 'lodash';

import { ActionCenterGroup, ActionControl } from '../customer-hub/ActionControl/ActionControl';

export const PreQualifiedQuoteStats = () => {
  const accessToken = useAppSelector((state) => state.accessToken.value);

  const [dateFilter, setDateFilter] = useState<DateRange>('today');

  const { data: preQualifiedQuoteStats } = useGetPreQualifiedQuoteStatsQuery(
    {
      date: dateFilter,
    },
    {
      skip: !accessToken,
      refetchOnMountOrArgChange: true,
    }
  );
  const controlOptions: ActionCenterGroup[] = useMemo(() => {
    const filterOptions = [
      {
        label: 'Today',
        value: 'today',
      },
      {
        label: 'Yesterday',
        value: 'yesterday',
      },
      {
        label: 'This week',
        value: 'week',
      },
      {
        label: 'This month',
        value: 'month',
      },
    ] as const;

    return [
      {
        name: `Stats for ${filterOptions.find((option) => option.value === dateFilter)?.label.toLowerCase()}`,
        options: filterOptions.map((option) => ({
          label: option.label,
          value: option.value,
          onClick: () => setDateFilter(option.value),
        })),
      },
    ];
  }, [dateFilter]);

  const stats = preQualifiedQuoteStats
    ? [
        {
          title: `Count (${preQualifiedQuoteStats.total})`,
          icon: 'ic:outline-numbers',
          disable: false,
          content: (
            <div className="flex flex-col">
              {Object.entries(preQualifiedQuoteStats.statusCount)
                .toSorted((a, b) => {
                  if (a[0] === 'QUOTE') return -1;
                  if (b[0] === 'QUOTE') return 1;
                  if (a[0] === 'NO_QUOTE') return 1;
                  if (b[0] === 'NO_QUOTE') return -1;
                  return a[0].localeCompare(b[0]);
                })
                .map(([key, value]) => (
                  <div key={key} className="flex flex-row justify-between space-x-1">
                    <span>
                      {preQualifiedQuoteEmojiMapper[key]} {preQualifiedQuoteStatusMapper[key]}:
                    </span>
                    <span className="tabular-nums">{value}</span>
                  </div>
                ))}
            </div>
          ),
        },
        {
          title: 'Amounts',
          icon: 'ic:outline-attach-money',
          disable: false,
          content: (
            <div>
              <div className="flex flex-row justify-between w-full space-x-4">
                <span>Total requested amount:</span>
                <span className="tabular-nums">{formatCurrency(preQualifiedQuoteStats.totalRequestedAmount, 2)}</span>
              </div>
              <div className="flex flex-row justify-between w-full space-x-4">
                <span>Average requested amount: </span>
                <span className="tabular-nums">{formatCurrency(preQualifiedQuoteStats.averageRequestedAmount, 2)}</span>
              </div>
              <div className="flex flex-row justify-between w-full space-x-4">
                <span>Total funded amount:</span>
                <span className="tabular-nums">{formatCurrency(preQualifiedQuoteStats.totalFundedAmount, 2)}</span>
              </div>
            </div>
          ),
        },
        {
          title: 'Loan purpose',
          icon: 'ic:outline-emoji-objects',
          disable: isEmpty(preQualifiedQuoteStats.loanPurposeCount),
          content: (
            <div>
              {Object.entries(preQualifiedQuoteStats.loanPurposeCount)
                .toSorted((a, b) => b[1] - a[1])
                .map(([key, value]) => (
                  <div className="flex flex-row justify-between" key={key}>
                    <span>{LoanPurposeDisplayNameEnum[key]}:</span>
                    <span className="tabular-nums">{value}</span>
                  </div>
                ))}
            </div>
          ),
        },
        {
          title: 'Applications',
          icon: 'ic:outline-note-alt',
          disable: isEmpty(preQualifiedQuoteStats.loanApplicationStatusCount),
          content: (
            <div>
              {Object.entries(preQualifiedQuoteStats.loanApplicationStatusCount)
                .toSorted((a, b) => b[1] - a[1])
                .map(([key, value]) => (
                  <div className="flex flex-row justify-between" key={key}>
                    <span>{loanStatusMapper[key]}:</span>
                    <span className="tabular-nums">{value}</span>
                  </div>
                ))}
            </div>
          ),
        },
        {
          title: 'Failed reasons',
          icon: 'ic:outline-thumb-down',
          disable: isEmpty(preQualifiedQuoteStats?.failedReasonCount),
          content: (
            <div>
              {Object.entries(preQualifiedQuoteStats.failedReasonCount)
                .toSorted((a, b) => b[1] - a[1])
                .map(([key, value]) => (
                  <div key={key} className="flex flex-row justify-between space-x-1">
                    <span className="first-letter:capitalize">{lowerCase(key)}:</span>
                    <span className="tabular-nums">{value}</span>
                  </div>
                ))}
            </div>
          ),
        },
      ]
    : [];

  if (!preQualifiedQuoteStats) {
    return null;
  }
  return (
    <div>
      <ActionControl actionsGroup={controlOptions} isHeader />
      <div className="flex flex-row gap-4">
        {stats
          .filter((stat) => !stat.disable)
          .map((stat) => (
            <Card key={stat.title} className="flex flex-col gap-2 flex-auto text-sm">
              <div className="flex flex-row items-center font-medium space-x-1">
                <IconV2 icon={stat.icon} className="size-4 text-grey-5" />
                <span>{stat.title}</span>
              </div>
              {stat.content}
            </Card>
          ))}
      </div>
    </div>
  );
};

import React, { useMemo } from 'react';
import { LoanApplicationRepaymentDetailDto, RepaymentScheduleResponseDto } from '@harmoney/api-interfaces';
import { SummaryCard } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';

import { BankAccountSelector } from './BankAccountSelector';
import { RepaymentSetting } from './RepaymentSetting';

interface RepaymentPlanProps {
  repaymentDetail: LoanApplicationRepaymentDetailDto;
  repaymentScheduleData: RepaymentScheduleResponseDto;
  onRepaymentScheduleChange: (repaymentScheduleData: RepaymentScheduleResponseDto) => void;
  showLoanSummary?: boolean;
  isDebtCon?: boolean;
}

export const RepaymentPlan = ({
  repaymentDetail,
  repaymentScheduleData,
  onRepaymentScheduleChange,
  showLoanSummary = false,
}: RepaymentPlanProps) => {
  const loanSummaryItems = useMemo(() => {
    if (!repaymentDetail || !showLoanSummary) return [];

    const loanPurposes = repaymentDetail.loanPurposes.map(({ fundedAmount, name }, index) => ({
      keys: [
        {
          id: `${name + index}`,
          name,
        },
      ],
      value: formatCurrency(fundedAmount),
    }));

    const totalAmountRepayable = {
      keys: [
        {
          id: 'total-amount-repayable',
          name: '<span class="font-medium">Amount you will repay</span>',
        },
      ],
      description: '<span class="text-grey-4">incl. establishment fee</span>',
      value: formatCurrency(repaymentDetail.amount + repaymentDetail.establishmentFee),
    };

    return [...loanPurposes, totalAmountRepayable];
  }, [repaymentDetail, showLoanSummary]);

  return (
    <SummaryCard
      title="Your repayment plan"
      items={loanSummaryItems}
      content={<BankAccountSelector repaymentDetail={repaymentDetail} bankAccountType="repaymentFromBankAccount" />}
      footer={
        <RepaymentSetting
          repaymentDetail={repaymentDetail}
          repaymentScheduleData={repaymentScheduleData}
          onRepaymentScheduleChange={onRepaymentScheduleChange}
        />
      }
    />
  );
};

import * as React from 'react';
import { Icon } from '@iconify/react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

export const Tooltip = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root>
>(({ children, ...props }, ref) => (
  <TooltipPrimitive.Provider>
    <TooltipPrimitive.Root {...props}>
      <TooltipPrimitive.Trigger asChild>
        <Icon icon="ic:round-info" className="text-grey-3" width={24} />
      </TooltipPrimitive.Trigger>
      <TooltipPrimitive.Content
        ref={ref}
        sideOffset={4}
        className="z-50 overflow-hidden rounded-md p-2 bg-grey-5 text-xs text-white whitespace-pre-wrap animate-fade-in data-[state=closed]:animate-fade-out max-w-[500px] text-start"
      >
        {children}
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Root>
  </TooltipPrimitive.Provider>
));

import React from 'react';
import classNames from 'classnames';

import { sanitizeContent } from '../../utils';
import { Button } from '../Button';
import { Card } from '../Card';
import { Label } from '../Label';

export interface SummaryCardItemKey {
  name: string;
  id: string;
}

export interface SummaryCardItem {
  keys: SummaryCardItemKey[];
  value: string;
  description?: string;
}

export interface SummaryCardProps {
  /**
   * title of the summary card
   */
  title: string;
  /**
   * name of the right action
   */
  actionName?: string;
  actionHandler?: () => void;
  items?: SummaryCardItem[];
  content?: React.ReactNode;
  footer?: React.ReactNode;
}

export const SummaryCard: React.FC<SummaryCardProps> = ({
  title,
  actionName,
  actionHandler,
  items,
  content,
  footer,
}) => {
  return (
    <Card className="rounded-xl !p-0">
      <div className="flex items-center justify-between p-4">
        <Label className="text-lg font-medium">{title}</Label>
        {actionName && (
          <Button size="medium" variant="text" className="text-sm" onClick={actionHandler}>
            {actionName}
          </Button>
        )}
      </div>
      {items && items.length > 0 && (
        <ul className="border-t-grey-2 border-t">
          {items.map(({ keys, value, description }, index) => (
            <li
              key={value}
              className={classNames(
                index !== items.length - 1 ? 'border-b-grey-1 border-b' : '',
                'm-4 mb-0 flex items-center justify-between pb-4'
              )}
            >
              {keys && keys.length !== 0 && (
                <>
                  <div className="flex flex-col">
                    {keys.map(({ id, name }) => (
                      <span key={id} className="leading-base">
                        {sanitizeContent({
                          content: name,
                          tag: 'span',
                        })}
                      </span>
                    ))}
                    {description && (
                      <small className="text-sm">
                        {sanitizeContent({
                          content: description,
                          tag: 'span',
                        })}
                      </small>
                    )}
                  </div>
                  {value && <span className="font-medium">{value}</span>}
                </>
              )}
            </li>
          ))}
        </ul>
      )}
      {content && <div className="p-4 border-t-grey-2 border-t">{content}</div>}
      {footer && <footer className="border-t-grey-2 border-t p-4">{footer}</footer>}
    </Card>
  );
};

import { useAppDispatch } from '@harmoney/redux';
import { useFormContext } from '@harmoney/ui-design-system';

import {
  setCreateWriteOff,
  setEditWriteOff,
  setIsDirectDebitSuspendOpen,
  setIsScheduleExtraPaymentOpen,
  setShowUpdateCollectionStatus,
  setShowUpdateDirectDebitPlan,
} from '../../../../redux/slice/admin';

import { Header } from './Header';
import { ChangeSummaryTypeEnum } from './util';

interface PaymentPlanFormHeaderProps {
  headerTitle: string;
  type: ChangeSummaryTypeEnum;
  closeVisible?: boolean;
}

export const PaymentPlanFormHeader = ({ headerTitle, type, closeVisible = true }: PaymentPlanFormHeaderProps) => {
  const dispatch = useAppDispatch();
  const { reset } = useFormContext();

  const handleCancel = () => {
    reset();

    switch (type) {
      case ChangeSummaryTypeEnum.SUSPEND_DIRECT_DEBIT:
        dispatch(setIsDirectDebitSuspendOpen(false));
        break;
      case ChangeSummaryTypeEnum.ONE_OFF_PAYMENT:
        dispatch(setIsScheduleExtraPaymentOpen(false));
        break;
      case ChangeSummaryTypeEnum.UPDATE_DIRECT_DEBIT_PLAN:
        dispatch(setShowUpdateDirectDebitPlan(false));
        break;
      case ChangeSummaryTypeEnum.UPDATE_COLLECTION_STATUS:
        dispatch(setShowUpdateCollectionStatus(false));
        break;
      case ChangeSummaryTypeEnum.WRITE_OFF:
        dispatch(setCreateWriteOff(false));
        break;
      case ChangeSummaryTypeEnum.EDIT_WRITE_OFF:
        dispatch(setEditWriteOff(false));
        break;
    }
  };

  return <Header headerTitle={headerTitle} onCancelClick={handleCancel} closeVisible={closeVisible} />;
};

import { VaultLoanViewDto } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';
import { LoanApplicationQueryResponse } from './loan-application-api';

const loanViewApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getLoanViewDetailsByApplicationId: builder.query<VaultLoanViewDto, string>({
      query: (loanApplicationId) => {
        return {
          url: `/loans/${loanApplicationId}`,
          method: 'GET',
        };
      },
    }),
    getAllLoansByUserId: builder.query<(VaultLoanViewDto & LoanApplicationQueryResponse)[], string>({
      query: (userId) => {
        return {
          url: `/loans/admin-all?userId=${userId}`,
          method: 'GET',
        };
      },
    }),
    getAllLoans: builder.query<(VaultLoanViewDto & LoanApplicationQueryResponse)[], string>({
      query: () => {
        return {
          url: `/loans`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useGetLoanViewDetailsByApplicationIdQuery, useGetAllLoansByUserIdQuery, useGetAllLoansQuery } =
  loanViewApi;

import { generateIcon } from '../../generateIcon';
import { IconVariant } from '../../Icon';

export const CaravanIcon = function ({ variant = 'grey-4' }: { variant?: IconVariant }) {
  const Component = generateIcon({
    viewBox: '0 0 24 24',
    variant: variant,
    path: (
      <>
        <g clipPath="url(#clip0_4222_53359)">
          <path d="M22.41 10.41L17.58 5.58C17.21 5.21 16.7 5 16.17 5H3C1.9 5 1 5.89 1 7V14C1 15.1 1.9 16 3 16C3 17.66 4.34 19 6 19C7.66 19 9 17.66 9 16H15C15 17.66 16.34 19 18 19C19.66 19 21 17.66 21 16C22.1 16 23 15.1 23 14V11.83C23 11.3 22.79 10.79 22.41 10.41ZM3 10V8C3 7.45 3.45 7 4 7H7V11H4C3.45 11 3 10.55 3 10ZM6 17.25C5.31 17.25 4.75 16.69 4.75 16C4.75 15.31 5.31 14.75 6 14.75C6.69 14.75 7.25 15.31 7.25 16C7.25 16.69 6.69 17.25 6 17.25ZM13 11H9V7H13V11ZM18 17.25C17.31 17.25 16.75 16.69 16.75 16C16.75 15.31 17.31 14.75 18 14.75C18.69 14.75 19.25 15.31 19.25 16C19.25 16.69 18.69 17.25 18 17.25ZM15 11V7H16L20 11H15Z" />
        </g>
        <defs>
          <clipPath id="clip0_4222_53359">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </>
    ),
  });
  return <Component />;
};

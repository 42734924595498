import { GoBackToEnum } from '@harmoney/api-interfaces';
import { useGoBack } from '@harmoney/hooks';
import { Button, CollapsibleCardUnstyled, Label } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';
import { AssetAndLiability } from '@prisma/client';
import { capitalize, toUpper } from 'lodash';

type DebtsSummaryProps = {
  debts: (AssetAndLiability & { networthSourceName: string })[];
  totalToConsolidate: number;
  canGoBack: boolean;
  taskId: string;
  hasOneDebt: boolean;
};

export const DebtsSummary = ({ debts, totalToConsolidate, canGoBack, taskId, hasOneDebt }: DebtsSummaryProps) => {
  const { handleGoBack } = useGoBack(taskId);
  return (
    <>
      <CollapsibleCardUnstyled
        title={<Label className="font-medium">Debts to consolidate</Label>}
        footer={
          <div className="flex flex-row justify-between items-center">
            <span>You&rsquo;re consolidating</span>
            <span className="font-medium">{formatCurrency(totalToConsolidate)}</span>
          </div>
        }
      >
        {canGoBack && !hasOneDebt && (
          <div className="px-4 py-2 border-b border-b-grey-2 text-sm">
            You can go back and{' '}
            <Button
              variant="link"
              className="text-sm text-secondary"
              onClick={() => handleGoBack({ goBackTo: GoBackToEnum.CONSOLIDATE_DEBT })}
            >
              change your debts
            </Button>
            .
          </div>
        )}
        <div className="p-4 gap-4 flex flex-col">
          {debts?.map((debt) => (
            <div key={debt?.id} className="flex flex-row justify-between items-center gap-2">
              <span>
                {toUpper(debt?.provider)} {capitalize(debt?.networthSourceName)}
              </span>
              <span>{formatCurrency(debt?.outstandingBalance)}</span>
            </div>
          ))}
        </div>
      </CollapsibleCardUnstyled>
    </>
  );
};

import React, { useMemo } from 'react';
import { NetworthSourceEnum } from '@harmoney/api-interfaces';
import { convertToMonthlyAmount, formatNumber } from '@harmoney/utilities';
import { AssetAndLiability, FinancialProfile, IncomeAndExpense, NetworthSource, Prisma } from '@prisma/client';

type Props = {
  affordabilityData: Prisma.JsonValue;
  financialProfileData: FinancialProfile & {
    asset_and_liability: (AssetAndLiability & {
      networthSource: NetworthSource;
    })[];
    income_and_expense: (IncomeAndExpense & {
      networthSource: NetworthSource;
    })[];
  };
};

export type RowData = {
  title: string;
  final: string;
  minAcceptableValue?: string;
  declared?: string;
  override?: string;
  bankStatement?: string;
};

export const DebtsTable = ({ affordabilityData, financialProfileData }: Props) => {
  const getMonthlyDeclaredTotalMortgageRepayment = (financialProfileData): number => {
    return financialProfileData.asset_and_liability.reduce((acc, item) => {
      if (item.networthSourceId === NetworthSourceEnum.LIABILITY_MORTGAGE_ID) {
        return acc + convertToMonthlyAmount(item.repaymentAmount, item.frequency);
      }
      return acc;
    }, 0);
  };

  const undisclosedDebtCarLoanDeclared =
    affordabilityData?.['debt']?.['undisclosedDebt']?.['carLoan']?.['declared'] ?? 0;

  const undisclosedDebtCarLoanFinal = affordabilityData?.['debt']?.['undisclosedDebt']?.['carLoan']?.['final'] ?? 0;

  const undisclosedDebtCarLoanBankStatement =
    affordabilityData?.['debt']?.['undisclosedDebt']?.['carLoan']?.['bankStatement'] ?? 0;

  const rowsData: RowData[] = useMemo(() => {
    return [
      {
        title: 'Mortgage',
        declared: formatNumber(getMonthlyDeclaredTotalMortgageRepayment(financialProfileData), 2),
        bankStatement: null,
        minAcceptableValue: formatNumber(affordabilityData?.['debt']?.['calculatedMortgageRepayment'] as number, 2),
        final: formatNumber(affordabilityData?.['finalDebt']?.['mortgageRepayment'] as number, 2),
      },
      {
        title: 'BNPL',
        declared: formatNumber(affordabilityData?.['debt']?.['declaredBuyNowPayLaterRepayment'] as number, 2),
        bankStatement: formatNumber(affordabilityData?.['decisionMetrics']?.['DM085'] as number, 2),
        minAcceptableValue: null,
        final: formatNumber(0, 2),
      },
      {
        title: 'Credit Card (incl Line of Credit)',
        declared: affordabilityData?.['debt']?.['declaredCreditCardRepayment']
          ? formatNumber(affordabilityData?.['debt']?.['declaredCreditCardRepayment'] as number, 2)
          : formatNumber(affordabilityData?.['debt']?.['declaredCreditCardLimit'] as number, 2),
        bankStatement: formatNumber(affordabilityData?.['decisionMetrics']?.['DM015'] as number, 2),
        minAcceptableValue: formatNumber(affordabilityData?.['finalDebt']?.['creditCardRepayment'] as number, 2),
        final: formatNumber(affordabilityData?.['finalCreditCardRepayment'] as number, 2),
      },
      {
        title: 'Overdraft',
        declared: formatNumber(affordabilityData?.['debt']?.['declaredOverdraftRepayment'] as number, 2),
        bankStatement: null,
        minAcceptableValue: null,
        final: formatNumber((affordabilityData?.['finalDebt']?.['overdraftRepayment'] as number) ?? 0, 2),
      },
      {
        title: 'Personal Loan',
        declared: formatNumber(affordabilityData?.['debt']?.['declaredPersonalLoanRepayment'] as number, 2),
        bankStatement: null,
        minAcceptableValue: formatNumber(affordabilityData?.['debt']?.['calculatedPersonalLoanRepayment'] as number, 2),
        final: formatNumber(affordabilityData?.['finalDebt']?.['personalLoanRepayment'] as number, 2),
      },
      {
        title: 'SACC Loan',
        declared: null,
        bankStatement: formatNumber(affordabilityData?.['decisionMetrics']?.['DM010'] as number, 2),
        minAcceptableValue: null,
        final: null,
      },
      {
        title: 'Non-SACC',
        declared: null,
        bankStatement: formatNumber(affordabilityData?.['decisionMetrics']?.['DM041'] as number, 2),
        minAcceptableValue: null,
        final: null,
      },
      {
        title: 'Undisclosed debts (Auto loan)',
        declared: formatNumber(undisclosedDebtCarLoanDeclared as number, 2),
        bankStatement: formatNumber(undisclosedDebtCarLoanBankStatement as number, 2),
        minAcceptableValue: null,
        final: formatNumber(undisclosedDebtCarLoanFinal as number, 2),
      },
      {
        title: 'Other',
        declared: formatNumber(
          (affordabilityData?.['debt']?.['declaredOtherRepayment'] - undisclosedDebtCarLoanDeclared) as number,
          2
        ),
        bankStatement: null,
        minAcceptableValue: null,
        final: formatNumber(
          (affordabilityData?.['finalDebt']?.['otherRepayment'] - undisclosedDebtCarLoanFinal) as number,
          2
        ),
      },
    ];
  }, [affordabilityData]);

  return (
    <table className="border-grey-2 w-full table-fixed border">
      <thead>
        <tr className="divide-grey-2 bg-grey-1 divide-x">
          <th className="w-1/5">Debts Repayments</th>
          <th className="w-1/5">Declared</th>
          <th className="w-1/5">Bank Statement</th>
          <th className="w-1/5">Minimum Acceptable value</th>
          <th className="w-1/5 bg-grey-2">{/* Co-Override */}</th>
          <th className="w-1/5">Final</th>
        </tr>
      </thead>
      <tbody>
        {rowsData.map((row) => (
          <tr key={row.title} className="divide-grey-2 border-grey-2 divide-x border">
            <td className="bg-grey-1 px-2 py-1 font-bold">{row.title}</td>
            <td className={`px-2 text-right ${row.declared ? '' : 'bg-grey-2'}`}>{row.declared ?? ''}</td>
            <td className={`px-2 text-right ${row.bankStatement ? '' : 'bg-grey-2'}`}>{row.bankStatement ?? ''}</td>
            <td className={`px-2 text-right ${row.minAcceptableValue ? '' : 'bg-grey-2'}`}>
              {row.minAcceptableValue ?? ''}
            </td>
            <td className={`px-2 text-right ${row.override ? '' : 'bg-grey-2'}`}>{row.override ?? ''}</td>
            <td className="px-2 text-right">{row.final}</td>
          </tr>
        ))}
        <tr>
          <td />
          <td />
          <td />
          <td />
          <td className="bg-grey-1 border-x-grey-2 border-x px-2 py-1 font-bold">Total Debts Repayments</td>
          <td className="px-2 text-right">{formatNumber(affordabilityData?.['finalTotalDebt'] as number, 2)}</td>
        </tr>
      </tbody>
    </table>
  );
};

import { errors } from '@harmoney/ui-utils';
import { IncomePayTypeEnum } from '@prisma/client';
import { isEmpty } from 'lodash';
import { z } from 'zod';

export const createFormSchemaForFinancialConfirmation = (shouldDisplayPartnerIncomeSection: boolean) => {
  return z
    .object({
      hasIncomeChange: z.string().min(1, { message: errors.defaultRequiredField }),
      hasExpenseChange: z.string().min(1, { message: errors.defaultRequiredField }),
      incomeDeclaredAmount: z.coerce.number().optional(),
      incomeFrequency: z.string().optional(),
      expenseDeclaredAmount: z.coerce.number().optional(),
      expenseFrequency: z.string().optional(),
      hasPartnerIncome: z.string().optional(),
      partnerIncomeAmount: z.number({ invalid_type_error: '' }).nullable().optional(),
      partnerIncomeFrequency: z.string().optional().nullable(),
      partnerIncomePayType: z.nativeEnum(IncomePayTypeEnum).optional().nullable(),
    })
    .refine(
      (data) => {
        if (shouldDisplayPartnerIncomeSection && !data.hasPartnerIncome) {
          return false;
        }
        return true;
      },
      { message: errors.defaultRequiredField, path: ['hasPartnerIncome'] }
    )
    .refine(
      (data) => {
        if (shouldDisplayPartnerIncomeSection && data.hasPartnerIncome === 'Yes' && !data.partnerIncomeAmount) {
          return false;
        }
        return true;
      },
      { message: errors.defaultValidAmount, path: ['partnerIncomeAmount'] }
    )
    .refine(
      (data) => {
        if (
          shouldDisplayPartnerIncomeSection &&
          data.hasPartnerIncome === 'Yes' &&
          data.partnerIncomeAmount &&
          !data.partnerIncomeFrequency
        ) {
          return false;
        }
        return true;
      },
      { message: errors.defaultRequiredFrequency, path: ['partnerIncomeFrequency'] }
    )
    .refine(
      (data) => {
        if (shouldDisplayPartnerIncomeSection && data.hasPartnerIncome === 'Yes' && !data.partnerIncomePayType) {
          return false;
        }
        return true;
      },
      {
        message: errors.defaultRequiredField,
        path: ['partnerIncomePayType'],
      }
    )
    .refine(
      (data) => {
        if (data.hasIncomeChange === 'Yes' && data.incomeDeclaredAmount === 0) {
          return false;
        }
        return true;
      },
      {
        message: errors.defaultValidAmount,
        path: ['incomeDeclaredAmount'],
      }
    )
    .refine(
      (data) => {
        if (data.incomeDeclaredAmount > 0 && isEmpty(data.incomeFrequency)) return false;
        return true;
      },
      {
        message: errors.defaultRequiredFrequency,
        path: ['incomeFrequency'],
      }
    )
    .refine(
      (data) => {
        if (data.hasExpenseChange === 'Yes' && data.expenseDeclaredAmount === 0) {
          return false;
        }
        return true;
      },
      {
        message: errors.defaultValidAmount,
        path: ['expenseDeclaredAmount'],
      }
    )
    .refine(
      (data) => {
        if (data.expenseDeclaredAmount > 0 && isEmpty(data.expenseFrequency)) return false;
        return true;
      },
      {
        message: errors.defaultRequiredFrequency,
        path: ['expenseFrequency'],
      }
    );
};

/**
 * Enumeration of task definition IDs used throughout the application.
 * Centralizes task IDs for easy maintenance and type safety.
 *
 * @note When adding a new task, ensure it's added to both this enum and the taskHandlers configuration if it should redirect to a specific page.
 */
export enum TaskDefinitionIdEnum {
  AFFORDABILITY_APPROVED_V4 = 'user-task-affordability-approved-v4',
  QUOTE_FOR_UNUTILISED_LIMIT_V2 = 'user-task-quote-for-unutilised-limit-v2',
  // Future task IDs can be added here
}

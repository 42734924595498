import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import { branchEmojiMapper, branchMapper, loanStatusEmojiMapper, loanStatusMapper } from '@harmoney/api-interfaces';
import { LoanApplicationAdminQueryResponse, useAppSelector, useGetAllApplicationsQuery } from '@harmoney/redux';
import { isProduction } from '@harmoney/ui-app-shell';
import { CopyButton, Spinner, UncontrolledCheckbox } from '@harmoney/ui-design-system';
import { DATE_TIME_FORMAT, isApplication, isQuote, SOMETHING_WENT_WRONG } from '@harmoney/ui-utils';
import { useDebouncedValue } from '@mantine/hooks';
import { LoanApplicationStatusEnum } from '@prisma/client';
import { ColumnDef, PaginationState } from '@tanstack/react-table';
import dayjs from 'dayjs';

import { DataTableForSimulation } from '../DataTableForSimulation';

export const ApplicationDataTableForSimulation = () => {
  const token = useAppSelector((state) => state.accessToken.value);
  const router = useRouter();
  const [globalFilter, setGlobalFilter] = useState('');

  const updateFiltersInUrl = (statusFilter: string, productFilter: string, branchFilter: string) => {
    router.push({
      pathname: router.pathname,
      query: { ...router.query, statusFilter, productFilter, branchFilter },
    });
  };

  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [productFilter, setProductFilter] = useState<string[]>([]);
  const [branchFilter, setBranchFilter] = useState<string[]>([]);
  const [staffFilter, setStaffFilter] = useState<string>(isProduction() ? 'harmoney' : undefined);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [indeterminate, setIndeterminate] = useState<boolean>(false);

  const [debounced] = useDebouncedValue(globalFilter, 400);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });
  const {
    data: allApplicationsData,
    isError,
    isLoading,
  } = useGetAllApplicationsQuery(
    {
      page: pageIndex + 1,
      limit: pageSize,
      search: encodeURI(debounced),
      filter: JSON.stringify({
        staffFilter,
        statusFilter: statusFilter?.join(','),
        productFilter,
        branchFilter: branchFilter?.join(','),
      }),
    },
    { skip: !token || !statusFilter || !productFilter || !branchFilter, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (router.query.statusFilter) {
      setStatusFilter((router.query.statusFilter as string)?.split(','));
    }
    if (router.query.productFilter) {
      setProductFilter((router.query.productFilter as string)?.split(','));
    }
    if (router.query.branchFilter) {
      setBranchFilter((router.query.branchFilter as string)?.split(','));
    }
  }, [router.query.statusFilter, router.query.productFilter, router.query.branchFilter]);

  const columns = useMemo<ColumnDef<LoanApplicationAdminQueryResponse>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <UncontrolledCheckbox
            name="select-all"
            checked={table.getIsAllPageRowsSelected() || table.getIsSomePageRowsSelected()}
            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => (
          <UncontrolledCheckbox
            name="Select row"
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        header: 'Id',
        accessorKey: 'id',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            <Link href={`/admin/details?userId=${row.original.userId}#applications/${row.original.id}`} passHref>
              {row.original.id}
            </Link>
            <CopyButton valueToCopy={row.original.id} size="small" />
          </div>
        ),
        enableSorting: false,
      },
      {
        header: 'Application Number',
        accessorKey: 'businessKey',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            <Link href={`/admin/simulation/details?applicationId=${row.original.id}`} passHref>
              {row.original.businessKey}
            </Link>
            <CopyButton valueToCopy={row.original.businessKey} size="small" />
          </div>
        ),
      },
      {
        header: 'Loan Product',
        accessorKey: 'loanProductName',
        cell: ({ row }) => <span>{row.original?.loanProduct?.name}</span>,
      },
      {
        header: 'Branch',
        accessorKey: 'branch',
        cell: ({ row }) => <span>{row.original.branch}</span>,
      },
      {
        header: 'Created At',
        accessorFn: (row) => dayjs(row.createdAt).format(DATE_TIME_FORMAT),
        sortingFn: (a, b) => (dayjs(a.original.createdAt).isBefore(b.original.createdAt) ? 1 : -1),
      },
      {
        header: 'Updated At',
        accessorFn: (row) => dayjs(row.updatedAt).format(DATE_TIME_FORMAT),
        sortingFn: (a, b) => (dayjs(a.original.updatedAt).isBefore(b.original.updatedAt) ? 1 : -1),
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: ({ row }) => (
          <span>{`${loanStatusEmojiMapper[row.original.status]} - ${loanStatusMapper[row.original.status]}`}</span>
        ),
      },
      {
        header: 'Customer Number',
        accessorKey: 'user.businessKey',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            <Link href={`/admin/details?userId=${row.original.userId}#customer-profile`} passHref>
              {row.original?.user?.businessKey}
            </Link>
            <CopyButton valueToCopy={row.original?.user?.businessKey} size="small" />
          </div>
        ),
        enableSorting: false,
      },
      {
        header: 'Customer Name',
        accessorKey: 'userProfile',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            {row.original?.user?.userProfile?.firstName && row.original?.user?.userProfile?.lastName ? (
              <>
                <Link href={`/admin/details?userId=${row.original.userId}#customer-profile`} passHref>
                  {`${row.original?.user?.userProfile?.firstName} ${row.original?.user?.userProfile?.lastName}`}
                </Link>
                <CopyButton
                  valueToCopy={`${row.original?.user?.userProfile?.firstName} ${row.original?.user?.userProfile?.lastName}`}
                  size="small"
                />
              </>
            ) : (
              'N/A'
            )}
          </div>
        ),
        enableSorting: false,
      },
    ],
    []
  );

  const statusFilterObject = useMemo(() => {
    const status = Object.keys(loanStatusMapper).map((key) => ({
      id: key,
      label: `${loanStatusEmojiMapper[key]} - ${loanStatusMapper[key]}`,
      checked: !statusFilter?.includes(key),
    }));
    return [
      {
        id: 'quote',
        label: 'Quote',
        options: status.filter((s) => isQuote(s.id as LoanApplicationStatusEnum)),
      },
      {
        id: 'application',
        label: 'Application',
        options: status.filter((s) => isApplication(s.id as LoanApplicationStatusEnum)),
      },
    ];
  }, []);

  const productFilterObject = [
    {
      id: 'Personal loan',
      label: 'Personal loan',
      checked: !productFilter?.includes('Personal loan'),
    },
    {
      id: 'Debt consolidation',
      label: 'Debt consolidation',
      checked: !productFilter?.includes('Debt consolidation'),
    },
  ];

  const branchFilterObject = useMemo(() => {
    return Object.keys(branchMapper).map((key) => ({
      id: key,
      label: `${branchEmojiMapper[key]} ${branchMapper[key]}`,
      checked: !branchFilter?.includes(key),
    }));
  }, [branchFilter]);

  useEffect(() => {
    statusFilterObject.forEach((filter) => {
      filter.options.forEach((option) => {
        option.checked = !statusFilter.includes(option.id);
      });
    });
    productFilterObject.forEach((option) => {
      option.checked = !productFilter.includes(option.id);
    });
    branchFilterObject.forEach((option) => {
      option.checked = !branchFilter.includes(option.id);
    });
  }, [statusFilter, productFilter, branchFilter]);

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filteredStatus = e.target.checked
      ? statusFilter.filter((status) => status !== e.target.value)
      : [...statusFilter, e.target.value];
    setStatusFilter(filteredStatus);
    updateFiltersInUrl(filteredStatus.join(','), productFilter.join(','), branchFilter.join(','));
  };

  const handleProductChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filteredProduct = e.target.checked
      ? productFilter.filter((product) => product !== e.target.value)
      : [...productFilter, e.target.value];
    setProductFilter(filteredProduct);
    updateFiltersInUrl(statusFilter.join(','), filteredProduct.join(','), branchFilter.join(','));
  };

  const handleBranchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filteredBranch = e.target.checked
      ? branchFilter.filter((branch) => branch !== e.target.value)
      : [...branchFilter, e.target.value];
    setBranchFilter(filteredBranch);
    updateFiltersInUrl(statusFilter.join(','), productFilter.join(','), filteredBranch.join(','));
  };

  useEffect(() => {
    if (statusFilter.length === 0 && productFilter.length === 0 && branchFilter.length === 0) {
      setAllChecked(true);
      setIndeterminate(false);
    } else if (statusFilter.length < 11 || productFilter.length < 2 || branchFilter.length < 2) {
      setAllChecked(false);
      setIndeterminate(true);
    } else {
      setAllChecked(false);
      setIndeterminate(false);
    }
  }, [statusFilter, productFilter, branchFilter]);

  const handleSelectAll = () => {
    if (allChecked) {
      const filteredStatus = [],
        filteredProduct = [],
        filteredBranch = [];
      productFilterObject.forEach((option) => {
        option.checked = false;
        filteredProduct.push(option.id);
      });
      branchFilterObject.forEach((option) => {
        option.checked = false;
        filteredBranch.push(option.id);
      });

      statusFilterObject.forEach((filter) => {
        filter.options.forEach((option) => {
          option.checked = false;
          filteredStatus.push(option.id);
        });
      });

      setStatusFilter(filteredStatus);
      setProductFilter(filteredProduct);
      setBranchFilter(filteredBranch);
      updateFiltersInUrl(filteredStatus.join(','), filteredProduct.join(','), filteredBranch.join(','));
      setAllChecked(false);
      setIndeterminate(false);
    } else {
      statusFilterObject.forEach((filter) => {
        filter.options.forEach((option) => {
          option.checked = true;
        });
      });

      productFilterObject.forEach((option) => {
        option.checked = true;
      });
      branchFilterObject.forEach((option) => {
        option.checked = true;
      });

      setStatusFilter([]);
      setProductFilter([]);
      setBranchFilter([]);
      updateFiltersInUrl('', '', '');

      setAllChecked(true);
      setIndeterminate(false);
    }
  };

  if (isError) {
    return <div className="m-3">{SOMETHING_WENT_WRONG}</div>;
  }

  if (isLoading) {
    return (
      <div className="align-center mt-32 flex justify-center">
        <Spinner />
      </div>
    );
  }
  return (
    <>
      <div className="grid grid-cols-8 grid-rows-1 gap-0">
        <div className="mt-12 flex max-w-fit flex-col gap-2">
          <div className="pl-2">
            <span className="text-sm font-bold">Country</span>
            <div className="flex flex-col gap-0">
              {branchFilterObject?.map((option) => (
                <div key={option.id}>
                  <input
                    type="checkbox"
                    id={option.id}
                    name={option.id}
                    value={option.id}
                    checked={option.checked}
                    onChange={handleBranchChange}
                  />
                  <label htmlFor={option.id} className="ml-1 align-[2px] text-xs">
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
          {statusFilterObject.map((filter) => (
            <div key={filter.id}>
              <span className="ml-1 text-sm font-bold">{filter.label}</span>
              <div className="flex flex-col gap-0 pl-2">
                {filter.options.map((option) => (
                  <div key={option.id}>
                    <input
                      type="checkbox"
                      id={option.id}
                      name={option.id}
                      value={option.id}
                      checked={option.checked}
                      onChange={handleStatusChange}
                    />
                    <label htmlFor={option.id} className="ml-1 align-[2px] text-xs">
                      {option.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className="pl-2">
            <span className="text-sm font-bold">Product</span>
            <div className="flex flex-col gap-0">
              {productFilterObject.map((option) => (
                <div key={option.id}>
                  <input
                    type="checkbox"
                    id={option.id}
                    name={option.id}
                    value={option.id}
                    checked={option.checked}
                    onChange={handleProductChange}
                  />
                  <label htmlFor={option.id} className="ml-1 align-[2px] text-xs">
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <UncontrolledCheckbox
            name="all-application-status"
            label="Select all / Clear all"
            alignLabel="right"
            className="pt-2 pl-2 font-medium text-xs"
            flexClassName="!gap-x-1"
            checkboxClassName="h-[0.875rem] w-[0.875rem] border-grey-4"
            checked={indeterminate === true ? 'indeterminate' : allChecked}
            iconWidth={14}
            blueIcon
            onCheckedChange={handleSelectAll}
          />
        </div>
        <div className="col-span-7">
          {columns && allApplicationsData?.data && (
            <DataTableForSimulation
              title="All Applications"
              columns={columns}
              data={allApplicationsData.data}
              pageIndex={pageIndex}
              pageSize={pageSize}
              total={allApplicationsData?.total}
              pageCount={allApplicationsData?.page}
              setGlobalFilter={setGlobalFilter}
              globalFilter={globalFilter}
              setPagination={setPagination}
            />
          )}
          <div className="mt-4 flex flex-row items-center gap-1">
            <span className="text-sm font-medium">Include Staff:</span>
            <input
              type="checkbox"
              id="staffFilter"
              name="staffFilter"
              value="staffFilter"
              defaultChecked={!isProduction()}
              onChange={(e) => setStaffFilter(e.target.checked ? undefined : 'harmoney')}
            />
          </div>
        </div>
      </div>
    </>
  );
};

import Image from 'next/image';
import { Divider } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';

export function ExtraLimitDescription({
  maxExtraLimitForSecondaryPurpose,
  interestRate,
}: {
  maxExtraLimitForSecondaryPurpose: number;
  interestRate: number;
}) {
  return (
    <>
      <div className="p-4 pb-0 flex mb-0">
        <p className="pr-4">
          Borrow up to an extra <strong>{formatCurrency(maxExtraLimitForSecondaryPurpose)}</strong> at{' '}
          <strong>
            {interestRate}% <small className="font-bold">(p.a.)</small>
          </strong>{' '}
          for something else
        </p>
        <Image src="/assets/images/extra-amounts.svg" alt="Extra amounts" width={150} height={150} />
      </div>
      <Divider className="m-0 text-grey-1" />
    </>
  );
}

import { toString } from 'lodash';

export const monthOptions = [
  { label: 'Month', value: '' },
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

export const yearsOptions = (numberOfYears) => {
  const dynamicYears = [];
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - numberOfYears;

  for (let year = startYear; year <= currentYear; year++) {
    dynamicYears.push({ label: toString(year), value: toString(year) });
  }
  return [{ label: 'Year', value: '' }, ...dynamicYears.reverse()];
};

import { useBranch } from '@harmoney/hooks';
import { Button, Card, IconV2 } from '@harmoney/ui-design-system';
import { IntercomActionEnum, openChatWindow } from '@harmoney/ui-utils';
import { formatNumber } from '@harmoney/utilities';
import { kebabCase } from 'lodash';

import { RatingLinks } from '../../../dashboard/StarRating';
import { TimeDisplayBySydneyTimeZone } from '../../QuoteReady/QuoteReadyV2';

interface EmailYourQuoteCardProps {
  isEmailingQuote: boolean;
  onEmailQuote: () => void;
}

interface QuoteHelpCardProps {
  header: string;
  iconSrc: string;
  iconWidth?: number;
  iconHeight?: number;
  children: React.ReactNode;
}

const DEFAULT_ICON_SIZE = 24;
const CARD_DESCRIPTION_TEXT_CLASSNAME = 'max-w-[350px] text-sm';

const QuoteHelpCardWrapper = ({
  header,
  iconSrc,
  iconWidth = DEFAULT_ICON_SIZE,
  iconHeight = DEFAULT_ICON_SIZE,
  children,
}: QuoteHelpCardProps) => {
  return (
    <Card className="flex flex-col text-center items-center gap-2 max-w-[512px]">
      <div className="mb-2">
        <IconV2 icon={iconSrc} width={iconWidth} height={iconHeight} />
      </div>
      <span className="m-0 font-medium">{header}</span>
      {children}
    </Card>
  );
};

export const EmailYourQuoteCard = ({ isEmailingQuote, onEmailQuote }: EmailYourQuoteCardProps) => {
  return (
    <QuoteHelpCardWrapper header="Email your quote" iconSrc="hmy:email-gradient" iconWidth={40} iconHeight={40}>
      <span className={CARD_DESCRIPTION_TEXT_CLASSNAME}>
        Need some time? Send a copy directly to your inbox for you to review later.
      </span>
      <Button onClick={onEmailQuote} isLoading={isEmailingQuote} variant="outline-secondary" className="mt-2">
        Send
      </Button>
    </QuoteHelpCardWrapper>
  );
};

export const GetQuickAnswersCard = () => {
  return (
    <QuoteHelpCardWrapper header="Get quick answers" iconSrc="hmy:chat-gradient" iconWidth={40} iconHeight={40}>
      <div className="flex gap-2 items-center justify-center">
        <div className="rounded-full h-3 w-3 bg-success" />
        <span className="text-xs">Online now</span>
      </div>
      <span className={CARD_DESCRIPTION_TEXT_CLASSNAME}>
        We offer tailored assistance to help you make the right decision for you. Ask us anything!
      </span>
      <Button
        onClick={() => openChatWindow(IntercomActionEnum.SHOW_NEW_MESSAGING)}
        variant="outline-secondary"
        className="mt-2"
      >
        Chat
      </Button>
    </QuoteHelpCardWrapper>
  );
};

const whatHappensNextSteps = [
  {
    stepNumber: '1',
    stepDescriptionFragment: <>View your quote and compare your repayments.</>,
  },
  {
    stepNumber: '2',
    stepDescriptionFragment: <>Tell us where to deposit your money and set up your repayments.</>,
  },
  {
    stepNumber: '3',
    stepDescriptionFragment: (
      <>
        Accept your contract and your money could be in your account <TimeDisplayBySydneyTimeZone />!
      </>
    ),
  },
];

export const WhatHappensNextCard = () => {
  return (
    <QuoteHelpCardWrapper header="Wondering what happens next?" iconSrc="hmy:next-gradient">
      <div className="flex flex-col gap-2">
        {whatHappensNextSteps.map((step) => (
          <div key={step.stepNumber} className="flex flex-row gap-3">
            <div className="text-left font-bold text-primary w-3">{step.stepNumber}</div>
            <span className="text-left text-sm">{step.stepDescriptionFragment}</span>
          </div>
        ))}
      </div>
    </QuoteHelpCardWrapper>
  );
};

export const RatingsCard = () => {
  const branch = useBranch();

  return (
    <QuoteHelpCardWrapper
      header="See what our customers are saying"
      iconSrc="hmy:rating-stars-gradient"
      iconWidth={120}
    >
      <div className="flex gap-6 mt-4 justify-between">
        {branch &&
          Object.keys(RatingLinks[branch]).map((platform) => (
            <a
              key={platform}
              className="flex flex-col justify-center items-center gap-2"
              href={RatingLinks[branch][platform].link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="text-lg font-medium">{RatingLinks[branch][platform].rate}</span>
              <img src={`/assets/images/${kebabCase(platform)}-full-logo.svg`} alt={platform} height={24} />
              <span className="text-sm">
                based on {formatNumber(RatingLinks[branch][platform].reviewCount)} reviews
              </span>
            </a>
          ))}
      </div>
    </QuoteHelpCardWrapper>
  );
};

import { forwardRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { CommonOptionProps } from '../../types';
import { Label } from '../Label';
import { RadioGroup } from '../RadioGroup';
import { Select } from '../Select';

interface RadioSelectSelectorProps {
  /**
   * The name of the group called radioOptions and selectOptions. Submitted with its owning form as part of a name/value pair.
   */
  radioOptions: CommonOptionProps[];
  selectOptions: CommonOptionProps[];
  /**
   * The name of the group. Submitted with its owning form as part of a name/value pair.
   */
  name: string;
  /**
   * The label to display above the radio group.
   */
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  /**
   * Optional parameter to set the length the radio group options array.
   */
  radioOptionsLength?: number;
  className?: string;
}

export const RadioSelectSelector = forwardRef<HTMLDivElement, RadioSelectSelectorProps>(
  (
    { radioOptions, selectOptions, label, name, className, onChange }: RadioSelectSelectorProps,
    forwardRef: React.Ref<HTMLDivElement>
  ) => {
    const { register, watch, resetField } = useFormContext();
    const [innerRadioOptions, setInnerRadioOptions] = useState<CommonOptionProps[]>(radioOptions);
    const watchControl = watch(name);

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      watchControl['secondary'] = e.target.value;

      if (!watchControl['secondary']) return;

      const selectedSecondaryOption = selectOptions.find(({ value }) => value === watchControl['secondary']);
      if (selectedSecondaryOption) {
        setInnerRadioOptions([
          ...radioOptions,
          {
            ...selectedSecondaryOption,
          },
        ]);
      }
      resetField(`${name}.primary`, { defaultValue: e.target.value });
      resetField(`${name}.secondary`, { defaultValue: '' });

      if (onChange) onChange(e);
    };

    const handleRadioBtnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (radioOptions.length !== innerRadioOptions.length) {
        setInnerRadioOptions(radioOptions);
      }
      if (watchControl['primary']) {
        resetField(`${name}.secondary`, { defaultValue: '' });
      }
      if (onChange) onChange(e);
    };

    return (
      <div className={className} ref={forwardRef}>
        {label && <Label className="mb-2 font-medium">{label}</Label>}
        <RadioGroup
          {...register(`${name}.primary`, {
            onChange: handleRadioBtnChange,
          })}
          className="mb-2"
          options={innerRadioOptions}
          displayValidationMessage={false}
        />
        <Select
          {...register(`${name}.secondary`, {
            onChange: (e) => handleSelectChange(e),
          })}
          options={selectOptions}
        />
      </div>
    );
  }
);

import { generateIcon } from '../generateIcon';

/**
 * @deprecated Check if it can be replaced with Iconify. If not, add it to icon.json.
 * Refer to icon document for details: https://63be80b51f526a24fc7865c5-tslqcdnosl.chromatic.com/?path=/docs/docs-icons--docs
 */
export const LoginNoViewIcon = generateIcon({
  viewBox: '0 0 24 24',
  path: (
    <>
      <g clipPath="url(#clip0_2490_20109)">
        <path
          d="M8 5.9C9.16 5.9 10.1 6.84 10.1 8C10.1 9.16 9.16 10.1 8 10.1C6.84 10.1 5.9 9.16 5.9 8C5.9 6.84 6.84 5.9 8 5.9ZM8 14.9C10.97 14.9 14.1 16.36 14.1 17V18.1H1.9V17C1.9 16.36 5.03 14.9 8 14.9ZM8 4C5.79 4 4 5.79 4 8C4 10.21 5.79 12 8 12C10.21 12 12 10.21 12 8C12 5.79 10.21 4 8 4ZM8 13C5.33 13 0 14.34 0 17V19C0 19.55 0.45 20 1 20H15C15.55 20 16 19.55 16 19V17C16 14.34 10.67 13 8 13Z"
          fill="#73A4F5"
        />
        <circle cx="15.5" cy="17.5" r="5.5" fill="white" />
        <path
          d="M18.0027 15.1053C19.5089 15.1053 20.7312 16.2842 20.7312 17.7368C20.7312 18.0789 20.6603 18.4 20.5348 18.7L22.1282 20.2368C22.9523 19.5737 23.6016 18.7158 24 17.7368C23.0559 15.4263 20.7258 13.7895 17.9973 13.7895C17.2333 13.7895 16.502 13.9211 15.8254 14.1579L17.0041 15.2947C17.3151 15.1737 17.648 15.1053 18.0027 15.1053ZM12.5457 13.6684L13.7899 14.8684L14.0409 15.1105C13.1351 15.7895 12.4256 16.6947 12 17.7368C12.9441 20.0474 15.2742 21.6842 18.0027 21.6842C18.8486 21.6842 19.6562 21.5263 20.3929 21.2421L20.6221 21.4632L22.221 23L22.9141 22.3316L13.2387 13L12.5457 13.6684ZM15.5634 16.5789L16.4093 17.3947C16.382 17.5053 16.3656 17.6211 16.3656 17.7368C16.3656 18.6105 17.0969 19.3158 18.0027 19.3158C18.1228 19.3158 18.2428 19.3 18.3574 19.2737L19.2033 20.0895C18.8377 20.2632 18.4338 20.3684 18.0027 20.3684C16.4966 20.3684 15.2742 19.1895 15.2742 17.7368C15.2742 17.3211 15.3834 16.9316 15.5634 16.5789ZM17.9154 16.1684L19.6344 17.8263L19.6453 17.7421C19.6453 16.8684 18.9141 16.1632 18.0082 16.1632L17.9154 16.1684Z"
          fill="#206EEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_2490_20109">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});

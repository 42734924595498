import { FrankieSmartUIStyle } from '../../identity/FrankieSmartUI/frankie-smartui-style';

export const FrankieVevoStyle = `
  ${FrankieSmartUIStyle}

  #ff-onboarding-widget .ff-passport-input div[placeholder='Country of Issue'] {
    display: block !important;
    margin-bottom: 1.25rem !important;
  }
`;

import { useEffect, useState } from 'react';
import { QuoteOption, RepaymentFrequency } from '@harmoney/api-interfaces';
import { CommonOptionProps } from '@harmoney/ui-design-system';
import { NumberFormatter } from '@harmoney/ui-utils';
import { formatCurrency, formatFrequency } from '@harmoney/utilities';
import { RepaymentFrequencyEnum } from '@prisma/client';

import { convertMonthsToYears } from '../components/quote/shared';

export const useFormatQuoteOptions = (repaymentFrequency: RepaymentFrequency, quoteOptions: QuoteOption[]) => {
  const [formattedQuoteOptions, setFormattedQuoteOptions] = useState<CommonOptionProps[]>();

  useEffect(() => {
    const mapFunction = repaymentFrequency ? getFormattedTermOption : getFormattedEmptyTermOption;
    const frequency = repaymentFrequency || RepaymentFrequency.MONTHLY;

    const formattedOptions = quoteOptions
      ?.filter((t) => t.repaymentFrequency === frequency)
      .map(mapFunction)
      .sort((a, b) => Number(a.value) - Number(b.value));

    setFormattedQuoteOptions(formattedOptions);
  }, [repaymentFrequency, quoteOptions]);

  const getFormattedTermOption = ({ repaymentAmount, repaymentFrequency, termInMonths }) => ({
    label: `
      <div class="flex justify-between">
        <div>
          <span class="font-medium">$${NumberFormatter(repaymentAmount)}</span><small>/${repaymentFrequency}</small>
        </div>
        <span class="mr-4">${termInMonths / 12} years</span>
      </div>`,
    value: termInMonths?.toString(),
  });

  const getFormattedEmptyTermOption = ({ termInMonths }) => ({
    label: `${termInMonths / 12} years`,
    value: termInMonths,
  });

  return formattedQuoteOptions;
};

export const useFormatQuoteOptionsV2 = (repaymentFrequency: RepaymentFrequencyEnum, quoteOptions: QuoteOption[]) => {
  const [formattedQuoteOptions, setFormattedQuoteOptions] = useState<CommonOptionProps[]>();

  useEffect(() => {
    const mapFunction = repaymentFrequency ? getFormattedTermOption : getFormattedEmptyTermOption;
    const frequency = repaymentFrequency || RepaymentFrequencyEnum.monthly;

    const formattedOptions = quoteOptions
      ?.filter((t) => t.repaymentFrequency === frequency)
      .map(mapFunction)
      .sort((a, b) => Number(a.value) - Number(b.value));

    setFormattedQuoteOptions(formattedOptions);
  }, [repaymentFrequency, quoteOptions]);

  const getFormattedTermOption = ({ repaymentAmount, repaymentFrequency, termInMonths }) => {
    const frequencyClass =
      repaymentFrequency === RepaymentFrequencyEnum.fortnightly ? 'flex flex-col sm:flex-row' : 'flex';

    return {
      label: `${convertMonthsToYears(termInMonths)} years`,
      value: termInMonths,
      description: `
        <div class="${frequencyClass}">
          <span>${formatCurrency(repaymentAmount)}</span>
          <span >/${formatFrequency(repaymentFrequency)}</span>
        </div>
      `,
    };
  };

  const getFormattedEmptyTermOption = ({ termInMonths }) => ({
    label: `${convertMonthsToYears(termInMonths)} years`,
    value: termInMonths,
  });

  return formattedQuoteOptions;
};

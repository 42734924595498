import { ComparisonOperator, errors } from '@harmoney/ui-utils';
import { z } from 'zod';

export const defaultValues = {
  loanPurpose: { primary: '', secondary: '' },
  questions: [],
  amount: null,
};

export const createFormSchema = (minimumLoanAmount: number, maximumLoanAmount: number) =>
  z.object({
    loanPurpose: z
      .object({
        primary: z.string(),
        secondary: z.string(),
      })
      .refine((data) => data.primary, {
        path: ['primary'],
      })
      .refine((data) => data.primary, {
        message: 'Please select a loan purpose',
        path: ['secondary'],
      }),
    questions: z
      .object({
        optionId: z.union([z.string(), z.boolean()]),
      })
      .refine(({ optionId }) => optionId, {
        message: errors.defaultRequiredField,
        path: ['optionId'],
      })
      .array(),
    amount: z
      .number({
        required_error: errors.amountComparison(ComparisonOperator.GREATER_THAN, minimumLoanAmount),
        invalid_type_error: errors.amountComparison(ComparisonOperator.GREATER_THAN, minimumLoanAmount),
      })
      .gte(minimumLoanAmount, {
        message: errors.amountComparison(ComparisonOperator.GREATER_THAN, minimumLoanAmount),
      })
      .lte(maximumLoanAmount, {
        message: errors.amountComparison(ComparisonOperator.LESS_THAN, maximumLoanAmount),
      }),
  });

import { generateIcon } from '../../generateIcon';
import { IconVariant } from '../../Icon';

export const DefaultIcon = function ({ variant = 'grey-4' }: { variant?: IconVariant }) {
  const Component = generateIcon({
    viewBox: '0 0 24 24',
    variant: variant,
    path: (
      <>
        <g clipPath="url(#clip0_4222_53362)">
          <path d="M7.91982 7.54C7.11982 7.2 6.77982 6.21 7.25982 5.49C8.22982 4.05 9.84982 3 11.9898 3C14.3398 3 15.9498 4.07 16.7698 5.41C17.4698 6.56 17.8798 8.71 16.7998 10.31C15.5998 12.08 14.4498 12.62 13.8298 13.76C13.6798 14.03 13.5898 14.25 13.5298 14.7C13.4398 15.43 12.8398 16 12.0998 16C11.2298 16 10.5198 15.25 10.6198 14.38C10.6798 13.87 10.7998 13.34 11.0798 12.84C11.8498 11.45 13.3298 10.63 14.1898 9.4C15.0998 8.11 14.5898 5.7 12.0098 5.7C10.8398 5.7 10.0798 6.31 9.60982 7.04C9.25982 7.61 8.52982 7.79 7.91982 7.54ZM13.9998 20C13.9998 21.1 13.0998 22 11.9998 22C10.8998 22 9.99982 21.1 9.99982 20C9.99982 18.9 10.8998 18 11.9998 18C13.0998 18 13.9998 18.9 13.9998 20Z" />
        </g>
        <defs>
          <clipPath id="clip0_4222_53362">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </>
    ),
  });
  return <Component />;
};

import { EnvironmentNameEnum } from '@harmoney/api-interfaces';

export function isDevelopment(): boolean {
  return process.env['NEXT_PUBLIC_ENVIRONMENT_NAME'] === EnvironmentNameEnum.development;
}

export function isPreview(): boolean {
  return process.env['NEXT_PUBLIC_ENVIRONMENT_NAME'] === EnvironmentNameEnum.preview;
}

export function isStaging(): boolean {
  return process.env['NEXT_PUBLIC_ENVIRONMENT_NAME'] === EnvironmentNameEnum.staging;
}

export function isProduction(): boolean {
  return process.env['NEXT_PUBLIC_ENVIRONMENT_NAME'] === EnvironmentNameEnum.production;
}

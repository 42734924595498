import React, { forwardRef } from 'react';
import { Control, Controller, FieldErrors, useFormContext } from 'react-hook-form';

import { BaseFormFieldProps } from '../FormField';
import { ControlledInputProps, Input } from '../Input';
import { ValidationMessage } from '../ValidationMessage';

/**
 * The native input returns the value in string format unless invoked with valueAsNumber or valueAsDate, you can read more under [this section](https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement).
 * However, it's not perfect. We still have to deal with isNaN or null values. So it's better to leave the transform at the custom hook level.
 * Read more from https://react-hook-form.com/advanced-usage/#TransformandParse
 */

export interface AmountInputProps extends ControlledInputProps {
  // maxLength gets checked with less than operator as $ is prefixed to the input, so the actual maxLength is maxLength + 1
  // for e.g. if we need 5 digits then maxLength should be 6
  maxLength?: number;
}

const ControllerPlus = <TInput extends string, TOutput>({
  className,
  name,
  control,
  transform,
  errors,
  validationMessage,
  isDirty,
  isTouched,
  isInvalid,
  maxLength,
  displayValidation,
  ...restProps
}: {
  transform: {
    input: (value: TOutput) => TInput;
    inputOnKeyDown: (value: TOutput, event: React.KeyboardEvent<HTMLInputElement>) => TInput;
    output: (value: React.ChangeEvent<HTMLInputElement>) => TOutput;
  };
  name: string;
  control: Control;
  errors: FieldErrors;
  validationMessage: string;
  displayValidation: boolean;
  maxLength: number;
  className?: string;
} & BaseFormFieldProps) => (
  <div className={className}>
    <Controller
      control={control}
      name={name}
      render={({ field: { value = '', onBlur, onChange } }) => (
        <Input
          {...restProps}
          type="text"
          name={name}
          prefix="$"
          isDirty={isDirty}
          isTouched={isTouched}
          isInvalid={isInvalid}
          isFilled={!!value || value === 0}
          maxLength={maxLength}
          inputMode="numeric"
          pattern="[0-9]+(,[0-9]+)*(.[0-9]+)?"
          onBlur={onBlur}
          onKeyDown={(e) => transform.inputOnKeyDown(value, e)}
          value={transform.input(value)}
          onChange={(e) => {
            onChange(transform.output(e));
          }}
        />
      )}
    />
    {validationMessage && !errors[name] ? (
      <small className="text-grey-4 leading-lg block text-sm">{validationMessage}</small>
    ) : (
      displayValidation && <ValidationMessage name={name} />
    )}
  </div>
);

/**
 * In AmountInput the  maxLength = 15 as the The number type in TypeScript follows the IEEE 754 standard for 64-bit floating-point numbers,
 * also known as double precision. This means that a number in TypeScript can hold up to 15 significant digits.
 */
export const AmountInput = forwardRef<HTMLElement, AmountInputProps>(
  (
    {
      className,
      name,
      validationMessage = '',
      maxLength = 15,
      displayValidation = true,
      ...restProps
    }: AmountInputProps,
    forwardRef: React.Ref<HTMLElement>
  ) => {
    const {
      control,
      getFieldState,
      formState,
      formState: { errors },
    } = useFormContext();

    const { isDirty, isTouched, invalid } = getFieldState(name, formState);

    const inputTransform = (value: number | null) => {
      if (value === 0 && !isNaN(value)) {
        return '0';
      } else if (!value || isNaN(value)) {
        return '';
      } else {
        return new Intl.NumberFormat('en-NZ').format(value);
      }
    };

    const inputOnKeyDownTransform = (value: number | null, event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Backspace' || event.key === 'Delete') {
        const stringValue = value?.toString() || '';
        if (stringValue.endsWith('0') || stringValue === '0') {
          return '';
        }
      }
      return inputTransform(value);
    };

    const outputTransform = (event: React.ChangeEvent<HTMLInputElement>) => {
      const output = event.target.value ? parseInt(event.target.value.replace(/[^0-9]/g, '')) : null;
      return output && isNaN(output) ? 0 : output;
    };

    return (
      <ControllerPlus<string, number | null>
        {...restProps}
        transform={{
          input: (value: number | null) => inputTransform(value),
          inputOnKeyDown: (value: number | null, event: React.KeyboardEvent<HTMLInputElement>) =>
            inputOnKeyDownTransform(value, event),
          output: (event: React.ChangeEvent<HTMLInputElement>) => outputTransform(event),
        }}
        control={control}
        errors={errors}
        name={name}
        validationMessage={validationMessage}
        isDirty={isDirty}
        isTouched={isTouched}
        isInvalid={invalid}
        displayValidation={displayValidation}
        maxLength={maxLength}
        className={className}
      />
    );
  }
);

export const DecimalAmountInput = forwardRef<HTMLElement, AmountInputProps>(
  (
    {
      className,
      name,
      validationMessage = '',
      maxLength = 20,
      displayValidation = true,
      ...restProps
    }: AmountInputProps,
    forwardRef: React.Ref<HTMLElement>
  ) => {
    const {
      control,
      getFieldState,
      formState,
      formState: { errors },
    } = useFormContext();

    const { isDirty, isTouched, invalid } = getFieldState(name, formState);
    const decimalNumberFormatter = (value: number) =>
      new Intl.NumberFormat('en-NZ', { maximumFractionDigits: 10 }).format(value);
    const numRegex = /^\d+([.]{0,1})?\d{0,10}$/gm;
    const lastDecimalRegEx = /^\d+([.]0?)?$/gm;
    const unFormatNumber = (formattedValue: string): string => {
      return formattedValue.replace(/[^\d.-]/g, '').replace(/^0+/, '');
    };

    const inputTransform = (value: string) => {
      if (value?.match(lastDecimalRegEx)) {
        return value;
      }

      if (value?.match(numRegex)) {
        return decimalNumberFormatter(Number.parseFloat(value));
      }

      return value;
    };

    const inputOnKeyDownTransform = (value: string, event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Backspace' || event.key === 'Delete') {
        const stringValue = value?.toString() || '';
        if (stringValue.endsWith('0') || stringValue === '0') {
          return '';
        }
      }
      return inputTransform(value);
    };

    const outputTransform = (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = unFormatNumber(event.target.value);

      const output: string = input
        ? input.match(numRegex) || input.match(lastDecimalRegEx)
          ? input
          : input.slice(0, -1)
        : input.slice(0, -1);
      return output || '0';
    };

    return (
      <ControllerPlus<string, string>
        {...restProps}
        transform={{
          input: (value: string) => inputTransform(value),
          inputOnKeyDown: (value: string, event: React.KeyboardEvent<HTMLInputElement>) =>
            inputOnKeyDownTransform(value, event),
          output: (event: React.ChangeEvent<HTMLInputElement>) => outputTransform(event),
        }}
        control={control}
        errors={errors}
        name={name}
        validationMessage={validationMessage}
        isDirty={isDirty}
        isTouched={isTouched}
        isInvalid={invalid}
        displayValidation={displayValidation}
        maxLength={maxLength}
        className={className}
      />
    );
  }
);

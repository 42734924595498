import { GoBackToEnum } from '@harmoney/api-interfaces';
import { useGoBackMutation } from '@harmoney/redux';

type Props = {
  goBackTo: GoBackToEnum;
  variables?: any;
};

export const useGoBack = (taskId: string) => {
  const [goBack] = useGoBackMutation();

  const handleGoBack = async ({ goBackTo, variables }: Props) => {
    await goBack({ taskId, variables: { ...variables, goBackTo } });
  };
  return { handleGoBack };
};

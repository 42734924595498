import {
  ReprocessOperationDetail,
  SaveIncomesDto,
  SaveNewIncomeOverrideDto,
  SaveReprocessOperationDto,
  UpdateIncomesDto,
} from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const incomeApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    submitIncome: builder.mutation<void, SaveIncomesDto>({
      query: (data) => {
        return {
          url: `/financial-profile/incomes`,
          method: 'POST',
          body: data,
        };
      },
    }),
    submitIncomeByCO: builder.mutation<void, SaveNewIncomeOverrideDto>({
      query: (data) => {
        return {
          url: `/financial-profile/incomes/override-new`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['FinancialProfile'],
    }),
    updateIncome: builder.mutation<void, UpdateIncomesDto>({
      query: (data) => {
        return {
          url: `/financial-profile/incomes`,
          method: 'PUT',
          body: data,
        };
      },
    }),
    getSelfEmployedTypes: builder.query<string[], string>({
      query: () => ({
        url: `/financial-profile/self-employed-types`,
        method: 'GET',
      }),
    }),
    coReviewReprocess: builder.mutation<{ needForReprocess: boolean }, SaveReprocessOperationDto>({
      query: (data) => ({
        url: `/financial-profile/co-review/reprocess`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['ReprocessOperation'],
    }),
    getReprocessOperation: builder.query<ReprocessOperationDetail[], string>({
      query: (loanApplicationId: string) => {
        return {
          url: `/financial-profile/co-review/get-reprocess-operation`,
          method: 'GET',
          params: {
            loanApplicationId,
          },
        };
      },
      providesTags: ['ReprocessOperation'],
    }),
  }),
});

export const {
  useSubmitIncomeMutation,
  useUpdateIncomeMutation,
  useGetSelfEmployedTypesQuery,
  useCoReviewReprocessMutation,
  useGetReprocessOperationQuery,
  useSubmitIncomeByCOMutation,
} = incomeApi;

/* tslint:disable */
/* eslint-disable */
/**
 * Frankie Financial API
 * ------   This API allows developers to integrate the Frankie Financial Compliance Utility into their applications. The API allows:   - Checking name, address, date of birth against national databases   - Validating Australian driver's licences, passports, medicare, visas and other Australian national ID documents   - Validating Australian electricity bills   - Validating NZ driver's licences   - Validating Chinese bank cards and national ID card   - Validating International passports and national ID documents   - PEP, Sanctions, Watchlist and adverse media checking   - Australian visa checks    - Fraud list and fraud background checks   - ID validation and selfie check comparisons.    ------     Industry specific services    - Comparing Australian electricity retailers for a better deal.  ------     KYB specific services    - Query organisation ownership   - Perform KYC & AML checks on shareholders, beneficial owners and office bearers.   - Query credit score and credit reports   - International company searches   - International company profiles    ------   The full version of this documentation along with supplemental articles can be found here:   - https://apidocs.frankiefinancial.com/  ------ Sandbox base URL is:   - https://api.demo.frankiefinancial.io/compliance/v1.2      - All calls are the same as production, only with test data. You can download the latest test data here: https://apidocs.frankiefinancial.com/docs/test-data    - Full Swagger definition, along with test data for playing in the sandbox can be obtained once initial commercial discussions have commenced.    - Production and optional UAT access will be opened up only to those with a signed commercial contract.    ------   Contact us at hello@frankiefinancial.com to speak with a sales rep about issuing a Customer ID and Sandbox api key.
 *
 * OpenAPI spec version: 1.14.0
 * Contact: dev-support@frankiefinancial.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * A key/value pair of strings that describe the location of the issue (key) and an issue description (value). Also inclused is a severity
 * @export
 * @interface IssueListItems
 */
export interface IssueListItems {
  /**
   * Human readable description of the issue
   * @type {string}
   * @memberof IssueListItems
   */
  issueDescription?: string;
  /**
   * Where the issue occured. It will describe a location in the response structure
   * @type {string}
   * @memberof IssueListItems
   */
  issueLocation?: string;
  /**
   * The impact of the issue on the process.   Is it just informational, such as a trivial different in a name match? Is it a warning to highlight something that is important, but did not prevent the process from completing? Is it a critical issue that prevented the check from completing successfully? Is it a stop condition that prevented the checks from being run at all?
   * @type {string}
   * @memberof IssueListItems
   */
  issueSeverity?: IssueListItemsIssueSeverityEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum IssueListItemsIssueSeverityEnum {
  INFO = 'INFO',
  WARN = 'WARN',
  CRIT = 'CRIT',
  STOP = 'STOP',
}

import { forwardRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import type { RadioGroupProps as RadixRadioGroupProps } from '@radix-ui/react-radio-group';
import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import classNames from 'classnames';
import { kebabCase } from 'lodash';

import { CommonOptionProps } from '../../types';
import { sanitizeContent } from '../../utils';
import { CheckRadioIcon, IconV2 } from '../Icon';
import * as AllIcons from '../Icon/Icons/IconsWithVariant';
import { Label } from '../Label';
import { ValidationMessage } from '../ValidationMessage';

import styles from './RadioGroup.module.scss';

export interface RadioGroupProps extends Omit<RadixRadioGroupProps, 'asChild'> {
  /**
   * The name of the group. Submitted with its owning form as part of a name/value pair.
   */
  name: string;
  /**
   * The items to display in the radio group.
   * @param label The label to display for the item.
   * @param value The value of the item.
   * @param imageSrc The image to display to the left of the item.
   * @param iconName The icon to display to the left of the item.
   */
  options: CommonOptionProps[];
  /**
   * The label to display above the radio group.
   */
  label?: string;
  /**
   * Optional parameter to toggle the validation message display
   */
  displayValidationMessage?: boolean;
  /**
   * Optional parameter to add a description underneath the label
   */
  description?: string;
  withShadow?: boolean;
}

export const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  (
    {
      className,
      options,
      name,
      label,
      disabled = false,
      displayValidationMessage = true,
      description,
      withShadow = false,
      ...restProps
    }: RadioGroupProps,
    forwardRef: React.Ref<HTMLDivElement>
  ) => {
    const { control, formState, getFieldState } = useFormContext();
    const { invalid } = getFieldState(name, formState);

    const {
      field: { onChange, value, onBlur },
    } = useController({
      name,
      control,
    });

    const fieldValue = value?.toString();
    const optionsContent = (fieldValue: string) =>
      options &&
      options.length > 0 &&
      options.map(
        ({
          label,
          value,
          imageSrc,
          iconName,
          width = 24,
          height = 24,
          icon,
          iconSelected,
          children,
          subtitle,
          description,
        }) => {
          const internalIcon = AllIcons.getIconComponent(
            kebabCase(iconName),
            value === fieldValue ? 'secondary' : 'grey-4'
          );
          return (
            <label
              key={value}
              data-testid={value}
              className={classNames(
                styles['item'],
                withShadow && styles['item-shadow'],
                value === fieldValue && styles['item-checked'],
                invalid && styles['item-invalid'],
                disabled && styles['item-disabled']
              )}
            >
              <div className="flex w-full flex-col">
                <div className="flex flex-row justify-between">
                  <div className="flex w-full items-center">
                    {icon && (
                      <span className="mr-2 flex">
                        {typeof icon !== 'string' ? (
                          icon
                        ) : value === fieldValue && iconSelected ? (
                          <IconV2 icon={iconSelected} width={24} height={24} className="text-secondary" />
                        ) : (
                          <IconV2
                            icon={icon}
                            width={24}
                            height={24}
                            className={value === fieldValue ? 'text-secondary' : 'text-grey-4'}
                          />
                        )}
                      </span>
                    )}
                    {iconName && <span className="mr-2 flex">{internalIcon}</span>}
                    {imageSrc && (
                      <img
                        className="mr-2"
                        src={imageSrc}
                        alt={typeof label === 'string' ? label : ''}
                        width={width}
                        height={height}
                        draggable={false}
                      />
                    )}
                    {label &&
                      typeof label === 'string' &&
                      sanitizeContent({
                        content: label,
                        tag: 'div',
                        className: classNames(styles['item-label'], 'w-full'),
                      })}
                  </div>
                  <RadixRadioGroup.Item value={value}>
                    <CheckRadioIcon size="medium" />
                  </RadixRadioGroup.Item>
                </div>
                {subtitle && <p className="text-grey-4 text-sm ml-8">{subtitle}</p>}
                {description && value === fieldValue && iconSelected && (
                  <p className="text-grey-4 text-sm ml-8">{description}</p>
                )}
                {children && <div>{children}</div>}
              </div>
            </label>
          );
        }
      );

    return (
      <div className={className} ref={forwardRef}>
        {label && (
          <Label htmlFor={name} className="mb-2">
            {label}
          </Label>
        )}
        {description && <p className="text-grey-4 mb-2 text-sm">{description}</p>}
        <RadixRadioGroup.Root
          aria-labelledby={name}
          className="flex flex-col"
          defaultValue={fieldValue}
          value={fieldValue}
          onValueChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          id={name}
          data-testid={name}
          {...restProps}
        >
          {optionsContent(fieldValue)}
        </RadixRadioGroup.Root>
        {displayValidationMessage && <ValidationMessage name={name} />}
      </div>
    );
  }
);

import { formatCurrency } from '@harmoney/utilities';
import { RepaymentFrequencyEnum } from '@prisma/client';
import { z } from 'zod';

export const DEFAULT_TERM_IN_MONTHS = 84;
export const DEFAULT_REPAYMENT_FREQUENCY = RepaymentFrequencyEnum.monthly;

export const validationMessages = {
  loanPurposeForUnutilisedLimit: 'Please select a loan purpose',
  amount: (lowerLimit: number, upperLimit: number) =>
    lowerLimit === upperLimit
      ? `You can borrow a maximum of ${formatCurrency(upperLimit)}`
      : `Enter an amount between ${formatCurrency(lowerLimit)} and ${formatCurrency(upperLimit)}`,
  repaymentFrequency: 'Please select a repayment frequency',
  termOption: 'Please select a term option',
};

export const defaultValues = {
  loanPurposeForUnutilisedLimit: '',
  termInMonths: DEFAULT_TERM_IN_MONTHS,
  repaymentFrequency: DEFAULT_REPAYMENT_FREQUENCY,
};

export const createFormSchema = (lowerLimit: number, upperLimit: number) => {
  return z.object({
    amount: z.coerce
      .number()
      .gte(lowerLimit, {
        message: validationMessages.amount(lowerLimit, upperLimit),
      })
      .lte(upperLimit, {
        message: validationMessages.amount(lowerLimit, upperLimit),
      }),
    repaymentFrequency: z.nativeEnum(RepaymentFrequencyEnum, {
      invalid_type_error: validationMessages.repaymentFrequency,
    }),
    termInMonths: z.coerce
      .number({
        invalid_type_error: validationMessages.termOption,
        required_error: validationMessages.termOption,
      })
      .refine((data) => [36, 60, 84].includes(data), {
        message: validationMessages.termOption,
      }),
  });
};

export const createFormSchemaForUnutilisedLimit = (lowerLimit: number, upperLimit: number) => {
  return createFormSchema(lowerLimit, upperLimit).extend({
    loanPurposeForUnutilisedLimit: z.string().min(1, { message: validationMessages.loanPurposeForUnutilisedLimit }),
  });
};

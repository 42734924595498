import React from 'react';
import { essentialExpenseMapper } from '@harmoney/api-interfaces';
import { Alert, Dialog } from '@harmoney/ui-design-system';
import { Icon } from '@iconify/react';
import { NetworthSource } from '@prisma/client';
import { capitalize } from 'lodash';

interface ExpenseCategoryListProps {
  expenseCategories: NetworthSource[];
  isCategoriesOpen: boolean;
  onCategoriesOpen: (state: boolean) => void;
  onExpenseCategoryClick: (id: number) => void;
}

export const ExpenseCategoryList: React.FC<ExpenseCategoryListProps> = ({
  expenseCategories,
  isCategoriesOpen,
  onCategoriesOpen,
  onExpenseCategoryClick,
}) => {
  return (
    <>
      <Dialog
        title="Add expenses you pay for"
        open={isCategoriesOpen}
        onOpenChange={onCategoriesOpen}
        modal
        contentClassName={`${expenseCategories.length < 5 ? 'h-fit' : 'h-4/5 overflow-hidden'}`}
      >
        <div className="p-4">
          <Alert variant="info" className="mb-0">
            Skip the non-essentials; we&rsquo;re just after your must-haves!
          </Alert>
        </div>
        <ul className="mb-4 h-4/5 overflow-scroll sm:mb-0">
          {expenseCategories.map(({ id, name }, index) => (
            <li
              key={id}
              onClick={() => onExpenseCategoryClick(id)}
              className={`flex cursor-pointer items-center justify-between p-4 ${
                index === 0 ? 'border-t-none' : 'border-t-grey-2 border-t'
              } last:mb-8 last:pb-8 last:sm:mb-0`}
            >
              <span>{essentialExpenseMapper[id] ?? capitalize(name)}</span>
              <Icon icon="ic:round-chevron-right" width={24} />
            </li>
          ))}
        </ul>
      </Dialog>
    </>
  );
};

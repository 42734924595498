import { formatCurrency, formatFrequency } from '@harmoney/utilities';
import { IncomeAndExpenseFrequencyEnum } from '@prisma/client';

export enum ComparisonOperator {
  GREATER_THAN = 'greater than',
  LESS_THAN = 'less than',
}

export const errors = {
  requiredField: (fieldName: string) => `${fieldName} is required`,
  invalid: (fieldName: string) => `Please enter a valid ${fieldName}`,
  defaultRequiredField: 'Please select an option',
  defaultValidAmount: 'Please enter an amount greater than 0',
  defaultValidPercentage: 'Please enter a percentage between 0 and 100',
  invalidCreditLimit: 'Credit limit cannot be less than repayment amount',
  defaultRequiredFrequency: 'Please select a frequency',
  amountComparison: (comparison: ComparisonOperator, amount: number) =>
    `Please enter an amount ${comparison} ${amount === 0 ? 0 : formatCurrency(amount)}`,
  amountComparisonWithFrequency: (
    comparison: ComparisonOperator,
    amount: number,
    frequency: IncomeAndExpenseFrequencyEnum
  ) => `Please enter an amount ${comparison} ${formatCurrency(amount)}/${formatFrequency(frequency)}`,
};

export const SOMETHING_WENT_WRONG = 'Something went wrong...';

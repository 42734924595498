import { generateIcon } from '../../generateIcon';
import { IconVariant } from '../../Icon';

export const EducationIcon = function ({ variant = 'grey-4' }: { variant?: IconVariant }) {
  const Component = generateIcon({
    viewBox: '0 0 24 24',
    variant: variant,
    path: (
      <>
        <g clipPath="url(#clip0_4222_53308)">
          <path d="M5.00125 13.1793V15.9893C5.00125 16.7193 5.40125 17.3993 6.04125 17.7493L11.0413 20.4793C11.6413 20.8093 12.3613 20.8093 12.9613 20.4793L17.9612 17.7493C18.6012 17.3993 19.0012 16.7193 19.0012 15.9893V13.1793L12.9613 16.4793C12.3613 16.8093 11.6413 16.8093 11.0413 16.4793L5.00125 13.1793ZM11.0413 3.5193L2.61125 8.1193C1.92125 8.4993 1.92125 9.4993 2.61125 9.8793L11.0413 14.4793C11.6413 14.8093 12.3613 14.8093 12.9613 14.4793L21.0012 10.0893V15.9993C21.0012 16.5493 21.4512 16.9993 22.0012 16.9993C22.5512 16.9993 23.0012 16.5493 23.0012 15.9993V9.5893C23.0012 9.2193 22.8012 8.8893 22.4812 8.7093L12.9613 3.5193C12.3613 3.1993 11.6413 3.1993 11.0413 3.5193V3.5193Z" />
        </g>
        <defs>
          <clipPath id="clip0_4222_53308">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </>
    ),
  });
  return <Component />;
};

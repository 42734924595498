import { LoanWriteOff, PaymentTransaction } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const loanWriteOffApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getTransactionsForWriteOffValidation: builder.query<PaymentTransaction[], string>({
      query: (loanApplicationId) => ({
        url: `/loan-write-off/${loanApplicationId}/transactions`,
        method: 'GET',
      }),
    }),
    getWriteOffByLoanApplicationId: builder.query<LoanWriteOff, string>({
      query: (loanApplicationId) => ({
        url: `/loan-write-off/${loanApplicationId}/write-off`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetTransactionsForWriteOffValidationQuery, useGetWriteOffByLoanApplicationIdQuery } = loanWriteOffApi;

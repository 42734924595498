import React, { forwardRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { PropsWithHTMLElement } from '../../types';
import { BaseFormFieldProps, FormField } from '../FormField';
import { HelpText } from '../HelpText';
import { Label } from '../Label';
import { ValidationMessage } from '../ValidationMessage';

export interface TextareaInternalProps extends Omit<BaseFormFieldProps, 'prefix' | 'suffix'> {
  name: string;
  placeholder?: string;
  rows?: number;
  cols?: number;
}

export type TextareaProps = PropsWithHTMLElement<TextareaInternalProps, 'textarea'>;

export const Textarea = forwardRef<HTMLDivElement, TextareaProps>(
  (
    {
      className,
      label,
      cols,
      readOnly,
      placeholder,
      helpText,
      name,
      disabled = false,
      rows = 3,
      ...restProps
    }: TextareaProps,
    forwardRef: React.Ref<HTMLDivElement>
  ) => {
    const {
      control,
      getFieldState,
      formState,
      formState: { errors },
    } = useFormContext();

    const { field } = useController({
      name,
      control,
      defaultValue: '',
    });

    const { isDirty, isTouched, invalid } = getFieldState(name, formState);

    return (
      <div className={className} ref={forwardRef}>
        {label && (
          <Label htmlFor={name} className="mb-2">
            {label}
          </Label>
        )}
        {helpText && <HelpText>{helpText}</HelpText>}
        <FormField isDirty={isDirty} isTouched={isTouched} isInvalid={invalid} disabled={disabled} readOnly={readOnly}>
          <textarea
            id={name}
            data-testid={name}
            rows={rows}
            cols={cols}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readOnly}
            aria-invalid={errors[name] ? 'true' : undefined}
            aria-readonly={readOnly ? 'true' : undefined}
            {...restProps}
            {...field}
          />
        </FormField>
        <ValidationMessage name={name} />
      </div>
    );
  }
);

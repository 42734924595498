import { formatCurrency } from '@harmoney/utilities';
import dayjs from 'dayjs';
import { isNumber } from 'lodash';

import { DATE_TIME_FORMAT } from './constants';

/**
 * The function `booleanToYesNo` converts a boolean value to a string representation of "Yes" or "No".
 * @param {boolean} value - The value parameter is a boolean value that represents a true or false
 * condition.
 */
export const booleanToYesNo = (value: boolean) => (value ? 'Yes' : 'No');

/**
 * The function `valueOrNA` returns the input value if it is truthy, otherwise it returns the string
 * 'N/A'.
 * @param {string | number | undefined} value - The `value` parameter can be of type `string`,
 * `number`, or `undefined`.
 */
export const valueOrNA = (value: string | number | undefined) => (value ? value : 'N/A');

/**
 * The function `valueOrNA` returns the input value if it is truthy, otherwise it returns the string
 * '-'.
 * @param {string | number | undefined} value - The `value` parameter can be of type `string`,
 * `number`, or `undefined`.
 */
export const valueOrDash = (value: string | number | undefined) => (value ? value : '-');

/**
 * The function `amountOrNA` returns the input value if it is truthy, otherwise it returns the string
 * 'N/A'.
 * @param {string | number | undefined} value - The `value` parameter can be of type `string`,
 * `number`, or `undefined`.
 */
export const amountOrNA = (value: string | number | undefined) => (value ? formatCurrency(Number(value), 2) : 'N/A');

/**
 * The function `amountOrDash` checks if the provided value is a valid number. If it is, the function
 * returns the value formatted as a currency string (e.g., "$5"). If the value is not a valid number,
 * the function returns a dash ("-").
 * @param {number | null | undefined} value - The `value` parameter can be of type `number`, `null`,
 * or `undefined`.
 * @returns {string} - Returns the value formatted as a currency string or a dash ("-") if the value
 * is not a valid number.
 */
export const amountOrDash = (value: number | null | undefined): string => {
  return isNumber(value) ? `$${value}` : '-';
};

export const booleanToYesNoOrNA = (value: boolean | undefined) => (value === undefined ? 'N/A' : booleanToYesNo(value));

/**
 * Formats a given date string or Date object according to the specified format.
 * If the date is null or undefined, returns a dash ('-').
 *
 * @param {string | Date | null | undefined} date - The date to format. Can be a string, Date object, null, or undefined.
 * @param {string} [format=DATE_TIME_FORMAT] - The format string to use for formatting the date. Defaults to DATE_TIME_FORMAT.
 * @returns {string} The formatted date string or a dash ('-') if the date is null or undefined.
 */
export const dateOrDash = (date: string | Date | null | undefined, format: string = DATE_TIME_FORMAT): string => {
  // use dayjs to format the date
  return dayjs(date).isValid() ? dayjs(date).format(format) : '-';
};

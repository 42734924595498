import { CreditFileDto } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const creditFileApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserCreditFiles: builder.query<CreditFileDto[], string>({
      query: (userId) => ({
        url: `/credit-file`,
        method: 'GET',
        params: {
          userId,
        },
      }),
    }),
    getCreditFileUrl: builder.query<string, string>({
      query: (fileName) => ({
        url: '/credit-file/file',
        method: 'GET',
        params: {
          fileName: encodeURIComponent(fileName),
        },
      }),
    }),
  }),
});

export const { useGetUserCreditFilesQuery, useLazyGetCreditFileUrlQuery } = creditFileApi;

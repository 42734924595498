import Link from 'next/link';
import { useMemo, useState } from 'react';
import { loanStatusEmojiMapper, preQualifiedQuoteLabelMapper } from '@harmoney/api-interfaces';
import {
  ExtendedPreQualifiedQuoteWithUser,
  useAppSelector,
  useGetAllPreQualifiedQuotesForAdminQuery,
} from '@harmoney/redux';
import { isPreview } from '@harmoney/ui-app-shell';
import { CopyButton } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';
import { useDebouncedValue } from '@mantine/hooks';
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';

import { FixedWidthForFirstColumn } from '../application-hub';
import { DataTable } from '../DataTable';

import { PreQualifiedQuoteStats } from './PreQualifiedQuoteStats';

export const PreQualifiedQuoteDataTable = () => {
  const accessToken = useAppSelector((state) => state.accessToken.value);
  const [globalFilter, setGlobalFilter] = useState('');
  const [paginationOptions, setPaginationOptions] = useState({
    pageIndex: 0,
    pageSize: 100,
  });

  const [debounced] = useDebouncedValue(globalFilter, 400);

  const { data: preQualifiedQuotesData } = useGetAllPreQualifiedQuotesForAdminQuery(
    {
      page: paginationOptions.pageIndex + 1,
      limit: paginationOptions.pageSize,
      search: encodeURI(debounced),
    },
    {
      skip: !accessToken,
      refetchOnMountOrArgChange: true,
    }
  );

  const preQualifiedQuotes = preQualifiedQuotesData?.data || [];
  const total = preQualifiedQuotesData?.total;
  const pageCount = preQualifiedQuotesData?.page;

  const fixedWidthForFirstColumn: FixedWidthForFirstColumn = {
    width: isPreview() ? 'w-[180px]' : 'w-[150px]',
    minWidthWithPadding: isPreview() ? 'min-w-[212px]' : 'min-w-[182px]',
    leftWidthWithPadding: '0px',
  };

  const columns = useMemo<ColumnDef<ExtendedPreQualifiedQuoteWithUser>[]>(
    () => [
      {
        header: 'Customer number',
        accessorKey: 'user.businessKey',
        id: 'user_businessKey',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            <Link href={`/admin/details?userId=${row.original.userId}#customer-profile`} passHref>
              {row.original?.user?.businessKey}
            </Link>
            <CopyButton valueToCopy={row.original?.user?.businessKey} size="small" />
          </div>
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        header: 'ID',
        accessorKey: 'id',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            <Link
              href={`/admin/details?userId=${row.original.userId}#pq/${row.original.id}`}
              passHref
              className="w-[10rem]"
            >
              {row.original.id}
            </Link>
            <CopyButton valueToCopy={row.original.id} size="small" />
          </div>
        ),
        enableSorting: false,
      },
      {
        header: 'Customer name',
        accessorKey: 'firstName',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            <Link href={`/admin/details?userId=${row.original.userId}#customer-profile`} passHref>
              {row.original.firstName} {row.original.lastName}
            </Link>
            <CopyButton valueToCopy={`${row.original.firstName} ${row.original.lastName}`} size="small" />
          </div>
        ),
      },
      {
        header: 'Email',
        accessorKey: 'email',
        cell: ({ row }) => <span>{row?.original?.user?.email}</span>,
      },
      {
        header: 'Product',
        accessorKey: 'variables.product.name',
        cell: ({ row }) => <span>{row?.original?.variables?.product?.name}</span>,
      },
      {
        header: 'Loan purpose',
        accessorKey: 'loanPurpose',
        cell: ({ row }) => <span>{row.original.loanPurpose}</span>,
      },
      {
        header: 'Status',
        accessorKey: 'quoteStatus',
        cell: ({ row }) => <span>{preQualifiedQuoteLabelMapper[row.original.quoteStatus]}</span>,
      },
      {
        header: 'Requested amount',
        accessorKey: 'requestedAmount',
        cell: ({ row }) => <span>{formatCurrency(row.original.requestedAmount)}</span>,
      },
      {
        header: 'Application(s)',
        accessorKey: 'loanApplications',
        cell: ({ row }) => (
          <div className="flex flex-col gap-1 text-nowrap">
            {row.original.quoteStatus === 'QUOTE' && row?.original?.loanApplications?.length > 0 ? (
              row?.original?.loanApplications.map((application) => (
                <Link
                  key={application.id}
                  href={`/admin/details/?userId=${row.original.userId}#applications/${application.id}`}
                >
                  {application.businessKey} {loanStatusEmojiMapper[application.status]}
                </Link>
              ))
            ) : (
              <span>No applications</span>
            )}
          </div>
        ),
      },
      {
        header: 'Created on',
        accessorKey: 'createdAt',
        cell: ({ row }) => (
          <>
            <span className="block">{dayjs(row.original.createdAt).format('DD/MM/YYYY')}</span>
            <span className="text-xs text-grey-4">{dayjs(row.original.createdAt).format('hh:mm:ss')}</span>
          </>
        ),
      },
      {
        header: 'Updated on',
        accessorKey: 'updatedAt',
        cell: ({ row }) => (
          <>
            <span className="block">{dayjs(row.original.updatedAt).format('DD/MM/YYYY')}</span>
            <span className="text-xs text-grey-4">{dayjs(row.original.updatedAt).format('hh:mm:ss')}</span>
          </>
        ),
      },
      {
        header: 'Expired on',
        accessorKey: 'quoteExpiryDate',
        cell: ({ row }) => (
          <>
            {dayjs(row.original.quoteExpiryDate).isValid() ? (
              <>
                <span className="block">{dayjs(row.original.quoteExpiryDate).format('DD/MM/YYYY')}</span>
                <span className="text-xs text-grey-4">{dayjs(row.original.quoteExpiryDate).format('hh:mm:ss')}</span>
              </>
            ) : (
              <span>-</span>
            )}
          </>
        ),
      },
      {
        header: 'Branch',
        accessorKey: 'variables.product.branch',
        cell: ({ row }) => <span>{row?.original?.variables?.product?.branch}</span>,
      },
    ],
    []
  );

  return (
    <div className="flex">
      <div className="px-6 pb-6 w-full">
        <PreQualifiedQuoteStats />
        <DataTable
          title="All Pre-qualified Quotes"
          data={preQualifiedQuotes}
          columns={columns}
          pageIndex={paginationOptions.pageIndex}
          pageSize={paginationOptions.pageSize}
          pageCount={pageCount}
          total={total}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          setPagination={setPaginationOptions}
          fixedWidthForFirstColumn={fixedWidthForFirstColumn}
        />
      </div>
    </div>
  );
};

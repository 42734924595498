import { useEffect, useState } from 'react';
import { RepaymentScheduleResponseDto } from '@harmoney/api-interfaces';
import { useFriendlyURL } from '@harmoney/hooks';
import {
  useAppSelector,
  useGetRepaymentDetailForUnutilisedLimitQuery,
  useGetRepaymentDetailQuery,
  useGetRepaymentScheduleQuery,
  useGetVariablesQuery,
  useLazyGetRepaymentScheduleQuery,
  useSavePaymentForUnutilisedLimitMutation,
  useSavePaymentMutation,
} from '@harmoney/redux';
import { APPLICATION_APPROVED, eventAnalytics, LOAN_PAYMENTS_ACCEPTED } from '@harmoney/ui-app-shell';
import { Spinner, useForm } from '@harmoney/ui-design-system';
import { Alert, ArrowCircleRightIcon, Button, Form } from '@harmoney/ui-design-system';
import dayjs from 'dayjs';
import { z } from 'zod';

import { defaultValues, formSchemaForPersonalLoan } from '../form-config';
import { formatDate, normalizeBankAccount } from '../utils';

import { PaymentLoanDetails } from './PaymentLoanDetails';
import { RepaymentPlan } from './RepaymentPlan';

interface PaymentFormProps {
  taskId: string;
  taskFriendlyURL: string;
  repaymentDetailQuery: typeof useGetRepaymentDetailQuery | typeof useGetRepaymentDetailForUnutilisedLimitQuery;
  savePaymentMutation: typeof useSavePaymentMutation | typeof useSavePaymentForUnutilisedLimitMutation;
  onNext: () => void;
  completeTask: ({ taskId }: { taskId: string }) => void;
}

export function PaymentForm({
  taskId,
  taskFriendlyURL,
  repaymentDetailQuery,
  savePaymentMutation,
  onNext,
  completeTask,
}: PaymentFormProps) {
  useFriendlyURL(taskFriendlyURL);

  const userId = useAppSelector((state) => state.userId.value);
  const { data: variables } = useGetVariablesQuery(taskId);
  const {
    data: repaymentDetail,
    isLoading: isRepaymentDetailLoading,
    isSuccess: isRepaymentDetailSuccess,
  } = repaymentDetailQuery(variables?.loanApplicationId?.toString(), {
    skip: !variables,
  });
  const { data: repaymentSchedule } = useGetRepaymentScheduleQuery(
    {
      loanApplicationId: variables?.loanApplicationId?.toString(),
      startDate: formatDate(repaymentDetail?.prefill?.startDate),
      frequency: repaymentDetail?.prefill?.frequency,
    },
    {
      skip: !variables || !repaymentDetail?.prefill?.startDate || !repaymentDetail?.prefill?.frequency,
    }
  );
  const [trigger] = useLazyGetRepaymentScheduleQuery();
  const [savePayment] = savePaymentMutation();

  const form = useForm({
    mode: 'onTouched',
    schema: formSchemaForPersonalLoan,
    defaultValues,
  });

  const {
    reset,
    getValues,
    clearErrors,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;

  const [repaymentScheduleData, setRepaymentScheduleData] = useState<RepaymentScheduleResponseDto>(null);

  useEffect(() => {
    if (repaymentDetail) {
      eventAnalytics.track(APPLICATION_APPROVED, { taskid_str: taskId });
    }
  }, [repaymentDetail, taskId]);

  useEffect(() => {
    if (!repaymentDetail) return;

    const bsb = repaymentDetail?.prefill?.repaidFrom?.bsb;
    const accountNumber = repaymentDetail?.prefill?.repaidFrom?.accountNumber;

    reset({
      ...getValues(),
      repaymentFromBankAccount:
        bsb && bsb !== 'None' && accountNumber ? normalizeBankAccount(bsb, accountNumber) : null,
      repaymentStartDate: repaymentDetail?.prefill?.startDate
        ? dayjs(repaymentDetail?.prefill?.startDate).toDate()
        : dayjs().toDate(),
      repaymentFrequency: repaymentDetail?.prefill?.frequency || null,
    });
  }, [reset, getValues, repaymentDetail]);

  useEffect(() => {
    if (repaymentSchedule) {
      setRepaymentScheduleData(repaymentSchedule);
    }
  }, [repaymentSchedule]);

  const handleRepaymentScheduleChange = async () => {
    const loanApplicationId = variables?.loanApplicationId?.toString();
    const startDate = formatDate(getValues('repaymentStartDate'));
    const frequency = getValues('repaymentFrequency');

    if (startDate && !frequency) {
      clearErrors('repaymentFrequency');
    }

    if (!startDate || !frequency) return;

    const repaymentSchedule = await trigger({ loanApplicationId, startDate, frequency }, true);

    if (repaymentSchedule.isSuccess) {
      setRepaymentScheduleData(repaymentSchedule.data);
    }
  };

  const handleSubmit = async (data: z.infer<typeof formSchemaForPersonalLoan>) => {
    const extractBankDetails = (bankAccount: string) => {
      const matchedBankAccount = repaymentDetail.bankAccounts.find(
        ({ accountNumber, bsb }) => bankAccount === normalizeBankAccount(bsb, accountNumber)
      );

      return { bsb: matchedBankAccount.bsb, accountNumber: matchedBankAccount.accountNumber };
    };

    const paidTo = extractBankDetails(data.loanToBankAccount);
    const repaidFrom = extractBankDetails(data.repaymentFromBankAccount);

    const repaymentSchedule = {
      loanApplicationId: variables?.loanApplicationId?.toString(),
      startDate: formatDate(data.repaymentStartDate),
      frequency: data.repaymentFrequency,
    };

    eventAnalytics.track(LOAN_PAYMENTS_ACCEPTED, {
      userid_str: userId,
      taskid_str: taskId,
    });

    await savePayment({
      paidTo,
      repaidFrom,
      repaymentSchedule,
    });

    onNext ? onNext() : completeTask({ taskId });
  };

  if (!repaymentDetail || isRepaymentDetailLoading || !isRepaymentDetailSuccess) return <Spinner />;

  return (
    <>
      <Alert variant="success" title="Thanks!">
        <p className="text-sm">We&rsquo;ve approved your application.</p>
      </Alert>
      <h1>
        Your <strong className="text-primary">payment details</strong>
      </h1>
      <Form form={form} onSubmit={handleSubmit}>
        <PaymentLoanDetails repaymentDetail={repaymentDetail} showLoanSummary />
        <RepaymentPlan
          repaymentDetail={repaymentDetail}
          repaymentScheduleData={repaymentScheduleData}
          onRepaymentScheduleChange={handleRepaymentScheduleChange}
        />
        <Button
          type="submit"
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          isLoading={isSubmitting || isSubmitSuccessful}
          hasShadow
        >
          Continue
        </Button>
      </Form>
    </>
  );
}

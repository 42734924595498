import { PaymentTypeEnum } from '@prisma/client';

export const bnplProviderOptions = [
  {
    label: 'Select Provider',
    value: '',
  },
  {
    label: 'Afterpay',
    value: 'afterpay',
  },
  {
    label: 'Zip',
    value: 'zip',
  },
  {
    label: 'Laybuy',
    value: 'laybuy',
  },
  {
    label: 'Klarna',
    value: 'klarna',
  },
  {
    label: 'Other',
    value: 'other',
  },
];
export const PaymentTypeOptions = [
  {
    label: 'BPAY',
    value: PaymentTypeEnum.BPAY,
  },
  { label: 'BSB', value: PaymentTypeEnum.BSB },
];
export const providerOptions = [
  {
    label: 'Select Provider',
    value: '',
  },
  {
    label: 'Commonwealth Bank ',
    value: 'commonwealth-bank',
  },
  {
    label: 'Westpac',
    value: 'westpac',
  },
  {
    label: 'ANZ',
    value: 'anz',
  },
  {
    label: 'NAB',
    value: 'nab',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

import { FeedbackDto } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const aiApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAIResult: builder.query<
      { prompt: string; result: JSON; traceId: string },
      { promptId: string; entityId: string }
    >({
      query: ({ promptId, entityId }) => ({
        url: '/ai-analyse/result',
        method: 'GET',
        params: { promptId, entityId },
      }),
    }),
    sendFeedback: builder.mutation<void, FeedbackDto>({
      query: (feedback) => ({
        url: '/ai-analyse/feedback',
        method: 'POST',
        body: feedback,
      }),
    }),
    analyseBankStatements: builder.query<{ prompt: string; response: JSON; traceId: string }, string>({
      query: (entityId) => ({
        url: '/ai-analyse/analyse-bank-statements',
        method: 'GET',
        params: { entityId },
        timeout: 90000,
      }),
    }),
  }),
});

export const { useSendFeedbackMutation, useLazyAnalyseBankStatementsQuery, useGetAIResultQuery } = aiApi;

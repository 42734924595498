import { generateIcon } from '../../generateIcon';
import { IconVariant } from '../../Icon';

export const NewVehicleIcon = function ({ variant = 'grey-4' }: { variant?: IconVariant }) {
  const Component = generateIcon({
    viewBox: '0 0 24 24',
    variant: variant,
    path: (
      <>
        <g clipPath="url(#clip0_4491_50209)">
          <rect width="24" height="24" fill="white" />
          <path d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5H6.5C5.84 5 5.29 5.42 5.08 6.01L3.11 11.68C3.04 11.89 3 12.11 3 12.34V19.5C3 20.33 3.67 21 4.5 21C5.33 21 6 20.33 6 19.5V19H18V19.5C18 20.32 18.67 21 19.5 21C20.32 21 21 20.33 21 19.5V12.34C21 12.12 20.96 11.89 20.89 11.68L18.92 6.01ZM6.5 16C5.67 16 5 15.33 5 14.5C5 13.67 5.67 13 6.5 13C7.33 13 8 13.67 8 14.5C8 15.33 7.33 16 6.5 16ZM17.5 16C16.67 16 16 15.33 16 14.5C16 13.67 16.67 13 17.5 13C18.33 13 19 13.67 19 14.5C19 15.33 18.33 16 17.5 16ZM5 11L6.27 7.18C6.41 6.78 6.79 6.5 7.22 6.5H16.78C17.21 6.5 17.59 6.78 17.73 7.18L19 11H5Z" />
          <path d="M15.0421 3.68052L16.871 4.38656L15.6806 2.8289L16.3866 0.999954L14.8289 2.19041L13 1.48438L14.1905 3.04204L13.4844 4.87098L15.0421 3.68052Z" />
          <path d="M21.6573 3.48819L24.0373 4.40696L22.4882 2.37998L23.407 -4.71046e-07L21.38 1.54913L19 0.630371L20.5491 2.65735L19.6304 5.03733L21.6573 3.48819Z" />
          <path d="M22.0421 8.68052L23.871 9.38656L22.6806 7.8289L23.3866 5.99995L21.8289 7.19041L20 6.48438L21.1905 8.04204L20.4844 9.87098L22.0421 8.68052Z" />
        </g>
        <defs>
          <clipPath id="clip0_4491_50209">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </>
    ),
  });
  return <Component />;
};

import { useMemo } from 'react';
import {
  AffordabilityModelDetailDto,
  NetworthSourceDisplayNameEnum,
  SelectedSourceEnum,
} from '@harmoney/api-interfaces';
import { formatNumber } from '@harmoney/utilities';
import classNames from 'classnames';

import {
  getFinalValue,
  getMonthlyAffordabilityValue,
  RowData,
  rowIsGrey,
  valueIsSelected,
} from './affordability-utils';
type Props = {
  affordabilityData: AffordabilityModelDetailDto;
};

export const DebtsTableV2 = ({ affordabilityData }: Props) => {
  const debtItems = affordabilityData?.items?.debts;
  const finalTotalDebt = formatNumber(affordabilityData?.overview?.totalDebtRepayment, 2);

  const rowsData: RowData[] = useMemo(() => {
    const createRowData = (
      title: string,
      netWorthSource: NetworthSourceDisplayNameEnum,
      isSharedExpense: boolean = false
    ): RowData => {
      const getValue = (source: SelectedSourceEnum) =>
        getMonthlyAffordabilityValue(netWorthSource, source, debtItems, isSharedExpense);

      const formatValue = (source: SelectedSourceEnum) => formatNumber(getValue(source), 2);

      const isValueSelected = (source: SelectedSourceEnum) => valueIsSelected(source, netWorthSource, debtItems);

      return {
        title,
        declared: {
          value: formatValue(SelectedSourceEnum.DECLARED),
          isSelected: isValueSelected(SelectedSourceEnum.DECLARED),
        },
        bankStatement: {
          value: formatValue(SelectedSourceEnum.BANK_STATEMENT),
          isSelected: isValueSelected(SelectedSourceEnum.BANK_STATEMENT),
        },
        minAcceptableValue: {
          value: formatValue(SelectedSourceEnum.MINIMUM_ACCEPTABLE_VALUE),
          isSelected: isValueSelected(SelectedSourceEnum.MINIMUM_ACCEPTABLE_VALUE),
        },
        override: {
          value: formatValue(SelectedSourceEnum.CO_OVERRIDE),
          isSelected: isValueSelected(SelectedSourceEnum.CO_OVERRIDE),
        },
        final: {
          value: formatNumber(getFinalValue(netWorthSource, debtItems, isSharedExpense), 2),
          isSelected: true,
        },
      };
    };

    return [
      createRowData('Mortage', NetworthSourceDisplayNameEnum.LIABILITY_MORTGAGE_NAME),
      createRowData('CC / Line of Credit', NetworthSourceDisplayNameEnum.LIABILITY_CREDIT_CARD_NAME),
      createRowData('Overdraft', NetworthSourceDisplayNameEnum.LIABILITY_OVERDRAFT_NAME),
      createRowData('Personal Loan', NetworthSourceDisplayNameEnum.LIABILITY_PERSONAL_LOAN_NAME),
      createRowData('Undisclosed debts (Auto loan)', NetworthSourceDisplayNameEnum.LIABILITY_UNDISCLOSED_CAR_LOAN),
      createRowData('Other', NetworthSourceDisplayNameEnum.LIABILITY_OTHER_NAME),
    ];
  }, [debtItems]);

  return (
    <table className="border-grey-2 w-full table-fixed border">
      <thead>
        <tr className="divide-grey-2 bg-grey-1 divide-x">
          <th className="w-1/5">Debts Repayments</th>
          <th className="w-1/5">Declared</th>
          <th className="w-1/5">Bank Statement</th>
          <th className="w-1/5">Min. Value</th>
          <th className="w-1/5 bg-grey-2">{/* Co-Override */}</th>
          <th className="w-1/5">Final</th>
        </tr>
      </thead>
      <tbody>
        {rowsData.map((row) => (
          <tr key={row.title} className="divide-grey-2 border-grey-2 divide-x border">
            <td className={classNames('bg-grey-1 px-2 py-1 font-bold', { 'text-grey-2': rowIsGrey(row) })}>
              {row.title}
            </td>
            <td className={classNames('px-2 text-right', { 'bg-grey-2': !row.declared.value })}>
              <span className={classNames({ 'font-normal': row.declared.isSelected })}>{row.declared.value ?? ''}</span>
            </td>
            <td className={classNames('px-2 text-right', { 'bg-grey-2': !row.bankStatement.value })}>
              <span className={classNames({ 'font-normal': row.bankStatement.isSelected })}>
                {row.bankStatement.value ?? ''}
              </span>
            </td>
            <td className={classNames('px-2 text-right', { 'bg-grey-2': !row.minAcceptableValue.value })}>
              <span className={classNames({ 'font-normal': row.minAcceptableValue.isSelected })}>
                {row.minAcceptableValue.value ?? ''}
              </span>
            </td>
            <td className={classNames('px-2 text-right', { 'bg-grey-2': !row.override.value })}>
              <span className={classNames({ 'font-normal': row.override.isSelected })}>{row.override.value ?? ''}</span>
            </td>
            <td className={classNames('px-2 text-right', { 'bg-grey-2': !row.final.value })}>
              <span className={classNames({ 'font-bold': row.final.isSelected })}>{row.final.value ?? ''}</span>
            </td>
          </tr>
        ))}
        <tr>
          <td />
          <td />
          <td />
          <td />
          <td className="bg-grey-1 border-x-grey-2 border-x px-2 py-1 font-bold">Total Debts</td>
          <td className="px-2 text-right font-bold">{finalTotalDebt}</td>
        </tr>
      </tbody>
    </table>
  );
};

import { useEffect, useMemo, useState } from 'react';
import { DebtConsolidationLiabilityDto } from '@harmoney/api-interfaces';
import { useReprocessObjectiveQuery } from '@harmoney/redux';
import { AssetAndLiability, NetworthSource } from '@prisma/client';

import { useDebtConBenefits } from './use-debt-con-benefits';

export interface ConsolidateDebt {
  interestRate: number;
  maximumBorrowingLimit: number;
  postApprovalDebtsData: Partial<AssetAndLiability & { networthSource: Partial<NetworthSource> }>[];
  hmyRepaymentAmount: number;
}
export const useCalculateDebtConBenefits = ({
  postApprovalDebtsData,
  interestRate,
  maximumBorrowingLimit,
  hmyRepaymentAmount,
}: ConsolidateDebt) => {
  const [debts, setDebts] = useState<DebtConsolidationLiabilityDto[]>();

  const { data } = useReprocessObjectiveQuery(
    {
      debts,
      interestRate,
      maximumBorrowingLimit: maximumBorrowingLimit,
      hmyRepaymentAmount: hmyRepaymentAmount,
    },
    { skip: !debts || !interestRate || !maximumBorrowingLimit || !hmyRepaymentAmount }
  );
  const benefits = useDebtConBenefits(data);

  useEffect(() => {
    const debts = [];
    if (postApprovalDebtsData) {
      postApprovalDebtsData.forEach((selectedDebt) => {
        if (selectedDebt) {
          const debt: DebtConsolidationLiabilityDto = {
            liability: {
              ...selectedDebt,
            },
            isEligibleForLowerInterest: false,
            isEligibleForPaylessMonthly: false,
            isEligibleForDebtCombination: false,
          };
          debts.push(debt);
        }
      });
    }
    setDebts(debts);
  }, [postApprovalDebtsData]);

  return useMemo(() => {
    if (benefits && data) {
      return benefits;
    }
  }, [benefits, data]);
};

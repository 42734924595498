import { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { LoanApplicationStatusEnum } from '@prisma/client';

import { useCancelLoanApplicationMutation } from '../redux/services';
import { setDebtFormData } from '../redux/slice/debt-form';
import { useAppSelector } from '../redux/store';

type CancelQuoteProps = {
  showCancelQuoteDialog: boolean;
  setShowCancelQuoteDialog: Dispatch<SetStateAction<boolean>>;
};

type CancelQuote = {
  showCancelQuoteDialog: boolean;
  handleCancelQuote: (props: HandleCancelQuoteProps) => void;
};

type HandleCancelQuoteProps = {
  applicationId: string;
  cancellationReasonId?: string;
  cancellationReason?: string;
  cancellationReasonOtherText?: string;
  type: 'quote' | 'application';
};

export const useCancelQuote = ({ setShowCancelQuoteDialog, showCancelQuoteDialog }: CancelQuoteProps): CancelQuote => {
  const [cancelApplication] = useCancelLoanApplicationMutation();
  const userId = useAppSelector((state) => state.userId.value);
  const debtForm = useAppSelector((state) => state.debtForm);
  const dispatch = useDispatch();

  const handleCancelQuote = async ({
    applicationId,
    cancellationReasonId,
    cancellationReason,
    cancellationReasonOtherText,
    type,
  }: HandleCancelQuoteProps) => {
    if (debtForm) {
      dispatch(setDebtFormData(null));
    }
    await cancelApplication({
      applicationId,
      userId,
      status:
        type === 'application'
          ? LoanApplicationStatusEnum.application_cancelled
          : LoanApplicationStatusEnum.quote_cancelled,
      cancellationReasonId,
      cancellationReason,
      cancellationReasonOtherText,
    });
    setShowCancelQuoteDialog(false);
  };

  return {
    showCancelQuoteDialog,
    handleCancelQuote,
  };
};

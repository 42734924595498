import { Alert, Badge, Button, Card, sanitizeContent } from '@harmoney/ui-design-system';
import { bankStatementErrorsMapper } from '@harmoney/ui-utils';
import { Icon } from '@iconify/react';
import {
  BankStatementOperation,
  BankStatementOperationStatus,
  BankStatementReference,
  BankStatementReferenceStatusEnum,
} from '@prisma/client';

type Props = {
  bankStatementReferences: (BankStatementReference & {
    operations: Pick<BankStatementOperation, 'rawResponse' | 'status' | 'updatedAt'>[];
  })[];
  handleRetryClick: (appReference: string) => void;
};

const getBankStatementStatus = (status: BankStatementReferenceStatusEnum, operations) => {
  if (status === 'callback_received' || status === 'ui_completed' || !operations) {
    return 'Processing';
  }
  if (operations && operations?.[0]?.status === BankStatementOperationStatus.SUCCESS) {
    return 'Success';
  }
  return null;
};

const getBadgeVariant = (status: BankStatementReferenceStatusEnum, operations) => {
  if (status === 'callback_received' || status === 'ui_completed' || !operations) {
    return 'tertiary';
  }
  if (operations && operations?.[0]?.status === BankStatementOperationStatus.SUCCESS) {
    return 'success';
  }
  return null;
};

export function BankStatementSummaryRender({ bankStatementReferences, handleRetryClick }: Props) {
  return (
    <>
      {bankStatementReferences && bankStatementReferences.length > 0 && (
        <Card className="!p-0">
          <p className="mb-0 p-4 font-medium">Added bank statements</p>
          <ul>
            {bankStatementReferences.map(({ id, bankName, status, operations, appReference }) => (
              <li key={id} className="border-t-grey-2 border-t p-4 flex flex-col gap-2">
                <div className="flex items-center justify-between">
                  <div className="flex">
                    <Icon icon="ic:outline-file-copy" width={24} className="text-grey-4 mr-2" />
                    <span>{bankName}</span>
                  </div>
                  {operations?.[0]?.status !== BankStatementOperationStatus.FAILURE && (
                    <Badge
                      label={getBankStatementStatus(status, operations)}
                      variant={getBadgeVariant(status, operations)}
                    />
                  )}
                </div>
                {operations?.[0]?.status === BankStatementOperationStatus.FAILURE && (
                  <Alert
                    variant="error"
                    title="Online issues"
                    rightAction={
                      <Button variant="text" size="medium" onClick={() => handleRetryClick(appReference)}>
                        Try again
                      </Button>
                    }
                    className="!mb-0"
                  >
                    {sanitizeContent({
                      content: bankStatementErrorsMapper({
                        errorCode: operations?.[0]?.rawResponse['error_code'],
                        errorMessage: operations?.[0]?.rawResponse['error_message'],
                      }),
                      className: 'text-sm',
                    })}
                  </Alert>
                )}
              </li>
            ))}
          </ul>
        </Card>
      )}
    </>
  );
}

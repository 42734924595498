import { BranchEnum, LoanProduct, RelationshipStatusEnum, PreQualifiedQuoteFailedReasonEnum } from '@prisma/client';

type LoanTermOptions = 36 | 60 | 84;

export enum QuoteStatusEnum {
  QUOTE = 'quote',
  NO_QUOTE = 'no-quote',
}

export type PreQualifiedQuoteBpmnVariables = {
  annualNetIncome?: number;
  area?: string;
  benchmarkValue?: number;
  comparisonRate?: number;
  debt?: { creditCardLimitFromCreditFile: number; calculatedPersonalLoanRepayment: number };
  degreesOfFreedom: { branch: BranchEnum };
  envConfig: { environment: string; apiUrl: string };
  establishmentFeeForFiveYears?: number;
  establishmentFeeForSevenYears?: number;
  establishmentFeeForThreeYears?: number;
  expense?: { monthlyHousingExpense: number };
  finalOffer?: {
    quoteStatus: QuoteStatusEnum;
    loanAmount: number;
    loanTerm: LoanTermOptions;
    failReason?: PreQualifiedQuoteFailedReasonEnum;
    note?: string;
  };
  finalTotalDebt?: number;
  finalTotalExpense?: number;
  income: { grossAnnualIncome: number };
  interestRateAndCreditLimit?: { interestRate: number; creditLimit: number };
  loan: {
    originalTerm: number;
    requestedTerm: LoanTermOptions;
    requestedAmount: number;
    loanPurpose: string;
  };
  loanLimitForIncome?: number;
  loanLimitFromUmiForFiveYears?: number;
  loanLimitFromUmiForSevenYears?: number;
  loanLimitFromUmiForThreeYears?: number;
  maximumBorrowingLimit?: number;
  monthlyNetIncome?: number;
  monthlyRepayment?: number;
  offer?: { offerAmount: number; offerTerm: LoanTermOptions };
  pd?: number;
  preQualifiedQuoteId: string;
  product: Partial<LoanProduct>;
  statusAndFailReasonAndErrorMessage?: { status: string };
  userId: string;
  umi?: number;
  declineCheckResult?: {
    quoteStatus: QuoteStatusEnum;
    failReason?: PreQualifiedQuoteFailedReasonEnum;
    note?: string;
  };
  user: { postCode: string; numberOfDependants: number; relationshipStatus: RelationshipStatusEnum };
  finalEstablishmentFee?: number;
  creditPullStatus?: string;
  liabilityId?: string;
};

export const preQualifiedQuoteFilterLabelMapper: Record<string, string> = {
  HAS_PQ: 'Has pre-qualified quote',
  // HAS_NO_PQ: 'Has no pre-qualified quote', // for future use
};

export const preQualifiedQuoteFilterEmojiMapper: Record<string, string> = {
  HAS_PQ: '📥',
  // HAS_NO_PQ: '⛔', // for future use
};

export const preQualifiedQuoteFilterValueMapper: Record<string, string> = {
  HAS_PQ: 'has-pq',
  // HAS_NO_PQ: 'has-no-pq', // for future use
};

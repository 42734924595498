import { LoanProductDto } from '@harmoney/api-interfaces';
import { BranchEnum } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const loanProductApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    // Remove this endpoint after this PR is merged
    getLoanProduct: builder.query<LoanProductDto, string>({
      query: (taskId) => ({
        url: `/loan-products/${taskId}`,
        method: 'GET',
      }),
    }),
    getLoanProductList: builder.query<LoanProductDto[], BranchEnum>({
      query: (branch) => ({
        url: `/loan-products?branch=${branch}`,
        method: 'GET',
      }),
    }),
    getLoanProductByTaskId: builder.query<LoanProductDto, string>({
      query: (taskId) => ({
        url: `/loan-products/detail?taskId=${taskId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetLoanProductQuery, useGetLoanProductListQuery, useGetLoanProductByTaskIdQuery } = loanProductApi;

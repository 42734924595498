import { AffordabilityModelDetailDto, IncomeAndExpenseSplitDto } from '@harmoney/api-interfaces';
import { AffordabilityModelEnum, UmiCalculationOperation } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const affordabilityApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAffordabilityByApplicationId: builder.query<UmiCalculationOperation, string>({
      query: (applicationId) => ({
        url: `/affordability`,
        method: 'GET',
        params: {
          applicationId,
        },
      }),
    }),
    getNetIncomeByGross: builder.query<number, number>({
      query: (grossIncome) => ({
        url: '/affordability/monthly-net-income',
        method: 'GET',
        params: { grossIncome },
      }),
    }),
    getAffordabilityDetailsByApplicationIdAndUserId: builder.query<
      AffordabilityModelDetailDto[],
      { applicationId: string; userId: string }
    >({
      query: ({ applicationId, userId }) => ({
        url: '/affordability-summary',
        method: 'GET',
        params: {
          applicationId,
          userId,
        },
      }),
    }),
    getAffordabilityIncomeAndExpenseSplits: builder.query<
      IncomeAndExpenseSplitDto,
      { applicationId: string; userId: string; modelUsed: AffordabilityModelEnum }
    >({
      query: ({ applicationId, userId, modelUsed }) => ({
        url: '/affordability-summary/splits',
        method: 'GET',
        params: {
          applicationId,
          userId,
          modelUsed,
        },
      }),
    }),
  }),
});

export const {
  useGetAffordabilityByApplicationIdQuery,
  useLazyGetNetIncomeByGrossQuery,
  useGetAffordabilityDetailsByApplicationIdAndUserIdQuery,
  useGetAffordabilityIncomeAndExpenseSplitsQuery,
} = affordabilityApi;

import { Referral } from './referral';

export type RedirectUrlParams = {
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
};

export type ReferralParams = {
  redirectUrlParams: RedirectUrlParams;
};

const PRE_QUALIFIED_QUOTE_CAMPAIGN = 'prequal-quote';

export const ReferralMapper: Record<Referral, ReferralParams> = {
  [Referral.CLEARSCORE]: {
    redirectUrlParams: {
      utmSource: 'clearscore',
      utmMedium: PRE_QUALIFIED_QUOTE_CAMPAIGN,
      utmCampaign: PRE_QUALIFIED_QUOTE_CAMPAIGN,
    },
  },
};

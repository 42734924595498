import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { AmountInput, Card, CommonOptionProps, ControlledSelect, Label } from '@harmoney/ui-design-system';
import { formatCurrency, MINIMUM_BORROWING_AMOUNT_FOR_UNUTILISED_LIMIT } from '@harmoney/utilities';
import { Icon } from '@iconify/react';

import { FormSchema } from '../form-config';

interface LoanPurposeAmountProps {
  loanPurposes: CommonOptionProps[];
  upperLimit: number;
  remainingAmount: number;
  register: UseFormRegister<FormSchema>;
}

export const LoanPurposeAmountForUnutilisedLimit: React.FC<LoanPurposeAmountProps> = ({
  loanPurposes,
  upperLimit,
  remainingAmount,
  register,
}) => {
  return (
    <Card className="!p-0">
      <div className="p-4">
        <fieldset className="mb-4">
          <Label className="mb-2">
            Borrow up to <strong>{formatCurrency(upperLimit)}</strong>
          </Label>
          <AmountInput {...register('loanAmountForUnutilisedLimit')} maxLength={6} />
        </fieldset>
        <fieldset className="mb-2">
          <Label className="mb-2">What will you use the money for?</Label>
          <ControlledSelect {...register('loanPurposeForUnutilisedLimit')} options={loanPurposes} />
        </fieldset>
      </div>
      {remainingAmount > 0 && upperLimit - remainingAmount >= MINIMUM_BORROWING_AMOUNT_FOR_UNUTILISED_LIMIT && (
        <div className="flex items-center p-4 bg-secondary-lighter-3 rounded-b-xl">
          <Icon icon="ic:baseline-currency-exchange" className="text-grey-3 mr-2" width={24} />
          <p className="text-sm">
            The remaining {formatCurrency(remainingAmount)} won&rsquo;t be available after taking this loan.{' '}
          </p>
        </div>
      )}
    </Card>
  );
};

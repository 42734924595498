import { BankStatementOperationStatus, BankStatementReferenceStatusEnum } from '@prisma/client';

import { ReferencesStatus } from './BankStatementSummaryAction';

export const getUiStatus = (bankStatementReferences): ReferencesStatus => {
  if (
    bankStatementReferences.some(
      ({ status }) =>
        status === BankStatementReferenceStatusEnum.ui_completed ||
        status === BankStatementReferenceStatusEnum.callback_received
    ) &&
    bankStatementReferences.some(({ operations }) =>
      operations.some(({ status }) => status === BankStatementOperationStatus.FAILURE)
    )
  ) {
    return 'partial-processing';
  }
  if (
    bankStatementReferences.every(
      ({ status, operations }) =>
        !operations ||
        status === BankStatementReferenceStatusEnum.ui_completed ||
        status === BankStatementReferenceStatusEnum.callback_received
    ) ||
    (bankStatementReferences.some(
      ({ status, operations }) =>
        status === BankStatementReferenceStatusEnum.ui_completed ||
        status === BankStatementReferenceStatusEnum.callback_received ||
        !operations
    ) &&
      bankStatementReferences.some(
        ({ operations }) => operations?.[0]?.status === BankStatementOperationStatus.SUCCESS
      ))
  ) {
    return 'processing';
  }
  if (
    bankStatementReferences.every(({ operations }) => operations?.[0]?.status === BankStatementOperationStatus.SUCCESS)
  ) {
    return 'success';
  }
  if (
    bankStatementReferences.every(({ operations }) => operations?.[0]?.status === BankStatementOperationStatus.FAILURE)
  ) {
    return 'error';
  }
  if (
    bankStatementReferences.some(
      ({ operations }) => operations?.[0]?.status === BankStatementOperationStatus.SUCCESS
    ) &&
    bankStatementReferences.some(({ operations }) => operations?.[0]?.status === BankStatementOperationStatus.FAILURE)
  ) {
    return 'partial-success';
  }
};

import { IsEnum, IsNumberString } from 'class-validator';
import { OrderEnum, TimeUnitEnum } from '../common';
import { PaymentPurposeEnum, PaymentStatusEnum } from '@prisma/client';

export type PaymentSubTransactionDTO = {
  principal?: number;
  interest?: number;
  other?: number;
};

export type PaymentTransactionDTO = {
  processingDate: Date;
  amount: number;
  purpose: PaymentPurposeEnum;
  status: PaymentStatusEnum;
  paymentKey: string;
  paymentBreakdown?: PaymentSubTransactionDTO;
  settledTo?: string;
  isExtraRepayment?: boolean;
  dishonoredDate?: Date;
  isReversed?: boolean;
};

export type PaymentTransactionGroupedByDateDTO = {
  date: string;
  paymentTransactions: PaymentTransactionDTO[];
};

export class PaymentHistoryForPeriodQuery {
  @IsNumberString()
  skipPeriod: number;
  @IsNumberString()
  numberOfPeriodUnits: number;
  @IsEnum(TimeUnitEnum)
  periodUnit: TimeUnitEnum;
  @IsEnum(OrderEnum)
  order: OrderEnum;
}

export class PaymentHistoryMetadata extends PaymentHistoryForPeriodQuery {
  startDate: string;
  endDate: string;
  loanApplicationId: string;
}

export type PaymentHistoryForPeriod = {
  data: PaymentTransactionDTO[];
  totalRecords: number;
  totalRecordsFetchedforCurrentPeriod: number;
};

export type GroupedPaymentHistoryForPeriodResult = {
  data: PaymentTransactionGroupedByDateDTO[];
  totalRecords: number;
  totalRecordsFetchedforCurrentPeriod: number;
  metadata?: PaymentHistoryMetadata;
};

export type SaveLoanMigrationDTO = {
  loanId: string;
  migrationId?: string;
};

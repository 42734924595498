import { IncomeAndExpenseFrequencyEnum as Frequency } from '@prisma/client';

export const frequencyOptionsWithoutDefault = [
  {
    label: 'Week',
    value: Frequency.weekly,
  },
  {
    label: 'Fortnight',
    value: Frequency.fortnightly,
  },
  {
    label: 'Month',
    value: Frequency.monthly,
  },
];

export const frequencyOptions = [
  {
    label: 'Select',
    value: '',
  },
  ...frequencyOptionsWithoutDefault,
];

export const frequencyOptionsWithYear = [
  ...frequencyOptions,
  {
    label: 'Year',
    value: Frequency.yearly,
  },
];

import { useEffect, useState } from 'react';
import { BranchEnum } from '@prisma/client';

export const useBranchFromUrl = () => {
  const [branch, setBranch] = useState<BranchEnum>(null);

  useEffect(() => {
    const hostname = window.location.hostname;
    if (hostname === 'localhost') {
      const localStorageBranch = localStorage.getItem('branch');
      if (localStorageBranch) {
        setBranch(localStorageBranch as BranchEnum);
      } else {
        setBranch(BranchEnum.AU);
      }
    } else {
      const branch = hostname.endsWith('.nz') ? BranchEnum.NZ : BranchEnum.AU;
      setBranch(branch);
    }
  }, []);

  return branch;
};

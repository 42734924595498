import { isPartnered } from '@harmoney/ui-utils';
import { isDebtCon } from '@harmoney/utilities';
import { AssetAndLiabilityRecordOriginEnum } from '@prisma/client';
import { kebabCase, omit } from 'lodash';

export const initialDebtFormValues = ({ id, code, relationshipStatus, loanProduct }) => {
  const commonValues = {
    networthSourceId: id,
    recordOrigin: AssetAndLiabilityRecordOriginEnum.manual,
    repaymentAmount: null,
    frequency: '',
    provider: '',
    otherProvider: '',
    creditFileReference: undefined,
  };

  const debtConCommonValues = {
    interestRate: null,
  };

  const creditCardForDebtConsolidation = {
    ...omit(commonValues, ['repaymentAmount', 'frequency']),
  };

  const debtFromBankStatementValues = {
    isFromJointBankAccount: null,
    ownUndisclosedDebt: undefined,
    bankStatementAmount: null,
    bankStatementRepaymentAmount: null,
    bankStatementRepaymentFrequency: null,
    bankReference: null,
    paidOff: undefined,
    ownership: undefined,
  };

  const creditCardValues = isDebtCon(loanProduct) ? creditCardForDebtConsolidation : commonValues;

  const debtConValues = isDebtCon(loanProduct) ? debtConCommonValues : {};

  const sharedMortgageValues = {
    isJointMortgage: '',
    jointMortgageAmount: null,
    jointMortgageFrequency: '',
    isResidence: '',
    rentalIncomeCoversMortgage: '',
  };

  const singleMortgageValues = {
    isMortgageShared: undefined,
  };

  const mortgageValues = isPartnered(relationshipStatus) ? sharedMortgageValues : singleMortgageValues;

  const formConfig = {
    'credit-card': {
      paysOutstandingBalance: '',
      creditLimit: null,
      recordOrigin: AssetAndLiabilityRecordOriginEnum.manual,
      ...creditCardValues,
    },
    'personal-loan': {
      outstandingBalance: null,
      recordOrigin: AssetAndLiabilityRecordOriginEnum.manual,
      ...commonValues,
      ...debtConValues,
    },
    'car-loan': {
      outstandingBalance: null,
      recordOrigin: AssetAndLiabilityRecordOriginEnum.manual,
      ...debtFromBankStatementValues,
      ...commonValues,
      ...debtConValues,
    },
    overdraft: {
      outstandingBalance: null,
      ...commonValues,
      ...debtConValues,
    },
    'other-debts': {
      debtType: '',
      outstandingBalance: null,
      ...commonValues,
    },
    'buy-now-pay-later': {
      creditLimit: null,
      ...commonValues,
    },
    mortgage: {
      outstandingBalance: null,
      recordOrigin: AssetAndLiabilityRecordOriginEnum.manual,
      isJointMortgageFromCreditFile: null,
      ...commonValues,
      ...mortgageValues,
    },
    'no-debt': {
      networthSourceId: id,
    },
    'line-of-credit': {
      creditLimit: null,
      outstandingBalance: null,
      recordOrigin: AssetAndLiabilityRecordOriginEnum.manual,
      ...commonValues,
      ...debtConValues,
    },
  };

  const debtFormValues = formConfig[kebabCase(code)];

  return debtFormValues;
};

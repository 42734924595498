import { errors } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import { DebtConsolidationBenefitEnum } from '@prisma/client';
import { z } from 'zod';

export const formSchema = (minimumBorrowingLimit, maximumBorrowingAmount, bypassMaximum = false) =>
  z
    .object({
      reason: z
        .array(
          z.nativeEnum(DebtConsolidationBenefitEnum, {
            errorMap: (issue, _ctx) => {
              if (issue.code) return { message: errors.defaultRequiredField };
            },
          })
        )
        .nullish(),
      debtsSelection: z.record(z.boolean()),
      totalToConsolidate: z
        .number()
        .gte(minimumBorrowingLimit)
        .lte(bypassMaximum ? Infinity : maximumBorrowingAmount),
    })
    .superRefine((data, { addIssue }) => {
      const debtsSelectionValues = Object.values(data.debtsSelection);
      // if totalToConsolidate is less than minimumBorrowingLimit, add issue to debtsSelection
      if (data.totalToConsolidate < minimumBorrowingLimit && debtsSelectionValues.some((value) => value === true)) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: `Please select more debts. Your debts need to add up to the minimum loan amount of ${formatCurrency(
            minimumBorrowingLimit
          )}.`,
          path: ['debtsSelection'],
        });
        return;
      }

      if (debtsSelectionValues.every((value) => value === false)) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please select the debts you want to consolidate.',
          path: ['debtsSelection'],
        });
        return;
      }

      if (debtsSelectionValues.some((value) => value === true) && data.totalToConsolidate >= minimumBorrowingLimit)
        return;
    });

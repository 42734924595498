import { kebabCase } from 'lodash';

export const initialFormValues = ({ id, code }) => {
  const commonValues = {
    networthSourceId: id,
    declaredAmount: null,
  };
  const formConfig = {
    'property-land': commonValues,
    vehicle: commonValues,
    savings: commonValues,
    'shares-bonds-funds': commonValues,
    boat: commonValues,
    'other-assets': {
      ...commonValues,
      assetName: '',
    },
    'no-assets': {
      networthSourceId: id,
    },
  };

  const defaultValue = {};

  const assetFormValues = formConfig[kebabCase(code)];

  return assetFormValues || defaultValue;
};

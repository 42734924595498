import { Fragment } from 'react';
import { PaymentTransactionGroupedByDateDTO } from '@harmoney/api-interfaces';
import { Badge } from '@harmoney/ui-design-system';
import { DATE_FORMAT_WITH_MONTH } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import { PaymentPurposeEnum, PaymentStatusEnum } from '@prisma/client';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';

type TransactionProps = {
  transaction: PaymentTransactionGroupedByDateDTO;
};

export const Transaction = ({ transaction }: TransactionProps) => {
  const badgeStatus: (keyof typeof PaymentStatusEnum)[] = [
    PaymentStatusEnum.error,
    PaymentStatusEnum.processing,
    PaymentStatusEnum.dishonored,
  ];

  const getTransactionType = (purpose: PaymentPurposeEnum, isExtraRepayment: boolean, isReversed: boolean) => {
    const transactionLabel = isExtraRepayment
      ? 'Extra repayment'
      : purpose === PaymentPurposeEnum.fee
        ? 'Establishment fee'
        : capitalize(purpose);
    return isReversed ? `${transactionLabel} reversal` : transactionLabel;
  };

  const getBadgeProps = (status: PaymentStatusEnum): { label: string; variant: 'secondary' | 'action' } => {
    switch (status) {
      case PaymentStatusEnum.error:
        return { label: 'Transaction error', variant: 'action' };
      case PaymentStatusEnum.processing:
        return { label: 'Pending', variant: 'secondary' };
      case PaymentStatusEnum.dishonored:
        return { label: 'Insufficient funds', variant: 'action' };
    }
  };

  return (
    <div className="bg-grey-1 px-4 py-2 mb-4">
      <p className="font-medium">{dayjs(transaction.date).format(DATE_FORMAT_WITH_MONTH)}</p>
      {transaction.paymentTransactions.map((subTransaction, index) => (
        <Fragment key={index}>
          <div className="my-4">
            <div className="flex justify-between items-center mb-2">
              <span className="inline-flex items-center gap-x-2">
                <span>
                  {getTransactionType(
                    subTransaction.purpose,
                    subTransaction.isExtraRepayment,
                    subTransaction.isReversed
                  )}
                </span>
                <span className="mr-2">
                  {badgeStatus.includes(subTransaction.status) && (
                    <Badge
                      label={getBadgeProps(subTransaction.status).label}
                      variant={getBadgeProps(subTransaction.status).variant}
                    />
                  )}
                </span>
              </span>
              <span className="font-medium">{formatCurrency(subTransaction.amount)}</span>
            </div>
            {subTransaction.paymentBreakdown && (
              <div className="flex items-center gap-x-2 ">
                {Object.entries(subTransaction.paymentBreakdown).map(([key, value], index, array) => (
                  <span className="text-xs inline-flex gap-x-2" key={index}>
                    <span>
                      <span>{capitalize(key)}</span> <span className="font-medium">{formatCurrency(value)}</span>
                    </span>
                    {index < array.length - 1 && <span>|</span>}
                  </span>
                ))}
              </div>
            )}
          </div>
        </Fragment>
      ))}
    </div>
  );
};

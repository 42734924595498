import Link from 'next/link';
import { CollapsibleCard } from '@harmoney/ui-design-system';

export const IDVDetails = ({ residencyStatus }: { residencyStatus: string }) => {
  const frankiePortalLink = process.env.NEXT_PUBLIC_FRANKIEONE_PORTAL_LINK;

  return (
    <CollapsibleCard title="ID Verification" className="mb-6">
      <div className="grid grid-cols-2 px-4">
        <div className="border-r-grey-1 border-r-2 p-4">
          <span className="grid grid-cols-2">
            <span>ID verification status:</span>
            <span>{'N/A'}</span>
          </span>
          <span className="grid grid-cols-2">
            <span>ID verification date:</span>
            <span>{'N/A'}</span>
          </span>
          <span className="grid grid-cols-2">
            <span>ID source:</span>
            <span>{'N/A'}</span>
          </span>
          <span className="grid grid-cols-2">
            <span>Notes/Comments:</span>
            <span>{'N/A'}</span>
          </span>
        </div>
        <div className="p-4">
          <span className="grid grid-cols-2">
            <span>Reason for failure:</span>
            <span>{'N/A'}</span>
          </span>
          <span className="grid grid-cols-2">
            <span>Link to admin portal:</span>
            <Link href={`${frankiePortalLink}`} passHref>
              Frankieone portal
            </Link>
          </span>
          <span className="grid grid-cols-2">
            <span>AML Check:</span>
            <span>{'N/A'}</span>
          </span>
          <span className="grid grid-cols-2">
            <span>Fraud Check:</span>
            <span>{'N/A'}</span>
          </span>
          <span className="grid grid-cols-2">
            <span>Residency Status:</span>
            <span>{residencyStatus || 'N/A'}</span>
          </span>
        </div>
      </div>
    </CollapsibleCard>
  );
};

import Link from 'next/link';
import React, { Fragment } from 'react';
import { opportunityMapper } from '@harmoney/api-interfaces';
import { useAppSelector, useGetAllLoansByUserIdQuery } from '@harmoney/redux';
import { Badge, CollapsibleCard, Spinner } from '@harmoney/ui-design-system';
import dayjs from 'dayjs';

import { loanStateBadgeVariant } from '../LoanVariation/utils';

const MONTHS_IN_YEAR = 12;

const loanStateToBadgeVariantMapper: Record<string, string> = {
  PENDING: 'Pending',
  ACTIVE: 'Active',
  PAID_OFF: 'Paid-off',
  CANCELLED: 'Cancelled',
  WRITTEN_OFF: 'Written-off',
  PARTIAL_WRITTEN_OFF: 'Partial Written-off',
};

export const CustomerLoanTable = ({ userId }: { userId: string }) => {
  const token = useAppSelector((state) => state?.accessToken?.value);
  const { data: loans, isLoading: isLoanApplicationsLoading } = useGetAllLoansByUserIdQuery(userId as string, {
    skip: !userId || !token,
  });

  if (isLoanApplicationsLoading) {
    return <Spinner />;
  }

  if (loans?.length === 0) {
    return (
      <div className="h-full w-full flex flex-column justify-center items-center text-xl text-grey-2 text-bold">
        Customer has no active loans
      </div>
    );
  }

  return (
    <CollapsibleCard title="Loans" className="mb-6" disabled>
      <table className="w-full text-left">
        <thead>
          <tr className="border-b border-b-grey-2">
            <th className="p-4">Loan number</th>
            <th className="p-4">Product</th>
            <th className="p-4">Opportunity</th>
            <th className="p-4">Loan purpose</th>
            <th className="p-4">Loan term</th>
            <th className="p-4">Loan funded on</th>
            <th className="p-4">Loan status</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {loans?.map((loan) => (
            <Fragment key={loan.businessKey}>
              <tr className="border-b border-b-grey-2">
                <td className="p-4">
                  <Link href={`/admin/customer/loan?applicationId=${loan.id}&userId=${loan.userId}`}>
                    {loan.businessKey}
                  </Link>
                </td>
                <td className="p-4">{loan.loanProduct.name}</td>
                <td className="p-4">{opportunityMapper[loan.originationType]}</td>
                <td className="p-4">
                  {loan.loanApplicationPurposes
                    .map((loanApplicationPurpose) => loanApplicationPurpose.loanPurpose.displayName)
                    .join(', ')}
                </td>
                <td className="p-4">{loan.termInMonths / MONTHS_IN_YEAR} years</td>
                <td className="p-4">{dayjs(loan.paymentTransactions[0]?.completedAt).format('DD MMMM YYYY')}</td>
                {loan?.loanState && (
                  <td className="p-4">
                    <Badge
                      variant={loanStateBadgeVariant[loanStateToBadgeVariantMapper[loan.loanState]]}
                      label={loanStateToBadgeVariantMapper[loan.loanState]}
                    />
                  </td>
                )}
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </CollapsibleCard>
  );
};

import { useEffect, useState } from 'react';
import { BankStatementOperation, BankStatementReference } from '@prisma/client';

import { ReferencesStatus } from '../components/bank-statement/BankStatementSummary/BankStatementSummaryAction';
import { getUiStatus } from '../components/bank-statement/BankStatementSummary/util';

export const useBankStatementRefStatus = (
  bankStatementReferences: (BankStatementReference & {
    operations: Pick<BankStatementOperation, 'rawResponse' | 'status' | 'updatedAt'>[];
  })[]
) => {
  const [allReferencesStatus, setAllReferencesStatus] = useState<ReferencesStatus>(null);

  useEffect(() => {
    if (bankStatementReferences) {
      setAllReferencesStatus(getUiStatus(bankStatementReferences));
    }
  }, [bankStatementReferences]);

  return { allReferencesStatus };
};

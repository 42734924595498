import { Alert } from '@harmoney/ui-design-system';

export const BankAccountConnectionIssueAlert = () => {
  return (
    <Alert variant="error" title="Connection issue">
      <p className="text-sm">
        There was an issue connecting to your bank. Please contact us via chat so our team can help resolve this for
        you.
      </p>
    </Alert>
  );
};

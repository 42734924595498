import { AccommodationExpenseEnum, ResidentialStatusEnum } from '@harmoney/api-interfaces';
import { errors, stringToBool } from '@harmoney/ui-utils';
import { convertToMonthlyAmount } from '@harmoney/utilities';
import { IncomeAndExpenseFrequencyEnum, RelationshipStatusEnum } from '@prisma/client';
import { isEmpty } from 'lodash';
import { z } from 'zod';

import { getErrorMessageForSharedExpense, shouldDisplaySharedExpense } from './form.util';

export enum FormSchemaTypeEnum {
  BASE = 'base',
  CHILD_SUPPORT = 'childSupport',
  ACCOMMODATION_EXPENSE_WITH_CHILD_SUPPORT = 'accommodationExpenseWithChildSupport',
  ACCOMMODATION_EXPENSE_WITHOUT_CHILD_SUPPORT = 'accommodationExpenseWithoutChildSupport',
}

export const householdV2FormFieldKeys = {
  ACCOMMODATION_EXPENSE: 'accommodationExpense',
  CHILD_SUPPORT: 'childSupport',
  DEPENDANTS: 'dependants',
  LIVING_TYPE: 'livingType',
};

export const sortedHouseholdV2FormFieldKeys = [
  householdV2FormFieldKeys.LIVING_TYPE,
  householdV2FormFieldKeys.ACCOMMODATION_EXPENSE,
  householdV2FormFieldKeys.DEPENDANTS,
  householdV2FormFieldKeys.CHILD_SUPPORT,
];

export const defaultValues = {
  [householdV2FormFieldKeys.LIVING_TYPE]: {
    primary: '',
    secondary: '',
  },
  [householdV2FormFieldKeys.DEPENDANTS]: {
    hasDependants: '',
    numberOfDependants: undefined,
  },
  [householdV2FormFieldKeys.CHILD_SUPPORT]: {
    hasChildSupportReceived: '',
    childSupportReceivedAmount: null,
    childSupportReceivedFrequency: '',
    hasChildSupportPaid: '',
    childSupportPaidAmount: null,
    childSupportPaidFrequency: '',
    hasChildSupportDeducted: '',
  },
  [householdV2FormFieldKeys.ACCOMMODATION_EXPENSE]: {
    situation: '',
    declaredAmount: null,
    declaredFrequency: '',
    declaredTotalAmount: null,
    declaredTotalFrequency: '',
  },
};

const formSchemaForLivingType = z.object({
  livingType: z
    .object({
      primary: z.string(),
      secondary: z.string(),
    })
    .refine((data) => data.primary, {
      path: ['primary'],
    })
    .refine((data) => data.primary, {
      message: errors.defaultRequiredField,
      path: ['secondary'],
    }),
});

const formSchemaForDependants = z.object({
  dependants: z
    .object({
      livingType: z.string().optional(),
      hasDependants: z.string().optional(),
      numberOfDependants: z.coerce
        .number({ invalid_type_error: errors.defaultValidAmount })
        .min(0, { message: 'Please enter a number greater than 0' })
        .max(30, { message: 'Please enter a number less than or equal to 30' })
        .optional(),
    })
    .refine(
      (data) => {
        if (!isEmpty(data.livingType) && isEmpty(data.hasDependants)) return false;
        return true;
      },
      {
        message: errors.defaultRequiredField,
        path: ['hasDependants'],
      }
    )
    .refine(
      (data) => {
        if (stringToBool(data.hasDependants) && (data.numberOfDependants === undefined || data.numberOfDependants <= 0))
          return false;
        return true;
      },
      {
        message: 'Please enter a number greater than 0',
        path: ['numberOfDependants'],
      }
    ),
});

const baseFormSchema = z.object({
  type: z.literal(FormSchemaTypeEnum.BASE),
  ...formSchemaForLivingType.shape,
  ...formSchemaForDependants.shape,
});

const formSchemaForChildSupport = baseFormSchema.extend({
  type: z.literal(FormSchemaTypeEnum.CHILD_SUPPORT),
  childSupport: z
    .object({
      hasChildSupportReceived: z.string().min(1, { message: errors.defaultRequiredField }).nullable().optional(),
      childSupportReceivedAmount: z.number({ invalid_type_error: errors.defaultValidAmount }).nullable().optional(),
      childSupportReceivedFrequency: z.string().nullable().optional(),
      hasChildSupportPaid: z.string().min(1, { message: errors.defaultRequiredField }).nullable().optional(),
      childSupportPaidAmount: z.number({ invalid_type_error: errors.defaultValidAmount }).nullable().optional(),
      childSupportPaidFrequency: z.string().nullable().optional(),
      hasChildSupportDeducted: z.string().nullable().optional(),
    })
    .refine(
      (data) => {
        const receivesChildSupportButNoAmountDeclared =
          stringToBool(data.hasChildSupportReceived) &&
          (!data.childSupportReceivedAmount || data.childSupportReceivedAmount <= 0);

        if (receivesChildSupportButNoAmountDeclared) return false;
        return true;
      },
      {
        message: errors.defaultValidAmount,
        path: ['childSupportReceivedAmount'],
      }
    )
    .refine(
      (data) => {
        if (data.childSupportReceivedAmount > 0 && isEmpty(data.childSupportReceivedFrequency)) return false;
        return true;
      },
      {
        message: errors.defaultRequiredFrequency,
        path: ['childSupportReceivedFrequency'],
      }
    )
    .refine(
      (data) => {
        const paysChildSupportButNoAmountDeclared =
          stringToBool(data.hasChildSupportPaid) && (!data.childSupportPaidAmount || data.childSupportPaidAmount <= 0);

        if (paysChildSupportButNoAmountDeclared) return false;
        return true;
      },
      {
        message: errors.defaultValidAmount,
        path: ['childSupportPaidAmount'],
      }
    )
    .refine(
      (data) => {
        if (data.childSupportPaidAmount > 0 && isEmpty(data.childSupportPaidFrequency)) return false;
        return true;
      },
      {
        message: errors.defaultRequiredFrequency,
        path: ['childSupportPaidFrequency'],
      }
    )
    .refine(
      (data) => {
        if (stringToBool(data.hasChildSupportPaid) && isEmpty(data.hasChildSupportDeducted)) return false;
        return true;
      },
      {
        message: errors.defaultRequiredField,
        path: ['hasChildSupportDeducted'],
      }
    ),
});

const createFormSchemaForAccommodationExpense = () => {
  const isDeclaredAmountValid = (data) => data.declaredAmount !== null && data.declaredAmount > 0;
  const isDeclaredTotalAmountValid = (data) => data.declaredTotalAmount !== null && data.declaredTotalAmount > 0;
  const hasExpense = (data) => data.situation !== AccommodationExpenseEnum.NO_COVERAGE;
  const isExpenseShared = (data) => data.situation === AccommodationExpenseEnum.SHARED;
  const showSharedExpense = (data) => shouldDisplaySharedExpense(data.relationshipStatus as RelationshipStatusEnum);

  return z.object({
    accommodationExpense: z
      .object({
        livingType: z.string().optional(),
        relationshipStatus: z.string().optional(),
        situation: z.string().optional(),
        declaredAmount: z.number({ invalid_type_error: errors.defaultValidAmount }).nullable().optional(),
        declaredFrequency: z.string().optional(),
        declaredTotalAmount: z.number({ invalid_type_error: '' }).nullable().optional(),
        declaredTotalFrequency: z.string().nullable().optional(),
      })
      .refine(
        (data) => {
          if (!isEmpty(data.livingType) && showSharedExpense(data) && isEmpty(data.situation)) return false;
          return true;
        },
        {
          message: errors.defaultRequiredField,
          path: ['situation'],
        }
      )
      .refine(
        (data) => {
          const isExpenseSharedButNoDeclaredTotalAmount = isExpenseShared(data) && !isDeclaredTotalAmountValid(data);
          if (isExpenseSharedButNoDeclaredTotalAmount) return false;
          return true;
        },
        {
          message: errors.defaultValidAmount,
          path: ['declaredTotalAmount'],
        }
      )
      .refine(
        (data) => {
          const hasDeclaredTotalAmountButNoTotalFrequency =
            isDeclaredTotalAmountValid(data) && isEmpty(data.declaredTotalFrequency);
          if (hasDeclaredTotalAmountButNoTotalFrequency) return false;
          return true;
        },
        {
          message: errors.defaultRequiredFrequency,
          path: ['declaredTotalFrequency'],
        }
      )
      .refine(
        (data) => {
          const isDeclaredTotalAmountGreaterOrEqualToSharedAmount =
            convertToMonthlyAmount(
              data.declaredTotalAmount,
              data.declaredTotalFrequency as IncomeAndExpenseFrequencyEnum
            ) >= convertToMonthlyAmount(data.declaredAmount, data.declaredFrequency as IncomeAndExpenseFrequencyEnum);

          if (
            isExpenseShared(data) &&
            data.declaredAmount > 0 &&
            data.declaredTotalAmount > 0 &&
            !isDeclaredTotalAmountGreaterOrEqualToSharedAmount
          ) {
            return false;
          }
          return true;
        },
        (data) => {
          return {
            message: getErrorMessageForSharedExpense(data.livingType as ResidentialStatusEnum),
            path: ['declaredAmount'],
          };
        }
      )
      .refine(
        (data) => {
          if (showSharedExpense(data)) {
            const isPayingAccommodationExpenseAndDeclaredAmountInvalid =
              !isEmpty(data.situation) && hasExpense(data) && !isDeclaredAmountValid(data);
            if (isPayingAccommodationExpenseAndDeclaredAmountInvalid) return false;
          } else {
            if (!isDeclaredAmountValid(data)) {
              return false;
            }
          }
          return true;
        },
        {
          message: errors.defaultValidAmount,
          path: ['declaredAmount'],
        }
      )
      .refine(
        (data) => {
          if (showSharedExpense(data)) {
            if (
              !isEmpty(data.situation) &&
              hasExpense(data) &&
              isDeclaredAmountValid(data) &&
              isEmpty(data.declaredFrequency)
            )
              return false;
          } else {
            if (isDeclaredAmountValid(data) && isEmpty(data.declaredFrequency)) {
              return false;
            }
          }
          return true;
        },
        {
          message: errors.defaultRequiredFrequency,
          path: ['declaredFrequency'],
        }
      ),
  });
};

const FormSchemaForAccommodationExpense = createFormSchemaForAccommodationExpense();

const formSchemaForAccommodationExpenseWithChildSupport = formSchemaForChildSupport.extend({
  type: z.literal(FormSchemaTypeEnum.ACCOMMODATION_EXPENSE_WITH_CHILD_SUPPORT),
  ...createFormSchemaForAccommodationExpense().shape,
});

const formSchemaForAccommodationExpenseWithoutChildSupport = baseFormSchema.extend({
  type: z.literal(FormSchemaTypeEnum.ACCOMMODATION_EXPENSE_WITHOUT_CHILD_SUPPORT),
  ...createFormSchemaForAccommodationExpense().shape,
});

export const formSchema = z.union([
  baseFormSchema,
  formSchemaForChildSupport,
  formSchemaForAccommodationExpenseWithChildSupport,
  formSchemaForAccommodationExpenseWithoutChildSupport,
]);

export type FormSchema = z.infer<typeof formSchema>;
export type FormSchemaForAccommodationExpense = z.infer<typeof FormSchemaForAccommodationExpense>;

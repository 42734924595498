import React from 'react';
import { Alert } from '@harmoney/ui-design-system';
import { calculateOneOffDifference } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';

type Props = {
  className?: string;
  firstRepaymentAmount: number;
  recurringRepaymentAmount: number;
};

export const OneOffIncreaseAlert = ({ className, firstRepaymentAmount, recurringRepaymentAmount }: Props) => {
  const oneOffIncreasedAmount = calculateOneOffDifference(firstRepaymentAmount, recurringRepaymentAmount);

  if (oneOffIncreasedAmount <= 0) {
    return null;
  }

  return (
    <Alert variant="info" className={className}>
      <p className="text-sm">
        Your first direct debit includes a one-off increase of {formatCurrency(oneOffIncreasedAmount)}{' '}
        {'to ensure that your first required monthly payment is paid in full.'}
      </p>
    </Alert>
  );
};

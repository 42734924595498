import { useMemo } from 'react';
import {
  useAppSelector,
  useGetAffordabilityDetailsByApplicationIdAndUserIdQuery,
  useGetAffordabilityIncomeAndExpenseSplitsQuery,
} from '@harmoney/redux';
import { CollapsibleCard, Divider } from '@harmoney/ui-design-system';
import { valueOrNA } from '@harmoney/ui-utils';
import { AssetAndLiability, FinancialProfile, IncomeAndExpense, NetworthSource, Prisma } from '@prisma/client';

import { Field } from '../customer-hub';
import { DebtsTableV2 } from '../customer-hub/AffordabilityV2/DebtsTableV2';
import { ExpensesTableV2 } from '../customer-hub/AffordabilityV2/ExpensesTableV2';
import { IncomeTableV2 } from '../customer-hub/AffordabilityV2/IncomeTableV2';
import { UmiTab } from '../customer-hub/AffordabilityV2/UmiTabs';

interface AffordabilityDetailsProps {
  userId: string;
  applicationId: string;
  rootData: Prisma.JsonValue;
  financialProfileData: FinancialProfile & {
    asset_and_liability: (AssetAndLiability & {
      networthSource: NetworthSource;
    })[];
    income_and_expense: (IncomeAndExpense & {
      networthSource: NetworthSource;
    })[];
  };
}

export const AffordabilityDetailsForSimulationV2 = ({ userId, applicationId, rootData }: AffordabilityDetailsProps) => {
  const accessToken = useAppSelector((state) => state.accessToken.value);

  const { data: offerData, isLoading } = useGetAffordabilityDetailsByApplicationIdAndUserIdQuery(
    { userId, applicationId },
    { skip: !userId || !applicationId || !accessToken }
  );

  const modelUsed = offerData?.find((offer) => offer.isSelected)?.model;

  const { data: splits, isLoading: splitsLoading } = useGetAffordabilityIncomeAndExpenseSplitsQuery(
    { userId, applicationId, modelUsed },
    { skip: !userId || !applicationId || !accessToken || !modelUsed }
  );

  const incomeAndExpenseSplits = useMemo(() => {
    if (!splitsLoading && splits) {
      return splits;
    }
  }, [splits, splitsLoading]);

  const currentModelData = useMemo(() => {
    if (!isLoading && offerData) {
      return offerData.find((offer) => offer.isSelected) || offerData[0];
    }
  }, [offerData, isLoading]);

  const getPrimaryIncomeSource = (): string => {
    return currentModelData?.items?.incomes?.find((income) => income.isPrimarySource)?.source;
  };

  const formatIncomeExpenseSplit = (value: number | string): string => {
    const split = valueOrNA(value);
    if (typeof split === 'string') return 'N/A';

    return `${split.toFixed(2)}%`;
  };

  const { data } = useGetAffordabilityDetailsByApplicationIdAndUserIdQuery(
    { userId, applicationId },
    { skip: !userId || !applicationId || !accessToken }
  );

  if (isLoading || !data) return null;

  return (
    <CollapsibleCard title="Affordability" className="mb-6" defaultOpen={false}>
      <div className="flex flex-col gap-2 py-4">
        <div>
          <div className="py-2 justify-evenly px-4 pb-1 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {data
              .toSorted((a, b) => {
                if (a.isSelected) return -1;
                if (b.isSelected) return 1;
                if (a.isEligible && !b.isEligible) return -1;
                if (!a.isEligible && b.isEligible) return 1;
                return b.umi - a.umi;
              })
              .map((item) => (
                <UmiTab affordabilityModelDetail={item} key={item.model} />
              ))}
          </div>
        </div>
        <Divider className="text-grey-2 px-4 my-2" />
        <div className="px-4">
          <p className="mb-4 font-medium">Shared contribution (%)</p>
          <div className="grid grid-cols-2 odd:border-r-grey-1">
            <div className="space-y-1">
              <Field className="space-x-4">
                <span>Income Split</span>
                <span>{formatIncomeExpenseSplit(valueOrNA(incomeAndExpenseSplits?.income))}</span>
              </Field>
              <Field className="space-x-4">
                <span>Accommodation Expenses Split</span>
                <span>{formatIncomeExpenseSplit(valueOrNA(incomeAndExpenseSplits?.accommodationExpenses))}</span>
              </Field>
              <Field className="space-x-4">
                <span>Final Accommodation Expenses Split</span>
                <span>{formatIncomeExpenseSplit(valueOrNA(incomeAndExpenseSplits?.finalAccommodationExpenses))}</span>
              </Field>
            </div>
            <div className="space-y-1">
              <Field className="space-x-4">
                <span>Primary Income Source</span>
                <span>{valueOrNA(getPrimaryIncomeSource())}</span>
              </Field>
              <Field className="space-x-4">
                <span>Living Expenses Split</span>
                <span>{`${formatIncomeExpenseSplit(valueOrNA(incomeAndExpenseSplits?.livingExpenses))}`}</span>
              </Field>
              <Field className="space-x-4">
                <span>Final Living Expenses Split</span>
                <span>{`${formatIncomeExpenseSplit(valueOrNA(incomeAndExpenseSplits?.finalLivingExpenses))}`}</span>
              </Field>
            </div>
          </div>
        </div>
        <Divider className="text-grey-2 px-4 my-2" />
        <div className="px-4 space-y-4">
          <span className="text-xs italic">*All values monthly</span>
          <IncomeTableV2 affordabilityData={currentModelData} rootData={rootData} />
          <ExpensesTableV2 affordabilityData={currentModelData} />
          <DebtsTableV2 affordabilityData={currentModelData} />
        </div>
      </div>
    </CollapsibleCard>
  );
};

import { Fragment, useMemo, useState } from 'react';
import { FinancialConfirmationSectionEnum } from '@harmoney/hooks';
import { Alert, Button, Card, Divider, Label } from '@harmoney/ui-design-system';
import { amountOrDash, capitalizeTitle } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';

import { FinancialProfileProps } from './financial-profile-props';

export const Assets = ({
  financialSummary,
  confirmedSections,
  onConfirmSection: handleConfirmSection,
  onUpdate: handleUpdate,
}: FinancialProfileProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUpdateInfo = async () => {
    try {
      setIsSubmitting(true);
      await handleUpdate(FinancialConfirmationSectionEnum.Assets);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };

  const displayUpToDate = useMemo(() => {
    return (
      confirmedSections?.includes(FinancialConfirmationSectionEnum.Assets) &&
      confirmedSections[confirmedSections.length - 1] === FinancialConfirmationSectionEnum.Assets
    );
  }, [confirmedSections?.length]);

  return (
    <Card className="!p-0">
      <div className="flex items-center justify-between">
        <Label className="p-4 text-lg font-medium">Assets</Label>
      </div>
      <Divider className="text-grey-2 m-0 p-0" />

      {financialSummary?.assets.length > 0 ? (
        financialSummary?.assets.map((asset, i) => (
          <Fragment key={`assets-${i}`}>
            <div className="flex flex-row items-center justify-between p-4 ">
              {asset.assetName ? (
                <span>{capitalizeTitle(asset.assetName)}</span>
              ) : asset.networthSourceName === `i don't own any assets ` ? (
                <span>Assets</span>
              ) : (
                <span>{capitalizeTitle(asset.networthSourceName)}</span>
              )}
              {asset.declaredAmount > 0 ? (
                <span className="font-medium">{formatCurrency(asset.declaredAmount)}</span>
              ) : (
                <span className="font-medium">{amountOrDash(asset.declaredAmount)}</span>
              )}
            </div>
            <Divider className="text-grey-1 m-0 px-4 pb-4" />
          </Fragment>
        ))
      ) : (
        <span className="flex flex-row items-center justify-between p-4">No assets</span>
      )}
      <div className="flex flex-col gap-4 px-2 pb-6 items-center">
        {confirmedSections.includes(FinancialConfirmationSectionEnum.Assets) ? (
          displayUpToDate && (
            <Alert variant="success" className="mb-0 sm:max-w-[20.5rem] w-full" autoDismissInMilliseconds={1500}>
              <p className="text-sm">Up to date</p>
            </Alert>
          )
        ) : (
          <Button
            type="button"
            alignIcon="end"
            variant="primary"
            disabled={isSubmitting}
            onClick={() => handleConfirmSection(FinancialConfirmationSectionEnum.Assets)}
          >
            That&rsquo;s right
          </Button>
        )}
        <Button
          type="button"
          alignIcon="end"
          variant="outline-secondary"
          hasShadow
          isLoading={isSubmitting}
          onClick={handleUpdateInfo}
        >
          Update my info
        </Button>
      </div>
    </Card>
  );
};

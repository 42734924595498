import React from 'react';
import { currencyOrDash, formatFrequency } from '@harmoney/utilities';
import { AssetAndLiabilityFrequencyEnum, IncomeAndExpenseFrequencyEnum, RepaymentFrequencyEnum } from '@prisma/client';

interface Props {
  amount: number;
  className?: string;
  frequency?: IncomeAndExpenseFrequencyEnum | AssetAndLiabilityFrequencyEnum | RepaymentFrequencyEnum;
  frequencyClassName?: string;
}

export const CurrencyWithFrequency: React.FC<Props> = ({ amount, frequency, className, frequencyClassName }) => {
  return (
    <span className={className}>
      {currencyOrDash(amount)}
      {amount !== null && frequency && (
        <>
          /<span className={frequencyClassName}>{formatFrequency(frequency)}</span>
        </>
      )}
    </span>
  );
};

import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { Frequency, LoanProductPurposeDto, LOCAL_QUOTE_DETAILS_KEY, LocalQuoteDetails } from '@harmoney/api-interfaces';
import { useFormatQuoteOptionsV2, useFriendlyURL } from '@harmoney/hooks';
import {
  useAppSelector,
  useGetLoanApplicationQuery,
  useGetQuoteOptionsForUnutilisedLimitQuery,
  useGetQuoteOptionsQuery,
  useGetUserQuery,
  useGetVariablesQuery,
} from '@harmoney/redux';
import { eventAnalytics, LOAN_APPLICATION_SUBMITTED } from '@harmoney/ui-app-shell';
import {
  AmountInput,
  ArrowCircleRightIcon,
  Button,
  Card,
  Divider,
  Form,
  Spinner,
  useForm,
} from '@harmoney/ui-design-system';
import {
  dayjsSydney,
  EXPIRY_DAYS_FOR_UNUTILISED_LIMIT,
  formatCurrency,
  MINIMUM_BORROWING_AMOUNT_FOR_UNUTILISED_LIMIT,
} from '@harmoney/utilities';
import { Icon } from '@iconify/react';
import { useLocalStorage, useScrollIntoView } from '@mantine/hooks';
import { LoanApplicationStatusEnum, RepaymentFrequencyEnum } from '@prisma/client';
import { isEmpty } from 'lodash';
import { z } from 'zod';

import { CommonProps } from '../../common-props';
import { QuoteHelpModal } from '../QuoteHelpModal/QuoteHelpModal';
import {
  calculateEstablishmentFee,
  getLoanPurposeName,
  InterestSavings,
  QuoteIntroductionV2,
  QuoteReviewModal,
  QuoteSummaryV2,
  RepaymentCalculator,
} from '../shared';

import {
  createFormSchemaForUnutilisedLimit,
  DEFAULT_REPAYMENT_FREQUENCY,
  DEFAULT_TERM_IN_MONTHS,
  defaultValues,
} from './form-config';

enum UIStateKey {
  IS_READY = 'isReady',
  IS_SUBMITTING = 'isSubmitting',
}

interface PersonalLoanQuoteProps extends CommonProps {
  isUnutilisedLimitLoan: boolean;
  defaultValues: typeof defaultValues;
  AdditionalFields?: React.ComponentType<{
    register: UseFormRegister<z.infer<ReturnType<typeof createFormSchemaForUnutilisedLimit>>>;
  }>;
  quoteData?: any | null;
  loanPurposesData?: LoanProductPurposeDto[];
  useGetQuoteOptionsQuery: typeof useGetQuoteOptionsQuery | typeof useGetQuoteOptionsForUnutilisedLimitQuery;
  createFormSchema: (lowerLimit: number, upperLimit: number) => any;
  submitLoanApplication: (data: any) => Promise<any>;
}

export function PersonalLoanQuote({
  taskId,
  taskFriendlyURL,
  isUnutilisedLimitLoan,
  defaultValues,
  quoteData,
  loanPurposesData,
  AdditionalFields,
  useGetQuoteOptionsQuery,
  completeTaskWithData,
  createFormSchema,
  submitLoanApplication,
}: PersonalLoanQuoteProps) {
  useFriendlyURL(taskFriendlyURL);
  const router = useRouter();
  const {
    primaryPurposeId: primaryPurposeIdFromURL,
    loanAmount: loanAmountFromURL,
    termInMonths: termInMonthsFromURL,
    repaymentFrequency: repaymentFrequencyFromURL,
  } = router.query;
  const userId = useAppSelector((state) => state.userId.value);
  const [localQuoteDetails, setLocalQuoteDetails] = useLocalStorage({
    key: `${LOCAL_QUOTE_DETAILS_KEY}-${taskId}`,
    defaultValue: {} as LocalQuoteDetails,
  });
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({ offset: 150 });
  const [uiState, setUIState] = useState({
    [UIStateKey.IS_READY]: false,
    [UIStateKey.IS_SUBMITTING]: false,
  });
  const [isQuoteReviewModalOpen, setIsQuoteReviewModalOpen] = useState(false);
  const [isQuoteHelpModalOpen, setIsQuoteHelpModalOpen] = useState(false);
  const [borrowingLimit, setBorrowingLimit] = useState<{ lowerLimit: number; upperLimit: number }>({
    lowerLimit: 0,
    upperLimit: 0,
  });

  const { data: userData } = useGetUserQuery();
  const { data: variables } = useGetVariablesQuery(taskId);
  const { data: loanApplicationData } = useGetLoanApplicationQuery(variables?.loanApplicationId.toString(), {
    skip: !variables,
    refetchOnMountOrArgChange: true,
  });

  const formSchema = createFormSchema(borrowingLimit.lowerLimit, borrowingLimit.upperLimit);
  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues,
  });
  const { register, watch, trigger, getValues, setValue } = form;
  const watchForm = watch();

  const totalAcceptedAmount = getValues('amount');
  const establishmentFee = useMemo(
    () => (isUnutilisedLimitLoan ? 0 : calculateEstablishmentFee(totalAcceptedAmount, quoteData)),
    [isUnutilisedLimitLoan, totalAcceptedAmount, quoteData]
  );

  const { data: quoteOptionsData } = useGetQuoteOptionsQuery(
    {
      loanApplicationId: variables?.loanApplicationId.toString(),
      loanAmount: totalAcceptedAmount,
      establishmentFee,
    },
    {
      skip: !variables || !totalAcceptedAmount || (!isUnutilisedLimitLoan && !establishmentFee),
      refetchOnMountOrArgChange: true,
    }
  );

  const formattedQuoteOptions = useFormatQuoteOptionsV2(
    watchForm.repaymentFrequency as RepaymentFrequencyEnum,
    quoteOptionsData
  );

  const repaymentDetails = useMemo(() => {
    if (!quoteOptionsData || !watchForm.termInMonths || !watchForm.repaymentFrequency) return null;

    const transformedQuoteOptions = quoteOptionsData.reduce((repaymentDetails, option) => {
      const termInMonths = option.termInMonths;
      const repaymentFrequency = option.repaymentFrequency;

      return {
        ...repaymentDetails,
        [termInMonths]: {
          ...repaymentDetails[termInMonths],
          [repaymentFrequency]: {
            ...option,
            establishmentFee: isUnutilisedLimitLoan ? 0 : option.establishmentFee,
            quotePresentedAmount: loanApplicationData?.quotePresentedAmount,
          },
        },
      };
    }, {});

    return transformedQuoteOptions[watchForm.termInMonths]?.[watchForm.repaymentFrequency];
  }, [
    quoteOptionsData,
    watchForm.termInMonths,
    watchForm.repaymentFrequency,
    isUnutilisedLimitLoan,
    loanApplicationData?.quotePresentedAmount,
  ]);

  const remainingAmount = useMemo(() => {
    if (!loanApplicationData) return;

    return borrowingLimit.upperLimit - watchForm.amount;
  }, [borrowingLimit.upperLimit, loanApplicationData, watchForm.amount]);

  const loanPurposeDetails = useMemo(() => {
    if (!loanApplicationData) return {};
    const loanApplicationPurpose = loanApplicationData.loanApplicationPurposes[0]?.loanPurpose;
    const selectedPurpose = isUnutilisedLimitLoan
      ? getLoanPurposeName(loanPurposesData, watchForm.loanPurposeForUnutilisedLimit)
      : loanApplicationPurpose;

    return {
      primaryPurposeId: selectedPurpose?.id,
      primaryPurpose: selectedPurpose?.displayName,
    };
  }, [loanApplicationData, isUnutilisedLimitLoan, loanPurposesData, watchForm.loanPurposeForUnutilisedLimit]);

  const { interestRate, quotePresentedAmount, isEligibleForExtraLimit, shouldShowRemainingAmountUnavailableMessage } =
    useMemo(() => {
      if (!loanApplicationData) return {};

      const isEligibleForExtraLimit = !isUnutilisedLimitLoan && loanApplicationData.isEligibleForExtraLimit;

      const isExtraLimitGreaterThanMinBorrowingLimit =
        remainingAmount > 0 &&
        loanApplicationData.quotePresentedAmount - remainingAmount >= MINIMUM_BORROWING_AMOUNT_FOR_UNUTILISED_LIMIT;

      return {
        interestRate: loanApplicationData.finalInterestRate as unknown as number,
        quotePresentedAmount: loanApplicationData?.quotePresentedAmount,
        isEligibleForExtraLimit,
        shouldShowExtraLimitMessage: isEligibleForExtraLimit && isExtraLimitGreaterThanMinBorrowingLimit,
        shouldShowRemainingAmountUnavailableMessage: isUnutilisedLimitLoan && isExtraLimitGreaterThanMinBorrowingLimit,
        expiryDate: dayjsSydney(
          loanApplicationData?.bankStatementReferences?.filter(
            (ref) => ref?.bankStatementReference?.callBackReceivedAt !== null
          )[0]?.bankStatementReference?.callBackReceivedAt
        )
          .add(EXPIRY_DAYS_FOR_UNUTILISED_LIMIT - 1, 'days')
          .format('D MMM YYYY'),
      };
    }, [isUnutilisedLimitLoan, loanApplicationData, remainingAmount]);

  const isSameLoanLimitAcrossOptions = useMemo(() => {
    return quoteOptionsData?.every(({ loanLimitFromUmi }) => loanLimitFromUmi === quoteOptionsData[0].loanLimitFromUmi);
  }, [quoteOptionsData]);

  useEffect(() => {
    if (
      !primaryPurposeIdFromURL ||
      !loanAmountFromURL ||
      !termInMonthsFromURL ||
      !repaymentFrequencyFromURL ||
      !loanApplicationData?.id
    )
      return;

    setLocalQuoteDetails((prevState) => ({
      ...prevState,
      primaryPurposeId: primaryPurposeIdFromURL as string,
      loanAmount: +loanAmountFromURL,
      termInMonths: +termInMonthsFromURL,
      repaymentFrequency: repaymentFrequencyFromURL as RepaymentFrequencyEnum,
      loanApplicationId: loanApplicationData.id,
    }));
  }, [
    loanAmountFromURL,
    primaryPurposeIdFromURL,
    repaymentFrequencyFromURL,
    termInMonthsFromURL,
    setLocalQuoteDetails,
    loanApplicationData?.id,
  ]);

  useEffect(() => {
    if (!loanApplicationData) return;

    setBorrowingLimit({
      lowerLimit: isUnutilisedLimitLoan
        ? MINIMUM_BORROWING_AMOUNT_FOR_UNUTILISED_LIMIT
        : +quoteData?.minimumBorrowingLimit,
      upperLimit: +loanApplicationData?.quotePresentedAmount,
    });

    const defaultLoanPurposeForUnutilisedLimit = '';
    const defaultTermInMonths = DEFAULT_TERM_IN_MONTHS;
    const defaultRepaymentFrequency = DEFAULT_REPAYMENT_FREQUENCY;

    let loanPurposeForUnutilisedLimit: string;
    let loanAmount: number;
    let termInMonths: number;
    let repaymentFrequency: RepaymentFrequencyEnum;

    if (!isEmpty(localQuoteDetails)) {
      loanPurposeForUnutilisedLimit = isUnutilisedLimitLoan
        ? localQuoteDetails?.primaryPurposeId
        : defaultLoanPurposeForUnutilisedLimit;
      loanAmount = +localQuoteDetails?.loanAmount;
      termInMonths = +localQuoteDetails?.termInMonths;
      repaymentFrequency = localQuoteDetails?.repaymentFrequency as RepaymentFrequencyEnum;
    } else {
      loanPurposeForUnutilisedLimit = defaultLoanPurposeForUnutilisedLimit;
      loanAmount = isUnutilisedLimitLoan
        ? +loanApplicationData?.quotePresentedAmount
        : +quoteData?.maximumBorrowingLimit;
      termInMonths = isUnutilisedLimitLoan ? defaultTermInMonths : +quoteData?.termInMonths;
      repaymentFrequency = defaultRepaymentFrequency;
    }

    if (isUnutilisedLimitLoan) {
      setValue('loanPurposeForUnutilisedLimit', loanPurposeForUnutilisedLimit);
    }
    setValue('amount', loanAmount);
    setValue('termInMonths', termInMonths);
    setValue('repaymentFrequency', repaymentFrequency);
  }, [
    getValues,
    isUnutilisedLimitLoan,
    loanApplicationData,
    localQuoteDetails,
    quoteData?.maximumBorrowingLimit,
    quoteData?.minimumBorrowingLimit,
    quoteData?.termInMonths,
    setValue,
  ]);

  const handleAction = async ({
    uiStateKey,
    onSuccess,
  }: {
    uiStateKey: UIStateKey;
    onSuccess: () => void | Promise<void>;
  }) => {
    setUIState((prevState) => ({
      ...prevState,
      [uiStateKey]: true,
    }));
    const isValid = await trigger();

    if (!isValid) {
      scrollIntoView();
      setUIState((prevState) => ({ ...prevState, [uiStateKey]: false }));
      return;
    }

    await onSuccess();
  };

  const handleEmailSend = () => {
    const updatedDetails = {
      ...localQuoteDetails,
      ...repaymentDetails,
      ...loanPurposeDetails,
      loanApplicationId: loanApplicationData?.id,
    };
    setLocalQuoteDetails(updatedDetails);
    return updatedDetails;
  };

  const handleQuoteReviewClick = async () => {
    await handleAction({
      uiStateKey: UIStateKey.IS_SUBMITTING,
      onSuccess: () => {
        setIsQuoteReviewModalOpen(true);

        setUIState((prevState) => ({
          ...prevState,
          [UIStateKey.IS_SUBMITTING]: false,
        }));
      },
    });
  };

  const handleNotReadyClick = async () => {
    await handleAction({
      uiStateKey: UIStateKey.IS_READY,
      onSuccess: () => {
        setIsQuoteHelpModalOpen(true);

        setUIState((prevState) => ({
          ...prevState,
          [UIStateKey.IS_READY]: false,
        }));
      },
    });
  };

  const handleSubmit = async () => {
    await handleAction({
      uiStateKey: UIStateKey.IS_SUBMITTING,
      onSuccess: async () => {
        await submitLoanApplication({
          id: variables.loanApplicationId.toString(),
          termInMonths: +watchForm.termInMonths,
          fundedAmount: +totalAcceptedAmount,
          repaymentFrequency: watchForm.repaymentFrequency as unknown as Frequency,
          status: LoanApplicationStatusEnum.application_in_progress,
          ...(isUnutilisedLimitLoan && { loanPurposeForUnutilisedLimit: watchForm.loanPurposeForUnutilisedLimit }),
        });

        eventAnalytics.track(LOAN_APPLICATION_SUBMITTED, {
          userid_str: userId,
          taskid_str: taskId,
        });

        await completeTaskWithData({ taskId });
      },
    });
  };

  const renderAmountInput = () => (
    <AmountInput {...register('amount')} label="How much do you want to borrow?" maxLength={6} className="m-4" />
  );

  const renderRemainingAmountMessage = (message: string) => (
    <div className="flex items-start p-4 bg-secondary-lighter-3 rounded-b-xl">
      <Icon icon="ic:baseline-currency-exchange" className="text-grey-3 mr-2" width={24} />
      <p className="text-sm">{message}</p>
    </div>
  );

  if (!loanApplicationData) return <Spinner />;

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="absolute -top-8 md:-top-[80px] w-full md:w-[756px] h-[200px] md:h-[245px] rounded-b-pill md:rounded-b-full bg-gradient-to-b from-primary-darker-1 to-primary" />

      <div className="z-10 w-full">
        <QuoteIntroductionV2
          preferredName={userData?.preferredName}
          quotePresentedAmount={quotePresentedAmount}
          interestRate={interestRate}
        />
      </div>

      <Form form={form} onSubmit={handleSubmit}>
        {(isUnutilisedLimitLoan || isEligibleForExtraLimit) && (
          <Card ref={targetRef} className="!p-0">
            {AdditionalFields && <AdditionalFields register={register} />}
            {renderAmountInput()}
            {shouldShowRemainingAmountUnavailableMessage &&
              renderRemainingAmountMessage(
                `The remaining ${formatCurrency(remainingAmount)} won't be available after taking this loan.`
              )}
          </Card>
        )}

        <Card className="py-6 px-0">
          <div className="px-4 pb-6">
            <RepaymentCalculator
              targetRef={targetRef}
              shouldShowLoanAmount={!isUnutilisedLimitLoan && !isEligibleForExtraLimit}
              formattedQuoteOptions={formattedQuoteOptions}
              isSameLoanLimitAcrossOptions={isSameLoanLimitAcrossOptions}
            />
          </div>

          <Divider className="text-grey-2" />

          <div className="p-4">
            <QuoteSummaryV2
              primaryPurpose={loanPurposeDetails?.primaryPurpose}
              repaymentDetails={repaymentDetails}
              isUnutilisedLimitLoan={isUnutilisedLimitLoan}
            />
          </div>

          <div className="text-center px-4 pt-2">
            <Button
              onClick={handleQuoteReviewClick}
              variant="primary"
              alignIcon="end"
              icon={<ArrowCircleRightIcon size="large" />}
              isLoading={uiState.isSubmitting}
              disabled={uiState.isReady}
              className="mb-6"
            >
              Review your quote
            </Button>
            <Button
              onClick={handleNotReadyClick}
              variant="outline-secondary"
              isLoading={uiState.isReady}
              disabled={uiState.isSubmitting}
            >
              Not ready yet?
            </Button>
          </div>
        </Card>
      </Form>

      <QuoteReviewModal
        primaryPurpose={loanPurposeDetails?.primaryPurpose}
        repaymentDetails={repaymentDetails}
        isQuoteReviewModalOpen={isQuoteReviewModalOpen}
        isSubmitting={uiState.isSubmitting}
        onQuoteReviewModalOpen={setIsQuoteReviewModalOpen}
        onSubmit={handleSubmit}
      />

      <QuoteHelpModal
        email={userData?.email}
        isQuoteHelpModalOpen={isQuoteHelpModalOpen}
        onEmailSend={handleEmailSend}
        onQuoteHelpModalOpen={setIsQuoteHelpModalOpen}
      />

      <div className="mt-6">
        <InterestSavings />
      </div>
    </div>
  );
}

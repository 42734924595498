import { useRouter } from 'next/router';
import { useState } from 'react';
import { useAuth } from '@harmoney/hooks';
import { ArrowCircleRightIcon, Button, Dialog } from '@harmoney/ui-design-system';

export function LoanAgreementGenerationError() {
  const [showModal, setShowModal] = useState(true);
  const router = useRouter();
  const { logout } = useAuth();

  const handleRefresh = () => {
    router.reload();
    setShowModal(false);
  };

  return (
    <Dialog
      open={showModal}
      onOpenChange={setShowModal}
      title="Document Generation Error"
      modal
      showCloseButton={false}
    >
      <div className="p-4">
        <div className="mt-1 mb-8">
          <p className="text-base">
            We encountered an issue while generating your &quot;Loan Agreement Details&quot; document. Our team is
            actively addressing this and will notify you once it&rsquo;s resolved.
          </p>
          <p className="text-base"> We apologise for any inconvenience and appreciate your patience.</p>
        </div>
        <div className="mb-8 flex flex-col-reverse items-center justify-center gap-2 sm:flex-row sm:justify-end ">
          <Button variant="secondary" className="md:min-w-fit" onClick={logout}>
            sign out
          </Button>
          <Button
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
            variant="primary"
            className="sm:min-w-fit"
            onClick={handleRefresh}
          >
            refresh
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

import { ScorecardOperation } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const scorecardOperationApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getScorecard: builder.query<ScorecardOperation[], string>({
      query: (applicationId: string) => {
        return {
          url: `/scorecard-operation`,
          method: 'GET',
          params: {
            applicationId,
          },
        };
      },
    }),
  }),
});

export const { useGetScorecardQuery } = scorecardOperationApi;

import { useEffect, useState } from 'react';
import { IWriteOffReasonFields } from '@harmoney/api-interfaces';
import {
  Alert,
  ArrowCircleRightIcon,
  Button,
  CommonOptionProps,
  Dialog,
  Form,
  Label,
  Select,
  Textarea,
  useForm,
} from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';
import { LoanWriteOffActionEnum } from '@prisma/client';
import { Entry } from 'contentful';

import { CommonProps } from '../../../common-props';
import { PaymentPlanFormFooter } from '../PaymentPlan/PaymentPlanFormFooter';
import { PaymentPlanFormHeader } from '../PaymentPlan/PaymentPlanFormHeader';
import { ChangeSummaryTypeEnum } from '../PaymentPlan/util';
import { Field } from '../shared';

import { createWriteOffFormSchema, writeOffLoanInitialValues } from './form-config';

interface WriteOffProps extends CommonProps {
  loanApplicationId: string;
  payOffAmount: number;
  writeOffReasons: Entry<IWriteOffReasonFields>[];
  errorOccurred?: boolean;
  onErrorAlert?: (value: boolean) => void;
  showWarningAlert?: boolean;
}

export const WriteOff = ({
  payOffAmount,
  taskId,
  completeTaskWithData,
  writeOffReasons,
  loanApplicationId,
  onErrorAlert,
  showWarningAlert,
}: WriteOffProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [initialtaskId, setInitialTaskId] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const sortedReasons = writeOffReasons?.sort((a, b) => a.fields.reason.localeCompare(b.fields.reason));

  const writeOffReasonOptions: CommonOptionProps[] = sortedReasons
    ? [
        { label: 'Select an option', value: '' },
        ...sortedReasons.map((reason) => ({
          label: reason.fields.reason,
          value: reason.fields.reason,
        })),
      ]
    : [];

  const form = useForm({
    mode: 'onTouched',
    schema: createWriteOffFormSchema(),
    defaultValues: writeOffLoanInitialValues,
  });

  const {
    watch,
    register,
    formState: { isValid },
  } = form;

  const watchForm = watch();

  const handleSubmit = () => {
    setInitialTaskId(taskId);
    setIsConfirmModalOpen(true);
  };

  const onModalSubmit = () => {
    setIsSubmitting(true);
    const formData = form.getValues();
    const writeOffData = {
      note: formData.notes,
      amount: payOffAmount,
      reason: formData.writeOffReason,
    };

    completeTaskWithData({
      taskId,
      variables: { writeOffData, loanApplicationId, actionType: LoanWriteOffActionEnum.create },
    });
    setIsConfirmModalOpen(false);
  };

  useEffect(() => {
    if (initialtaskId && initialtaskId !== taskId) {
      setIsSubmitting(false);
      setShowAlert(true);
      onErrorAlert(true);
    }
  }, [taskId]);

  return (
    <>
      <Form form={form} onSubmit={handleSubmit}>
        <PaymentPlanFormHeader
          headerTitle="Write Off"
          type={ChangeSummaryTypeEnum.WRITE_OFF}
          closeVisible={!isSubmitting}
        />
        <div className="px-4 pt-3">
          <Field>
            <span>Write off amount:</span>
            <span>{payOffAmount ? formatCurrency(Number(payOffAmount), 2) : '$0'}</span>
          </Field>
          <Select
            options={writeOffReasonOptions}
            name={'Write off reason'}
            label="Write off reason"
            className="mt-4"
            {...register('writeOffReason')}
          />
          <Label className="mt-8">Notes</Label>
          <span className="text-sm text-grey-4">
            Please ensure customers’ sensitive information is not recorded here.
          </span>
          <Textarea {...register('notes')} className="mt-2" />
          <PaymentPlanFormFooter
            buttonText="Save"
            type={ChangeSummaryTypeEnum.WRITE_OFF}
            isSubmitting={isSubmitting}
            isDisabled={!isValid}
            isCancelDisabled={isSubmitting}
          />
        </div>
        {showAlert && (
          <Alert variant="error" title="Failed to create write off record due to technical issues" className="mt-4" />
        )}
        {showWarningAlert && (
          <Alert
            variant="warning"
            title="There was a direct debit in the last 5 days which could impact the amount if it has not been cleared"
            className="mt-4"
          />
        )}
      </Form>
      <Dialog
        open={isConfirmModalOpen}
        onOpenChange={() => {
          setIsConfirmModalOpen(!isConfirmModalOpen);
        }}
        title="Write Off"
        modal
      >
        <p className="p-4 font-medium">
          Are you sure you want to fully write off this loan?
          <span className="flex justify-between mt-4">
            <span className="font-medium">Write off amount:</span>
            <span>{payOffAmount ? formatCurrency(Number(payOffAmount), 2) : '$0'}</span>
          </span>
          <span className="flex justify-between mt-4">
            <span className="font-medium">Write off reason:</span>
            <span>{watchForm.writeOffReason}</span>
          </span>
          <span className="flex justify-between mt-4">
            <span className="font-medium">Notes:</span>
            <span>{watchForm.notes}</span>
          </span>
        </p>
        <div className="shrink-1 flex w-full flex-col items-center justify-start gap-2 sm:flex-col md:flex-row-reverse p-4 flex-wrap">
          <Button
            onClick={onModalSubmit}
            type="submit"
            variant="primary"
            className="sm:!min-w-full md:!min-w-fit md:!max-w-fit"
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
            isLoading={isSubmitting}
            hasShadow
          >
            Write off
          </Button>
          <Button
            onClick={() => setIsConfirmModalOpen(false)}
            variant="secondary"
            className="!min-w-fit !max-w-fit"
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </div>
      </Dialog>
    </>
  );
};

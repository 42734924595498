import {
  DateRange,
  GetQuoteDetailsDto,
  Liability,
  PaginatedQueryInput,
  PaginatedResult,
  PreQualifiedQuoteBpmnVariables,
  PreQualifiedQuoteStats,
} from '@harmoney/api-interfaces';
import { LoanApplicationStatusEnum, PreQualifiedQuote, User } from '@prisma/client';

import { emptySplitApi } from './empty-api';

type QueryParams = {
  id: string;
  token: string;
};

export type ExtendedPreQualifiedQuote = Omit<PreQualifiedQuote, 'variables'> & {
  variables: PreQualifiedQuoteBpmnVariables;
  loanApplications: {
    id: string;
    businessKey: string;
    status: LoanApplicationStatusEnum;
  }[];
  liabilities?: Liability[];
};

export type ExtendedPreQualifiedQuoteWithUser = ExtendedPreQualifiedQuote & {
  user: User | null;
};

const preQualifiedQuoteApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getPreQualifiedQuote: builder.query<GetQuoteDetailsDto, QueryParams>({
      query: ({ id, token }) => ({
        url: `/pre-qualified-quote/${id}?token=${token}`,
        method: 'GET',
      }),
    }),
    getPreQualifiedQuoteData: builder.query<
      Pick<PreQualifiedQuote, 'firstName' | 'requestedAmount' | 'relationshipStatus'> & {
        loanPurposeId: string;
      },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/pre-qualified-quote/${id}/data`,
        method: 'GET',
      }),
    }),
    getInterestRateByPreQualifiedQuoteId: builder.query<number | null, { id: QueryParams['id'] }>({
      query: ({ id }) => ({
        url: `/pre-qualified-quote/${id}/interest-rate`,
        method: 'GET',
      }),
    }),
    getAllPreQualifiedQuotesForAdmin: builder.query<
      PaginatedResult<ExtendedPreQualifiedQuoteWithUser>,
      PaginatedQueryInput
    >({
      query: (params) => ({
        url: `/pre-qualified-quote/admin`,
        method: 'GET',
        params,
      }),
      providesTags: ['PreQualifiedQuote'],
    }),
    getPreQualifiedQuoteForAdmin: builder.query<ExtendedPreQualifiedQuote, { id: QueryParams['id'] }>({
      query: ({ id }) => ({
        url: `/pre-qualified-quote/admin/${id}`,
        method: 'GET',
      }),
      providesTags: ['PreQualifiedQuote'],
    }),
    getPreQualifiedQuotesByUserId: builder.query<ExtendedPreQualifiedQuote[], { userId: string }>({
      query: ({ userId }) => ({
        url: `/pre-qualified-quote/user/${userId}`,
        method: 'GET',
      }),
    }),
    getPreQualifiedQuoteStats: builder.query<PreQualifiedQuoteStats, { date: DateRange }>({
      query: ({ date }) => ({
        url: `/pre-qualified-quote/stats`,
        method: 'GET',
        params: { date },
      }),
    }),
    updateAcceptedTermsTimestamp: builder.mutation<{ success: boolean }, QueryParams>({
      query: ({ id, token }) => ({
        url: `/pre-qualified-quote/${id}/update-accepted-terms-timestamp?token=${token}`,
        method: 'PUT',
      }),
      invalidatesTags: ['PreQualifiedQuote'],
    }),
  }),
});

export const {
  useGetPreQualifiedQuoteQuery,
  useGetPreQualifiedQuoteForAdminQuery,
  useGetAllPreQualifiedQuotesForAdminQuery,
  useUpdateAcceptedTermsTimestampMutation,
  useGetInterestRateByPreQualifiedQuoteIdQuery,
  useGetPreQualifiedQuoteDataQuery,
  useGetPreQualifiedQuotesByUserIdQuery,
  useGetPreQualifiedQuoteStatsQuery,
} = preQualifiedQuoteApi;

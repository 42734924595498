import { errors } from '@harmoney/ui-utils';
import { BranchEnum } from '@prisma/client';
import { z } from 'zod';

import {
  Country,
  createFormSchema as createFormSchemaForMobilePhoneNumber,
} from '../../fulfilment/PhoneNumberVerification/form-config';

interface PhoneNumberVerificationForm {
  branch: BranchEnum;
  prefix: string;
  RegionalText: Record<BranchEnum, Country>;
}

export const createFormSchema = ({ branch, prefix, RegionalText }: PhoneNumberVerificationForm) => {
  const SchemaForMobilePhoneNumber = createFormSchemaForMobilePhoneNumber({ branch, prefix, RegionalText });

  const baseSchema = z.object({
    residencyStatus: z.string().min(1, { message: errors.defaultRequiredField }),
    relationshipStatus: z.string().min(1, { message: errors.defaultRequiredField }),
  });
  return baseSchema.merge(SchemaForMobilePhoneNumber);
};

import { Branch } from '@harmoney/api-interfaces';
import { useFriendlyURL } from '@harmoney/hooks';
import { useGetUserQuery, useGetVariablesQuery, useSubmitLoanApplicationMutation } from '@harmoney/redux';
import {
  AmountInput,
  ArrowCircleRightIcon,
  Button,
  Card,
  Form,
  Label,
  ToggleGroup,
  useForm,
} from '@harmoney/ui-design-system';
import { AmountFormatter, errors, toggleResidencyStatusOptions } from '@harmoney/ui-utils';
import { ResidencyStatus } from '@prisma/client';
import { z } from 'zod';

import { CommonProps } from '../../common-props';

export function LoanAmount({ loanProductData, taskId, completeTask, taskFriendlyURL }: CommonProps) {
  const { branch, displayResidencyStatus, minimumLoanAmount, maximumLoanAmount } = loanProductData;

  const baseFormSchema = z.object({
    amount: z
      .number({
        required_error: `Please enter an amount greater than ${AmountFormatter.format(minimumLoanAmount)}`,
        invalid_type_error: `Please enter an amount greater than ${AmountFormatter.format(minimumLoanAmount)}`,
      })
      .gte(minimumLoanAmount, {
        message: `Please enter an amount greater than ${AmountFormatter.format(minimumLoanAmount)}`,
      })
      .lte(maximumLoanAmount, {
        message: `Please enter an amount less than ${AmountFormatter.format(maximumLoanAmount)}`,
      }),
  });
  const formSchema = z.discriminatedUnion('displayResidencyStatus', [
    z
      .object({
        displayResidencyStatus: z.literal(true),
        residencyStatus: z.nativeEnum(ResidencyStatus, {
          errorMap: (issue, _ctx) => {
            if (issue.code) return { message: errors.defaultRequiredField };
          },
        }),
      })
      .merge(baseFormSchema),
    z
      .object({
        displayResidencyStatus: z.literal(false),
      })
      .merge(baseFormSchema),
  ]);

  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues: {
      displayResidencyStatus,
    },
  });
  const {
    register,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;

  const { data: userData } = useGetUserQuery();
  const { data: variables } = useGetVariablesQuery(taskId);
  const [submitLoanApplication] = useSubmitLoanApplicationMutation();

  const handleSubmit = async ({ amount, ...formValues }) => {
    const loanApplication = {
      id: variables?.loanApplicationId?.toString(),
      requestedAmount: amount,
      residencyStatus: formValues?.residencyStatus,
    };

    if (!formValues?.residencyStatus) {
      delete loanApplication.residencyStatus;
    }

    await submitLoanApplication(loanApplication);
    completeTask({ taskId });
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      <h1>
        Hi <span className="text-primary">{userData?.preferredName}</span>, tell us more
      </h1>
      <Form form={form} onSubmit={handleSubmit}>
        <Card>
          <Label className="mb-2 font-medium">How much would you like to borrow?</Label>
          <AmountInput
            {...register('amount')}
            maxLength={6}
            validationMessage={`An amount between ${AmountFormatter.format(
              minimumLoanAmount
            )} and ${AmountFormatter.format(maximumLoanAmount)}`}
          />
        </Card>
        {displayResidencyStatus && (
          <Card>
            <Label className="mb-2 font-medium">
              {`Are you ${branch === Branch.AU ? 'an Australian' : 'a New Zealand'} citizen or permanent resident?`}
            </Label>
            <ToggleGroup {...register('residencyStatus')} options={toggleResidencyStatusOptions(branch)} />
          </Card>
        )}
        <Button
          type="submit"
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          isLoading={isSubmitting || isSubmitSuccessful}
          hasShadow
        >
          Continue
        </Button>
      </Form>
    </>
  );
}

import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { TaskDetailDto } from '@harmoney/api-interfaces';

import * as Components from '../components';

export const useTaskComponent = (taskDetail?: TaskDetailDto, formKey?: string) => {
  const [TaskComponent, setTaskComponent] = useState(null);
  const router = useRouter();

  useEffect(() => {
    if (!taskDetail && !formKey) {
      setTaskComponent(null);
      return;
    }
    const componentKey = formKey ?? taskDetail.routeMap?.[taskDetail.taskDefinitionId];
    setTaskComponent(componentKey ? () => Components[componentKey] : undefined);
    return () => {
      setTaskComponent(null);
    };
  }, [taskDetail, router, formKey]);

  return TaskComponent;
};

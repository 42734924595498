/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef, useEffect, useRef } from 'react';
import { useMergedRef } from '@mantine/hooks';
// If this is imported differently, the component won't render in the Journey app. So please ignore the error. The commented out import URLs works only in Storybook.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import BpmnViewer from 'bpmn-js/dist/bpmn-navigated-viewer.production.min.js'; //'bpmn-js/lib/Viewer';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import type overlays from 'diagram-js/lib/features/overlays/Overlays.d.ts'; // 'bpmn-js/dist/bpmn-navigated-viewer.production.min.js';

import 'bpmn-js/dist/assets/diagram-js.css';
import styles from './BpmnRender.module.scss';

type BpmnProps = {
  xml: XMLDocument;
  processStats?: any[];
  sequenceFlows?: string[];
};

export const BpmnRender = forwardRef(({ xml, processStats, sequenceFlows }: BpmnProps, ref) => {
  const bpmnRef = useRef(null);
  const modeler = useRef<BpmnViewer>();

  const mergedRef = useMergedRef(bpmnRef, ref);

  useEffect(() => {
    const createModeler = async () => {
      if (bpmnRef.current && xml) {
        modeler.current = new BpmnViewer({
          container: bpmnRef.current,
          keyboard: {
            bindTo: window,
          },
        });
        try {
          await modeler.current.importXML(xml);
          const overlays = (await modeler?.current?.get('overlays')) as overlays;

          if (overlays && processStats) {
            processStats?.forEach(({ activityId, incidents, active, completed }) => {
              if (incidents > 0 && overlays) {
                overlays.add(activityId, 'flowNodeState', {
                  position: {
                    bottom: 9,
                    right: 0,
                  },
                  html: `<div class=${styles[`bpmn-render-incident`]}>${incidents}</div>`,
                });
              }
              if (active > 0 && overlays) {
                overlays.add(activityId, 'flowNodeState', {
                  position: {
                    bottom: 9,
                    left: 0,
                  },
                  html: `<div class=${styles[`bpmn-render-active`]}>${active}</div>`,
                });
              }
              if (active === 0 && incidents === 0) {
                const lastItem = processStats.find((processStat) => processStat.activityId.startsWith('end-'));
                if (lastItem) {
                  overlays.add(lastItem.activityId, 'flowNodeState', {
                    position: {
                      top: 23,
                      left: 8.3,
                    },
                    html: `<div class=${styles[`bpmn-render-complete`]}>${completed}</div>`,
                  });
                }
              }
            });
            if (sequenceFlows) {
              sequenceFlows.forEach((sequenceFlow) => {
                const elementRegistry: any = modeler?.current?.get('elementRegistry');
                const graphicsFactory: any = modeler?.current?.get('graphicsFactory');
                const element = elementRegistry?.get(sequenceFlow);
                if (element?.di !== undefined) {
                  element.di.set('stroke', '#4d90ff');

                  const gfx = elementRegistry?.getGraphics(element);
                  if (gfx !== undefined) {
                    graphicsFactory?.update('connection', element, gfx);
                  }
                }
              });
            }
          }
        } catch (error) {
          if (error) {
            console.error(`BPMN render error: ${error}`);
          }
        }
      }
    };

    createModeler();

    modeler.current?.on('import.done', () => {
      (modeler.current?.get('canvas') as any).zoom('fit-viewport', 'auto');
    });

    return () => {
      modeler.current?.destroy();
    };
  }, [xml, processStats, sequenceFlows]);

  return <div ref={mergedRef} id="canvas" style={{ height: '200px' }}></div>;
});

BpmnRender.displayName = 'BpmnRender';

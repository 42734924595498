import { LoanApplicationDto } from '@harmoney/api-interfaces';
import { Button, Card, Divider, Label } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';

export function ExtraLimitOption({
  maxExtraLimitForSecondaryPurpose,
  onBorrowMoreClick,
}: {
  loanApplicationData: LoanApplicationDto;
  maxExtraLimitForSecondaryPurpose: number;
  onBorrowMoreClick: () => void;
}) {
  return (
    <Card className="!p-0">
      <Label className="font-medium p-4">Borrow more</Label>
      <Divider className="m-0 text-grey-2" />

      <p className="px-4 pt-4">
        Borrow up to an extra <strong>{formatCurrency(maxExtraLimitForSecondaryPurpose)}</strong> for something else?
      </p>
      <div className="flex justify-center pb-4">
        <Button variant="secondary" className="!max-w-fit" onClick={onBorrowMoreClick}>
          Borrow more
        </Button>
      </div>
    </Card>
  );
}

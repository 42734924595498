import { AccommodationExpenseEnum, ResidentialStatusEnum } from '@harmoney/api-interfaces';

export const getAccommodationExpenseOptions = (livingSituation: ResidentialStatusEnum) => [
  {
    label: 'Select an option',
    value: '',
  },
  {
    label: getLabelForSharedExpense(livingSituation),
    value: AccommodationExpenseEnum.SHARED,
  },
  {
    label: 'No, I pay all of it',
    value: AccommodationExpenseEnum.FULL_PAID,
  },
  {
    label: getLabelForNoExpense(livingSituation),
    value: AccommodationExpenseEnum.NO_COVERAGE,
  },
];

const getLabelForSharedExpense = (livingSituation: ResidentialStatusEnum) =>
  livingSituation === ResidentialStatusEnum.RENTING ? 'Yes, I share my rent' : 'Yes, I share the cost';

const getLabelForNoExpense = (livingSituation: ResidentialStatusEnum) =>
  livingSituation === ResidentialStatusEnum.RENTING ? 'No, I don’t pay any rent' : 'No, I don’t pay anything';

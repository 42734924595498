import { TaskDefinitionIdEnum } from '@harmoney/api-interfaces';

export const taskHandlers: Record<
  TaskDefinitionIdEnum,
  {
    shouldRedirect: boolean;
  }
> = {
  [TaskDefinitionIdEnum.AFFORDABILITY_APPROVED_V4]: { shouldRedirect: true },
  [TaskDefinitionIdEnum.QUOTE_FOR_UNUTILISED_LIMIT_V2]: { shouldRedirect: true },
  // Future task handlers can be added here
};

import { LoanApplicationDto, relationshipStatusMapper, ResidencyStatusMapping } from '@harmoney/api-interfaces';
import { Card, Divider, Label } from '@harmoney/ui-design-system';
import { UserProfile } from '@prisma/client';

interface Props {
  loanApplicationData: LoanApplicationDto;
  userProfileData: UserProfile;
}

export const BasicInformation = ({ loanApplicationData, userProfileData }: Props) => {
  return (
    <Card className="!p-0">
      <div className="flex items-center justify-between">
        <Label className="p-4 text-lg font-medium">Basic information</Label>
      </div>
      <Divider className="text-grey-2 m-0 p-0" />

      <div className="flex items-center justify-between p-4">
        <Label>Residency status</Label>
        <span className="font-medium">{ResidencyStatusMapping[loanApplicationData?.residencyStatus]}</span>
      </div>
      <Divider className="text-grey-2 m-0 px-4" />

      <div className="flex items-center justify-between p-4">
        <Label>Relationship status</Label>
        <span className="font-medium">{relationshipStatusMapper[userProfileData?.relationshipStatus]}</span>
      </div>
    </Card>
  );
};

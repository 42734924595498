import { EmploymentCodeEnum } from '@prisma/client';

/* The `employmentCodeMapper` is a constant variable that maps the enum values of
`EmploymentCodeEnum` to their corresponding string representations. */
export const employmentCodeMapper: Record<EmploymentCodeEnum, string> = {
  [EmploymentCodeEnum.BENEFIT_UNEMPLOYMENT]: 'BENEFIT & UNEMP',
  [EmploymentCodeEnum.PROFESSIONAL]: 'Professional',
  [EmploymentCodeEnum.SELF_EMPLOYED]: 'Self-Employed',
  [EmploymentCodeEnum.RETIRED_SUPERANNUATION]: 'RETIRED & SUPER',
  [EmploymentCodeEnum.UNSKILLED]: 'Unskilled',
  [EmploymentCodeEnum.OTHERS]: 'Others',
  [EmploymentCodeEnum.PENSION]: 'PENSION',
  [EmploymentCodeEnum.STUDENT]: 'STUDENT',
  [EmploymentCodeEnum.HOME]: 'HOME',
  [EmploymentCodeEnum.OFFICE]: 'OFFICE',
  [EmploymentCodeEnum.TRADE]: 'TRADE',
};

/* The `employmentCodeReverseMapper` is a constant variable that maps string values to the corresponding
enum values of the `EmploymentCodeEnum` enum. */
export const employmentCodeReverseMapper: Record<string, EmploymentCodeEnum> = {
  'BENEFIT & UNEMP': EmploymentCodeEnum.BENEFIT_UNEMPLOYMENT,
  Professional: EmploymentCodeEnum.PROFESSIONAL,
  'Self-Employed': EmploymentCodeEnum.SELF_EMPLOYED,
  'RETIRED & SUPER': EmploymentCodeEnum.RETIRED_SUPERANNUATION,
  Unskilled: EmploymentCodeEnum.UNSKILLED,
  Others: EmploymentCodeEnum.OTHERS,
  PENSION: EmploymentCodeEnum.PENSION,
  STUDENT: EmploymentCodeEnum.STUDENT,
  HOME: EmploymentCodeEnum.HOME,
  OFFICE: EmploymentCodeEnum.OFFICE,
  TRADE: EmploymentCodeEnum.TRADE,
};

import { convertStringToBoolean } from '@harmoney/utilities';
import { AssetAndLiabilityOwnershipEnum } from '@prisma/client';

export const NETWORTH_CODE_NO_DEBT = 'no_debt';
export const NETWORTH_CODE_MORTGAGE = 'mortgage';
export const NETWORTH_CODE_CAR_LOAN = 'car_loan';

export function transformSubmittedData(data: { debts?: object }) {
  return Object.entries(data.debts).flatMap(([key, value]) => {
    value.map((item) => {
      Object.entries(item).map(([key, value]) => {
        if (value === 'true' || value === 'false') {
          item[key] = convertStringToBoolean(value);
        }
        if (key === 'frequency' && value === '') {
          item[key] = null;
        }
      });
    });

    if (key === NETWORTH_CODE_MORTGAGE) {
      const data = [];
      value.map((item) => {
        if (item.jointMortgageFrequency === '') {
          item = { ...item, jointMortgageFrequency: null };
        }
        if (item.rentalIncomeCoversMortgage === '') {
          item = { ...item, rentalIncomeCoversMortgage: null };
        }
        if (item.isJointMortgage === '') {
          item = { ...item, isJointMortgage: null };
        }
        data.push(item);
      });
      return data;
    }
    if (key === NETWORTH_CODE_CAR_LOAN) {
      const data = [];
      value.map((item) => {
        if (item.bankReference) {
          item = {
            ...item,
            ownership: item.ownUndisclosedDebt
              ? AssetAndLiabilityOwnershipEnum.myself
              : item.ownUndisclosedDebt === undefined
                ? AssetAndLiabilityOwnershipEnum.myself
                : AssetAndLiabilityOwnershipEnum.partner,
          };
        }
        data.push(item);
      });
      return data;
    }
    if (key === NETWORTH_CODE_NO_DEBT) {
      return value[0];
    }

    return value;
  });
}

import { useMemo, useState } from 'react';
import { LoanApplicationDto, RepaymentFrequency } from '@harmoney/api-interfaces';
import { useCalculateDebtConBenefits, useQuoteData } from '@harmoney/hooks';
import {
  useGetPostApprovalConsolidatedDebtsQuery,
  useGetQuoteOptionsQuery,
  useGetVariablesQuery,
} from '@harmoney/redux';
import { eventAnalytics, QUOTE_READY } from '@harmoney/ui-app-shell';
import { ArrowCircleRightIcon, Badge, Button, Card, ClockLightningIcon, Divider } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';
import { AssetAndLiability } from '@prisma/client';

import { CommonProps } from '../../common-props';
import { DebtConBenefitsRender } from '../../debt-consolidation/ConsolidateDebt/components';
import { DebtsSummaryV2 } from '../DebtConsolidationQuoteV2/components/DebtsSummaryV2';
import { calculateEstablishmentFee } from '../shared';

import { TimeDisplayBySydneyTimeZone } from './QuoteReadyV2';

import 'swiper/css';

interface QuoteReadyDebtConProps extends CommonProps {
  loanApplicationData: LoanApplicationDto;
}

const SEVEN_YEARS_IN_MONTHS = 84;

const renderSelectedDebtsForConsolidationMessage = (
  consolidatedDebts: Record<string, any>[],
  postApprovalDebtsData: (AssetAndLiability & { networthSourceName: string })[]
) => {
  const areAllConsolidatedDebtsApproved = consolidatedDebts.every((cd) =>
    postApprovalDebtsData.find((pd) => pd.id === cd.id)
  );

  if (areAllConsolidatedDebtsApproved) {
    return null;
  }

  return (
    <span className="text-grey-4 text-xs">
      Please note that {postApprovalDebtsData?.length === 1 ? 'only one' : 'not all'} of the debts you selected could be
      consolidated.
    </span>
  );
};

export function QuoteReadyDebtCon({
  loanApplicationData,
  loanProductData,
  taskId,
  completeTask,
}: QuoteReadyDebtConProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: postApprovalDebtsData } = useGetPostApprovalConsolidatedDebtsQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const { quoteData } = useQuoteData(taskId);
  const { data: variables } = useGetVariablesQuery(taskId);

  const maxExtraLimitForSecondaryPurpose = useMemo(() => {
    return loanApplicationData
      ? loanApplicationData.applicationBorrowingLimit - loanApplicationData?.quoteMaxBorrowingLimit
      : 0;
  }, [loanApplicationData]);

  const establishmentFee = useMemo(() => {
    return calculateEstablishmentFee(loanApplicationData?.quoteMaxBorrowingLimit, quoteData);
  }, [quoteData, loanApplicationData]);

  const { data: debtConQuoteOptionsData } = useGetQuoteOptionsQuery(
    {
      loanApplicationId: loanApplicationData?.id,
      loanAmount: loanApplicationData?.quoteMaxBorrowingLimit,
      establishmentFee: establishmentFee,
    },
    {
      skip: !variables || !establishmentFee || !loanApplicationData?.quoteMaxBorrowingLimit,
      refetchOnMountOrArgChange: true,
    }
  );

  const hmyRepaymentAmount = useMemo(() => {
    const result = debtConQuoteOptionsData?.find(
      (option) =>
        option.termInMonths === SEVEN_YEARS_IN_MONTHS && option.repaymentFrequency === RepaymentFrequency.MONTHLY
    )?.repaymentAmount;
    return result || undefined;
  }, [debtConQuoteOptionsData]);

  const benefits = useCalculateDebtConBenefits({
    postApprovalDebtsData,
    maximumBorrowingLimit: loanApplicationData?.quoteMaxBorrowingLimit,
    interestRate: Number(loanApplicationData?.finalInterestRate),
    hmyRepaymentAmount,
  });

  const handleContinueClick = async () => {
    setIsSubmitting(true);

    await completeTask({
      taskId,
    });
    eventAnalytics.track(QUOTE_READY, {
      taskid_str: taskId,
    });
  };

  return (
    <>
      <Card>
        <h1>
          Congratulations, here&rsquo;s your <span className="text-primary">quote</span>
        </h1>
        {loanProductData && loanApplicationData && (
          <>
            <p className="mb-6">
              Fixed interest rate of{' '}
              <span className="font-medium">{loanApplicationData?.finalInterestRate as unknown as number}% p.a.</span>
            </p>

            <DebtsSummaryV2 postApprovalDebtsData={postApprovalDebtsData} />

            {variables?.consolidatedDebts &&
              postApprovalDebtsData &&
              renderSelectedDebtsForConsolidationMessage(
                variables.consolidatedDebts as Record<string, any>[],
                postApprovalDebtsData
              )}
            <div className="mt-4">
              {loanApplicationData?.isEligibleForExtraLimit &&
                maxExtraLimitForSecondaryPurpose >= loanApplicationData.minExtraLimit && (
                  <>
                    <Badge variant="tertiary" label="Optional" className="text-xs my-2" />
                    <p className="flex flex-row justify-between items-center gap-2 mb-0 text-sm">
                      Borrow extra for something else
                      <span>{formatCurrency(maxExtraLimitForSecondaryPurpose)}</span>
                    </p>
                    <Divider className="mx-0 text-grey-2 my-2" />
                    <p className="flex flex-row justify-between items-center gap-2 mb-0">
                      Total borrowing limit
                      <span className="font-medium">{formatCurrency(loanApplicationData?.quotePresentedAmount)}</span>
                    </p>
                  </>
                )}
            </div>
          </>
        )}
      </Card>

      {benefits && <DebtConBenefitsRender benefits={benefits} />}

      <Card className="flex items-center !p-4">
        <span className="mr-2 flex">
          <ClockLightningIcon />
        </span>
        <span className="text-sm">
          Hit continue and your debts could be paid off <TimeDisplayBySydneyTimeZone />
        </span>
      </Card>
      <div className="mb-6 text-center">
        <Button
          onClick={handleContinueClick}
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          isLoading={isSubmitting}
        >
          Continue
        </Button>
      </div>
    </>
  );
}

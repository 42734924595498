import { generateIcon } from '../../generateIcon';
import { IconVariant } from '../../Icon';

export const FuneralExpensesIcon = function ({ variant = 'grey-4' }: { variant?: IconVariant }) {
  const Component = generateIcon({
    viewBox: '0 0 24 24',
    variant: variant,
    path: (
      <>
        <g clipPath="url(#clip0_4222_53318)">
          <path d="M6.32031 13.01C7.28031 13.03 8.17031 13.51 8.77031 14.35C9.50031 15.38 10.7103 16 12.0003 16C13.2903 16 14.5003 15.38 15.2303 14.34C15.8303 13.5 16.7203 13.02 17.6803 13C16.9603 11.78 14.0803 11 12.0003 11C9.93031 11 7.04031 11.78 6.32031 13.01Z" />
          <path d="M4 13C5.66 13 7 11.66 7 10C7 8.34 5.66 7 4 7C2.34 7 1 8.34 1 10C1 11.66 2.34 13 4 13Z" />
          <path d="M20 13C21.66 13 23 11.66 23 10C23 8.34 21.66 7 20 7C18.34 7 17 8.34 17 10C17 11.66 18.34 13 20 13Z" />
          <path d="M12 10C13.66 10 15 8.66 15 7C15 5.34 13.66 4 12 4C10.34 4 9 5.34 9 7C9 8.66 10.34 10 12 10Z" />
          <path d="M21 14H17.73C16.96 14 16.38 14.45 16.05 14.92C16.01 14.98 14.69 17 12 17C10.57 17 8.97 16.36 7.95 14.92C7.56 14.37 6.95 14 6.27 14H3C1.9 14 1 14.9 1 16V19C1 19.55 1.45 20 2 20H7C7.55 20 8 19.55 8 19V17.74C9.15 18.54 10.54 19 12 19C13.46 19 14.85 18.54 16 17.74V19C16 19.55 16.45 20 17 20H22C22.55 20 23 19.55 23 19V16C23 14.9 22.1 14 21 14Z" />
        </g>
        <defs>
          <clipPath id="clip0_4222_53318">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </>
    ),
  });
  return <Component />;
};

import { generateIcon } from '../../generateIcon';
import { IconVariant } from '../../Icon';

export const GoingOnHolidayIcon = function ({ variant = 'grey-4' }: { variant?: IconVariant }) {
  const Component = generateIcon({
    viewBox: '0 0 24 24',
    variant: variant,
    path: (
      <path d="M13.1277 14.5594L14.5577 13.1294L20.9977 19.5724L19.5707 20.9994L13.1277 14.5594ZM17.4207 8.82937L20.2807 5.96937C16.3307 2.01937 9.93071 2.00937 5.98071 5.94937C9.91071 4.64937 14.2907 5.69937 17.4207 8.82937V8.82937ZM5.95071 5.97937C2.01071 9.92937 2.02071 16.3294 5.97071 20.2794L8.83071 17.4194C5.70071 14.2894 4.65071 9.90937 5.95071 5.97937ZM5.97071 5.95937L5.96071 5.96937C5.58071 8.97937 7.13071 12.8494 10.2607 15.9894L15.9907 10.2594C12.8607 7.12937 8.98071 5.57937 5.97071 5.95937V5.95937Z" />
    ),
  });
  return <Component />;
};

import React, { forwardRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import classNames from 'classnames';

import { FormField } from '../FormField';
import { HelpText } from '../HelpText';
import { ControlledInputProps } from '../Input';
import { Label } from '../Label';
import { ValidationMessage } from '../ValidationMessage';

type PercentageInputProps = ControlledInputProps & {
  inputWidth?: string;
};

export const PercentageInput = forwardRef<HTMLElement, PercentageInputProps>(
  (
    {
      className,
      name,
      validationMessage = '',
      maxLength = 5,
      displayValidation = true,
      label,
      helpText,
      inputWidth = 'w-full',
      ...restProps
    }: PercentageInputProps,
    forwardRef: React.Ref<HTMLElement>
  ) => {
    const { control } = useFormContext();

    const {
      field: { onChange, value: fieldValue, onBlur },
      fieldState: { invalid },
    } = useController({
      name,
      control,
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(parseFloat(event.target.value));
    };

    return (
      <div className={className}>
        {label && (
          <Label htmlFor={name} className="mb-2">
            {label}
          </Label>
        )}
        {helpText && <HelpText>{helpText}</HelpText>}
        <FormField suffix="%" {...restProps} className={classNames([invalid && 'border-error', inputWidth])}>
          <NumericFormat
            value={fieldValue}
            onBlur={onBlur}
            onChange={handleChange}
            decimalScale={2}
            max={100}
            min={0}
            maxLength={5}
            allowNegative={false}
            className="w-full"
            isAllowed={(values) => {
              const { floatValue } = values;
              return floatValue === undefined || floatValue <= 100;
            }}
          />
        </FormField>
        {validationMessage && invalid ? (
          <small className="text-grey-4 leading-lg block text-sm">{validationMessage}</small>
        ) : (
          displayValidation && <ValidationMessage name={name} />
        )}
      </div>
    );
  }
);

import { LoanApplicationDto } from '@harmoney/api-interfaces';
import { Button, Card } from '@harmoney/ui-design-system';

import { ExtraLimitDescription } from './ExtraLimitDescription';

export function ExtraLimitOption({
  loanApplicationData,
  maxExtraLimitForSecondaryPurpose,
  onBorrowMoreClick,
}: {
  loanApplicationData: LoanApplicationDto;
  maxExtraLimitForSecondaryPurpose: number;
  onBorrowMoreClick: () => void;
}) {
  return (
    <Card className="!p-0">
      <ExtraLimitDescription
        interestRate={loanApplicationData?.interestRate}
        maxExtraLimitForSecondaryPurpose={maxExtraLimitForSecondaryPurpose}
      />
      <div className="p-4 text-center">
        <Button
          variant="outline-secondary"
          className="sm:!min-w-full md:!min-w-fit md:!max-w-fit"
          onClick={onBorrowMoreClick}
        >
          Borrow more
        </Button>
      </div>
    </Card>
  );
}

import Link from 'next/link';
import { loanStatusEmojiMapper, preQualifiedQuoteLabelMapper } from '@harmoney/api-interfaces';
import { ExtendedPreQualifiedQuote } from '@harmoney/redux';
import { TextLink } from '@harmoney/ui-design-system';
import { dateOrDash, valueOrDash } from '@harmoney/ui-utils';
import { PreQualifiedQuoteStatusEnum } from '@prisma/client';
import { lowerCase } from 'lodash';

import { Field } from './shared';

interface PreQualifiedQuoteDecisionDetailsProps {
  preQualifiedQuote: ExtendedPreQualifiedQuote;
}

export const PreQualifiedQuoteDecisionDetails = ({ preQualifiedQuote }: PreQualifiedQuoteDecisionDetailsProps) => {
  return (
    <div className="grid grid-cols-2 px-4">
      <div className="border-r-grey-1 border-r-2 p-4">
        <Field>
          <span>Decision:</span>
          <span>{preQualifiedQuoteLabelMapper[preQualifiedQuote?.quoteStatus]}</span>
        </Field>
        {preQualifiedQuote?.processInstanceKey && (
          <TextLink
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.NEXT_PUBLIC_CAMUNDA_OPERATE_ENDPOINT}/processes/${preQualifiedQuote.processInstanceKey}`}
          >
            Link to Camunda Operate
          </TextLink>
        )}
        <Field className="mt-4">
          <span>Application(s)</span>
          <div className="flex flex-col">
            {preQualifiedQuote.quoteStatus === 'QUOTE' && preQualifiedQuote?.loanApplications?.length > 0 ? (
              // for each application link to the PQ show the application number
              preQualifiedQuote?.loanApplications.map((application) => (
                <Link
                  key={application.id}
                  href={`/admin/details/?userId=${preQualifiedQuote.userId}#applications/${application.id}`}
                >
                  {application.businessKey} {loanStatusEmojiMapper[application.status]}
                </Link>
              ))
            ) : (
              <span>No applications linked</span>
            )}
          </div>
        </Field>
      </div>
      <div className="p-4">
        <Field>
          <span>Failed reason:</span>
          <span className="first-letter:capitalize">{valueOrDash(lowerCase(preQualifiedQuote?.failedReason))}</span>
        </Field>
        <Field>
          <span>Note:</span>
          <span>{valueOrDash(preQualifiedQuote?.note)}</span>
        </Field>
        {preQualifiedQuote?.quoteStatus === PreQualifiedQuoteStatusEnum.QUOTE && (
          <>
            <Field>
              <span>Advert clicked at:</span>
              <span>{dateOrDash(preQualifiedQuote.advertClickedAt)}</span>
            </Field>
            <Field>
              <span>T&C&rsquo;s accepted at:</span>
              <span>{dateOrDash(preQualifiedQuote.acceptedTermsTimestamp)}</span>
            </Field>
          </>
        )}
      </div>
    </div>
  );
};

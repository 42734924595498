import { errors } from '@harmoney/ui-utils';
import { isEmpty } from 'lodash';
import { z } from 'zod';

export const defaultValues = {
  declaredAmount: null,
  frequency: '',
};

export const formSchema = z
  .object({
    declaredAmount: z
      .number({
        invalid_type_error: errors.defaultValidAmount,
        required_error: errors.defaultValidAmount,
      })
      .min(0, { message: errors.defaultValidAmount })
      .nullable()
      .optional(),
    frequency: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.declaredAmount !== null && data.declaredAmount > 0 && isEmpty(data.frequency)) {
        return false;
      }
      return true;
    },
    {
      message: errors.defaultRequiredFrequency,
      path: ['frequency'],
    }
  );

export type FormSchema = z.infer<typeof formSchema>;

import { useGetRepaymentDetailQuery, useSavePaymentMutation } from '@harmoney/redux';

import { CommonProps } from '../../common-props';
import { WizardChildProps } from '../../Wizard';

import { PaymentForm } from './components';

export interface PaymentProps extends CommonProps, WizardChildProps {}

export function Payment({ taskId, onNext, completeTask, taskFriendlyURL }: PaymentProps) {
  return (
    <PaymentForm
      taskId={taskId}
      taskFriendlyURL={taskFriendlyURL}
      repaymentDetailQuery={useGetRepaymentDetailQuery}
      savePaymentMutation={useSavePaymentMutation}
      onNext={onNext}
      completeTask={completeTask}
    />
  );
}

import { useMemo } from 'react';
import { AmountFrequency, Button, ControlledInput } from '@harmoney/ui-design-system';
import { frequencyOptionsWithYear } from '@harmoney/ui-utils';

export const LivingExpenseItem = ({ code, index, ...props }) => {
  const controls = useMemo(() => {
    const expenseNameControl = ['other_expenses'];

    const controls = [];

    if (expenseNameControl.includes(code)) {
      controls.push(
        <ControlledInput
          {...props.register(`expenses.${code}[${index}].otherExpenseType` as const)}
          label="What kind of expense is it?"
          className="mb-4"
          key={`expenseNameControl${code}_${index}`}
        />
      );
      controls.push(
        <div key={`amountFrequency_${code}_${index}`}>
          <label>
            How much do <span className="font-medium">you</span> pay towards it?
          </label>
          <AmountFrequency
            register={props.register}
            inputKey={`expenses.${code}[${index}].declaredAmount` as const}
            selectKey={`expenses.${code}[${index}].frequency` as const}
            name="expense"
            options={frequencyOptionsWithYear}
            className="mb-6"
          />
        </div>
      );
      if (index !== 0) {
        controls.push(
          <span className="w-100 mx-4 mb-3 flex justify-end" key={`delete-button-${index}`}>
            <Button size="small" variant="text" onClick={props.removeItem} className="inline-flex items-center">
              Delete
            </Button>
          </span>
        );
      }
    } else {
      controls.push(
        <div key={`amountFrequency_${code}`}>
          <label>
            How much do <span className="font-medium">you</span> pay towards it?
          </label>
          <AmountFrequency
            register={props.register}
            inputKey={`expenses.${code}.declaredAmount`}
            selectKey={`expenses.${code}.frequency`}
            name="expense"
            options={frequencyOptionsWithYear}
            className="mb-6"
          />
        </div>
      );
    }

    return controls;
  }, [code, index, props]);

  return (
    controls.length > 0 && (
      <div className="border-grey-3 border-1 mb-3 rounded-lg border border-inherit p-6 pb-0">{controls} </div>
    )
  );
};

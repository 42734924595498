import React, { useEffect, useMemo, useState } from 'react';
import { relationshipStatusMapper, ResidencyStatusMapping } from '@harmoney/api-interfaces';
import { useFriendlyURL, UserInformationConfirmationSectionEnum, useUserInformationConfirm } from '@harmoney/hooks';
import { useAppSelector, useGetUserProfileQuery, useGetUserQuery } from '@harmoney/redux';
import { Alert, ArrowCircleRightIcon, Button, Spinner } from '@harmoney/ui-design-system';
import { valueOrNA } from '@harmoney/ui-utils';
import { useScrollIntoView } from '@mantine/hooks';
import dayjs from 'dayjs';

import { CommonProps } from '../../common-props';

import { InformationSummary, InfoSummaryField } from './components';

export function UserInformationSummary({ taskId, completeTaskWithData, taskFriendlyURL }: CommonProps) {
  useFriendlyURL(taskFriendlyURL);
  const { confirmSection, confirmedSections } = useUserInformationConfirm();
  const [isSubmitting, setIsSubmitting] = useState({
    updateUserInformation: false,
    updateIdentityVerification: false,
    continue: false,
  });
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 60,
  });
  const token = useAppSelector((state) => state.accessToken.value);
  const { data: userData } = useGetUserQuery(null, { skip: !token, refetchOnMountOrArgChange: true });
  const { data: userProfile } = useGetUserProfileQuery(null, { skip: !token, refetchOnMountOrArgChange: true });

  const dateOfBirth = useMemo(() => {
    if (userProfile?.dateOfBirth) {
      return dayjs(userProfile.dateOfBirth).format('DD/MM/YYYY');
    }
    return null;
  }, [userProfile?.dateOfBirth]);

  const address = useMemo(() => {
    const currentAddress = userProfile?.addresses?.find((address) => address.isCurrent);
    if (currentAddress) {
      return `${currentAddress.streetNumber + ' ' + currentAddress.streetName}, ${currentAddress.suburb || currentAddress.town}, ${currentAddress.state}, ${currentAddress.postalCode}, ${currentAddress.country}`;
    }
    return null;
  }, [userProfile?.addresses]);

  const [showIDVerificationConfirmed, showPersonalSectionConfirmed] = useMemo(() => {
    if (confirmedSections.length === 0) {
      return [false, false];
    }
    if (confirmedSections.length === 1) {
      return [true, false];
    }
    return [false, true];
  }, [confirmedSections?.length]);

  const handleSubmit = async () => {
    try {
      setIsSubmitting({ updateIdentityVerification: false, updateUserInformation: false, continue: true });
      await completeTaskWithData({ taskId, variables: { nextStep: 'Continue' } });
    } catch (e) {
      console.error(`Error completing task ${taskId}`, e);
      setIsSubmitting({ updateIdentityVerification: false, updateUserInformation: false, continue: false });
    }
  };

  const handleUpdateInfo = async (nextStep: string) => {
    try {
      if (nextStep === 'UpdateUserInformation') {
        setIsSubmitting({ updateUserInformation: true, updateIdentityVerification: false, continue: false });
      } else {
        setIsSubmitting({ updateUserInformation: false, updateIdentityVerification: true, continue: false });
      }
      await completeTaskWithData({ taskId, variables: { nextStep } });
    } catch (e) {
      console.error(`Error completing task ${taskId}`, e);
      setIsSubmitting({ updateUserInformation: false, updateIdentityVerification: false, continue: false });
    }
  };

  useEffect(() => {
    if (targetRef?.current && confirmedSections?.length !== 0) {
      scrollIntoView();
    }
  }, [scrollIntoView, targetRef?.current, confirmedSections?.length]);

  if (!userData || !userProfile) {
    return <Spinner />;
  }

  return (
    <>
      <h1>
        Does everything <span className="text-primary">look right</span>?
      </h1>
      <InformationSummary title="ID verification" showDivider>
        <InfoSummaryField
          label="Name"
          value={valueOrNA(`${userProfile?.firstName} ${userProfile.middleName} ${userProfile?.lastName}`)}
          ratio={3}
        />
        <InfoSummaryField label="Date of Birth" value={valueOrNA(dateOfBirth)} />
        <InfoSummaryField label="Current address" value={valueOrNA(address)} />
        <div className="flex flex-col gap-4 items-center">
          {showIDVerificationConfirmed && (
            <Alert variant="success" className="mb-0 sm:max-w-[20.5rem] w-full" autoDismissInMilliseconds={1500}>
              <p className="text-sm">Up to date</p>
            </Alert>
          )}
          {!confirmedSections.includes(UserInformationConfirmationSectionEnum.IdentityVerification) && (
            <Button
              type="submit"
              alignIcon="end"
              variant="primary"
              icon={<ArrowCircleRightIcon size="large" />}
              onClick={() => confirmSection(UserInformationConfirmationSectionEnum.IdentityVerification)}
              disabled={isSubmitting.updateIdentityVerification}
            >
              That&rsquo;s right
            </Button>
          )}
          <Button
            type="submit"
            variant="outline-secondary"
            hasShadow
            onClick={() => handleUpdateInfo('UpdateIdentityVerification')}
            isLoading={isSubmitting.updateIdentityVerification}
            disabled={isSubmitting.updateUserInformation || isSubmitting.continue}
          >
            Update my info
          </Button>
        </div>
      </InformationSummary>
      <div ref={targetRef}>
        {confirmedSections.length !== 0 && (
          <InformationSummary title="Personal" showDivider>
            <InfoSummaryField
              label="Citizenship"
              value={valueOrNA(ResidencyStatusMapping[userData?.residencyStatus])}
              ratio={3}
            />
            <InfoSummaryField
              label="Relationship status"
              value={valueOrNA(relationshipStatusMapper[userData?.relationshipStatus])}
            />
            <InfoSummaryField label="Phone number" value={valueOrNA(userData?.mobilePhoneNumber)} />
            <InfoSummaryField label="Email" value={valueOrNA(userData?.email)} />
            <div className="flex flex-col gap-4 items-center">
              {showPersonalSectionConfirmed && (
                <Alert variant="success" className="mb-0 sm:max-w-[20.5rem] w-full" autoDismissInMilliseconds={1500}>
                  <p className="text-sm">Up to date</p>
                </Alert>
              )}
              {!confirmedSections.includes(UserInformationConfirmationSectionEnum.UserInformation) && (
                <Button
                  type="submit"
                  alignIcon="end"
                  variant="primary"
                  icon={<ArrowCircleRightIcon size="large" />}
                  onClick={() => confirmSection(UserInformationConfirmationSectionEnum.UserInformation)}
                  disabled={isSubmitting.updateUserInformation}
                >
                  That&rsquo;s right
                </Button>
              )}
              <Button
                type="submit"
                variant="outline-secondary"
                hasShadow
                onClick={() => handleUpdateInfo('UpdateUserInformation')}
                isLoading={isSubmitting.updateUserInformation}
                disabled={isSubmitting.updateIdentityVerification || isSubmitting.continue}
              >
                Update my info
              </Button>
            </div>
          </InformationSummary>
        )}
      </div>
      {confirmedSections.length === 2 && (
        <div className="flex flex-col gap-6">
          <Button
            type="submit"
            variant="primary"
            hasShadow
            onClick={handleSubmit}
            isLoading={isSubmitting.continue}
            disabled={isSubmitting.updateIdentityVerification || isSubmitting.updateUserInformation}
          >
            Continue
          </Button>
        </div>
      )}
    </>
  );
}

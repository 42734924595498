import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useFriendlyURL = (replaceStr: string): void => {
  const router = useRouter();

  useEffect(() => {
    if (replaceStr && !replaceStr.includes('undefined') && router.asPath.includes('task')) {
      const friendlyURL = router.asPath.replace('task', 'app/' + replaceStr);
      window.history.replaceState(null, '', friendlyURL);
    } else {
      if (window.location.href.includes('/app/')) {
        const taskURL = '/task' + window.location.search;
        if (taskURL) {
          router.replace(taskURL);
        }
      }
    }
  }, [replaceStr, router]);
};

import { useState } from 'react';
import {
  LoanApplicationQueryResponse,
  useAdjustInterestRateMutation,
  useAppDispatch,
  useGetInterestRateAdjustmentsQuery,
  useLazyGetInterestRateAdjustmentsQuery,
} from '@harmoney/redux';
import { Form, useForm } from '@harmoney/ui-design-system';
import { Icon } from '@iconify/react';
import { LoanApplicationScorecardStatusEnum, ScorecardOperation } from '@prisma/client';
import bigDecimal from 'js-big-decimal';

import { setIsInterestRateOverrideOpen } from '../../../../redux/slice/admin';

import { DiscountedInterestRate } from './DiscountedInterestRate';
import { createFormSchema, defaultValues } from './form-config';

interface Props {
  loanApplicationData: LoanApplicationQueryResponse;
  scorecardData: ScorecardOperation[];
  status: LoanApplicationScorecardStatusEnum;
}

export const InterestRateOverrideForm = ({ loanApplicationData }: Props) => {
  const dispatch = useAppDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: interestRateAdjustments } = useGetInterestRateAdjustmentsQuery(loanApplicationData?.id, {
    skip: !loanApplicationData || !loanApplicationData?.customerInterestRate,
    refetchOnMountOrArgChange: true,
  });
  const [refetchInterestRateAdjustments] = useLazyGetInterestRateAdjustmentsQuery();
  const [adjustInterestRate] = useAdjustInterestRateMutation();

  const scorecardInterestRate = +loanApplicationData?.customerInterestRate;

  const form = useForm({
    mode: 'onTouched',
    schema: createFormSchema(interestRateAdjustments?.discountPercentageLimit),
    defaultValues,
  });
  const {
    trigger,
    watch,
    formState: { isValid },
  } = form;

  const handleApplyClick = async () => {
    const valid = await trigger();
    if (valid) setIsConfirmModalOpen(true);
  };

  const handleInterestRateChange = () => {
    form.resetField('customInterestRate');
  };

  const handleSubmit = async () => {
    if (!isValid) return;
    setIsSubmitting(true);
    await adjustInterestRate({
      applicationId: loanApplicationData.id,
      finalInterestRate: +watch().customInterestRate
        ? bigDecimal.round(watch().customInterestRate, 2)
        : bigDecimal.round(watch().adjustedInterestRate, 2),
    });
    await refetchInterestRateAdjustments(loanApplicationData.id);
    setIsConfirmModalOpen(false);
    setIsSubmitting(false);
    dispatch(setIsInterestRateOverrideOpen(false));
  };

  const handleCancel = () => {
    form.reset();
    dispatch(setIsInterestRateOverrideOpen(false));
  };

  if (!interestRateAdjustments) return null;

  return (
    <>
      <div className="sticky top-0 z-9 rounded-b-sm bg-white px-4 pb-2">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-baseline justify-end gap-4">
            <h2 className="text-primary">Apply interest rate discount</h2>
          </div>
          <Icon icon="ic:round-close" onClick={handleCancel} className="cursor-pointer" width={24} />
        </div>
      </div>
      <Form form={form} onSubmit={handleSubmit}>
        <DiscountedInterestRate
          {...{
            form: form,
            scorecardInterestRate,
            isConfirmModalOpen,
            isSubmitting,
            setIsConfirmModalOpen,
            onSubmit: handleSubmit,
            onInterestRateChange: handleInterestRateChange,
            onCancelClick: handleCancel,
            onApplyClick: handleApplyClick,
            minInterestRate: interestRateAdjustments?.discountPercentageLimit?.min,
          }}
        />
      </Form>
    </>
  );
};

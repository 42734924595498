import { loanVariationPurposeMapper, loanVariationTypeMapper } from '@harmoney/api-interfaces';
import { useLazyGetAppliedCsvByIdQuery } from '@harmoney/redux';
import { Button, IconV2, Spinner } from '@harmoney/ui-design-system';
import { DATE_FORMAT } from '@harmoney/ui-utils';
import { LoanVariation, LoanVariationTypeEnum } from '@prisma/client';
import dayjs from 'dayjs';

import { downloadCSV } from './utils';
import { VariationFormHeader } from './VariationFormHeader';

interface VariationDataProps {
  isVariationDataLoading?: boolean;
  variationData: LoanVariation;
  historyView?: boolean;
  fullName?: string;
}

export const VariationData = ({
  isVariationDataLoading = false,
  variationData,
  historyView = false,
  fullName,
}: VariationDataProps) => {
  const [trigger] = useLazyGetAppliedCsvByIdQuery();

  const handleDownloadCSV = async () => {
    const csvData = await trigger({
      loanApplicationId: variationData.loanApplicationId,
      simulationId: variationData.id,
    }).unwrap();
    const fileName = `${variationData.type}_${variationData.loanApplicationId}_${variationData.completedAt}`;
    if (csvData) {
      downloadCSV(csvData, fileName);
    }
  };
  if (isVariationDataLoading) {
    return <Spinner size="small" />;
  }
  return (
    <>
      {historyView && <VariationFormHeader historyView />}
      <div className="px-4">
        <div className="border-b-grey-2 border-b-[1px] pb-6">
          <span className="grid grid-cols-2 py-1">
            <span>Variation Type:</span>
            <span className="font-medium">{loanVariationTypeMapper[variationData?.type]}</span>
          </span>
          <span className="grid grid-cols-2 py-1">
            <span>Purpose:</span>
            <span className="font-medium">{loanVariationPurposeMapper[variationData?.purpose]}</span>
          </span>
          {variationData?.otherPurpose && (
            <span className="grid grid-cols-2">
              <span>Other purpose:</span>
              <span className="font-medium">{variationData?.otherPurpose}</span>
            </span>
          )}
        </div>
        <div className="border-b-grey-2 border-b-[1px] py-6">
          {variationData?.type === LoanVariationTypeEnum.REPAYMENT_HOLIDAY && (
            <>
              <p className="font-medium">Direct debit and monthly contractual schedule suspension</p>
              <span className="grid grid-cols-2 py-1">
                <span>Pause Date:</span>
                <span className="font-medium">{variationData?.effectiveDate}</span>
              </span>
            </>
          )}
          <span className="grid grid-cols-2 py-1">
            <span>
              {variationData?.type === LoanVariationTypeEnum.REPAYMENT_HOLIDAY
                ? 'Next contractual payment date (new):'
                : 'Contractual Payment Date:'}
            </span>
            <span className="font-medium">{variationData?.contractualPaymentDate}</span>
          </span>
          <span className="grid grid-cols-2 py-1">
            <span>
              {variationData?.type === LoanVariationTypeEnum.REPAYMENT_HOLIDAY
                ? 'Number of months for the loan term:'
                : 'Loan Term:'}
            </span>
            <span className="font-medium">{variationData?.loanTerm}</span>
          </span>
        </div>
        {historyView && (
          <>
            <div className="border-b-grey-2 border-b-[1px] py-6">
              <span className="grid grid-rows-2 py-1">
                <span>Notes:</span>
                <span className="font-medium">{variationData?.note}</span>
              </span>
            </div>
            <div className="py-6">
              <span className="grid grid-cols-2 py-1">
                <span>Committed on:</span>
                <span className="font-medium">{`${dayjs(variationData?.completedAt).format(DATE_FORMAT)} at ${dayjs(variationData?.completedAt).format('h:mm a')}`}</span>
              </span>
              <span className="grid grid-cols-2 py-1">
                <span>Committed by:</span>
                <span className="font-medium">{fullName}</span>
              </span>
              <span className="grid grid-cols-2 py-6">
                <Button
                  variant="text"
                  icon={<IconV2 icon="material-symbols:download-rounded" width={24} height={24} />}
                  alignIcon="start"
                  isFullWidth={true}
                  onClick={handleDownloadCSV}
                  className="font-body justify-start font-normal capitalize tracking-normal"
                >
                  Download amortisation schedule (CSV)
                </Button>
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

import { useMemo } from 'react';
import { RiskCheckResultEnum } from '@harmoney/api-interfaces';
import { CollapsibleCard, TextLink } from '@harmoney/ui-design-system';
import { LoanApplicationRiskCheckStatusEnum, RiskCheckOperation, RiskCheckTypeEnum } from '@prisma/client';
import dayjs from 'dayjs';

import { Field } from './shared';

const riskCheckTypeMapping = {
  [RiskCheckTypeEnum.GamblingRiskCheck]: 'Gambling as % Income',
  [RiskCheckTypeEnum.ATMWithdrawalRiskCheck]: 'ATM Withdrawals as % Income',
  [RiskCheckTypeEnum.SACCProvidersRiskCheck]: 'Number of SACC Providers',
  [RiskCheckTypeEnum.SACCLoansRiskCheck]: 'Number of SACC Loans',
  [RiskCheckTypeEnum.DishonoursRiskCheck]: 'Number of Dishonours',
  [RiskCheckTypeEnum.NonSACCProviderRiskCheck]: 'Number of non-SACC Providers (payday advance)',
};

interface Props {
  fraudCheckData: any;
  fraudCheckInstanceId: string;
  riskCheckOperationData: RiskCheckOperation[];
  riskCheckInstanceId: string;
  riskCheckStatus: LoanApplicationRiskCheckStatusEnum;
}

export const FraudRiskCheckDetails = ({
  fraudCheckData,
  fraudCheckInstanceId,
  riskCheckOperationData,
  riskCheckInstanceId,
  riskCheckStatus,
}: Props) => {
  const operateURL = process.env.NEXT_PUBLIC_CAMUNDA_OPERATE_ENDPOINT;

  const riskCheckResult = useMemo(() => {
    if (!riskCheckStatus) return null;
    return riskCheckStatus === LoanApplicationRiskCheckStatusEnum.Successful
      ? RiskCheckResultEnum.Successful
      : RiskCheckResultEnum.Unsuccessful;
  }, [riskCheckStatus]);

  const sortedRiskCheckOperationData = useMemo(() => {
    if (riskCheckOperationData && riskCheckOperationData.length > 0) {
      const sortedRiskCheckOperationData = [...riskCheckOperationData].sort((a, b) => {
        return dayjs(a?.createdAt).diff(dayjs(b?.createdAt));
      });

      const original = sortedRiskCheckOperationData.filter((riskCheck) => {
        return dayjs(riskCheck?.createdAt).isSame(dayjs(sortedRiskCheckOperationData?.[0]?.createdAt), 'minute');
      });

      const previous = sortedRiskCheckOperationData.filter((riskCheck) => {
        if (sortedRiskCheckOperationData.length < 2) return null;
        return dayjs(riskCheck?.createdAt).isSame(
          dayjs(sortedRiskCheckOperationData?.[sortedRiskCheckOperationData.length - 2]?.createdAt),
          'minute'
        );
      });

      const latest = sortedRiskCheckOperationData.filter((riskCheck) => {
        return dayjs(riskCheck?.createdAt).isSame(
          dayjs(sortedRiskCheckOperationData?.[sortedRiskCheckOperationData.length - 1]?.createdAt),
          'minute'
        );
      });

      if (dayjs(original?.[0]?.createdAt).isSame(dayjs(latest?.[0]?.createdAt), 'minute')) {
        return { latest };
      }

      return { original, previous, latest };
    }
  }, [riskCheckOperationData]);

  return (
    <CollapsibleCard title="Fraud & Risk" className="mb-6">
      <div className="grid grid-cols-2 px-4">
        <div className="odd:border-r-grey-1 p-4 odd:border-r-2 space-y-2">
          <span className="text-primary font-bold">Fraud</span>
          {fraudCheckData && (
            <>
              <Field>
                <span>Bank Account Holder List:</span>
                <span>{fraudCheckData?.bankAccountHolders?.toString()}</span>
              </Field>
              <Field>
                <span>Full Name:</span>
                <span>
                  {fraudCheckData?.userName?.toString() ??
                    fraudCheckData?.userNameAndReversedName?.userName?.toString()}
                </span>
              </Field>
              <Field>
                <span>Fraud Check Risk:</span>
                <span>{fraudCheckData?.fraudCheckRisk?.toString()}</span>
              </Field>
              <Field>
                <span>Fraud Check Result:</span>
                <span>
                  {fraudCheckData?.bankAccountHolders?.toString().includes('Unavailable')
                    ? `${fraudCheckData?.fraudCheckResult?.toString()} - account holder not available - manual check required`
                    : fraudCheckData?.fraudCheckResult?.toString()}
                </span>
              </Field>
            </>
          )}
          <Field>
            <span>Link to Camunda Operate:</span>
            {fraudCheckInstanceId ? (
              <TextLink
                target="_blank"
                rel="noopener noreferrer"
                href={`${operateURL}/processes/${fraudCheckInstanceId}`}
              >
                Fraud Check Details
              </TextLink>
            ) : (
              'N/A'
            )}
          </Field>
        </div>
        <div className="p-4 space-y-2">
          <span className="text-primary font-bold">Risk</span>
          {riskCheckResult !== null && riskCheckOperationData && (
            <>
              <Field>
                <span>Risk Check result:</span>
                <span className={riskCheckResult !== RiskCheckResultEnum.Successful ? 'text-error' : 'text-success'}>
                  {riskCheckResult}
                </span>
              </Field>
              {sortedRiskCheckOperationData?.latest?.map((riskCheck) => (
                <Field key={riskCheck.id}>
                  <span>{riskCheckTypeMapping[riskCheck.riskCheckType] || riskCheck.riskCheckType}</span>
                  <div className="flex flex-col">
                    <span>{riskCheck?.reason ?? 'N/A'}</span>
                    {sortedRiskCheckOperationData?.previous && (
                      <span className="text-sm text-grey-3">
                        Previous value:{' '}
                        {
                          sortedRiskCheckOperationData.previous.find(
                            ({ riskCheckType }) => riskCheckType === riskCheck.riskCheckType
                          ).reason
                        }
                      </span>
                    )}
                  </div>
                </Field>
              ))}
            </>
          )}
          <Field className="pt-2">
            <span>Link to Camunda Operate:</span>
            <span>
              {riskCheckInstanceId ? (
                <TextLink
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${operateURL}/processes/${riskCheckInstanceId}`}
                >
                  Risk Check Details
                </TextLink>
              ) : (
                'N/A'
              )}
            </span>
          </Field>
        </div>
      </div>
    </CollapsibleCard>
  );
};

import { UserInbox } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const userInboxApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    readUserInbox: builder.mutation<{ success: boolean }, string>({
      query: (id) => {
        return {
          url: `/user-inbox/${id}`,
          method: 'DELETE',
        };
      },
    }),
    getLatestUserInbox: builder.query<UserInbox, void>({
      query: () => ({
        url: `/user-inbox/latest-unread`,
        method: 'GET',
      }),
    }),
    getAllUserInboxes: builder.query<UserInbox[], void>({
      query: () => ({
        url: `/user-inbox/unread`,
        method: 'GET',
      }),
      providesTags: ['Application'],
    }),
  }),
});

export const { useReadUserInboxMutation, useGetLatestUserInboxQuery, useGetAllUserInboxesQuery } = userInboxApi;

import { useMemo } from 'react';
import { EmploymentType } from '@harmoney/api-interfaces';
import { AmountFrequency, Button, ControlledInput, Select, ToggleGroup } from '@harmoney/ui-design-system';
import {
  benefitTypeOptions,
  employmentCodeOptions,
  employmentTypeOptions,
  frequencyOptions,
  frequencyOptionsWithOneOffPayment,
  frequencyOptionsWithYear,
  monthOptions,
  selfEmploymentOptions,
  toggleYesNoOptions,
  workingMonthsOptions,
  yearsOptions,
} from '@harmoney/ui-utils';

export const IncomeItem = ({ code, index, formData, reference, ...props }) => {
  const controls = useMemo(() => {
    const typeControl = ['salary'];
    const amountControl = ['salary', 'self_employed', 'other_income_sources', 'interest_dividend'];
    const spousalSupportControl = ['spousal_support'];
    const incomeNotListedNameControl = ['other_income_sources'];
    const pensionControl = ['pension'];
    const benefitAmountControl = ['benefit'];
    const benefitNameControl = ['benefit'];
    const seasonalControl = ['salary'];
    const benefitSelectControl = ['benefit'];
    const rentalIncomeShared = ['rental_income'];
    const rentalAmountControl = ['rental_income'];
    const superannuationAmountControl = ['superannuation'];
    const studentAmountControl = ['student_payments'];
    const selfEmploymentSelectControl = ['self_employed'];

    const controls = [];

    if (typeControl.includes(code)) {
      controls.push(
        <div key={`typeControl_${code}_${index}`}>
          <Select
            key={`typeControl_${code}_${index}_employmentCode`}
            label="What do you do for work?"
            reference={reference}
            {...props.register(`incomes.${code}[${index}].employmentCode`)}
            options={employmentCodeOptions}
            className="mb-4"
          />
          <Select
            key={`typeControl_${code}_${index}_employmentType`}
            label="What is your work arrangement?"
            {...props.register(`incomes.${code}[${index}].employmentType`)}
            options={employmentTypeOptions}
            className="mb-4"
          />
          <label>When did you start working there?</label>
          <div key={`typeControl_${code}_${index}_startEmploymentMonth`} className="mt-2 flex justify-between gap-x-2">
            <Select
              {...props.register(`incomes.${code}[${index}].startEmploymentMonth`)}
              options={monthOptions}
              className="mb-4 w-1/2"
            />
            <Select
              {...props.register(`incomes.${code}[${index}].startEmploymentYear`)}
              options={yearsOptions(40)}
              className="mb-4 w-1/2"
            />
          </div>
        </div>
      );
    }

    if (benefitSelectControl.includes(code)) {
      controls.push(
        <Select
          key={`benefitSelectControl_${code}_${index}`}
          label="What type of benefit do you get?"
          {...props.register(`incomes.${code}[${index}].benefitType`)}
          options={benefitTypeOptions}
          className="mb-4"
        />
      );
    }

    if (selfEmploymentSelectControl.includes(code)) {
      controls.push(
        <Select
          key={`selfEmploymentSelectControl_${code}_${index}`}
          label="What type of self employment is it?"
          {...props.register(`incomes.${code}[${index}].selfEmploymentType`)}
          options={selfEmploymentOptions}
          className="mb-4"
        />
      );
    }

    if (benefitNameControl.includes(code)) {
      const benefitType = formData[code][index]['benefitType'];
      {
        benefitType === 'Other' &&
          controls.push(
            <ControlledInput
              {...props.register(`incomes.${code}[${index}].benefitName`)}
              key={`benefitNameControl_${code}_${index}`}
              type="text"
              label="What is the benefit you receive?"
              className="mb-4"
            />
          );
      }
    }

    if (seasonalControl.includes(code)) {
      const employmentType = formData[code][index]['employmentType'];
      {
        employmentType === EmploymentType.SEASONAL &&
          controls.push(
            <Select
              key={`seasonalControl_${code}_${index}`}
              label="How many months in a year do you work?"
              {...props.register(`incomes.${code}[${index}].seasonalWorkingMonths`)}
              options={workingMonthsOptions}
              className="mb-4"
            />
          );
      }
    }

    if (rentalIncomeShared.includes(code)) {
      controls.push(
        <ToggleGroup
          key={`rentalIncomeShared_${code}_${index}`}
          {...props.register(`incomes.${code}[${index}].isRentalIncomeShared`)}
          label="Is your rental income shared with anyone?"
          options={toggleYesNoOptions}
          className="mb-4"
        />
      );
    }

    if (incomeNotListedNameControl.includes(code)) {
      controls.push(
        <ControlledInput
          {...props.register(`incomes.${code}[${index}].otherIncomeType`)}
          key={`incomeNotListedNameControl_${code}_${index}`}
          type="text"
          label="What kind of income do you get?"
          className="mb-4"
        />
      );
    }

    if (amountControl.includes(code)) {
      controls.push(
        <AmountFrequency
          key={`amountControl_${code}_${index}`}
          register={props.register}
          label="How much do you receive (after tax and deductions)?"
          inputKey={`incomes.${code}[${index}].declaredAmount`}
          selectKey={`incomes.${code}[${index}].frequency`}
          name="income"
          options={frequencyOptionsWithYear}
          className="mb-4"
        />
      );
    }

    if (rentalAmountControl.includes(code)) {
      controls.push(
        <AmountFrequency
          key={`rentalAmountControl${code}_${index}`}
          register={props.register}
          label="How much do you receive (after tax and deductions)?"
          inputKey={`incomes.${code}[${index}].declaredAmount`}
          selectKey={`incomes.${code}[${index}].frequency`}
          name="income"
          options={frequencyOptions}
          className="mb-4"
        />
      );
    }

    if (spousalSupportControl.includes(code)) {
      controls.push(
        <AmountFrequency
          key={`spousalSupportControl_${code}_${index}`}
          register={props.register}
          label="How much do you receive (after tax and deductions)?"
          inputKey={`incomes.${code}[${index}].declaredAmount`}
          selectKey={`incomes.${code}[${index}].frequency`}
          name="income"
          options={frequencyOptionsWithOneOffPayment}
          className="mb-4"
        />
      );
    }

    if (pensionControl.includes(code)) {
      controls.push(
        <AmountFrequency
          key={`pensionControl_${code}_${index}`}
          register={props.register}
          label="How much do you receive (after tax and deductions)?"
          inputKey={`incomes.${code}[${index}].declaredAmount`}
          selectKey={`incomes.${code}[${index}].frequency`}
          name="income"
          options={frequencyOptions}
          className="mb-4"
        />
      );
    }

    if (benefitAmountControl.includes(code)) {
      controls.push(
        <AmountFrequency
          key={`benefitAmountControl_${code}_${index}`}
          register={props.register}
          label="How much do you receive (after tax and deductions)?"
          inputKey={`incomes.${code}[${index}].declaredAmount`}
          selectKey={`incomes.${code}[${index}].frequency`}
          name="income"
          options={frequencyOptionsWithOneOffPayment}
          className="mb-4"
        />
      );
    }

    if (studentAmountControl.includes(code)) {
      controls.push(
        <AmountFrequency
          key={`studentAmountControl_${code}_${index}`}
          register={props.register}
          label="How much do you receive (after tax and deductions)?"
          inputKey={`incomes.${code}[${index}].declaredAmount`}
          selectKey={`incomes.${code}[${index}].frequency`}
          name="income"
          options={frequencyOptionsWithYear}
          className="mb-4"
        />
      );
    }

    if (superannuationAmountControl.includes(code)) {
      controls.push(
        <AmountFrequency
          key={`superannuationAmountControl_${code}_${index}`}
          register={props.register}
          label="How much do you receive (after tax and deductions)?"
          inputKey={`incomes.${code}[${index}].declaredAmount`}
          selectKey={`incomes.${code}[${index}].frequency`}
          name="income"
          options={frequencyOptionsWithYear}
          className="mb-4"
        />
      );
    }

    if (index !== 0) {
      controls.push(
        <span className="w-100 mx-4 mb-3 flex justify-end">
          <Button
            size="small"
            variant="text"
            onClick={() => props.removeItem(code, index)}
            className="inline-flex items-center"
          >
            Delete
          </Button>
        </span>
      );
    }

    return controls;
  }, [code, index, reference, props, formData]);

  return controls.length > 0 ? (
    <div className="border-grey-3 border-1 mx-4 mb-3 rounded-lg	border border-inherit p-6 pb-0">{controls}</div>
  ) : null;
};

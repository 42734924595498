import { Card } from '@harmoney/ui-design-system';
import { booleanToYesNo, valueOrNA } from '@harmoney/ui-utils';

import { Field } from '../../shared';

import { Expense } from './ExpenseDetails';

export const SharedLivingExpense = ({ expense }: { expense: Expense }) => (
  <Card className={'shadow-none border border-grey-2 h-fit p-4 w-[calc(50%-4px)]'}>
    <Field>
      <span>Expense shared:</span>
      <span>{booleanToYesNo(expense?.isExpenseShared)}</span>
    </Field>
    <Field>
      <span>Expense shared with:</span>
      <span>{valueOrNA(expense?.expenseSharedWith)}</span>
    </Field>
  </Card>
);

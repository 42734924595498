import { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { CheckboxProps as RadixCheckboxProps } from '@radix-ui/react-checkbox';
import * as RadixCheckbox from '@radix-ui/react-checkbox';
import classNames from 'classnames';

import { CheckSquareIcon, IconSize } from '../Icon';
import { Label } from '../Label';
import { ValidationMessage } from '../ValidationMessage';

import { getCheckboxSize } from './UncontrolledCheckbox';

import styles from './Checkbox.module.scss';

export interface CheckboxProps extends Omit<RadixCheckboxProps, 'asChild'> {
  /**
   * The name of the checkbox. Submitted with its owning form as part of a name/value pair.
   */
  name: string;
  /**
   * The value given as data when submitted with a name.
   */
  value?: string;
  /**
   * The label of the checkbox
   */
  label?: React.ReactElement | string;
  /**
   * The position of label
   */
  alignLabel?: 'left' | 'right';
  displayValidationMessage?: boolean;
  /**
   * The size of the icon
   */
  checkIconSize?: IconSize;
}

export const Checkbox = forwardRef<HTMLDivElement, CheckboxProps>(
  (
    {
      className,
      name,
      disabled,
      label,
      onCheckedChange,
      checkIconSize = 'medium',
      alignLabel = 'right',
      displayValidationMessage = true,
      ...restProps
    }: CheckboxProps,
    forwardRef: React.Ref<HTMLDivElement>
  ) => {
    const { control } = useFormContext();

    return (
      <div className={className} ref={forwardRef}>
        <div className="flex items-center gap-x-4">
          {label && alignLabel === 'left' && (
            <Label className={classNames(styles['label'])} htmlFor={name} data-disabled={disabled ? '' : undefined}>
              {label}
            </Label>
          )}
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <RadixCheckbox.Root
                defaultChecked={value}
                onCheckedChange={onChange}
                onBlur={onBlur}
                value={value}
                id={name}
                data-testid={name}
                ref={ref}
                disabled={disabled}
                className={classNames(styles['checkbox'], getCheckboxSize(checkIconSize))}
                {...restProps}
              >
                <CheckSquareIcon size={checkIconSize} />
              </RadixCheckbox.Root>
            )}
          />
          {label && alignLabel === 'right' && (
            <Label className={classNames(styles['label'])} htmlFor={name} data-disabled={disabled ? '' : undefined}>
              {label}
            </Label>
          )}
        </div>
        {displayValidationMessage && <ValidationMessage name={name} />}
      </div>
    );
  }
);

import { AuditDecisionDto } from '@harmoney/api-interfaces';
import { AuditDecision, AuditDecisionOption, AuditDecisionType, User } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const auditDecisionApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAvailableAuditDecisionTypes: builder.query<
      (AuditDecisionType & { auditDecisionOptions: AuditDecisionOption[] })[],
      {
        userId: string;
        applicationId: string;
      }
    >({
      query: ({ userId, applicationId }) => {
        return {
          url: '/audit-decision-types',
          method: 'GET',
          params: {
            userId,
            applicationId,
          },
        };
      },
      providesTags: ['AuditDecision'],
    }),
    getAuditDecisionsByLoanApplicationId: builder.query<
      (AuditDecision & {
        auditDecisionType: AuditDecisionType;
        auditDecisionOption: AuditDecisionOption;
        user: User;
      })[],
      string
    >({
      query: (loanApplicationId) => {
        return {
          url: '/audit-decision-types/audit-decisions',
          method: 'GET',
          params: { loanApplicationId },
        };
      },
      providesTags: ['AuditDecision'],
    }),
    saveAuditDecision: builder.mutation<AuditDecision, AuditDecisionDto>({
      query: (auditDecision) => {
        return {
          url: '/audit-decision-types/audit-decisions',
          method: 'POST',
          body: auditDecision,
        };
      },
      invalidatesTags: ['AuditDecision'],
    }),
  }),
});

export const {
  useGetAvailableAuditDecisionTypesQuery,
  useGetAuditDecisionsByLoanApplicationIdQuery,
  useSaveAuditDecisionMutation,
} = auditDecisionApi;

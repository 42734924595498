import React from 'react';
import { coAuditTagsMapper } from '@harmoney/api-interfaces';
import { CollapsibleCard, Divider } from '@harmoney/ui-design-system';
import { booleanToYesNo, CreditAuditQuestions, CreditAuditSections, DATE_TIME_FORMAT } from '@harmoney/ui-utils';
import { CreditAudit } from '@prisma/client';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

import { Field } from './Field';
import { Section } from './Section';
import { TagDisplay } from './TagDisplay';

type Props = {
  creditAuditData: CreditAudit;
};

export const CreditAuditDetails = ({ creditAuditData }: Props) => {
  const {
    creditOfficerName,
    createdAt,
    requirementsAndObjectives,
    requirementsAndObjectivesTags,
    eligibilityIdentityAndFraudMitigation,
    eligibilityIdentityAndFraudMitigationTags,
    creditRisk,
    creditRiskTags,
    affordability,
    affordabilityTags,
    responsibleLending,
    responsibleLendingTags,
    additionalEnquiries,
    additionalEnquiriesTags,
    additionalEnquiriesNotes,
    coAuditDecision,
    decisionBasis,
    mightHaveApprovedIf,
    wouldHaveApprovedFor,
    maxOffer,
    newTagsSuggestions,
    tagsToBeRemoved,
    notes,
    recommendationsDeprecated,
    systemPerformedCorrectlyDeprecated,
    tagsDeprecated,
  } = creditAuditData;

  // const userId = useAppSelector((state) => state.userId.value);

  // const isAuthor = userId === creditAuditData.creditOfficerId;

  // if (!isAuthor) return null;

  return (
    <CollapsibleCard
      title="Credit Audit Details"
      defaultOpen={false}
      className="relative mb-6"
      description={`Submitted by ${creditOfficerName} on ${dayjs(createdAt).format(DATE_TIME_FORMAT)}`}
    >
      <>
        <div className="grid grid-cols-2 px-4">
          <div className="border-r-grey-1 flex flex-col gap-4 border-r-2 p-4">
            <Section title={CreditAuditSections.requirementsAndObjectives}>
              <Field
                label={`${CreditAuditQuestions.requirementsAndObjectivesMet}:`}
                value={requirementsAndObjectives}
                format="title-case"
              />
              <TagDisplay tags={requirementsAndObjectivesTags} />
            </Section>
            <Section title={CreditAuditSections.eligibilityIdentityAndFraudMitigation}>
              <Field
                label={CreditAuditQuestions.eligibilityMet}
                value={eligibilityIdentityAndFraudMitigation}
                format="title-case"
              />
              <TagDisplay tags={eligibilityIdentityAndFraudMitigationTags} />
            </Section>
            <Section title={CreditAuditSections.creditRisk}>
              <Field label={CreditAuditQuestions.happyToLend} value={creditRisk} format="title-case" />
              <TagDisplay tags={creditRiskTags} />
            </Section>
            <Section title={CreditAuditSections.affordability}>
              <Field label={CreditAuditQuestions.affordable} value={affordability} format="title-case" />
              <TagDisplay tags={affordabilityTags} />
            </Section>
            <Section title={CreditAuditSections.responsibleLending}>
              <Field
                label={CreditAuditQuestions.responsibleLendingMet}
                value={responsibleLending}
                format="title-case"
              />
              <TagDisplay tags={responsibleLendingTags} />
            </Section>
          </div>
          <div className="flex flex-col gap-2 p-4">
            <Section title={CreditAuditSections.additionalEnquiries}>
              <Field
                label={CreditAuditQuestions.additionalEnquiriesToggle}
                value={booleanToYesNo(additionalEnquiries)}
                format="title-case"
              />
              <TagDisplay tags={additionalEnquiriesTags} />
              <span>{CreditAuditQuestions.additionalEnquiriesNotes}</span>
              <span className="bg-grey-1  border-grey-1 whitespace-pre-wrap rounded-lg border py-1 px-2">
                {additionalEnquiriesNotes}
              </span>
            </Section>
            <Divider className="my-4" />
            <h3 className="mb-2 font-semibold">{CreditAuditSections.cORecommendation}</h3>
            <Field label={CreditAuditQuestions.decision} value={coAuditDecision} />
            <Field label={CreditAuditQuestions.decisionBasis} value={decisionBasis.join(', ')} />
            {!isEmpty(mightHaveApprovedIf) && (
              <Field label={CreditAuditQuestions.mightHaveApprovedIf} value={mightHaveApprovedIf.join(', ')} />
            )}
            <Field label={CreditAuditQuestions.wouldHaveApprovedFor} value={wouldHaveApprovedFor} format="title-case" />
            <Field label={CreditAuditQuestions.maxOffer} value={maxOffer} format="amount" />
            <span className="grid grid-cols-2">
              <span>{CreditAuditQuestions.newTagsSuggestions}</span>
              <span className="bg-grey-1  border-grey-1 whitespace-pre-wrap rounded-lg border py-1 px-2">
                {newTagsSuggestions}
              </span>
            </span>
            <TagDisplay tags={tagsToBeRemoved} label={CreditAuditQuestions.tagsToBeRemoved} />
            <span className="grid grid-cols-2">
              <span>{CreditAuditQuestions.notes}</span>
              <span className="bg-grey-1  border-grey-1 whitespace-pre-wrap rounded-lg border py-1 px-2">{notes}</span>
            </span>
            {!isEmpty(recommendationsDeprecated) && (
              <span className="grid grid-cols-2">
                <span>Recommendations (deprecated)</span>
                <span className="bg-grey-1  border-grey-1 whitespace-pre-wrap rounded-lg border py-1 px-2">
                  {recommendationsDeprecated}
                </span>
              </span>
            )}
            {!isEmpty(systemPerformedCorrectlyDeprecated) && (
              <Field
                label="Did the system performed correctly? (deprecated)"
                value={booleanToYesNo(systemPerformedCorrectlyDeprecated)}
                format="title-case"
              />
            )}
            {!isEmpty(tagsDeprecated) && (
              <TagDisplay tags={tagsDeprecated.map((tag) => coAuditTagsMapper[tag])} label="Tags (deprecated)" />
            )}
          </div>
        </div>
      </>
    </CollapsibleCard>
  );
};

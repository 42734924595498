import { useMemo } from 'react';
import { DebtConsolidationObjectDto } from '@harmoney/api-interfaces';
import { NumberFormatterNoDecimal } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import { DebtConsolidationBenefitEnum } from '@prisma/client';

import { HARMONEY_CUSTOMER_COUNT } from '../components/debt-consolidation/ConsolidateDebt/components';

export const useDebtConBenefits = (processResults: DebtConsolidationObjectDto) => {
  return useMemo(() => {
    return [
      {
        id: DebtConsolidationBenefitEnum.COMBINE,
        icon: 'hmy:debt-consolidation',
        children: (
          <div>
            You&rsquo;ll turn{' '}
            <span className="text-sm font-semibold">{processResults?.eligibleDebtsCombinationCount}</span> payments into
            one!
          </div>
        ),
        displayOrder: 0,
        display: processResults?.eligibleDebtsCombinationCount > 1,
      },
      {
        id: DebtConsolidationBenefitEnum.LOWER_INTEREST,
        icon: 'ic:outline-show-chart',
        iconClassName: 'transform scale-x-[-1]',
        children: (
          <div>
            You&rsquo;ll lower your rate by{' '}
            <span className="text-sm font-semibold">{processResults?.lowerInterestRate}%</span> (p.a.)
          </div>
        ),
        displayOrder: 1,
        display: processResults?.lowerInterestRate > 0,
      },
      {
        id: DebtConsolidationBenefitEnum.PAY_LESS,
        icon: 'ic:outline-savings',
        children: (
          <div>
            You&rsquo;ll save{' '}
            <span className="text-sm font-semibold">{formatCurrency(processResults?.payLessAmount)}</span> a month!
          </div>
        ),
        displayOrder: 2,
        display: processResults?.payLessAmount > 0,
      },
      {
        id: DebtConsolidationBenefitEnum.CHANGE_PROVIDER,
        icon: 'ic:outline-tag-faces',
        children: (
          <div>
            You&rsquo;ll join over{' '}
            <span className="text-sm font-semibold">{NumberFormatterNoDecimal(HARMONEY_CUSTOMER_COUNT)}</span> of our
            customers
          </div>
        ),
        displayOrder: 3,
        display: true,
      },
      {
        id: DebtConsolidationBenefitEnum.NO_FEES,
        icon: 'ic:outline-search',
        children: (
          <div>
            You&rsquo;ll pay <span className="text-sm font-semibold">$0</span> in early & ongoing fees
          </div>
        ),
        displayOrder: 4,
        display: true,
      },
    ];
  }, [processResults]);
};

export type DebtConBenefits = ReturnType<typeof useDebtConBenefits>;

import React, { useEffect, useMemo } from 'react';
import { PromptName } from '@harmoney/api-interfaces';
import {
  useGetAIResultQuery,
  useLazyAnalyseBankStatementsQuery,
  useLazyGetNetIncomeByGrossQuery,
} from '@harmoney/redux';
import { Button, Spinner, Tooltip } from '@harmoney/ui-design-system';
import { formatNumber } from '@harmoney/utilities';
import { Icon } from '@iconify/react';
import { Prisma } from '@prisma/client';

import { AIFeedback } from '../../AIFeedback';

type Props = {
  affordabilityData: Prisma.JsonValue;
  rootData: Prisma.JsonValue;
};

export type RowData = {
  title: string;
  final: string;
  declared?: string;
  bankStatement?: string;
  ai?: string;
  override?: string;
};

export const IncomeTable = ({ affordabilityData, rootData }: Props) => {
  const declaredPartnerMonthlyIncome = affordabilityData?.['income']?.['declaredPartnerIncome'] as number;
  const [getPartnersIncome, { data: calculatedPartnersMonthlyIncomeData }] = useLazyGetNetIncomeByGrossQuery();
  const [analyseBankStatement, { isFetching: isAnalyseFetching }] = useLazyAnalyseBankStatementsQuery();
  const {
    data: aiResult,
    refetch,
    isLoading: isAIResultLoading,
    isFetching: isAIResultFetching,
  } = useGetAIResultQuery(
    { promptId: PromptName.BANK_STATEMENTS_INCOME_SUMMARY, entityId: rootData?.['loanApplicationId'] },
    { skip: !rootData?.['loanApplicationId'] || rootData?.['aggregationResult']?.status !== 'success' }
  );

  const getDeclaredIncome = (key) =>
    !isNaN(affordabilityData?.['income']?.[key]) ? affordabilityData?.['income']?.[key] : null;
  const getFinalIncome = (key) =>
    !isNaN(affordabilityData?.['finalIncome']?.[key]) ? affordabilityData?.['finalIncome']?.[key] : null;
  const getOverrideIncome = (key) =>
    !isNaN(affordabilityData?.['income']?.['coOverrideIncome']?.[key])
      ? affordabilityData?.['income']?.['coOverrideIncome']?.[key]
      : null;

  useEffect(() => {
    if (
      !affordabilityData?.['netAnnualHouseholdIncome'] &&
      affordabilityData?.['income']?.['isNetPartnerIncomeDeclared'] === false
    ) {
      getPartnersIncome(declaredPartnerMonthlyIncome);
    }
  }, [affordabilityData, declaredPartnerMonthlyIncome]);

  const getNetHouseholdIncome = () => {
    let calculatedNetHouseholdIncome: number | null = 0;

    if (
      !affordabilityData?.['netAnnualHouseholdIncome'] &&
      affordabilityData?.['income']?.['isNetPartnerIncomeDeclared'] === false &&
      calculatedPartnersMonthlyIncomeData
    ) {
      calculatedNetHouseholdIncome =
        (affordabilityData?.['income']?.['declaredBorrowerIncome'] as number) + calculatedPartnersMonthlyIncomeData;
    } else if (
      !affordabilityData?.['netAnnualHouseholdIncome'] &&
      affordabilityData?.['income']?.['isNetPartnerIncomeDeclared'] === true
    ) {
      calculatedNetHouseholdIncome =
        (affordabilityData?.['income']?.['declaredBorrowerIncome'] as number) +
        (affordabilityData?.['income']?.['declaredPartnerIncome'] as number);
    } else {
      calculatedNetHouseholdIncome = affordabilityData?.['income']?.['declaredHouseholdIncome'] as number;
    }

    return calculatedNetHouseholdIncome;
  };

  const handleAnalyseBankStatement = async () => {
    const analyseResult = await analyseBankStatement(rootData?.['loanApplicationId']);
    if (analyseResult.data) {
      await refetch();
    }
  };

  const rowsData: RowData[] = useMemo(() => {
    const netHouseholdIncome = Math.round(getNetHouseholdIncome());
    return [
      {
        title: 'Household Income',
        declared: netHouseholdIncome !== null ? formatNumber(netHouseholdIncome, 2) : '',
        bankStatement: null,
        ai: null,
        override: getOverrideIncome('coOverrideHouseholdIncome')
          ? formatNumber(getOverrideIncome('coOverrideHouseholdIncome'), 2)
          : '',
        final: getOverrideIncome('coOverrideHouseholdIncome')
          ? formatNumber(getOverrideIncome('coOverrideHouseholdIncome'), 2)
          : netHouseholdIncome !== null
            ? formatNumber(netHouseholdIncome, 2)
            : '',
      },
      {
        title: 'Salary/Wages',
        declared: getDeclaredIncome('declaredPrimaryIncome')
          ? formatNumber(getDeclaredIncome('declaredPrimaryIncome'), 2)
          : getDeclaredIncome('declaredSalaryWagesIncome')
            ? formatNumber(getDeclaredIncome('declaredSalaryWagesIncome'), 2)
            : '',
        bankStatement: formatNumber(affordabilityData?.['decisionMetrics']?.['DM001'] as number, 2),
        ai: aiResult && formatNumber(aiResult?.result?.['wages']),
        override: getOverrideIncome('coOverridePrimaryIncome')
          ? formatNumber(getOverrideIncome('coOverridePrimaryIncome'), 2)
          : getOverrideIncome('coOverrideSalaryWagesIncome')
            ? formatNumber(getOverrideIncome('coOverrideSalaryWagesIncome'), 2)
            : '',
        final: getFinalIncome('primaryIncome')
          ? formatNumber(getFinalIncome('primaryIncome'), 2)
          : getFinalIncome('salaryWagesIncome')
            ? formatNumber(getFinalIncome('salaryWagesIncome'), 2)
            : '',
      },
      {
        title: 'Self Employed Income',
        declared: getDeclaredIncome('declaredSelfEmployedIncome')
          ? formatNumber(getDeclaredIncome('declaredSelfEmployedIncome'), 2)
          : '',
        bankStatement: null,
        ai: aiResult && formatNumber(aiResult?.result?.['selfEmployedIncome']),
        override: getDeclaredIncome('calculatedSelfEmployedIncome')
          ? formatNumber(getDeclaredIncome('calculatedSelfEmployedIncome'), 2)
          : '',
        final: getFinalIncome('selfEmployedIncome') ? formatNumber(getFinalIncome('selfEmployedIncome'), 2) : '',
      },
      {
        title: 'Rental Income',
        declared: formatNumber(affordabilityData?.['income']?.['declaredRentalIncome'] as number, 2),
        bankStatement: formatNumber(affordabilityData?.['decisionMetrics']?.['DM115'] as number, 2),
        ai: aiResult && formatNumber(aiResult?.result?.['rentalIncome']),
        override: getOverrideIncome('coOverrideRentalIncome')
          ? formatNumber(getOverrideIncome('coOverrideRentalIncome'), 2)
          : '',
        final: formatNumber(affordabilityData?.['finalIncome']?.['rentalIncome'] as number, 2),
      },
      {
        title: 'Child Support',
        declared: formatNumber(affordabilityData?.['income']?.['declaredChildSupportIncome'] as number, 2),
        bankStatement: formatNumber(affordabilityData?.['decisionMetrics']?.['DM103'] as number, 2),
        ai: aiResult && formatNumber(aiResult?.result?.['childSupport']),
        override: getOverrideIncome('coOverrideChildSupportIncome')
          ? formatNumber(getOverrideIncome('coOverrideChildSupportIncome'), 2)
          : '',
        final: formatNumber((affordabilityData?.['finalIncome']?.['childSupportIncome'] ?? 0) as number, 2),
      },
      {
        title: 'Benefit',
        declared: formatNumber(affordabilityData?.['income']?.['declaredBenefit'] as number, 2),
        bankStatement: formatNumber(affordabilityData?.['decisionMetrics']?.['DM038'] as number, 2),
        ai: aiResult && formatNumber(aiResult?.result?.['benefit']),
        override: getOverrideIncome('coOverrideBenefit') ? formatNumber(getOverrideIncome('coOverrideBenefit'), 2) : '',
        final: formatNumber(affordabilityData?.['finalIncome']?.['benefit'] as number, 2),
      },
      {
        title: 'Pension',
        declared: formatNumber(affordabilityData?.['income']?.['declaredPension'] as number, 2),
        bankStatement: formatNumber(affordabilityData?.['decisionMetrics']?.['TF011'] as number, 2),
        ai: aiResult && formatNumber(aiResult?.result?.['pension']),
        override: getOverrideIncome('coOverridePension') ? formatNumber(getOverrideIncome('coOverridePension'), 2) : '',
        final: formatNumber(affordabilityData?.['finalIncome']?.['pension'] as number, 2),
      },
      {
        title: 'Interest/Dividends',
        declared: getDeclaredIncome('declaredInterestDividend')
          ? formatNumber(getDeclaredIncome('declaredInterestDividend'), 2)
          : '',
        bankStatement: null,
        ai: null,
        override: getOverrideIncome('coOverrideInterestDividend')
          ? formatNumber(getOverrideIncome('coOverrideInterestDividend'), 2)
          : '',
        final: getFinalIncome('interestDividend') ? formatNumber(getFinalIncome('interestDividend'), 2) : '',
      },
      {
        title: 'Other Income',
        declared: getDeclaredIncome('declaredOtherIncome')
          ? formatNumber(getDeclaredIncome('declaredOtherIncome'), 2)
          : '',
        bankStatement: formatNumber(affordabilityData?.['decisionMetrics']?.['DM127'] as number, 2),
        ai: aiResult && formatNumber(aiResult?.result?.['otherIncome']),
        override: getOverrideIncome('coOverrideOtherIncome')
          ? formatNumber(getOverrideIncome('coOverrideOtherIncome'), 2)
          : '',
        final: getFinalIncome('otherIncome') ? formatNumber(getFinalIncome('otherIncome'), 2) : '',
      },
      {
        title: 'Income Decreases',
        declared: formatNumber(affordabilityData?.['income']?.['declaredDecreasedIncome'] as number, 2),
        bankStatement: null,
        ai: null,
        final: formatNumber(affordabilityData?.['income']?.['declaredDecreasedIncome'] as number, 2),
      },
    ];
  }, [affordabilityData, calculatedPartnersMonthlyIncomeData, aiResult]);

  return (
    <>
      <table className="border-grey-2 table-fixed border w-full">
        <thead>
          <tr className="divide-grey-2 bg-grey-1 divide-x">
            <th className="w-1/6">Income (Net) </th>
            <th className="w-1/6">Declared</th>
            <th className="w-1/6">Bank Statement</th>
            <th className="w-1/6">
              <div className="flex flex-row gap-2 items-center justify-center">
                <Icon icon="mdi:stars" className="text-secondary" width={16} />
                <span>AI Analyse</span>
                {aiResult?.prompt && (
                  <Tooltip>
                    Prompt:
                    <br />
                    <span className="text-left">{aiResult?.prompt.replace(/\.(\s|$)/g, '. \n$1')}</span>
                  </Tooltip>
                )}
              </div>
            </th>
            <th className="w-1/6">Co-Override</th>
            <th className="w-1/6">Final</th>
          </tr>
        </thead>
        <tbody>
          {rowsData.map((row, index) => (
            <tr key={row.title} className="divide-grey-2 border-grey-2 divide-x border">
              <td className="bg-grey-1 px-2 py-1 font-bold">{row.title}</td>
              <td className={`px-2 text-right ${row.declared ? '' : 'bg-grey-2'}`}>{row.declared ?? ''}</td>
              <td className={`px-2 text-right ${row.bankStatement ? '' : 'bg-grey-2'}`}>{row.bankStatement ?? ''}</td>
              {!aiResult && !isAIResultLoading ? (
                <>
                  {index === 0 && (
                    <td rowSpan={rowsData.length} className="px-2 text-center">
                      <div className="flex flex-col items-center gap-4">
                        <span>
                          {isAnalyseFetching || isAIResultFetching
                            ? "This won't take long"
                            : 'Analyse these bank statement values with AI'}
                        </span>
                        {isAIResultFetching || isAnalyseFetching ? (
                          <Spinner size="small" />
                        ) : (
                          <Button
                            variant="accent"
                            size="small"
                            className="!min-w-fit"
                            onClick={handleAnalyseBankStatement}
                          >
                            Analyse
                          </Button>
                        )}
                      </div>
                    </td>
                  )}
                </>
              ) : (
                <td className={`px-2 text-right ${row.ai ? '' : 'bg-grey-2'}`}>{row.ai ?? ''}</td>
              )}
              <td className={`px-2 text-right ${row.override ? '' : 'bg-grey-2'}`}>{row.override ?? ''}</td>
              <td className="px-2 text-right">{row.final}</td>
            </tr>
          ))}
          <tr>
            <td />
            <td />
            <td />
            <td />
            <td className="bg-grey-1 border-x-grey-2 border-x px-2 py-1 font-bold">Total Income (Net) </td>
            <td className="px-2 text-right">{formatNumber(affordabilityData?.['finalTotalIncome'] as number, 2)}</td>
          </tr>
        </tbody>
      </table>
      {aiResult && <AIFeedback traceId={aiResult.traceId} />}
    </>
  );
};

import { useEffect, useRef, useState } from 'react';
import { useAppSelector, useGetSmartUIParameterQuery } from '@harmoney/redux';

import { useBranch } from './use-branch';

export const useOneSDK = ({ config }) => {
  const [oneSDKInstance, setOneSDKInstance] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [components, setComponents] = useState([]);
  const initializedRef = useRef(false);
  const userId = useAppSelector((state) => state.userId.value);
  const branch = useBranch();
  const { data: smartUIParameters, refetch: refetchSmartUIParameter } = useGetSmartUIParameterQuery(
    { userId, branch, preset: 'one-sdk' },
    { skip: !userId || !branch, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    const generateOneSDKInstance = async () => {
      setLoading(true);
      const tokenSessionFromBackEnd = smartUIParameters;
      if (!tokenSessionFromBackEnd) return;
      const SDKConfig = config || {
        mode: 'development',
        recipe: {
          form: {
            provider: {
              name: 'react',
            },
          },
        },
      };
      try {
        const oneSDKInit = await (
          await import('@frankieone/one-sdk')
        ).default({
          session: tokenSessionFromBackEnd,
          ...SDKConfig,
        });
        const device = oneSDKInit.component('device', {
          activityType: 'REGISTRATION',
          sessionId: userId,
        });
        device.start();
        const oneSdkIndividual = oneSDKInit.individual();

        oneSdkIndividual.setPhoneNumber(smartUIParameters.mobilePhoneNumber);
        oneSdkIndividual.setEmail(smartUIParameters.email);
        setOneSDKInstance(oneSDKInit);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (!initializedRef.current && smartUIParameters) {
      generateOneSDKInstance();
      initializedRef.current = true;
    }

    return () => {
      components.forEach((component) => {
        component?.off();
        component?.unmount();
      });
    };
  }, [components, config, smartUIParameters]);

  return {
    oneSDKInstance,
    error,
    loading,
    setComponents,
  };
};

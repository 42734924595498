import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(relativeTime);
dayjs.extend(utc);

type CompareDatesArgs = {
  date: string | Date;
  threshold?: number;
};

export const compareDatesFromNowInDays = ({ date, threshold = 60 }: CompareDatesArgs): string => {
  const diffInDays = dayjs().diff(date, 'days');
  switch (true) {
    case diffInDays === 0:
      return 'Today';
    case diffInDays === 1:
      return 'Yesterday';
    case diffInDays <= threshold:
      return `${diffInDays} days ago`;
    default:
      return dayjs(date).fromNow();
  }
};

export const parseUTCAndConvertToLocalTime = (date: string | Date): dayjs.Dayjs => {
  const utcDate = dayjs.utc(date);
  return utcDate.local();
};

export const convertMonthsToYears = (months: number): string | number => {
  const years = months / 12;

  if (months === 1) return 1;
  if (months > 1) return `${years} years`;

  return 'N/A';
};

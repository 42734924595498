import { ComponentProps } from 'react';
import classNames from 'classnames';

import styles from './Divider.module.scss';

export type DividerProps = ComponentProps<'div'>;

export const Divider = ({ className, children, ...restProps }: DividerProps) => {
  const classes = classNames(styles['divider'], className);

  return (
    <div role="separator" className={classes} {...restProps}>
      {children && <span className="px-4">{children}</span>}
    </div>
  );
};

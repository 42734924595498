import { PreQualifiedQuoteBpmnVariables } from '@harmoney/api-interfaces';
import { ExtendedPreQualifiedQuote } from '@harmoney/redux';
import { CurrencyWithFrequency } from '@harmoney/ui-design-system';
import { valueOrNA } from '@harmoney/ui-utils';
import { formatNumber } from '@harmoney/utilities';

import { Field } from './shared';

interface PreQualifiedQuoteAffordabilityDetailsProps {
  preQualifiedQuote: ExtendedPreQualifiedQuote;
}

export const PreQualifiedQuoteAffordabilityDetails = ({
  preQualifiedQuote,
}: PreQualifiedQuoteAffordabilityDetailsProps) => {
  const variables = preQualifiedQuote?.variables as unknown as PreQualifiedQuoteBpmnVariables;
  const monthlyNetIncome = variables?.monthlyNetIncome;
  const finalTotalExpense = variables?.finalTotalExpense;
  const finalTotalDebt = variables?.finalTotalDebt;
  const umi = variables?.umi;

  return (
    <div className="grid grid-cols-2 px-4">
      <div className="border-r-grey-1 border-r-2 p-4">
        <Field className="!pb-4">
          <span>Total income:</span>
          {monthlyNetIncome ? (
            <CurrencyWithFrequency amount={monthlyNetIncome} frequency="monthly" />
          ) : (
            <span>{valueOrNA(monthlyNetIncome)}</span>
          )}
        </Field>
        <Field className="!pb-4">
          <span>Total expenses:</span>
          {finalTotalExpense ? (
            <CurrencyWithFrequency amount={finalTotalExpense} frequency="monthly" />
          ) : (
            <span>{valueOrNA(finalTotalExpense)}</span>
          )}
        </Field>
        <Field className="!pb-4">
          <span>Total debts:</span>
          {finalTotalDebt ? (
            <CurrencyWithFrequency amount={finalTotalDebt} frequency="monthly" />
          ) : (
            <span>{valueOrNA(finalTotalDebt)}</span>
          )}
        </Field>
      </div>
      <div className="p-4">
        <Field className="!pb-4">
          <span>UMI:</span>
          {umi ? <span>{formatNumber(umi)}</span> : <span>{valueOrNA(umi)}</span>}
        </Field>
      </div>
    </div>
  );
};

import { generateIcon } from '../../generateIcon';
import { IconVariant } from '../../Icon';

export const HouseholdItemsIcon = function ({ variant = 'grey-4' }: { variant?: IconVariant }) {
  const Component = generateIcon({
    viewBox: '0 0 24 24',
    variant: variant,
    path: (
      <>
        <g clipPath="url(#clip0_4613_49606)">
          <path d="M12.8 5.6V1.6C12.8 0.72 12.08 0 11.2 0H1.6C0.72 0 0 0.72 0 1.6V5.6H12.8ZM3.2 2.4H4.8V4.8H3.2V2.4ZM0 7.2V14.4C0 15.28 0.72 16 1.6 16H11.2C12.08 16 12.8 15.28 12.8 14.4V7.2H0ZM4.8 12H3.2V8H4.8V12Z" />
          <rect x="6" y="14" width="16" height="7" fill="white" />
          <rect width="11" height="4" transform="matrix(1 0 0 -1 9 14)" fill="white" />
          <path d="M21.0555 13.1667V11.5C21.0555 10.125 19.9305 9 18.5555 9H10.2222C8.84717 9 7.72217 10.125 7.72217 11.5V13.1667C6.34717 13.1667 5.22217 14.2917 5.22217 15.6667V19.8333C5.22217 21.2083 6.34717 22.3333 7.72217 22.3333V23.1667C7.72217 23.625 8.09717 24 8.5555 24C9.01383 24 9.38883 23.625 9.38883 23.1667V22.3333H19.3888V23.1667C19.3888 23.625 19.7638 24 20.2222 24C20.6805 24 21.0555 23.625 21.0555 23.1667V22.3333C22.4305 22.3333 23.5555 21.2083 23.5555 19.8333V15.6667C23.5555 14.2917 22.4305 13.1667 21.0555 13.1667ZM9.38883 11.5C9.38883 11.0417 9.76383 10.6667 10.2222 10.6667H18.5555C19.0138 10.6667 19.3888 11.0417 19.3888 11.5V13.8167C18.8805 14.275 18.5555 14.9333 18.5555 15.6667V17.3333H10.2222V15.6667C10.2222 14.9333 9.89717 14.275 9.38883 13.8167V11.5ZM21.8888 19.8333C21.8888 20.2917 21.5138 20.6667 21.0555 20.6667H7.72217C7.26383 20.6667 6.88883 20.2917 6.88883 19.8333V15.6667C6.88883 15.2083 7.26383 14.8333 7.72217 14.8333C8.1805 14.8333 8.5555 15.2083 8.5555 15.6667V19H20.2222V15.6667C20.2222 15.2083 20.5972 14.8333 21.0555 14.8333C21.5138 14.8333 21.8888 15.2083 21.8888 15.6667V19.8333Z" />
        </g>
        <defs>
          <clipPath id="clip0_4613_49606">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </>
    ),
  });
  return <Component />;
};

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { PropsWithHTMLElement } from '../../types';
import { Button } from '../Button';
import { IconV2 as Icon, IconV2 } from '../Icon';

import styles from './Alert.module.scss';

export type AlertVariant = 'success' | 'info' | 'warning' | 'error' | 'tip' | 'wait' | 'celebrate' | 'light-info';

export type AlertInternalProps = {
  /**
   * Determines style variation of Alert component.
   * @default info
   */
  variant: AlertVariant;
  /**
   * Determines the title of the Alert.
   */
  title?: string;
  /**
   * Determines the content of the Alert.
   */
  children?: React.ReactNode;
  /**
   * Determines whether or not the Alert can be dismissed.
   * @default false
   */
  dismissible?: boolean;
  /**
   * Determines whether or not the Alert can be collapsed.
   * @default false
   */
  collapsible?: boolean;
  /**
   * Determines whether to add a different icon
   */
  icon?: React.ReactNode;
  /**
   * Determines what the right action element is
   */
  rightAction?: React.ReactNode;

  /**
   * Determines whether the alert is open or closed
   */
  defaultCollapsed?: boolean;

  /**
   * Determines whether the alert dismisses automatically
   */
  autoDismissInMilliseconds?: number;

  /*
   * A handler to be called when the dismissible button is clicked
   */
  dismissibleHandler?: () => void;
};

export type AlertProps = PropsWithHTMLElement<AlertInternalProps, 'div'>;

const iconMapping: { [key in AlertVariant]: React.ReactElement } = {
  success: <IconV2 icon="material-symbols:check-circle-rounded" className="text-success" width={24} />,
  warning: <IconV2 icon="ic:round-warning" className="text-warning" width={24} />,
  error: <IconV2 icon="ic:round-error" className="text-error" width={24} />,
  info: <IconV2 icon="ic:round-info" className="text-secondary-lighter-1" width={24} />,
  tip: <IconV2 icon="mdi:lightbulb" className="text-secondary-lighter-1" width={22} />,
  wait: <IconV2 icon="ic:round-hourglass-top" className="text-warning" width={22} />,
  celebrate: <IconV2 icon="mdi:party-popper" className="text-tertiary" width={24} />,
  'light-info': <IconV2 icon="ic:round-info" className="text-secondary" width={24} color="#73A4F5" />,
};

export const Alert = ({
  className,
  children,
  title,
  icon,
  onClick,
  variant = 'info',
  dismissible = false,
  collapsible = false,
  rightAction = null,
  defaultCollapsed = false,
  dismissibleHandler,
  autoDismissInMilliseconds,
}: AlertProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

  const rootClasses = classNames(
    styles['alert'],
    {
      [styles[`alert-${variant}`]]: variant,
      [styles['alert-closed']]: !isOpen,
    },
    className
  );

  const handleDismiss = () => {
    setIsOpen(false);
    if (dismissibleHandler) {
      dismissibleHandler();
    }
  };

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined;
    if (autoDismissInMilliseconds) {
      timer = setTimeout(() => {
        setIsOpen(false);
        if (dismissibleHandler) {
          dismissibleHandler();
        }
      }, autoDismissInMilliseconds);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [autoDismissInMilliseconds, dismissibleHandler]);

  return (
    <div className={rootClasses} role="alert" onClick={onClick}>
      <div className="flex flex-row w-full items-center justify-center">
        <span className={classNames(rightAction && 'mt-1', 'mr-2 flex self-start')}>
          {icon ?? iconMapping[variant]}
        </span>
        <div className="w-full">
          {title && (
            <div className="flex flex-row items-center justify-between">
              <h5 className="font-body mb-0">{title}</h5>
              {collapsible && (
                <Button
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  icon={<Icon icon="ic:round-chevron-right" className="text-grey-5" width={24} />}
                  className={classNames(!isCollapsed ? styles['collapsed'] : '')}
                />
              )}
              {dismissible && (
                <Button
                  onClick={handleDismiss}
                  icon={<IconV2 icon="ic:round-close" className="text-grey-3" width={30} />}
                />
              )}
              {rightAction && <div className="cursor-pointer">{rightAction}</div>}
            </div>
          )}
          {children && !isCollapsed && <div className={`w-full text-sm ${title ? 'mt-2' : 'mt-0'}`}>{children}</div>}
        </div>
      </div>
    </div>
  );
};

import { Icon } from '@iconify/react';

interface HeaderProps {
  headerTitle: string;
  onCancelClick: () => void;
  closeVisible?: boolean;
}

export const Header = ({ headerTitle, onCancelClick, closeVisible = true }: HeaderProps) => {
  const handleCancel = () => {
    onCancelClick();
  };
  return (
    <div className="sticky top-0 z-10 rounded-b-sm bg-white px-4 pb-2 pt-6">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-baseline justify-end gap-4">
          <h2>{headerTitle}</h2>
        </div>
        {closeVisible && <Icon icon="ic:round-close" onClick={handleCancel} className="cursor-pointer" width={24} />}
      </div>
    </div>
  );
};

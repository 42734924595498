/* tslint:disable */
/* eslint-disable */
/**
 * Frankie Financial API
 * ------   This API allows developers to integrate the Frankie Financial Compliance Utility into their applications. The API allows:   - Checking name, address, date of birth against national databases   - Validating Australian driver's licences, passports, medicare, visas and other Australian national ID documents   - Validating Australian electricity bills   - Validating NZ driver's licences   - Validating Chinese bank cards and national ID card   - Validating International passports and national ID documents   - PEP, Sanctions, Watchlist and adverse media checking   - Australian visa checks    - Fraud list and fraud background checks   - ID validation and selfie check comparisons.    ------     Industry specific services    - Comparing Australian electricity retailers for a better deal.  ------     KYB specific services    - Query organisation ownership   - Perform KYC & AML checks on shareholders, beneficial owners and office bearers.   - Query credit score and credit reports   - International company searches   - International company profiles    ------   The full version of this documentation along with supplemental articles can be found here:   - https://apidocs.frankiefinancial.com/  ------ Sandbox base URL is:   - https://api.demo.frankiefinancial.io/compliance/v1.2      - All calls are the same as production, only with test data. You can download the latest test data here: https://apidocs.frankiefinancial.com/docs/test-data    - Full Swagger definition, along with test data for playing in the sandbox can be obtained once initial commercial discussions have commenced.    - Production and optional UAT access will be opened up only to those with a signed commercial contract.    ------   Contact us at hello@frankiefinancial.com to speak with a sales rep about issuing a Customer ID and Sandbox api key.
 *
 * OpenAPI spec version: 1.14.0
 * Contact: dev-support@frankiefinancial.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * The reason why the scanData in a response is missing. - \"NORMAL\": The scanData was retrieved and is included. If it is empty then it was never provided or was provided empty. - \"EXCLUDED\": The retrieval request was not for 'full' data, or the object has 'ScanDelete' set so the scanData is not included - \"FAILED\": The scanData could not be retrieved from the secure document store.  The enumScanDataRetrievalState will not usually be set in a request. If a ScannedDocumentObject in a response has a 'FAILED' retrieval state then that object should not be sent back in a future possible update. It should either be omitted or the original data should be resent if it is available from another source. However it is safe to send the object in an update with the state received in a response. Any state other than 'NORMAL' (or '') will cause the blank scanData to be ignored, but other fields in the object will be updated if needed.
 * @export
 * @enum {string}
 */
export enum EnumScanDataRetrievalState {
  NORMAL = 'NORMAL',
  EXCLUDED = 'EXCLUDED',
  FAILED = 'FAILED',
}

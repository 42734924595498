import { Fragment } from 'react';
import { FinancialProfileDto } from '@harmoney/api-interfaces';
import { Card, Divider, Label } from '@harmoney/ui-design-system';
import { AmountFormatter, amountOrDash, capitalizeTitle, FrequencyFormatter, replaceChar } from '@harmoney/ui-utils';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';

export const Incomes = ({ financialSummary }: { financialSummary: FinancialProfileDto }) => {
  return (
    <Card className="!p-0">
      <div className="flex items-center justify-between">
        <Label className="p-4 text-lg font-medium">Income</Label>
      </div>
      <Divider className="text-grey-2 m-0 p-0" />

      {financialSummary?.incomes.length > 0 &&
        financialSummary?.incomes.map(
          (
            {
              id,
              networthSourceName,
              otherIncomeType,
              employmentType,
              seasonalWorkingMonths,
              frequency,
              declaredAmount,
              isRentalIncomeShared,
              employmentStartDate,
              benefitType,
            },
            index
          ) => (
            <Fragment key={`incomes-${id}`}>
              <div key={index} className="flex flex-row items-center justify-between p-4">
                <div>
                  {otherIncomeType ? (
                    <Label className="text-base">{capitalizeTitle(otherIncomeType)}</Label>
                  ) : networthSourceName === `i don't earn an income` ? (
                    <Label className="text-base">Income</Label>
                  ) : (
                    <Label className="text-base">{capitalizeTitle(networthSourceName)}</Label>
                  )}
                  {networthSourceName === `salary / wages` && (
                    <Label className="text-xs">
                      ({capitalize(replaceChar(employmentType))}
                      {seasonalWorkingMonths ? `, ${seasonalWorkingMonths}` : null}
                      {`, since ${dayjs(employmentStartDate).format('MMMM YYYY').toString()}`})
                    </Label>
                  )}
                  {isRentalIncomeShared ?? null ? (
                    isRentalIncomeShared ? (
                      <span className="text-xs">(Shared Income)</span>
                    ) : (
                      <span className="text-xs">(Individual income)</span>
                    )
                  ) : null}
                  {networthSourceName === 'benefit' && <span className="text-xs">{benefitType}</span>}
                </div>
                {declaredAmount > 0 ? (
                  <span className="font-medium">
                    {AmountFormatter.format(declaredAmount)}/{FrequencyFormatter(frequency)}
                  </span>
                ) : (
                  <span className="font-medium">{amountOrDash(declaredAmount)}</span>
                )}
              </div>
              <Divider className="text-grey-1 m-0 px-4" />
            </Fragment>
          )
        )}
    </Card>
  );
};

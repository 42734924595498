import { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { NetworthSourceEnum, NetworthSourceKind } from '@harmoney/api-interfaces';
import { useFriendlyURL } from '@harmoney/hooks';
import { useAppSelector, useGetNetworthSourcesQuery } from '@harmoney/redux';
import { useSaveExpensesMutation } from '@harmoney/redux';
import { eventAnalytics, FINANCIALPROFILE_EXPENSES_PROVIDED } from '@harmoney/ui-app-shell';
import {
  Alert,
  ArrowCircleRightIcon,
  Button,
  Card,
  CollapsibleHeader,
  Form,
  HelpText,
  Select,
  ToggleGroup,
  useForm,
} from '@harmoney/ui-design-system';
import { errors, expensesShareWithOptions, stringToBool, toggleYesNoOptions } from '@harmoney/ui-utils';
import { ExpenseSharerEnum } from '@prisma/client';
import { flatMapDeep, isArray, isEmpty, isObject } from 'lodash';
import { z } from 'zod';

import { CommonProps } from '../../common-props';

import { LivingExpenseItem } from './LivingExpenseItem/LivingExpenseItem';
import { initialFormExpenseValues } from './InitialFormExpenseValues';

const NETWORTH_CODE_NO_EXPENSE = 'no_expense';
const NETWORTH_CODE_OTHER_EXPENSES = 'other_expenses';

const expenseSchemaOne = z
  .object({
    networthSourceId: z.number().optional(),
    declaredAmount: z.coerce.number({ invalid_type_error: errors.defaultValidAmount }).optional(),
    frequency: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.networthSourceId !== null && data.declaredAmount <= 0) {
        return false;
      }
      return true;
    },
    {
      message: errors.defaultValidAmount,
      path: ['declaredAmount'],
    }
  )
  .refine(
    (data) => {
      if (data.declaredAmount > 0 && isEmpty(data.frequency)) return false;
      return true;
    },
    {
      message: errors.defaultRequiredFrequency,
      path: ['frequency'],
    }
  );

const expenseSchemaTwo = z.array(
  z
    .object({
      otherExpenseType: z.string().min(1, { message: errors.requiredField('Expense') }),
      networthSourceId: z.number({ invalid_type_error: errors.defaultValidAmount }).optional(),
      declaredAmount: z.coerce
        .number({ invalid_type_error: errors.defaultValidAmount })
        .gte(1, { message: errors.defaultValidAmount }),
      frequency: z.string().optional(),
    })
    .refine(
      (data) => {
        if (data.declaredAmount > 0 && isEmpty(data.frequency)) return false;
        return true;
      },
      {
        message: errors.defaultRequiredFrequency,
        path: ['frequency'],
      }
    )
);
const expenseSchema = z.union([expenseSchemaTwo, expenseSchemaOne]);

const rootSchema = z
  .object({
    isExpenseShared: z.string().min(1, { message: errors.defaultRequiredField }),
    expenseSharedWith: z.string().optional(),
    isAdditionalFinancialCommitment: z.string().min(1, { message: errors.defaultRequiredField }),
    expenses: z.record(expenseSchema),
  })
  .refine(
    (data) => {
      if (data.isExpenseShared === 'Yes' && isEmpty(data.expenseSharedWith)) {
        return false;
      }
      return true;
    },
    {
      message: errors.defaultRequiredField,
      path: ['expenseSharedWith'],
    }
  );

export function LivingExpense({ completeTask, taskId, taskFriendlyURL }: CommonProps) {
  const userId = useAppSelector((state) => state.userId.value);

  const form = useForm({
    mode: 'onTouched',
    schema: rootSchema,
    defaultValues: { isExpenseShared: '', expenseSharedWith: '', isAdditionalFinancialCommitment: '', expenses: {} },
  });

  const { control, register, watch, setValue, resetField } = form;

  const {
    fields: otherExpenseFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `expenses.${NETWORTH_CODE_OTHER_EXPENSES}`,
  });

  const watchForm = watch();

  const { data: expenseCategories } = useGetNetworthSourcesQuery(NetworthSourceKind.EXPENSE);
  const [expandCollapsible, setExpandCollapsible] = useState<boolean[]>([]);
  const [noExpenseData, setNoExpenseData] = useState(null);

  const [disableCollapsible, setDisableCollapsible] = useState(false);
  const [formTouched, setFormTouched] = useState(false);

  const [formSubmitting, setFormSubmitting] = useState(false);

  const [saveExpenses] = useSaveExpensesMutation();

  const handleSubmit = async (data) => {
    const updatedExpensesData = { ...data['expenses'] };

    if (data['expenses']['other_expenses']?.length === 0) {
      delete updatedExpensesData['other_expenses'];
    }
    // handle data incomes in case of no income
    data['expenses'] = isEmpty(updatedExpensesData) ? noExpenseData : updatedExpensesData;

    if (isEmpty(data.expenses)) {
      setFormSubmitting(false);

      setFormTouched(true);
      return;
    }

    setFormSubmitting(true);

    // Extract all objects
    const transformedData: any = flatMapDeep(data.expenses, (value) => {
      if (isObject(value) && Object.keys(value).length > 1 && !isArray(value)) {
        return [value];
      } else if (isArray(value)) {
        return value.map((item) => (isObject(item) ? item : {}));
      }
      return [];
    });
    let expenseShare;
    if (data.isExpenseShared === 'Yes') {
      expenseShare = data.expenseSharedWith;
    } else {
      expenseShare = ExpenseSharerEnum.none;
    }
    const updatedData = [
      ...transformedData,
      {
        networthSourceId: NetworthSourceEnum.EXPENSE_SHARED_ID,
        isExpenseShared: stringToBool(data.isExpenseShared),
        expenseSharedWith: expenseShare,
      },
    ];
    await saveExpenses({
      taskId,
      expenses: updatedData,
    });
    eventAnalytics.track(FINANCIALPROFILE_EXPENSES_PROVIDED, {
      userid_str: userId,
      taskid_str: taskId,
    });
    await completeTask({ taskId });
  };

  const onToggle = async (expense, index, noExpenseIndex) => {
    setFormTouched(false);
    // set collapsible state to track the expandible div's
    await setExpandCollapsible((prevState) => {
      let newState;
      if (index === noExpenseIndex) {
        newState = prevState.map(() => false);
        newState[index] = !prevState[index];
        setValue('expenses', {});
        setValue('isAdditionalFinancialCommitment', 'No');
        setValue('isExpenseShared', watchForm.isExpenseShared);
        setValue('expenseSharedWith', watchForm.expenseSharedWith);
      } else {
        if (prevState[index]) {
          resetField(`expenses.${expense.code}`);
        }
        newState = [...prevState];
        newState[index] = !prevState[index];
      }
      return newState;
    });

    if (expense.code === NETWORTH_CODE_NO_EXPENSE) {
      setDisableCollapsible(!disableCollapsible);
      setNoExpenseData((prevState) =>
        isEmpty(prevState) ? { [`${expense.code}`]: initialFormExpenseValues(expense) } : null
      );
    } else {
      expandCollapsible[index]
        ? setValue(`expenses.${expense.code}`, {})
        : setValue(`expenses.${expense.code}`, initialFormExpenseValues(expense));
    }
  };

  useEffect(() => {
    if (expenseCategories) {
      setExpandCollapsible(Array(expenseCategories.length).fill(false));
    }
  }, [expenseCategories]);

  //Set values to other_expense form array based on the toggle button value
  useEffect(() => {
    if (watchForm.isAdditionalFinancialCommitment === 'Yes') {
      setValue(`expenses.${NETWORTH_CODE_OTHER_EXPENSES}`, [
        initialFormExpenseValues({ id: NetworthSourceEnum.EXPENSE_OTHER_ID, code: NETWORTH_CODE_OTHER_EXPENSES }),
      ]);
    }

    if (watchForm.isAdditionalFinancialCommitment === 'No') {
      setValue(`expenses.${NETWORTH_CODE_OTHER_EXPENSES}`, []);
    }
  }, [watchForm.isAdditionalFinancialCommitment]);

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      <h1>
        What do you <strong className="text-primary">pay</strong> for?
      </h1>
      <Form form={form} onSubmit={handleSubmit}>
        <Card>
          <ToggleGroup
            {...register('isExpenseShared')}
            label="Do you share your expenses with someone?"
            options={toggleYesNoOptions}
          />
          {watchForm.isExpenseShared === 'Yes' && (
            <Select
              {...register('expenseSharedWith')}
              label="Who do you share your expenses with?"
              className="mt-4"
              options={expensesShareWithOptions}
            />
          )}
        </Card>

        {expenseCategories?.map((expense, expenseIndex) => (
          <CollapsibleHeader
            valid={formTouched}
            key={expense.id}
            checkbox={true}
            code={expense.code}
            disabled={expense.code === NETWORTH_CODE_NO_EXPENSE ? false : disableCollapsible}
            subtitle={expense.description}
            title={expense.name}
            onCollapseChange={() => onToggle(expense, expenseIndex, expenseCategories.length - 1)}
            open={expandCollapsible[expenseIndex]}
          >
            {expense.code != NETWORTH_CODE_OTHER_EXPENSES && expense.code !== NETWORTH_CODE_NO_EXPENSE && (
              <div className="mx-4">
                <LivingExpenseItem register={register} index={expenseIndex} code={expense.code} />
              </div>
            )}
          </CollapsibleHeader>
        ))}
        {formTouched && <p className="text-error">Please select an option</p>}

        {!disableCollapsible && (
          <div key="additionalExpense">
            <Card>
              <label>Do you have any other regular payments or expenses that you plan to keep making?</label>
              <HelpText className="mt-2">Private school fees, charitable donations etc</HelpText>
              <ToggleGroup {...register('isAdditionalFinancialCommitment')} options={toggleYesNoOptions} />
              {watchForm.isAdditionalFinancialCommitment === 'Yes' && (
                <>
                  <Alert variant="info" className="mt-6">
                    <p>
                      Please don&rsquo;t add your <strong>mortgage, credit card, or loan repayments.</strong>{' '}
                      We&rsquo;ll discuss your debts later.
                    </p>
                  </Alert>
                  {otherExpenseFields.map((otherExpenseField, index) => (
                    <div key={otherExpenseField['id']} className="mt-4">
                      <LivingExpenseItem
                        register={register}
                        index={index}
                        code={NETWORTH_CODE_OTHER_EXPENSES}
                        removeItem={() => remove(index)}
                      />
                      {otherExpenseFields?.length - 1 === index && (
                        <Button
                          key={index}
                          className="mx-4 mb-3"
                          alignIcon="start"
                          size="small"
                          variant="text"
                          onClick={() =>
                            append(
                              initialFormExpenseValues({
                                id: NetworthSourceEnum.EXPENSE_OTHER_ID,
                                code: NETWORTH_CODE_OTHER_EXPENSES,
                              })
                            )
                          }
                        >
                          + Another Expense
                        </Button>
                      )}
                    </div>
                  ))}
                </>
              )}
            </Card>
          </div>
        )}

        <Button
          type="submit"
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          className="mt-6"
          hasShadow
          isLoading={formSubmitting}
        >
          Continue
        </Button>
      </Form>
    </>
  );
}

import {
  useGetRepaymentDetailForUnutilisedLimitQuery,
  useSavePaymentForUnutilisedLimitMutation,
} from '@harmoney/redux';

import { CommonProps } from '../../common-props';
import { WizardChildProps } from '../../Wizard';

import { PaymentForm } from './components';

interface PaymentProps extends CommonProps, WizardChildProps {}

export function PaymentForUnutilisedLimit({ taskId, onNext, completeTask, taskFriendlyURL }: PaymentProps) {
  return (
    <PaymentForm
      taskId={taskId}
      taskFriendlyURL={taskFriendlyURL}
      repaymentDetailQuery={useGetRepaymentDetailForUnutilisedLimitQuery}
      savePaymentMutation={useSavePaymentForUnutilisedLimitMutation}
      onNext={onNext}
      completeTask={completeTask}
    />
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * Frankie Financial API
 * ------   This API allows developers to integrate the Frankie Financial Compliance Utility into their applications. The API allows:   - Checking name, address, date of birth against national databases   - Validating Australian driver's licences, passports, medicare, visas and other Australian national ID documents   - Validating Australian electricity bills   - Validating NZ driver's licences   - Validating Chinese bank cards and national ID card   - Validating International passports and national ID documents   - PEP, Sanctions, Watchlist and adverse media checking   - Australian visa checks    - Fraud list and fraud background checks   - ID validation and selfie check comparisons.    ------     Industry specific services    - Comparing Australian electricity retailers for a better deal.  ------     KYB specific services    - Query organisation ownership   - Perform KYC & AML checks on shareholders, beneficial owners and office bearers.   - Query credit score and credit reports   - International company searches   - International company profiles    ------   The full version of this documentation along with supplemental articles can be found here:   - https://apidocs.frankiefinancial.com/  ------ Sandbox base URL is:   - https://api.demo.frankiefinancial.io/compliance/v1.2      - All calls are the same as production, only with test data. You can download the latest test data here: https://apidocs.frankiefinancial.com/docs/test-data    - Full Swagger definition, along with test data for playing in the sandbox can be obtained once initial commercial discussions have commenced.    - Production and optional UAT access will be opened up only to those with a signed commercial contract.    ------   Contact us at hello@frankiefinancial.com to speak with a sales rep about issuing a Customer ID and Sandbox api key.
 *
 * OpenAPI spec version: 1.14.0
 * Contact: dev-support@frankiefinancial.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CurrentBillDataSolar } from './current-bill-data-solar';
import { Period } from './period';
import { Retailer } from './retailer';
/**
 *
 * @export
 * @interface CurrentBillData
 */
export interface CurrentBillData {
  /**
   * Account (Billing) address.
   * @type {string}
   * @memberof CurrentBillData
   */
  accountAddress: string;
  /**
   * Customer name.
   * @type {string}
   * @memberof CurrentBillData
   */
  accountName: string;
  /**
   * Customer account number
   * @type {string}
   * @memberof CurrentBillData
   */
  accountNumber: string;
  /**
   * Recalculated cost of the plan based on users comsumption and plan rates including discounts, rebates, concessions etc... Additional fees such as credit card processing fees are ignored.
   * @type {number}
   * @memberof CurrentBillData
   */
  actualPlanTotalCost: number;
  /**
   * Start date for billing period
   * @type {string}
   * @memberof CurrentBillData
   */
  billDateFrom: string;
  /**
   * End date for billing period
   * @type {string}
   * @memberof CurrentBillData
   */
  billDateTo: string;
  /**
   * Number of days in billing period (billDateTo - billDateFrom)
   * @type {number}
   * @memberof CurrentBillData
   */
  days: number;
  /**
   * Total value of all unconditional discounts applied to the bill
   * @type {number}
   * @memberof CurrentBillData
   */
  discount: number;
  /**
   * * `E` - Electricity
   * @type {string}
   * @memberof CurrentBillData
   */
  fuelType: CurrentBillDataFuelTypeEnum;
  /**
   * National Meter identifier (NMI)
   * @type {string}
   * @memberof CurrentBillData
   */
  nmi: string;
  /**
   * Rates and charges for each period on the bill
   * @type {Array<Period>}
   * @memberof CurrentBillData
   */
  periods: Array<Period>;
  /**
   *
   * @type {Retailer}
   * @memberof CurrentBillData
   */
  retailer: Retailer;
  /**
   * Array of rates and charges for solar on the bill, by period. If no solar is present on the uploaded bill this object will not be present.
   * @type {Array<CurrentBillDataSolar>}
   * @memberof CurrentBillData
   */
  solar?: Array<CurrentBillDataSolar>;
  /**
   * Supply address. This may differ from account address if bill payers address is different from account address.
   * @type {string}
   * @memberof CurrentBillData
   */
  supplyAddress: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CurrentBillDataFuelTypeEnum {
  E = 'E',
}

import { forwardRef } from 'react';
import classNames from 'classnames';

interface CardProps {
  className?: string;
  children?: React.ReactNode;
}

export const Card = forwardRef<HTMLDivElement, CardProps>(({ className, children }, ref) => {
  return (
    <div className={classNames('shadow-drop mb-6 rounded-xl bg-white p-6', className)} ref={ref}>
      {children}
    </div>
  );
});

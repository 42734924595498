import { AmountInput, CommonOptionProps, Spinner, ToggleGroup, useFormContext } from '@harmoney/ui-design-system';
import { frequencyOptionsWithoutDefault } from '@harmoney/ui-utils';

interface RepaymentCalculatorProps {
  targetRef: React.RefObject<HTMLDivElement>;
  shouldShowLoanAmount: boolean;
  formattedQuoteOptions: CommonOptionProps[];
  isSameLoanLimitAcrossOptions: boolean;
}

export const RepaymentCalculator = ({
  targetRef,
  shouldShowLoanAmount,
  formattedQuoteOptions,
  isSameLoanLimitAcrossOptions,
}: RepaymentCalculatorProps) => {
  const { register } = useFormContext();

  return (
    <>
      {shouldShowLoanAmount && (
        <div ref={targetRef}>
          <AmountInput {...register('amount')} label="How much do you want to borrow?" maxLength={6} className="mb-6" />
        </div>
      )}

      <ToggleGroup
        {...register('repaymentFrequency')}
        options={frequencyOptionsWithoutDefault}
        label="How would you like to repay your loan?"
        className="mb-6 sm:min-w-full"
      />

      <fieldset>
        <legend className="mb-2">Over how many years?</legend>
        {!isSameLoanLimitAcrossOptions && (
          <p className="text-sm text-grey-4 mb-2">
            Changing your borrowing amount may change the affordable loan terms available to you.
          </p>
        )}
        {formattedQuoteOptions ? (
          <ToggleGroup {...register('termInMonths')} options={formattedQuoteOptions} className="mb-6 sm:min-w-full" />
        ) : (
          <div className="flex flex-col items-center bg-grey-1 rounded-xl py-4">
            <Spinner size="small" />
            <p className="mb-0 mt-2">Generating your loan terms...</p>
          </div>
        )}
      </fieldset>
    </>
  );
};

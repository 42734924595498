import { generateIcon } from '../generateIcon';
import { IconProps } from '../Icon';

/**
 * @deprecated Check if it can be replaced with Iconify. If not, add it to icon.json.
 * Refer to icon document for details: https://63be80b51f526a24fc7865c5-tslqcdnosl.chromatic.com/?path=/docs/docs-icons--docs
 */
export const ChevronRightIcon = function ({ variant = 'grey-5', ...rest }: IconProps) {
  const Component = generateIcon({
    viewBox: '0 0 8 12',
    variant,
    path: <path d="M1.99997 0L0.589966 1.41L5.16997 6L0.589966 10.59L1.99997 12L7.99997 6L1.99997 0Z" />,
  });

  return <Component {...rest} />;
};

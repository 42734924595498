import { useEffect, useState } from 'react';
import { IBranchFields } from '@harmoney/api-interfaces';
import { getBranchCollection } from '@harmoney/services';
import { EntryCollection } from 'contentful';

import { useBranch } from './use-branch';

export function useBranchContent() {
  const branch = useBranch();
  const [branchContent, setBranchContent] = useState<IBranchFields | null>(null);

  useEffect(() => {
    (async function () {
      if (!branch) return;
      const branchContent: EntryCollection<IBranchFields> = await getBranchCollection(branch);
      const branchFields = branchContent?.items[0]?.fields;
      setBranchContent(branchFields);
    })();
  }, [branch]);

  return { branchContent };
}

import { ReprocessOperationDetail } from '@harmoney/api-interfaces';
import { CollapsibleCard } from '@harmoney/ui-design-system';
import { booleanToYesNo, DATE_TIME_FORMAT } from '@harmoney/ui-utils';
import dayjs from 'dayjs';

import { Field } from '../shared';

interface Props {
  coReprocessData: ReprocessOperationDetail[];
}

export const CoReprocessDetails = ({ coReprocessData }: Props) => {
  if (!coReprocessData) return null;

  return (
    <CollapsibleCard title="Reprocess Operation" className="mb-6">
      {coReprocessData?.map((coReprocess, i) => (
        <div key={`reprocess_${i}`} className="grid grid-cols-2 px-4">
          <div className="p-4 space-y-2">
            <Field>
              <span>What other information did you use to review this application?</span>
              <span>
                <ul>{coReprocess?.nextSteps?.map((step, index) => <li key={index}>{step}</li>)}</ul>
              </span>
            </Field>
            <Field>
              <span>Has override values:</span>
              <span>{booleanToYesNo(coReprocess?.hasOverride)}</span>
            </Field>
            <Field>
              <span>Reprocessed by:</span>
              <span>{coReprocess?.user?.preferredName ?? coReprocess?.user?.email}</span>
            </Field>
            <Field>
              <span>Processed at:</span>
              <span>{dayjs(coReprocess?.createdAt).format(DATE_TIME_FORMAT)}</span>
            </Field>
          </div>
        </div>
      ))}
    </CollapsibleCard>
  );
};

import { useEffect, useState } from 'react';
import { ScheduleExtraPaymentDto, VaultLoanInformationDto, VaultPaymentInformationDto } from '@harmoney/api-interfaces';
import { useGetRepaymentScheduleDataQuery } from '@harmoney/redux';
import {
  ArrowCircleRightIcon,
  Button,
  ControlledInput,
  Dialog,
  Form,
  Label,
  Select,
  Textarea,
  useForm,
} from '@harmoney/ui-design-system';
import { DATE_FORMAT } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import { LoanVariationPurposeEnum } from '@prisma/client';
import dayjs from 'dayjs';

import { CommonProps } from '../../../common-props';
import { loanVariationPurposeOptions } from '../LoanVariation/loan-variation-options';

import { ExtraPaymentItem } from './ExtraPaymentItem';
import {
  createScheduleExtraPaymentFormSchema,
  extraPaymentInitialValues,
  getScheduleExtraPaymentDefaultValues,
} from './form-config';
import { PaymentPlanFormFooter } from './PaymentPlanFormFooter';
import { PaymentPlanFormHeader } from './PaymentPlanFormHeader';
import {
  ChangeSummaryTypeEnum,
  getCurrentOneOffPaymentDates,
  getCurrentPendingOneOffPaymentAmount,
  getFinalPaymentPlanData,
} from './util';

interface ScheduleExtraPaymentProps extends CommonProps {
  loanApplicationId: string;
  paymentInformation: VaultPaymentInformationDto;
  loanInformation: VaultLoanInformationDto;
}

export const ScheduleExtraPayment = ({
  loanApplicationId,
  paymentInformation,
  loanInformation,
  taskId,
  completeTaskWithData,
}: ScheduleExtraPaymentProps) => {
  const { data: paymentPlanData } = useGetRepaymentScheduleDataQuery(loanApplicationId, {
    skip: !loanApplicationId,
    refetchOnMountOrArgChange: true,
  });

  const finalPaymentPlanData = getFinalPaymentPlanData(paymentPlanData);

  const currentOneOffPaymentDates = getCurrentOneOffPaymentDates(finalPaymentPlanData);
  const currentPendingOneOffPaymentAmount = getCurrentPendingOneOffPaymentAmount(finalPaymentPlanData);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const form = useForm({
    mode: 'onTouched',
    schema: createScheduleExtraPaymentFormSchema(
      paymentInformation?.payOffAmount,
      currentOneOffPaymentDates,
      currentPendingOneOffPaymentAmount
    ),
    defaultValues: getScheduleExtraPaymentDefaultValues(),
  });

  const {
    register,
    watch,
    setValue,
    getValues,
    resetField,
    formState: { errors },
  } = form;

  const watchForm = watch();
  const watchExtraPayments = watchForm.extraPayments;

  useEffect(() => {
    if (watchExtraPayments.length > 1 && errors?.extraPayments?.type === 'custom') {
      setIsError(true);
    } else setIsError(false);
  }, [errors?.extraPayments, watchExtraPayments.length]);

  const handleSubmit = () => {
    setIsConfirmModalOpen(true);
  };

  const onAddItem = () => {
    const allItems = watchExtraPayments;
    allItems.push(extraPaymentInitialValues);
    setValue('extraPayments', allItems);
  };

  const onRemoveItem = async (indexToRemove: number) => {
    const currentExtraPayments = await getValues('extraPayments');
    const updatedExtraPayments = currentExtraPayments.filter((_, index) => index !== indexToRemove);
    await resetField('extraPayments');
    await setValue('extraPayments', updatedExtraPayments);
  };

  const onModalSubmit = () => {
    setIsSubmitting(true);
    const formData = form.getValues();

    const scheduleData: ScheduleExtraPaymentDto[] = [];

    formData.extraPayments.forEach((payment) => {
      scheduleData.push({
        purpose: formData.purpose,
        otherPurpose: formData.otherPurpose || null,
        note: formData.notes,
        amount: payment.paymentAmount,
        scheduledAt: dayjs(payment.schedulePaymentDate).format('YYYY-MM-DD'),
      });
    });

    completeTaskWithData({ taskId, variables: { loanApplicationId, scheduleData } });
  };

  return (
    <>
      <Form form={form} onSubmit={handleSubmit}>
        <PaymentPlanFormHeader headerTitle="Schedule extra payment" type={ChangeSummaryTypeEnum.ONE_OFF_PAYMENT} />
        <div className="px-4">
          <Select {...register('purpose')} label="Purpose" options={loanVariationPurposeOptions} className="mb-6" />

          {watchForm.purpose === LoanVariationPurposeEnum.OTHER && (
            <>
              <Label htmlFor="otherPurpose" className="mb-2">
                Specify the purpose
              </Label>
              <ControlledInput {...register('otherPurpose')} className="mb-6" type="text" />
            </>
          )}
          {watchExtraPayments.map((_, index) => (
            <div key={index}>
              <ExtraPaymentItem
                index={index}
                register={register}
                removeItem={onRemoveItem}
                payOffAmount={paymentInformation?.payOffAmount}
                maturityDate={loanInformation?.maturityDate}
                isError={isError}
                totalPayments={watchExtraPayments.length}
              />
            </div>
          ))}
          {errors.extraPayments && <p className="text-error text-right mb-0">{errors.extraPayments.message}</p>}
          <div className="mb-12 flex justify-end">
            <Button size="medium" variant="text" onClick={() => onAddItem()} className="mt-6">
              Add another extra payment
            </Button>
          </div>
          <Textarea {...register('notes')} label="Notes" placeholder="Add a note" />
          <PaymentPlanFormFooter
            buttonText="Save"
            isSubmitting={isSubmitting}
            type={ChangeSummaryTypeEnum.ONE_OFF_PAYMENT}
          />
        </div>
      </Form>
      <Dialog
        open={isConfirmModalOpen}
        onOpenChange={() => {
          setIsConfirmModalOpen(!isConfirmModalOpen);
        }}
        title="Schedule extra payment"
        modal
        showCloseButton={!isSubmitting}
      >
        <p className="p-4">
          Are you sure you want to schedule the extra payment{watchExtraPayments.length === 1 ? ' ' : 's '}below:
          {watchExtraPayments.map((payment, index) => (
            <span key={index} className="flex justify-between mt-4">
              <span className="font-medium">{formatCurrency(payment.paymentAmount)}</span>
              <span>Scheduled for {dayjs(payment.schedulePaymentDate).format(DATE_FORMAT)}</span>
            </span>
          ))}
        </p>
        <div className="shrink-1 flex w-full flex-col items-center justify-start gap-2 sm:flex-col md:flex-row-reverse p-4 flex-wrap">
          <Button
            onClick={onModalSubmit}
            type="submit"
            variant="primary"
            className="sm:!min-w-full md:!min-w-fit md:!max-w-fit"
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
            isLoading={isSubmitting}
            hasShadow
          >
            Confirm
          </Button>
          <Button
            onClick={() => setIsConfirmModalOpen(false)}
            variant="secondary"
            className="!min-w-fit !max-w-fit"
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </div>
      </Dialog>
    </>
  );
};

import { PreQualifiedQuoteBpmnVariables } from '@harmoney/api-interfaces';
import { ExtendedPreQualifiedQuote } from '@harmoney/redux';
import { valueOrNA } from '@harmoney/ui-utils';
import { currencyOrDash } from '@harmoney/utilities';
import { PreQualifiedQuoteStatusEnum } from '@prisma/client';
import classNames from 'classnames';

import { Field } from './shared';

interface PreQualifiedQuoteFinalDetailsProps {
  preQualifiedQuote: ExtendedPreQualifiedQuote;
  mode?: PreQualifiedQuoteStatusEnum;
}

export const PreQualifiedQuoteFinalDetails = ({
  preQualifiedQuote,
  mode = 'QUOTE',
}: PreQualifiedQuoteFinalDetailsProps) => {
  const variables = preQualifiedQuote?.variables as unknown as PreQualifiedQuoteBpmnVariables;

  const LimitDetails = () => (
    <div className="p-4 tabular-nums">
      <Field className="!pb-4">
        <span>Loan limit from income:</span>
        <span className="text-right max-w-16">{currencyOrDash(variables?.loanLimitForIncome)}</span>
      </Field>
      <Field className="!pb-4">
        <span>Loan limit from umi for three years:</span>
        <span className="text-right max-w-16">{currencyOrDash(variables?.loanLimitFromUmiForThreeYears)}</span>
      </Field>
      <Field className="!pb-4">
        <span>Loan limit from umi for five years:</span>
        <span className="text-right max-w-16">{currencyOrDash(variables?.loanLimitFromUmiForFiveYears)}</span>
      </Field>
      <Field className="!pb-4">
        <span>Loan limit from umi for seven years:</span>
        <span className="text-right max-w-16">{currencyOrDash(variables?.loanLimitFromUmiForSevenYears)}</span>
      </Field>
      <Field className="!pb-4">
        <span>Requested amount:</span>
        <span className="text-right max-w-16">{currencyOrDash(variables?.loan?.requestedAmount)}</span>
      </Field>
      <Field className="!pb-4">
        <span>Credit limit from score card:</span>
        <span className="text-right max-w-16">
          {currencyOrDash(variables?.interestRateAndCreditLimit?.creditLimit)}
        </span>
      </Field>
      <Field className="!pb-4">
        <span>Maximum loan amount:</span>
        <span className="text-right max-w-16">{currencyOrDash(variables?.product?.maximumLoanAmount)}</span>
      </Field>
      <Field className="!pb-4">
        <span>Minimum loan amount:</span>
        <span className="text-right max-w-16">{currencyOrDash(variables?.product?.minimumLoanAmount)}</span>
      </Field>
    </div>
  );

  return (
    <div className={classNames('grid px-4', mode === 'QUOTE' ? 'grid-cols-2' : 'grid-cols-1')}>
      {mode === 'QUOTE' && (
        <div className="border-r-grey-1 border-r-2 p-4">
          <Field className="!pb-4">
            <span>Loan amount:</span>
            <span>{currencyOrDash(variables?.finalOffer?.loanAmount)}</span>
          </Field>
          <Field className="!pb-4">
            <span>Interest rate:</span>
            <span>{variables?.interestRateAndCreditLimit?.interestRate}%</span>
          </Field>
          <Field className="!pb-4">
            <span>Comparison rate:</span>
            <span>{variables?.comparisonRate}%</span>
          </Field>
          <Field className="!pb-4">
            <span>Quote term:</span>
            <span>{valueOrNA(variables?.finalOffer?.loanTerm)}</span>
          </Field>
          <Field className="!pb-4">
            <span>Repayment amount:</span>
            <span>{currencyOrDash(variables?.monthlyRepayment)}</span>
          </Field>
        </div>
      )}
      <LimitDetails />
    </div>
  );
};

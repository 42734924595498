import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { loanStatusEmojiMapper } from '@harmoney/api-interfaces';
import { useAppDispatch, useLazySearchUsersQuery, userApi } from '@harmoney/redux';
import { Card, CopyButton, Dialog, Divider, Input, Spinner } from '@harmoney/ui-design-system';
import { useDebouncedCallback, useHotkeys } from '@mantine/hooks';
import { LoanApplicationStatusEnum } from '@prisma/client';
import dayjs from 'dayjs';

export const Search = () => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [trigger, { isLoading, data }] = useLazySearchUsersQuery();
  const router = useRouter();
  const dispatch = useAppDispatch();

  const searchRef = useRef<HTMLButtonElement>(null);

  const handleSearch = useDebouncedCallback(async (query: string) => {
    await trigger(query);
  }, 500);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    handleSearch(event.target.value);
  };

  useHotkeys([
    [
      '/',
      () => {
        searchRef.current?.click();
      },
    ],
  ]);

  useEffect(() => {
    const handlePageChange = () => {
      setOpen(false);
      setSearch('');
      dispatch(userApi.util.invalidateTags(['Search']));
    };
    router.events.on('routeChangeComplete', handlePageChange);
    router.events.on('hashChangeComplete', handlePageChange);
    window.addEventListener('hashchange', handlePageChange);
    return () => {
      router.events.off('routeChangeComplete', handlePageChange);
      router.events.off('hashChangeComplete', handlePageChange);
      window.removeEventListener('hashchange', handlePageChange);
    };
  }, [router, dispatch]);

  return (
    <>
      <button
        className="bg-white border-grey-3 border rounded-lg py-1 px-2"
        onClick={() => setOpen(true)}
        ref={searchRef}
      >
        <span className="flex flex-row items-center justify-between space-x-12 font-bold text-sm py-0">
          <span className="text-grey-4 text-sm font-bold"> Search...</span>
          <span className="font-mono text-[0.5rem]">
            Press{' '}
            <span className="max-w-2 bg-grey-1 max-h-2 rounded-sm border border-b-2 border-opacity-20 px-1 font-mono text-[0.5rem]">
              /
            </span>
          </span>
        </span>
      </button>
      <Dialog
        open={open}
        onOpenChange={() => {
          setOpen(false);
          setSearch('');
        }}
        showCloseButton
        title="Search users and applications"
      >
        <div className="flex flex-col space-y-4">
          <Input
            placeholder="Search..."
            name="search"
            onChange={handleChange}
            value={search}
            className="mx-4 mt-4"
            autoFocus
            autoComplete="off"
          />
          <div className="p-4">
            {isLoading && <Spinner />}
            {data && data.length === 0 && <div className="text-center text-grey-4">No results found</div>}
            {data && data.length > 0 && (
              <div className="space-y-3">
                <span className="text-sm text-grey-4">
                  Found {data.length} result{data.length > 1 && 's'}
                </span>
                <div className="max-h-[500px] overflow-auto p-4">
                  {data.map((user) => (
                    <Card key={user?.id}>
                      <div className="flex flex-col space-y-4">
                        <div className="flex flex-row items-start justify-between space-x-2">
                          <div className="flex flex-col gap-1">
                            <span className="font-bold text-lg">
                              {user?.preferredName}{' '}
                              {user?.userProfile?.firstName &&
                                `(${user?.userProfile?.firstName} ${user?.userProfile?.lastName})`}
                            </span>
                            <div className="flex flex-row gap-1 items-center">
                              <span className="text-grey-4 text-sm">{user.businessKey}</span>
                              <CopyButton valueToCopy={user?.businessKey} size="small" />
                            </div>
                            <div className="flex flex-row gap-1 items-center">
                              <span className="text-grey-4 text-sm">{user.id}</span>
                              <CopyButton valueToCopy={user?.id} size="small" />
                            </div>
                          </div>
                          <div className="flex flex-col gap-1 items-end">
                            <div className="flex flex-row gap-1 items-center">
                              <span className="text-grey-4 text-sm">{user?.email}</span>
                              <CopyButton valueToCopy={user?.email} size="small" />
                            </div>
                            <div className="flex flex-row gap-1 items-center">
                              <span className="text-grey-4 text-sm">{user?.mobilePhoneNumber}</span>
                              <CopyButton valueToCopy={user?.mobilePhoneNumber} size="small" />
                            </div>
                            <Link
                              className="mt-2"
                              title={`View profile ${user?.id}`}
                              href={`/admin/details/?userId=${user?.id}#customer-profile`}
                              passHref
                              replace
                            >
                              View Profile
                            </Link>
                          </div>
                        </div>
                        <Divider className="text-grey-2 !mb-0" />
                        {user?.loanApplication.length === 0 && (
                          <div className="text-center text-grey-4">No loan applications found</div>
                        )}
                        {user?.loanApplication?.length > 0 && (
                          <div className="space-y-2">
                            <span className="font-medium">Loan Applications</span>
                            {[...user.loanApplication]
                              .sort((a, b) => {
                                return dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix();
                              })
                              .map((loan, index, arr) => (
                                <>
                                  <div key={loan.id} className="flex flex-row items-start justify-between gap-1">
                                    <div className="flex flex-col space-y-2">
                                      <span className="font-bold">
                                        {loan?.loanApplicationPurposes?.[0]?.loanPurpose?.displayName}{' '}
                                        {loan?.displayName && `(${loan?.displayName})`}
                                      </span>
                                      <div className="flex flex-row gap-1 items-center">
                                        <span className="text-grey-4">{loan.businessKey}</span>
                                        <CopyButton valueToCopy={loan.businessKey} size="small" />
                                      </div>
                                      <div className="flex flex-row gap-1 items-center">
                                        <span className="text-grey-4">{loan.id}</span>
                                        <CopyButton valueToCopy={loan.id} size="small" />
                                      </div>
                                    </div>
                                    <div className="flex flex-col gap-1 items-end">
                                      <span className="text-lg">{loanStatusEmojiMapper[loan.status]}</span>
                                      <Link
                                        className="mt-2"
                                        title={`View application ${loan?.id}`}
                                        href={`/admin/details/?userId=${user.id}#applications/${loan.id}`}
                                        passHref
                                        replace
                                      >
                                        View Application
                                      </Link>
                                      {loan.status === LoanApplicationStatusEnum.application_successful && (
                                        <Link
                                          className="mt-2"
                                          title={`View loan ${loan?.id}`}
                                          href={`/admin/customer/loan?applicationId=${loan.id}&userId=${user.id}`}
                                          passHref
                                          replace
                                        >
                                          View Loan
                                        </Link>
                                      )}
                                    </div>
                                  </div>
                                  {index !== arr.length - 1 && <Divider className="text-grey-2 !mb-0" />}
                                </>
                              ))}
                          </div>
                        )}
                      </div>
                    </Card>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};

import { useState } from 'react';
import { toast, ToastPosition } from 'react-toastify';
import { LocalQuoteDetails } from '@harmoney/api-interfaces';
import { useSendQuoteEmailMutation } from '@harmoney/redux';
import { Button, ChevronRightIcon, Dialog } from '@harmoney/ui-design-system';

import { EmailYourQuoteCard, GetQuickAnswersCard, RatingsCard, WhatHappensNextCard } from './components/QuoteHelpCards';

const DEFAULT_TOAST_OPTIONS = {
  position: 'bottom-center' as ToastPosition,
  hideProgressBar: true,
  autoClose: 2500,
  closeButton: false,
  draggable: false,
};

interface QuoteHelpModalProps {
  email: string;
  isQuoteHelpModalOpen: boolean;
  onEmailSend: () => LocalQuoteDetails;
  onQuoteHelpModalOpen: (isOpen: boolean) => void;
}

export function QuoteHelpModal({
  email,
  isQuoteHelpModalOpen,
  onEmailSend,
  onQuoteHelpModalOpen,
}: QuoteHelpModalProps) {
  const [uiState, setUIState] = useState({
    isGoingBackToQuote: false,
    isEmailingQuote: false,
  });

  const [sendQuoteEmail] = useSendQuoteEmailMutation();

  const handleEmailQuote = async () => {
    setUIState((prevState) => ({
      ...prevState,
      isEmailingQuote: true,
    }));

    const updatedLocalQuoteDetails = onEmailSend();

    try {
      await sendQuoteEmail(updatedLocalQuoteDetails).unwrap();

      toast.success(`Your quote was successfully sent to ${email}`, {
        ...DEFAULT_TOAST_OPTIONS,
        className: 'border bg-tertiary-lighter-3 border-tertiary !p-0 !mx-4 !mb-4 rounded-lg',
        bodyClassName: 'bg-tertiary-lighter-3 !m-0 !p-4 text-grey-5 text-sm',
      });
    } catch (e) {
      toast.error('Something went wrong. Please try again later.', {
        ...DEFAULT_TOAST_OPTIONS,
        className: 'border bg-error-fill border-error !p-4 !mb-4 !mx-2 rounded-lg',
        bodyClassName: 'bg-error-fill !m-0 text-grey-5 text-sm',
      });
    }

    setUIState((prevState) => ({
      ...prevState,
      isEmailingQuote: false,
    }));
  };

  return (
    <>
      <Dialog
        open={isQuoteHelpModalOpen}
        onOpenChange={() => onQuoteHelpModalOpen(!isQuoteHelpModalOpen)}
        modal
        contentClassName="max-h-full min-w-full pt-8 pb-16 overflow-x-hidden overflow-y-auto px-4 z-50 mt-0"
      >
        <div className="flex flex-col items-center justify-center">
          <div className="absolute -top-8 w-full md:w-[756px] h-[358px] md:h-[454px] rounded-b-pill md:rounded-b-full bg-gradient-to-b from-primary-darker-1 to-primary" />
          <div className="relative z-10 mb-4 md:w-[512px]">
            <ChevronRightIcon
              onClick={() => onQuoteHelpModalOpen(!isQuoteHelpModalOpen)}
              size="tiny"
              className="rotate-180 cursor-pointer mb-4"
              variant="white"
            />
            <p className="text-xl text-white font-bold">Not quite ready to move forward?</p>
            <span className="text-white">
              We understand that applying for a loan is a big step. These resources can help you make a more informed
              decision.
            </span>
          </div>
          <div className="relative z-10">
            <EmailYourQuoteCard isEmailingQuote={uiState.isEmailingQuote} onEmailQuote={handleEmailQuote} />
            <GetQuickAnswersCard />
            <WhatHappensNextCard />
            <RatingsCard />
          </div>
          <Button
            onClick={() => onQuoteHelpModalOpen(!isQuoteHelpModalOpen)}
            isLoading={uiState.isGoingBackToQuote}
            variant="secondary"
            hasShadow
          >
            Back to Quote
          </Button>
        </div>
      </Dialog>
    </>
  );
}

import React, { useRef, useState } from 'react';
import { Icon } from '@iconify/react';

import 'regenerator-runtime/runtime';

type Props = {
  globalFilter: string;
  totalItems: number;
  setGlobalFilter: (value: string) => void;
};

const GlobalFilter = ({ globalFilter, setGlobalFilter, totalItems }: Props) => {
  const [value, setValue] = useState(globalFilter);

  const searchRef = useRef<HTMLInputElement>(null);

  return (
    <div className="flex flex-row items-center gap-1 relative">
      <Icon icon="mdi:search" className="absolute text-grey-3 left-2" width={24} height={24} />
      <input
        ref={searchRef}
        type="text"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          setGlobalFilter(e.target.value);
        }}
        placeholder="Search"
        className="w-[360px] rounded-lg border border-grey-2 p-2 pl-8"
      />
    </div>
  );
};

export default GlobalFilter;

import { CollapsibleCard } from '@harmoney/ui-design-system';
import { DATE_TIME_FORMAT } from '@harmoney/ui-utils';
import dayjs from 'dayjs';

interface Props {
  loanApplicationData;
  loanDocumentsData;
}

export const LoanAgreement = ({ loanApplicationData, loanDocumentsData }: Props) => {
  const generalTermsAcceptedAt = loanApplicationData?.loanAgreementGeneralTermsAcceptedAt;
  const disclosureAcceptedAt = loanApplicationData?.loanDisclosureAcceptedAt;
  return (
    <CollapsibleCard title="Loan Agreement" className="mb-6">
      <div className="grid-cols grid px-4">
        <div className="odd:border-r-grey-1 p-4 odd:border-r-2">
          {loanApplicationData && (
            <>
              <span className="grid grid-cols-2">
                <span>Loan agreement general terms accepted at</span>
                <span>{generalTermsAcceptedAt ? dayjs(generalTermsAcceptedAt).format(DATE_TIME_FORMAT) : 'N/A'}</span>
              </span>
              <span className="grid grid-cols-2">
                <span>Loan agreement details accepted at </span>
                <span>{disclosureAcceptedAt ? dayjs(disclosureAcceptedAt).format(DATE_TIME_FORMAT) : 'N/A'}</span>
              </span>
              {generalTermsAcceptedAt && disclosureAcceptedAt && (
                <span className="grid grid-cols-2">
                  <span>Loan agreement details document </span>
                  <span>
                    <a href={`${loanDocumentsData?.url}`} target="_blank" rel="noreferrer">
                      View file
                    </a>
                  </span>
                </span>
              )}
            </>
          )}
        </div>
      </div>
    </CollapsibleCard>
  );
};

import { useEffect, useState } from 'react';
import { Slide, toast } from 'react-toastify';
import {
  FULL_HISTORY_START_DATE,
  OrderEnum,
  PaymentTransactionGroupedByDateDTO,
  TimeUnitEnum,
} from '@harmoney/api-interfaces';
import { Transaction } from '@harmoney/components';
import { useGetPaymentTransactionsQuery, useLazyGetStatementOfAccountPDFQuery } from '@harmoney/redux';
import { Button, Card, Spinner } from '@harmoney/ui-design-system';
import dayjs from 'dayjs';

interface TransactionHistoryProps {
  loanApplicationId: string;
}

export const TransactionHistory = ({ loanApplicationId }: TransactionHistoryProps) => {
  const [limit, setLimit] = useState<number>(3);
  const [transactions, setTransactions] = useState<PaymentTransactionGroupedByDateDTO[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const TRANSACTIONS_TO_SHOW = 5;

  const [trigger] = useLazyGetStatementOfAccountPDFQuery();
  const { data: transactionData } = useGetPaymentTransactionsQuery(
    {
      loanApplicationId,
      params: { skipPeriod: 0, numberOfPeriodUnits: 1, periodUnit: TimeUnitEnum.Year, order: OrderEnum.DESC },
    },
    { skip: !loanApplicationId, refetchOnMountOrArgChange: true, refetchOnFocus: true, refetchOnReconnect: true }
  );

  useEffect(() => {
    setTransactions(transactionData?.data?.slice(0, limit));
  }, [limit, transactionData]);

  const handleLoadMore = () => {
    setLimit((prev) => prev + TRANSACTIONS_TO_SHOW);
  };

  const downloadPdf = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `StatementOfAccount.pdf`);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleError = () => {
    toast.error(
      `There was a problem downloading your statement of account. Please reach out to our customer service and we will send it to you directly.`,
      {
        position: 'bottom-center',
        autoClose: false,
        closeButton: true,
        draggable: false,
        className: 'border bg-error-fill border-error !p-4 !mb-4 !mx-2 rounded-lg',
        bodyClassName: 'bg-error-fill !m-0 text-grey-5 text-sm',
        transition: Slide,
      }
    );
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const pdfUrl = await trigger({
        loanApplicationId,
        requestedStartDate: FULL_HISTORY_START_DATE,
        requestedEndDate: dayjs().format('YYYY-MM-DD'),
      }).unwrap();

      downloadPdf(pdfUrl);
    } catch (e) {
      console.error(e);
      handleError();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Card className="flex flex-col items-stretch !p-0 md:px-16 my-6">
        <div className="p-4 my-2 text-grey-5 font-medium border-b border-b-grey-2">
          <div className="flex justify-between mb-2">
            <p className="text-base font-medium !mb-0">Recent transactions</p>
            <div className="flex">
              <Button variant="text" onClick={handleSubmit} disabled={isSubmitting}>
                Download
              </Button>
              {isSubmitting && <Spinner size="small" className="pl-2" />}
            </div>
          </div>
          <p>Showing transactions for last year only. Download for full statement of account.</p>
        </div>
        {transactions?.length > 0 ? (
          <>
            <div className="my-4">
              {transactions?.map((transaction, index) => <Transaction key={index} transaction={transaction} />)}
            </div>

            {transactions?.length < transactionData?.data?.length && (
              <Button className="mb-4 mx-auto" variant="outline-secondary" onClick={handleLoadMore}>
                Load More
              </Button>
            )}
          </>
        ) : (
          <div className="text-grey-4 p-4">No recent transactions</div>
        )}
      </Card>
    </>
  );
};

import { errors, isPartnered, stringToBool } from '@harmoney/ui-utils';
import { convertToMonthlyAmount } from '@harmoney/utilities';
import { IncomeAndExpenseFrequencyEnum, RelationshipStatusEnum } from '@prisma/client';
import { isEmpty } from 'class-validator';
import { z } from 'zod';

import { errorMessageForAmountComparison } from '../LivingExpenseV2/form-config';

export const defaultValues = {
  otherEssentialExpenses: {
    id: '',
    declaredTotalAmount: null,
    frequencyForTotalAmount: '',
    hasOtherEssentialExpenses: '',
    isExpenseShared: '',
    withSharedExpense: {
      declaredAmount: null,
      frequency: '',
    },
  },
  totalEssentialExpenses: {
    id: '',
    declaredTotalAmount: null,
    frequencyForTotalAmount: '',
    isExpenseShared: '',
    withSharedExpense: {
      declaredAmount: null,
      frequency: '',
    },
  },
};

export const formSchemaForMarriedOrDeFactoTotalEssentialExpenses = z.object({
  totalEssentialExpenses: z
    .object({
      id: z.string().nullable().optional(),
      declaredTotalAmount: z
        .number({
          invalid_type_error: errors.defaultValidAmount,
          required_error: errors.defaultValidAmount,
        })
        .nullable()
        .optional(),
      frequencyForTotalAmount: z.string().nullable().optional(),
      isExpenseShared: z.string().nullable().optional(),
      withSharedExpense: z.object({
        declaredAmount: z
          .number({
            invalid_type_error: errors.defaultValidAmount,
            required_error: errors.defaultValidAmount,
          })
          .nullable()
          .optional(),
        frequency: z.string().nullable().optional(),
      }),
    })
    .refine(
      (data) => {
        const hasNoDeclaredTotalAmount = !data.declaredTotalAmount || data.declaredTotalAmount === 0;
        const hasNoDeclaredSharedAmount =
          !data.withSharedExpense.declaredAmount || data.withSharedExpense.declaredAmount === 0;

        if (hasNoDeclaredTotalAmount) return false;
        if (hasNoDeclaredTotalAmount && hasNoDeclaredSharedAmount) {
          return false;
        }
        return true;
      },
      {
        message: errors.defaultValidAmount,
        path: ['declaredTotalAmount'],
      }
    )
    .refine(
      (data) => {
        const hasDeclaredTotalAmountButNoFrequency =
          data.declaredTotalAmount > 0 && isEmpty(data.frequencyForTotalAmount);

        if (hasDeclaredTotalAmountButNoFrequency) return false;
        return true;
      },
      {
        message: errors.defaultRequiredField,
        path: ['frequencyForTotalAmount'],
      }
    )
    .refine(
      (data) => {
        if (isEmpty(data.isExpenseShared)) return false;
        return true;
      },
      {
        message: errors.defaultRequiredField,
        path: ['isExpenseShared'],
      }
    )
    .refine(
      (data) => {
        const hasDeclaredSharedAmountButNoFrequency =
          data.withSharedExpense.declaredAmount > 0 && isEmpty(data.withSharedExpense.frequency);

        if (hasDeclaredSharedAmountButNoFrequency) return false;
        return true;
      },
      {
        message: errors.defaultRequiredField,
        path: ['withSharedExpense', 'frequency'],
      }
    )
    .refine(
      (data) => {
        const isExpenseSharedButNoDeclaredSharedExpense =
          stringToBool(data.isExpenseShared) && !data.withSharedExpense.declaredAmount;

        if (isExpenseSharedButNoDeclaredSharedExpense) return false;

        return true;
      },
      {
        message: errors.defaultValidAmount,
        path: ['withSharedExpense', 'declaredAmount'],
      }
    )
    .refine(
      (data) => {
        const isDeclaredTotalAmountGreaterOrEqualToSharedAmount =
          convertToMonthlyAmount(
            data.declaredTotalAmount,
            data.frequencyForTotalAmount as IncomeAndExpenseFrequencyEnum
          ) >=
          convertToMonthlyAmount(
            data.withSharedExpense.declaredAmount,
            data.withSharedExpense.frequency as IncomeAndExpenseFrequencyEnum
          );

        if (
          data.withSharedExpense.declaredAmount >= 0 &&
          data.declaredTotalAmount > 0 &&
          isDeclaredTotalAmountGreaterOrEqualToSharedAmount
        ) {
          return true;
        }

        return false;
      },
      {
        message: errorMessageForAmountComparison,
        path: ['withSharedExpense', 'declaredAmount'],
      }
    ),
});

export const formSchemaForNonMarriedOrDefactoTotalEssentialExpenses = z.object({
  totalEssentialExpenses: z
    .object({
      id: z.string().nullable().optional(),
      declaredTotalAmount: z
        .number({
          invalid_type_error: errors.defaultValidAmount,
          required_error: errors.defaultValidAmount,
        })
        .nullable()
        .optional(),
      frequencyForTotalAmount: z.string().nullable().optional(),
      isExpenseShared: z.string().nullable().optional(),
      withSharedExpense: z.object({
        declaredAmount: z
          .number({
            invalid_type_error: errors.defaultValidAmount,
            required_error: errors.defaultValidAmount,
          })
          .nullable()
          .optional(),
        frequency: z.string().nullable().optional(),
      }),
    })
    .refine(
      (data) => {
        const hasNoDeclaredTotalAmount = !data.declaredTotalAmount || data.declaredTotalAmount === 0;
        const hasDeclaredTotalAmountButNoFrequency =
          data.declaredTotalAmount > 0 && isEmpty(data.frequencyForTotalAmount);

        if (hasNoDeclaredTotalAmount || hasDeclaredTotalAmountButNoFrequency) return false;
        return true;
      },
      {
        message: errors.defaultValidAmount,
        path: ['frequencyForTotalAmount'],
      }
    )
    .refine(
      (data) => {
        if (data.declaredTotalAmount === null || data.declaredTotalAmount === 0) {
          return false;
        }
        return true;
      },
      {
        message: errors.defaultRequiredField,
        path: ['declaredTotalAmount'],
      }
    ),
});

export const formSchemaForMarriedOrDeFactoOtherEssentialExpenses = z.object({
  otherEssentialExpenses: z
    .object({
      id: z.string().nullable().optional(),
      hasOtherEssentialExpenses: z
        .string({ required_error: errors.defaultRequiredField })
        .min(1, { message: errors.defaultRequiredField }),
      declaredTotalAmount: z
        .number({
          invalid_type_error: errors.defaultValidAmount,
          required_error: errors.defaultValidAmount,
        })
        .nullable()
        .optional(),
      frequencyForTotalAmount: z.string().nullable().optional(),
      isExpenseShared: z.string().nullable().optional(),
      withSharedExpense: z.object({
        declaredAmount: z
          .number({
            invalid_type_error: errors.defaultValidAmount,
            required_error: errors.defaultValidAmount,
          })
          .nullable()
          .optional(),
        frequency: z.string().nullable().optional(),
      }),
    })
    .refine(
      (data) => {
        const hasOtherEssentialExpensesButNoDeclaredExpense =
          stringToBool(data.hasOtherEssentialExpenses) && !data.declaredTotalAmount;

        const hasOtherExpensesAndIsExpenseShared =
          stringToBool(data.hasOtherEssentialExpenses) && stringToBool(data.isExpenseShared);
        const declaredTotalAmountIsEmpty = data.declaredTotalAmount === null || data.declaredTotalAmount === 0;
        const declaredSharedAmountIsEmpty =
          data.withSharedExpense.declaredAmount === null || data.withSharedExpense.declaredAmount === 0;

        if (hasOtherEssentialExpensesButNoDeclaredExpense) return false;
        if (hasOtherExpensesAndIsExpenseShared && declaredTotalAmountIsEmpty && declaredSharedAmountIsEmpty) {
          return false;
        }
        return true;
      },
      {
        message: errors.defaultValidAmount,
        path: ['declaredTotalAmount'],
      }
    )
    .refine(
      (data) => {
        const hasDeclaredTotalAmountButNoFrequency =
          data.declaredTotalAmount > 0 && isEmpty(data.frequencyForTotalAmount);

        if (hasDeclaredTotalAmountButNoFrequency) return false;
        return true;
      },
      {
        message: errors.defaultRequiredField,
        path: ['frequencyForTotalAmount'],
      }
    )
    .refine(
      (data) => {
        const hasOtherEssentialExpensesButNoSharedExpense =
          stringToBool(data.hasOtherEssentialExpenses) && isEmpty(data.isExpenseShared);

        if (hasOtherEssentialExpensesButNoSharedExpense) return false;
        return true;
      },
      {
        message: errors.defaultRequiredField,
        path: ['isExpenseShared'],
      }
    )
    .refine(
      (data) => {
        const isExpenseSharedButNoDeclaredSharedExpense =
          stringToBool(data.hasOtherEssentialExpenses) &&
          stringToBool(data.isExpenseShared) &&
          !data.withSharedExpense.declaredAmount;

        if (isExpenseSharedButNoDeclaredSharedExpense) {
          return false;
        }

        const hasOtherEssentialExpenses = stringToBool(data.hasOtherEssentialExpenses);
        const hasOtherEssentialExpensesAndExpensesAreNotShared =
          hasOtherEssentialExpenses && !stringToBool(data.isExpenseShared);

        if (!hasOtherEssentialExpenses || hasOtherEssentialExpensesAndExpensesAreNotShared) {
          return true;
        }

        return true;
      },
      {
        message: errors.defaultValidAmount,
        path: ['withSharedExpense', 'declaredAmount'],
      }
    )
    .refine(
      (data) => {
        const hasDeclaredSharedAmountButNoFrequency =
          stringToBool(data.hasOtherEssentialExpenses) &&
          stringToBool(data.isExpenseShared) &&
          data.withSharedExpense.declaredAmount > 0 &&
          isEmpty(data.withSharedExpense.frequency);

        if (hasDeclaredSharedAmountButNoFrequency) return false;
        return true;
      },
      {
        message: errors.defaultRequiredField,
        path: ['withSharedExpense', 'frequency'],
      }
    )
    .refine(
      (data) => {
        const hasSharedOtherEssentialExpenses =
          stringToBool(data.hasOtherEssentialExpenses) && stringToBool(data.isExpenseShared);

        const isDeclaredTotalAmountGreaterOrEqualToSharedAmount =
          convertToMonthlyAmount(
            data.declaredTotalAmount,
            data.frequencyForTotalAmount as IncomeAndExpenseFrequencyEnum
          ) >=
          convertToMonthlyAmount(
            data.withSharedExpense.declaredAmount,
            data.withSharedExpense.frequency as IncomeAndExpenseFrequencyEnum
          );

        if (hasSharedOtherEssentialExpenses && !isDeclaredTotalAmountGreaterOrEqualToSharedAmount) {
          return false;
        }
        return true;
      },
      {
        message: errorMessageForAmountComparison,
        path: ['withSharedExpense', 'declaredAmount'],
      }
    ),
});

export const formSchemaForNonMarriedOrDeFactoOtherEssentialExpenses = z.object({
  otherEssentialExpenses: z
    .object({
      id: z.string().nullable().optional(),
      hasOtherEssentialExpenses: z
        .string({ required_error: errors.defaultRequiredField })
        .min(1, { message: errors.defaultRequiredField }),
      declaredTotalAmount: z
        .number({
          invalid_type_error: errors.defaultValidAmount,
          required_error: errors.defaultValidAmount,
        })
        .nullable()
        .optional(),
      frequencyForTotalAmount: z.string().nullable().optional(),
      isExpenseShared: z.string().nullable().optional(),
      withSharedExpense: z.object({
        declaredAmount: z
          .number({
            invalid_type_error: errors.defaultValidAmount,
            required_error: errors.defaultValidAmount,
          })
          .nullable()
          .optional(),
        frequency: z.string().nullable().optional(),
      }),
    })
    .refine(
      (data) => {
        const hasOtherEssentialExpensesButNoDeclaredExpense =
          stringToBool(data.hasOtherEssentialExpenses) && !data.declaredTotalAmount;

        if (hasOtherEssentialExpensesButNoDeclaredExpense) return false;
        return true;
      },
      {
        message: errors.defaultValidAmount,
        path: ['declaredTotalAmount'],
      }
    )
    .refine(
      (data) => {
        if (data.declaredTotalAmount > 0 && isEmpty(data.frequencyForTotalAmount)) return false;
        return true;
      },
      {
        message: errors.defaultRequiredField,
        path: ['frequencyForTotalAmount'],
      }
    ),
});

export const livingExpensesV2FormSchemaForMarriedOrDeFacto = z.object({
  ...formSchemaForMarriedOrDeFactoTotalEssentialExpenses.shape,
  ...formSchemaForMarriedOrDeFactoOtherEssentialExpenses.shape,
});

export const livingExpensesV2FormSchemaForNonMarriedOrDeFacto = z.object({
  ...formSchemaForNonMarriedOrDefactoTotalEssentialExpenses.shape,
  ...formSchemaForNonMarriedOrDeFactoOtherEssentialExpenses.shape,
});

export const formSchema = z.union([
  livingExpensesV2FormSchemaForMarriedOrDeFacto,
  livingExpensesV2FormSchemaForNonMarriedOrDeFacto,
]);

export const getFormSchema = (relationshipStatus: RelationshipStatusEnum) => {
  if (!relationshipStatus) return null;

  if (isPartnered(relationshipStatus)) {
    return livingExpensesV2FormSchemaForMarriedOrDeFacto;
  }

  return livingExpensesV2FormSchemaForNonMarriedOrDeFacto;
};

export type FormSchema = z.infer<typeof formSchema>;

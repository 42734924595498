import { AmountFormatter, amountOrDash, FrequencyFormatter } from '@harmoney/ui-utils';
import { AssetAndLiabilityFrequencyEnum, IncomeAndExpenseFrequencyEnum } from '@prisma/client';

interface Props {
  amount: number;
  frequency?: IncomeAndExpenseFrequencyEnum | AssetAndLiabilityFrequencyEnum;
}

export const FormattedAmount = ({ amount, frequency }: Props) =>
  amount > 0 ? (
    <span className="font-medium">
      {AmountFormatter.format(amount)}
      {frequency ? `/${FrequencyFormatter(frequency)}` : ''}
    </span>
  ) : (
    <span className="font-medium">{amountOrDash(amount)}</span>
  );

import { Fragment, MutableRefObject, useEffect, useRef, useState } from 'react';
import { DebtConsolidationLiabilityDto, RepaymentFrequency } from '@harmoney/api-interfaces';
import { useDebtConBenefits } from '@harmoney/hooks';
import { useLazyGetQuoteOptionsQuery, useLazyReprocessObjectiveQuery } from '@harmoney/redux';
import {
  Benefits,
  Card,
  CurrencyWithFrequency,
  Divider,
  IconV2,
  UncontrolledCheckbox,
  ValidationMessage,
} from '@harmoney/ui-design-system';
import { AmountFormatter } from '@harmoney/ui-utils';
import { useIntersection, useResizeObserver } from '@mantine/hooks';
import classNames from 'classnames';
import { isEmpty, isEqual } from 'lodash';
import { SwiperRef } from 'swiper/react';

import { DebtConBenefitsRender, standardDebtConsolidationReasons } from './DebtConBenefitsRender';
import { DebtItemToConsolidate } from './DebtItemToConsolidate';

type EstablishmentFee = {
  establishmentFeeOverThreshold: number;
  establishmentFeeUnderThreshold: number;
  establishmentFeeThreshold: number;
};

type Props = {
  debts: DebtConsolidationLiabilityDto[];
  maximumBorrowingLimit: number;
  interestRate: number;
  applicationId: string;
  establishmentFee: EstablishmentFee;
  canSelectAll: boolean;
  pageRef?: MutableRefObject<HTMLFormElement>;
  form: any;
};

export const DebtsToConsolidate = ({
  debts,
  maximumBorrowingLimit,
  interestRate,
  applicationId,
  establishmentFee,
  canSelectAll,
  pageRef,
  form,
}: Props) => {
  const {
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = form;

  const total = debts
    ?.filter((debt) => watch().debtsSelection[debt.liability.id])
    .reduce((acc, curr) => {
      return acc + curr.liability.outstandingBalance;
    }, 0);
  const [trigger, result] = useLazyGetQuoteOptionsQuery();
  const [reprocessObjectiveTrigger, results] = useLazyReprocessObjectiveQuery();
  const [selectedDebts, setSelectedDebts] = useState([]);
  const [areBenefitsCommon, setAreBenefitsCommon] = useState(undefined);
  const { ref: benefitsRef, entry } = useIntersection({
    root: pageRef.current,
    threshold: 1,
  });
  const [ref, rect] = useResizeObserver();
  const swiperRef = useRef<SwiperRef>(null);

  useEffect(() => {
    setValue('totalToConsolidate', total);
    const estFee =
      total > establishmentFee.establishmentFeeThreshold
        ? establishmentFee.establishmentFeeOverThreshold
        : establishmentFee.establishmentFeeUnderThreshold;
    if (total > 0) {
      trigger({ loanAmount: total, establishmentFee: estFee, loanApplicationId: applicationId }, true);
    }
  }, [total, setValue]);

  const allChecked = Object.values(watch().debtsSelection).every((value) => value === true);

  const indeterminate = Object.values(watch().debtsSelection).some((value) => value === true) && !allChecked;

  const schedule = result.data?.find(
    (option) => option.repaymentFrequency === RepaymentFrequency.MONTHLY && option.termInMonths === 84
  );

  const handleSelectAll = () => {
    if (errors?.debtsSelection) {
      clearErrors('debtsSelection');
    }
    setValue(
      'debtsSelection',
      Object.fromEntries(Object.keys(watch().debtsSelection).map((key) => [key, !allChecked]))
    );
  };

  useEffect(() => {
    const selectedDebts = Object.keys(watch().debtsSelection)
      .filter((key) => watch().debtsSelection[key])
      .map((key) => debts.find((debt) => debt.liability.id === key));
    setSelectedDebts(selectedDebts);

    reprocessObjectiveTrigger(
      {
        debts: selectedDebts,
        interestRate,
        maximumBorrowingLimit,
        hmyRepaymentAmount: schedule?.repaymentAmount,
      },
      true
    );
  }, [debts, schedule]);

  const benefits = useDebtConBenefits(results.data);

  useEffect(() => {
    if (
      !isEqual(
        watch().reason,
        benefits.filter((benefit) => benefit.display).map((benefit) => benefit.id)
      )
    ) {
      setValue(
        'reason',
        benefits.filter((benefit) => benefit.display).map((benefit) => benefit.id)
      );
    }
  }, [watch, benefits, setValue]);

  useEffect(() => {
    if (isEqual(watch().reason, standardDebtConsolidationReasons) && !isEmpty(selectedDebts)) {
      setAreBenefitsCommon(true);
    } else {
      setAreBenefitsCommon(false);
    }
  }, [selectedDebts, watch]);

  return (
    <>
      <Card className={classNames('mt-4 flex flex-col !p-0 overflow-hidden')} ref={ref}>
        <div
          className={classNames(
            errors?.debtsSelection && 'border-t border-x rounded-t-xl border-error',
            'flex-row flex justify-between items-center pr-4'
          )}
        >
          <div className="flex flex-col gap-1 p-4">
            <span className="font-medium">Choose your debts</span>
          </div>
          {canSelectAll && (
            <UncontrolledCheckbox
              name="all-debts"
              label="Select all"
              alignLabel="left"
              checked={indeterminate === true ? 'indeterminate' : allChecked}
              onCheckedChange={handleSelectAll}
            />
          )}
        </div>
        <div
          className={classNames(
            errors?.debtsSelection ? 'border border-t-grey-2 border-error' : 'border-y-grey-2 border-y'
          )}
        >
          {[...debts]
            ?.sort((a, b) => {
              if (
                a.liability.outstandingBalance <= maximumBorrowingLimit &&
                b.liability.outstandingBalance <= maximumBorrowingLimit
              ) {
                return +b.liability.interestRate - +a.liability.interestRate;
              }
              if (b.liability.outstandingBalance > maximumBorrowingLimit) {
                return -1;
              }
            })
            .map((debt, index, arr) => (
              <Fragment key={debt.liability.id}>
                <DebtItemToConsolidate form={form} debt={debt} maximumBorrowingLimit={maximumBorrowingLimit} />
                {index !== arr.length - 1 && <Divider className="text-grey-2" />}
              </Fragment>
            ))}
        </div>
        <div className="flex flex-col gap-2 p-4">
          {errors?.debtsSelection && <ValidationMessage name="debtsSelection" />}
          <div className="flex w-full flex-row justify-between">
            <span>Total</span>
            <span className="font-semibold">{total > 0 ? AmountFormatter.format(total) : '-'}</span>
          </div>
          <div className="flex w-full flex-row justify-between">
            <span className="text-sm">on a 7 year term</span>
            <span className="font-semibold text-sm">
              {total > 0 ? <CurrencyWithFrequency amount={schedule?.repaymentAmount} frequency="monthly" /> : '-'}
            </span>
          </div>
          <Divider className="text-grey-2" />
          <div className="flex w-full flex-row justify-between text-sm text-grey-4">
            <span>Consolidate up to</span>
            <span className="font-semibold">
              {maximumBorrowingLimit > 0 ? AmountFormatter.format(maximumBorrowingLimit) : '-'}
            </span>
          </div>
          <div className="flex w-full flex-row justify-between text-sm text-grey-4">
            <span>Interest rate</span>
            <span className="font-semibold">{interestRate > 0 ? `${interestRate}% p.a.` : '-'}</span>
          </div>
        </div>
        <div
          ref={benefitsRef}
          className={classNames(!areBenefitsCommon && selectedDebts.length >= 1 ? 'h-[175px]' : 'h-fit')}
        >
          {areBenefitsCommon ? null : (
            <div>
              {selectedDebts.length < 1 ? (
                <div className="p-4 bg-secondary-lighter-2 text-sm font-medium flex flex-row items-center justify-center w-full">
                  <div className="text-center">Choose debts to unlock benefits.</div>
                </div>
              ) : (
                <div
                  className={classNames(
                    !entry?.isIntersecting ? `fixed bottom-0 rounded-b-xl` : 'static w-full',
                    'bg-white'
                  )}
                  style={{ width: rect?.width }}
                >
                  <div className="p-4 bg-secondary-lighter-2 text-sm font-medium flex flex-row items-center justify-center">
                    <div className="flex flex-row items-center justify-between w-full">
                      <div>Nice picks! You&rsquo;ll unlock these</div>
                      <div
                        className={classNames(
                          swiperRef?.current?.swiper?.isLocked
                            ? 'hidden'
                            : 'flex flex-row gap-1 items-center text-grey-4 text-sm'
                        )}
                      >
                        Scroll{' '}
                        <IconV2 icon="ic:outline-chevron-right" className="align-text-bottom" width={16} height={16} />
                      </div>
                    </div>
                  </div>
                  <div className="pt-2">
                    <Benefits.Container ref={swiperRef}>
                      {benefits
                        .sort((a, b) => a.displayOrder - b.displayOrder)
                        .map(({ icon, children, iconClassName, display }, index) => (
                          <Benefits.Card key={index} icon={icon} iconClassName={iconClassName} display={display}>
                            {children}
                          </Benefits.Card>
                        ))}
                    </Benefits.Container>
                  </div>
                  <div className="px-4 py-2 text-xs text-grey-4">*Savings apply to 7-year term only.</div>
                </div>
              )}
            </div>
          )}
        </div>
      </Card>
      {areBenefitsCommon && <DebtConBenefitsRender benefits={benefits} />}
    </>
  );
};

import React from 'react';
import classNames from 'classnames';

import { PropsWithHTMLElement } from '../../types';

interface HelpTextInternalProps {
  children: React.ReactNode;
}

export type HelpTextProps = PropsWithHTMLElement<HelpTextInternalProps, 'small'>;

export const HelpText = ({ children, className, ...restProps }: HelpTextProps) => {
  return (
    <p className={classNames('text-grey-4 mb-2 flex items-center text-sm', className)} {...restProps}>
      {children}
    </p>
  );
};

import Link from 'next/link';
import {
  LoanPurposeDisplayNameEnum,
  loanStatusEmojiMapper,
  preQualifiedQuoteLabelMapper,
} from '@harmoney/api-interfaces';
import { ExtendedPreQualifiedQuote } from '@harmoney/redux';
import { Badge } from '@harmoney/ui-design-system';
import { DATE_FORMAT } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import { useHash } from '@mantine/hooks';
import dayjs from 'dayjs';

interface CustomerPreQualifiedQuoteListItemProps {
  quote: ExtendedPreQualifiedQuote;
}

export const CustomerPreQualifiedQuoteListItem = ({ quote }: CustomerPreQualifiedQuoteListItemProps) => {
  const [, setHash] = useHash();
  return (
    <>
      <li key={quote.id} className={`border-grey-2 grid grid-cols-10 p-4 text-sm`}>
        <div className="col-span-2 flex flex-row">
          <span
            title={`View Application ${quote.id}`}
            onClick={() => setHash(`pq/${quote.id}`)}
            className=" cursor-pointer text-secondary mt-1 ml-2"
          >
            {quote.id}
          </span>
        </div>
        <div className="col-span-1 ml-3">
          <span>Personal loan</span>
        </div>
        <div className="col-span-1 ml-3">
          <span>{quote.referral}</span>
        </div>
        <div className="col-span-1 ml-3">
          <span>{LoanPurposeDisplayNameEnum[quote.loanPurpose]}</span>
        </div>
        <div className="col-span-1 ml-4">
          <span>{formatCurrency(quote.requestedAmount)}</span>
        </div>
        <div className="col-span-1 ml-3">
          <span>{dayjs(quote.createdAt).format(DATE_FORMAT)}</span>
        </div>
        <div className="col-span-2 ml-2 flex flex-col gap-1">
          {quote.quoteStatus === 'QUOTE' && quote?.loanApplications?.length > 0 ? (
            // for each application link to the PQ show the application number
            quote?.loanApplications.map((application) => (
              <Link key={application.id} href={`/admin/details/?userId=${quote.userId}#applications/${application.id}`}>
                {application.businessKey} {loanStatusEmojiMapper[application.status]}
              </Link>
            ))
          ) : (
            <span>No applications linked</span>
          )}
        </div>
        <div className="col-span-1 ml-6">
          <Badge
            variant={quote?.quoteStatus === 'QUOTE' ? 'success' : 'tertiary'}
            label={preQualifiedQuoteLabelMapper[quote?.quoteStatus]}
          />
        </div>
      </li>
    </>
  );
};

import Link from 'next/link';
import { useEffect, useMemo, useState } from 'react';
import { TaskResponseDto } from '@harmoney/api-interfaces';
import { useAppSelector, useGetAllTasksQuery } from '@harmoney/redux';
import { Badge, Spinner } from '@harmoney/ui-design-system';
import { DATE_TIME_FORMAT, SOMETHING_WENT_WRONG } from '@harmoney/ui-utils';
import { ColumnDef, PaginationState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';

import { ActionCenterGroup, ActionControl } from '../customer-hub/ActionControl/ActionControl';
import { DataTable } from '../DataTable';

export const TaskDataTable = () => {
  const accessToken = useAppSelector((state) => state.accessToken.value);
  const userId = useAppSelector((state) => state.userId.value);
  const [globalFilter, setGlobalFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState<'All Tasks' | 'Unassigned Tasks' | 'My Tasks' | 'Completed Tasks'>(
    'All Tasks'
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });

  const {
    data: allTaskData,
    isError,
    isLoading,
  } = useGetAllTasksQuery(null, { refetchOnMountOrArgChange: true, skip: !userId || !accessToken });

  const [dataToRender, setDataToRender] = useState(allTaskData);

  useEffect(() => {
    if (allTaskData) setDataToRender(allTaskData);
  }, [allTaskData]);

  const columns = useMemo<ColumnDef<TaskResponseDto>[]>(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            <Link href={`/admin/task/details?taskId=${row.original.id}`} passHref>
              {row.original.id}
            </Link>
          </div>
        ),
      },
      {
        header: 'Task Name',
        accessorKey: 'name',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            <span>{row.original.name}</span>
          </div>
        ),
      },
      {
        header: 'Process Instance Key',
        accessorKey: 'name',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            <span>{row.original.processInstanceKey}</span>
          </div>
        ),
      },
      {
        header: 'Process Name',
        accessorKey: 'name',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            <span>{row.original.processName}</span>
          </div>
        ),
      },
      {
        header: 'Created At',
        accessorFn: (row) => dayjs(row.creationDate).format(DATE_TIME_FORMAT),
      },
      {
        header: 'Task State',
        accessorKey: 'taskState',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            <Badge
              variant={row.original.taskState === 'COMPLETED' ? 'success' : 'secondary'}
              label={capitalize(row.original.taskState)}
            />
          </div>
        ),
      },
    ],
    []
  );
  useEffect(() => {
    switch (statusFilter) {
      case 'All Tasks':
        setDataToRender(allTaskData);
        break;
      case 'Unassigned Tasks':
        setDataToRender(allTaskData?.filter((task) => !task.assignee));
        break;
      case 'My Tasks':
        setDataToRender(allTaskData?.filter((task) => task.assignee === userId));
        break;
      case 'Completed Tasks':
        setDataToRender(allTaskData?.filter((task) => task.taskState === 'COMPLETED'));
        break;
      default:
        break;
    }
  }, [allTaskData, statusFilter, userId]);

  const controlOptions: ActionCenterGroup[] = useMemo(() => {
    const filterOptions = [
      { id: '_task_0', label: 'All Tasks', value: 'All Tasks' },
      { id: '_task_1', label: 'Unassigned Tasks', value: 'Unassigned Tasks' },
      { id: '_task_2', label: 'My Tasks', value: 'My Tasks' },
      { id: '_task_3', label: 'Completed Tasks', value: 'Completed Tasks' },
    ] as const;

    return [
      {
        name: statusFilter,
        options: filterOptions.map((option) => ({
          label: option.label,
          value: option.value,
          onClick: () => setStatusFilter(option.value),
        })),
      },
    ];
  }, [statusFilter]);

  if (isError) {
    return <div className="m-3">{SOMETHING_WENT_WRONG}</div>;
  }

  if (isLoading) {
    return (
      <div className="align-center mt-32 flex justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col">
        <div className="col-span-7">
          {/* TODO the pageSize and pageCount and total need to improve */}
          {columns && dataToRender && (
            <DataTable
              title={<ActionControl actionsGroup={controlOptions} isHeader />}
              data={dataToRender}
              columns={columns}
              pageIndex={pageIndex}
              pageCount={1}
              pageSize={pageSize}
              total={3}
              setGlobalFilter={setGlobalFilter}
              globalFilter={globalFilter}
              setPagination={setPagination}
            />
          )}
        </div>
      </div>
    </>
  );
};

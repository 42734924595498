import { RiskCheckOperation } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const riskCheckOperationApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getRiskCheck: builder.query<RiskCheckOperation[], string>({
      query: (applicationId: string) => {
        return {
          url: '/risk-rule/operations',
          method: 'GET',
          params: {
            applicationId,
          },
        };
      },
    }),
  }),
});

export const { useGetRiskCheckQuery } = riskCheckOperationApi;

import { Card } from '@harmoney/ui-design-system';

interface QuoteIntroductionProps {
  preferredName: string;
  interestRate?: number;
  isDebtCon?: boolean;
}

export function QuoteIntroduction({ preferredName, interestRate, isDebtCon = false }: QuoteIntroductionProps) {
  return (
    <>
      <h1>
        Here&rsquo;s your <span className="text-primary">quote</span>, {preferredName}!
      </h1>

      {!isDebtCon && (
        <Card className="!p-4">
          <p>
            Fixed interest rate of <strong>{interestRate}%</strong>
          </p>
        </Card>
      )}
    </>
  );
}

import { ControlledInput, DatePicker } from '@harmoney/ui-design-system';
import { parseUTCAndConvertToLocalTime } from '@harmoney/ui-utils';

interface ContractualPaymentDateFieldsProps {
  contractualStartDate: Date;
  contractualEndDate: Date;
  register: any;
}

export const ContractualPaymentDateFields = ({
  contractualStartDate,
  contractualEndDate,
  register,
}: ContractualPaymentDateFieldsProps) => {
  return (
    <>
      <DatePicker
        className="mb-4"
        {...register('contractualPaymentDate')}
        label="Next contractual payment date (new)"
        selected={parseUTCAndConvertToLocalTime(contractualStartDate).toDate()}
        minDate={parseUTCAndConvertToLocalTime(contractualStartDate).toDate()}
        maxDate={parseUTCAndConvertToLocalTime(contractualEndDate).toDate()}
        onKeyDown={(e) => e.preventDefault()}
      />
      <ControlledInput type="number" className="mb-6" {...register('loanTerm')} isNumeric label="Number of months" />
    </>
  );
};

/**
 * The function `isTrue` checks if a given value is equal to either "true" or "Yes" and returns a
 * boolean value.
 * @param {string} value - The `value` parameter is a string that represents a boolean value. It can be
 * either "true" or "Yes".
 * @returns A boolean value is being returned.
 */
export const isBooleanStringTrue = (value: string): boolean => {
  const validTrueValues = ['true', 'yes'];
  return validTrueValues.includes(value?.toLowerCase?.());
};

/**
 * The function checks if a given value is equal to 'false' or 'No'.
 * @param {string} value - The `value` parameter is a string that represents a value that we want to
 * check if it is false.
 * @returns A boolean value indicating whether the input value is equal to either 'false' or 'No'.
 */
export const isBooleanStringFalse = (value: string): boolean => {
  const validFalseValues = ['false', 'no'];
  return validFalseValues.includes(value?.toLowerCase?.());
};

/**
 * The function checks if a given string is a valid boolean value.
 * @param {string} value - The `value` parameter is a string that represents a boolean value. `true`, `yes`, false`, and `no` are valid boolean values.
 * @returns The function `isValidBooleanString` returns a boolean value.
 */
export const isValidBooleanString = (value: string): boolean => {
  return isBooleanStringTrue(value) || isBooleanStringFalse(value);
};

/**
 * The function `convertStringToBoolean` converts a string value to a boolean value, throwing an error if the
 * string is not a valid boolean value.
 * @param {string} value - The `value` parameter is a string that represents a boolean value.
 * @returns The function `convertToBoolean` returns a boolean value.
 */
export const convertStringToBoolean = (value: string): boolean => {
  if (isBooleanStringTrue(value)) {
    return true;
  }
  if (isBooleanStringFalse(value)) {
    return false;
  }
  throw new Error(`The value ${value} is not a valid boolean value.`);
};

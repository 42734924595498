import { Alert, Dialog } from '@harmoney/ui-design-system';

import { SelectPersonalLoanPurposeForm } from '../SelectPersonalLoanPurposeForm';

interface LoanBalanceUnaffordableModalProps {
  isOpen: boolean;
  onModalOpen: () => void;
}

export const LoanBalanceUnaffordableModal = ({ isOpen, onModalOpen }: LoanBalanceUnaffordableModalProps) => {
  return (
    <Dialog
      title="Your quote has been updated"
      open={isOpen}
      onOpenChange={onModalOpen}
      modal
      contentClassName="max-h-[90vh] overflow-y-auto"
    >
      <div className="p-4">
        <p className="mb-6">
          As of today your current Harmoney loan balance has increased, which affects how much you can borrow.
        </p>
        <Alert variant="warning">
          <p>
            At this point you can&rsquo;t consolidate any of your debts. But you can borrow for something else instead.
          </p>
        </Alert>
        <SelectPersonalLoanPurposeForm
          label="What would you like to borrow for instead?"
          primaryCTAText="change"
          secondaryCTAText="cancel application"
          onSubmit={() => console.log('submit')}
          onCancel={() => console.log('cancel')}
        />
      </div>
    </Dialog>
  );
};

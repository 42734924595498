import { AmountFormatter } from '@harmoney/ui-utils';
import { camelCase, startCase } from 'lodash';

type Props = {
  label: string;
  value: string | number;
  format?: 'amount' | 'title-case' | 'none';
};

export const Field = ({ label, value, format = 'none' }: Props) => {
  const formattedValue = (value) => {
    switch (format) {
      case 'amount':
        return AmountFormatter.format(value);
      case 'title-case':
        return startCase(camelCase(value));
      default:
        return value;
    }
  };

  return (
    <span className="grid grid-cols-3">
      <span className="col-span-2">{label}</span>
      <span className="font-medium">{formattedValue(value)}</span>
    </span>
  );
};

import { LivingExpenseV2 } from '@harmoney/components';
import { useFriendlyURL } from '@harmoney/hooks';

import { CommonProps } from '../../common-props';

export const UpdateEssentialExpense = ({ taskId, completeTaskWithData, taskFriendlyURL }: CommonProps) => {
  const handleCancel = async () => {
    await completeTaskWithData({ taskId });
  };

  useFriendlyURL(taskFriendlyURL);

  return <LivingExpenseV2 taskId={taskId} completeTaskWithData={completeTaskWithData} onCancel={handleCancel} />;
};

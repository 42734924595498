import { ErrorMessage } from '@hookform/error-message';

export interface ValidationMessageProps {
  /**
   * Name of the field
   */
  name: string;
}

export const ValidationMessage = ({ name }: ValidationMessageProps) => {
  return (
    <ErrorMessage
      name={name}
      render={({ message }) => (
        <span role="alert" aria-live="assertive" className="leading-sm text-error mt-1 block text-sm">
          {message}
        </span>
      )}
    />
  );
};

import { Alert } from '@harmoney/ui-design-system';
import { AmountFormatter } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import { upperCase, upperFirst } from 'lodash';

export interface DebtDto {
  id: string;
  provider: string;
  networthSourceName: string;
  outstandingBalance: number;
}

interface DebtList {
  debts: DebtDto[];
  consolidatedAmount: number;
}

export const DebtList = ({ debts, consolidatedAmount }: DebtList) => (
  <Alert
    variant="light-info"
    title={`${AmountFormatter.format(consolidatedAmount)} of your debts will be paid directly to your providers`}
    className="sm:max-w-[20.5rem]"
  >
    {debts?.map((debt) => (
      <div key={debt.id} className="flex justify-between">
        <div className="flex">
          <span className="mr-2">
            {upperCase(debt.provider)} {upperFirst(debt.networthSourceName)}
          </span>
        </div>
        <div className="flex flex-col items-end">
          <span>{formatCurrency(debt.outstandingBalance)}</span>
        </div>
      </div>
    ))}
  </Alert>
);

import { VaultPaymentInformationDto } from '@harmoney/api-interfaces';
import { CollapsibleCard } from '@harmoney/ui-design-system';
import { amountOrNA, capitalizeTitle, DATE_FORMAT, valueOrNA } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import dayjs from 'dayjs';

import { Field } from '../shared';

interface Props {
  paymentInformationData: VaultPaymentInformationDto;
}

export const PaymentInformationDetails = ({ paymentInformationData }: Props) => {
  if (!paymentInformationData) return null;

  return (
    <CollapsibleCard title="Payment information" className="mb-6">
      <div className="grid grid-cols-2 px-4">
        <div className="border-r-grey-1 border-r-2 p-4">
          <Field>
            <span>First payment date:</span>
            <span>{dayjs(paymentInformationData?.firstPaymentDate).format(DATE_FORMAT) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>First payment amount:</span>
            <span>{amountOrNA(paymentInformationData?.firstPaymentAmount)}</span>
          </Field>
          <Field>
            <span>Recurring payment date:</span>
            <span>{dayjs(paymentInformationData?.recurringPaymentDate).format(DATE_FORMAT) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Recurring payment amount:</span>
            <span>
              {paymentInformationData?.recurringPaymentAmount
                ? formatCurrency(Number(paymentInformationData?.recurringPaymentAmount), 2)
                : '$0'}
            </span>
          </Field>
        </div>
        <div className="p-4">
          <Field>
            <span>Recurring payment frequency:</span>
            <span>{valueOrNA(capitalizeTitle(paymentInformationData?.recurringPaymentFrequency))}</span>
          </Field>
          <Field>
            <span>Monthly contractual payment date:</span>
            <span>{dayjs(paymentInformationData?.monthlyContractualPaymentDate).format(DATE_FORMAT) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Monthly contractual payment amount:</span>
            <span>{amountOrNA(paymentInformationData?.monthlyContractualPaymentAmount)}</span>
          </Field>
          <Field>
            <span>Pay off amount:</span>
            <span>
              {paymentInformationData?.payOffAmount
                ? formatCurrency(Number(paymentInformationData?.payOffAmount), 2)
                : '$0'}
            </span>
          </Field>
          <Field>
            <span>Pay ID:</span>
            <span>{valueOrNA(paymentInformationData?.payId)}</span>
          </Field>
        </div>
      </div>
    </CollapsibleCard>
  );
};

import { emptySplitApi } from './empty-api';

const statementOfAccountApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getStatementOfAccountPDF: builder.query<
      string,
      { loanApplicationId: string; requestedStartDate: string; requestedEndDate: string }
    >({
      query: ({ loanApplicationId, requestedStartDate, requestedEndDate }) => ({
        url: `/statement-of-account/${loanApplicationId}/document-url`,
        method: 'GET',
        params: {
          requestedStartDate,
          requestedEndDate,
        },
        responseHandler: 'text',
      }),
    }),
  }),
});

export const { useLazyGetStatementOfAccountPDFQuery } = statementOfAccountApi;

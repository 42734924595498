import { useState } from 'react';
import { LoanApplicationRepaymentDetailDto, RepaymentScheduleResponseDto } from '@harmoney/api-interfaces';
import { Button, DatePicker, RadioGroup, Spinner, useFormContext } from '@harmoney/ui-design-system';
import { calculateOneOffDifference } from '@harmoney/ui-utils';
import dayjs from 'dayjs';

import { getRepaymentFrequencyOptions } from '../utils';

import { RepaymentInfo } from './RepaymentInfo';

interface RepaymentSettingProps {
  repaymentDetail: LoanApplicationRepaymentDetailDto;
  repaymentScheduleData: RepaymentScheduleResponseDto;
  onRepaymentScheduleChange: (repaymentScheduleData: RepaymentScheduleResponseDto) => void;
}

export const RepaymentSetting = ({
  repaymentDetail,
  repaymentScheduleData,
  onRepaymentScheduleChange,
}: RepaymentSettingProps) => {
  const { register, watch } = useFormContext();
  const [changeRepaymentPlan, setChangeRepaymentPlan] = useState(false);

  const RepaymentDescription = () => {
    return repaymentDetail?.prefill?.startDate ? (
      <p className="text-grey-4 text-sm">
        We&rsquo;ve aligned your repayment plan with your pay days. You can change the start date and frequency below.
      </p>
    ) : (
      <p className="text-grey-4 text-sm">We recommend aligning your repayments with your pay days.</p>
    );
  };

  const renderRepaymentInfo = (repaymentScheduleData: RepaymentScheduleResponseDto) => {
    if (!repaymentScheduleData) return null;

    const oneOffIncreasedAmount = calculateOneOffDifference(
      repaymentScheduleData.firstRepayment.amount,
      repaymentScheduleData.recurringRepayment.amount
    );

    return oneOffIncreasedAmount > 0 ? (
      <>
        <RepaymentInfo
          date={repaymentScheduleData.firstRepayment.date}
          amount={repaymentScheduleData.firstRepayment.amount}
          oneOffIncreasedAmount={oneOffIncreasedAmount}
          isFirstRepayment
          showDueDay
        />
        <RepaymentInfo
          date={repaymentScheduleData.recurringRepayment.date}
          amount={repaymentScheduleData.recurringRepayment.amount}
          oneOffIncreasedAmount={oneOffIncreasedAmount}
          repaymentFrequency={watch('repaymentFrequency')}
        />
      </>
    ) : (
      <RepaymentInfo
        date={repaymentScheduleData.firstRepayment.date}
        amount={repaymentScheduleData.firstRepayment.amount}
        repaymentFrequency={watch('repaymentFrequency')}
        showDueDay
      />
    );
  };

  if (!repaymentDetail) return <Spinner />;

  return (
    <>
      <h3 className="font-medium text-base">Your repayments</h3>

      <RepaymentDescription />

      {(changeRepaymentPlan || !repaymentDetail?.prefill?.startDate) && (
        <>
          <DatePicker
            {...register('repaymentStartDate', { onChange: onRepaymentScheduleChange })}
            label={repaymentDetail?.prefill?.startDate ? 'Start  on' : 'When do you want your repayments to start?'}
            placeholderText={repaymentDetail?.prefill?.startDate ? '' : 'Please select a date'}
            minDate={dayjs(repaymentDetail.startDate).toDate()}
            maxDate={dayjs(repaymentDetail.endDate).toDate()}
            flexiblePopperPlacement
            showDueDay={!!repaymentDetail?.prefill?.startDate}
            onKeyDown={(e) => e.preventDefault()}
          />
          {watch('repaymentStartDate') && !dayjs(watch('repaymentStartDate')).isSame(dayjs(), 'day') && (
            <RadioGroup
              {...register('repaymentFrequency', { onChange: onRepaymentScheduleChange })}
              label="Repeats every"
              options={getRepaymentFrequencyOptions(watch('repaymentStartDate'))}
              className="mt-4"
            />
          )}
        </>
      )}

      {renderRepaymentInfo(repaymentScheduleData)}

      {repaymentDetail?.prefill?.startDate && !changeRepaymentPlan && (
        <div className="text-center mt-6 mb-2">
          <Button variant="outline-secondary" onClick={() => setChangeRepaymentPlan(true)}>
            Change repayment plan
          </Button>
        </div>
      )}
    </>
  );
};

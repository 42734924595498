import { useEffect } from 'react';
import { Address, Frequency, NetworthSourceEnum, ResidentialStatusEnum } from '@harmoney/api-interfaces';
import { useFriendlyURL } from '@harmoney/hooks';
import {
  useAppSelector,
  useGetUserProfileQuery,
  useGetUserQuery,
  useSaveExpensesMutation,
  useSubmitIncomeMutation,
  useUpdateAddressMutation,
  useUpdateUserProfileMutation,
} from '@harmoney/redux';
import { eventAnalytics, FINANCIALPROFILE_LIVING_SITUATION_PROVIDED } from '@harmoney/ui-app-shell';
import {
  AmountFrequency,
  ArrowCircleRightIcon,
  Button,
  Card,
  ControlledInput,
  Form,
  Label,
  RadioSelectSelector,
  Select,
  ToggleGroup,
  useForm,
} from '@harmoney/ui-design-system';
import {
  errors,
  frequencyOptions,
  otherLivingSituationOptions,
  primaryLivingSituationOptions,
  relationshipStatusOptions,
  stringToBool,
  toggleYesNoOptions,
} from '@harmoney/ui-utils';
import { AssetAndLiabilityFrequencyEnum, RelationshipStatusEnum } from '@prisma/client';
import { isEmpty, toNumber } from 'lodash';
import { z } from 'zod';

import { CommonProps } from '../../common-props';

const formSchema = z
  .object({
    livingType: z
      .object({
        primary: z.string(),
        secondary: z.string(),
      })
      .refine((data) => data.primary, {
        path: ['primary'],
      })
      .refine((data) => data.primary, {
        message: errors.defaultRequiredField,
        path: ['secondary'],
      }),

    relationshipStatus: z.string().optional(),

    hasDependants: z.string().optional(),
    numberOfDependants: z.coerce
      .number()
      .min(0, { message: 'Please enter a valid number' })
      .max(30, { message: 'Please enter a number less than or equal to 30' })
      .optional(),
    hasChildSupportReceived: z.string().optional(),
    childSupportReceivedAmount: z.number({ invalid_type_error: errors.defaultValidAmount }).optional(),
    childSupportReceivedFrequency: z.string().optional(),
    hasChildSupportPaid: z.string().optional(),
    childSupportPaidAmount: z.number({ invalid_type_error: errors.defaultValidAmount }).optional(),
    childSupportPaidFrequency: z.string().optional(),
    hasChildSupportDeducted: z.string().optional(),

    declaredHousingAmount: z.number({ invalid_type_error: errors.defaultValidAmount }).nullable().optional(),
    housingFrequency: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.livingType.primary !== ResidentialStatusEnum.HOME_OWNER && data.declaredHousingAmount === null)
        return false;
      return true;
    },
    {
      message: 'Please enter a valid amount',
      path: ['declaredHousingAmount'],
    }
  )
  .refine(
    (data) => {
      if (data.declaredHousingAmount >= 0 && isEmpty(data.housingFrequency)) return false;
      return true;
    },
    {
      message: errors.defaultRequiredFrequency,
      path: ['housingFrequency'],
    }
  )
  .refine(
    (data) => {
      if (!isEmpty(data.livingType.primary) && isEmpty(data.relationshipStatus)) return false;
      return true;
    },
    {
      message: errors.defaultRequiredField,
      path: ['relationshipStatus'],
    }
  )
  .refine(
    (data) => {
      if (!isEmpty(data.livingType.primary) && isEmpty(data.hasDependants)) return false;
      return true;
    },
    {
      message: errors.defaultRequiredField,
      path: ['hasDependants'],
    }
  )
  .refine(
    (data) => {
      if (data.hasDependants === 'Yes' && data.numberOfDependants === 0) return false;
      return true;
    },
    {
      message: 'Please enter a number greater than 0',
      path: ['numberOfDependants'],
    }
  )
  .refine(
    (data) => {
      if (data.hasDependants === 'Yes' && isEmpty(data.hasChildSupportReceived)) return false;
      return true;
    },
    {
      message: errors.defaultRequiredField,
      path: ['hasChildSupportReceived'],
    }
  )
  .refine(
    (data) => {
      if (data.hasDependants === 'Yes' && isEmpty(data.hasChildSupportPaid)) return false;
      return true;
    },
    {
      message: errors.defaultRequiredField,
      path: ['hasChildSupportPaid'],
    }
  )
  .refine(
    (data) => {
      if (data.hasChildSupportReceived === 'Yes' && data.childSupportReceivedAmount === 0) return false;
      return true;
    },
    {
      message: errors.defaultValidAmount,
      path: ['childSupportReceivedAmount'],
    }
  )
  .refine(
    (data) => {
      if (data.childSupportReceivedAmount > 0 && isEmpty(data.childSupportReceivedFrequency)) return false;
      return true;
    },
    {
      message: errors.defaultRequiredFrequency,
      path: ['childSupportReceivedFrequency'],
    }
  )
  .refine(
    (data) => {
      if (data.hasChildSupportPaid === 'Yes' && data.childSupportPaidAmount === 0) return false;
      return true;
    },
    {
      message: errors.defaultValidAmount,
      path: ['childSupportPaidAmount'],
    }
  )
  .refine(
    (data) => {
      if (data.childSupportPaidAmount > 0 && isEmpty(data.childSupportPaidFrequency)) return false;
      return true;
    },
    {
      message: errors.defaultRequiredFrequency,
      path: ['childSupportPaidFrequency'],
    }
  )
  .refine(
    (data) => {
      if (data.hasChildSupportPaid === 'Yes' && isEmpty(data.hasChildSupportDeducted)) return false;
      return true;
    },
    {
      message: errors.defaultRequiredField,
      path: ['hasChildSupportDeducted'],
    }
  );

export function Household({ taskId, completeTask, taskFriendlyURL }: CommonProps) {
  const userId = useAppSelector((state) => state.userId.value);

  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    shouldUnregister: true,
    defaultValues: {
      livingType: {
        primary: '',
        secondary: '',
      },
      relationshipStatus: '',
      hasDependants: '',
      numberOfDependants: undefined,
      hasChildSupportReceived: '',
      childSupportReceivedAmount: null,
      childSupportReceivedFrequency: '',
      hasChildSupportPaid: '',
      childSupportPaidAmount: null,
      childSupportPaidFrequency: '',
      hasChildSupportDeducted: '',
      declaredHousingAmount: null,
      housingFrequency: '',
    },
  });

  const {
    register,
    watch,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;
  const formChanges = watch();

  const { data: userData } = useGetUserQuery();
  const { data: userProfile, refetch: refetchUserProfile, isSuccess } = useGetUserProfileQuery();
  const [saveExpenses] = useSaveExpensesMutation();
  const [submitIncome] = useSubmitIncomeMutation();
  const [updateAddress] = useUpdateAddressMutation();
  const [updateUserProfile] = useUpdateUserProfileMutation();

  const getNetWorthSourceId = (livingType: ResidentialStatusEnum) => {
    switch (livingType) {
      case ResidentialStatusEnum.RENTING:
        return NetworthSourceEnum.EXPENSE_RENT_ID;
      case ResidentialStatusEnum.BOARDER:
        return NetworthSourceEnum.EXPENSE_BOARDER_ID;
      case ResidentialStatusEnum.LIVE_WITH_PARENTS:
        return NetworthSourceEnum.EXPENSE_LIVE_WITH_PARENTS_ID;
      case ResidentialStatusEnum.GOVERNMENT_HOUSING:
        return NetworthSourceEnum.EXPENSE_GOVERNMENT_HOUSING_ID;
      case ResidentialStatusEnum.COMPANY_HOUSING:
        return NetworthSourceEnum.EXPENSE_COMPANY_HOUSING_ID;
    }
  };

  useEffect(() => {
    if (isSuccess && !userProfile) {
      refetchUserProfile();
    }
  }, [userData, refetchUserProfile, isSuccess, userProfile]);

  const handleSubmit = async (data) => {
    if (!userProfile) await refetchUserProfile();

    let activeAddressId: string;
    let addressData: Address;

    const currentAddress = userProfile?.addresses?.find((address) => address.isCurrent);
    let updatedCurrentAddress;
    if (currentAddress) {
      activeAddressId = currentAddress.id;
      updatedCurrentAddress = {
        ...currentAddress,
        residentialStatus: data.livingType.primary,
      };
    }

    let updatedUserProfile;
    if (data?.hasDependants === 'Yes') {
      updatedUserProfile = {
        numberOfDependants: toNumber(data?.numberOfDependants),
        relationshipStatus: data?.relationshipStatus as RelationshipStatusEnum,
      };
    } else {
      updatedUserProfile = {
        numberOfDependants: 0,
        relationshipStatus: data?.relationshipStatus as RelationshipStatusEnum,
      };
    }

    await updateUserProfile({ data: updatedUserProfile });

    await updateAddress({ id: activeAddressId, data: updatedCurrentAddress });

    const expensesData = [];

    const networthSourceId = getNetWorthSourceId(data?.livingType.primary);

    if (data?.livingType.primary !== ResidentialStatusEnum.HOME_OWNER) {
      expensesData.push({
        networthSourceId,
        addressId: currentAddress.id,
        frequency: data?.housingFrequency as Frequency,
        declaredAmount: data?.declaredHousingAmount,
        address: addressData,
      });
    }

    if (data?.hasChildSupportReceived === 'Yes') {
      await submitIncome({
        taskId,
        incomes: [
          {
            networthSourceId: NetworthSourceEnum.INCOME_CHILD_SUPPORT_ID,
            declaredAmount: data?.childSupportReceivedAmount,
            frequency: data?.childSupportReceivedFrequency as AssetAndLiabilityFrequencyEnum,
          },
        ],
      });
    }

    if (data?.hasChildSupportPaid === 'Yes') {
      expensesData.push({
        networthSourceId: NetworthSourceEnum.EXPENSE_CHILD_SUPPORT_ID,
        declaredAmount: data?.childSupportPaidAmount,
        frequency: data?.childSupportPaidFrequency as AssetAndLiabilityFrequencyEnum,
        deductedFromIncome: stringToBool(data?.hasChildSupportDeducted),
      });
    }

    await saveExpenses({
      taskId,
      expenses: expensesData,
    });

    eventAnalytics.track(FINANCIALPROFILE_LIVING_SITUATION_PROVIDED, {
      userid_str: userId,
      taskid_str: taskId,
    });

    completeTask({ taskId });
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      <h1>
        Tell us about your <span className="text-primary">household</span>
      </h1>
      <Form form={form} onSubmit={handleSubmit}>
        <Card>
          <RadioSelectSelector
            name="livingType"
            label="What is your living situation?"
            radioOptions={primaryLivingSituationOptions}
            selectOptions={otherLivingSituationOptions}
          />
        </Card>
        {formChanges?.livingType?.primary && formChanges?.livingType?.primary !== ResidentialStatusEnum.HOME_OWNER && (
          <Card>
            <Label className="mb-2">
              How much do <span className="font-medium">you</span> contribute towards
              {formChanges?.livingType?.primary === ResidentialStatusEnum.RENTING ? ' rent' : ' housing'}?
            </Label>
            <AmountFrequency
              register={register}
              inputKey="declaredHousingAmount"
              selectKey="housingFrequency"
              name="renting-housing"
              options={frequencyOptions}
            />
          </Card>
        )}
        {formChanges?.livingType?.primary && (
          <>
            <Card>
              <Select
                {...register('relationshipStatus')}
                label="What is your relationship status?"
                options={relationshipStatusOptions}
              />
            </Card>
            <Card>
              <ToggleGroup
                {...register('hasDependants')}
                label="Do you have children or dependants?"
                options={toggleYesNoOptions}
              />
              {formChanges?.hasDependants === 'Yes' && (
                <ControlledInput
                  {...register('numberOfDependants')}
                  type="number"
                  label="How many children or dependants do you have?"
                  className="mt-4"
                />
              )}
            </Card>
          </>
        )}
        {formChanges?.hasDependants === 'Yes' && (
          <>
            <Card>
              <Label className="mb-2">
                Do you <span className="font-medium">receive</span> child support?
              </Label>
              <ToggleGroup {...register('hasChildSupportReceived')} options={toggleYesNoOptions} />
              {formChanges?.hasChildSupportReceived === 'Yes' && (
                <div className="mt-4">
                  <Label className="mb-2">
                    How much child support do <span className="font-medium">you</span> receive?
                  </Label>
                  <AmountFrequency
                    register={register}
                    inputKey="childSupportReceivedAmount"
                    selectKey="childSupportReceivedFrequency"
                    name="receive-child-support"
                    options={frequencyOptions}
                  />
                </div>
              )}
            </Card>
            <Card>
              <Label className="mb-2">
                Do you <span className="font-medium">pay</span> child support?
              </Label>
              <ToggleGroup {...register('hasChildSupportPaid')} options={toggleYesNoOptions} />
              {formChanges?.hasChildSupportPaid === 'Yes' && (
                <>
                  <div className="mt-4">
                    <Label className="mb-2">
                      How much child support do <span className="font-medium">you</span> pay?
                    </Label>
                    <AmountFrequency
                      register={register}
                      inputKey="childSupportPaidAmount"
                      selectKey="childSupportPaidFrequency"
                      name="pay-child-support"
                      options={frequencyOptions}
                    />
                  </div>
                  <ToggleGroup
                    {...register('hasChildSupportDeducted')}
                    label="Is your child support deducted from your income?"
                    options={toggleYesNoOptions}
                    className="mt-4"
                  />
                </>
              )}
            </Card>
          </>
        )}
        <Button
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          isLoading={isSubmitting || isSubmitSuccessful}
          hasShadow
          type="submit"
        >
          Continue
        </Button>
      </Form>
    </>
  );
}

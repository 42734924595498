import React, { useEffect, useState } from 'react';
import { convertFromMonthlyAmount, formatCurrency, frequencyOptionsWithYear } from '@harmoney/utilities';
import { Icon } from '@iconify/react';
import { IncomeAndExpenseFrequencyEnum } from '@prisma/client';

import { UncontrolledSelect } from '../Select';

export interface ExpenseSummaryProps {
  title?: string;
  totalAmount: number;
  sharedAmount: number;
  defaultFrequency?: IncomeAndExpenseFrequencyEnum;
  onChange?: (value: string) => void;
  className?: string;
}

export const ExpenseSummary: React.FC<ExpenseSummaryProps> = ({
  title,
  totalAmount,
  sharedAmount,
  onChange,
  className,
  defaultFrequency = IncomeAndExpenseFrequencyEnum.monthly,
}: ExpenseSummaryProps) => {
  const [frequency, setFrequency] = useState<IncomeAndExpenseFrequencyEnum>(defaultFrequency);
  const [innerTotalAmount, setInnerTotalAmount] = useState<number>(totalAmount);
  const [innerSharedAmount, setInnerSharedAmount] = useState<number>(sharedAmount);

  useEffect(() => {
    setInnerTotalAmount(totalAmount);
    setInnerSharedAmount(sharedAmount);
    handleChange(frequency);
  }, [totalAmount, sharedAmount]);

  const handleChange = (value: string) => {
    setFrequency(value as IncomeAndExpenseFrequencyEnum);
    setInnerTotalAmount(convertFromMonthlyAmount(totalAmount, value as IncomeAndExpenseFrequencyEnum));
    setInnerSharedAmount(convertFromMonthlyAmount(sharedAmount, value as IncomeAndExpenseFrequencyEnum));
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className={className}>
      <p>{title}</p>
      <div className="grid sm:grid-cols-3">
        <div className="mb-2 grid grid-cols-2 sm:col-span-2 sm:mb-0">
          <div className="flex items-center">
            <Icon icon="material-symbols:house-outline-rounded" className="text-grey-3" width={24} />
            <p className="ml-2 flex flex-col">
              <span className="text-sm">Total cost</span>
              <span className="font-medium">{formatCurrency(innerTotalAmount)}</span>
            </p>
          </div>
          <div className="flex items-center">
            <Icon icon="material-symbols:person-outline" className="text-grey-3" width={24} />
            <p className="ml-2 flex flex-col">
              <span className="text-sm">Your share</span>
              <span className="font-medium">{formatCurrency(innerSharedAmount)}</span>
            </p>
          </div>
        </div>
        <UncontrolledSelect options={frequencyOptionsWithYear} value={frequency} onChange={handleChange} />
      </div>
    </div>
  );
};

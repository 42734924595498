import React, { ErrorInfo } from 'react';
import { Spinner } from '@harmoney/ui-design-system';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // after resetting password, redirect user to index page and do auto login
    if (error.message?.indexOf("Can't get tokens without the CodeVerifier") === 0) {
      window.location.href = '/?auto_login=true';
      return;
    }
    console.error(error.message);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <div>
          <Spinner></Spinner>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

import React from 'react';
import classNames from 'classnames';

export interface InformationBoxItem {
  /**
   * the icon displayed on the left
   */
  icon?: React.ReactNode;
  /**
   * the text displayed on the right
   */
  text: string;
}

export interface InformationBoxProps {
  /**
   * Title on the top of the box
   */
  title?: string;
  /**
   * Information displayed as a list
   */
  items?: InformationBoxItem[];
}

export const InformationBox = ({ title, items }: InformationBoxProps) => {
  return (
    <div className="flex flex-col rounded-xl bg-white">
      {title && <div className="p-4 text-sm font-medium">{title}</div>}
      {items && items.length !== 0 && (
        <>
          {items.map(({ icon, text }, index) => (
            <div
              key={index}
              className={classNames(
                'leading-lg flex items-center p-4 text-sm font-normal',
                title || (!title && index !== 0) ? 'border-t-grey-2 border-t' : ''
              )}
            >
              {icon && <div className="mr-2 flex items-center">{icon}</div>}
              {text && <p className="leading-base text-sm">{text}</p>}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

declare global {
  interface Window {
    Intercom: (action: IntercomActionEnum) => void;
  }
}

export enum IntercomActionEnum {
  SHOW = 'show',
  HIDE = 'hide',
  SHOW_NEW_MESSAGING = 'showNewMessage',
}

export const openChatWindow = (action = IntercomActionEnum.SHOW) => {
  if (!window?.Intercom) {
    console.warn('Intercom is not loaded');
    return;
  }
  return window.Intercom(action);
};

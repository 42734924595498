import { useEffect } from 'react';

export const ScrollEventHandler = ({ divRef, onScroll, children }) => {
  const handleScroll = () => {
    const divElement = divRef.current;
    if (divElement) {
      const isBottom = Math.floor(Math.ceil(divElement.scrollTop) + divElement.offsetHeight) >= divElement.scrollHeight;
      onScroll(isBottom);
    }
  };

  useEffect(() => {
    const divElement = divRef.current;
    if (divElement) {
      divElement.addEventListener('scroll', handleScroll);
      return () => {
        divElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [divRef, onScroll]);

  return <>{children}</>;
};

import { Fragment } from 'react';
import { essentialExpenseMapper, FinancialProfileDto, NetworthSourceEnum } from '@harmoney/api-interfaces';
import { Card, CurrencyWithFrequency, Divider, Label } from '@harmoney/ui-design-system';
import { RelationshipStatusEnum } from '@prisma/client';

type FinancialProfileExpenseType = FinancialProfileDto['expenses'][number];

export const Expenses = ({ financialSummary }: { financialSummary: FinancialProfileDto }) => {
  const relationshipStatus = financialSummary?.livingSituation?.relationshipStatus;

  const isMarriedOrDefacto =
    relationshipStatus === RelationshipStatusEnum.MARRIED || relationshipStatus === RelationshipStatusEnum.DE_FACTO;

  const hasTotalOrOtherEssentialExpense = financialSummary?.expenses.some(
    (expense) =>
      expense.networthSourceId === NetworthSourceEnum.EXPENSE_TOTAL_ESSENTIAL_EXPENSES_ID ||
      expense.networthSourceId === NetworthSourceEnum.EXPENSE_OTHER_REGULAR_EXPENSES_ID
  );

  const shouldShowExpenseSharing =
    (isMarriedOrDefacto && hasTotalOrOtherEssentialExpense) || !hasTotalOrOtherEssentialExpense;

  return (
    <Card className="!p-0">
      <div className="flex items-center justify-between">
        <Label className="p-4 text-lg font-medium">Essential expenses</Label>
      </div>
      <Divider className="text-grey-2 m-0 p-0" />

      {financialSummary?.expenses.map((expense) => (
        <Fragment key={`expenses-${expense.id}`}>
          {shouldShowExpenseSharing ? (
            <SharedExpense
              expense={expense}
              key={expense.id}
              hasTotalOrOtherEssentialExpense={hasTotalOrOtherEssentialExpense}
            />
          ) : (
            <SoloExpense expense={expense} key={expense.id} />
          )}
          <Divider className="text-grey-1 my-0 px-4" />
        </Fragment>
      ))}
    </Card>
  );
};

const SharedExpense = ({
  expense,
  hasTotalOrOtherEssentialExpense,
}: {
  expense: FinancialProfileExpenseType;
  hasTotalOrOtherEssentialExpense: boolean;
}) => {
  const {
    networthSourceId,
    otherExpenseType,
    declaredAmount,
    frequencyForTotalAmount,
    frequency,
    declaredTotalAmount,
    isExpenseShared,
  } = expense;

  return (
    <>
      {networthSourceId === NetworthSourceEnum.EXPENSE_OTHER_REGULAR_EXPENSES_ID && !declaredAmount ? (
        <NoOtherRegularExpense networthSourceId={networthSourceId} />
      ) : (
        <div className="flex flex-col p-4">
          <Label>
            {networthSourceId === NetworthSourceEnum.EXPENSE_NOT_LISTED_ID
              ? otherExpenseType
              : essentialExpenseMapper[networthSourceId]}
          </Label>

          <ul className="mt-2 text-sm">
            <li className="flex justify-between">
              <span>{hasTotalOrOtherEssentialExpense ? 'Total' : 'Total cost'}</span>
              <CurrencyWithFrequency
                amount={isExpenseShared ? declaredTotalAmount : declaredAmount}
                frequency={isExpenseShared ? frequencyForTotalAmount : frequency}
                className="font-medium"
              />
            </li>
            <li className="flex justify-between">
              <span>Your share</span>
              {isExpenseShared && declaredAmount > 0 ? (
                <CurrencyWithFrequency amount={declaredAmount} frequency={frequency} className="font-medium" />
              ) : (
                '-'
              )}
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

const SoloExpense = ({ expense }: { expense: FinancialProfileExpenseType }) => {
  const { networthSourceId, otherExpenseType, declaredAmount, frequency } = expense;

  return (
    <>
      {networthSourceId === NetworthSourceEnum.EXPENSE_OTHER_REGULAR_EXPENSES_ID && !declaredAmount ? (
        <NoOtherRegularExpense networthSourceId={networthSourceId} />
      ) : (
        <div className="flex justify-between p-4">
          <Label>
            {networthSourceId === NetworthSourceEnum.EXPENSE_NOT_LISTED_ID
              ? otherExpenseType
              : essentialExpenseMapper[networthSourceId]}
          </Label>
          {declaredAmount > 0 ? (
            <CurrencyWithFrequency amount={declaredAmount} frequency={frequency} className="font-medium" />
          ) : (
            '-'
          )}
        </div>
      )}
    </>
  );
};

const NoOtherRegularExpense = ({ networthSourceId }: { networthSourceId: NetworthSourceEnum }) => (
  <div className="flex justify-between p-4">
    <Label>{essentialExpenseMapper[networthSourceId]}</Label>
    <span className="font-medium">No</span>
  </div>
);

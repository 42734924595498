import { useMemo } from 'react';
import { Alert, Card, Divider, Label } from '@harmoney/ui-design-system';
import { formatCurrency, LiabilityProvider } from '@harmoney/utilities';
import { capitalize } from 'lodash';

export function HarmoneyPaymentDetail({ debts }) {
  const totalAmount = useMemo(() => {
    return debts?.reduce((acc, curr) => {
      return acc + curr.outstandingBalance;
    }, 0);
  }, [debts]);
  return (
    <>
      <Card className="!p-1">
        <div className="flex items-center justify-between">
          <Label className="p-4 text-lg font-medium">Your Harmoney loans</Label>
        </div>
        <Divider className="text-grey-2 m-0 p-0" />
        <Alert variant="info" className="m-4">
          <p>
            The below loans totaling <span className="font-medium">{formatCurrency(totalAmount)}</span> will be
            consolidated into your new loan.
          </p>
        </Alert>
        {debts?.map((debt) => {
          const { provider, outstandingBalance } = debt;
          const loanPurpose = provider.replace(LiabilityProvider.Harmoney, '').trim();
          const borderClass = 'border-grey-2 m-4 mb-4 rounded-md border  border-gray-300';
          return (
            <>
              <div key={debt.id} className={borderClass}>
                <div className="flex items-center justify-between p-3">
                  <Label>Loan purpose</Label>
                  <span className="font-medium">{capitalize(loanPurpose)}</span>
                </div>
                <Divider className="text-grey-2 m-0 p-2" />

                <div className="flex items-center justify-between p-3">
                  <Label>Amount we retain</Label>
                  <span className="font-medium">{formatCurrency(outstandingBalance)}</span>
                </div>
              </div>
            </>
          );
        })}
      </Card>
    </>
  );
}

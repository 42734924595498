import { IncomeAndExpenseFrequencyEnum as Frequency } from '@prisma/client';

import { frequencyConversionMap } from './convert-to-monthly-amount';

const getConversionFactor = (frequency: Frequency): number => {
  return frequencyConversionMap[frequency] || 1;
};

/**
 * Converts a monthly amount to a different frequency for financial profile calculations.
 * @param amount - The monthly amount.
 * @param frequency - The frequency to convert to.
 * @returns The converted amount.
 */
export const convertFromMonthlyAmount = (amount: number, frequency: Frequency): number => {
  const conversionFactor = getConversionFactor(frequency);
  return amount / conversionFactor;
};

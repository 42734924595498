import { Fragment } from 'react';
import { FinancialProfileDto } from '@harmoney/api-interfaces';
import { Card, Divider, Label } from '@harmoney/ui-design-system';
import { AmountFormatter, amountOrDash, FrequencyFormatter } from '@harmoney/ui-utils';
import { capitalize } from 'lodash';

export const Expenses = ({ financialSummary }: { financialSummary: FinancialProfileDto }) => {
  const removeIPayFor = (sentence: string): string => {
    const phrase = 'i pay for';
    if (sentence.includes(phrase)) {
      sentence = sentence.replace(phrase, '').trim();
    }
    return sentence;
  };

  return (
    <Card className="!p-0">
      <div className="flex items-center justify-between">
        <Label className="p-4 text-lg font-medium">Spending</Label>
      </div>
      <Divider className="text-grey-2 m-0 p-0" />

      {financialSummary?.expenses.map(
        ({
          id,
          networthSourceName,
          declaredAmount,
          frequency,
          expenseSharedWith,
          otherExpenseType,
          isExpenseShared,
        }) => (
          <Fragment key={`expenses-${id}`}>
            {expenseSharedWith === null && (
              <div className="flex flex-row  items-center justify-between p-4">
                {otherExpenseType ? (
                  <Label>{capitalize(removeIPayFor(otherExpenseType))}</Label>
                ) : (
                  <Label>{capitalize(removeIPayFor(networthSourceName))}</Label>
                )}
                {declaredAmount > 0 ? (
                  <span className="font-medium">
                    {AmountFormatter.format(declaredAmount)}/{FrequencyFormatter(frequency)}
                  </span>
                ) : (
                  <span className="font-medium">{amountOrDash(declaredAmount)}</span>
                )}
              </div>
            )}

            {expenseSharedWith && (
              <div className="flex flex-row  items-center justify-between p-4">
                <Label className="text-base">Share expenses</Label>
                <span className="font-medium">{isExpenseShared ? 'Yes' : 'No'}</span>
              </div>
            )}

            <Divider className="text-grey-1 m-0 px-4" />
          </Fragment>
        )
      )}
    </Card>
  );
};

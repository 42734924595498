import { LoanProductPurposeDto, SaveLoanPurposeDto } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const loanPurposesApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getLoanPurposes: builder.query<LoanProductPurposeDto[], string>({
      query: (id) => ({
        url: `/loan-products/${id}/loan-purposes`,
        method: 'GET',
      }),
    }),
    updateLoanPurposes: builder.mutation<
      { id: string }[],
      {
        applicationId: string;
        loanPurposes: SaveLoanPurposeDto[];
      }
    >({
      query: ({ applicationId, loanPurposes }) => ({
        url: `/loan-applications/${applicationId}/loan-purposes`,
        method: 'POST',
        body: loanPurposes,
      }),
      invalidatesTags: ['Application'],
    }),
    getLoanPurposesForPersonalLoan: builder.query<LoanProductPurposeDto[], null>({
      query: () => ({
        url: `/loan-products/loan-purposes-for-personal-loan`,
        method: 'GET',
      }),
    }),
    deleteLoanPurpose: builder.mutation<
      void,
      {
        applicationId: string;
        loanApplicationPurposeId: string;
      }
    >({
      query: ({ applicationId, loanApplicationPurposeId }) => ({
        url: `/loan-applications/${applicationId}/loan-purposes/${loanApplicationPurposeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Application'],
    }),
  }),
});

export const {
  useGetLoanPurposesQuery,
  useUpdateLoanPurposesMutation,
  useGetLoanPurposesForPersonalLoanQuery,
  useDeleteLoanPurposeMutation,
} = loanPurposesApi;

import { RepaymentFrequency } from '@harmoney/api-interfaces';
import { formatCurrency } from '@harmoney/utilities';
import { isUndefined } from 'lodash';
import { z } from 'zod';

export const validationMessages = {
  singlePurposeError: (lowerLimit: number, upperLimit: number) =>
    lowerLimit === upperLimit
      ? `You can borrow a maximum of ${formatCurrency(upperLimit)}`
      : `The borrowing amount should be between ${formatCurrency(lowerLimit)} and ${formatCurrency(upperLimit)}`,
  upperLimitError: (maxExtraLimitAmount: number) =>
    `You can only borrow an extra ${formatCurrency(maxExtraLimitAmount)}`,
  lowerLimitError: (lowerLimit: number) => `Borrow at least ${formatCurrency(lowerLimit)} in total`,
  greaterThan0Error: 'Please enter an amount greater than 0',
  secondaryPurposeError: 'Please select an option',
  secondaryPurposeAmountError: 'Please enter an amount greater than 0 or delete this loan purpose',
  repaymentFrequencyError: 'Please select your repayment frequency',
  termOptionError: 'Please select a term option',
};

export const defaultValues = { termInMonths: 84, repaymentFrequency: RepaymentFrequency.MONTHLY };

export const createFormSchema = (lowerLimit: number, upperLimit: number) => {
  return z
    .object({
      hasAddedAdditionalPurpose: z.boolean().nullable().optional(),
      primaryPurposeAmount: z.coerce
        .number({
          invalid_type_error: validationMessages.greaterThan0Error,
          required_error: validationMessages.greaterThan0Error,
        })
        .min(1, { message: validationMessages.greaterThan0Error }),
      secondaryPurposeId: z
        .string()
        .min(1, { message: validationMessages.secondaryPurposeError })
        .nullable()
        .optional(),
      secondaryPurposeAmount: z.coerce.number().nullable().optional(),
      repaymentFrequency: z.nativeEnum(RepaymentFrequency, {
        invalid_type_error: validationMessages.repaymentFrequencyError,
      }),
      termInMonths: z.coerce
        .number({
          invalid_type_error: validationMessages.termOptionError,
          required_error: validationMessages.termOptionError,
        })
        .refine((data) => [36, 60, 84].includes(data), { message: validationMessages.termOptionError }),
    })
    .superRefine((data, ctx) => {
      const totalAmount = data.primaryPurposeAmount + (data.secondaryPurposeAmount || 0);
      if (!isUndefined(data.primaryPurposeAmount)) {
        if (data.hasAddedAdditionalPurpose) {
          if (
            data.secondaryPurposeAmount === null ||
            data.secondaryPurposeAmount === undefined ||
            data.secondaryPurposeAmount === 0
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['secondaryPurposeAmount'],
              message: validationMessages.secondaryPurposeAmountError,
            });
            return;
          }
          if (totalAmount > upperLimit) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['secondaryPurposeAmount'],
              message: validationMessages.upperLimitError(upperLimit - data.primaryPurposeAmount),
            });
          }
          if (totalAmount < lowerLimit) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['secondaryPurposeAmount'],
              message: validationMessages.lowerLimitError(lowerLimit),
            });
          }
        } else {
          if (totalAmount < lowerLimit || totalAmount > upperLimit) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['primaryPurposeAmount'],
              message: validationMessages.singlePurposeError(lowerLimit, upperLimit),
            });
          }
        }
      }
    });
};

// Example usage: This formSchema is for type reference only
const formSchema = createFormSchema(2000, 70000);

export type FormSchema = z.infer<typeof formSchema>;

export type LoanPurposeFormType = 'primary' | 'secondary';

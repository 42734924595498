import React, { useMemo } from 'react';
import {
  AffordabilityModelDetailDto,
  NetworthSourceDisplayNameEnum,
  PromptName,
  SelectedSourceEnum,
} from '@harmoney/api-interfaces';
import { useGetAIResultQuery, useLazyAnalyseBankStatementsQuery } from '@harmoney/redux';
import { Button, Spinner, Tooltip } from '@harmoney/ui-design-system';
import { formatNumber } from '@harmoney/utilities';
import { Icon } from '@iconify/react';
import { Prisma } from '@prisma/client';
import classNames from 'classnames';

import { AIFeedback } from '../../AIFeedback';

import { RowData, rowIsGrey } from './affordability-utils';
import { getFinalValue, getMonthlyAffordabilityValue, valueIsSelected } from './affordability-utils';
type Props = {
  affordabilityData: AffordabilityModelDetailDto;
  rootData: Prisma.JsonValue;
};

export const IncomeTableV2 = ({ affordabilityData, rootData }: Props) => {
  const incomeItems = affordabilityData?.items?.incomes;
  const finalIncome = formatNumber(affordabilityData?.overview?.totalIncome, 2);
  const [analyseBankStatement, { isFetching: isAnalyseFetching }] = useLazyAnalyseBankStatementsQuery();
  const {
    data: aiResult,
    refetch,
    isLoading: isAIResultLoading,
    isFetching: isAIResultFetching,
  } = useGetAIResultQuery(
    { promptId: PromptName.BANK_STATEMENTS_INCOME_SUMMARY, entityId: rootData?.['loanApplicationId'] },
    { skip: !rootData?.['loanApplicationId'] || rootData?.['aggregationResult']?.status !== 'success' }
  );
  const handleAnalyseBankStatement = async () => {
    const analyseResult = await analyseBankStatement(rootData?.['loanApplicationId']);
    if (analyseResult.data) {
      await refetch();
    }
  };
  const rowsData: RowData[] = useMemo(() => {
    const createRowData = (
      title: string,
      netWorthSource: NetworthSourceDisplayNameEnum,
      isSharedIncome: boolean = false
    ): RowData => {
      const getValue = (source: SelectedSourceEnum) =>
        getMonthlyAffordabilityValue(netWorthSource, source, incomeItems, isSharedIncome);
      const formatValue = (source: SelectedSourceEnum) => formatNumber(getValue(source), 2);
      const isValueSelected = (source: SelectedSourceEnum, isShareable: boolean) =>
        valueIsSelected(source, netWorthSource, incomeItems, isShareable);
      return {
        title,
        declared: {
          value: formatValue(SelectedSourceEnum.DECLARED),
          isSelected: isValueSelected(SelectedSourceEnum.DECLARED, isSharedIncome),
        },
        bankStatement: {
          value: formatValue(SelectedSourceEnum.BANK_STATEMENT),
          isSelected: isValueSelected(SelectedSourceEnum.BANK_STATEMENT, isSharedIncome),
        },
        override: {
          value: formatValue(SelectedSourceEnum.CO_OVERRIDE),
          isSelected: isValueSelected(SelectedSourceEnum.CO_OVERRIDE, isSharedIncome),
        },
        final: {
          value: formatNumber(getFinalValue(netWorthSource, incomeItems, isSharedIncome), 2),
          isSelected: true,
        },
      };
    };
    return [
      createRowData('Household', NetworthSourceDisplayNameEnum.INCOME_HOUSEHOLD_NAME),
      createRowData('Salary / Wages', NetworthSourceDisplayNameEnum.INCOME_SALARY_WAGES_NAME),
      createRowData('Self Employed', NetworthSourceDisplayNameEnum.INCOME_SELF_EMPLOYED_NAME),
      createRowData('Rental Income', NetworthSourceDisplayNameEnum.INCOME_RENT_NAME),
      createRowData('Child Support', NetworthSourceDisplayNameEnum.INCOME_CHILD_SUPPORT_NAME),
      createRowData('Benefit', NetworthSourceDisplayNameEnum.INCOME_BENEFIT_NAME),
      createRowData('Pension', NetworthSourceDisplayNameEnum.INCOME_PENSION_NAME),
      createRowData('Interest / Dividends', NetworthSourceDisplayNameEnum.INCOME_INTEREST_DIVIDEND_NAME),
      createRowData('Other Income', NetworthSourceDisplayNameEnum.INCOME_OTHER_NAME),
      createRowData('Income Decreases', NetworthSourceDisplayNameEnum.INCOME_CHANGE_NAME),
    ];
  }, [incomeItems]);
  return (
    <>
      <table className="border-grey-2 table-fixed border w-full">
        <thead>
          <tr className="divide-grey-2 bg-grey-1 divide-x">
            <th className="w-1/6">Income</th>
            <th className="w-1/6">Declared</th>
            <th className="w-1/6">Bank Statement</th>
            <th className="w-1/6">
              <div className="flex flex-row gap-2 items-center justify-center">
                <Icon icon="mdi:stars" className="text-secondary" width={16} />
                <span>AI Analyse</span>
                {aiResult?.prompt && (
                  <Tooltip>
                    Prompt:
                    <br />
                    <span className="text-left">{aiResult?.prompt.replace(/\.(\s|$)/g, '. \n$1')}</span>
                  </Tooltip>
                )}
              </div>
            </th>
            <th className="w-1/6">Co-Override</th>
            <th className="w-1/6">Final</th>
          </tr>
        </thead>
        <tbody>
          {rowsData.map((row, index) => (
            <tr key={row.title} className="divide-grey-2 border-grey-2 divide-x border">
              <td className={classNames('bg-grey-1 px-2 py-1 font-bold', { 'text-grey-2': rowIsGrey(row) })}>
                {row.title}
              </td>
              <td className={classNames('px-2 text-right', { 'bg-grey-2': !row.declared.value })}>
                <span className={classNames({ 'font-normal': row.declared.isSelected })}>
                  {row.declared.value ?? ''}
                </span>
              </td>
              <td className={classNames('px-2 text-right', { 'bg-grey-2': !row.bankStatement.value })}>
                <span className={classNames({ 'font-normal': row.bankStatement.isSelected })}>
                  {row.bankStatement.value ?? ''}
                </span>
              </td>
              {!aiResult && !isAIResultLoading ? (
                <>
                  {index === 0 && (
                    <td rowSpan={rowsData.length} className="px-2 text-center">
                      <div className="flex flex-col items-center gap-4">
                        <span>
                          {isAnalyseFetching || isAIResultFetching
                            ? "This won't take long"
                            : 'Analyse these bank statement values with AI'}
                        </span>
                        {isAIResultFetching || isAnalyseFetching ? (
                          <Spinner size="small" />
                        ) : (
                          <Button
                            variant="accent"
                            size="small"
                            className="!min-w-fit"
                            onClick={handleAnalyseBankStatement}
                          >
                            Analyse
                          </Button>
                        )}
                      </div>
                    </td>
                  )}
                </>
              ) : (
                <td className={classNames('px-2 text-right', { 'bg-grey-2': !row.ai })}>
                  <span>{row.ai?.value ?? ''} </span>
                </td>
              )}
              <td className={classNames('px-2 text-right', { 'bg-grey-2': !row.override.value })}>
                <span className={classNames({ 'font-normal': row.override.isSelected })}>
                  {row.override.value ?? ''}
                </span>
              </td>
              <td className={classNames('px-2 text-right', { 'bg-grey-2': !row.final.value })}>
                <span className={classNames({ 'font-bold': row.final.isSelected })}>
                  {Number(row.final.value) > 0 && row.title === 'Income Decreases'
                    ? `-${row.final.value}`
                    : row.final.value}
                </span>
              </td>
            </tr>
          ))}
          <tr>
            <td />
            <td />
            <td />
            <td />
            <td className="bg-grey-1 border-x-grey-2 border-x px-2 py-1 font-bold">Total Income (Net) </td>
            <td className="px-2 text-right font-bold">{finalIncome}</td>
          </tr>
        </tbody>
      </table>
      {aiResult && <AIFeedback traceId={aiResult.traceId} />}
    </>
  );
};

import { useEffect, useState } from 'react';
import { DebtConsolidationLiabilityDto } from '@harmoney/api-interfaces';
import { Checkbox, CurrencyWithFrequency } from '@harmoney/ui-design-system';
import { convertToMonthlyAmount, formatCurrency } from '@harmoney/utilities';
import classNames from 'classnames';

import { formatProviderName } from '../../../financial-profile/FinancialConfirmation/components/Debts/util';

type Props = {
  debt: DebtConsolidationLiabilityDto;
  maximumBorrowingLimit: number;
  form: any;
};

export const DebtItemToConsolidate = ({ form, debt, maximumBorrowingLimit }: Props) => {
  const {
    register,
    watch,
    formState: { errors },
    clearErrors,
  } = form;
  const [isOverLimit, setIsOverLimit] = useState(false);

  const total = watch().totalToConsolidate;

  useEffect(() => {
    // get the total amount of debts selected, if the total + the current debt is over the limit, disable the checkbox for other debts
    const selected = Object(watch().debtsSelection);
    if (!selected[debt.liability.id] && total + debt.liability.outstandingBalance > maximumBorrowingLimit) {
      setIsOverLimit(true);
      return;
    }
    if (total <= maximumBorrowingLimit) {
      setIsOverLimit(false);
      return;
    }
    setIsOverLimit(isOverLimit);
  }, [debt.liability.id, debt.liability.outstandingBalance, isOverLimit, maximumBorrowingLimit, total, watch]);

  return (
    <div key={debt.liability?.id} className={classNames(isOverLimit && 'text-grey-4', 'flex flex-col gap-2 p-4')}>
      <div className="flex flex-row justify-between items-center gap-4">
        <div className="flex flex-col gap-1">
          <span>
            {formatProviderName(
              debt?.liability?.provider,
              debt?.liability?.otherProvider,
              debt?.liability?.networthSource?.name
            )}
          </span>
          <div className="text-grey-4 text-sm flex flex-row gap-2">
            <span>{debt?.liability?.interestRate.toString()}% p.a.</span>
            <span>|</span>
            <CurrencyWithFrequency
              className="text-sm text-grey-4"
              amount={convertToMonthlyAmount(debt.liability.repaymentAmount, debt.liability.frequency)}
              frequency="monthly"
            />
          </div>
        </div>
        <Checkbox
          alignLabel="left"
          label={formatCurrency(debt.liability.outstandingBalance)}
          className={classNames(isOverLimit && '![&>label]:text-grey-4', 'font-medium')}
          key={debt.liability.id}
          checked={watch().debtsSelection[debt.liability.id]}
          {...register(`debtsSelection.${debt.liability.id}`, {
            onChange: () => {
              if (errors.debtsSelection) {
                clearErrors('debtsSelection');
              }
            },
          })}
          disabled={isOverLimit}
          displayValidationMessage={false}
        />
      </div>
    </div>
  );
};

import Image from 'next/image';
import { BranchRecord } from '@harmoney/api-interfaces';
import { useBranch } from '@harmoney/hooks';
import { IconV2, TextLink } from '@harmoney/ui-design-system';

type RatingEntry = {
  link: string;
  rate: string;
  reviewCount?: number;
};

type RatingLinks = {
  google: RatingEntry;
  shopperApproved: RatingEntry;
};

export const RatingLinks: BranchRecord<RatingLinks> = {
  AU: {
    google: {
      link: 'https://www.google.com/maps/place/Harmoney/@-33.864967,151.2077854,17z/data=!3m1!4b1!4m6!3m5!1s0x6b12aef325cf341b:0x610c5a1f03a4ecd1!8m2!3d-33.864967!4d151.2077854!16s%2Fg%2F11cjpkpmy2?entry=ttu',
      rate: '4.7/5',
      reviewCount: 1524,
    },
    shopperApproved: {
      link: 'https://www.shopperapproved.com/reviews/www.harmoney.com.au',
      rate: '4.8/5',
      reviewCount: 18204,
    },
  },
  NZ: {
    google: {
      link: 'https://www.google.com/maps/place/Harmoney/@-36.8433579,174.7587419,17z/data=!3m1!5s0x6d0d47f9e15215dd:0x37e91d645e101ba7!4m8!3m7!1s0x6d0d47fbaaf22327:0x1e915fc3ea28303b!8m2!3d-36.8433622!4d174.7613168!9m1!1b1!16s%2Fg%2F11b6gd5_y2?entry=ttu',
      rate: '4.7/5',
    },
    shopperApproved: {
      link: 'https://www.shopperapproved.com/reviews/www.harmoney.co.nz',
      rate: '4.8/5',
    },
  },
};

const StarRating = () => {
  const branch = useBranch();
  return (
    <div className="mb-6 flex items-center justify-between px-8">
      <span className="font-medium">Our star rating</span>
      <span className="flex items-center gap-4">
        <TextLink
          href={RatingLinks[branch]?.google.link}
          target="_blank"
          rel="noopener noreferrer"
          alignIcon="start"
          icon={<IconV2 icon="logos:google-icon" width={22} />}
        >
          {RatingLinks[branch]?.google.rate}
        </TextLink>
        <TextLink target="_blank" href={RatingLinks[branch]?.shopperApproved.link} rel="noopener noreferrer">
          <span className="flex items-center">
            <Image alt="Shopper Approved Logo" src="/assets/images/shopper-approved.svg" width={40} height={32} />
            <span>{RatingLinks[branch]?.shopperApproved.rate}</span>
          </span>
        </TextLink>
      </span>
    </div>
  );
};
export default StarRating;

import { Player } from '@lottiefiles/react-lottie-player';

import { GraphIcon } from '../../Icon';

export const LottieCalculation = () => {
  return (
    <div className="flex flex-col items-center">
      <div className="relative">
        <Player
          autoplay
          loop
          controls={false}
          src="/assets/lotties/monetization.json"
          style={{ height: '200px', width: '200px' }}
        />
        <GraphIcon size="large" className="absolute top-[42%] left-[42%]" />
      </div>
    </div>
  );
};

import { SaveLiabilitiesDto } from '@harmoney/api-interfaces';
import { AssetAndLiability, CreditFileLiabilities, NetworthSource } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const liabilityApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    saveLiabilities: builder.mutation<void, SaveLiabilitiesDto>({
      query: (data) => {
        return {
          url: `/financial-profile/liabilities`,
          method: 'POST',
          body: data,
        };
      },
    }),
    getConsolidatedLiabilities: builder.query<CreditFileLiabilities, void>({
      query: () => {
        return {
          url: `/financial-profile/liabilities/consolidated`,
          method: 'GET',
        };
      },
    }),
    getCreditFileLiabilities: builder.query<CreditFileLiabilities, string>({
      query: (financialProfileId) => {
        return {
          url: `/financial-profile/${financialProfileId}/credit-file-liabilities`,
          method: 'GET',
        };
      },
    }),
    updatePostApprovalConsolidatedLiabilities: builder.mutation<
      void,
      {
        [key: string]: boolean;
      }
    >({
      query: (ids) => {
        return {
          url: `/financial-profile/liabilities/update-post-approval-consolidated-debts`,
          method: 'PUT',
          body: ids,
        };
      },
    }),
    getEligibleDebtsForConsolidation: builder.query<
      (Partial<AssetAndLiability> & {
        networthSource: Partial<NetworthSource>;
      })[],
      string
    >({
      query: (financialProfileId) => {
        return {
          url: `/financial-profile/liabilities/eligible-debts-for-consolidation`,
          method: 'GET',
          params: {
            financialProfileId,
          },
        };
      },
    }),
    getLiabilities: builder.query<
      (AssetAndLiability & {
        networthSource: NetworthSource;
      })[],
      string
    >({
      query: (financialProfileId) => {
        return {
          url: `/financial-profile/${financialProfileId}/liabilities`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useSaveLiabilitiesMutation,
  useGetConsolidatedLiabilitiesQuery,
  useUpdatePostApprovalConsolidatedLiabilitiesMutation,
  useGetEligibleDebtsForConsolidationQuery,
  useGetLiabilitiesQuery,
  useGetCreditFileLiabilitiesQuery,
} = liabilityApi;

import { forwardRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { ValidationMessage } from '../ValidationMessage';

import { Select, SelectProps } from './Select';

export interface ControlledSelectProps extends SelectProps {
  /**
   * Validation message
   */
  validationMessage?: string;
  displayValidation?: boolean;
}

export const ControlledSelect = forwardRef<HTMLDivElement, ControlledSelectProps>(
  (
    { className, validationMessage, name, displayValidation = true, ...restProps }: ControlledSelectProps,
    forwardRef: React.Ref<HTMLDivElement>
  ) => {
    const {
      control,
      formState: { errors },
    } = useFormContext();

    const {
      field: { ref, ...selectProps },
    } = useController({
      name,
      control,
      defaultValue: '',
    });

    return (
      <div className={className} ref={forwardRef}>
        <Select ref={ref} data-testid={name} displayValidation={false} {...restProps} {...selectProps} />
        {validationMessage && !errors[name] ? (
          <small className="text-grey-4 leading-lg block text-sm">{validationMessage}</small>
        ) : (
          displayValidation && <ValidationMessage name={name} />
        )}
      </div>
    );
  }
);

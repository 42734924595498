import { errors } from '@harmoney/ui-utils';
import { z } from 'zod';

export const defaultValues = {
  adjustedInterestRate: null,
  customInterestRate: null,
};

export const createFormSchema = (discountPercentageLimit: { min: number; max: number }) =>
  z
    .object({
      adjustedInterestRate: z
        .string({
          required_error: errors.defaultRequiredField,
          invalid_type_error: errors.defaultRequiredField,
        })
        .min(1, { message: errors.defaultRequiredField }),
      customInterestRate: z
        .number({
          required_error: errors.requiredField('Interest rate'),
          invalid_type_error: errors.requiredField('Interest rate'),
        })
        .gt(discountPercentageLimit?.min, {
          message: `Interest rate cannot be set below or equal to ${discountPercentageLimit?.min}%`,
        })
        .lt(discountPercentageLimit?.max, {
          message: `Interest rate cannot be set above ${discountPercentageLimit?.max}%`,
        })
        .nullable()
        .optional(),
    })
    .refine(
      (data) => {
        if (data.adjustedInterestRate === 'custom' && data.customInterestRate === null) {
          return false;
        }
        return true;
      },
      {
        message: errors.requiredField('Interest rate'),
        path: ['customInterestRate'],
      }
    );

import {
  FRANKIE_ONFIDO_CONFIGURATION_AU,
  FRANKIE_ONFIDO_CONFIGURATION_NZ,
} from '../FrankieSmartUIOnfido/frankie-onfido-config';

import { FrankieVevoStyle } from './frankie-vevo-style';

// Reference: https://assets.frankiefinancial.io/onboarding/v4/phrases.json
const phrases = {
  document: {
    type_passport: {
      number: 'Document Number',
    },
  },
  passport_input_screen: {
    title: 'What are your passport details?',
  },
  document_select: {
    title: 'Got your Passport ready?',
    subtitle: '',
    hint_message: 'Choose which you’d like to provide.',
    footer_text: 'Your Passport must be valid and not expired.',
  },
  name_input_screen: {
    title: 'What is your full name?',
    middle_name_label: 'Middle Name',
  },
};

export const FRANKIE_VEVO_CONFIGURATION_AU = {
  ...FRANKIE_ONFIDO_CONFIGURATION_AU,
  injectedCss: FrankieVevoStyle,
  documentTypes: [
    { type: 'PASSPORT', label: 'Passport (AUS)', acceptedCountries: 'ALL', idExpiry: true },
    {
      type: 'PASSPORT',
      label: 'Foreign Passport (with AUS Visa)',
      acceptedCountries: 'ALL',
      idExpiry: true,
    },
  ],
  phrases: {
    ...FRANKIE_ONFIDO_CONFIGURATION_AU.phrases,
    ...phrases,
  },
};

export const FRANKIE_VEVO_CONFIGURATION_NZ = {
  ...FRANKIE_ONFIDO_CONFIGURATION_NZ,
  idScanVerification: false,
};

export enum CreditAuditSections {
  requirementsAndObjectives = 'Requirements & Objectives',
  eligibilityIdentityAndFraudMitigation = 'Eligibility, identity & fraud mitigation',
  creditRisk = 'Credit Risk',
  affordability = 'Affordability',
  responsibleLending = 'Responsible Lending',
  additionalEnquiries = 'Additional Enquiries',
  cORecommendation = 'CO Recommendation',
}

export enum CreditAuditQuestions {
  requirementsAndObjectivesMet = "The customer's requirements & objectives are met",
  whyThisAnswer = 'Why did you choose this answer (can select multiple)',
  eligibilityMet = 'The customer is eligible to apply',
  happyToLend = 'Before looking at exact UMI, I would be happy to lend to the borrower',
  affordable = 'The loan is affordable',
  responsibleLendingMet = 'The loan will not put the customer into financial hardship',
  additionalEnquiriesToggle = 'Is there additional information that might have caused a different system decision?',
  additionalEnquiriesTags = 'What additional data might have helped? (can select multiple)',
  additionalEnquiriesNotes = 'What additional enquiries would you have made?',
  decision = 'Based on the information I have, my recommendation is:',
  decisionBasis = 'On what basis is this recommendation?',
  mightHaveApprovedIf = 'I might have approved if there was:',
  wouldHaveApprovedFor = 'I would have approved for:',
  maxOffer = 'My maximum offer would have been:',
  newTagsSuggestions = 'Suggested new tags:',
  tagsToBeRemoved = 'Suggested tags to be removed',
  notes = 'Other notes',
}

import { ResponseResult } from '@harmoney/api-interfaces';
import { Address, Prisma, UserProfile } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const userProfileApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserProfile: builder.query<UserProfile & { addresses: Address[] }, void>({
      query: () => ({
        url: `/user-profile/customer`,
        method: 'GET',
      }),
    }),
    getUserProfileForAdmin: builder.query<UserProfile & { addresses: Address[]; residencyStatus: string }, string>({
      query: (id) => ({
        url: `/user-profile/admin/${id}`,
        method: 'GET',
      }),
    }),
    updateUserProfile: builder.mutation<ResponseResult, { data: Prisma.UserProfileUpdateInput }>({
      query: ({ data }) => ({
        url: `/user-profile`,
        method: 'PUT',
        body: data,
      }),
    }),
    updateAddress: builder.mutation<ResponseResult, { id: string; data: Address }>({
      query: ({ id, data }) => ({
        url: `/user-profile/address/${id}`,
        method: 'PUT',
        body: data,
      }),
    }),
    getUserProfileAddress: builder.query<Address, string>({
      query: (id) => ({
        url: `/user-profile/${id}/address`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetUserProfileQuery,
  useGetUserProfileForAdminQuery,
  useLazyGetUserProfileForAdminQuery,
  useGetUserProfileAddressQuery,
  useUpdateAddressMutation,
  useUpdateUserProfileMutation,
} = userProfileApi;

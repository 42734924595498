import { Fragment, useState } from 'react';
import { Alert, Button, Dialog, Divider } from '@harmoney/ui-design-system';
import { Icon } from '@iconify/react';

const benefits = [
  {
    icon: 'ic:outline-savings',
    children: <p>Interest is calculated daily, so the faster you pay off your loan, the more interest you save.</p>,
  },
  {
    icon: 'ic:outline-credit-card',
    children: <p>You can make extra payments easily, check your dashboard.</p>,
  },
  {
    icon: 'ic:money-off',
    children: <p>There are no fees for paying off your loan early!</p>,
  },
];

export const InterestSavings = () => {
  const [isInterestSavingsOpen, setIsInterestSavingsOpen] = useState(false);

  return (
    <>
      <Alert variant="tip" title="The sooner you repay your loan, the less interest you'll pay.">
        <>
          <p className="text-sm">Interest is calculated daily, so paying extra whenever you can saves on interest!</p>
          <Button
            onClick={() => setIsInterestSavingsOpen(true)}
            variant="text"
            className="text-sm font-medium"
            alignIcon="start"
          >
            Learn more
          </Button>
        </>
      </Alert>

      <Dialog
        title="The sooner you repay your loan, the less interest you'll pay."
        open={isInterestSavingsOpen}
        onOpenChange={() => setIsInterestSavingsOpen(!isInterestSavingsOpen)}
        modal
      >
        <div className="flex flex-col mb-4">
          <ul className="p-4 flex flex-col">
            {benefits.map(({ icon, children }, index) => (
              <Fragment key={index}>
                <li className="flex my-4">
                  <span className="mr-4">
                    <Icon icon={icon} className="text-secondary-lighter-1" width={24} height={24} />
                  </span>
                  {children}
                </li>
                {index !== benefits.length - 1 && <Divider className="text-grey-1" />}
              </Fragment>
            ))}
          </ul>

          <Divider className="text-grey-1" />

          <Button
            variant="outline-secondary"
            onClick={() => setIsInterestSavingsOpen(false)}
            className="my-4 self-center"
          >
            Got it
          </Button>
        </div>
      </Dialog>
    </>
  );
};

import { useMemo } from 'react';
import { AmountInput, Button, ControlledInput } from '@harmoney/ui-design-system';

export const AssetItem = ({ code, index, formData, ...props }) => {
  const amountControl = ['property_land', 'vehicle', 'savings', 'shares_bonds_funds', 'boat', 'other_assets'];
  const assetNotListedNameControl = ['other_assets'];

  const controls = useMemo(() => {
    const controls = [];

    if (assetNotListedNameControl.includes(code)) {
      controls.push(
        <ControlledInput
          {...props.register(`assets.${code}[${index}].assetName`)}
          key={`assetNotListedNameControl-${code}-${index}`}
          type="text"
          className="mb-4"
          label="What kind of asset do you have?"
        />
      );
    }

    if (amountControl.includes(code)) {
      controls.push(
        <AmountInput
          className="mb-4"
          key={`amount-${code}-${index}`}
          {...props.register(`assets.${code}[${index}].declaredAmount`)}
          label="What is the value?"
        />
      );
    }

    if (index !== 0) {
      controls.push(
        <span className="w-100 mx-4 mb-3 flex justify-end">
          <Button
            size="small"
            variant="text"
            onClick={() => props.removeItem(code, index)}
            className="inline-flex items-center"
          >
            Delete
          </Button>
        </span>
      );
    }

    return controls;
  }, [amountControl, assetNotListedNameControl]);

  return controls.length > 0 ? (
    <div className="border-grey-3 border-1 mx-4 mb-3 rounded-lg	border border-inherit p-6 pb-4">{controls}</div>
  ) : null;
};

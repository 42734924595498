import { ExpenseDto, NetworthSourceEnum } from '@harmoney/api-interfaces';
import { stringToBool } from '@harmoney/ui-utils';
import { IncomeAndExpenseFrequencyEnum } from '@prisma/client';

import { FormSchema } from './form-config';

const buildExpenseData = (
  data: FormSchema,
  config: { source: keyof FormSchema; networthSourceId: NetworthSourceEnum; hasOtherEssentialExpenses?: boolean }
): ExpenseDto => {
  const { source, networthSourceId } = config;
  const expenseData = data[source];
  const isExpenseShared = stringToBool(expenseData.isExpenseShared);

  if (networthSourceId === NetworthSourceEnum.EXPENSE_OTHER_REGULAR_EXPENSES_ID && !config.hasOtherEssentialExpenses) {
    return {
      id: expenseData.id,
      networthSourceId,
      declaredAmount: 0,
    };
  }

  return {
    id: expenseData.id,
    networthSourceId,
    isExpenseShared,
    declaredAmount: isExpenseShared ? expenseData.withSharedExpense?.declaredAmount : expenseData.declaredTotalAmount,
    frequency: isExpenseShared
      ? (expenseData.withSharedExpense?.frequency as IncomeAndExpenseFrequencyEnum)
      : (expenseData.frequencyForTotalAmount as IncomeAndExpenseFrequencyEnum),
    declaredTotalAmount: isExpenseShared ? expenseData.declaredTotalAmount : null,
    frequencyForTotalAmount: isExpenseShared
      ? (expenseData.frequencyForTotalAmount as IncomeAndExpenseFrequencyEnum)
      : null,
  };
};

export const buildTotalEssentialExpenseData = (data: FormSchema): ExpenseDto => {
  return buildExpenseData(data, {
    source: 'totalEssentialExpenses',
    networthSourceId: NetworthSourceEnum.EXPENSE_TOTAL_ESSENTIAL_EXPENSES_ID,
  });
};

export const buildOtherRegularExpenseData = (data: FormSchema, hasOtherEssentialExpenses = false): ExpenseDto => {
  return buildExpenseData(data, {
    source: 'otherEssentialExpenses',
    networthSourceId: NetworthSourceEnum.EXPENSE_OTHER_REGULAR_EXPENSES_ID,
    hasOtherEssentialExpenses,
  });
};

import { DirectDebitPlanParamsDto } from '@harmoney/api-interfaces';
import { ArrowCircleRightIcon, Button, Dialog, Label } from '@harmoney/ui-design-system';
import { formatFrequency } from '@harmoney/utilities';
import { capitalize } from 'lodash';

interface UpdateDirectDebitPlanModalProps {
  isConfirmModalOpen: boolean;
  setIsConfirmModalOpen: (isOpen: boolean) => void;
  onModalSubmit: () => void;
  isSubmitting: boolean;
  directDebitPlan: DirectDebitPlanParamsDto;
}

export const UpdateDirectDebitPlanModal: React.FC<UpdateDirectDebitPlanModalProps> = ({
  isConfirmModalOpen,
  setIsConfirmModalOpen,
  onModalSubmit,
  isSubmitting,
  directDebitPlan,
}) => (
  <Dialog
    open={isConfirmModalOpen}
    onOpenChange={() => setIsConfirmModalOpen(!isConfirmModalOpen)}
    title="Update direct debit plan"
    modal
  >
    <div className="p-4">
      <div className="text-left text-base font-medium">
        <Label className="mb-4">Are you sure you want to update the payment plan?</Label>
        <Label>Updated to:</Label>
      </div>

      <ul className="my-4">
        <li className="my-4 grid grid-cols-2">
          <span>Next payment:</span>
          <span className="font-medium">{directDebitPlan.paymentDate}</span>
        </li>
        <li className="grid grid-cols-2">
          <span>Payment frequency:</span>
          <span className="font-medium">{capitalize(formatFrequency(directDebitPlan.paymentFrequency))}</span>
        </li>
      </ul>

      <div className="shrink-1 flex w-full flex-col items-center justify-start gap-2 sm:flex-col md:flex-row-reverse flex-wrap">
        <Button
          onClick={onModalSubmit}
          type="submit"
          variant="primary"
          className="sm:!min-w-full md:!min-w-fit md:!max-w-fit"
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          isLoading={isSubmitting}
          hasShadow
        >
          Update
        </Button>
        <Button
          onClick={() => setIsConfirmModalOpen(false)}
          variant="secondary"
          disabled={isSubmitting}
          className="!min-w-fit !max-w-fit"
        >
          Cancel
        </Button>
      </div>
    </div>
  </Dialog>
);

import { BankStatementFilesDto } from '@harmoney/api-interfaces';
import { useLazyGetBankStatementFileUrlQuery } from '@harmoney/redux';
import { Button, CollapsibleCard } from '@harmoney/ui-design-system';
import { booleanToYesNo, compareDatesFromNowInDays, DATE_TIME_FORMAT, RETRIEVED_ON } from '@harmoney/ui-utils';
import { BankStatementOperationStatus } from '@prisma/client';
import dayjs from 'dayjs';

interface BankStatementDetailsProps {
  bankStatementData: BankStatementFilesDto[];
}

export const BankStatementDetails = ({ bankStatementData }: BankStatementDetailsProps) => {
  const [trigger] = useLazyGetBankStatementFileUrlQuery();

  const onBSFileClick = (file) => {
    // eslint-disable-next-line promise/catch-or-return
    trigger(file, true).then((res) => {
      // eslint-disable-next-line promise/always-return
      res.isSuccess && window.open(decodeURI(res.data), '_blank');
    });
  };
  return (
    <CollapsibleCard title="Bank Statements" className="mb-6">
      <div className="grid grid-cols-2 px-4">
        {bankStatementData &&
          bankStatementData?.map((bankStatement, index) => (
            <div key={index} className="odd:border-r-grey-1 border-b-grey-1 border-b-2 p-4 odd:border-r-2">
              {bankStatement?.fileNames.length > 0 && (
                <span className="grid grid-cols-2 items-center">
                  <span>{bankStatement?.bankName} bank statement</span>
                  <span>
                    {bankStatement?.fileNames?.map((file, i) => (
                      <Button key={i} variant="link" onClick={() => onBSFileClick(file)} className="mt-2 text-left">
                        View file
                      </Button>
                    ))}
                  </span>
                </span>
              )}
              <span className="grid grid-cols-2">
                <span>Is Aggregation:</span>
                <span className="capitalize">
                  {typeof bankStatement?.isAggregation === 'boolean'
                    ? booleanToYesNo(bankStatement?.isAggregation)
                    : 'N/A'}
                </span>
              </span>
              <span className="grid grid-cols-2">
                <span>Status:</span>
                <span className="capitalize">{bankStatement.status.toLowerCase() ?? 'N/A'}</span>
              </span>
              {bankStatement.status === BankStatementOperationStatus.FAILURE && (
                <span className="grid grid-cols-2">
                  <span>Reason for failure:</span>
                  <span>{`Code: ${bankStatement.rawResponse['error_code']}, Message: ${bankStatement.rawResponse['error_message']}`}</span>
                </span>
              )}
              <span className="grid grid-cols-2">
                <span>{RETRIEVED_ON}</span>
                <span>{dayjs(bankStatement.timestamp).format(DATE_TIME_FORMAT) ?? 'N/A'}</span>
              </span>
              <span className="grid grid-cols-2">
                <span> </span>
                <span>{compareDatesFromNowInDays({ date: bankStatement.timestamp }) ?? 'N/A'}</span>
              </span>
            </div>
          ))}
      </div>
    </CollapsibleCard>
  );
};

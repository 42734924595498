import React from 'react';
import { LoanApplicationQueryResponse } from '@harmoney/redux';
import { Badge, Divider } from '@harmoney/ui-design-system';
import { isLoanArrearsCancelled, isPaidOff, isVaultLoanStateWrittenOff } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import { VaultLoanAccountStateEnum } from '@prisma/client';
import dayjs from 'dayjs';

type Props = {
  application: LoanApplicationQueryResponse;
};

const getBadgeProps = (
  application: LoanApplicationQueryResponse
): { label: string; variant: 'tertiary' | 'success' } => {
  const { status, loanArrearsStatus } = application;
  if (isPaidOff(status, loanArrearsStatus) || application.loanState === VaultLoanAccountStateEnum.PAID_OFF)
    return { label: 'Paid off loan', variant: 'success' };
  if (isLoanArrearsCancelled(loanArrearsStatus) || application.loanState === VaultLoanAccountStateEnum.CANCELLED)
    return { label: 'Cancelled loan', variant: 'tertiary' };
  if (isVaultLoanStateWrittenOff(application.loanState)) {
    return { label: 'Written off loan', variant: 'tertiary' };
  }
  switch (status) {
    case 'quote_expired':
      return { label: 'Expired quote', variant: 'tertiary' };
    case 'quote_cancelled':
      return { label: 'Cancelled quote', variant: 'tertiary' };
    case 'quote_unsuccessful':
      return { label: 'Unsuccessful quote', variant: 'tertiary' };
    case 'application_expired':
      return { label: 'Expired application', variant: 'tertiary' };
    case 'application_cancelled':
      return { label: 'Cancelled application', variant: 'tertiary' };
    case 'application_unsuccessful':
      return { label: 'Unsuccessful application', variant: 'tertiary' };
    default:
      return { label: '', variant: 'tertiary' };
  }
};

export const HistoryLoanDetails = ({ application }: Props) => {
  const badge = getBadgeProps(application);

  return (
    <div key={application.id} className="flex flex-col gap-4 p-4">
      <Badge label={badge.label} variant={badge.variant} />
      <div className="flex flex-row justify-between text-sm">
        <span>Loan purpose</span>
        <span className="font-medium">
          {application?.loanApplicationPurposes?.[0]?.loanPurpose?.displayName || '-'}
        </span>
      </div>
      <Divider className="text-grey-1 !m-0" />
      <div className="flex flex-row justify-between text-sm">
        <span>Amount</span>
        <span className="font-medium">{formatCurrency(application.requestedAmount || application.fundedAmount)}</span>
      </div>
      <Divider className="text-grey-1 !m-0" />
      <div className="flex flex-row justify-between text-sm">
        <span>Start date</span>
        <span className="font-medium">{dayjs(application.createdAt).format('DD MMMM YYYY')}</span>
      </div>
    </div>
  );
};

import { Fragment } from 'react';
import { IExpenseOverrideReasonFields, NetworthSourceEnum } from '@harmoney/api-interfaces';
import { IncomeAndExpense, LoanApplicationStatusEnum, NetworthSource, RelationshipStatusEnum } from '@prisma/client';
import classNames from 'classnames';
import { Entry } from 'contentful';

import { IndividualExpense } from './IndividualExpense';
import { SharedLivingExpense } from './SharedLivingExpense';

export type Expense = IncomeAndExpense & {
  networthSource: NetworthSource;
  isAccommodationExpense?: boolean;
};

interface ExpenseDetailsProps {
  expenseData: Expense[];
  applicationStatus: LoanApplicationStatusEnum;
  relationshipStatus?: RelationshipStatusEnum;
  overrideReasons?: Entry<IExpenseOverrideReasonFields>[];
  withForm?: boolean;
}

export const ExpenseDetails = ({
  expenseData,
  applicationStatus,
  relationshipStatus,
  overrideReasons,
  withForm,
}: ExpenseDetailsProps) => {
  return (
    <div className={classNames(withForm ? 'grid-cols-1' : 'grid-cols-2', 'grid gap-2')}>
      {expenseData.map((expense) => {
        return (
          <Fragment key={expense.id}>
            {expense.networthSourceId !== NetworthSourceEnum.EXPENSE_SHARED_ID ? (
              <IndividualExpense
                withForm={withForm}
                expense={expense}
                applicationStatus={applicationStatus}
                overrideReasons={overrideReasons}
                relationshipStatus={relationshipStatus}
              />
            ) : (
              <SharedLivingExpense expense={expense} />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

import classNames from 'classnames';

import { PropsWithHTMLElement } from '../../types';

import styles from './Spinner.module.scss';

export type SpinnerInternalProps = {
  /**
   * Determines the color that will be used in the `fill` property of the SVG
   * @default secondary
   */
  variant?: 'primary' | 'secondary' | 'tertiary';
  /**
   * Controls the size of the spinner. The default `medium` size is 40px wide,
   * the `small` size is 25px wide, and the `large` size is 60px wide
   * @default small
   */
  size?: 'small' | 'medium' | 'large';
};

export type SpinnerProps = PropsWithHTMLElement<SpinnerInternalProps, 'span'>;

export const Spinner = ({ variant = 'secondary', size = 'medium', className, ...restProps }: SpinnerProps) => {
  const classes = classNames(
    styles['spinner'],
    {
      [styles[`spinner-${variant}`]]: variant,
      [styles[`spinner-${size}`]]: size,
      [styles[`spinner-${variant}-${size}`]]: variant && size,
    },
    className
  );

  return (
    <span className={classes} {...restProps}>
      <svg className="animate-spin" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="56 146 500 500">
        <path
          fillOpacity="0.3"
          d="M306,646C168.1,646,56,533.9,56,396s112.1-250,250-250s250,112.1,250,250
    S443.7,646,306,646z M306,187.6c-114.9,0-208.4,93.5-208.4,208.4S191.1,604.4,306,604.4S514.2,510.9,514.2,396
    S420.9,187.6,306,187.6z"
        ></path>
        <path
          d="M446.9,189.6l-23.6,34.3c54.9,37.5,91,100.6,91,172s-36.1,134.5-91,172l23.6,34.3
        C512.6,557,556,481.4,556,395.7S512.6,234.8,446.9,189.6z"
        ></path>
      </svg>
    </span>
  );
};

import Image from 'next/image';
import { useFriendlyURL } from '@harmoney/hooks';
import { ArrowCircleRightIcon, Button, Card } from '@harmoney/ui-design-system';

import { CommonProps } from '../../common-props';

export function ApplicationInReview({ taskFriendlyURL }: CommonProps) {
  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      <h1>
        Your quote is in <span className="text-primary">review</span>
      </h1>
      <div className="flex flex-col items-center">
        <Card className="flex flex-col justify-start gap-4">
          <div className="justify-center w-full flex flex-row">
            <Image src="/assets/images/quote-review.svg" alt="Quote in review" width={100} height={100} />
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-medium mb-0">Thank you for sharing your information with us</p>
            <p>
              We are assessing the information you provided. Once it&rsquo;s processed we&rsquo;ll let you know via
              email.
            </p>
          </div>
        </Card>

        <Button
          className="mb-4"
          as="a"
          href="/dashboard"
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          hasShadow
        >
          Go to dashboard
        </Button>
      </div>
    </>
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface JourneyState {
  value: string;
}

const initialState: JourneyState = {
  value: null,
};

export const journeySlice = createSlice({
  name: 'journey',
  initialState,
  reducers: {
    setJourney: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
});

export const { setJourney } = journeySlice.actions;

export default journeySlice.reducer;

import { useLocalStorage } from '@mantine/hooks';

export enum UserInformationConfirmationSectionEnum {
  IdentityVerification = 'IdentityVerification',
  UserInformation = 'UserInformation',
}

export const useUserInformationConfirm = () => {
  const [confirmedSections, setConfirmedSections] = useLocalStorage({
    key: 'user-information-confirmation',
    defaultValue: [],
  });

  const confirmSection = (section: UserInformationConfirmationSectionEnum) => {
    if (!confirmedSections.includes(section)) setConfirmedSections([...confirmedSections, section]);
  };
  const resetConfirmation = () => {
    setConfirmedSections([]);
  };

  return {
    confirmedSections,
    confirmSection,
    resetConfirmation,
  };
};

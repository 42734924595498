import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';

import { queryErrorLogger } from './middleware';
import { emptySplitApi } from './services';
import {
  accessTokenReducer,
  adminReducer,
  debtFormReducer,
  journeyReducer,
  pusherReducer,
  taskIdReducer,
  userIdReducer,
} from './slice';

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
  taskId: taskIdReducer,
  userId: userIdReducer,
  journey: journeyReducer,
  accessToken: accessTokenReducer,
  pusher: pusherReducer,
  admin: adminReducer,
  debtForm: debtFormReducer,
  [emptySplitApi.reducerPath]: emptySplitApi.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat([emptySplitApi.middleware, queryErrorLogger]),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

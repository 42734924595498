import React, { useState } from 'react';

export interface WizardChildProps {
  onNext?: () => void;
}

interface WizardProps {
  children: React.ReactNode;
}

export function Wizard({ children }: WizardProps) {
  const [step, setStep] = useState(0);
  const steps: React.ReactNode[] = React.Children.toArray(children);

  const handleNext = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    }
  };

  return <div>{steps[step] && React.cloneElement(steps[step] as React.ReactElement, { onNext: handleNext })}</div>;
}

import React, { useRef, useState } from 'react';
import { useDisplayDebtConAffordableModal, useFriendlyURL } from '@harmoney/hooks';
import {
  useAcceptLoanDisclosureMutation,
  useAppSelector,
  useCheckIfBPayDisbursementPresentQuery,
  useGetLoanApplicationQuery,
  useGetLoanDocumentIdentifiersQuery,
  useGetRefreshedLoansQuery,
  useGetVariablesQuery,
} from '@harmoney/redux';
import { eventAnalytics, LOAN_CONTRACT_ACCEPTED } from '@harmoney/ui-app-shell';
import { ArrowCircleRightIcon, Button, Card, LoanDisclosureTemplate } from '@harmoney/ui-design-system';
import dayjs from 'dayjs';

import { LoanBalanceAffordableModal } from '../../../../components/loan-balance';
import { CommonProps } from '../../../common-props';
import { ScrollEventHandler } from '../ScrollEventHandler';

export function LoanDisclosure({ taskId, completeTask, completeTaskWithData, taskFriendlyURL }: CommonProps) {
  const [loanDisclosureSubmitting, setLoanDisclosureSubmitting] = useState(false);
  const userId = useAppSelector((state) => state.userId.value);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const { data: variables } = useGetVariablesQuery(taskId);
  const { data: loanApplicationData } = useGetLoanApplicationQuery(variables?.loanApplicationId.toString(), {
    skip: !variables,
  });
  const { data: refreshedLoanData } = useGetRefreshedLoansQuery(variables?.loanApplicationId?.toString(), {
    skip: !variables,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const { showLoanDetailsAffordableModal, setShowLoanDetailsAffordableModal } = useDisplayDebtConAffordableModal({
    refreshedLoanData,
    onUnaffordable: () => completeTask({ taskId, variables: { nextStep: 'decline' } }),
  });

  const { data: loanDisclosureDetails } = useGetLoanDocumentIdentifiersQuery(loanApplicationData?.id, {
    skip: !loanApplicationData || !refreshedLoanData,
  });

  const { data: isBPayPresent } = useCheckIfBPayDisbursementPresentQuery(loanApplicationData?.id, {
    skip: !loanApplicationData,
  });

  const [acceptLoanDisclosure] = useAcceptLoanDisclosureMutation();
  const scrollDivRef = useRef(null);

  const handleCloseLoanBalanceAffordableModal = () => setShowLoanDetailsAffordableModal(false);

  const handleSubmit = async () => {
    setLoanDisclosureSubmitting(true);
    eventAnalytics.track(LOAN_CONTRACT_ACCEPTED, {
      userid_str: userId,
      taskid_str: taskId,
    });
    await acceptLoanDisclosure(loanApplicationData.id)
      .unwrap()
      .then(() => completeTask({ taskId }))
      .catch(() => setLoanDisclosureSubmitting(false));
  };

  const handleScrollToBottom = (isBottom) => {
    if (isBottom) {
      setIsScrolledToBottom(isBottom);
    }
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      <h1>
        <strong className="text-primary">Important information </strong> to read
      </h1>
      <p className="mb-4"> A copy will be sent to your email</p>
      <Card className="px-4 py-4">
        <div className="leading-lg flex items-center p-0 text-sm font-normal">
          <p className="leading-base text-sm">Loan Agreement Details</p>
        </div>
        <ScrollEventHandler divRef={scrollDivRef} onScroll={handleScrollToBottom}>
          <div className="border-grey-2 mt-3 h-[27rem] overflow-auto rounded-md border p-4" ref={scrollDivRef}>
            <LoanDisclosureTemplate withHtml={false} data={loanDisclosureDetails} />
          </div>
        </ScrollEventHandler>
      </Card>
      <p className="mb-2 text-sm">
        Your application expires on{' '}
        <span className="font-bold">
          {dayjs(loanApplicationData?.quoteExpiryAt).subtract(2, 'days').format('DD MMMM YYYY')}
        </span>{' '}
        at 11:59PM.
      </p>
      <p className="text-sm">
        By accepting, you authorise Harmoney to set up a direct debit authority against your selected bank account, and
        confirm you have read and agree to the{' '}
        <a target="_blank" rel="noopener noreferrer" href="https://www.monoova.com/ddrsa">
          Monoova Service Agreement
        </a>
        {isBPayPresent && (
          <>
            {' '}
            and{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://www.monoova.com/bpay-terms">
              BPAY terms
            </a>
          </>
        )}
        .
      </p>
      <div className="flex justify-center">
        <Button
          type="submit"
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          className="mt-6"
          hasShadow
          disabled={!isScrolledToBottom}
          isLoading={loanDisclosureSubmitting}
          onClick={handleSubmit}
        >
          {!isScrolledToBottom ? 'Scroll to Accept' : 'Accept'}
        </Button>
      </div>

      {refreshedLoanData && (
        <LoanBalanceAffordableModal
          isOpen={showLoanDetailsAffordableModal}
          onModalOpen={handleCloseLoanBalanceAffordableModal}
          onContinue={handleCloseLoanBalanceAffordableModal}
          quoteDetails={refreshedLoanData}
        />
      )}
    </>
  );
}

export default LoanDisclosure;

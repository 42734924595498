import { generateIcon } from '../generateIcon';

/**
 * @deprecated Check if it can be replaced with Iconify. If not, add it to icon.json.
 * Refer to icon document for details: https://63be80b51f526a24fc7865c5-tslqcdnosl.chromatic.com/?path=/docs/docs-icons--docs
 */
export const VerificationErrorIcon = generateIcon({
  viewBox: '0 0 82 82',
  variant: 'none',
  path: (
    <>
      <circle cx="41" cy="41" r="39.8" stroke="#D33D3B" strokeWidth="2.4" />
      <path d="M26.65 55.3499L55.35 26.6499" stroke="#D33D3B" strokeWidth="2.4" strokeLinecap="round" />
      <path d="M26.65 26.6499L55.35 55.3499" stroke="#D33D3B" strokeWidth="2.4" strokeLinecap="round" />
    </>
  ),
});

import React from 'react';
import { CollapsibleProps } from '@radix-ui/react-accordion';
import * as Collapsible from '@radix-ui/react-collapsible';
import classNames from 'classnames';

import { ChevronIcon, IconV2 } from '../Icon';

import styles from './CollapsibleCard.module.scss';

export interface CollapsibleCardProps extends Omit<CollapsibleProps, 'asChild'> {
  title?: string;
  /**
   * The open state of the collapsible when it is initially rendered.
   * Use when you do not need to control its open state.
   */
  defaultOpen?: boolean;
  /**
   * When `true`, prevents the user from interacting with the collapsible.
   */
  disabled?: boolean;
  /**
   * Extra information about the collapsible.
   */
  description?: string | React.ReactNode;
}

export const CollapsibleCard = ({
  title,
  children,
  className,
  defaultOpen = true,
  disabled = false,
  description,
}: CollapsibleCardProps) => {
  const [open, setOpen] = React.useState(defaultOpen);
  return (
    <Collapsible.Root
      className={classNames(styles['root'], className)}
      defaultOpen={defaultOpen}
      open={open}
      disabled={disabled}
      onOpenChange={setOpen}
    >
      <Collapsible.Trigger asChild>
        <button className={styles['trigger']}>
          <div className="flex w-full justify-between">
            <div className="flex flex-row items-center gap-1">
              {!disabled && (
                <ChevronIcon size="tiny" className={classNames(open ? 'rotate-180' : '', 'transition-all mr-2')} />
              )}
              <span className={styles['title']}>{title}</span>
            </div>
            <span className="text-grey-5 mr-6 text-sm">{description}</span>
          </div>
        </button>
      </Collapsible.Trigger>

      <div className="overflow-hidden">
        <Collapsible.Content className={classNames(open ? 'animate-expand' : 'animate-collapse', 'transition-all')}>
          {children}
        </Collapsible.Content>
      </div>
    </Collapsible.Root>
  );
};

export const CollapsibleCardUnstyled = ({
  title,
  children,
  className,
  defaultOpen = true,
  disabled = false,
  footer,
}: Omit<CollapsibleCardProps, 'title'> & {
  footer?: React.ReactNode;
  title: React.ReactNode;
}) => {
  const [open, setOpen] = React.useState(defaultOpen);

  return (
    <Collapsible.Root
      className={classNames('rounded-xl shadow-drop bg-white mb-6', className)}
      defaultOpen={defaultOpen}
      open={open}
      disabled={disabled}
      onOpenChange={setOpen}
    >
      <Collapsible.Trigger asChild>
        <button
          className={classNames(
            footer ? 'rounded-t-xl' : 'rounded-xl',
            open ? 'border-b border-grey-2 rounded-b-none' : '',
            'bg-white p-4 flex w-full items-center overflow-hidden'
          )}
        >
          <div className="flex w-full justify-between">
            {title}
            <IconV2
              icon="ic:round-chevron-right"
              width={24}
              className={classNames(open ? '-rotate-90' : 'rotate-90', 'transition-all text-grey-5')}
            />
          </div>
        </button>
      </Collapsible.Trigger>
      <div className="overflow-hidden">
        <Collapsible.Content className={classNames(open ? 'animate-expand' : 'animate-collapse', 'transition-all')}>
          {children}
        </Collapsible.Content>
      </div>
      {footer && <div className={classNames('border-t border-t-grey-2 p-4')}>{footer}</div>}
    </Collapsible.Root>
  );
};

import { z } from 'zod';

export const defaultValues = {
  displayName: '',
};

export const renameLoanDialogSchema = z.object({
  displayName: z.string().min(1, {
    message: 'Name is required',
  }),
});

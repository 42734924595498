import { generateIcon } from '../generateIcon';

/**
 * @deprecated Check if it can be replaced with Iconify. If not, add it to icon.json.
 * Refer to icon document for details: https://63be80b51f526a24fc7865c5-tslqcdnosl.chromatic.com/?path=/docs/docs-icons--docs
 */
export const CheckRadioIcon = generateIcon({
  viewBox: '0 0 24 24',
  path: <circle cx="12" cy="12" r="9" fill="white" stroke="#7EE399" strokeWidth="6" />,
});

import { VaultAccountStatus } from './vault-account-status.dto';

export const vaultAccountStatusMapper: Record<VaultAccountStatus, string> = {
  [VaultAccountStatus.ACCOUNT_STATUS_UNKNOWN]: 'Unknown',
  [VaultAccountStatus.ACCOUNT_STATUS_OPEN]: 'Open',
  [VaultAccountStatus.ACCOUNT_STATUS_PENDING]: 'Pending',
  [VaultAccountStatus.ACCOUNT_STATUS_PENDING_CLOSURE]: 'Pending closure',
  [VaultAccountStatus.ACCOUNT_STATUS_CLOSED]: 'Closed',
  [VaultAccountStatus.ACCOUNT_STATUS_CANCELLED]: 'Cancelled',
};

export const loansViewStatusMapper: Record<VaultAccountStatus, string> = {
  [VaultAccountStatus.ACCOUNT_STATUS_OPEN]: 'Active',
  [VaultAccountStatus.ACCOUNT_STATUS_PENDING_CLOSURE]: 'Active',
  [VaultAccountStatus.ACCOUNT_STATUS_CLOSED]: 'Closed',
  [VaultAccountStatus.ACCOUNT_STATUS_CANCELLED]: 'Cancelled',
  [VaultAccountStatus.ACCOUNT_STATUS_UNKNOWN]: '',
  [VaultAccountStatus.ACCOUNT_STATUS_PENDING]: '',
};

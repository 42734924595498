import Image from 'next/image';
import { Icon } from '@iconify/react';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@radix-ui/react-collapsible';
import classNames from 'classnames';
import { capitalize, kebabCase, trim } from 'lodash';

import { Card } from '../Card';
import { CheckSquareIcon, ChevronIcon, DisabledSquareIcon, UncheckSquareIcon } from '../Icon';
import { Label } from '../Label';

import styles from './CollapsibleHeader.module.scss';

interface CollapsibleHeaderProps {
  title: string;
  open: boolean;
  code?: string;
  subtitle?: string;
  checkbox?: boolean;
  chevron?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  disabledActive?: boolean;
  valid?: boolean;
  dropdownImage?: React.ReactNode;
  onCollapseChange?: (isChecked: boolean) => void;
  withIconify?: string;
}

export const CollapsibleHeader = ({
  title,
  subtitle,
  code,
  children,
  open = false,
  valid = false,
  checkbox = false,
  chevron = false,
  disabled = false,
  disabledActive = false,
  onCollapseChange,
  dropdownImage,
  withIconify,
  ...props
}: CollapsibleHeaderProps) => {
  const imageUrl = `/assets/images/${kebabCase(code || title)}.svg`;
  const validateFormBorder = valid ? 'border border-1 border-error' : '';
  const disabledBackground = disabled ? '!bg-grey-1' : 'bg-white';
  const disabledActiveBorder = disabledActive ? 'border border-1 border-tertiary-lighter-1' : '';
  const activeCheckboxBorder = open && checkbox ? 'border border-1 border-tertiary-lighter-1' : '';

  const onChange = () => {
    open = !open;
    onCollapseChange?.(open);
  };

  const capitalizeTitle = (title: string) => {
    if (title.includes('/')) {
      const [beforeSlash, afterSlash] = title.split('/');
      const result = `${capitalize(trim(beforeSlash))}/${capitalize(trim(afterSlash))}`;
      return result;
    }
    return capitalize(trim(title));
  };

  return (
    <Card
      className={classNames(
        validateFormBorder,
        disabledBackground,
        disabledActiveBorder,
        activeCheckboxBorder,
        'mb-3 !p-1'
      )}
    >
      <Collapsible
        disabled={disabled || disabledActive}
        open={open || disabledActive}
        onOpenChange={onChange}
        className="focus:outline-none focus:ring-2 focus:ring-indigo-500"
      >
        <CollapsibleTrigger
          className={`${disabled ? 'bg-grey-1' : 'bg-white'} flex w-full items-center justify-between rounded-lg  p-4`}
        >
          <div className="flex items-center gap-x-3">
            {dropdownImage || withIconify ? (
              <Icon icon={withIconify as string} width={24} className={open ? 'text-secondary' : 'text-grey-3'} />
            ) : (
              <Image
                src={imageUrl}
                className={`${open ? 'self-auto' : 'grayscale'}`}
                alt={`${title}_image`}
                width={24}
                height={24}
              />
            )}
            <div className="text-left">
              <Label className={open ? 'font-medium' : ''}>{capitalizeTitle(title)}</Label>
              {subtitle && <small className="text-grey-4 leading-lg block text-sm">{capitalizeTitle(subtitle)}</small>}
            </div>
          </div>
          {chevron && <ChevronIcon size="tiny" className={!open ? 'rotate-360' : 'rotate-180'} />}
          {checkbox &&
            !disabled &&
            !disabledActive &&
            (open ? <CheckSquareIcon size="medium" /> : <UncheckSquareIcon size="medium" />)}
          {checkbox && disabledActive && (
            <Icon icon="ic:round-check-box" width={32} height={32} className="text-tertiary-lighter-2" />
          )}
          {checkbox && disabled && <DisabledSquareIcon size="medium" />}
        </CollapsibleTrigger>
        <CollapsibleContent className={classNames(styles['collapsible-content'])}>{children}</CollapsibleContent>
      </Collapsible>
    </Card>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * Frankie Financial API
 * ------   This API allows developers to integrate the Frankie Financial Compliance Utility into their applications. The API allows:   - Checking name, address, date of birth against national databases   - Validating Australian driver's licences, passports, medicare, visas and other Australian national ID documents   - Validating Australian electricity bills   - Validating NZ driver's licences   - Validating Chinese bank cards and national ID card   - Validating International passports and national ID documents   - PEP, Sanctions, Watchlist and adverse media checking   - Australian visa checks    - Fraud list and fraud background checks   - ID validation and selfie check comparisons.    ------     Industry specific services    - Comparing Australian electricity retailers for a better deal.  ------     KYB specific services    - Query organisation ownership   - Perform KYC & AML checks on shareholders, beneficial owners and office bearers.   - Query credit score and credit reports   - International company searches   - International company profiles    ------   The full version of this documentation along with supplemental articles can be found here:   - https://apidocs.frankiefinancial.com/  ------ Sandbox base URL is:   - https://api.demo.frankiefinancial.io/compliance/v1.2      - All calls are the same as production, only with test data. You can download the latest test data here: https://apidocs.frankiefinancial.com/docs/test-data    - Full Swagger definition, along with test data for playing in the sandbox can be obtained once initial commercial discussions have commenced.    - Production and optional UAT access will be opened up only to those with a signed commercial contract.    ------   Contact us at hello@frankiefinancial.com to speak with a sales rep about issuing a Customer ID and Sandbox api key.
 *
 * OpenAPI spec version: 1.14.0
 * Contact: dev-support@frankiefinancial.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Benefits } from './benefits';
import { Conditions } from './conditions';
import { Contract } from './contract';
import { Fees } from './fees';
import { NameValue } from './name-value';
import { PlanDiscounts } from './plan-discounts';
import { Rates } from './rates';
import { Retailer } from './retailer';
/**
 * All information associated with a given plan
 * @export
 * @interface Plan
 */
export interface Plan {
  /**
   *
   * @type {Benefits}
   * @memberof Plan
   */
  benefits?: Benefits;
  /**
   *
   * @type {Conditions}
   * @memberof Plan
   */
  conditions?: Conditions;
  /**
   *
   * @type {Contract}
   * @memberof Plan
   */
  contract?: Contract;
  /**
   * Default Offer (DMO/VDO) text to be displayed for this plan
   * @type {string}
   * @memberof Plan
   */
  defaultOfferMessage: string;
  /**
   * Is payment by direct debit required in order to subscribe to this plan
   * @type {boolean}
   * @memberof Plan
   */
  directDebitRequired: boolean;
  /**
   *
   * @type {PlanDiscounts}
   * @memberof Plan
   */
  discounts?: PlanDiscounts;
  /**
   * Estimated cost of this plan, based on the usage from the uploaded bill, with no conditional discounts applied. If this plan offers no conditional discounts the estimatedTotalCost and the estimatedBaseCost will be the same.
   * @type {number}
   * @memberof Plan
   */
  estimatedBaseCost: number;
  /**
   * The estimated saving the customer could have realised if they had been on this plan during the billing period
   * @type {number}
   * @memberof Plan
   */
  estimatedSaving: number;
  /**
   * Estimated cost of this plan, based on the usage from uploaded bill, with all conditional discounts applied.
   * @type {number}
   * @memberof Plan
   */
  estimatedTotalCost: number;
  /**
   *
   * @type {Fees}
   * @memberof Plan
   */
  feesAndCharges?: Fees;
  /**
   *
   * @type {NameValue}
   * @memberof Plan
   */
  greenOptions?: NameValue;
  /**
   * Unique identifier for this plan. This ID is passed when calling the switch API.
   * @type {number}
   * @memberof Plan
   */
  id: number;
  /**
   * Name of the plan
   * @type {string}
   * @memberof Plan
   */
  name: string;
  /**
   * Is pay on time required in order to subscribe to this plan
   * @type {boolean}
   * @memberof Plan
   */
  payOnTimeRequired: boolean;
  /**
   *
   * @type {NameValue}
   * @memberof Plan
   */
  paymentOptions?: NameValue;
  /**
   *
   * @type {Rates}
   * @memberof Plan
   */
  rates: Rates;
  /**
   *
   * @type {Retailer}
   * @memberof Plan
   */
  retailer: Retailer;
  /**
   * The type of energy plan * `SR` - Single Rate * `TOU` - Time Of Use
   * @type {string}
   * @memberof Plan
   */
  type: PlanTypeEnum;
  /**
   * Link to BPID (Basic Plan Information Document (NSW, SA, QLD, ACT)) or EPFS (Energy Price Fact Sheet (VIC))
   * @type {string}
   * @memberof Plan
   */
  url: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PlanTypeEnum {
  SR = 'SR',
  TOU = 'TOU',
}

import React, { forwardRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import type { CheckboxProps as RadixCheckboxProps } from '@radix-ui/react-checkbox';
import * as RadixCheckbox from '@radix-ui/react-checkbox';
import classNames from 'classnames';

import { CommonOptionProps } from '../../types';
import { CheckSquareIcon } from '../Icon';
import { Label } from '../Label';
import { ValidationMessage } from '../ValidationMessage';

import styles from './CheckboxGroup.module.scss';

export interface CheckboxGroupProps extends Omit<RadixCheckboxProps, 'asChild'> {
  /**
   * The name of the checkbox. Submitted with its owning form as part of a name/value pair.
   */
  name: string;
  options: CommonOptionProps[];
}

export const CheckboxGroup = forwardRef<HTMLDivElement, CheckboxGroupProps>(
  ({ className, options, name, disabled }: CheckboxGroupProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { control, formState, getFieldState } = useFormContext();
    const { invalid } = getFieldState(name, formState);

    const {
      field: { onChange, value: fieldValue, onBlur },
    } = useController({
      name,
      control,
      defaultValue: [],
    });

    const handleChange = (checked: RadixCheckbox.CheckedState, value: CommonOptionProps['value']) => {
      const newValues = checked ? [...fieldValue, value] : fieldValue.filter((v: unknown) => v !== value);
      onChange(newValues);
    };

    const classes = classNames(
      styles['checkbox-group'],
      styles['checkbox-group-item'],
      disabled && styles['checkbox-group-disabled'],
      invalid && styles['checkbox-group-invalid']
    );

    return (
      <div className={className} ref={forwardRef}>
        {options.map(({ label, value }) => (
          <Label
            key={value}
            className={classNames(classes, fieldValue.includes(value) && styles['checkbox-group-checked'])}
            htmlFor={value}
          >
            <div className="flex">
              <span className={classNames('flex flex-1 cursor-pointer', styles['label'])}>
                <span className={styles['title']}>{label}</span>
              </span>
              <RadixCheckbox.Root
                onCheckedChange={(checked) => handleChange(checked, value)}
                value={value}
                onBlur={onBlur}
                disabled={disabled}
                id={value}
                data-testid={value}
                className={styles['checkbox-group-button']}
                checked={fieldValue.includes(value)}
              >
                <CheckSquareIcon size="medium" />
              </RadixCheckbox.Root>
            </div>
          </Label>
        ))}
        <ValidationMessage name={name} />
      </div>
    );
  }
);

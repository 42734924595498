/* tslint:disable */
/* eslint-disable */
/**
 * Frankie Financial API
 * ------   This API allows developers to integrate the Frankie Financial Compliance Utility into their applications. The API allows:   - Checking name, address, date of birth against national databases   - Validating Australian driver's licences, passports, medicare, visas and other Australian national ID documents   - Validating Australian electricity bills   - Validating NZ driver's licences   - Validating Chinese bank cards and national ID card   - Validating International passports and national ID documents   - PEP, Sanctions, Watchlist and adverse media checking   - Australian visa checks    - Fraud list and fraud background checks   - ID validation and selfie check comparisons.    ------     Industry specific services    - Comparing Australian electricity retailers for a better deal.  ------     KYB specific services    - Query organisation ownership   - Perform KYC & AML checks on shareholders, beneficial owners and office bearers.   - Query credit score and credit reports   - International company searches   - International company profiles    ------   The full version of this documentation along with supplemental articles can be found here:   - https://apidocs.frankiefinancial.com/  ------ Sandbox base URL is:   - https://api.demo.frankiefinancial.io/compliance/v1.2      - All calls are the same as production, only with test data. You can download the latest test data here: https://apidocs.frankiefinancial.com/docs/test-data    - Full Swagger definition, along with test data for playing in the sandbox can be obtained once initial commercial discussions have commenced.    - Production and optional UAT access will be opened up only to those with a signed commercial contract.    ------   Contact us at hello@frankiefinancial.com to speak with a sales rep about issuing a Customer ID and Sandbox api key.
 *
 * OpenAPI spec version: 1.14.0
 * Contact: dev-support@frankiefinancial.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * Used to describe the contents of the KVP data.   The general.* and raw.* types are pretty much what they say on the tin.   All raw.* fields will be base64 encoded so as to not interfere with JSON structuring. These are useful for returning/storing large quantities of data that doesn't necessarily require processing now, or may be useful to a calling client.  The id.* and pii.* are used to indicate that this is data that can be used to create new document objects, or entities. They should also be treated with the utmost care and attention when it comes to securing them too.  id.external can be used to capture an object's ID on an external service, and can potentially be searchable in the index  Note - This is different from a result.id.  defunct is used to mark an existing KVP deleted when the value must be retained, for example for audit purposes.  result.* are used to capture response codes and transaction IDs from external services  error.* types can be used when processing a document that returns an error, but doesn't necessarily require a full blown error response.
 * @export
 * @enum {string}
 */
export enum EnumKVPType {
  Defunct = 'defunct',
  GeneralString = 'general.string',
  GeneralInteger = 'general.integer',
  GeneralFloat = 'general.float',
  GeneralBool = 'general.bool',
  GeneralDate = 'general.date',
  GeneralDatetime = 'general.datetime',
  RawJsonBase64 = 'raw.json.base64',
  RawXmlBase64 = 'raw.xml.base64',
  RawBase64 = 'raw.base64',
  ErrorCode = 'error.code',
  ErrorMessage = 'error.message',
  ResultCode = 'result.code',
  ResultId = 'result.id',
  IdExternal = 'id.external',
  IdNumberPrimary = 'id.number.primary',
  IdNumberAdditional = 'id.number.additional',
  IdMsisdn = 'id.msisdn',
  IdEmail = 'id.email',
  IdDevice = 'id.device',
  PiiNameFull = 'pii.name.full',
  PiiNameFamilyname = 'pii.name.familyname',
  PiiNameGivenname = 'pii.name.givenname',
  PiiNameMiddlename = 'pii.name.middlename',
  PiiGender = 'pii.gender',
  PiiAddressLongform = 'pii.address.longform',
  PiiAddressShortform = 'pii.address.shortform',
  PiiAddressStreet1 = 'pii.address.street1',
  PiiAddressStreet2 = 'pii.address.street2',
  PiiAddressPostalcode = 'pii.address.postalcode',
  PiiAddressTown = 'pii.address.town',
  PiiAddressSuburb = 'pii.address.suburb',
  PiiAddressRegion = 'pii.address.region',
  PiiAddressState = 'pii.address.state',
  PiiAddressCountry = 'pii.address.country',
  PiiDob = 'pii.dob',
  TransientString = 'transient.string',
}

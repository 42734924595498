import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useTaskComponent } from '@harmoney/hooks';
import {
  useAppSelector,
  useAssignTaskByUserMutation,
  useFindTaskByIdQuery,
  useGetUserByIdQuery,
} from '@harmoney/redux';
import { Button, CollapsibleCard, Spinner } from '@harmoney/ui-design-system';
import { DATE_TIME_FORMAT, SOMETHING_WENT_WRONG } from '@harmoney/ui-utils';
import dayjs from 'dayjs';

import { Field } from '../customer-hub';

export const TaskDetails = () => {
  const router = useRouter();
  const accessToken = useAppSelector((state) => state.accessToken.value);
  const taskId = router.query.taskId as string;
  const userId = useAppSelector((state) => state.userId.value);
  const {
    data: taskData,
    isError,
    isLoading,
  } = useFindTaskByIdQuery(taskId, { refetchOnMountOrArgChange: true, skip: !accessToken || !taskId });

  const { data: assignee } = useGetUserByIdQuery(taskData?.assignee, {
    refetchOnMountOrArgChange: true,
    skip: !accessToken || !userId || !taskData,
  });

  const [assignTaskByUser] = useAssignTaskByUserMutation();

  const handleAssignTask = async () => {
    const data = {
      assignee: userId,
      allowOverrideAssignment: false,
    };

    await assignTaskByUser({ taskId, data }).unwrap;
  };

  const TaskComponent = useTaskComponent(null, taskData?.formKey);

  const loanApplicationId = useMemo(() => {
    return taskData?.variables.find((variable) => variable.name === 'loanApplicationId').value.replace(/^"|"$/g, '');
  }, [taskData?.variables]);
  const applicationUserId = useMemo(() => {
    return taskData?.variables.find((variable) => variable.name === 'userId').value.replace(/^"|"$/g, '');
  }, [taskData?.variables]);

  if (isError) {
    return <div className="m-3">{SOMETHING_WENT_WRONG}</div>;
  }

  if (isLoading) {
    return (
      <div className="align-center mt-32 flex justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="break-words p-4 sm:p-6">
      {taskData && (
        <div className="break-words p-4 sm:p-6">
          <div className="mb-4 flex items-center justify-between">
            <h2 className="text-primary mb-0">Task </h2>
            <span className="font-medium">{`Task ID: ${taskData.id}`}</span>
          </div>
          <CollapsibleCard title="Task Detail" className="mb-6" defaultOpen={true}>
            <div className="grid grid-cols-2 px-4">
              <div className="p-4 col-span-1">
                <Field>
                  <span>Name:</span>
                  <span>{taskData.name}</span>
                </Field>
                <Field>
                  <span>Task Status:</span>
                  <span>{taskData.taskState}</span>
                </Field>
                <Field>
                  <span>Created At:</span>
                  <span>{dayjs(taskData.creationDate).format(DATE_TIME_FORMAT)}</span>
                </Field>
              </div>
              <div className="p-4">
                <Field>
                  <span>Process Instance Key:</span>
                  <span>{taskData.processInstanceKey}</span>
                </Field>
                <Field>
                  <span>Application Details:</span>
                  <Link
                    href={`/admin/customer/application/?userId=${applicationUserId}&applicationId=${loanApplicationId}`}
                    target="_blank"
                    passHref
                  >
                    View application
                  </Link>
                </Field>
                <Field>
                  <span>Assignee:</span>
                  <span className="flex flex-col gap-2">
                    <span>{taskData?.assignee !== null && assignee ? assignee.email : 'Not yet assigned'}</span>
                    {taskData?.assignee == null && (
                      <Button variant="text" size="medium" onClick={handleAssignTask}>
                        Assign to me
                      </Button>
                    )}
                  </span>
                </Field>
              </div>
            </div>
          </CollapsibleCard>
          <div className="my-4">
            {TaskComponent && taskData?.assignee === userId && taskData?.taskState !== 'COMPLETED' && (
              <TaskComponent taskId={taskId} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

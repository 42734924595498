import { useEffect } from 'react';
import { useAppSelector, useGetPreQualifiedQuoteForAdminQuery } from '@harmoney/redux';
import { Accordion, CollapsibleCard } from '@harmoney/ui-design-system';

import { PreQualifiedQuoteAffordabilityDetails } from '../PreQualifiedQuoteAffordabilityDetails';
import { PreQualifiedQuoteDecisionDetails } from '../PreQualifiedQuoteDecisionDetails';
import { PreQualifiedQuoteFinalDetails } from '../PreQualifiedQuoteFinalDetails';
import { PreQualifiedQuoteFinancialProfileDetails } from '../PreQualifiedQuoteFinancialProfileDetails';

import { RequirementsAndObjectivesDetails } from './RequirementsAndObjectivesDetails';

export interface PreQualifiedQuoteDetailsProps {
  applicationId: string;
}

export const PreQualifiedQuoteDetails = ({ applicationId }: PreQualifiedQuoteDetailsProps) => {
  const accessToken = useAppSelector((state) => state.accessToken.value);
  const { data: preQualifiedQuoteData } = useGetPreQualifiedQuoteForAdminQuery(
    { id: applicationId },
    { skip: !applicationId || !accessToken }
  );

  useEffect(() => {
    document.title = 'Pre-Qualified Quote';
  }, []);

  const items = [
    {
      title: 'Decision',
      content: <PreQualifiedQuoteDecisionDetails preQualifiedQuote={preQualifiedQuoteData} />,
    },
    {
      title: 'Requirements and objectives',
      content: <RequirementsAndObjectivesDetails preQualifiedQuote={preQualifiedQuoteData} />,
    },
    {
      title: 'Financial Profile',
      content: <PreQualifiedQuoteFinancialProfileDetails preQualifiedQuote={preQualifiedQuoteData} />,
    },
    {
      title: 'Affordability',
      content: <PreQualifiedQuoteAffordabilityDetails preQualifiedQuote={preQualifiedQuoteData} />,
    },
    {
      title: 'Pre-qualified quote',
      content: (
        <PreQualifiedQuoteFinalDetails
          preQualifiedQuote={preQualifiedQuoteData}
          mode={preQualifiedQuoteData?.quoteStatus}
        />
      ),
    },
  ];

  if (!preQualifiedQuoteData) {
    return null;
  }

  return (
    <CollapsibleCard title="Pre-qualified Quote" className="mb-6">
      <div className="p-4">
        <Accordion
          items={items}
          defaultValue={[
            'Decision',
            'Requirements and objectives',
            'Financial Profile',
            'Affordability',
            'Pre-qualified quote',
          ]}
        />
      </div>
    </CollapsibleCard>
  );
};

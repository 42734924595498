import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { IReprocessNextStepsFields, NetworthSourceEnum } from '@harmoney/api-interfaces';
import {
  ExpenseDetails,
  filterByNetworthSourceKind,
  IncomeDetails,
  IncomeV3,
  LivingExpenseV2,
} from '@harmoney/components';
import {
  useAppDispatch,
  useAppSelector,
  useCoReviewReprocessMutation,
  useSubmitExpensesByCOMutation,
  useSubmitIncomeByCOMutation,
} from '@harmoney/redux';
import { OverrideReasons } from '@harmoney/services';
import {
  Button,
  Dialog,
  Form,
  IconV2,
  MultiSelect,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  useForm,
} from '@harmoney/ui-design-system';
import { Icon } from '@iconify/react';
import {
  AssetAndLiability,
  FinancialProfile,
  IncomeAndExpense,
  LoanApplication,
  NetworthSource,
  NetworthSourceKind,
} from '@prisma/client';
import { EntryCollection } from 'contentful';
import { z } from 'zod';

import { setIsDealRescued } from '../../../../redux/slice/admin';

type Props = {
  loanApplicationData?: LoanApplication;
  financialProfileData: FinancialProfile & {
    asset_and_liability: (AssetAndLiability & {
      networthSource: NetworthSource;
    })[];
    income_and_expense: (IncomeAndExpense & {
      networthSource: NetworthSource;
    })[];
  };
  overrideReasons: OverrideReasons;
  nextSteps: EntryCollection<IReprocessNextStepsFields>;
};

const formSchema = z.object({
  coReprocessNextSteps: z.array(z.string()),
});

export const RescueDealForm = ({ financialProfileData, loanApplicationData, overrideReasons, nextSteps }: Props) => {
  const coId = useAppSelector((state) => state.userId.value);
  const [reprocessApplication] = useCoReviewReprocessMutation();
  const [, { isUninitialized, isSuccess }] = useSubmitIncomeByCOMutation({
    fixedCacheKey: `${financialProfileData.userId}-${financialProfileData.id}-${coId}`,
  });
  const [, { isUninitialized: isNewExpenseUninitialized, isSuccess: isNewExpenseSuccess }] =
    useSubmitExpensesByCOMutation({
      fixedCacheKey: `${financialProfileData.userId}-${financialProfileData.id}-${coId}`,
    });
  const [coReprocessNextSteps, setCoReprocessNextSteps] = useState<{ label: string; value: string }[]>([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState(
    'Since no overrides were detected, the quote will be declined. Please wait 2 mins  for it to complete processing'
  );
  const [addIncome, setAddIncome] = useState(false);
  const [addExpense, setAddExpense] = useState(false);

  const dispatch = useAppDispatch();

  const incomes: (IncomeAndExpense & {
    networthSource: NetworthSource;
  })[] = filterByNetworthSourceKind(financialProfileData?.income_and_expense, NetworthSourceKind.INCOME);
  const expenses: (IncomeAndExpense & {
    networthSource: NetworthSource;
    isAccommodationExpense?: boolean;
  })[] = filterByNetworthSourceKind(financialProfileData?.income_and_expense, NetworthSourceKind.EXPENSE);

  const hasTotalEssentialExpense = expenses.some(
    (expense) => expense.networthSourceId === NetworthSourceEnum.EXPENSE_TOTAL_ESSENTIAL_EXPENSES_ID
  );

  useEffect(() => {
    if (isSuccess && !isUninitialized) {
      setAddIncome(false);
    }
  }, [isSuccess, isUninitialized]);

  useEffect(() => {
    if (isNewExpenseSuccess && !isNewExpenseUninitialized) {
      setAddExpense(false);
    }
  }, [isNewExpenseSuccess, isNewExpenseUninitialized]);

  const items = [
    {
      title: 'Income',
      content: (
        <div className="flex flex-col gap-4 px-2 pt-2">
          <h3 className="m-0 font-semibold">Income</h3>
          <IncomeDetails
            withForm
            incomeData={incomes}
            overrideReasons={overrideReasons.incomeOverrideReasons}
            applicationStatus={loanApplicationData?.status}
          />
          {!addIncome ? (
            <div className="flex flex-row gap-4 justify-center">
              <Button
                variant="accent"
                size="small"
                className="!min-w-fit self-center"
                onClick={() => setAddIncome(true)}
                icon={<Icon icon="ic:round-add" width={20} />}
                alignIcon="start"
              >
                Income(s)
              </Button>
            </div>
          ) : (
            <>
              <IconV2
                icon="ic:outline-close"
                onClick={() => {
                  setAddIncome(false);
                }}
                width={24}
                className="self-end text-grey-5 mr-4 cursor-pointer"
              />
              {addIncome && (
                <IncomeV3
                  isCustomerFacing={false}
                  customerId={financialProfileData.userId}
                  financialProfileId={financialProfileData.id}
                />
              )}
            </>
          )}
        </div>
      ),
    },
    {
      title: 'Expenses',
      content: (
        <div className="flex flex-col gap-4 px-2 pt-2">
          <h3 className="m-0 font-semibold">Expenses</h3>
          <ExpenseDetails
            withForm
            expenseData={expenses}
            applicationStatus={loanApplicationData?.status}
            overrideReasons={overrideReasons.expensesOverrideReasons}
            relationshipStatus={loanApplicationData?.relationshipStatus}
          />
          {!hasTotalEssentialExpense && (
            <>
              {!addExpense ? (
                <div className="flex flex-row gap-4 justify-center">
                  <Button
                    variant="accent"
                    size="small"
                    className="!min-w-fit self-center"
                    onClick={() => setAddExpense(true)}
                    icon={<Icon icon="ic:round-add" width={20} />}
                    alignIcon="start"
                  >
                    Expense(s)
                  </Button>
                </div>
              ) : (
                <>
                  <IconV2
                    icon="ic:outline-close"
                    onClick={() => setAddExpense(false)}
                    width={24}
                    className="self-end text-grey-5 mr-4 cursor-pointer"
                  />
                  <LivingExpenseV2
                    isCustomerFacing={false}
                    customerId={financialProfileData.userId}
                    financialProfileId={financialProfileData.id}
                  />
                </>
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues: {
      coReprocessNextSteps: [],
    },
  });

  const {
    formState: { isSubmitting },
    register,
  } = form;

  useEffect(() => {
    const nextStepItems = nextSteps.items.map((item) => ({
      label: item.fields.step,
      value: item.fields.step,
    }));
    setCoReprocessNextSteps(nextStepItems);
  }, [nextSteps]);

  const handleSubmit = async (formValues: z.infer<typeof formSchema>) => {
    try {
      const formData = {
        loanApplicationId: loanApplicationData.id,
        nextSteps: [],
      };

      if (formValues.coReprocessNextSteps?.length > 0) {
        formData.nextSteps = formValues.coReprocessNextSteps;
      }
      const response = await reprocessApplication(formData).unwrap();
      if (response?.needForReprocess) {
        setAlertMessage('Your override values are being processed. Please wait 2 mins for a decision.');
      }
      setIsConfirmModalOpen(true);
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setIsDealRescued(false));
    }
  };

  const handleCancel = () => {
    dispatch(setIsDealRescued(false));
  };

  return (
    <>
      <Tabs defaultValue="Income">
        <div className="sticky top-0 z-10 rounded-b-sm bg-white pr-2">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-baseline justify-end gap-4">
              <h2 className="text-primary">Review financial profile</h2>
            </div>
            <Icon icon="ic:round-close" onClick={handleCancel} className="cursor-pointer" width={24} />
          </div>
          <TabsList className="w-full mb-1 rounded-xl">
            {items.map((item) => (
              <TabsTrigger key={item.title} value={item.title}>
                {item.title}
              </TabsTrigger>
            ))}
          </TabsList>
        </div>
        {items.map((item) => (
          <TabsContent key={item.title} value={item.title}>
            {item.content}
          </TabsContent>
        ))}
      </Tabs>
      <Form form={form} onSubmit={handleSubmit} className="pb-16">
        <div className="flex flex-col gap-2 pt-4">
          <span className="px-1">What other information did you use to review this application?</span>
          <Controller
            control={form.control}
            {...register('coReprocessNextSteps')}
            render={(props) => (
              <MultiSelect
                options={coReprocessNextSteps}
                className="mx-1"
                onChange={(option: Record<string, string>[]) => props.field.onChange(option.map((o) => o.value))}
                {...props}
              />
            )}
          />
        </div>
        <div className="mt-6 flex flex-row items-end justify-center gap-8">
          <Button variant="tertiary" onClick={handleCancel} size="medium" className="!min-w-fit">
            Cancel
          </Button>
          <Button type="submit" alignIcon="end" variant="primary" size="medium" isLoading={isSubmitting} hasShadow>
            Process Application
          </Button>
        </div>
      </Form>
      <Dialog
        open={isConfirmModalOpen}
        onOpenChange={() => {
          setIsConfirmModalOpen(!isConfirmModalOpen);
        }}
        title={alertMessage}
        modal
      ></Dialog>
    </>
  );
};

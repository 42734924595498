import { AppState, RedirectLoginOptions, useAuth0, User } from '@auth0/auth0-react';

export const useAuth = () => {
  const {
    loginWithRedirect,
    logout: logoutBase,
    user,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
  } = useAuth0();
  const login = (options?: RedirectLoginOptions<AppState>) =>
    loginWithRedirect({ appState: { returnTo: window.location.href }, ...options });
  const logout = () => logoutBase({ logoutParams: { returnTo: window.location.origin } });

  const getUserToken = () => getAccessTokenSilently({ detailedResponse: true });

  const getUserRoles = (user: User): string[] => {
    const namespace = 'https://stellare.harmoney.com/roles';

    const result = user?.[namespace] ?? [];

    return result as string[];
  };
  const getIdpIdentifier = (user: User): string => {
    return user?.sub ?? '';
  };

  return { login, logout, isLoading, isAuthenticated, user, getUserRoles, getUserToken, getIdpIdentifier };
};

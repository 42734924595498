import { useMemo } from 'react';
import { DeclineCheckResultDto, loanStatusMapper } from '@harmoney/api-interfaces';
import { LoanApplicationQueryResponse } from '@harmoney/redux';
import { CollapsibleCard } from '@harmoney/ui-design-system';
import { amountOrNA, convertMonthsToYears, DATE_TIME_FORMAT, isCancelled } from '@harmoney/ui-utils';
import { LoanApplicationStatusEnum, ScorecardOperation, UmiCalculationOperation } from '@prisma/client';
import { Prisma } from '@prisma/client';
import dayjs from 'dayjs';

interface DecisionDetailsProps {
  affordabilityData: UmiCalculationOperation;
  loanApplicationData: LoanApplicationQueryResponse;
  lowestOffer: number;
  offerData: Prisma.JsonValue;
  scorecardData: ScorecardOperation[];
  rootData: Prisma.JsonValue;
  extraLimitData?: Prisma.JsonValue;
}

export const DecisionDetails = ({
  affordabilityData,
  loanApplicationData,
  offerData,
  rootData,
  extraLimitData = {},
}: DecisionDetailsProps) => {
  const declineReasons = useMemo(() => {
    if (rootData?.['declineCheckResult']) {
      return (rootData?.['declineCheckResult'] as DeclineCheckResultDto[]).filter((item) => {
        return (
          item &&
          (item.applicationStatus === LoanApplicationStatusEnum.application_unsuccessful.toString() ||
            item.applicationStatus === LoanApplicationStatusEnum.quote_unsuccessful.toString())
        );
      });
    }
    return ((affordabilityData?.declineCheckResult ?? []) as DeclineCheckResultDto[]).filter((item) => {
      return item && item.applicationStatus === LoanApplicationStatusEnum.quote_unsuccessful.toString();
    });
  }, [affordabilityData?.declineCheckResult, rootData]);

  return (
    <CollapsibleCard title="Decision" className="mb-6">
      <div className="grid grid-cols-1 px-4">
        <div className="p-4">
          <span className="grid grid-cols-2">
            <span>Decision:</span>
            <span>{loanStatusMapper[loanApplicationData?.status] ?? 'N/A'}</span>
          </span>
          {isCancelled(loanApplicationData?.status) && (
            <span className="grid grid-cols-2">
              <span>Cancellation reason:</span>
              <span>
                {loanApplicationData?.cancellationReason}
                {loanApplicationData?.cancellationReasonOtherText
                  ? `, ${loanApplicationData?.cancellationReasonOtherText}`
                  : ''}
              </span>
            </span>
          )}
          <span className="grid grid-cols-2">
            <span>Reason for Unsuccessful:</span>
            <ul>
              {declineReasons?.length > 0
                ? declineReasons.map(({ reason }, index) => (
                    <li key={index} className="list-disc">
                      {reason}
                    </li>
                  ))
                : 'N/A'}
            </ul>
          </span>
          <span className="grid grid-cols-2">
            <span>Decision date:</span>
            <span>{affordabilityData ? dayjs(affordabilityData?.updatedAt).format(DATE_TIME_FORMAT) : 'N/A'}</span>
          </span>
          <span className="grid grid-cols-2">
            <span>Product Type:</span>
            <span>{offerData ? offerData?.['selectedOffer'] : 'N/A'}</span>
          </span>
          <span className="grid grid-cols-2">
            <span className="font-bold">Initial quote presented amount:</span>
            {(extraLimitData['extraLimitOutput']?.['quotePresentedAmount'] ||
              loanApplicationData?.quotePresentedAmount) && (
              <span>
                {amountOrNA(
                  extraLimitData['extraLimitOutput']?.['quotePresentedAmount'] ||
                    loanApplicationData?.quotePresentedAmount
                )}
              </span>
            )}
            {!extraLimitData['extraLimitOutput']?.['quotePresentedAmount'] &&
              !loanApplicationData?.quotePresentedAmount && (
                <span>{amountOrNA(rootData?.['quote']?.['maximumBorrowingLimit'])}</span>
              )}
          </span>
          <span className="grid grid-cols-2">
            <span className="font-bold">Original loan purpose:</span>
            <span>{loanApplicationData?.loanApplicationPurposes[0]?.loanPurpose?.displayName}</span>
          </span>
          <span className="grid grid-cols-2">
            <span className="font-bold">Customer requested amount:</span>
            <span>{amountOrNA(loanApplicationData?.requestedAmount)}</span>
          </span>
          <span className="grid grid-cols-2">
            <span className="font-bold">Final customer accepted amount:</span>
            <span>{amountOrNA(loanApplicationData?.fundedAmount)}</span>
          </span>
          {loanApplicationData?.loanApplicationPurposes.length > 0 &&
            loanApplicationData?.loanApplicationPurposes.map((loanApplicationPurpose) => {
              return (
                <span className="grid grid-cols-2" key={loanApplicationPurpose.id}>
                  {loanApplicationPurpose.loanPurposeId && (
                    <>
                      <span className="font-bold">{loanApplicationPurpose.loanPurpose.displayName} accepted:</span>
                      <span>
                        {loanApplicationPurpose.fundedAmount
                          ? amountOrNA(loanApplicationPurpose.fundedAmount)
                          : amountOrNA(loanApplicationData.fundedAmount)}
                      </span>
                    </>
                  )}
                </span>
              );
            })}
          <span className="grid grid-cols-2">
            <span className="font-bold">Customer accepted term:</span>
            <span>{convertMonthsToYears(loanApplicationData?.termInMonths)}</span>
          </span>
          <span className="grid grid-cols-2">
            <span className="font-bold">Customer accepted interest rate:</span>
            <span>{loanApplicationData?.finalInterestRate ? loanApplicationData?.finalInterestRate + '%' : 'N/A'}</span>
          </span>
        </div>
      </div>
    </CollapsibleCard>
  );
};

// eslint-disable-next-line simple-import-sort/imports
import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { Swiper, SwiperProps, SwiperRef, SwiperSlide } from 'swiper/react';
import { FreeMode, Mousewheel } from 'swiper/modules';

import { IconV2 as Icon } from '../Icon';

import 'swiper/css';
import 'swiper/css/free-mode';

type CardProps = {
  icon: string;
  iconClassName?: string;
  className?: string;
  childrenClassName?: string;
  children: React.ReactNode;
  display?: boolean;
};

const Card = ({ children, icon, iconClassName, className, childrenClassName, display = true }: CardProps) => {
  return (
    <div
      className={classNames(
        className,
        display
          ? 'opacity-100 max-h-full animate-fade-in duration-1000'
          : 'opacity-0 max-h-0 animate-fade-out duration-1000 overflow-hidden',
        'flex p-2 items-start space-x-1 self-stretch border border-grey-2 w-32 rounded-xl transition-all'
      )}
    >
      <Icon icon={icon} className={classNames(iconClassName, 'text-secondary-lighter-1 !w-6 !h-6 shrink-0')} />
      <div className={classNames(childrenClassName, 'text-xs text-grey-4')}>{children}</div>
    </div>
  );
};

type ContainerProps = {
  children: React.ReactNode;
} & SwiperProps;

const Container = forwardRef<SwiperRef, ContainerProps>(({ children, ...rest }, ref) => {
  return (
    <Swiper
      modules={[FreeMode, Mousewheel]}
      spaceBetween={16}
      slidesPerView={'auto'}
      slidesOffsetAfter={16}
      slidesOffsetBefore={16}
      freeMode
      mousewheel
      watchOverflow
      ref={ref}
      {...rest}
    >
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child) && child.type === Card && child.props.display === false) {
          return null;
        }
        return (
          <SwiperSlide key={index} className="!w-fit !overflow-auto">
            {child}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
});

export const Benefits = {
  Card,
  Container,
};

export type { CardProps, ContainerProps };

import {
  DEFAULT_WHOLE_NUMBER_FRACTION_DIGITS,
  determineFractionDigits,
  FractionDigitsForWholeNumber,
} from './format-number';

export const formatCurrency = (
  value: number,
  fractionDigitsForWholeNumber: FractionDigitsForWholeNumber = DEFAULT_WHOLE_NUMBER_FRACTION_DIGITS
): string => {
  const options = {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: determineFractionDigits(value, fractionDigitsForWholeNumber),
    maximumFractionDigits: determineFractionDigits(value, fractionDigitsForWholeNumber),
  } as Intl.NumberFormatOptions;

  return new Intl.NumberFormat('en-NZ', options).format(value);
};

import { DisbursementDto, DisbursementRecordDto } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const disbursementApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDisbursementDetail: builder.query<DisbursementDto, string>({
      query: (applicationId) => ({
        url: '/payments/disbursal-transaction',
        method: 'GET',
        params: {
          applicationId,
        },
      }),
    }),
    getAllDisbursementDetail: builder.query<DisbursementDto[], string>({
      query: (applicationId) => ({
        url: '/payments/disbursal-transactions',
        method: 'GET',
        params: {
          applicationId,
        },
      }),
    }),
    getDisbursementRecords: builder.query<DisbursementRecordDto[], string>({
      query: (applicationId) => ({
        url: '/disbursement',
        method: 'GET',
        params: {
          applicationId,
        },
      }),
    }),
    checkIfBPayDisbursementPresent: builder.query<boolean, string>({
      query: (applicationId) => ({
        url: '/disbursement/check/bpay',
        method: 'GET',
        params: {
          applicationId,
        },
      }),
    }),
  }),
});

export const {
  useGetDisbursementDetailQuery,
  useGetDisbursementRecordsQuery,
  useCheckIfBPayDisbursementPresentQuery,
  useGetAllDisbursementDetailQuery,
} = disbursementApi;

import { generateIcon } from '../../generateIcon';
import { IconVariant } from '../../Icon';

export const TaxBillIcon = function ({ variant = 'grey-4' }: { variant?: IconVariant }) {
  const Component = generateIcon({
    viewBox: '0 0 24 24',
    variant: variant,
    path: (
      <>
        <g clipPath="url(#clip0_4222_53335)">
          <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12.88 17.76V18.12C12.88 18.6 12.49 19 12 19C11.52 19 11.12 18.61 11.12 18.12V17.7C10.49 17.55 9.19 17.09 8.43 15.6C8.2 15.16 8.42 14.61 8.88 14.42L8.95 14.39C9.36 14.22 9.82 14.39 10.03 14.78C10.35 15.39 10.98 16.15 12.15 16.15C13.08 16.15 14.13 15.67 14.13 14.54C14.13 13.58 13.43 13.08 11.85 12.51C10.75 12.12 8.5 11.48 8.5 9.2C8.5 9.1 8.51 6.8 11.12 6.24V5.88C11.12 5.39 11.52 5 12 5C12.48 5 12.88 5.39 12.88 5.88V6.25C13.95 6.44 14.63 7.01 15.04 7.55C15.38 7.99 15.2 8.63 14.68 8.85C14.32 9 13.9 8.88 13.66 8.57C13.38 8.19 12.88 7.8 12.06 7.8C11.36 7.8 10.25 8.17 10.25 9.19C10.25 10.14 11.11 10.5 12.89 11.09C15.29 11.92 15.9 13.14 15.9 14.54C15.9 17.17 13.4 17.67 12.88 17.76Z" />
        </g>
        <defs>
          <clipPath id="clip0_4222_53335">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </>
    ),
  });
  return <Component />;
};

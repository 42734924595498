import { useFriendlyURL } from '@harmoney/hooks';
import { useGetLoanApplicationQuery, useGetVariablesQuery } from '@harmoney/redux';
import { ArrowCircleRightIcon, Button, LottieConfetti, Spinner } from '@harmoney/ui-design-system';
import { COMPONENT_NAME, componentDefaultCopyText } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';

import { CommonProps } from '../../common-props';

export function MoneyDisbursingForUnutilisedLimit({
  taskId,
  completeTask,
  copy = componentDefaultCopyText[COMPONENT_NAME.MONEY_DISBURSING],
  taskFriendlyURL,
}: CommonProps) {
  const { data: variables } = useGetVariablesQuery(taskId);
  const { data: loanApplicationData, isLoading: isLoanApplicationDataLoading } = useGetLoanApplicationQuery(
    variables?.loanApplicationId.toString(),
    {
      skip: !variables,
    }
  );

  useFriendlyURL(taskFriendlyURL);

  if (isLoanApplicationDataLoading || !loanApplicationData?.fundedAmount) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col items-center">
      <div className="relative mb-16">
        <h1 className="text-center">
          <span className="text-[2.25rem]">{copy['title']}</span>
          <br />
          <span className="text-secondary text-[4rem]">{formatCurrency(loanApplicationData?.fundedAmount)}</span>
          <br />
          <span>{copy['subtitle']}</span>
        </h1>
        <LottieConfetti className="absolute top-[-5%] left-[15%]" />
      </div>
      <Button
        className="mb-4"
        as="a"
        href="/dashboard"
        alignIcon="end"
        icon={<ArrowCircleRightIcon size="large" />}
        variant="primary"
        hasShadow
        onClick={() => completeTask({ taskId })}
      >
        {copy['ctaText']}
      </Button>
      <p>{copy['tipText']}</p>
    </div>
  );
}

import { NetworthSourceEnum } from '@harmoney/api-interfaces';

export const getAssetDetailsByNetWorthSourceId = (assetTypes, networthSourceId: NetworthSourceEnum) => {
  return assetTypes?.find((asset) => asset.id === Number(networthSourceId));
};

export const AssetsOrderMap = {
  [NetworthSourceEnum.ASSET_PROPERTY_LAND_ID]: 1,
  [NetworthSourceEnum.ASSET_VEHICLE_ID]: 2,
  [NetworthSourceEnum.ASSET_SAVINGS_ID]: 3,
  [NetworthSourceEnum.ASSET_SHARES_BONDS_FUNDS_ID]: 4,
  [NetworthSourceEnum.ASSET_BOAT_ID]: 5,
  [NetworthSourceEnum.ASSET_OTHER_ID]: 6,
  [NetworthSourceEnum.ASSET_NO_ID]: 7,
};

export const correctOrderOfAssetTypes = (assetTypes, orderMap: { [key: number]: number }) => {
  return [...assetTypes].sort((a, b) => {
    const orderA = orderMap[a.id] ?? a.id;
    const orderB = orderMap[b.id] ?? b.id;
    return orderA - orderB;
  });
};

import { AgreementRangeOptionsEnum, COAuditDecisionEnum, MoreLessEnum } from '@prisma/client';

export const approvedOptions = [
  {
    label: 'approved',
    value: COAuditDecisionEnum.APPROVED,
  },
  {
    label: 'unsuccessful',
    value: COAuditDecisionEnum.UNSUCCESSFUL,
  },
];

export const moreLessOptions = [
  {
    label: 'More',
    value: MoreLessEnum.MORE,
  },
  {
    label: 'Less',
    value: MoreLessEnum.LESS,
  },
];

export const rangeOptions = [
  {
    label: 'Strongly Disagree',
    value: AgreementRangeOptionsEnum.STRONGLY_DISAGREE,
  },
  {
    label: 'Disagree',
    value: AgreementRangeOptionsEnum.DISAGREE,
  },
  {
    label: 'Neutral',
    value: AgreementRangeOptionsEnum.NEUTRAL,
  },
  {
    label: 'Agree',
    value: AgreementRangeOptionsEnum.AGREE,
  },
  {
    label: 'Strongly Agree',
    value: AgreementRangeOptionsEnum.STRONGLY_AGREE,
  },
];

export const recommendedOptions = [
  {
    label: 'Credit Risk',
    value: 'credit risk',
  },
  {
    label: 'Compliance (Responsible Lending)',
    value: 'compliance',
  },
];

export const mightHaveApprovedOptions = [
  {
    label: 'Co-borrower',
    value: 'coBorrower',
  },
  {
    label: 'Debt con included',
    value: 'debtCon included',
  },
  {
    label: 'Nothing would change my mind',
    value: 'nothing',
  },
];

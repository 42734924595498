import { Fragment, useMemo, useState } from 'react';
import { essentialExpenseMapper, NetworthSourceEnum } from '@harmoney/api-interfaces';
import { FinancialConfirmationSectionEnum } from '@harmoney/hooks';
import { Alert, Button, Card, CurrencyWithFrequency, Divider, Label } from '@harmoney/ui-design-system';

import { FinancialProfileProps } from './financial-profile-props';

export const EssentialExpenses = ({
  financialSummary,
  confirmedSections,
  onConfirmSection: handleConfirmSection,
  onUpdate: handleUpdate,
}: FinancialProfileProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUpdateInfo = async () => {
    try {
      setIsSubmitting(true);
      await handleUpdate(FinancialConfirmationSectionEnum.EssentialExpenses);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };

  const displayUpToDate = useMemo(() => {
    return (
      confirmedSections?.includes(FinancialConfirmationSectionEnum.EssentialExpenses) &&
      confirmedSections[confirmedSections.length - 1] === FinancialConfirmationSectionEnum.EssentialExpenses
    );
  }, [confirmedSections?.length]);

  return (
    <Card className="!p-0">
      <div className="flex items-center justify-between">
        <Label className="p-4 text-lg font-medium">Essential expenses</Label>
      </div>
      <Divider className="text-grey-2 m-0 p-0" />

      {financialSummary?.expenses
        .filter((expense) => expense.isEssentialExpense)
        .map(
          ({
            id,
            declaredAmount,
            frequency,
            otherExpenseType,
            declaredTotalAmount,
            frequencyForTotalAmount,
            networthSourceId,
            isExpenseShared,
          }) => (
            <Fragment key={`expenses-${id}`}>
              {isExpenseShared ? (
                <div className="flex flex-col p-4">
                  <Label>
                    {networthSourceId === NetworthSourceEnum.EXPENSE_NOT_LISTED_ID
                      ? otherExpenseType
                      : essentialExpenseMapper[networthSourceId]}
                  </Label>

                  <ul className="mt-2 text-sm">
                    <li className="flex justify-between">
                      <span>Total cost</span>
                      <CurrencyWithFrequency
                        amount={declaredTotalAmount}
                        frequency={frequencyForTotalAmount}
                        className="font-medium"
                      />
                    </li>
                    <li className="flex justify-between">
                      <span>Your share</span>
                      <CurrencyWithFrequency amount={declaredAmount} frequency={frequency} className="font-medium" />
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="flex justify-between p-4">
                  <Label>
                    {networthSourceId === NetworthSourceEnum.EXPENSE_NOT_LISTED_ID
                      ? otherExpenseType
                      : essentialExpenseMapper[networthSourceId]}
                  </Label>
                  <CurrencyWithFrequency amount={declaredAmount} frequency={frequency} className="font-medium" />
                </div>
              )}
              <Divider className="text-grey-1 my-0 px-4 pb-4" />
            </Fragment>
          )
        )}
      <div className="flex flex-col gap-4 px-2 pb-6 items-center">
        {confirmedSections.includes(FinancialConfirmationSectionEnum.EssentialExpenses) ? (
          displayUpToDate && (
            <Alert variant="success" className="mb-0 sm:max-w-[20.5rem] w-full" autoDismissInMilliseconds={1500}>
              <p className="text-sm">Up to date</p>
            </Alert>
          )
        ) : (
          <Button
            type="button"
            alignIcon="end"
            variant="primary"
            disabled={isSubmitting}
            onClick={() => handleConfirmSection(FinancialConfirmationSectionEnum.EssentialExpenses)}
          >
            That&rsquo;s right
          </Button>
        )}
        <Button type="button" variant="outline-secondary" hasShadow isLoading={isSubmitting} onClick={handleUpdateInfo}>
          Update my info
        </Button>
      </div>
    </Card>
  );
};

import { DebtType as LiabilityType } from '@harmoney/api-interfaces';
import { LiabilityProvider } from '@harmoney/utilities';
import { capitalize, toUpper } from 'lodash';

export function formatProviderName(provider: string, otherProvider: string, networthSourceName: string) {
  if (networthSourceName === LiabilityType.PERSONAL_LOAN && provider.startsWith(LiabilityProvider.Harmoney)) {
    return `${provider} loan`;
  }
  if (networthSourceName === LiabilityType.OTHER_DEBTS) {
    return `${toUpper(otherProvider)} ${capitalize(networthSourceName)}`;
  }
  return `${toUpper(provider)} ${capitalize(networthSourceName)}`;
}

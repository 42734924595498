import { useEffect, useState } from 'react';
import { useFriendlyURL } from '@harmoney/hooks';
import { useAppSelector, useCompleteTaskMutation } from '@harmoney/redux';
import { eventAnalytics, FINANCIALPROFILE_BANKPROFILE_UPLOADED } from '@harmoney/ui-app-shell';
import { Alert, ArrowCircleRightIcon, Button, IconV2, PendingBankStatementIcon } from '@harmoney/ui-design-system';

import { CommonProps } from '../../common-props';

import { BankStatementNotificationType } from './bank-statement-notification-type';

export interface BankStatementNotificationProps extends CommonProps {
  status: BankStatementNotificationType;
  processing: boolean;
}

const contentMap = {
  [BankStatementNotificationType.WAITING]: (
    <>
      <div className="my-6 flex justify-center">
        <PendingBankStatementIcon />
      </div>
      <Alert
        variant="warning"
        title="Some statements are pending"
        icon={<IconV2 icon="ic:round-hourglass-top" className="text-warning" width={24} />}
      >
        <p>
          We&rsquo;re currently waiting for some data, if we don&rsquo;t receive it by the time you finish your
          application we&rsquo;ll let you know what to do next.
        </p>
        <p>For now, let&rsquo;s keep going.</p>
      </Alert>
    </>
  ),
  [BankStatementNotificationType.ALREADY_PROVIDED]: (
    <p>You&rsquo;ve already provided recent bank statements, you can go ahead and continue with your application.</p>
  ),
  [BankStatementNotificationType.RECEIVED]: <p>You&rsquo;ve successfully connected your banks.</p>,
};

export function BankStatementNotification({ taskId, status, taskFriendlyURL }: BankStatementNotificationProps) {
  const [processing, setProcessing] = useState(false);
  const userId = useAppSelector((state) => state.userId.value);
  const [completeTask] = useCompleteTaskMutation();
  const handleNext = async (e) => {
    setProcessing(true);
    eventAnalytics.track(FINANCIALPROFILE_BANKPROFILE_UPLOADED, {
      userid_str: userId,
      taskid_str: taskId,
      status: status,
    });
    await completeTask({ taskId });
  };

  useEffect(() => {
    return () => {
      setProcessing(false);
    };
  }, []);

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      <h1 className="mb-6">
        <span className="text-primary">Thank you</span> for sharing your bank statements
      </h1>
      {contentMap[status]}
      <div className="flex justify-center">
        <Button
          alignIcon="end"
          variant="primary"
          icon={<ArrowCircleRightIcon size="large" />}
          isLoading={processing}
          onClick={handleNext}
          hasShadow
        >
          Continue
        </Button>
      </div>
    </>
  );
}

export const withBankStatementStatus = (WrappedComponent, status: BankStatementNotificationType, taskId) => {
  return <WrappedComponent status={status} taskId={taskId.taskId} />;
};

export function BankStatementNotificationApproved(taskId) {
  return withBankStatementStatus(BankStatementNotification, BankStatementNotificationType.ALREADY_PROVIDED, taskId);
}

export function BankStatementNotificationWaiting(taskId) {
  return withBankStatementStatus(BankStatementNotification, BankStatementNotificationType.WAITING, taskId);
}

/**
 * This file is the style to customise the Smart UI's appearance
 * More details see https://apidocs.frankiefinancial.com/docs/custom-styling
 */
export const FrankieSmartUIStyle = `
  #ff-onboarding-widget,
  #ff-onboarding-widget .ff-review-input .ff-form {
    background-color: transparent !important;
  }

  #ff-onboarding-widget .ff-document-form-description {
    padding: 0 !important;
    margin-bottom: var(--spacing-4) !important
  }

  #ff-onboarding-widget .ff-title {
    font-family: var(--font-family-heading) !important;
    font-size: var(--font-size-h1) !important;
    font-weight: 700 !important;
    color: var(--colour-brand-primary) !important;
    min-height: 0 !important;
    margin: 0 !important;
  }

  #ff-onboarding-widget .ff-outcome-message .ff-title {
    font-family: var(--font-family-body) !important;
    font-size: var(--font-size-base) !important;
    font-weight: 500 !important;
    color: var(--colour-grey-5) !important;
  }

  #ff-onboarding-widget .ff-welcome-view {
    display: none !important;
  }

  #ff-onboarding-widget,
  #ff-onboarding-widget p,
  #ff-onboarding-widget div,
  #ff-onboarding-widget input,
  #ff-onboarding-widget textarea,
  #ff-onboarding-widget select,
  #ff-onboarding-widget .ff-text-link {
    font-family: var(--font-family-body) !important;
    font-size: var(--font-size-base) !important;
    font-weight: 400 !important;
    color: var(--colour-grey-5) !important;
  }

  #ff-onboarding-widget .ff-text-link {
    color: var(--colour-brand-secondary) !important;
    margin-top: var(--spacing-2) !important;
  }
  #ff-onboarding-widget .ff-text-link:hover {
    color: var(--colour-brand-secondary-darker-1) !important;
  }

  #ff-onboarding-widget .ff-feedback.ff-display-error.ff-errors {
    color: var(--colour-utility-error) !important;
  }

  #ff-onboarding-widget .ff-main-label {
    font-weight: 500 !important;
  }

  #ff-onboarding-widget .ff-hint-label,
  #ff-onboarding-widget .helper-text .icon-outline {
    display: none !important; 
  }

  #ff-onboarding-widget .helper-text .document-number-info-text {
    display: block !important; 
  }

  #ff-onboarding-widget .ff-form,
  #ff-onboarding-widget .ff-form .vs__selected-options,
  #ff-onboarding-widget .ff-form .vs__selected,
  #ff-onboarding-widget .ff-form .vs__search,
  #ff-onboarding-widget .ff-form .ff-stack,
  #ff-onboarding-widget .ff-form .ff-scrollable,
  #ff-onboarding-widget .ff-form .ff-stack .ff-document-type {
    padding: 0 !important;
    margin: 0 !important;
  }

  #ff-onboarding-widget .ff-native-select-icon {
    top: 46px !important;
  }

  #ff-onboarding-widget .ff-review-input .ff-document-review-input:last-child {
    margin-bottom: 0 !important;
  }

  #ff-onboarding-widget .ff-form .ff-feedback,
  #ff-onboarding-widget .ff-form .ff-text-link {
    margin-top: var(--spacing-2) !important;
  }

  #ff-onboarding-widget .ff-form .ff-review-summary {
    background-color: var(--colour-white) !important;
  }
  #ff-onboarding-widget .ff-form .ff-summary-card .ff-review-title {
    font-weight: 500 !important;
  }
  #ff-onboarding-widget .ff-form .ff-summary-card .ff-review-label {
    color: var(--colour-grey-3) !important;
  }

  #ff-onboarding-widget .ff-consent-input .ff-the-tick {
    border: 1px solid var(--colour-grey-3) !important;
    border-radius: var(--border-radius-default) !important;
  }
  #ff-onboarding-widget .ff-consent-input .ff-the-tick:hover {
    border-color: var(--colour-brand-tertiary) !important;
  }
  #ff-onboarding-widget .ff-consent-input .checked .ff-the-tick {
    border-color: var(--colour-brand-tertiary) !important;
  }
  #ff-onboarding-widget .ff-consent-input .checked .ff-the-tick .ff-icon {
    border-radius: var(--border-radius-default) !important;
    background-color: var(--colour-brand-tertiary) !important;
  }

  #ff-onboarding-widget .ff-details-review .bottom-drawer .ff-dialog-content {
    bottom: 80px !important;
  }

  #ff-onboarding-widget .ff-buttton-wrapper {
    margin: var(--spacing-6) 0 !important;
    padding: 0 !important;
    box-shadow: none !important;
    background: transparent !important;
    display: flex !important;
    gap: 8px !important;
    align-items: center !important;
    justify-content: center !important;
  }

  #ff-onboarding-widget .ff-basic-button {
    padding: 0.875rem var(--spacing-6) !important;
    border-radius: var(--border-radius-pill) !important;
    border: 2px solid transparent !important;
    background-color: var(--colour-brand-primary) !important;
    color: var(--colour-white) !important;
    font-family: var(--font-family-mono) !important;
    font-size: var(--font-size-base) !important;
    font-weight: 500 !important;
    text-transform: uppercase !important;
    letter-spacing: 0.16em !important;
    cursor: pointer !important;
    text-decoration: none !important;
    box-shadow: none !important;
    transition: all 0.2s ease-in-out !important;
    --tw-shadow: var(--box-shadow-drop);
    --tw-shadow-colored: var(--box-shadow-drop);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }
  #ff-onboarding-widget .ff-basic-button[disabled],
  #ff-onboarding-widget .ff-basic-button[disabled]:hover {
    background-color: var(--colour-grey-2) !important;
    border-color: var(--colour-grey-2) !important;
    color: var(--colour-grey-5) !important;
    cursor: not-allowed !important;
    box-shadow: none !important;
  }
  #ff-onboarding-widget .ff-basic-button:focus-visible {
    background-color: var(--colour-brand-primary-darker-2) !important;
  }
  #ff-onboarding-widget .ff-basic-button:hover {
    background-color: var(--colour-brand-primary-darker-1) !important;
    box-shadow: 0px 4px 12px rgb(178 178 178 / 70%) !important;
  }
  #ff-onboarding-widget .ff-basic-button:active,
  #ff-onboarding-widget .ff-basic-button:focus-visible {
    background-color: var(--colour-brand-primary-darker-2) !important;
  }

  #ff-onboarding-widget .ff-review-buttons .ff-cancel {
    border: transparent !important;
    color: var(--colour-brand-secondary) !important;
    background: var(--colour-white) !important;
  }
  #ff-onboarding-widget .ff-review-buttons .ff-cancel:hover {
    background: var(--colour-secondary-lighter-3) !important;
  }
  #ff-onboarding-widget .ff-review-buttons .ff-cancel:active,
  #ff-onboarding-widget .ff-review-buttons .ff-cancel:focus-visible {
    background: var(--colour-secondary-lighter-2) !important;
  }

  #ff-onboarding-widget .ff-basic-button {
    width: 328px !important;
  }
  #ff-onboarding-widget .ff-review-buttons .ff-cancel,
  #ff-onboarding-widget .ff-review-buttons .ff-save {
    width: 228px !important;
  }

  #ff-onboarding-widget .ff-document-type-button {
    border: 1px solid var(--colour-grey-3) !important;
    background: var(--colour-white) !important;
    border-radius: var(--border-radius-lg) !important;
  }
  #ff-onboarding-widget .ff-document-type-button:hover {
    border-color: var(--colour-brand-secondary) !important;
    background: var(--colour-brand-secondary-lighter-3) !important;
  }
  #ff-onboarding-widget .ff-goahead-button.ff-document-type-button[disabled] {
    border-color: var(--colour-grey-2) !important;
    background: var(--colour-grey-2) !important;
  }

  #ff-onboarding-widget .ff-review-input .ff-review-buttons {
    display: flex !important;
    flex-direction: column !important;
    column-gap: 8px !important;
    align-items: center !important;
  }
  #ff-onboarding-widget .ff-review-input .ff-review-buttons button:first-child {
    margin-right: 0px !important;
  }

  #ff-onboarding-widget a {
    color: var(--colour-brand-secondary) !important;
    text-decoration: none !important;
  }
  #ff-onboarding-widget a:hover {
    color: var(--colour-brand-secondary-darker-1) !important;
  }

  #ff-onboarding-widget .ff-passport-number {
    margin-bottom: var(--spacing-4) !important;
  }

  #ff-onboarding-widget .ff-review-input .ff-applicant-review-input.ff-summary-card {
    margin-top: var(--spacing-4) !important;
  }
  
  @media (min-width: 480px) {
    #ff-onboarding-widget .ff-basic-button {
      max-width: 328px !important;
      min-width: fit-content !important;
      margin: 0 auto !important;
      justify-content: center !important;
    }
    #ff-onboarding-widget .ff-review-input .ff-review-buttons {
      display: flex !important;
      flex-direction: column !important;
      column-gap: 8px !important;
      align-items: center !important;
    }
    #ff-onboarding-widget .ff-review-buttons .ff-cancel,
    #ff-onboarding-widget .ff-review-buttons .ff-save {
      max-width: 228px !important;
    }
    #ff-onboarding-widget .ff-review-input .ff-review-buttons button:first-child {
      margin: 0px !important;
    }
  }
  @media (min-width: 720px) {
    #ff-onboarding-widget .ff-basic-button {
      min-width: 328px !important;
      margin: 0 auto !important;
      justify-content: center !important;
    }
    #ff-onboarding-widget .ff-review-input .ff-review-buttons {
      display: flex !important;
      flex-direction: row !important;
      gap: 8px !important;
      align-items: center !important;
    }
    #ff-onboarding-widget .ff-review-buttons .ff-cancel,
    #ff-onboarding-widget .ff-review-buttons .ff-save {
      max-width: fit-content !important;
      min-width: 228px !important;
    }
    #ff-onboarding-widget .ff-review-input .ff-review-buttons button:first-child {
      margin-right: 20px !important;
    }
  }
`;

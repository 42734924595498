import { RepaymentFrequencyEnum } from '@prisma/client';
import dayjs from 'dayjs';

export const getNextPaymentDate = (startDate: string, frequency: RepaymentFrequencyEnum) => {
  let nextPaymentDate: dayjs.Dayjs;

  switch (frequency) {
    case RepaymentFrequencyEnum.monthly:
      nextPaymentDate = dayjs(startDate).add(1, 'month');
      break;
    case RepaymentFrequencyEnum.weekly:
      nextPaymentDate = dayjs(startDate).add(1, 'week');
      break;
    case RepaymentFrequencyEnum.fortnightly:
      nextPaymentDate = dayjs(startDate).add(2, 'week');
      break;
    default:
      throw new Error('Invalid payment frequency');
  }

  return nextPaymentDate.format('YYYY-MM-DD');
};

import { ResidentialStatusEnum } from '../user';

export const livingSituationLabelMapper: Record<ResidentialStatusEnum, string> = {
  [ResidentialStatusEnum.HOME_OWNER]: 'Home owner',
  [ResidentialStatusEnum.RENTING]: 'Tenant',
  [ResidentialStatusEnum.BOARDER]: 'Boarder',
  [ResidentialStatusEnum.LIVE_WITH_PARENTS]: 'Living with parents',
  [ResidentialStatusEnum.GOVERNMENT_HOUSING]: 'Government housing',
  [ResidentialStatusEnum.COMPANY_HOUSING]: 'Company housing',
};

export const rentalSituationLabelMapper: Partial<Record<ResidentialStatusEnum, string>> = {
  [ResidentialStatusEnum.RENTING]: 'rent',
  [ResidentialStatusEnum.BOARDER]: 'board',
  [ResidentialStatusEnum.LIVE_WITH_PARENTS]: 'accommodation',
  [ResidentialStatusEnum.GOVERNMENT_HOUSING]: 'accommodation',
  [ResidentialStatusEnum.COMPANY_HOUSING]: 'accommodation',
};

import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { ICancellationReasonFields } from '@harmoney/api-interfaces';
import { useCancelQuote } from '@harmoney/hooks';
import { ArrowCircleRightIcon, Button, Dialog, Form, Select, Textarea, useForm } from '@harmoney/ui-design-system';
import { LoanApplicationStatusEnum } from '@prisma/client';
import { EntryCollection } from 'contentful';
import { z } from 'zod';

type ProductName = 'personal loan' | 'debt consolidation';
type ValidApplicationStatus = 'quote_in_progress' | 'quote_offered' | 'application_in_progress';

type Props = {
  type: 'quote' | 'application';
  applicationId: string;
  applicationStatus: LoanApplicationStatusEnum;
  productName: string;
  title?: string;
  primaryCTAText?: string;
  secondaryCTAText?: string;
  canSelectReason?: boolean;
  reasons?: EntryCollection<ICancellationReasonFields>;
  showCancelQuoteDialog: boolean;
  setCancelQuoteDialog?: Dispatch<SetStateAction<boolean>>;
};

const formSchema = (canSelectReason, otherReasonId) =>
  z
    .object({
      reasonId: z.string().nullable().optional(),
      otherReasonText: z.string().max(250, 'Please enter less than 250 characters').optional(),
    })
    .superRefine((data, { addIssue }) => {
      if (canSelectReason && !data.reasonId) {
        addIssue({ code: z.ZodIssueCode.custom, message: 'Please select a reason', path: ['reasonId'] });
      }
      if (data.reasonId === otherReasonId && !data.otherReasonText) {
        addIssue({ code: z.ZodIssueCode.custom, message: 'Please provide details', path: ['otherReasonText'] });
      }
    });

export const CancelQuoteDialog = ({
  title,
  type,
  applicationId,
  applicationStatus,
  productName,
  primaryCTAText,
  secondaryCTAText,
  canSelectReason = true,
  reasons,
  setCancelQuoteDialog,
  showCancelQuoteDialog,
}: Props) => {
  const { handleCancelQuote } = useCancelQuote({
    setShowCancelQuoteDialog: setCancelQuoteDialog,
    showCancelQuoteDialog,
  });
  const router = useRouter();

  const formattedReasons = useMemo(() => {
    return reasons?.items
      ?.filter((item) => {
        const { product, status } = item.fields;
        if (
          product.includes(productName.toLowerCase() as ProductName) &&
          status.includes(applicationStatus as ValidApplicationStatus)
        ) {
          return true;
        }
      })
      .map((item) => {
        const { reason } = item.fields;
        return {
          label: reason,
          value: item.sys.id,
        };
      });
  }, [applicationStatus, productName, reasons?.items]);

  const otherReasonId = reasons?.items?.find((reason) => reason.fields.reason === 'Other reason')?.sys.id;

  const form = useForm({
    mode: 'onSubmit',
    schema: formSchema(canSelectReason, otherReasonId),
    defaultValues: {
      reasonId: null,
      otherReasonText: '',
    },
  });

  const { register, watch, reset } = form;

  const handleReasonSubmit = async (data) => {
    const reason = reasons?.items?.find((reason) => reason.sys.id === data.reasonId)?.fields.reason;
    await handleCancelQuote({
      type,
      applicationId,
      cancellationReasonId: data.reasonId,
      cancellationReason: reason,
      cancellationReasonOtherText: data.otherReasonText,
    });
    if (router.pathname !== '/') {
      router.push('/');
    }
  };

  return (
    <Dialog
      open={showCancelQuoteDialog}
      onOpenChange={() => {
        reset();
        setCancelQuoteDialog(!showCancelQuoteDialog);
      }}
      title={title ? title : `Cancel your ${type}`}
      modal
    >
      <div className="p-4">
        <div className="mt-1 mb-8 flex flex-col items-start justify-start">
          {type === 'quote' && <p>By cancelling this quote, you&apos;ll need to start over.</p>}
          {type === 'application' && (
            <p>
              You will <strong>permanently lose</strong> this approval, however, this will{' '}
              <strong>not remove the enquiry</strong> on your credit file.
            </p>
          )}
        </div>
        <Form form={form} onSubmit={handleReasonSubmit}>
          {canSelectReason && (
            <div className="mb-8 flex flex-col gap-4">
              <Select
                {...register('reasonId')}
                label="Please let us know why you're cancelling"
                options={[
                  { label: 'Select a reason', value: '' },
                  ...formattedReasons
                    .filter((reason) => reason.value !== otherReasonId)
                    .sort(() => Math.random() - 0.5),
                  { label: 'Other reason', value: otherReasonId },
                ]}
                displayValidation
              />
              {watch().reasonId === otherReasonId && (
                <Textarea {...register('otherReasonText')} placeholder="Please provide details" />
              )}
            </div>
          )}
          <div className="mb-8 flex flex-col-reverse items-center justify-center gap-2 md:flex-row">
            <Button
              variant="secondary"
              onClick={() => {
                reset();
                setCancelQuoteDialog(false);
              }}
            >
              {secondaryCTAText ? secondaryCTAText : 'No, keep it'}
            </Button>
            <Button alignIcon="end" icon={<ArrowCircleRightIcon size="large" />} variant="primary" type="submit">
              {primaryCTAText ? primaryCTAText : `CANCEL ${type}`}
            </Button>
          </div>
        </Form>
      </div>
    </Dialog>
  );
};

import { LoanVariationTypeEnum, LoanVariationPurposeEnum } from '@prisma/client';
import { LoanVariationFlag } from './loan-variation.dto';

export const loanVariationTypeMapper: Record<LoanVariationTypeEnum, string> = {
  [LoanVariationTypeEnum.ARREAS_CONSOLIDATION]: 'Arrears Consolidation',
  [LoanVariationTypeEnum.CONTRACTUAL_PAYMENT_DATE]: 'Contractual Payment Date',
  [LoanVariationTypeEnum.REPAYMENT_HOLIDAY]: 'Repayment Holiday',
};

export const loanVariationTypeReverseMapper: Record<string, LoanVariationTypeEnum> = {
  'Arrears Consolidation': LoanVariationTypeEnum.ARREAS_CONSOLIDATION,
  'Contractual Payment Date': LoanVariationTypeEnum.CONTRACTUAL_PAYMENT_DATE,
  'Repayment Holiday': LoanVariationTypeEnum.REPAYMENT_HOLIDAY,
};

export const loanVariationPurposeMapper: Record<LoanVariationPurposeEnum, string> = {
  [LoanVariationPurposeEnum.COLLECTIONS]: 'Collections',
  [LoanVariationPurposeEnum.CUSTOMER_SERVICE]: 'Customer Service',
  [LoanVariationPurposeEnum.HARDSHIP]: 'Hardship',
  [LoanVariationPurposeEnum.OTHER]: 'Other',
};

export const loanVariationPurposeReverseMapper: Record<string, LoanVariationPurposeEnum> = {
  Collections: LoanVariationPurposeEnum.COLLECTIONS,
  'Customer Service': LoanVariationPurposeEnum.CUSTOMER_SERVICE,
  Hardship: LoanVariationPurposeEnum.HARDSHIP,
  Other: LoanVariationPurposeEnum.OTHER,
};

export const loanVariationFlagMapper: Record<LoanVariationFlag, string> = {
  [LoanVariationFlag.REPAYMENT_HOLIDAY_FLAG]: 'Repayment Holiday',
  [LoanVariationFlag.DIRECT_DEBIT_SUSPEND_FLAG]: 'Direct Debit',
  [LoanVariationFlag.INTEREST_ACCRUED_PAUSE_FLAG]: 'Interest Accrued',
  [LoanVariationFlag.SCHEDULE_EXTRA_PAYMENT_FLAG]: 'Scheduled extra payments',
  [LoanVariationFlag.UPDATE_DIRECT_DEBIT_FLAG]: 'Updated direct debit plan',
};

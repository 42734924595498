import { useAppSelector, useGetProcessDefinitionQuery, useGetProcessInstanceQuery } from '@harmoney/redux';
import { BpmnRender, Spinner } from '@harmoney/ui-design-system';

type Props = {
  processDefinitionKey: string;
  processInstanceId: string;
};

export const BpmnProgressDetails = ({ processDefinitionKey, processInstanceId }: Props) => {
  const accessToken = useAppSelector((state) => state.accessToken.value);
  const {
    data: processDefinition,
    isLoading,
    isError,
    error,
  } = useGetProcessDefinitionQuery(processDefinitionKey, {
    skip: !accessToken,
  });
  const { data: processInstance, isLoading: isProcessInstanceLoading } = useGetProcessInstanceQuery(processInstanceId, {
    skip: !accessToken,
  });

  if (isProcessInstanceLoading || isLoading) {
    return <Spinner />;
  }

  if (isError) {
    console.error('[BPMN Progress Details Failed to render] error:', error);
  }

  return (
    <div>
      <BpmnRender
        xml={processDefinition}
        processStats={processInstance?.statistics}
        sequenceFlows={processInstance?.sequenceFlow}
      />
    </div>
  );
};

import { EssentialExpenseDetailDto, NetworthSourceEnum } from '@harmoney/api-interfaces';
import { useFriendlyURL } from '@harmoney/hooks';
import {
  useAppSelector,
  useGetEssentialExpensesDetailsQuery,
  useSubmitIncomeByCOMutation,
  useSubmitIncomeMutation,
} from '@harmoney/redux';
import { eventAnalytics, FINANCIALPROFILE_HOUSEHOLD_INCOME_PROVIDED } from '@harmoney/ui-app-shell';
import {
  AmountFrequency,
  ArrowCircleRightIcon,
  Button,
  Card,
  ExpenseSummary,
  Form,
  useForm,
} from '@harmoney/ui-design-system';
import { convertToMonthlyAmount, frequencyOptionsWithYear } from '@harmoney/utilities';
import { IncomeAndExpenseFrequencyEnum } from '@prisma/client';

import { CommonProps, ExtraAdminProps } from '../../common-props';

import { HouseholdIncomeIcon } from './components';
import { defaultValues, FormSchema, formSchema } from './form-config';

export function HouseholdIncome({
  taskId,
  completeTask,
  taskFriendlyURL,
  isCustomerFacing = true,
  customerId,
  financialProfileId,
}: CommonProps & ExtraAdminProps) {
  const userId = useAppSelector((state) => state.userId.value);

  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues,
  });

  const {
    register,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;

  const [saveIncomes] = useSubmitIncomeMutation();
  const [submitIncomeByCO] = useSubmitIncomeByCOMutation({
    fixedCacheKey: `${customerId}-${financialProfileId}-${userId}`,
  });
  const { data: expensesDetailData } = useGetEssentialExpensesDetailsQuery(taskId);

  const sumAmount = (expensesData: EssentialExpenseDetailDto[]): { totalAmount: number; sharedAmount: number } => {
    return expensesData.reduce(
      (acc, expense) => {
        acc.totalAmount +=
          convertToMonthlyAmount(expense.declaredTotalAmount, expense.frequencyForTotalAmount) ||
          convertToMonthlyAmount(expense.declaredAmount, expense.frequency);
        acc.sharedAmount += convertToMonthlyAmount(expense.declaredAmount, expense.frequency);
        return acc;
      },
      { totalAmount: 0, sharedAmount: 0 }
    );
  };

  const handleSubmit = async (data: FormSchema) => {
    const amountData = () => {
      if (isCustomerFacing) {
        return {
          declaredAmount: data.declaredAmount || 0,
          frequency: (data.frequency as IncomeAndExpenseFrequencyEnum) || IncomeAndExpenseFrequencyEnum.monthly,
        };
      } else {
        return {
          creditOfficerFrequency:
            (data.frequency as IncomeAndExpenseFrequencyEnum) || IncomeAndExpenseFrequencyEnum.monthly,
          creditOfficerAmount: data.declaredAmount || 0,
        };
      }
    };
    const incomeData = {
      networthSourceId: NetworthSourceEnum.INCOME_HOUSEHOLD_ID,
      ...amountData(),
    };
    if (isCustomerFacing) {
      await saveIncomes({
        taskId,
        incomes: [incomeData],
      });

      eventAnalytics.track(FINANCIALPROFILE_HOUSEHOLD_INCOME_PROVIDED, {
        userid_str: userId,
        taskid_str: taskId,
      });

      completeTask({ taskId });
    } else {
      await submitIncomeByCO({
        customerId,
        financialProfileId,
        incomes: [incomeData],
      });
    }
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      {isCustomerFacing && (
        <h1>
          Paint a picture of your <span className="text-primary">household</span>
        </h1>
      )}
      <Form form={form} onSubmit={handleSubmit}>
        <Card>
          {expensesDetailData && expensesDetailData.length > 0 && (
            <ExpenseSummary
              title="Your essential household expenses"
              totalAmount={sumAmount(expensesDetailData).totalAmount}
              sharedAmount={sumAmount(expensesDetailData).sharedAmount}
              defaultFrequency={IncomeAndExpenseFrequencyEnum.monthly}
              className="mb-6 hidden"
            />
          )}
          {isCustomerFacing && (
            <>
              <p className="text-sm">We&rsquo;re all about transparency here, so let&rsquo;s spill the beans! 📣</p>
              <p className="text-sm">
                What&rsquo;s the big number that keeps the lights on and the fridge stocked in your household?{' '}
                <HouseholdIncomeIcon />
              </p>
            </>
          )}
          {isCustomerFacing ? (
            <label>
              Share your <span className="font-medium">total household income</span>
            </label>
          ) : (
            <label>Total household income</label>
          )}
          <AmountFrequency
            register={register}
            inputKey="declaredAmount"
            selectKey="frequency"
            options={frequencyOptionsWithYear}
            name="householdIncome"
            className="mt-2"
          />
        </Card>
        <div className="text-center">
          <Button
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
            variant="primary"
            hasShadow
            isLoading={isSubmitting || isSubmitSuccessful}
            type="submit"
          >
            {isCustomerFacing ? 'Continue' : 'Save household income'}
          </Button>
        </div>
      </Form>
    </>
  );
}

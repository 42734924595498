import { Fragment } from 'react';
import { FinancialProfileDto } from '@harmoney/api-interfaces';
import { Badge, CurrencyWithFrequency, Divider, Label } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';
import { capitalize, toUpper } from 'lodash';

export const DebtConDebts = ({ debts }: { debts: FinancialProfileDto['liabilities'] }) => {
  const totalOutstandingBalance = debts?.reduce((sum, liability) => {
    if (typeof liability.outstandingBalance === 'number' && liability.debtConsolidation === true) {
      return sum + liability.outstandingBalance;
    } else {
      return sum;
    }
  }, 0);
  return (
    <>
      <div className="bg-secondary-lighter-3 mx-4 mt-4 rounded-t-lg py-2">
        <Label className="mt-2 px-2 font-medium">Debts you&apos;ve chosen to consolidate</Label>
        <Divider className="text-grey-2 my-2 p-0" />
        {debts?.map(
          (
            {
              repaymentAmount,
              frequency,
              provider,
              creditLimit,
              outstandingBalance,
              interestRate,
              recordOrigin,
              networthSourceName,
              bankReference,
            },
            item
          ) => (
            <Fragment key={`liability-${item}`}>
              <div className="p-2">
                <div className="flex flex-row items-center px-2">
                  {recordOrigin === 'prefill' && (
                    <Badge
                      className="text-xs text-grey-4 mb-1"
                      label={`From ${bankReference ? `bank statement` : `credit file`}`}
                      variant="tertiary"
                    />
                  )}
                </div>
                <div className="flex flex-row items-center px-2 ">
                  <div className="basis-2/3">
                    {toUpper(provider)} {capitalize(networthSourceName)}
                  </div>
                  <div className="flex basis-1/3 justify-end">
                    <span className="font-medium">
                      {outstandingBalance ? formatCurrency(outstandingBalance) : formatCurrency(creditLimit)}
                    </span>
                  </div>
                </div>
                <div className="flex flex-row px-2">
                  <div className="items-center">
                    <span className="text-sm text-grey-3">
                      {interestRate >= 0 && `${interestRate}% p.a.`}
                      {interestRate >= 0 && repaymentAmount && ' | '}
                      {repaymentAmount && repaymentAmount > 0 && (
                        <CurrencyWithFrequency amount={repaymentAmount} frequency={frequency} />
                      )}
                      {repaymentAmount && creditLimit && ' | '}
                      {creditLimit && ` ${formatCurrency(creditLimit)} limit`}
                    </span>
                  </div>
                </div>
              </div>
              <Divider className="text-grey-2" />
            </Fragment>
          )
        )}
      </div>
      <div className="bg-secondary-lighter-3 mx-4 flex flex-row items-center justify-between rounded-b-lg px-4 pb-4 font-medium">
        <span>Debt consolidation total</span>
        <span>{formatCurrency(totalOutstandingBalance)}</span>
      </div>
    </>
  );
};

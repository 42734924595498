import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Role, SaveUserActivityDto } from '@harmoney/api-interfaces';
import { useRole } from '@harmoney/hooks';
import { useAppDispatch, useAppSelector, useSaveUserActivityMutation } from '@harmoney/redux';
import { Alert, CommonOptionProps } from '@harmoney/ui-design-system';
import { UserActivityPurposeEnum, UserActivityTypeEnum } from '@prisma/client';
import classNames from 'classnames';

import { setIsBlockAccessToRepeatOpen } from '../../../../redux/slice/admin';
import { ActionCenterGroup, ActionControl } from '../ActionControl/ActionControl';
import { BlockAccessToRepeatForm } from '../BlockAccessToRepeat/BlockAccessToRepeatForm';
import { CustomerDetails } from '../CustomerDetails';
import { CustomerProfileSummary } from '../CustomerProfileSummary/CustomerProfileSummary';

const ALERT_MESSAGES = {
  HARDBLOCK_INIT: 'Customer is blocked from repeat loans',
  HARDBLOCK_REMOVE: 'Customer has access to repeat loans if all other eligibility criteria is met',
  ERROR_UPDATE:
    'There was a problem while updating the block access to repeat loans. Please reach out to the engineering team for help.',
};

export const hasActiveActions = (actionCenter: Record<string, boolean>): boolean =>
  Object.values(actionCenter).some(Boolean);

const getAlertMessage = (activityType: UserActivityTypeEnum, activityPurpose: UserActivityPurposeEnum) => {
  if (activityType === UserActivityTypeEnum.REPEAT_HARDBLOCK) {
    switch (activityPurpose) {
      case UserActivityPurposeEnum.INITIALISE:
        return ALERT_MESSAGES.HARDBLOCK_INIT;
      case UserActivityPurposeEnum.REMOVE:
        return ALERT_MESSAGES.HARDBLOCK_REMOVE;
      default:
        return '';
    }
  }

  return '';
};

export const CustomerProfile = ({ userId }: { userId: string }) => {
  const isHardshipAdmin = useRole(Role.HARDSHIP_ADMIN);
  const actionCenter = useAppSelector((state) => state.admin?.actionCenter);
  const isBlockAccessToRepeatOpen = useAppSelector((state) => state.admin?.actionCenter?.isBlockAccessToRepeatOpen);
  const dispatch = useAppDispatch();
  const [alertMessage, setAlertMessage] = useState('');

  const [saveUserActivity] = useSaveUserActivityMutation();

  const actionCenterOptions: ActionCenterGroup[] = useMemo(() => {
    const commonActionCenterOptions: CommonOptionProps[] = [
      {
        label: 'Block access to repeat loans',
        value: 'block_access_to_repeat',
        onClick: () => dispatch(setIsBlockAccessToRepeatOpen(true)),
        disabled: !isHardshipAdmin,
      },
    ];

    return [{ name: 'Actions', options: commonActionCenterOptions }];
  }, [dispatch, isHardshipAdmin]);

  const handleUserActivitySave = async (data: SaveUserActivityDto) => {
    try {
      await saveUserActivity({ ...data, userId });
      setAlertMessage(getAlertMessage(data.activityType, data.purpose));
      dispatch(setIsBlockAccessToRepeatOpen(false));
    } catch {
      toast.error(ALERT_MESSAGES.ERROR_UPDATE, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeButton: false,
        draggable: false,
        className: 'border bg-error-fill border-error !p-4 !mx-2 rounded-lg',
        bodyClassName: 'bg-error-fill !m-0 text-grey-5 text-sm',
      });
      dispatch(setIsBlockAccessToRepeatOpen(false));
    }
  };

  useEffect(() => {
    if (!alertMessage) return;

    const timeout = setTimeout(() => {
      setAlertMessage('');
    }, 5000);

    return () => clearTimeout(timeout);
  }, [alertMessage]);

  return (
    <>
      {alertMessage && <Alert variant="success" className="mb-0 mx-12" title={alertMessage} />}
      <div
        className={classNames(
          'grid grid-cols-7 grid-rows-1 gap-4 transition-all',
          hasActiveActions(actionCenter)
            ? 'mr-2 h-[calc(100vh-75px)] overflow-hidden'
            : 'mr-4 overflow-auto sm:mr-4 md:mx-8'
        )}
      >
        <div
          className={classNames(
            'break-words p-4',
            hasActiveActions(actionCenter) ? 'col-span-4 overflow-y-scroll' : 'col-span-7 overflow-clip'
          )}
        >
          <ActionControl actionsGroup={actionCenterOptions} className="mb-6" />
          <CustomerProfileSummary userId={userId} />
          <CustomerDetails userId={userId} />
        </div>
        {hasActiveActions(actionCenter) && (
          <div className="animate-slide-right col-span-3 col-start-5 overflow-y-scroll transition-all p-4">
            {isBlockAccessToRepeatOpen && (
              <BlockAccessToRepeatForm
                onSaveUserActivity={handleUserActivitySave}
                onCancel={() => dispatch(setIsBlockAccessToRepeatOpen(false))}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

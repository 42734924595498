import { useMemo, useState } from 'react';
import { loanVariationTypeMapper, VaultLoanInformationDto, VaultPaymentInformationDto } from '@harmoney/api-interfaces';
import {
  useApplySimulatedVariationMutation,
  useGetDirectDebitStatusQuery,
  useGetVariablesQuery,
  useGetVariationRecordByIdQuery,
  useLazyGetSimulationCsvByIdQuery,
} from '@harmoney/redux';
import {
  Alert,
  ArrowCircleRightIcon,
  Button,
  Checkbox,
  Form,
  IconV2,
  Textarea,
  useForm,
} from '@harmoney/ui-design-system';
import { PaymentStatusEnum } from '@prisma/client';
import dayjs from 'dayjs';

import { CommonProps } from '../../../common-props';

import { createSimulationApplyFormSchema, getSimulationApplyDefaultValues } from './form-config';
import { downloadCSV } from './utils';
import { VariationData } from './VariationData';
import { VariationFormHeader } from './VariationFormHeader';

export interface LoanVariationApplyProps extends CommonProps {
  loanApplicationId: string;
  loanInformation: VaultLoanInformationDto;
  paymentInformation?: VaultPaymentInformationDto;
}

export const LoanVariationApply = ({
  loanApplicationId,
  loanInformation,
  taskId,
  completeTaskWithData,
  paymentInformation,
}: LoanVariationApplyProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const form = useForm({
    mode: 'onTouched',
    schema: createSimulationApplyFormSchema(),
    defaultValues: getSimulationApplyDefaultValues(),
  });

  const { register, watch } = form;

  const watchForm = watch();

  const [trigger] = useLazyGetSimulationCsvByIdQuery();
  const [applySimulatedVariation] = useApplySimulatedVariationMutation();

  const { data: directDebitPaymentStatus } = useGetDirectDebitStatusQuery(loanApplicationId);
  const { data: variables } = useGetVariablesQuery(taskId, { skip: !taskId });

  const simulationId = variables?.simulationId?.toString();

  const { data: variationData, isLoading: isVariationDataLoading } = useGetVariationRecordByIdQuery(simulationId, {
    skip: !simulationId,
    refetchOnMountOrArgChange: true,
  });

  const handleDownloadCSV = async () => {
    const csvData = await trigger({ loanApplicationId, simulationId }).unwrap();
    const fileName = `${loanApplicationId}-${simulationId}-simulation`;
    if (csvData) {
      downloadCSV(csvData, fileName);
    }
  };

  const handleTaskCompletion = (nextStep: string) => {
    completeTaskWithData({ taskId, variables: { nextStep } });
  };

  const handleSubmit = async (data) => {
    setIsSubmitting(true);
    if (dayjs().diff(variationData?.updatedAt, 'day') >= 1) {
      handleTaskCompletion('invalid-simulation');
    } else {
      await applySimulatedVariation({ loanApplicationId, simulationId, note: data?.note as string });
      handleTaskCompletion('apply-variation');
    }
  };

  const isButtonDisabled = useMemo(
    () =>
      !watchForm.variationConfirmation ||
      directDebitPaymentStatus === PaymentStatusEnum.processing ||
      loanInformation?.penalities > 0 ||
      loanInformation?.penalitiesDue > 0 ||
      loanInformation?.penalitiesOverDue > 0,
    [watchForm, directDebitPaymentStatus, loanInformation]
  );

  const CheckboxLabel = (
    <span>
      Yes, I confirm that I would like to proceed with applying{' '}
      <span className="font-medium">{loanVariationTypeMapper[variationData?.type]}</span> variation to this loan
    </span>
  );

  const handleEdit = () => {
    handleTaskCompletion('re-simulate');
  };
  return (
    <>
      <VariationFormHeader form={form} />
      <VariationData variationData={variationData} isVariationDataLoading={isVariationDataLoading} />
      <div className="flex items-center justify-start gap-2 sm:flex-col md:flex-row-reverse py-4 mt-6 mr-6">
        <Button
          type="submit"
          variant="text"
          className="sm:!min-w-full md:!min-w-fit md:!max-w-fit"
          onClick={handleEdit}
        >
          Edit
        </Button>
      </div>

      <Form form={form} onSubmit={handleSubmit}>
        <div className="px-4">
          <div className="w-full bg-grey-1 p-6 rounded-xl mt-6">
            <Button
              variant="text"
              icon={
                <IconV2
                  icon="material-symbols:download-rounded"
                  width={24}
                  height={24}
                  className="text-secondary ml-2"
                />
              }
              alignIcon="start"
              isFullWidth={true}
              onClick={handleDownloadCSV}
              className="font-body justify-start font-normal capitalize tracking-normal"
            >
              Download amortisation schedule (CSV)
            </Button>
            <Checkbox {...register('variationConfirmation')} className="py-4 pl-3" label={CheckboxLabel} />
            <Textarea {...register('note')} label="Notes" className="ml-2 mt-4" placeholder="Add a note" />
            <Button
              type="submit"
              variant="primary"
              icon={<ArrowCircleRightIcon size="large" />}
              alignIcon="end"
              className="mt-6"
              isFullWidth
              disabled={isButtonDisabled}
              isLoading={isSubmitting}
            >
              Apply variation
            </Button>
            {directDebitPaymentStatus === PaymentStatusEnum.processing && (
              <Alert variant="warning" className="mt-6">
                Direct Debit payment(s) pending clearance. Variation cannot be applied with pending payment(s).
              </Alert>
            )}
            {(loanInformation?.penalities > 0 ||
              loanInformation?.penalitiesDue > 0 ||
              loanInformation?.penalitiesOverDue > 0) && (
              <Alert variant="warning" className="mt-6">
                Variation cannot be applied when penalties are present on the loan.
              </Alert>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};

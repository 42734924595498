import {
  AmountInput,
  ArrowCircleRightIcon,
  Button,
  CommonOptionProps,
  ControlledSelect,
  Dialog,
  Form,
  Label,
  useForm,
} from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';
import { kebabCase } from 'lodash';

import { CommonProps } from '../../../common-props';

import { ExtraLimitDescription } from './ExtraLimitDescription';
import { createFormSchema, defaultValues } from './form-config';

interface ExtraLimitModalProps extends CommonProps {
  availableSecondaryPurposes: CommonOptionProps[];
  maxExtraLimitForSecondaryPurpose: number;
  interestRate: number;
  isExtraLimitModalOpen: boolean;
  isExtraLimitFormOpen: boolean;
  onExtraLimitFormSubmit: (data) => void;
  onBorrowMoreClick?: () => void;
  onSkipClick: () => void;
  onExtraLimitModalOpenChange: () => void;
}

export function ExtraLimitModal({
  availableSecondaryPurposes,
  maxExtraLimitForSecondaryPurpose,
  interestRate,
  isExtraLimitModalOpen,
  isExtraLimitFormOpen,
  onExtraLimitFormSubmit,
  onBorrowMoreClick,
  onSkipClick,
  onExtraLimitModalOpenChange,
}: ExtraLimitModalProps) {
  const formSchema = createFormSchema(maxExtraLimitForSecondaryPurpose);
  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues,
  });
  const {
    register,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;

  const renderedLoanPurposes = [
    {
      label: 'Select loan purpose',
      value: '',
      iconName: 'more-loan-purposes',
    },
    ...availableSecondaryPurposes.map(({ iconName, ...rest }) => ({ ...rest, iconName: kebabCase(iconName) })),
  ];

  return (
    <Dialog
      title="You can borrow more!"
      open={isExtraLimitModalOpen}
      onOpenChange={onExtraLimitModalOpenChange}
      modal={true}
    >
      <ExtraLimitDescription
        interestRate={interestRate}
        maxExtraLimitForSecondaryPurpose={maxExtraLimitForSecondaryPurpose}
      />

      {isExtraLimitFormOpen ? (
        <Form form={form} onSubmit={(data) => onExtraLimitFormSubmit(data)} className="p-4 pb-0 mb-8">
          <fieldset className="mb-4">
            <Label className="mb-2">What would you like to do?</Label>
            <ControlledSelect {...register('secondaryPurposeId')} options={renderedLoanPurposes} />
          </fieldset>
          <fieldset className="mb-4">
            <Label className="mb-2">
              Borrow up to an extra <strong>{formatCurrency(maxExtraLimitForSecondaryPurpose)}</strong>
            </Label>
            <AmountInput {...register('secondaryPurposeAmount')} maxLength={6} />
          </fieldset>
          <div className="text-center">
            <Button
              type="submit"
              variant="primary"
              className="sm:!min-w-full md:!min-w-fit md:!max-w-fit"
              alignIcon="end"
              icon={<ArrowCircleRightIcon size="large" />}
              isLoading={isSubmitting || isSubmitSuccessful}
            >
              Continue
            </Button>
          </div>
        </Form>
      ) : (
        <div className="p-4 mb-8 sm:mb-2">
          <div className="shrink-1 flex w-full flex-col items-center justify-start gap-2 sm:flex-col md:flex-row-reverse">
            <Button
              variant="primary"
              className="sm:!min-w-full md:!min-w-fit md:!max-w-fit"
              alignIcon="end"
              icon={<ArrowCircleRightIcon size="large" />}
              onClick={onBorrowMoreClick}
            >
              Borrow more
            </Button>
            <Button variant="secondary" className="sm:!min-w-full md:!min-w-fit md:!max-w-fit" onClick={onSkipClick}>
              Skip
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
}

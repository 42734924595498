import { Alert } from '@harmoney/ui-design-system';
import classNames from 'classnames';

interface QuoteDisclaimerProps {
  isDebtCon?: boolean;
  classNameForDisclaimer?: string;
}

export function QuoteDisclaimer({ isDebtCon = false, classNameForDisclaimer = '' }: QuoteDisclaimerProps) {
  const sharedDisclaimer =
    'By submitting this application, you agree that a fixed interest, fixed repayment loan will meet your requirements';

  const disclaimer = isDebtCon
    ? `${sharedDisclaimer} and you understand that Harmoney can not guarantee a lower interest.\n \n Depending on rates and fees of your existing debt, you may incur additional costs by refinancing.`
    : `${sharedDisclaimer}.`;

  return (
    <>
      <Alert variant="warning" className="mb-4" title="Heads up!">
        <p className="text-sm">
          We&apos;ll make an inquiry on your credit report and you won&apos;t be able to change your quote past this
          point.
        </p>
      </Alert>
      <p className={classNames(classNameForDisclaimer, 'mb-6 whitespace-pre-line')}>{disclaimer}</p>
    </>
  );
}

import { useFormContext } from 'react-hook-form';
import { LoanPurposeQuestionType } from '@prisma/client';

import { Checkbox } from '../Checkbox';
import { Label } from '../Label';
import { ControlledSelect } from '../Select';
import { ToggleGroup } from '../ToggleGroup';

export interface LoanPurposeSelectorProps {
  name: string;
  loanPurposes: any[];
  loanPurposeQuestions: any[];
  selectPlaceholder?: string;
  label?: string;
}

export const LoanPurposeSelector = ({
  label,
  name,
  loanPurposes,
  loanPurposeQuestions = [],
  selectPlaceholder,
}: LoanPurposeSelectorProps) => {
  const { register } = useFormContext();

  return (
    <>
      {label && <Label className="mb-2">{label}</Label>}
      <ControlledSelect {...register(name)} options={loanPurposes} placeholder={selectPlaceholder} />
      {loanPurposeQuestions.length > 0 &&
        loanPurposeQuestions.map(({ id, questionType, name: questionName, options }, index) => (
          <div key={id} className="pt-4">
            {questionType === LoanPurposeQuestionType.YESNO && (
              <>
                <Label className="mb-2">{questionName}</Label>
                <ToggleGroup
                  name={`questions.${index}.optionId`}
                  options={options.map((option: any) => ({ label: option.name, value: option.id }))}
                />
              </>
            )}
            {questionType === LoanPurposeQuestionType.CHECK && (
              <Checkbox name={`questions.${index}.optionId`} label={questionName} />
            )}
          </div>
        ))}
    </>
  );
};

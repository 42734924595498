import { NetworthSourceEnum } from './networth-source.dto';

export const essentialExpenseMapper: Partial<Record<NetworthSourceEnum, string>> = {
  [NetworthSourceEnum.EXPENSE_FUEL_ID]: 'Fuel',
  [NetworthSourceEnum.EXPENSE_GROCERIES_ID]: 'Groceries',
  [NetworthSourceEnum.EXPENSE_HOUSEHOLD_ITEM_ID]: 'Household items',
  [NetworthSourceEnum.EXPENSE_INSURANCE_ID]: 'Insurance',
  [NetworthSourceEnum.EXPENSE_INTERNET_ID]: 'Internet',
  [NetworthSourceEnum.EXPENSE_MEDICAL_ID]: 'Medical expenses',
  [NetworthSourceEnum.EXPENSE_PET_CARE_ID]: 'Pet care (food, medicine etc)',
  [NetworthSourceEnum.EXPENSE_PHONE_PLAN_ID]: 'Phone plan',
  [NetworthSourceEnum.EXPENSE_POWER_ID]: 'Power',
  [NetworthSourceEnum.EXPENSE_TRANSPORT_ID]: 'Public transport',
  [NetworthSourceEnum.EXPENSE_WATER_ID]: 'Water',
  [NetworthSourceEnum.EXPENSE_NOT_LISTED_ID]: 'Expense not listed',
  [NetworthSourceEnum.EXPENSE_TOTAL_ESSENTIAL_EXPENSES_ID]: 'Essential expenses',
  [NetworthSourceEnum.EXPENSE_OTHER_REGULAR_EXPENSES_ID]: 'Other regular expenses',
};

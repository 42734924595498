import { ReactNode } from 'react';

interface JourneyPageProps {
  children?: ReactNode;
  title?: string;
  subtitle?: string;
  description?: string;
}

export const JourneyPage = ({ children, title, subtitle, description }: JourneyPageProps) => {
  return (
    <>
      {title && <h1 className="p-4">{title}</h1>}
      {subtitle && (
        <p className="p-2">
          <strong>{subtitle}</strong>
        </p>
      )}
      {description && <p className="p-2">{description}</p>}

      {children}
    </>
  );
};

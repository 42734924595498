import { ReactNode } from 'react';
import { useAppSelector, useGetUserByIdQuery } from '@harmoney/redux';
import { Button, Divider } from '@harmoney/ui-design-system';
import { valueOrNA } from '@harmoney/ui-utils';
import { Icon } from '@iconify/react';
import classNames from 'classnames';

type Props = {
  userId: string;
  children: ReactNode;
  collapsed?: boolean;
  setCollapsed?: (collapsed: boolean) => void;
};

export const AdminSidebar = ({ userId, children, collapsed, setCollapsed }: Props) => {
  const token = useAppSelector((state) => state.accessToken.value);
  const { data: userDataResult } = useGetUserByIdQuery(userId as string, { skip: !token || !userId });

  return (
    <div
      className={classNames(
        'fixed flex flex-col py-8 border-r border-r-grey-2 bg-grey-1 h-screen transition-width duration-300',
        collapsed ? 'max-w-[24px] px-4' : 'max-w-[240px] px-6'
      )}
    >
      <div className={collapsed ? 'hidden' : 'block'}>
        <div className="flex justify-between items-center max-w-full">
          <div className="flex flex-col max-w-full">
            <span className="font-medium mb-2">{valueOrNA(userDataResult?.preferredName)}</span>
            <div className="mb-2 text-sm flex flex-col">
              <span className="text-grey-4">Customer ID:</span>
              <span>{valueOrNA(userDataResult?.id)}</span>
            </div>
            <div className="mb-2 text-sm flex flex-col">
              <span className="text-grey-4">Customer number:</span>
              <span>{valueOrNA(userDataResult?.businessKey)}</span>
            </div>
            <div className="text-sm flex flex-col">
              <span className="text-grey-4">Customer email:</span>
              <span className="break-words">{valueOrNA(userDataResult?.email)}</span>
            </div>
          </div>
        </div>
        <Divider className="my-6" />
        {children}
      </div>
      <Button
        onClick={() => setCollapsed(!collapsed)}
        variant="text"
        className="absolute top-[20px] right-[-20px] !text-grey-2"
      >
        <span className="border rounded-pill flex bg-grey-1">
          {collapsed ? (
            <Icon icon="ic:round-chevron-right" width={32} />
          ) : (
            <Icon icon="ic:round-chevron-left" width={32} />
          )}
        </span>
      </Button>
    </div>
  );
};

import { Alert } from '@harmoney/ui-design-system';

interface LoanPeriodProps {
  className?: string;
}

export function LoanPeriodAlert({ className }: LoanPeriodProps) {
  return (
    <>
      <Alert variant="info" className={className} title="Available loan period">
        Changing your borrowing amount may change your available loan period (3, 5, or 7 years). This is based on how
        much you can afford.
      </Alert>
    </>
  );
}

import { COAuditTags } from '@prisma/client';

/**
 * @deprecated
 **/
export const coAuditTagsMapper: Record<COAuditTags, string> = {
  ACCEPTABLE_DISHONOURS: 'Acceptable dishonours',
  DO_NOT_LIKE_THE_APPLICATION: "Don't like the application",
  EXCESSIVE_ATM_WITHDRAWALS: 'Excessive ATM withdrawals',
  EXCESSIVE_DISHONOURS: 'Excessive dishonours',
  EXCESSIVE_PAYDAY_LENDERS: 'Excessive payday lenders',
  INCOME_NOT_IDENTIFIED: 'Income not identified',
  INCORRECT_AFFORDABILITY_INPUTS: 'Incorrect affordability inputs',
  LARGE_TRANFERS_IN: 'Large transfers in',
  LARGE_TRANFERS_OUT: 'Large transfers out',
  NON_RISKY_GAMBLING_WINS: 'Non-risky gambling (wins)',
  RISKY_GAMBLING: 'Risky gambling',
  SIGNIFICANT_UNCATEGORISED_TRANSACTIONS: 'Significant uncategorised transactions',
  UNDISCLOSED_DEBT: 'Undisclosed debt',
  UNEXPLAINED_CASH_DEPOSITS: 'Unexplained cash deposits',
  UNRECOGNISED_LOAN_ADVANCES: 'Unrecognised loan advances',
  WOULD_HAVE_APPROVED: 'Would have approved',
};

import dayjs from 'dayjs';

import { parseUTCAndConvertToLocalTime } from './date.util';
import { RepaymentFrequencyEnum } from '.prisma/client';

export const formatDate = (date: string | Date) => parseUTCAndConvertToLocalTime(date).format('YYYY-MM-DD');

export const calculateOneOffDifference = (firstRepayment: number, recurringRepayment: number) => {
  if (isNaN(firstRepayment) || isNaN(recurringRepayment)) return 0;
  return firstRepayment - recurringRepayment;
};

export const isFirstRepaymentDatePassed = (startDate: Date) => {
  return dayjs().startOf('day').isAfter(startDate);
};

export const showCountdown = (startDate: Date, frequency: RepaymentFrequencyEnum) => {
  const today = dayjs();
  const firstRepaymentDate = dayjs(startDate);
  const difference = firstRepaymentDate.diff(today, 'day');

  switch (frequency) {
    case RepaymentFrequencyEnum.weekly:
      return difference < 3;
    case RepaymentFrequencyEnum.fortnightly:
      return difference < 5;
    case RepaymentFrequencyEnum.monthly:
      return difference < 5;
    default:
      return false;
  }
};

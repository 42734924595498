import { z } from 'zod';

export const writeOffLoanInitialValues = { writeOffReason: '', notes: '' };

export const editWriteOffLoanInitialValues = (reason, note) => {
  return { writeOffReason: reason, notes: note };
};

export const createWriteOffFormSchema = () =>
  z.object({
    writeOffReason: z.string().min(1, { message: 'Please select a reason' }),
    notes: z.string().optional(),
  });

import { generateIcon } from '../generateIcon';

/**
 * @deprecated Check if it can be replaced with Iconify. If not, add it to icon.json.
 * Refer to icon document for details: https://63be80b51f526a24fc7865c5-tslqcdnosl.chromatic.com/?path=/docs/docs-icons--docs
 */
export const UserCheckIcon = generateIcon({
  viewBox: '0 0 30 30',
  variant: 'none',
  path: (
    <path
      d="M18.75 22.5L21.25 25L26.25 20M13.75 25H9C7.59987 25 6.8998 25 6.36502 24.7275C5.89462 24.4878 5.51217 24.1054 5.27248 23.635C5 23.1002 5 22.4001 5 21V9C5 7.59987 5 6.8998 5.27248 6.36502C5.51217 5.89462 5.89462 5.51217 6.36502 5.27248C6.8998 5 7.59987 5 9 5H21C22.4001 5 23.1002 5 23.635 5.27248C24.1054 5.51217 24.4878 5.89462 24.7275 6.36502C25 6.8998 25 7.59987 25 9V15M8.75 25C8.75 24.5719 8.79304 24.1539 8.87502 23.75C9.45411 20.8972 11.9763 18.75 15 18.75M17.5 12.5C17.5 13.8807 16.3807 15 15 15C13.6193 15 12.5 13.8807 12.5 12.5C12.5 11.1193 13.6193 10 15 10C16.3807 10 17.5 11.1193 17.5 12.5Z"
      stroke="#206EEE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});

import { generateIcon } from '../generateIcon';

/**
 * @deprecated Check if it can be replaced with Iconify. If not, add it to icon.json.
 * Refer to icon document for details: https://63be80b51f526a24fc7865c5-tslqcdnosl.chromatic.com/?path=/docs/docs-icons--docs
 */
export const ClockIcon = generateIcon({
  viewBox: '0 0 42 42',
  variant: 'none',
  path: (
    <path
      d="M8.85767 21C9.71884 14.094 15.61 8.75 22.7494 8.75C30.4813 8.75 36.7494 15.018 36.7494 22.75C36.7494 30.482 30.4813 36.75 22.7494 36.75H14M22.75 22.75V15.75M19.25 5.25H26.25M5.25 26.25H14M8.75 31.5H17.5"
      stroke="#206EEE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});

import { useAppSelector, useGetPreQualifiedQuotesByUserIdQuery } from '@harmoney/redux';
import { CollapsibleCard } from '@harmoney/ui-design-system';

import { CustomerPreQualifiedQuoteListItem } from './CustomerPreQualifiedQuoteListItem';

type Props = {
  userId: string;
};

export const CustomerPreQualifiedQuoteTable = ({ userId }: Props) => {
  const token = useAppSelector((state) => state?.accessToken?.value);
  const { data } = useGetPreQualifiedQuotesByUserIdQuery(
    { userId },
    {
      skip: !userId || !token,
    }
  );

  if (!data) {
    return null;
  }

  return (
    <CollapsibleCard title="Pre-Qualified Quotes" className="mb-6" disabled>
      <ul>
        <li className="border-grey-2 grid grid-cols-10 border-b p-4">
          <span className="col-span-2 mr-3 font-medium">ID</span>
          <span className="col-span-1 ml-3 font-medium">Product</span>
          <span className="col-span-1 mx-1 font-medium">Opportunity</span>
          <span className="col-span-1 mx-1 font-medium">Loan purpose</span>
          <span className="col-span-1 ml-3 font-medium">Requested Amount</span>
          <span className="col-span-1 ml-3 font-medium">Created on</span>
          <span className="col-span-2 mx-1 font-medium">Applications</span>
          <span className="col-span-1 ml-6 font-medium">Status</span>
        </li>
      </ul>
      <ul>
        {data.map((quote) => (
          <CustomerPreQualifiedQuoteListItem key={quote.id} quote={quote} />
        ))}
      </ul>
    </CollapsibleCard>
  );
};

import {
  FRANKIE_CONFIGURATION_AU,
  FRANKIE_CONFIGURATION_NZ,
  privacyPolicyLinkAU,
} from '../../identity/FrankieSmartUI/frankie-config';

export const FRANKIE_ONFIDO_CONFIGURATION_AU = {
  ...FRANKIE_CONFIGURATION_AU,
  saveOnly: true,
  phrases: {
    ...FRANKIE_CONFIGURATION_AU.phrases,
    pending_screen: {
      title: 'Thank you for submitting your biometric information',
      innner_p_1: "We are currently processing your request and will notify you once it's completed.",
      innner_p_2: "This shouldn't take long. Please avoid closing or refreshing the application.",
    },
    review_details_screen: {
      personal_info_label: 'Your personal information',
      submit_button_cta: 'Verify my ID',
      loading_verification:
        '<h1>Verifying your identity...</h1><p>Please do not close or refresh this page. It might take a few seconds.</p>',
    },
  },
  consentText: `I understand Harmoney will verify my identity in accordance with its ${privacyPolicyLinkAU} `,
  idScanVerification: {
    welcomeScreen: false,
    releaseVersion: 'v1.1',
    useMobileDevice: true,
    useLiveness: true,
    language: {
      locale: 'en_US',
      direction: 'ltr',
      phrases: {
        welcome: {
          next_button: 'Continue',
          title: 'Biometric identity check',
          subtitle: '',
          list_header_webcam: 'Follow the instructions to:',
          list_item_doc: 'Photograph your Identity document',
          list_item_selfie: 'Record your face',
        },
        doc_submit: {
          title_passport: 'Submit passport photo page',
          title_permit_back: 'Submit residence permit (back)',
          title_permit_front: 'Submit residence permit (front)',
        },
        doc_confirmation: {
          button_primary_upload: 'Confirm',
        },
        selfie_confirmation: {
          subtitle: 'Make sure your selfie is clear, with no blur or glare',
        },
        mobilePhrases: {
          photo_upload: {
            body_license_back: "Take a photo of your driver's licence (back)",
            body_license_front: "Take a photo of your driver's licence (front)",
            body_passport: 'Take a photo of your passport photo page',
          },
        },
      },
    },
  },
};

export const FRANKIE_ONFIDO_CONFIGURATION_NZ = {
  ...FRANKIE_CONFIGURATION_NZ,
  idScanVerification: false,
};

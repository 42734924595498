import { EssentialExpenseDetailDto, essentialExpenseMapper, NetworthSourceEnum } from '@harmoney/api-interfaces';
import { Button, CurrencyWithFrequency } from '@harmoney/ui-design-system';

interface ExpenseTableProps {
  data: EssentialExpenseDetailDto[];
  onEdit: (id: string) => void;
}

export const ExpenseTable: React.FC<ExpenseTableProps> = (props) => {
  return (
    <table className="min-w-full">
      <thead>
        <tr className="text-sm">
          <th className="border-grey-1 border px-2 py-4 text-center font-medium"></th>
          <th className="border-grey-1 border px-2 py-4 text-center font-medium">Total cost</th>
          <th className="border-grey-1 bg-secondary-lighter-3 border px-2 py-4 text-center font-medium">Your share</th>
          <th className="border-grey-1 border px-2 py-4 text-center font-medium"></th>
        </tr>
      </thead>
      <tbody>
        {props.data.map(
          (
            {
              id,
              networthSourceId,
              declaredTotalAmount,
              frequencyForTotalAmount,
              declaredAmount,
              frequency,
              otherExpenseType,
            },
            index
          ) => (
            <tr key={id} className="bg-white text-sm even:bg-gray-100">
              <td className="border-grey-1 border px-2 py-4 text-left">
                {networthSourceId === NetworthSourceEnum.EXPENSE_NOT_LISTED_ID
                  ? otherExpenseType
                  : essentialExpenseMapper[networthSourceId]}
              </td>
              <td className="border-grey-1 border px-2 py-4 text-right">
                <CurrencyWithFrequency
                  amount={declaredTotalAmount}
                  frequency={frequencyForTotalAmount}
                  frequencyClassName="block sm:inline"
                />
              </td>
              <td className="border-grey-1 bg-secondary-lighter-3 border px-2 py-4 text-right font-medium">
                <CurrencyWithFrequency
                  amount={declaredAmount}
                  frequency={frequency}
                  frequencyClassName="block sm:inline"
                />
              </td>
              <td className="border-grey-1 border text-center">
                <Button
                  onClick={() => props.onEdit(id)}
                  className="!px-2 !py-4"
                  alignIcon="start"
                  size="medium"
                  variant="text"
                >
                  EDIT
                </Button>
              </td>
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};

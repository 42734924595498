import { Fragment, useMemo, useState } from 'react';
import {
  FinancialProfileDto,
  livingSituationLabelMapper,
  NetworthSourceEnum,
  rentalSituationLabelMapper,
  ResidentialStatusEnum,
} from '@harmoney/api-interfaces';
import { FinancialConfirmationSectionEnum } from '@harmoney/hooks';
import { Alert, Button, Card, Divider, Label } from '@harmoney/ui-design-system';
import { RelationshipStatusEnum } from '@prisma/client';
import { capitalize } from 'lodash';

import { FormattedAmount } from '../../FinancialSummary/shared';
import { shouldDisplaySharedExpense } from '../../HouseholdV2/form.util';

import { FinancialProfileProps } from './financial-profile-props';

type LivingSituation = FinancialProfileDto['livingSituation'];
type Income = LivingSituation['incomes'][number];
type Expense = LivingSituation['expenses'][number];

const LivingSituationLabel = ({ label, value }: { label: string; value: string | number }) => (
  <div className="flex items-center justify-between p-4">
    <Label>{capitalize(label)}</Label>
    <span className="font-medium">{value}</span>
  </div>
);

const IncomeExpenseLabel = ({ item }: { item: Expense | Income }) => (
  <>
    <div className="flex items-center justify-between p-4">
      <div>
        <Label>{capitalize(item.networthSourceName)}</Label>
        {(item as Expense).deductedFromIncome && <Label className="text-xs">(Deducted from income)</Label>}
      </div>
      <FormattedAmount amount={item.declaredAmount} frequency={item.frequency} />
    </div>
    <Divider className="text-grey-1 my-0 px-4" />
  </>
);

const RentContribution = ({ expenses, livingSituation }: { expenses: Expense[]; livingSituation: LivingSituation }) => {
  const expense =
    expenses &&
    expenses.find(
      (expense) =>
        expense.networthSourceId === NetworthSourceEnum.EXPENSE_RENT_ID ||
        expense.networthSourceId === NetworthSourceEnum.EXPENSE_BOARDER_ID ||
        expense.networthSourceId === NetworthSourceEnum.EXPENSE_LIVE_WITH_PARENTS_ID ||
        expense.networthSourceId === NetworthSourceEnum.EXPENSE_COMPANY_HOUSING_ID ||
        expense.networthSourceId === NetworthSourceEnum.EXPENSE_GOVERNMENT_HOUSING_ID
    );

  if (!expense) return;

  const showSharedExpense = shouldDisplaySharedExpense(livingSituation.relationshipStatus as RelationshipStatusEnum);

  return (
    <>
      <div className={`flex p-4 ${showSharedExpense ? 'flex-col' : 'justify-between'}`}>
        <Label>
          {capitalize(rentalSituationLabelMapper[livingSituation.residentialStatus as ResidentialStatusEnum])}
        </Label>
        {showSharedExpense ? (
          <ul className="mt-2 text-sm">
            <li className="flex justify-between">
              <span>Total cost</span>
              <FormattedAmount amount={expense.declaredTotalAmount} frequency={expense.frequencyForTotalAmount} />
            </li>
            <li className="flex justify-between">
              <span>Your share</span>
              <FormattedAmount amount={expense.declaredAmount} frequency={expense.frequency} />
            </li>
          </ul>
        ) : (
          <FormattedAmount amount={expense.declaredAmount} frequency={expense.frequency} />
        )}
      </div>
      <Divider className="text-grey-1 my-0 px-4" />
    </>
  );
};

const ChildSupportExpense = ({ expenses }: { expenses: Expense[] }) => {
  const expense =
    expenses && expenses.find((expense) => expense.networthSourceId === NetworthSourceEnum.EXPENSE_CHILD_SUPPORT_ID);

  if (!expense) return;

  return <IncomeExpenseLabel item={expense} />;
};

export const LivingSituation = ({
  financialSummary,
  isDebtCon = false,
  confirmedSections,
  onConfirmSection,
  onUpdate,
}: FinancialProfileProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUpdateInfo = async () => {
    try {
      setIsSubmitting(true);
      await onUpdate(FinancialConfirmationSectionEnum.LivingSituation);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };

  const displayUpToDate = useMemo(() => {
    return (
      confirmedSections?.includes(FinancialConfirmationSectionEnum.LivingSituation) &&
      confirmedSections[confirmedSections.length - 1] === FinancialConfirmationSectionEnum.LivingSituation
    );
  }, [confirmedSections?.length]);

  return (
    <Card className="!p-0">
      <Label className="p-4 text-lg font-medium">Living situation</Label>
      <Divider className="text-grey-2 m-0 p-0" />

      <LivingSituationLabel
        label="Living situation"
        value={
          livingSituationLabelMapper[financialSummary?.livingSituation?.residentialStatus as ResidentialStatusEnum]
        }
      />
      <Divider className="text-grey-1 my-0 px-4" />

      <RentContribution
        expenses={financialSummary?.livingSituation?.expenses}
        livingSituation={financialSummary?.livingSituation}
      />
      <Divider className="text-grey-1 my-0 px-4" />

      <LivingSituationLabel
        label="Children or dependents"
        value={financialSummary?.livingSituation?.dependants || 'No'}
      />
      <Divider className="text-grey-1 my-0 px-4" />

      {financialSummary?.livingSituation?.incomes.map((income, i) => (
        <Fragment key={`living-situation-incomes-${i}`}>
          <IncomeExpenseLabel item={income} />
        </Fragment>
      ))}

      <ChildSupportExpense expenses={financialSummary?.livingSituation?.expenses} />

      <div className="flex flex-col gap-4 px-4 py-6 items-center">
        {confirmedSections.includes(FinancialConfirmationSectionEnum.LivingSituation) ? (
          displayUpToDate && (
            <Alert variant="success" className="mb-0 sm:max-w-[20.5rem] w-full" autoDismissInMilliseconds={1500}>
              <p className="text-sm">Up to date</p>
            </Alert>
          )
        ) : (
          <Button
            type="button"
            alignIcon="end"
            variant="primary"
            disabled={isSubmitting}
            onClick={() => onConfirmSection(FinancialConfirmationSectionEnum.LivingSituation)}
          >
            That&rsquo;s right
          </Button>
        )}
        <Button type="button" isLoading={isSubmitting} variant="outline-secondary" hasShadow onClick={handleUpdateInfo}>
          Update my info
        </Button>
      </div>
    </Card>
  );
};

import { UseFormRegister } from 'react-hook-form';
import { LoanApplicationDto, LoanProductPurposeDto } from '@harmoney/api-interfaces';
import { Card, CommonOptionProps, Divider, Label } from '@harmoney/ui-design-system';
import { LoanApplicationPurpose, LoanPurpose } from '@prisma/client';

import { FormSchema } from '../form-config';

import { SecondaryPurposeForm } from './SecondaryPurposeForm';

interface MultiLoanPurposesProps {
  loanApplicationData: LoanApplicationDto & { bankStatementReferences: any[] };
  loanPurposesData: LoanProductPurposeDto[];
  availableSecondaryPurposes: CommonOptionProps[];
  loanApplicationPurposes: (LoanApplicationPurpose & {
    loanPurpose: LoanPurpose;
  })[];
  uiState: {
    isSecondaryPurposeFormOpen: boolean;
    errorMsgForSecondaryPurposeAmount: string;
  };
  primaryPurposeAmount: number;
  secondaryPurposeId: string;
  secondaryPurposeAmount: number;
  maxExtraLimitForSecondaryPurpose: number;
  register: UseFormRegister<FormSchema>;
  onDeleteClick: (id: string) => void;
}

export function MultiLoanPurposes({
  loanApplicationData,
  secondaryPurposeAmount,
  availableSecondaryPurposes,
  uiState,
  secondaryPurposeId,
  maxExtraLimitForSecondaryPurpose,
  register,
  onDeleteClick,
}: MultiLoanPurposesProps) {
  return (
    <>
      {uiState.isSecondaryPurposeFormOpen && (
        <Card className="!p-0">
          <Label className="p-4 font-medium ">Borrow more</Label>
          <Divider className="m-0 text-grey-2" />
          <SecondaryPurposeForm
            loanApplicationData={loanApplicationData}
            remainingAmount={maxExtraLimitForSecondaryPurpose - secondaryPurposeAmount}
            register={register}
            availableSecondaryPurposes={availableSecondaryPurposes}
            maxExtraLimitForSecondaryPurpose={maxExtraLimitForSecondaryPurpose}
            onDeleteClick={() => onDeleteClick(secondaryPurposeId)}
            errorMsgForSecondaryPurposeAmount={uiState.errorMsgForSecondaryPurposeAmount}
          />
        </Card>
      )}
    </>
  );
}

import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { LoanApplicationDto } from '@harmoney/api-interfaces';
import { AmountInput, Card, Label } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';

import { FormSchema } from '../form-config';

interface LoanPurposeAmountProps {
  remainingAmount: number;
  loanApplicationData: LoanApplicationDto & { bankStatementReferences: any[] };
  register: UseFormRegister<FormSchema>;
}

export const LoanPurposeAmount: React.FC<LoanPurposeAmountProps> = ({
  loanApplicationData,
  register,
  remainingAmount,
}) => {
  return (
    <Card className="!p-0">
      <Label className="m-4 mb-2">
        Borrow up to <strong>{formatCurrency(loanApplicationData?.quotePresentedAmount)}</strong> for{' '}
        {loanApplicationData?.loanApplicationPurposes[0]?.loanPurpose?.displayName?.toLowerCase()}
      </Label>
      <AmountInput {...register('amount')} maxLength={6} className="m-4" />
    </Card>
  );
};

import React from 'react';
import { boldContent, Card } from '@harmoney/ui-design-system';

type Props = {
  content: string;
  description: string;
};

const RepeatIneligibleCard = ({ content, description }: Props) => {
  const subtitle = 'Borrow more';
  const boldedContent = boldContent(content);

  return (
    <Card className="flex flex-col md:px-16">
      <div className="border-b-grey-2 flex flex-row justify-between">
        <div className="flex flex-row items-center">
          <span className="font-medium">{subtitle}</span>
        </div>
      </div>
      <div className="flex flex-col pt-4 text-left">
        <p className="text-grey-0 mb-2" dangerouslySetInnerHTML={{ __html: boldedContent }}></p>
        <p className="text-sm text-grey-4 pt-0">{description}</p>
      </div>
    </Card>
  );
};

export default RepeatIneligibleCard;

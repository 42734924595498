import { AffordabilityModelDetailDto, affordabilityModelMapper } from '@harmoney/api-interfaces';
import { Badge, Card, IconV2 } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';
import classNames from 'classnames';

type Props = {
  affordabilityModelDetail: AffordabilityModelDetailDto;
};
export const UmiTab = ({ affordabilityModelDetail }: Props) => {
  return (
    <Card
      className={classNames('h-full w-full flex-1 px-6 space-around pb-0', {
        'outline outline-secondary': affordabilityModelDetail.isSelected,
      })}
    >
      <div className="flex flex-col items-start w-full space-y-1">
        <div className="flex flex-row justify-between items-center w-full gap-4 flex-nowrap">
          <span className="font-normal">{affordabilityModelMapper[affordabilityModelDetail.model]} UMI</span>
          {affordabilityModelDetail.isSelected && (
            <Badge
              label={
                <span className="flex flex-row items-center gap-1 flex-nowrap text-xs">
                  <IconV2 icon="mdi:check-decagram" className="size-4 text-success" />
                  Selected
                </span>
              }
              className="py-0"
            />
          )}
        </div>
        <span className={classNames({ 'text-error': affordabilityModelDetail.umi < 0 })}>
          <span className="font-bold">{formatCurrency(affordabilityModelDetail.umi)} </span>
        </span>
      </div>
    </Card>
  );
};

import React from 'react';
import { LoanApplicationQueryResponse } from '@harmoney/redux';
import { Card, Divider } from '@harmoney/ui-design-system';
import dayjs from 'dayjs';

import { HistoryLoanDetails } from './HistoryLoanDetails';

type Props = {
  applications: LoanApplicationQueryResponse[];
};

const HistoryCard = ({ applications }: Props) => {
  return (
    <Card className="!p-0">
      <div className="border-b border-b-grey-2 p-4 font-medium">
        <span>History</span>
      </div>
      {applications
        .sort((a, b) => (dayjs(a.createdAt).isBefore(b.createdAt) ? 1 : -1))
        .slice(0, 3)
        .map((application, index) => (
          <React.Fragment key={application.id}>
            <HistoryLoanDetails application={application} />
            {index < applications.length - 1 && <Divider className="text-grey-2 !m-0" />}
          </React.Fragment>
        ))}
    </Card>
  );
};

export default HistoryCard;

import { EventTypeEnum } from '@prisma/client';

export enum ScheduleTriggerType {
  WINDOW_EVENT = 'window_event',
  MONOOVA_REPORTS = 'monoova_reports',
  JOB_RUNNER = 'job_runner',
  REPEAT_ELIGIBILITY_SYNC = 'repeat_eligibility_sync',
}

export class ScheduleTriggerEvent {
  id?: string;
  type: ScheduleTriggerType;
  payload?:
    | JobRunnerTriggerEventPayload
    | {
        [key: string]: string | number | boolean;
      };
}

export type JobRunnerTriggerEventPayload = {
  jobType: EventTypeEnum;
  runCount?: number;
};

import { errors } from '@harmoney/ui-utils';
import { LoanVariationPurposeEnum } from '@prisma/client';
import dayjs from 'dayjs';
import { z } from 'zod';

import { LoanVariationOptionsEnum } from './loan-variation-options';
import { contractualStartDate } from './utils';

export const getSimulationDefaultValues = (remainingTerm: number) => {
  return {
    loanVariationType: '',
    contractualPaymentDate: contractualStartDate,
    loanTerm: remainingTerm,
    purpose: '',
    otherPurpose: '',
    effectiveDate: dayjs().toDate(),
  };
};

export const createSimulationFormSchema = (remainingTerm: number) =>
  z
    .object({
      loanVariationType: z.string(),
      contractualPaymentDate: z.coerce.date(),
      loanTerm: z.coerce.number().max(84, { message: 'The number of months cannot be greater than 84 months' }),
      purpose: z.string(),
      otherPurpose: z.string().optional(),
      effectiveDate: z.coerce.date().optional(),
    })
    .superRefine((data, { addIssue }) => {
      if (data.loanVariationType === LoanVariationOptionsEnum.REPAYMENT_HOLIDAY && !data.effectiveDate) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.requiredField('Pause Date'),
          path: ['effectiveDate'],
        });
      }
      if (data.purpose === LoanVariationPurposeEnum.OTHER && !data.otherPurpose) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.requiredField('Other Purpose'),
          path: ['otherPurpose'],
        });
      }
      if (data.purpose === LoanVariationPurposeEnum.OTHER && /^\s*$/g.test(data.otherPurpose)) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Other Purpose can not be empty',
          path: ['otherPurpose'],
        });
      }
    });

export const getSimulationApplyDefaultValues = () => {
  return {
    variationConfirmation: false,
    note: '',
  };
};

export const createSimulationApplyFormSchema = () =>
  z
    .object({
      variationConfirmation: z.boolean(),
      note: z.string().min(1, { message: 'Please add a note' }),
    })
    .superRefine((data, { addIssue }) => {
      if (/^\s*$/g.test(data.note)) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Notes can not be empty',
          path: ['note'],
        });
      }
    });

import { UseFormRegister } from 'react-hook-form';
import { AmountFrequency, Card, Label, ToggleGroup } from '@harmoney/ui-design-system';
import { frequencyOptions, toggleYesNoOptions } from '@harmoney/ui-utils';

import { FormSchema } from '../form-config';

interface Props {
  register: UseFormRegister<FormSchema>;
  hasChildSupportReceived: boolean;
  hasChildSupportPaid: boolean;
}

export const ChildSupportCard = ({ register, hasChildSupportReceived, hasChildSupportPaid }: Props) => (
  <>
    <Card>
      <Label className="mb-2">
        Do you <span className="font-medium">receive</span> child support?
      </Label>
      <ToggleGroup {...register('childSupport.hasChildSupportReceived')} options={toggleYesNoOptions} />
      {hasChildSupportReceived && (
        <div className="mt-4">
          <Label className="mb-2">
            How much child support do <span className="font-medium">you</span> receive?
          </Label>
          <AmountFrequency
            register={register}
            inputKey="childSupport.childSupportReceivedAmount"
            selectKey="childSupport.childSupportReceivedFrequency"
            name="receive-child-support"
            options={frequencyOptions}
          />
        </div>
      )}
    </Card>
    <Card>
      <Label className="mb-2">
        Do you <span className="font-medium">pay</span> child support?
      </Label>
      <ToggleGroup {...register('childSupport.hasChildSupportPaid')} options={toggleYesNoOptions} />
      {hasChildSupportPaid && (
        <>
          <div className="mt-4">
            <Label className="mb-2">
              How much child support do <span className="font-medium">you</span> pay?
            </Label>
            <AmountFrequency
              register={register}
              inputKey="childSupport.childSupportPaidAmount"
              selectKey="childSupport.childSupportPaidFrequency"
              name="pay-child-support"
              options={frequencyOptions}
            />
          </div>
          <ToggleGroup
            {...register('childSupport.hasChildSupportDeducted')}
            label="Is your child support deducted from your income?"
            options={toggleYesNoOptions}
            className="mt-4"
          />
        </>
      )}
    </Card>
  </>
);

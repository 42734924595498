import classNames from 'classnames';

import { PropsWithHTMLElement } from '../../types';

import { BaseFormFieldProps } from './types';

import styles from './FormField.module.scss';

export interface FormFieldInternalProps extends BaseFormFieldProps {
  children: React.ReactNode;
  className?: string;
}

type FormFieldProps = PropsWithHTMLElement<FormFieldInternalProps, 'div'>;

export const FormField: React.FC<FormFieldProps> = ({
  children,
  className,
  isDirty,
  isFilled,
  isTouched,
  isInvalid,
  disabled = false,
  readOnly = false,
  prefix,
  suffix,
  ...restProps
}) => {
  const isDisabled = disabled || readOnly;
  const isValid = !isDisabled && !isInvalid && (isDirty || isTouched);

  const classes = classNames(
    styles['field'],
    {
      [styles['field-valid']]: isValid,
      [styles['field-invalid']]: isInvalid,
      [styles['field-disabled']]: disabled,
      [styles['field-readonly']]: readOnly,
    },
    className
  );
  const childClasses = classNames({
    [styles['field-valid']]: isFilled,
    [styles['field-prefix']]: prefix,
  });
  return (
    <div className={classes} {...restProps}>
      {prefix && <span className={childClasses}>{prefix}</span>}
      {children}
      {suffix && <span>{suffix}</span>}
    </div>
  );
};

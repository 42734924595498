import React, { useMemo } from 'react';
import {
  AffordabilityModelDetailDto,
  NetworthSourceDisplayNameEnum,
  SelectedSourceEnum,
} from '@harmoney/api-interfaces';
import { formatNumber } from '@harmoney/utilities';
import classNames from 'classnames';

import { RowData, rowIsGrey } from './affordability-utils';
import { getFinalValue, getMonthlyAffordabilityValue, valueIsSelected } from './affordability-utils';

type Props = {
  affordabilityData: AffordabilityModelDetailDto;
};

export const ExpensesTableV2 = ({ affordabilityData }: Props) => {
  const expenseItems = affordabilityData?.items?.expenses;
  const finalExpense = formatNumber(affordabilityData?.overview?.totalExpense, 2);

  const rowsData: RowData[] = useMemo(() => {
    const createRowData = (
      title: string,
      netWorthSource: NetworthSourceDisplayNameEnum,
      isSharedExpense: boolean = false
    ): RowData => {
      const getValue = (source: SelectedSourceEnum) =>
        getMonthlyAffordabilityValue(netWorthSource, source, expenseItems, isSharedExpense);

      const formatValue = (source: SelectedSourceEnum) => formatNumber(getValue(source), 2);

      const isValueSelected = (source: SelectedSourceEnum, isShareable: boolean) =>
        valueIsSelected(source, netWorthSource, expenseItems, isSharedExpense);

      return {
        title,
        declared: {
          value: formatValue(SelectedSourceEnum.DECLARED),
          isSelected: isValueSelected(SelectedSourceEnum.DECLARED, isSharedExpense),
        },
        bankStatement: {
          value: formatValue(SelectedSourceEnum.BANK_STATEMENT),
          isSelected: isValueSelected(SelectedSourceEnum.BANK_STATEMENT, isSharedExpense),
        },
        benchmark: {
          value: formatValue(SelectedSourceEnum.BENCHMARK),
          isSelected: isValueSelected(SelectedSourceEnum.BENCHMARK, isSharedExpense),
        },
        override: {
          value: formatValue(SelectedSourceEnum.CO_OVERRIDE),
          isSelected: isValueSelected(SelectedSourceEnum.CO_OVERRIDE, isSharedExpense),
        },
        final: {
          value: formatNumber(getFinalValue(netWorthSource, expenseItems, isSharedExpense), 2),
          isSelected: true,
        },
      };
    };

    return [
      createRowData('Total Rent', NetworthSourceDisplayNameEnum.EXPENSE_ACCOMMODATION_NAME),
      createRowData('Share of Rent', NetworthSourceDisplayNameEnum.EXPENSE_ACCOMMODATION_NAME, true),
      createRowData('Total Living Exp.', NetworthSourceDisplayNameEnum.EXPENSE_LIVING_NAME),
      createRowData('Share of Living Exp.', NetworthSourceDisplayNameEnum.EXPENSE_LIVING_NAME, true),
      createRowData('Child Support', NetworthSourceDisplayNameEnum.EXPENSE_CHILD_SUPPORT_NAME),
      createRowData('Total Other', NetworthSourceDisplayNameEnum.EXPENSE_OTHER_NAME),
      createRowData('Shared Other', NetworthSourceDisplayNameEnum.EXPENSE_OTHER_NAME, true),
      createRowData('Expenses Increases', NetworthSourceDisplayNameEnum.EXPENSE_INCREASED_NAME),
    ];
  }, [expenseItems]);

  return (
    <table className="border-grey-2 table-fixed border w-full">
      <thead>
        <tr className="divide-grey-2 bg-grey-1 divide-x">
          <th className="w-1/6">Expenses</th>
          <th className="w-1/6">Declared</th>
          <th className="w-1/6">Bank Statement</th>
          <th className="w-1/6">Benchmark</th>
          <th className="w-1/6">Co-Override</th>
          <th className="w-1/6">Final</th>
        </tr>
      </thead>
      <tbody>
        {rowsData.map((row) => (
          <tr key={row.title} className="divide-grey-2 border-grey-2 divide-x border">
            <td className={classNames('bg-grey-1 px-2 py-1 font-bold', { 'text-grey-2': rowIsGrey(row) })}>
              {row.title}
            </td>
            <td className={classNames('px-2 text-right', { 'bg-grey-2': !row.declared.value })}>
              <span className={classNames({ 'font-normal': row.declared.isSelected })}>{row.declared.value ?? ''}</span>
            </td>
            <td className={classNames('px-2 text-right', { 'bg-grey-2': !row.bankStatement.value })}>
              <span className={classNames({ 'font-normal': row.bankStatement.isSelected })}>
                {row.bankStatement.value ?? ''}
              </span>
            </td>
            <td className={classNames('px-2 text-right', { 'bg-grey-2': !row.benchmark.value })}>
              <span className={classNames({ 'font-normal': row.benchmark.isSelected })}>
                {row.benchmark.value ?? ''}
              </span>
            </td>
            <td className={classNames('px-2 text-right', { 'bg-grey-2': !row.override.value })}>
              <span className={classNames({ 'font-normal': row.override.isSelected })}>{row.override.value ?? ''}</span>
            </td>
            <td className={classNames('px-2 text-right', { 'bg-grey-2': !row.final.value })}>
              <span className={classNames({ 'font-bold': row.final.isSelected })}>{row.final.value ?? ''}</span>
            </td>
          </tr>
        ))}
        <tr>
          <td />
          <td />
          <td />
          <td />
          <td className="bg-grey-1 border-x-grey-2 border-x px-2 py-1 font-bold">Total Expenses (Net)</td>
          <td className="px-2 text-right font-bold">{finalExpense}</td>
        </tr>
      </tbody>
    </table>
  );
};

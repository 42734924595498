import { errors } from '@harmoney/ui-utils';
import { RepaymentFrequencyEnum } from '@prisma/client';
import dayjs from 'dayjs';
import { z } from 'zod';

const BANK_ACCOUNT_ERROR = 'Please select a bank account';
const DATE_ERROR = 'Please select a date';

const defaultValues = {
  loanToBankAccount: null,
  repaymentFromBankAccount: null,
  repaymentStartDate: null,
  repaymentFrequency: null,
};

const bankAccountSchema = z
  .string({
    errorMap: (issue, _ctx) => {
      if (issue.code) return { message: BANK_ACCOUNT_ERROR };
    },
  })
  .min(1, { message: BANK_ACCOUNT_ERROR });

const repaymentStartDateSchema = z.coerce
  .date()
  .refine((date) => !dayjs(date).isSame(dayjs(), 'day'), { message: DATE_ERROR });

const repaymentFrequencySchema = z.nativeEnum(RepaymentFrequencyEnum, {
  invalid_type_error: errors.defaultRequiredField,
});

const formSchemaForPersonalLoan = z.object({
  loanToBankAccount: bankAccountSchema,
  repaymentFromBankAccount: bankAccountSchema,
  repaymentFrequency: repaymentFrequencySchema,
  repaymentStartDate: repaymentStartDateSchema,
});

const formSchemaForDebtCon = z
  .object({
    hasPersonalLoanPurpose: z.boolean().nullable().optional(),
    loanToBankAccount: bankAccountSchema.nullable().optional(),
    repaymentFromBankAccount: bankAccountSchema,
    repaymentStartDate: repaymentStartDateSchema,
    repaymentFrequency: repaymentFrequencySchema,
  })
  .refine(
    (data) => {
      if (data.hasPersonalLoanPurpose && !data.loanToBankAccount) return false;
      return true;
    },
    { message: BANK_ACCOUNT_ERROR, path: ['loanToBankAccount'] }
  );

export { defaultValues, formSchemaForPersonalLoan, formSchemaForDebtCon };

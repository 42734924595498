import { generateIcon } from '../generateIcon';

/**
 * @deprecated Check if it can be replaced with Iconify. If not, add it to icon.json.
 * Refer to icon document for details: https://63be80b51f526a24fc7865c5-tslqcdnosl.chromatic.com/?path=/docs/docs-icons--docs
 */
export const UncheckSquareIcon = generateIcon({
  viewBox: '0 0 24 24',
  path: <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="white" stroke="#B2B2B2" />,
});

import { QuoteOption } from '@harmoney/api-interfaces';
import { ArrowCircleRightIcon, Button, Dialog, Divider } from '@harmoney/ui-design-system';
import { formatCurrency, formatFrequency } from '@harmoney/utilities';

import { QuoteDisclaimer } from './QuoteDisclaimer';
import { convertMonthsToYears } from './utils';

interface QuoteReviewModalProps {
  primaryPurpose: string;
  repaymentDetails: QuoteOption;
  isSubmitting: boolean;
  isQuoteReviewModalOpen: boolean;
  onQuoteReviewModalOpen: (isQuoteReviewModalOpen: boolean) => void;
  onSubmit: () => void;
}

export const QuoteReviewModal = ({
  primaryPurpose,
  repaymentDetails,
  isSubmitting,
  isQuoteReviewModalOpen,
  onQuoteReviewModalOpen,
  onSubmit,
}: QuoteReviewModalProps) => {
  if (!repaymentDetails) return null;

  const { establishmentFee, interestRate, loanAmount, repaymentAmount, repaymentFrequency, termInMonths } =
    repaymentDetails;

  return (
    <Dialog
      title="Review your quote"
      open={isQuoteReviewModalOpen}
      onOpenChange={() => onQuoteReviewModalOpen(!isQuoteReviewModalOpen)}
      modal
    >
      <div>
        <ul className="text-sm p-4">
          <li className="mb-2 flex justify-between">
            <span>{primaryPurpose || 'Loan amount'}</span>
            <span className="font-medium">{formatCurrency(loanAmount)}</span>
          </li>
          <li className="mb-2 flex justify-between">
            <span>Establishment fee</span>
            <span className="font-medium">{formatCurrency(establishmentFee)}</span>
          </li>
          <Divider className="text-grey-2" />
          <li className="flex justify-between pt-2 font-medium text-base">
            <span>Loan total</span>
            <span>{formatCurrency(loanAmount + establishmentFee)}</span>
          </li>
        </ul>

        <Divider className="text-grey-2" />

        <ul className="text-sm p-4">
          <li className="mb-2 flex justify-between">
            <span>Your interest rate</span>
            <span className="font-medium">{interestRate}% p.a.</span>
          </li>
          <li className="mb-2 flex justify-between">
            <span>Your repayments</span>
            <span className="font-medium">
              {formatCurrency(repaymentAmount)}/{formatFrequency(repaymentFrequency)}
            </span>
          </li>
          <li className="flex justify-between">
            <span>Your term</span>
            <span className="font-medium">{convertMonthsToYears(termInMonths)} years</span>
          </li>
        </ul>

        <Divider className="text-grey-2" />

        <div className="p-4">
          <QuoteDisclaimer classNameForDisclaimer="text-xs text-grey-4" />
        </div>

        <div className="flex flex-col items-center px-4 pt-4 pb-8">
          <Button
            onClick={onSubmit}
            variant="primary"
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
            isLoading={isSubmitting}
            className="mb-6"
          >
            Submit application
          </Button>
          <Button variant="outline-secondary" onClick={() => onQuoteReviewModalOpen(false)} disabled={isSubmitting}>
            Back
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

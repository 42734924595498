export enum MonoovaReportTypeEnum {
  TRANSACTIONS = 'TRANSACTIONS',
  DIRECT_ENTRY_NPP = 'DIRECT_ENTRY_NPP',
  RTGS_IMP = 'RTGS_IMP',
}

export type MonoovaReportsPayload = {
  eventTime: number;
  endTime: number;
  numberOfDays: number;
  date: string;
  delay: string;
  rtgsStartDate: string;
  rtgsStartTime: number;
};

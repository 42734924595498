import { UseFormRegister } from 'react-hook-form';
import { LoanApplicationDto, LoanProductPurposeDto } from '@harmoney/api-interfaces';
import { Card, CommonOptionProps } from '@harmoney/ui-design-system';
import { LoanApplicationPurpose, LoanPurpose } from '@prisma/client';

import { getLoanPurposeName } from '../../shared';
import { FormSchema, LoanPurposeFormType } from '../form-config';

import PrimaryPurposeForm from './PrimaryPurposeForm';
import { PurposeCard } from './PurposeCard';
import { SecondaryPurposeForm } from './SecondaryPurposeForm';

interface MultiLoanPurposesProps {
  loanApplicationData: LoanApplicationDto;
  loanPurposesData: LoanProductPurposeDto[];
  availableSecondaryPurposes: CommonOptionProps[];
  loanApplicationPurposes: (LoanApplicationPurpose & {
    loanPurpose: LoanPurpose;
  })[];
  uiState: {
    isPrimaryPurposeFormOpen: boolean;
    isSecondaryPurposeFormOpen: boolean;
    errorMsgForSecondaryPurposeAmount: string;
  };
  primaryPurposeAmount: number;
  secondaryPurposeId: string;
  secondaryPurposeAmount: number;
  maxExtraLimitForSecondaryPurpose: number;
  register: UseFormRegister<FormSchema>;
  onEditClick: (form: LoanPurposeFormType) => void;
  onDeleteClick: (id: string) => void;
}

export function MultiLoanPurposes({
  loanApplicationData,
  loanPurposesData,
  availableSecondaryPurposes,
  loanApplicationPurposes,
  uiState,
  primaryPurposeAmount,
  secondaryPurposeId,
  secondaryPurposeAmount,
  maxExtraLimitForSecondaryPurpose,
  register,
  onEditClick,
  onDeleteClick,
}: MultiLoanPurposesProps) {
  return (
    <>
      <Card className="!p-4">
        {((loanApplicationPurposes.length === 1 && !loanApplicationData.hasDeletedLoanPurpose) ||
          uiState.isPrimaryPurposeFormOpen) && (
          <PrimaryPurposeForm
            loanApplicationData={loanApplicationData}
            secondaryPurposeAmount={secondaryPurposeAmount}
            register={register}
          />
        )}

        {loanApplicationPurposes.length > 1 && !uiState.isPrimaryPurposeFormOpen && (
          <PurposeCard
            purpose={{
              name: loanApplicationPurposes[0].loanPurpose.name,
              displayName: loanApplicationPurposes[0].loanPurpose.displayName,
              amount: primaryPurposeAmount,
            }}
            onEdit={() => onEditClick('primary')}
          />
        )}
      </Card>

      {secondaryPurposeId && (
        <Card className="!p-4">
          {uiState.isSecondaryPurposeFormOpen ? (
            <SecondaryPurposeForm
              register={register}
              availableSecondaryPurposes={availableSecondaryPurposes}
              maxExtraLimitForSecondaryPurpose={maxExtraLimitForSecondaryPurpose}
              onDeleteClick={() => onDeleteClick(secondaryPurposeId)}
              errorMsgForSecondaryPurposeAmount={uiState.errorMsgForSecondaryPurposeAmount}
            />
          ) : (
            <PurposeCard
              purpose={{
                ...getLoanPurposeName(loanPurposesData, secondaryPurposeId),
                amount: secondaryPurposeAmount,
              }}
              onEdit={() => onEditClick('secondary')}
            />
          )}
        </Card>
      )}
    </>
  );
}

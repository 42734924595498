import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TaskIdState {
  value: string;
}

const initialState: TaskIdState = {
  value: null,
};

export const taskIdSlice = createSlice({
  name: 'taskId',
  initialState,
  reducers: {
    setTaskId: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
});

export const { setTaskId } = taskIdSlice.actions;

export default taskIdSlice.reducer;

import { LoanApplicationStatusEnum } from '@prisma/client';

export type DeclineCheckResultDto = {
  applicationStatus: LoanApplicationStatusEnum;
  reason: string;
};

export enum DeclineCheckReason {
  UMI_NOT_COVER_REPAYMENT = 'Credit policy failed: UMI cannot cover the repayment amount',
}

import React, { forwardRef } from 'react';
import classNames from 'classnames';

import { PropsWithHTMLElement } from '../../types';

import styles from './TextLink.module.scss';

export type TextLinkInternalProps = {
  /**
   * Determines style variation of TextLink component
   * @default secondary
   */
  variant?: 'primary' | 'secondary';
  /**
   * Determines the destination regarding the link text
   * @default #
   */
  href?: string;
  /**
   * Expects any of the icon components
   */
  icon?: React.ReactElement;
  /**
   * Determines the icon position regarding the link text
   * @default end
   */
  alignIcon?: 'start' | 'end';
  children?: React.ReactNode;
};

export type TextLinkProps = PropsWithHTMLElement<TextLinkInternalProps, 'a'>;

export const TextLink = forwardRef<HTMLAnchorElement, TextLinkProps>(
  (
    {
      variant = 'secondary',
      icon,
      alignIcon = 'end',
      href = '#',
      target = '_blank',
      rel = 'noreferrer noopener',
      children,
      className,
      ...restProps
    }: TextLinkProps,
    ref: React.Ref<HTMLAnchorElement>
  ) => {
    const classes = classNames(
      styles['link'],
      {
        [styles[`link-${variant}`]]: variant,
      },
      className
    );

    const iconContent = icon ? (
      <span className="flex">
        {React.cloneElement(icon, {
          size: 'medium',
        })}
      </span>
    ) : null;

    return (
      <a className={classes} href={href} target={target} rel={rel} ref={ref} {...restProps}>
        {icon && alignIcon === 'start' && iconContent}
        {children && <span className={icon ? styles[alignIcon] : undefined}>{children}</span>}
        {icon && alignIcon === 'end' && iconContent}
      </a>
    );
  }
);

import { ArrowCircleRightIcon, Button, Dialog, PercentageInput, RadioGroup } from '@harmoney/ui-design-system';
import bigDecimal from 'js-big-decimal';

import { calculateDiscountPercentage, getInterestRateAdjustmentOptions } from './utils';

interface DiscountedInterestRateProps {
  scorecardInterestRate: number;
  isConfirmModalOpen: boolean;
  isSubmitting: boolean;
  setIsConfirmModalOpen: (isOpen: boolean) => void;
  onSubmit: () => void;
  onInterestRateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCancelClick: () => void;
  onApplyClick: () => void;
  form: any;
  minInterestRate: number;
}

export const DiscountedInterestRate = ({
  scorecardInterestRate,
  isConfirmModalOpen,
  isSubmitting,
  setIsConfirmModalOpen,
  onSubmit,
  onInterestRateChange,
  onCancelClick,
  onApplyClick,
  form,
  minInterestRate,
}: DiscountedInterestRateProps) => {
  const {
    register,
    watch,
    trigger,
    formState: { errors: formErrors, isValid },
  } = form;

  return (
    <>
      <fieldset className="my-4">
        <RadioGroup
          {...register('adjustedInterestRate', {
            onChange: onInterestRateChange,
          })}
          label="Apply discount"
          options={getInterestRateAdjustmentOptions(scorecardInterestRate, minInterestRate)}
          defaultValue={`${scorecardInterestRate}`}
        />
      </fieldset>
      {watch().adjustedInterestRate === 'custom' && (
        <fieldset className="mb-4">
          <PercentageInput
            {...register('customInterestRate', {
              onChange: () => trigger('customInterestRate'),
            })}
          />
          {!formErrors.customInterestRate?.message &&
            watch().customInterestRate !== null &&
            !isNaN(watch().customInterestRate) && (
              <span className="text-grey-4 mt-2">
                -{calculateDiscountPercentage(scorecardInterestRate, +watch().customInterestRate)}% discount
              </span>
            )}
        </fieldset>
      )}
      <div className="flex justify-end">
        <Button onClick={onCancelClick} variant="text" className="min-w-fit mr-4">
          Cancel
        </Button>
        <Button
          onClick={onApplyClick}
          variant="outline-secondary"
          className="min-w-fit"
          disabled={
            +watch().adjustedInterestRate === +scorecardInterestRate ||
            !isValid ||
            +watch().adjustedInterestRate <= minInterestRate
          }
        >
          Apply
        </Button>
      </div>
      <Dialog
        open={isConfirmModalOpen}
        onOpenChange={() => setIsConfirmModalOpen(!isConfirmModalOpen)}
        title="Interest rate discount change"
        modal
      >
        <div className="p-4">
          <p>
            Are you sure you want to reduce the interest rate on this loan application from{' '}
            <strong>{scorecardInterestRate}%</strong> down to{' '}
            <strong>
              {watch().adjustedInterestRate !== 'custom'
                ? bigDecimal.round(watch().adjustedInterestRate, 2)
                : bigDecimal.round(watch().customInterestRate, 2)}
              %
            </strong>{' '}
            ?
          </p>
          <p>Only authorised staff members are able to make interest rate discounts on applications.</p>
          <div className="shrink-1 flex w-full flex-col items-center justify-start gap-2 sm:flex-col md:flex-row-reverse py-4">
            <Button
              onClick={onSubmit}
              type="submit"
              variant="primary"
              className="sm:!min-w-full md:!min-w-fit md:!max-w-fit"
              alignIcon="end"
              icon={<ArrowCircleRightIcon size="large" />}
              hasShadow
              isLoading={isSubmitting}
            >
              Continue
            </Button>
            <Button onClick={onCancelClick} variant="secondary" className="!min-w-fit !max-w-fit">
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

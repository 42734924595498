import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { AmountInput, Button, ControlledSelect, Label } from '@harmoney/ui-design-system';
import { CommonOptionProps } from '@harmoney/ui-design-system'; // Adjust this import based on the actual path
import { formatCurrency } from '@harmoney/utilities';

import { FormSchema } from '../form-config';

interface SecondaryPurposeFormProps {
  availableSecondaryPurposes: CommonOptionProps[];
  maxExtraLimitForSecondaryPurpose: number;
  errorMsgForSecondaryPurposeAmount: string;
  register: UseFormRegister<FormSchema>;
  onDeleteClick: () => void;
}

export const SecondaryPurposeForm: React.FC<SecondaryPurposeFormProps> = ({
  availableSecondaryPurposes,
  maxExtraLimitForSecondaryPurpose,
  errorMsgForSecondaryPurposeAmount,
  onDeleteClick,
  register,
}: SecondaryPurposeFormProps) => {
  return (
    <>
      <fieldset className="mb-4">
        <Label className="mb-2">What would you like to do?</Label>
        <ControlledSelect {...register('secondaryPurposeId')} options={availableSecondaryPurposes} />
      </fieldset>
      <fieldset className="mb-4">
        <Label className="mb-2">
          Borrow up to an extra <strong>{formatCurrency(maxExtraLimitForSecondaryPurpose)}</strong>
        </Label>
        <AmountInput {...register('secondaryPurposeAmount')} displayValidation={false} maxLength={6} />
        {errorMsgForSecondaryPurposeAmount && (
          <p role="alert" aria-live="assertive" className="leading-sm text-error mt-1 block text-sm mb-0">
            {errorMsgForSecondaryPurposeAmount}
          </p>
        )}
      </fieldset>
      <div className="text-right">
        <Button variant="text" onClick={onDeleteClick}>
          Delete
        </Button>
      </div>
    </>
  );
};

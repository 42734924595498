import { AffordabilityItemDto, NetworthSourceDisplayNameEnum, SelectedSourceEnum } from '@harmoney/api-interfaces';

export const getMonthlyAffordabilityValue = (
  networthSource: NetworthSourceDisplayNameEnum,
  selectedSource: SelectedSourceEnum,
  itemCategory: AffordabilityItemDto[],
  isShareable: boolean = false // default value is false
): number => {
  const source = itemCategory?.find((source) => source?.source === networthSource);
  if (!source) return null;
  const monthlyNetValues = source?.monthlyNetValues;
  return isShareable
    ? monthlyNetValues?.find((item) => item?.id === selectedSource && item?.isShared)?.value
    : monthlyNetValues?.find((item) => item?.id === selectedSource && !item?.isShared)?.value;
};

export const getFinalValue = (
  networthSource: NetworthSourceDisplayNameEnum,
  itemCategory: AffordabilityItemDto[],
  isShareable: boolean = false // default value is false
): number => {
  const source = itemCategory?.find((source) => source?.source === networthSource);
  if (!source) return null;
  if (isShareable && source?.finalSharedValue) return source?.finalSharedValue;
  return isShareable
    ? source?.monthlyNetValues?.find((item) => item?.isSelected && item?.isShared)?.value ?? 0
    : source?.monthlyNetValues?.find((item) => item?.isSelected && !item?.isShared)?.value ?? 0;
};

export const valueIsSelected = (
  selectedSource: SelectedSourceEnum,
  networthSource: NetworthSourceDisplayNameEnum,
  itemCategory: AffordabilityItemDto[],
  isShareable: boolean = false // default value is false
): boolean => {
  const source = itemCategory?.find((source) => source?.source === networthSource);
  if (!source) return false;
  return source?.monthlyNetValues?.find((item) => item?.id === selectedSource && item?.isShared === isShareable)
    ?.isSelected;
};

export const rowIsGrey = (row: RowData) => {
  return Object.values(SelectedSourceEnum).every((key) => !row[key]?.value);
};

export type RowData = {
  title: string;
  ai?: RowItem;
  final: RowItem;
  benchmark?: RowItem;
  declared?: RowItem;
  bankStatement: RowItem;
  override: RowItem;
  minAcceptableValue?: RowItem;
};

type RowItem = {
  value: string;
  isSelected?: boolean;
};

import classNames from 'classnames';

export const Field = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return (
    <span
      className={classNames(
        className,
        'grid grid-cols-2 gap-1 pb-4 first-letter:capitalize [&>span:last-child]:font-medium'
      )}
    >
      {children}
    </span>
  );
};

import { useMemo } from 'react';
import { LoanApplicationQueryResponse, useGetInterestRateAdjustmentsQuery } from '@harmoney/redux';
import { CollapsibleCard } from '@harmoney/ui-design-system';
import { DATE_TIME_FORMAT } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import { LoanApplicationScorecardStatusEnum, Prisma, ScorecardOperation } from '@prisma/client';
import dayjs from 'dayjs';
import bigDecimal from 'js-big-decimal';

import { Field } from '../shared';

import { calculateDiscountPercentage } from './utils';

interface Props {
  loanApplicationData: LoanApplicationQueryResponse;
  scorecardData: ScorecardOperation[];
  status: LoanApplicationScorecardStatusEnum;
  rootData: Prisma.JsonValue;
}

export const ScorecardDetails = ({ loanApplicationData, status, scorecardData, rootData }: Props) => {
  const { data: interestRateAdjustments } = useGetInterestRateAdjustmentsQuery(loanApplicationData?.id, {
    skip: !loanApplicationData || !loanApplicationData?.customerInterestRate,
    refetchOnMountOrArgChange: true,
  });

  const scorecardInterestRate = +loanApplicationData?.customerInterestRate;

  const sortedScorecardData = useMemo(() => {
    if (scorecardData && scorecardData.length > 0) {
      const sortedScorecardData = [...scorecardData].sort((a, b) => {
        return dayjs(a.timestamp).diff(dayjs(b.timestamp));
      });

      const original = sortedScorecardData[0];

      const previous = sortedScorecardData.length < 2 ? null : sortedScorecardData[sortedScorecardData.length - 2];

      const latest = sortedScorecardData[sortedScorecardData.length - 1];

      if (dayjs(original?.timestamp).isSame(dayjs(latest?.timestamp), 'minute')) {
        return { latest };
      }

      return { original, previous, latest };
    }
  }, [scorecardData]);

  if (!interestRateAdjustments) return null;

  return (
    <>
      <CollapsibleCard
        title="Scorecard"
        className="mb-6"
        description={
          interestRateAdjustments?.['user'] &&
          `Interest rate last edited by ${interestRateAdjustments?.['user']?.['email']} on ${dayjs(interestRateAdjustments?.['createdAt']).format(DATE_TIME_FORMAT)}`
        }
      >
        {interestRateAdjustments && (
          <div className="grid grid-cols-2 px-4">
            <div className="border-r-grey-1 border-r-2 p-4 space-y-2">
              <Field>
                <span>Interest Rate:</span>
                <div className="flex flex-col">
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      {interestRateAdjustments.finalInterestRate ? (
                        <>
                          <span>{bigDecimal.round(interestRateAdjustments?.finalInterestRate, 2)}%</span>
                          <span className=" text-grey-4">
                            Discount applied: -
                            {calculateDiscountPercentage(
                              scorecardInterestRate,
                              +interestRateAdjustments.finalInterestRate
                            )}
                            %
                          </span>
                          <span className=" text-grey-4">Scorecard interest rate: {`${scorecardInterestRate}%`}</span>
                        </>
                      ) : (
                        <span>{`${new bigDecimal(+loanApplicationData?.finalInterestRate).round(2).getValue()}%`}</span>
                      )}
                    </div>
                  </div>
                  {sortedScorecardData?.previous && (
                    <span className="text-sm text-grey-3">
                      Previous value:{' '}
                      {`${new bigDecimal(+sortedScorecardData.previous.interestRate).round(2).getValue()}%`}
                    </span>
                  )}
                </div>
              </Field>
              <Field>
                <span>Date:</span>
                <span>{dayjs(sortedScorecardData?.latest?.timestamp).format(DATE_TIME_FORMAT)}</span>
              </Field>
              <Field>
                <span>Score Limit:</span>
                <div className="flex flex-col">
                  <span>
                    {formatCurrency(
                      rootData?.['interestRateAndCreditLimit']?.['originalCreditLimit'] ||
                        rootData?.['interestRateAndCreditLimit']?.['creditLimit']
                    )}
                  </span>
                </div>
              </Field>
              <Field>
                <span>Credit Limit:</span>
                <div className="flex flex-col">
                  <span>{formatCurrency(sortedScorecardData?.latest?.creditLimit)}</span>
                  {sortedScorecardData?.previous && (
                    <span className="text-sm text-grey-3">
                      Previous value: {formatCurrency(sortedScorecardData?.previous.creditLimit)}
                    </span>
                  )}
                </div>
              </Field>
              <Field>
                <span>Prob. of Default:</span>
                <div className="flex flex-col">
                  <span>{sortedScorecardData?.latest?.probabilityOfDefault.toString()}</span>
                  {sortedScorecardData?.previous && (
                    <span className="text-sm text-grey-3">
                      Previous value: {sortedScorecardData.previous.probabilityOfDefault.toString()}
                    </span>
                  )}
                </div>
              </Field>
            </div>
            <div className="p-4 space-y-2">
              <Field>
                <span>Status:</span>
                <span className={status === LoanApplicationScorecardStatusEnum.Passed ? 'text-success' : 'text-error'}>
                  {status}
                </span>
              </Field>
              {sortedScorecardData?.latest.status === 'fail' && (
                <Field>
                  <span>Reason:</span>
                  <div className="flex flex-col">
                    <span>{sortedScorecardData?.latest.errorMessage}</span>
                    {sortedScorecardData?.previous && sortedScorecardData.previous.status === 'fail' && (
                      <span className="text-sm text-grey-3">
                        Previous value: {sortedScorecardData.previous.errorMessage}
                      </span>
                    )}
                  </div>
                </Field>
              )}
              <Field>
                <span>Model ID:</span>
                <div className="flex flex-col">
                  <span>{sortedScorecardData?.latest.modelId}</span>
                  {sortedScorecardData?.previous &&
                    sortedScorecardData.previous.modelId !== sortedScorecardData.latest.modelId && (
                      <span className="text-sm text-grey-3">
                        Previous value: {sortedScorecardData.previous.modelId}
                      </span>
                    )}
                </div>
              </Field>
              <Field>
                <span>Scorecard Version:</span>
                <div className="flex flex-col">
                  <span>{sortedScorecardData?.latest.scorecardVersion}</span>
                  {sortedScorecardData?.previous &&
                    sortedScorecardData.previous.scorecardVersion !== sortedScorecardData.latest.scorecardVersion && (
                      <span className="text-sm text-grey-3">
                        Previous value: {sortedScorecardData.previous.scorecardVersion}
                      </span>
                    )}
                </div>
              </Field>
            </div>
          </div>
        )}
      </CollapsibleCard>
    </>
  );
};

import React from 'react';
import { formatCurrency, formatFrequency } from '@harmoney/utilities';
import { RepaymentFrequencyEnum } from '@prisma/client';
import classNames from 'classnames';

import { convertMonthsToYears } from './utils';

type Props = {
  repaymentAmount: number;
  repaymentFrequency: RepaymentFrequencyEnum;
  termInMonths: number;
  className?: string;
};

export const QuoteRepayment = ({ repaymentAmount, repaymentFrequency, termInMonths, className }: Props) => {
  return (
    <div className={classNames('text-center', className)}>
      <p className="mb-0">You&rsquo;ll pay</p>
      <h2 className="mb-0">
        {formatCurrency(repaymentAmount)} / {repaymentFrequency && formatFrequency(repaymentFrequency)}
      </h2>
      <p className="mb-0 font-bold">on a {convertMonthsToYears(termInMonths)} year term</p>
    </div>
  );
};

import { addCollection, Icon as IconifyIcon, IconProps } from '@iconify/react';

import icons from './icons.json';

addCollection(icons);

//TODO: Rename to Icon once all icons are migrated
export const IconV2 = (props: IconProps) => {
  return <IconifyIcon {...props} />;
};

import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { useFinancialConfirm, useFriendlyURL, useUserInformationConfirm } from '@harmoney/hooks';
import { ArrowCircleRightIcon, Button } from '@harmoney/ui-design-system';

import { CommonProps } from '../../common-props';

export function LifeChange({ taskId, completeTaskWithData, taskFriendlyURL }: CommonProps) {
  useFriendlyURL(taskFriendlyURL);
  const { resetFinancialConfirmation, resetLocalPartnerIncome } = useFinancialConfirm();
  const { resetConfirmation: resetUserInformationConfirmation } = useUserInformationConfirm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleClick = async () => {
    try {
      setIsSubmitting(true);
      await completeTaskWithData({ taskId });
    } catch (e) {
      console.error(`Error completing task ${taskId}`, e);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    resetFinancialConfirmation();
    resetLocalPartnerIncome();
    resetUserInformationConfirmation();
  }, []);

  return (
    <>
      <h1>
        We&apos;ve <span className="text-primary">kept your details</span> from your last application
      </h1>
      <div className="mx-auto text-center mb-3">
        <Image
          alt="Life Changes"
          src="/assets/images/change-life.svg"
          width={128}
          height={173}
          className="md:w-[256px] md:h-[346px]"
        />
      </div>
      <p className="font-normal mb-6">
        In the next couple of pages, take a moment to{' '}
        <span className="font-medium">review and update the information</span> we have on our end.
      </p>
      <div className="text-center">
        <Button
          type="submit"
          alignIcon="end"
          variant="primary"
          icon={<ArrowCircleRightIcon size="large" />}
          hasShadow
          onClick={handleClick}
          isLoading={isSubmitting}
        >
          Continue
        </Button>
      </div>
    </>
  );
}

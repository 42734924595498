/**
 * Please import icons alphabetically
 */

import { AddressCardIcon } from './Icons/AddressCardIcon';
import { ArrowCircleRightIcon } from './Icons/ArrowCircleRightIcon';
import { BankStatementIcon } from './Icons/BankStatementIcon';
import { CheckRadioIcon } from './Icons/CheckRadioIcon';
import { CheckSquareIcon } from './Icons/CheckSquareIcon';
import { ChevronIcon } from './Icons/ChevronIcon';
import { ChevronRightIcon } from './Icons/ChevronRightIcon';
import { ClockIcon } from './Icons/ClockIcon';
import { ClockLightningIcon } from './Icons/ClockLightningIcon';
import { EmailVerificationIcon } from './Icons/EmailVerificationIcon';
import { EmptyProgressIcon } from './Icons/EmptyProgressIcon';
import { GraphIcon } from './Icons/GraphIcon';
import { DebtConIcon } from './Icons/IconsWithVariant/DebtConIcon';
import { FlagIcon } from './Icons/IconsWithVariant/FlagIcon';
import { ImprovingMyHomeIcon } from './Icons/IconsWithVariant/ImprovingMyHomeIcon';
import { LoginNoViewIcon } from './Icons/LoginNoViewIcon';
import { NoAccountChangesIcon } from './Icons/NoAccountChangesIcon';
import { PendingBankStatementIcon } from './Icons/PendingBankStatementIcon';
import { StaticProgressIcon } from './Icons/StaticProgressIcon';
import { UncheckSquareIcon } from './Icons/UncheckSquareIcon';
import { UserCheckIcon } from './Icons/UserCheckIcon';
import { VerificationErrorIcon } from './Icons/VerificationErrorIcon';
import { VerificationSuccessIcon } from './Icons/VerificationSuccessIcon';

export * from './Icon';
export * from './V2';
export { ClockLightningIcon } from './Icons/ClockLightningIcon';
export { AddressCardIcon } from './Icons/AddressCardIcon';
export { ArrowCircleRightIcon } from './Icons/ArrowCircleRightIcon';
export { BankStatementIcon } from './Icons/BankStatementIcon';
export { CheckRadioIcon } from './Icons/CheckRadioIcon';
export { CheckSquareIcon } from './Icons/CheckSquareIcon';
export { ChevronIcon } from './Icons/ChevronIcon';
export { ChevronRightIcon } from './Icons/ChevronRightIcon';
export { ClockIcon } from './Icons/ClockIcon';
export { DisabledSquareIcon } from './Icons/DisabledSquareIcon';
export { EmailVerificationIcon } from './Icons/EmailVerificationIcon';
export { EmptyProgressIcon } from './Icons/EmptyProgressIcon';
export { FlagIcon } from './Icons/IconsWithVariant/FlagIcon';
export { GraphIcon } from './Icons/GraphIcon';
export { ImprovingMyHomeIcon } from './Icons/IconsWithVariant/ImprovingMyHomeIcon';
export { LoginNoViewIcon } from './Icons/LoginNoViewIcon';
export { NoAccountChangesIcon } from './Icons/NoAccountChangesIcon';
export { PendingBankStatementIcon } from './Icons/PendingBankStatementIcon';
export { StaticProgressIcon } from './Icons/StaticProgressIcon';
export { UserCheckIcon } from './Icons/UserCheckIcon';
export { UncheckSquareIcon } from './Icons/UncheckSquareIcon';
export { VerificationErrorIcon } from './Icons/VerificationErrorIcon';
export { VerificationSuccessIcon } from './Icons/VerificationSuccessIcon';
export { getIconComponent } from './Icons/IconsWithVariant';
export { DebtConIcon } from './Icons/IconsWithVariant/DebtConIcon';

export const HmyIcons = [
  {
    name: 'AddressCardIcon',
    icon: AddressCardIcon,
  },
  {
    name: 'ArrowCircleRightIcon',
    icon: ArrowCircleRightIcon,
  },
  {
    name: 'UncheckSquareIcon',
    icon: UncheckSquareIcon,
  },
  {
    name: 'BankStatementIcon',
    icon: BankStatementIcon,
  },
  {
    name: 'CheckRadioIcon',
    icon: CheckRadioIcon,
  },
  {
    name: 'CheckSquareIcon',
    icon: CheckSquareIcon,
  },
  {
    name: 'ChevronIcon',
    icon: ChevronIcon,
  },
  {
    name: 'ChevronRightIcon',
    icon: ChevronRightIcon,
  },
  {
    name: 'ClockIcon',
    icon: ClockIcon,
  },
  {
    name: 'ClockLightningIcon',
    icon: ClockLightningIcon,
  },
  {
    name: 'EmailVerificationIcon',
    icon: EmailVerificationIcon,
  },
  {
    name: 'EmptyProgressIcon',
    icon: EmptyProgressIcon,
  },
  {
    name: 'FlagIcon',
    icon: FlagIcon,
  },
  {
    name: 'GraphIcon',
    icon: GraphIcon,
  },
  { name: 'ImprovingMyHomeIcon', icon: ImprovingMyHomeIcon },
  {
    name: 'LoginNoViewIcon',
    icon: LoginNoViewIcon,
  },
  {
    name: 'PendingBankStatementIcon',
    icon: PendingBankStatementIcon,
  },
  {
    name: 'StaticProgressIcon',
    icon: StaticProgressIcon,
  },
  {
    name: 'UserCheckIcon',
    icon: UserCheckIcon,
  },
  {
    name: 'VerificationErrorIcon',
    icon: VerificationErrorIcon,
  },
  {
    name: 'VerificationSuccessIcon',
    icon: VerificationSuccessIcon,
  },
  {
    name: 'NoAccountChangesIcon',
    icon: NoAccountChangesIcon,
  },
  {
    name: 'DebtConIcon',
    icon: DebtConIcon,
  },
];

import Select, { GroupBase, Props } from 'react-select';
import { clsx } from 'clsx';

import { Label } from '../Label';

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    label?: string;
  }
}

const controlStyles = {
  base: 'border rounded-lg bg-white hover:cursor-pointer hover:border-secondary border-grey-2',
  focus: 'border-secondary ring-1 ring-secondary',
  nonFocus: 'border-grey-2 hover:border-grey-4',
};
const placeholderStyles = 'text-grey-3 p-1';
const selectInputStyles = 'p-1';
const valueContainerStyles = 'p-1 gap-1';
const singleValueStyles = 'leading-7 ml-1';
const multiValueStyles = 'bg-secondary-lighter-2 rounded flex flex-row items-center align-middle pl-2 pr-1 gap-1';
const multiValueLabelStyles = 'leading-6 py-1 bg-primary-ligther-2';
const multiValueRemoveStyles = 'border hover:text-error-hover text-grey-5 hover:border-red-300 rounded';
const indicatorsContainerStyles = 'p-1 gap-1';
const clearIndicatorStyles = 'text-grey-5 p-1 rounded-md hover:bg-red-50 hover:text-red-800';
const indicatorSeparatorStyles = 'display-none';
const dropdownIndicatorStyles = 'p-1 hover:bg-grey-1 text-grey-4 rounded-md hover:text-black';
const menuStyles = 'p-1 mt-2 border border-grey-2 bg-white rounded-lg';
const groupHeadingStyles = 'ml-3 mt-2 mb-1 text-grey-5 text-sm';
const optionStyles = {
  base: 'hover:cursor-pointer px-3 py-2 rounded',
  focus: 'bg-grey-1 active:bg-grey-200',
  selected: "after:content-['✔'] after:ml-2 after:text-green-5 text-grey-5",
};
const noOptionsMessageStyles = 'text-grey-500 p-2 bg-grey-50 rounded-sm';

export function MultiSelect<Option, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: Props<Option, boolean, Group>
) {
  const { label } = props;
  return (
    <>
      {label && (
        <Label className="mb-1" aria-labelledby={label}>
          {label}
        </Label>
      )}
      <Select
        className="rounded-lg"
        isMulti
        unstyled
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        menuShouldScrollIntoView={false}
        maxMenuHeight={250}
        menuPlacement="auto"
        styles={{
          input: (base) => ({
            ...base,
            'input:focus': {
              boxShadow: 'none',
            },
          }),
          // On mobile, the label will truncate automatically, so we want to
          // override that behaviour.
          multiValueLabel: (base) => ({
            ...base,
            whiteSpace: 'normal',
            overflow: 'visible',
          }),
          control: (base) => ({
            ...base,
            transition: 'none',
          }),
        }}
        classNames={{
          control: ({ isFocused }) =>
            clsx(isFocused ? controlStyles.focus : controlStyles.nonFocus, controlStyles.base),
          placeholder: () => placeholderStyles,
          input: () => selectInputStyles,
          valueContainer: () => valueContainerStyles,
          singleValue: () => singleValueStyles,
          multiValue: () => multiValueStyles,
          multiValueLabel: () => multiValueLabelStyles,
          multiValueRemove: () => multiValueRemoveStyles,
          indicatorsContainer: () => indicatorsContainerStyles,
          clearIndicator: () => clearIndicatorStyles,
          indicatorSeparator: () => indicatorSeparatorStyles,
          dropdownIndicator: () => dropdownIndicatorStyles,
          menu: () => menuStyles,
          groupHeading: () => groupHeadingStyles,
          option: ({ isFocused, isSelected }) =>
            clsx(isFocused && optionStyles.focus, isSelected && optionStyles.selected, optionStyles.base),
          noOptionsMessage: () => noOptionsMessageStyles,
        }}
        {...props}
      />
    </>
  );
}

import { PreQualifiedQuoteStatusEnum } from '@prisma/client';

export const preQualifiedQuoteLabelMapper: Record<PreQualifiedQuoteStatusEnum, string> = {
  [PreQualifiedQuoteStatusEnum.QUOTE]: 'Quote',
  [PreQualifiedQuoteStatusEnum.NO_QUOTE]: 'No Quote',
};

export const preQualifiedQuoteEmojiMapper: Record<PreQualifiedQuoteStatusEnum, string> = {
  [PreQualifiedQuoteStatusEnum.QUOTE]: '💰',
  [PreQualifiedQuoteStatusEnum.NO_QUOTE]: '❌',
};

export const preQualifiedQuoteStatusMapper: Record<PreQualifiedQuoteStatusEnum, string> = {
  [PreQualifiedQuoteStatusEnum.QUOTE]: 'Prequalified Quote',
  [PreQualifiedQuoteStatusEnum.NO_QUOTE]: 'No Quote',
};

import bigDecimal from 'js-big-decimal';

export enum DiscountPercentEnum {
  Zero = 0,
  Ten = 10,
  Twenty = 20,
}

export const calculateRateByPercentage = (scorecardInterestRate: number, discountPercent: number) => {
  const discount = new bigDecimal(scorecardInterestRate * (discountPercent / 100));
  const result = new bigDecimal(scorecardInterestRate).subtract(discount);
  return +result.round(2, 1).getValue();
};

export const calculateDiscountPercentage = (scorecardInterestRate: number, customRate: number) => {
  const discount = scorecardInterestRate - customRate;
  return +new bigDecimal((discount / scorecardInterestRate) * 100).round(2, 1).getValue();
};

export const getInterestRateAdjustmentOptions = (scorecardInterestRate: number, minInterestRate: number) => {
  const result = [
    {
      label: `
          <div class="flex justify-between">
            <span>${scorecardInterestRate}%</span>
            <span class="mr-4 font-normal text-grey-4">Scorecard interest rate</span>
          </div>`,
      value: `${scorecardInterestRate}`,
    },
  ];
  if (minInterestRate <= calculateRateByPercentage(scorecardInterestRate, DiscountPercentEnum.Ten)) {
    result.push({
      label: `
          <div class="flex justify-between">
            <span>${calculateRateByPercentage(scorecardInterestRate, DiscountPercentEnum.Ten)}%</span>
            <span class="mr-4 font-normal text-grey-4">(-${DiscountPercentEnum.Ten}% discount)</span>
          </div>`,
      value: `${calculateRateByPercentage(scorecardInterestRate, DiscountPercentEnum.Ten)}`,
    });
  }
  if (minInterestRate <= calculateRateByPercentage(scorecardInterestRate, DiscountPercentEnum.Twenty)) {
    result.push({
      label: `
          <div class="flex justify-between">
            <span>${calculateRateByPercentage(scorecardInterestRate, DiscountPercentEnum.Twenty)}%</span>
            <span class="mr-4 font-normal text-grey-4">(-${DiscountPercentEnum.Twenty}% discount)</span>
          </div>`,
      value: `${calculateRateByPercentage(scorecardInterestRate, DiscountPercentEnum.Twenty)}`,
    });
  }
  result.push({
    label: 'Custom interest rate',
    value: 'custom',
  });
  return result;
};

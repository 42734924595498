import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Pusher from 'pusher-js';

export interface PusherState {
  value: Pusher;
}

const initialState: PusherState = {
  value: null,
};

export const pusherSlice = createSlice({
  name: 'pusher',
  initialState,
  reducers: {
    setPusher: (state, action: PayloadAction<Pusher>) => {
      state.value = action.payload;
    },
  },
});

export const { setPusher } = pusherSlice.actions;

export default pusherSlice.reducer;

import { IncomeAndExpenseFrequencyEnum as Frequency } from '@prisma/client';

export const frequencyConversionMap: Partial<Record<Frequency, number>> = {
  [Frequency.weekly]: 52 / 12,
  [Frequency.fortnightly]: 26 / 12,
  [Frequency.yearly]: 1 / 12,
  [Frequency.monthly]: 1,
};

export const convertToMonthlyAmount = (amount: number, frequency: Frequency): number => {
  const conversionFactor = frequencyConversionMap[frequency];
  return amount * (conversionFactor || 1);
};

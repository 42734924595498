import { SaveUserActivityDto } from '@harmoney/api-interfaces';
import { ArrowCircleRightIcon, Button, Form, ToggleGroup, useForm } from '@harmoney/ui-design-system';
import { Icon } from '@iconify/react';
import { UserActivityPurposeEnum, UserActivityTypeEnum } from '@prisma/client';
import { z } from 'zod';

interface BlockAccessToRepeatFormProps {
  onSaveUserActivity: (data: Omit<SaveUserActivityDto, 'userId'>) => void;
  onCancel: () => void;
}

const formSchema = z.object({
  activityPurpose: z.nativeEnum(UserActivityPurposeEnum),
});

const activityPurposes = [
  { label: 'block', value: UserActivityPurposeEnum.INITIALISE },
  { label: 'unblock', value: UserActivityPurposeEnum.REMOVE },
];

export const BlockAccessToRepeatForm = ({ onSaveUserActivity, onCancel }: BlockAccessToRepeatFormProps) => {
  const form = useForm({
    mode: 'onSubmit',
    schema: formSchema,
    defaultValues: {
      activityPurpose: null,
    },
  });

  const {
    register,
    formState: { isSubmitSuccessful, isSubmitting },
  } = form;

  const handleSubmit = (data: z.infer<typeof formSchema>) => {
    onSaveUserActivity({
      activityType: UserActivityTypeEnum.REPEAT_HARDBLOCK,
      activityHappenedAt: new Date(),
      purpose: data.activityPurpose,
      source: 'Admin portal',
    });
  };

  return (
    <>
      <div className="flex justify-between aligns-center mb-6">
        <h1 className="mb-0">Block access to repeat loans</h1>
        <span className="flex items-center">
          <Icon icon="ic:round-close" onClick={onCancel} className="cursor-pointer" width={24} />
        </span>
      </div>

      <Form form={form} onSubmit={handleSubmit}>
        <ToggleGroup
          {...register('activityPurpose')}
          label="Block access to repeat loans"
          options={activityPurposes}
          className="sm:min-w-full"
        />
        <div className="mt-8 flex justify-between gap-4">
          <Button
            variant="tertiary"
            onClick={onCancel}
            size="medium"
            className="!min-w-fit"
            disabled={isSubmitting || isSubmitSuccessful}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            size="medium"
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="medium" />}
            className="sm:min-w-fit"
            isLoading={isSubmitting || isSubmitSuccessful}
          >
            Submit
          </Button>
        </div>
      </Form>
    </>
  );
};

import { CommonOptionProps } from '@harmoney/ui-design-system';
import { capitalize, kebabCase } from 'lodash';

interface Condition {
  key?: string;
  threshold?: number;
  showImage?: boolean;
}

export const transformRadioSelectOptions = (items: any[], condition?: Condition) => {
  const { key, threshold, showImage } = { key: 'id', threshold: 3, showImage: false, ...condition };
  const primaryOptions: CommonOptionProps[] = [];
  const secondaryOptions: CommonOptionProps[] = [];
  items?.forEach((item, index) => {
    const itemVal = {
      label: capitalize(item.name),
      value: item[key].toString(),
    } as CommonOptionProps;
    if (index < threshold && showImage) {
      itemVal.imageSrc = `/assets/images/${kebabCase(item.code)}.svg`;
    }
    index < threshold ? primaryOptions.push(itemVal) : secondaryOptions.push(itemVal);
  });
  return { primaryOptions, secondaryOptions };
};

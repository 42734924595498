import React from 'react';
import { parseUTCAndConvertToLocalTime } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import { Icon } from '@iconify/react';
import { RepaymentFrequencyEnum } from '@prisma/client';
import dayjs from 'dayjs';

import { getRepaymentFrequencyLabel } from '../utils';

interface RepaymentInfoProps {
  date: string;
  amount: number;
  repaymentFrequency?: RepaymentFrequencyEnum;
  oneOffIncreasedAmount?: number;
  isFirstRepayment?: boolean;
  showDueDay?: boolean;
}

export const RepaymentInfo: React.FC<RepaymentInfoProps> = ({
  date,
  amount,
  repaymentFrequency,
  oneOffIncreasedAmount = 0,
  isFirstRepayment = false,
  showDueDay = false,
}) => {
  const isRecurring = !isFirstRepayment;
  const iconType = isFirstRepayment ? 'mdi:calendar-today' : 'mdi:calendar-month';
  const title = isFirstRepayment ? 'First direct debit' : 'Recurring direct debit';

  return (
    <div className={`bg-grey-1 p-4 flex justify-between ${isFirstRepayment ? 'my-4' : 'mt-4'} rounded-lg`}>
      <div className="flex">
        <span>
          <Icon icon={iconType} className="text-grey-4 mr-2" width={24} height={24} />
        </span>
        <div className="flex flex-col">
          <strong className="text-base font-medium">{title}</strong>
          <span className="text-sm">
            {isFirstRepayment ? 'On' : 'Starts'} {parseUTCAndConvertToLocalTime(date).format('ddd, D MMMM')}
          </span>
          {showDueDay && <span className="text-sm">In {Math.abs(dayjs().diff(date, 'days'))} days</span>}
          {isFirstRepayment && oneOffIncreasedAmount > 0 && (
            <p className="text-xs">
              Includes a one-off increase of {formatCurrency(oneOffIncreasedAmount)} to ensure your first required
              monthly payment is paid in full.
            </p>
          )}
          {isRecurring && (
            <span className="text-sm">
              Repeats {repaymentFrequency === RepaymentFrequencyEnum.monthly && 'on the '}
              {getRepaymentFrequencyLabel(date, repaymentFrequency)}
            </span>
          )}
        </div>
      </div>
      <strong className="text-lg font-medium">{formatCurrency(amount)}</strong>
    </div>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * Frankie Financial API
 * ------   This API allows developers to integrate the Frankie Financial Compliance Utility into their applications. The API allows:   - Checking name, address, date of birth against national databases   - Validating Australian driver's licences, passports, medicare, visas and other Australian national ID documents   - Validating Australian electricity bills   - Validating NZ driver's licences   - Validating Chinese bank cards and national ID card   - Validating International passports and national ID documents   - PEP, Sanctions, Watchlist and adverse media checking   - Australian visa checks    - Fraud list and fraud background checks   - ID validation and selfie check comparisons.    ------     Industry specific services    - Comparing Australian electricity retailers for a better deal.  ------     KYB specific services    - Query organisation ownership   - Perform KYC & AML checks on shareholders, beneficial owners and office bearers.   - Query credit score and credit reports   - International company searches   - International company profiles    ------   The full version of this documentation along with supplemental articles can be found here:   - https://apidocs.frankiefinancial.com/  ------ Sandbox base URL is:   - https://api.demo.frankiefinancial.io/compliance/v1.2      - All calls are the same as production, only with test data. You can download the latest test data here: https://apidocs.frankiefinancial.com/docs/test-data    - Full Swagger definition, along with test data for playing in the sandbox can be obtained once initial commercial discussions have commenced.    - Production and optional UAT access will be opened up only to those with a signed commercial contract.    ------   Contact us at hello@frankiefinancial.com to speak with a sales rep about issuing a Customer ID and Sandbox api key.
 *
 * OpenAPI spec version: 1.14.0
 * Contact: dev-support@frankiefinancial.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * The standard MIME type of the file being uploaded. We'll double-check to be certain, but this can help speed things up
 * @export
 * @enum {string}
 */
export enum EnumMIMEType {
  Imagejpeg = 'image/jpeg',
  Imageavif = 'image/avif',
  Imagepng = 'image/png',
  Imagegif = 'image/gif',
  Imagewebp = 'image/webp',
  Imagetiff = 'image/tiff',
  Imagebmp = 'image/bmp',
  Imageheic = 'image/heic',
  Imageheif = 'image/heif',
  Applicationzip = 'application/zip',
  ApplicationxZip = 'application/x-zip',
  ApplicationxZipCompressed = 'application/x-zip-compressed',
  ApplicationxTar = 'application/x-tar',
  ApplicationxRarCompressed = 'application/x-rar-compressed',
  ApplicationxGzip = 'application/x-gzip',
  Applicationgzip = 'application/gzip',
  ApplicationxBzip2 = 'application/x-bzip2',
  Applicationx7zCompressed = 'application/x-7z-compressed',
  Applicationpdf = 'application/pdf',
  Applicationrtf = 'application/rtf',
  Applicationpostscript = 'application/postscript',
  Applicationjson = 'application/json',
  Audiompeg = 'audio/mpeg',
  Audiom4a = 'audio/m4a',
  AudioxWav = 'audio/x-wav',
  Audioamr = 'audio/amr',
  Textplain = 'text/plain',
  Textrtf = 'text/rtf',
  Applicationmsword = 'application/msword',
  ApplicationvndOpenxmlformatsOfficedocumentWordprocessingmlDocument = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ApplicationvndMsExcel = 'application/vnd.ms-excel',
  ApplicationvndOpenxmlformatsOfficedocumentSpreadsheetmlSheet = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ApplicationvndMsOutlook = 'application/vnd.ms-outlook',
  ApplicationvndMsPowerpoint = 'application/vnd.ms-powerpoint',
  ApplicationvndOasisOpendocumentText = 'application/vnd.oasis.opendocument.text',
  ApplicationvndOpenxmlformatsOfficedocumentPresentationmlPresentation = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  Videoavi = 'video/avi',
  VideoxM4a = 'video/x-m4a',
  Videomp4 = 'video/mp4',
  Videowebm = 'video/webm',
  Videoquicktime = 'video/quicktime',
  Videomsvideo = 'video/msvideo',
  VideoxMsvideo = 'video/x-msvideo',
  VideoxMsWmv = 'video/x-ms-wmv',
  Videompeg = 'video/mpeg',
}

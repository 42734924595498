import { UseFormRegister } from 'react-hook-form';
import { Card, CommonOptionProps, RadioGroup, Spinner, ToggleGroup } from '@harmoney/ui-design-system';
import { frequencyOptionsWithoutDefault } from '@harmoney/utilities';

import { FormSchema } from '../ValidQuoteV3/form-config';

interface RepaymentOptionsProps {
  formattedQuoteOptions: CommonOptionProps[];
  register: UseFormRegister<FormSchema>;
}

export function RepaymentOptions({ formattedQuoteOptions, register }: RepaymentOptionsProps) {
  return (
    <Card className="!p-4">
      <fieldset className="mb-4">
        <ToggleGroup
          {...register('repaymentFrequency')}
          options={frequencyOptionsWithoutDefault}
          className="font-medium"
          label="How would you like to repay your loan?"
        />
      </fieldset>
      <fieldset>
        <legend className="mb-2 font-medium">Over how many years?</legend>
        <p className="mb-2">You can pay your loan early without any extra charge.</p>
        {formattedQuoteOptions ? (
          <RadioGroup {...register('termInMonths')} options={formattedQuoteOptions} />
        ) : (
          <div className="flex">
            <Spinner size="small" />
            &nbsp;&nbsp;Generating quote options ...
          </div>
        )}
      </fieldset>
    </Card>
  );
}

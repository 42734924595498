import { useEffect, useState } from 'react';
import { RepaymentFrequency } from '@harmoney/api-interfaces';
import { useFormatQuoteOptions, useFriendlyURL, useQuoteData } from '@harmoney/hooks';
import {
  useAppSelector,
  useGetLoanApplicationQuery,
  useGetQuoteOptionsQuery,
  useGetUserQuery,
  useGetVariablesQuery,
  useSubmitLoanApplicationMutation,
} from '@harmoney/redux';
import { eventAnalytics, LOAN_APPLICATION_SUBMITTED } from '@harmoney/ui-app-shell';
import { Alert, Button, Card, Form, RadioGroup, Spinner, ToggleGroup, useForm } from '@harmoney/ui-design-system';
import { AmountFormatter, frequencyOptionsWithoutDefault } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import { LoanApplicationStatusEnum } from '@prisma/client';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { z } from 'zod';

import { CommonProps } from '../../common-props';
dayjs.extend(relativeTime);

const getValidationMessageForAmount = (lowerLimit: number, upperLimit: number) => {
  if (lowerLimit === upperLimit) {
    return `You can borrow a maximum of ${AmountFormatter.format(upperLimit)}`;
  }
  return `The borrowing amount should be between ${AmountFormatter.format(lowerLimit)} and ${AmountFormatter.format(
    upperLimit
  )}`;
};

export function DebtConsolidationQuote({ taskId, completeTask, taskFriendlyURL }: CommonProps) {
  const userId = useAppSelector((state) => state.userId.value);
  const [borrowingLimit, setBorrowingLimit] = useState<{ lowerLimit: number; upperLimit: number }>({
    lowerLimit: 0,
    upperLimit: 0,
  });
  const [establishmentFee, setEstablishmentFee] = useState<number>();
  const [totalLoanAmount, setTotalLoanAmount] = useState<number>();

  const formSchema = z.object({
    amount: z.coerce
      .number()
      .gte(borrowingLimit.lowerLimit, {
        message: getValidationMessageForAmount(borrowingLimit.lowerLimit, borrowingLimit.upperLimit),
      })
      .lte(borrowingLimit.upperLimit, {
        message: getValidationMessageForAmount(borrowingLimit.lowerLimit, borrowingLimit.upperLimit),
      }),
    repaymentFrequency: z.nativeEnum(RepaymentFrequency, {
      invalid_type_error: 'Please select your repayment frequency',
    }),
    termInMonths: z.coerce
      .number({
        invalid_type_error: 'Please select a term option',
        required_error: 'Please select a term option',
      })
      .refine(
        (data) => {
          if ([36, 60, 84].includes(data)) return true;
          else return false;
        },
        {
          message: 'Please select a term option',
        }
      ),
  });
  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues: { termInMonths: 84, repaymentFrequency: RepaymentFrequency.MONTHLY },
  });
  const {
    watch,
    register,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = form;
  const amount = watch('amount');
  const repaymentFrequency = watch('repaymentFrequency');

  const { data: userData } = useGetUserQuery();
  const { data: variables } = useGetVariablesQuery(taskId);
  const { data: loanApplicationData } = useGetLoanApplicationQuery(variables?.loanApplicationId.toString(), {
    skip: !variables,
    refetchOnMountOrArgChange: true,
  });
  const { quoteData } = useQuoteData(taskId);

  const { data: quoteOptionsData } = useGetQuoteOptionsQuery(
    {
      loanApplicationId: variables?.loanApplicationId.toString(),
      loanAmount: amount,
      establishmentFee: establishmentFee,
    },
    {
      skip: !quoteData || !amount || !!errors?.amount || !establishmentFee,
    }
  );
  const [submitLoanApplication] = useSubmitLoanApplicationMutation();
  const formattedQuoteOptions = useFormatQuoteOptions(repaymentFrequency as RepaymentFrequency, quoteOptionsData);

  useEffect(() => {
    if (!quoteData) return;

    form.setValue('amount', +variables?.totalToConsolidate);
    form.setValue('termInMonths', +quoteData.termInMonths);
    setBorrowingLimit({
      lowerLimit: quoteData.minimumBorrowingLimit,
      upperLimit: quoteData.maximumBorrowingLimit,
    });
  }, [form, quoteData]);

  useEffect(() => {
    if (!quoteData) return;
    setEstablishmentFee(
      amount < quoteData?.establishmentFeeThreshold
        ? quoteData?.establishmentFeeUnderThreshold
        : quoteData?.establishmentFeeOverThreshold
    );
    setTotalLoanAmount(+amount + +establishmentFee);
  }, [amount, establishmentFee, quoteData]);

  const handleSubmit = async (data) => {
    await submitLoanApplication({
      id: variables.loanApplicationId.toString(),
      termInMonths: +data.termInMonths,
      fundedAmount: +data.amount,
      repaymentFrequency: data.repaymentFrequency,
      quoteExpiryAt: dayjs(loanApplicationData?.createdDate)
        .utc()
        .add(60, 'day')
        .set('hour', 23)
        .set('minutes', 59)
        .set('seconds', 59)
        .toDate(),
      applicationExpiryAt: dayjs(loanApplicationData?.createdDate)
        .utc()
        .add(60, 'day')
        .set('hour', 23)
        .set('minutes', 59)
        .set('seconds', 59)
        .toDate(),
      status: LoanApplicationStatusEnum.application_in_progress,
    });
    eventAnalytics.track(LOAN_APPLICATION_SUBMITTED, {
      userid_str: userId,
      taskid_str: taskId,
    });
    completeTask({ taskId });
  };

  useFriendlyURL(taskFriendlyURL);

  if (!quoteData || !loanApplicationData) return <Spinner />;

  return (
    <>
      <h1>
        Here&rsquo;s your <span className="text-primary">quote</span>, {userData?.preferredName}!
      </h1>
      <Form form={form} onSubmit={handleSubmit}>
        <Card>
          <p className="text-md mb-1 font-medium">
            Fixed interest rate {loanApplicationData?.finalInterestRate as unknown as number}%
          </p>
          <p className="text-md  font-medium">You are requesting to consolidate {formatCurrency(amount)}</p>
        </Card>
        <Card>
          <fieldset className="mb-4">
            <ToggleGroup
              {...register('repaymentFrequency')}
              options={frequencyOptionsWithoutDefault}
              className="font-medium"
              label="How would you like to repay your loan?"
            />
          </fieldset>

          <fieldset>
            <legend className="mb-2 font-medium">Over how many years?</legend>
            <p className="mb-2">You can pay your loan early without any extra charge.</p>
            {formattedQuoteOptions ? (
              <RadioGroup {...register('termInMonths')} options={formattedQuoteOptions} />
            ) : (
              <div className="flex">
                <Spinner size="small" />
                &nbsp;&nbsp;Generating quote options ...
              </div>
            )}
          </fieldset>
        </Card>

        {repaymentFrequency && (
          <>
            <ul className="mb-4">
              <li className="mb-2 flex justify-between">
                <span>
                  {loanApplicationData?.loanApplicationPurposes.length > 0 &&
                    loanApplicationData?.loanApplicationPurposes[0].loanPurpose.displayName}
                </span>
                <span>{AmountFormatter.format(amount)}</span>
              </li>
              <li className="mb-2 flex justify-between">
                <span>Establishment fee</span>
                <span>{AmountFormatter.format(establishmentFee)}</span>
              </li>
              <li className="border-grey-5 mb-2 flex justify-between border-y py-2 font-bold">
                <span>Loan total</span>
                <span>{AmountFormatter.format(totalLoanAmount)}</span>
              </li>
              <li className="flex justify-between font-bold">
                <span>Your fixed interest rate</span>
                <span>{loanApplicationData?.finalInterestRate as unknown as number}%</span>
              </li>
            </ul>

            <Alert variant="warning" className="mb-4" title="Heads up!">
              <p className="text-sm">
                We&apos;ll
                <strong className="font-bold"> make an inquiry on your credit report</strong> and you won&apos;t be able
                to change your quote past this point.
              </p>
            </Alert>

            <p className="mb-6">
              By submitting this application, you agree that a fixed interest, fixed repayment loan will meet your
              requirements and you understand that Harmoney can not guarantee a lower interest. Depending on rates and
              fees of your existing debt, you may incur additional costs by refinancing.
            </p>
          </>
        )}
        <div className="flex justify-center">
          <Button variant="primary" isLoading={isSubmitting || isSubmitSuccessful} type="submit" hasShadow>
            Submit application
          </Button>
        </div>
      </Form>
    </>
  );
}

import { useCallback, useState } from 'react';
import {
  ArrowCircleRightIcon,
  Button,
  CommonOptionProps,
  Dialog,
  Form,
  Label,
  Select,
  useForm,
} from '@harmoney/ui-design-system';
import dayjs from 'dayjs';
import { z } from 'zod';

import { CommonProps } from '../../../common-props';
import { PaymentPlanFormFooter } from '../PaymentPlan/PaymentPlanFormFooter';
import { PaymentPlanFormHeader } from '../PaymentPlan/PaymentPlanFormHeader';
import { ChangeSummaryTypeEnum } from '../PaymentPlan/util';

export interface UpdateCollectionStatusProps extends CommonProps {
  currentStatus: string;
  loanApplicationId: string;
  collectionStatusOptions: CommonOptionProps[];
}

export const UpdateCollectionStatus = ({
  taskId,
  currentStatus,
  completeTaskWithData,
  collectionStatusOptions,
}: UpdateCollectionStatusProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const form = useForm({
    mode: 'onTouched',
    schema: z.object({ status: z.string() }),
    defaultValues: { status: currentStatus || 'No status' },
  });

  const { register, getValues } = form;

  const onModalSubmit = useCallback(() => {
    setIsSubmitting(true);

    completeTaskWithData({
      taskId,
      variables: { collectionStatus: getValues('status') },
    });
  }, [taskId, completeTaskWithData, getValues]);

  const handleSubmit = () => setIsConfirmModalOpen(true);

  return (
    <>
      <Form form={form} onSubmit={handleSubmit}>
        <PaymentPlanFormHeader
          type={ChangeSummaryTypeEnum.UPDATE_COLLECTION_STATUS}
          headerTitle="Update collection status"
        />
        <div className="px-4">
          <Select
            {...register('status')}
            label="Collection status"
            options={collectionStatusOptions}
            className="mb-6"
          />
          <PaymentPlanFormFooter
            type={ChangeSummaryTypeEnum.UPDATE_COLLECTION_STATUS}
            buttonText="Update"
            isSubmitting={isSubmitting}
          />
        </div>
      </Form>

      <Dialog
        open={isConfirmModalOpen}
        onOpenChange={() => setIsConfirmModalOpen(!isConfirmModalOpen)}
        title="Update collection status"
        modal
      >
        <div className="p-4">
          <div className="text-left text-base font-medium">
            <Label className="mb-4">Are you sure you want to set the collection status below?</Label>
            <div className="flex justify-between mt-6 mb-6">
              <Label>{getValues('status')}</Label>
              <Label>
                Set on {dayjs().format('DD/MM/YYYY')} at {dayjs().format('hh:mmA')}
              </Label>
            </div>
          </div>

          <div className="shrink-1 flex w-full flex-col items-center justify-start gap-2 sm:flex-col md:flex-row-reverse flex-wrap">
            <Button
              onClick={onModalSubmit}
              type="submit"
              variant="primary"
              className="sm:!min-w-full md:!min-w-fit md:!max-w-fit"
              alignIcon="end"
              icon={<ArrowCircleRightIcon size="large" />}
              isLoading={isSubmitting}
              hasShadow
            >
              Confirm
            </Button>
            <Button
              onClick={() => setIsConfirmModalOpen(false)}
              variant="secondary"
              disabled={isSubmitting}
              className="!min-w-fit !max-w-fit"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

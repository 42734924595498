import { useState } from 'react';
import { LoanPurposeQuestionDto } from '@harmoney/api-interfaces';
import { Alert, ArrowCircleRightIcon, Button, Dialog, Form, RadioGroup, useForm } from '@harmoney/ui-design-system';
import { z } from 'zod';

import { SelectPersonalLoanPurposeForm, selectPersonalLoanPurposeSchema } from '../../SelectPersonalLoanPurposeForm';

interface BlockSingleHmyLoanDebtConModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onChangeLoanPurposeSubmit: (
    loanPurposeData: {
      id: string;
      answers: {
        questionId: string;
        optionId: string;
      }[];
    }[]
  ) => Promise<void>;
}

const BORROW_SOMETHING_ELSE_OPTION = 'borrow_something_else';
const ADD_MORE_DEBTS_OPTION = 'add_more_debts';

const debtConBlockAlternateOptions = [
  {
    label: 'Borrow for something else instead',
    value: BORROW_SOMETHING_ELSE_OPTION,
  },
  {
    label: 'Or add more debts to consolidate',
    value: ADD_MORE_DEBTS_OPTION,
  },
];

const selectBlockedDebtConOptionSchema = z.object({
  selectedBlockedDebtConOption: z.string().min(1, {
    message: 'Please select an option',
  }),
});

const SelectBlockedDebtConOptionForm = ({
  onSubmit,
}: {
  onSubmit: (data: z.infer<typeof selectBlockedDebtConOptionSchema>) => void;
}) => {
  const form = useForm({
    mode: 'onSubmit',
    schema: selectBlockedDebtConOptionSchema,
    defaultValues: {
      selectedBlockedDebtConOption: '',
    },
  });

  const { register } = form;

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Alert variant="warning">
        <p>You only have 1 Harmoney debt to consolidate.</p>
      </Alert>
      <p className="font-medium">You can either:</p>
      <RadioGroup {...register('selectedBlockedDebtConOption')} options={debtConBlockAlternateOptions} />
      <Button
        variant="primary"
        type="submit"
        alignIcon="end"
        icon={<ArrowCircleRightIcon size="large" />}
        className="my-4"
      >
        Continue
      </Button>
    </Form>
  );
};

export function BlockSingleHmyLoanDebtConModal({
  isOpen,
  closeModal,
  onChangeLoanPurposeSubmit,
}: BlockSingleHmyLoanDebtConModalProps) {
  const [selectedOption, setSelectedOption] = useState<string>(null);

  const handleSelectAlternateDebtConOption = (data: z.infer<typeof selectBlockedDebtConOptionSchema>) => {
    setSelectedOption(data.selectedBlockedDebtConOption);

    if (data.selectedBlockedDebtConOption === ADD_MORE_DEBTS_OPTION) {
      handleCloseModal();
    }
  };

  const handleSelectPersonalLoanPurposeSubmit = async (
    formData: z.infer<typeof selectPersonalLoanPurposeSchema>,
    questions: LoanPurposeQuestionDto[]
  ) => {
    const transformedLoanPurposeData = [
      {
        id: formData.loanPurpose,
        answers: formData.questions.map(({ optionId }, index) => ({
          questionId: questions[index].id,
          optionId: typeof optionId === 'boolean' ? null : optionId,
        })),
      },
    ];

    await onChangeLoanPurposeSubmit(transformedLoanPurposeData);
  };

  const handleBackToSelectBlockedDebtConOptionsForm = () => {
    resetSelectedOption();
  };

  const handleCloseModal = () => {
    resetSelectedOption();
    closeModal();
  };

  const resetSelectedOption = () => {
    setSelectedOption(null);
  };

  return (
    <Dialog
      title="You need to consolidate more than one debt for a debt consolidation loan."
      modal
      open={isOpen}
      onOpenChange={handleCloseModal}
    >
      <div className="p-4">
        {!selectedOption && <SelectBlockedDebtConOptionForm onSubmit={handleSelectAlternateDebtConOption} />}
        {selectedOption === BORROW_SOMETHING_ELSE_OPTION && (
          <SelectPersonalLoanPurposeForm
            onSubmit={handleSelectPersonalLoanPurposeSubmit}
            onCancel={handleBackToSelectBlockedDebtConOptionsForm}
          />
        )}
      </div>
    </Dialog>
  );
}

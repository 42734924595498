import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DebtFormState {
  data: string;
}

const initialState: DebtFormState = {
  data: '',
};

export const debtFormSlice = createSlice({
  name: 'debtForm',
  initialState,
  reducers: {
    setDebtFormData: (state, action: PayloadAction<string>) => {
      state.data = action.payload;
    },
  },
});

export const { setDebtFormData } = debtFormSlice.actions;

export default debtFormSlice.reducer;

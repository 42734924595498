import { useState } from 'react';
import { useFriendlyURL } from '@harmoney/hooks';
import { ArrowCircleRightIcon, Button } from '@harmoney/ui-design-system';

import { CommonProps } from '../../common-props';

export function HardIdentityFailure({ taskId, completeTaskWithData, taskFriendlyURL }: CommonProps) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = () => {
    setIsSubmitting(true);
    completeTaskWithData({ taskId });
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      <h1>
        We are <span className="text-primary">unable to give</span> you a loan today
      </h1>
      <div className="flex flex-col justify-center">
        <p>
          Thank you for taking the time to fill out your application. We were not able to verify your ID due to too many
          failed attempts and are unable to give you a loan today.
        </p>
        <Button
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          type="button"
          className="mt-6 mx-auto"
          hasShadow
          onClick={handleSubmit}
          isLoading={isSubmitting}
        >
          go to dashboard
        </Button>
      </div>
    </>
  );
}

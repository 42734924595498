import { useEffect, useMemo, useState } from 'react';
import { Frequency, RepaymentFrequency } from '@harmoney/api-interfaces';
import { useFormatQuoteOptions, useFriendlyURL, useQuoteData } from '@harmoney/hooks';
import {
  useAppSelector,
  useDeleteLoanPurposeMutation,
  useGetLoanApplicationQuery,
  useGetLoanPurposesQuery,
  useGetQuoteOptionsQuery,
  useGetUserQuery,
  useGetVariablesQuery,
  useLazyGetLoanApplicationQuery,
  useSubmitLoanApplicationMutation,
  useUpdateLoanPurposesMutation,
} from '@harmoney/redux';
import { eventAnalytics, LOAN_APPLICATION_SUBMITTED } from '@harmoney/ui-app-shell';
import { Button, Form, Spinner, useForm } from '@harmoney/ui-design-system';
import { LoanApplicationStatusEnum } from '@prisma/client';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { isUndefined } from 'lodash';

import { CommonProps } from '../../common-props';
import {
  calculateEstablishmentFee,
  formatLoanPurposes,
  getLoanPurposeName,
  LoanPeriodAlert,
  QuoteDisclaimer,
  QuoteIntroduction,
  QuoteSummary,
  RepaymentOptions,
} from '../shared';

import { ExtraLimitModal } from './ExtraLimit/ExtraLimitModal';
import { MultiLoanPurposes } from './components';
import { ExtraLimitOption } from './ExtraLimit';
import { createFormSchema, defaultValues, LoanPurposeFormType, validationMessages } from './form-config';

dayjs.extend(relativeTime);

export function ValidQuoteV2({ loanProductData, taskId, completeTask, taskFriendlyURL }: CommonProps) {
  useFriendlyURL(taskFriendlyURL);
  const userId = useAppSelector((state) => state.userId.value);
  const [borrowingLimit, setBorrowingLimit] = useState<{ lowerLimit: number; upperLimit: number }>({
    lowerLimit: 0,
    upperLimit: 0,
  });
  const [uiState, setUiState] = useState({
    isExtraLimitModalOpen: false,
    isPrimaryPurposeFormOpen: false,
    isSecondaryPurposeFormOpen: false,
    isExtraLimitFormOpen: false,
    isSubmitting: false,
    hasDeletedLoanPurpose: false,
    errorMsgForSecondaryPurposeAmount: '',
  });

  const formSchema = createFormSchema(borrowingLimit.lowerLimit, borrowingLimit.upperLimit);
  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues,
  });
  const {
    watch,
    register,
    unregister,
    setError,
    clearErrors,
    formState: { errors },
  } = form;
  const watchFields = watch();

  const { data: userData } = useGetUserQuery();
  const { data: variables } = useGetVariablesQuery(taskId);
  const { quoteData } = useQuoteData(taskId);
  const { data: loanPurposesData } = useGetLoanPurposesQuery(loanProductData?.id, {
    skip: !loanProductData,
  });
  const { data: loanApplicationData } = useGetLoanApplicationQuery(variables?.loanApplicationId.toString(), {
    skip: !variables,
    refetchOnMountOrArgChange: true,
  });
  const [refetchLoanApplicationData] = useLazyGetLoanApplicationQuery();

  const { totalAcceptedAmount, establishmentFee, totalLoanAmount } = useMemo(() => {
    const totalAccepted = watchFields.primaryPurposeAmount + (watchFields.secondaryPurposeAmount || 0);
    const fee = calculateEstablishmentFee(totalAccepted, quoteData);
    const totalLoan = totalAccepted + fee;
    return { totalAcceptedAmount: totalAccepted, establishmentFee: fee, totalLoanAmount: totalLoan };
  }, [watchFields.primaryPurposeAmount, watchFields.secondaryPurposeAmount, quoteData]);

  const loanApplicationPurposes = useMemo(() => {
    return loanApplicationData?.loanApplicationPurposes || [];
  }, [loanApplicationData?.loanApplicationPurposes]);

  const availableSecondaryPurposes = useMemo(() => {
    if (!loanPurposesData || !loanApplicationPurposes) return [];
    const excludeId = loanApplicationPurposes[0]?.loanPurposeId;
    return formatLoanPurposes(loanPurposesData, excludeId);
  }, [loanApplicationPurposes, loanPurposesData]);

  const maxExtraLimitForSecondaryPurpose = useMemo(() => {
    return loanApplicationData ? loanApplicationData.applicationBorrowingLimit - watchFields.primaryPurposeAmount : 0;
  }, [watchFields.primaryPurposeAmount, loanApplicationData]);

  const isEligibleForExtraLimit = useMemo(() => {
    return (
      loanApplicationData?.isEligibleForExtraLimit &&
      maxExtraLimitForSecondaryPurpose >= loanApplicationData.minExtraLimit
    );
  }, [loanApplicationData, maxExtraLimitForSecondaryPurpose]);

  const { showExtraLimitOption, showExtraLimitModal } = useMemo(() => {
    const hasDeletedLoanPurpose = loanApplicationData?.hasDeletedLoanPurpose;

    const showExtraLimitOption = isEligibleForExtraLimit && (hasDeletedLoanPurpose || uiState.hasDeletedLoanPurpose);
    const showExtraLimitModal =
      isEligibleForExtraLimit &&
      loanApplicationPurposes.length === 1 &&
      (!hasDeletedLoanPurpose || !uiState.hasDeletedLoanPurpose);

    return { showExtraLimitOption, showExtraLimitModal };
  }, [
    isEligibleForExtraLimit,
    loanApplicationData?.hasDeletedLoanPurpose,
    loanApplicationPurposes.length,
    uiState.hasDeletedLoanPurpose,
  ]);

  const { data: quoteOptionsData } = useGetQuoteOptionsQuery(
    {
      loanApplicationId: variables?.loanApplicationId.toString(),
      loanAmount: totalAcceptedAmount,
      establishmentFee,
    },
    {
      skip: !variables || !totalAcceptedAmount || !establishmentFee,
      refetchOnMountOrArgChange: true,
    }
  );

  const formattedQuoteOptions = useFormatQuoteOptions(
    watchFields.repaymentFrequency as RepaymentFrequency,
    quoteOptionsData
  );

  const isSameLoanLimitAcrossOptions = useMemo(() => {
    return (
      quoteOptionsData?.every(({ loanLimitFromUmi }) => loanLimitFromUmi === quoteOptionsData[0].loanLimitFromUmi) ||
      false
    );
  }, [quoteOptionsData]);

  const [updateLoanPurposes] = useUpdateLoanPurposesMutation();
  const [deleteLoanPurpose] = useDeleteLoanPurposeMutation();
  const [submitLoanApplication] = useSubmitLoanApplicationMutation();

  useEffect(() => {
    if (!quoteData || !loanApplicationData) return;

    setBorrowingLimit({
      lowerLimit: quoteData.minimumBorrowingLimit,
      upperLimit: loanApplicationData.quotePresentedAmount,
    });

    if (loanApplicationPurposes[0].fundedAmount) {
      if (!isUndefined(watchFields.primaryPurposeAmount)) {
        form.setValue('primaryPurposeAmount', watchFields.primaryPurposeAmount);
      } else {
        form.setValue(
          'primaryPurposeAmount',
          Math.min(+loanApplicationData.quotePresentedAmount, +loanApplicationPurposes[0].fundedAmount)
        );
      }
    } else {
      form.setValue(
        'primaryPurposeAmount',
        Math.min(+loanApplicationData.quotePresentedAmount, +loanApplicationData.requestedAmount)
      );
    }

    if (loanApplicationData?.loanApplicationPurposes.length === 1 && loanApplicationData.hasDeletedLoanPurpose) {
      setUiState((prevState) => ({
        ...prevState,
        isPrimaryPurposeFormOpen: true,
        hasDeletedLoanPurpose: true,
      }));
    }

    if (loanApplicationData.loanApplicationPurposes.length > 1 || loanApplicationData.hasDeletedLoanPurpose) {
      form.setValue('hasAddedAdditionalPurpose', true);
    }

    form.setValue('termInMonths', +quoteData.termInMonths);

    const declaredSecondaryPurpose = loanApplicationPurposes.length > 1 && loanApplicationPurposes[1];
    if (declaredSecondaryPurpose) {
      form.setValue('secondaryPurposeId', declaredSecondaryPurpose.loanPurposeId);
      form.setValue('secondaryPurposeAmount', declaredSecondaryPurpose.requestedAmount);
    }
  }, [loanApplicationPurposes, form, loanApplicationData, quoteData]);

  useEffect(() => {
    if (loanApplicationPurposes.length === 1) return;

    const { lowerLimit, upperLimit } = borrowingLimit;

    const getErrorMessage = () => {
      const totalAmount = watchFields.primaryPurposeAmount + watchFields.secondaryPurposeAmount;
      if (totalAmount < lowerLimit) {
        return validationMessages.lowerLimitError(lowerLimit);
      }
      if (totalAmount > upperLimit) {
        return validationMessages.upperLimitError(upperLimit);
      }
      if (!watchFields.secondaryPurposeAmount) {
        return validationMessages.secondaryPurposeAmountError;
      }
      return null;
    };

    const errorMessage = getErrorMessage();

    if (watchFields.secondaryPurposeAmount !== undefined && errorMessage) {
      setError('secondaryPurposeAmount', { type: 'custom', message: errorMessage });
      setUiState((prevState) => ({
        ...prevState,
        errorMsgForSecondaryPurposeAmount: errorMessage,
      }));
    } else {
      clearErrors('secondaryPurposeAmount');
      setUiState((prevState) => ({
        ...prevState,
        errorMsgForSecondaryPurposeAmount: '',
      }));
    }
  }, [
    borrowingLimit,
    loanApplicationPurposes,
    watchFields.primaryPurposeAmount,
    watchFields.secondaryPurposeAmount,
    setError,
    clearErrors,
  ]);

  const handleBorrowMoreClick = () => {
    setUiState((prevState) => ({
      ...prevState,
      isExtraLimitModalOpen: true,
      isExtraLimitFormOpen: true,
    }));
  };

  const handleEditClick = (formType: LoanPurposeFormType) => {
    if (formType === 'primary') {
      if (uiState.errorMsgForSecondaryPurposeAmount) return;
      setUiState((prevState) => ({
        ...prevState,
        isPrimaryPurposeFormOpen: true,
        isSecondaryPurposeFormOpen: false,
      }));
    }
    if (formType === 'secondary') {
      if (errors?.primaryPurposeAmount?.message) return;
      setUiState((prevState) => ({
        ...prevState,
        isPrimaryPurposeFormOpen: false,
        isSecondaryPurposeFormOpen: true,
      }));
    }
  };

  const handleDeleteClick = async (loanPurposeId: string) => {
    const loanApplicationPurposeId = loanApplicationPurposes.find(
      (purpose) => purpose.loanPurposeId === loanPurposeId
    )?.id;
    await deleteLoanPurpose({ applicationId: loanApplicationData?.id, loanApplicationPurposeId });
    unregister(['secondaryPurposeId', 'secondaryPurposeAmount']);
    setUiState((prevState) => ({
      ...prevState,
      isPrimaryPurposeFormOpen: true,
      isSecondaryPurposeFormOpen: false,
      hasDeletedLoanPurpose: true,
      errorMsgForSecondaryPurposeAmount: '',
    }));
    form.trigger('primaryPurposeAmount');
    form.setValue('primaryPurposeAmount', watchFields.primaryPurposeAmount);
  };

  const handleExtraLimitFormSubmit = async (data) => {
    const loanPurposeData = [
      {
        id: loanApplicationPurposes[0].loanPurposeId,
        requestedAmount: loanApplicationPurposes[0].requestedAmount,
        fundedAmount: watchFields.primaryPurposeAmount,
        deletedAt: null,
        deletedBy: null,
      },
      {
        id: data.secondaryPurposeId,
        requestedAmount: data.secondaryPurposeAmount,
        fundedAmount: data.secondaryPurposeAmount,
        deletedAt: null,
        deletedBy: null,
      },
    ];

    await updateLoanPurposes({
      applicationId: variables.loanApplicationId.toString(),
      loanPurposes: loanPurposeData,
    });

    await refetchLoanApplicationData(variables.loanApplicationId.toString());

    setUiState((prevState) => ({
      ...prevState,
      isExtraLimitModalOpen: false,
      isPrimaryPurposeFormOpen: false,
      hasDeletedLoanPurpose: false,
    }));
  };

  const submitForm = async () => {
    await submitLoanApplication({
      id: variables.loanApplicationId.toString(),
      termInMonths: +form.getValues('termInMonths'),
      fundedAmount: +totalAcceptedAmount,
      repaymentFrequency: form.getValues('repaymentFrequency') as unknown as Frequency,
      status: LoanApplicationStatusEnum.application_in_progress,
    });

    const primaryLoanPurpose = {
      id: loanApplicationPurposes[0].loanPurposeId,
      requestedAmount: +loanApplicationPurposes[0].requestedAmount,
      fundedAmount: +watchFields.primaryPurposeAmount,
    };

    const secondaryLoanPurpose = {
      id: watchFields.secondaryPurposeId,
      requestedAmount: +watchFields.secondaryPurposeAmount,
      fundedAmount: +watchFields.secondaryPurposeAmount,
    };

    await updateLoanPurposes({
      applicationId: variables.loanApplicationId.toString(),
      loanPurposes: watchFields.secondaryPurposeId ? [primaryLoanPurpose, secondaryLoanPurpose] : [primaryLoanPurpose],
    });

    eventAnalytics.track(LOAN_APPLICATION_SUBMITTED, {
      userid_str: userId,
      taskid_str: taskId,
    });

    completeTask({ taskId });
  };

  const handleSubmit = async () => {
    if (showExtraLimitModal) {
      setUiState((prevState) => ({
        ...prevState,
        isExtraLimitModalOpen: true,
        isExtraLimitFormOpen: false,
      }));
      return;
    }

    setUiState((prevState) => ({
      ...prevState,
      isSubmitting: true,
    }));

    await submitForm();
  };

  const handleSkipClick = async () => {
    setUiState((prevState) => ({
      ...prevState,
      isExtraLimitModalOpen: false,
      isSubmitting: true,
    }));

    await submitForm();
  };

  if (!quoteData) return <Spinner />;

  return (
    <>
      <QuoteIntroduction
        preferredName={userData?.preferredName}
        interestRate={loanApplicationData?.finalInterestRate as unknown as number}
      />

      <Form form={form} onSubmit={handleSubmit}>
        {loanApplicationPurposes.length > 0 && (
          <MultiLoanPurposes
            {...{
              loanApplicationData,
              loanPurposesData,
              availableSecondaryPurposes,
              loanApplicationPurposes,
              uiState,
              primaryPurposeAmount: watchFields.primaryPurposeAmount,
              secondaryPurposeId: watchFields.secondaryPurposeId,
              secondaryPurposeAmount: watchFields.secondaryPurposeAmount,
              maxExtraLimitForSecondaryPurpose,
              register,
              onEditClick: handleEditClick,
              onDeleteClick: handleDeleteClick,
            }}
          />
        )}

        {!isSameLoanLimitAcrossOptions && <LoanPeriodAlert className="mb-6" />}

        {showExtraLimitOption && !errors?.primaryPurposeAmount?.message && (
          <ExtraLimitOption
            {...{ loanApplicationData, maxExtraLimitForSecondaryPurpose, onBorrowMoreClick: handleBorrowMoreClick }}
          />
        )}

        <RepaymentOptions formattedQuoteOptions={formattedQuoteOptions} register={register} />

        {watchFields.repaymentFrequency && (
          <QuoteSummary
            primaryPurpose={loanApplicationData?.loanApplicationPurposes[0]?.loanPurpose?.displayName}
            primaryPurposeAmount={watchFields.primaryPurposeAmount}
            secondaryPurpose={getLoanPurposeName(loanPurposesData, watchFields.secondaryPurposeId)?.displayName}
            secondaryPurposeAmount={watchFields.secondaryPurposeAmount}
            establishmentFee={establishmentFee}
            totalLoanAmount={totalLoanAmount}
            interestRate={loanApplicationData?.finalInterestRate as unknown as number}
          />
        )}

        <QuoteDisclaimer />

        <div className="flex justify-center">
          <Button variant="primary" isLoading={uiState.isSubmitting} type="submit" hasShadow>
            Submit application
          </Button>
        </div>
      </Form>

      {uiState.isExtraLimitModalOpen && (
        <ExtraLimitModal
          availableSecondaryPurposes={availableSecondaryPurposes}
          maxExtraLimitForSecondaryPurpose={maxExtraLimitForSecondaryPurpose}
          interestRate={loanApplicationData.finalInterestRate as unknown as number}
          isExtraLimitModalOpen={uiState.isExtraLimitModalOpen}
          isExtraLimitFormOpen={uiState.isExtraLimitFormOpen}
          onExtraLimitFormSubmit={handleExtraLimitFormSubmit}
          onBorrowMoreClick={handleBorrowMoreClick}
          onSkipClick={handleSkipClick}
          onExtraLimitModalOpenChange={() =>
            setUiState((prevState) => ({ ...prevState, isExtraLimitModalOpen: !uiState.isExtraLimitModalOpen }))
          }
        />
      )}
    </>
  );
}

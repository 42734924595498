import { LocalQuoteDetails } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const emailApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    sendVerificationEmail: builder.query<void, void>({
      query: () => ({
        url: '/emails/trigger-verification',
        method: 'GET',
      }),
    }),
    checkEmailVerificationStatus: builder.query<
      { verificationFlag: boolean },
      { userId: string; idpIdentifier: string }
    >({
      query: ({ userId, idpIdentifier }) => ({
        url: `/emails/check-verification-status/${userId}/${idpIdentifier}`,
        method: 'GET',
      }),
    }),
    sendQuoteEmail: builder.mutation<void, LocalQuoteDetails>({
      query: (quoteDetails) => ({
        url: '/emails/quote-details',
        method: 'POST',
        body: quoteDetails,
      }),
    }),
  }),
});

export const { useSendVerificationEmailQuery, useCheckEmailVerificationStatusQuery, useSendQuoteEmailMutation } =
  emailApi;

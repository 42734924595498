import { useAppDispatch } from '@harmoney/redux';
import { Icon } from '@iconify/react';

import { setIsLoanVariationApplied, setShowVariationHistory } from '../../../../redux/slice/admin';

interface VariationFormHeaderProps {
  form?: any;
  historyView?: boolean;
}

export const VariationFormHeader = ({ form, historyView = false }: VariationFormHeaderProps) => {
  const dispatch = useAppDispatch();

  const handleCancel = () => {
    if (!historyView) {
      form.reset();
      dispatch(setIsLoanVariationApplied(false));
    } else dispatch(setShowVariationHistory(false));
  };

  return (
    <div className="sticky top-0 z-9 rounded-b-sm bg-white px-4 pb-2 pt-6">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-baseline justify-end gap-4">
          <h2>Loan Variation</h2>
        </div>
        <Icon icon="ic:round-close" onClick={handleCancel} className="cursor-pointer" width={24} />
      </div>
    </div>
  );
};

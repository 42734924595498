import { Player } from '@lottiefiles/react-lottie-player';

export const LottieConfetti = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div {...props}>
      <Player
        autoplay
        loop
        controls={false}
        src="/assets/lotties/confetti.json"
        style={{ height: '200px', width: '200px' }}
      />
    </div>
  );
};

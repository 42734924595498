import { useDebtConBenefits } from '@harmoney/hooks';
import { useGetDebtConsolidationQuery } from '@harmoney/redux';

import { DebtConBenefitsRender } from '../../../../components/debt-consolidation/ConsolidateDebt/components';

type DebtConBenefitsProps = {
  interestRate: number;
  maximumBorrowingLimit: number;
  financialProfileId: string;
  hmyRepaymentAmount: number;
};

export const DebtConBenefits = ({
  interestRate,
  maximumBorrowingLimit,
  financialProfileId,
  hmyRepaymentAmount,
}: DebtConBenefitsProps) => {
  const { data } = useGetDebtConsolidationQuery({
    financialProfileId: financialProfileId,
    interestRate: +interestRate,
    maximumBorrowingLimit: maximumBorrowingLimit,
    hmyRepaymentAmount: hmyRepaymentAmount,
  });

  const benefits = useDebtConBenefits(data);

  return <DebtConBenefitsRender benefits={benefits} />;
};

import Link from 'next/link';
import { useRouter } from 'next/router';
import { useAppSelector } from '@harmoney/redux';
import { Button, CopyButton, Spinner } from '@harmoney/ui-design-system';
import {
  AssetAndLiability,
  BPMNSimulation,
  BPMNSimulationStatusEnum,
  FinancialProfile,
  IncomeAndExpense,
  NetworthSource,
  Prisma,
} from '@prisma/client';

import { AffordabilityDetailsForSimulation } from './AffordabilityDetailsForSimulation';
import { AffordabilityDetailsForSimulationV2 } from './AffordabilityDetailsForSimulationV2';

export const SimulationDetails = ({
  simulationDetails,
  financialProfileData,
  isRetrying,
  onRetry,
}: {
  simulationDetails: BPMNSimulation & { existingVariables: Prisma.JsonValue };
  financialProfileData: FinancialProfile & {
    asset_and_liability: (AssetAndLiability & {
      networthSource: NetworthSource;
    })[];
    income_and_expense: (IncomeAndExpense & {
      networthSource: NetworthSource;
    })[];
  };
  isRetrying: boolean;
  onRetry: () => void;
}) => {
  const statusColorMap = {
    [BPMNSimulationStatusEnum.new]: 'text-grey-5',
    [BPMNSimulationStatusEnum.in_progress]: 'text-secondary',
    [BPMNSimulationStatusEnum.success]: 'text-success',
    [BPMNSimulationStatusEnum.error]: 'text-error',
  };

  const userId = useAppSelector((state) => state.userId.value);
  const router = useRouter();
  const {
    query: { applicationId },
  } = router;

  return (
    <>
      <div className="flex justify-between">
        <h1 className="text-primary flex justify-start">Simulation details</h1>
        <Link
          className="mr-2 mt-2"
          href={`/admin/details?userId=${userId}#applications/${applicationId}`}
          passHref
          target="_blank"
        >
          Link to Original Application
        </Link>
      </div>
      {simulationDetails?.variables && financialProfileData && (
        <AffordabilityDetailsForSimulation
          rootData={simulationDetails?.variables}
          financialProfileData={financialProfileData}
        />
      )}

      {simulationDetails?.variables && financialProfileData && (
        <AffordabilityDetailsForSimulationV2
          applicationId={simulationDetails?.variables?.['applicationId']}
          userId={simulationDetails?.variables?.['userId']}
          rootData={simulationDetails?.variables}
          financialProfileData={financialProfileData}
        />
      )}

      <h2 className="flex items-center">
        <span>Simulation status</span>:{' '}
        <span className="flex items-center ml-2">
          <span className={statusColorMap[simulationDetails.status]}>{simulationDetails.status} </span>
          <span className="ml-4">
            {' '}
            {simulationDetails.status === BPMNSimulationStatusEnum.in_progress && <Spinner size="small" />}
          </span>
        </span>
      </h2>
      {simulationDetails.notes && <p>Simulation notes: {simulationDetails.notes}</p>}
      <h3 className="text-grey-5 font-medium">Comparison of data between simulated and existing BPMN</h3>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <div className="flex justify-between">
            <h4>Simulated</h4>
            <Button
              onClick={onRetry}
              variant="outline-secondary"
              size="small"
              className="min-w-fit"
              isLoading={isRetrying}
            >
              Retry
            </Button>
          </div>

          <div className="bg-black p-4 rounded relative mt-4">
            <CopyButton
              valueToCopy={JSON.stringify(simulationDetails.variables, null, 2)}
              className="absolute right-2 text-white"
            />
            <code className="text-white whitespace-pre-wrap font-mono">
              {JSON.stringify(simulationDetails.variables, null, 2)}
            </code>
          </div>
        </div>

        <div>
          <h4>Existing</h4>
          <div className="bg-black p-4 rounded relative mt-8">
            <CopyButton
              valueToCopy={JSON.stringify(simulationDetails.existingVariables, null, 2)}
              className="absolute right-2 text-white"
            />
            <code className="text-white whitespace-pre-wrap font-mono">
              {JSON.stringify(simulationDetails.existingVariables, null, 2)}
            </code>
          </div>
        </div>
      </div>
    </>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * Frankie Financial API
 * ------   This API allows developers to integrate the Frankie Financial Compliance Utility into their applications. The API allows:   - Checking name, address, date of birth against national databases   - Validating Australian driver's licences, passports, medicare, visas and other Australian national ID documents   - Validating Australian electricity bills   - Validating NZ driver's licences   - Validating Chinese bank cards and national ID card   - Validating International passports and national ID documents   - PEP, Sanctions, Watchlist and adverse media checking   - Australian visa checks    - Fraud list and fraud background checks   - ID validation and selfie check comparisons.    ------     Industry specific services    - Comparing Australian electricity retailers for a better deal.  ------     KYB specific services    - Query organisation ownership   - Perform KYC & AML checks on shareholders, beneficial owners and office bearers.   - Query credit score and credit reports   - International company searches   - International company profiles    ------   The full version of this documentation along with supplemental articles can be found here:   - https://apidocs.frankiefinancial.com/  ------ Sandbox base URL is:   - https://api.demo.frankiefinancial.io/compliance/v1.2      - All calls are the same as production, only with test data. You can download the latest test data here: https://apidocs.frankiefinancial.com/docs/test-data    - Full Swagger definition, along with test data for playing in the sandbox can be obtained once initial commercial discussions have commenced.    - Production and optional UAT access will be opened up only to those with a signed commercial contract.    ------   Contact us at hello@frankiefinancial.com to speak with a sales rep about issuing a Customer ID and Sandbox api key.
 *
 * OpenAPI spec version: 1.14.0
 * Contact: dev-support@frankiefinancial.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @interface ComparisonError
 */
export interface ComparisonError {
  /**
   * Timestamp of when the attempted comparison took place
   * @type {string}
   * @memberof ComparisonError
   */
  comparisonDate: string;
  /**
   * The correlationId as passed in the request
   * @type {string}
   * @memberof ComparisonError
   */
  correlationId: string;
  /**
   * * `1000` - Document not recognised (i.e. not valid pdf or image) * `1001` - Bill is not SME or Domestic * `1004` - Bill is gas (if to be excluded) * `1005` - Template Not Found – The document was a pdf but service did not recognise the uploaded document against any of it’s templates * `1008` - Unsupported distributor – bill is from jurisdiction that is unsupported * `1009` - Unsupported distributor – bill is from jurisdiction that is unsupported * `1030` - Invoice from date is missing * `1031` - Invoice to date is missing * `1033` - Supply address is missing * `1039` - NMI is missing * `1041` - Bill is a predictive plan making comparison hard * `1044` - Bill is on embedded network * `1045` - Incompatible charge item – manual comparison needed * `1062` - C&I Bill loaded * `1080` - API failed to reconcile bill usually meaning that not all cost items were picked up
   * @type {number}
   * @memberof ComparisonError
   */
  errorCode: ComparisonErrorErrorCodeEnum;
  /**
   * Description of error that can be displayed as user feedback. e.g. \"Uploaded document not a PDF\"
   * @type {string}
   * @memberof ComparisonError
   */
  message: string;
  /**
   * Version of the API on which the error took place. This value should be reported with any issue raised.
   * @type {string}
   * @memberof ComparisonError
   */
  version: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ComparisonErrorErrorCodeEnum {
  NUMBER_1000 = 1000,
  NUMBER_1001 = 1001,
  NUMBER_1004 = 1004,
  NUMBER_1005 = 1005,
  NUMBER_1008 = 1008,
  NUMBER_1009 = 1009,
  NUMBER_1030 = 1030,
  NUMBER_1031 = 1031,
  NUMBER_1033 = 1033,
  NUMBER_1039 = 1039,
  NUMBER_1041 = 1041,
  NUMBER_1044 = 1044,
  NUMBER_1045 = 1045,
  NUMBER_1062 = 1062,
  NUMBER_1080 = 1080,
}

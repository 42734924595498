import React, { useMemo } from 'react';
import { UiQuoteStatus } from '@harmoney/api-interfaces';
import { UserInstanceQueryResponse } from '@harmoney/redux';
import { FlagIcon } from '@harmoney/ui-design-system';

import { initialProgress, ProgressRender, trackProgress, UiQuoteStatusWithProgress } from '../ProgressRender';

type Props = {
  data: UserInstanceQueryResponse;
};

export const ApplicationProgress = ({ data }: Props) => {
  const uiStatusMap: UiQuoteStatus[] = useMemo(() => {
    return data?.uiApplicationStatusMap as UiQuoteStatus[];
  }, [data]);

  const progress: UiQuoteStatusWithProgress[] = useMemo(() => {
    if (!data || !uiStatusMap) return null;

    if (!data?.completedTasks) return initialProgress(uiStatusMap);

    return uiStatusMap
      .slice()
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map((uiStatus, index) => {
        const trackedProgress = trackProgress(data?.completedTasks, data?.incompleteTasks, uiStatus);
        // for last task use FlagIcon instead
        if (index === uiStatusMap.length - 1) {
          return { ...trackedProgress, icon: <FlagIcon variant="grey-2" size="tiny" /> };
        }
        return trackedProgress;
      });
  }, [data, uiStatusMap]);

  return <ProgressRender progress={progress} taskTotal={uiStatusMap?.length} />;
};

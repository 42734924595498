import { FinancialProfileDto, IncomeAndExpensesOverrideDto } from '@harmoney/api-interfaces';
import { AssetAndLiability, FinancialProfile, IncomeAndExpense, NetworthSource } from '@prisma/client';

import { emptySplitApi } from './empty-api';

type FinancialProfileQueryResponse = FinancialProfile & {
  asset_and_liability: (AssetAndLiability & {
    networthSource: NetworthSource;
  })[];
  income_and_expense: (IncomeAndExpense & {
    networthSource: NetworthSource;
    isAccommodationExpense: boolean;
  })[];
};

const financialSummaryApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getFinancialSummary: builder.query<FinancialProfileDto, { id: string; loanApplicationId: string }>({
      query: ({ id, loanApplicationId }) => ({
        url: `/financial-profile/${id}`,
        method: 'GET',
        params: {
          loanApplicationId,
        },
      }),
    }),
    getFinancialProfileByUserId: builder.query<FinancialProfileQueryResponse, string>({
      query: (userId) => ({
        url: '/financial-profile',
        method: 'GET',
        params: {
          userId,
        },
      }),
    }),
    getFinancialProfileById: builder.query<FinancialProfileQueryResponse, string>({
      query: (id) => ({
        url: `/financial-profile/${id}/admin`,
        method: 'GET',
      }),
      providesTags: ['FinancialProfile'],
    }),
    overrideIncomeAndExpense: builder.mutation<void, IncomeAndExpensesOverrideDto>({
      query: (data) => ({
        url: `/financial-profile/income-and-expense/override`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['FinancialProfile'],
    }),
  }),
});

export const {
  useGetFinancialSummaryQuery,
  useGetFinancialProfileByUserIdQuery,
  useGetFinancialProfileByIdQuery,
  useOverrideIncomeAndExpenseMutation,
} = financialSummaryApi;

import { QuoteOption } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const quoteApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getQuoteOptions: builder.query<
      QuoteOption[],
      { loanApplicationId: string; loanAmount: number; establishmentFee: number }
    >({
      query: ({ loanApplicationId, loanAmount, establishmentFee }) => ({
        url: `/loan-applications/${loanApplicationId}/quote/options/calculation`,
        method: 'GET',
        params: {
          loanAmount,
          establishmentFee,
        },
      }),
    }),
    getQuoteOptionsForUnutilisedLimit: builder.query<
      QuoteOption[],
      { loanApplicationId: string; loanAmount: number; establishmentFee: number }
    >({
      query: ({ loanApplicationId, loanAmount, establishmentFee }) => ({
        url: `/unutilised-limit/loan-applications/${loanApplicationId}/quote/options/calculation`,
        method: 'GET',
        params: {
          loanAmount,
          establishmentFee,
        },
      }),
    }),
  }),
});

export const { useGetQuoteOptionsQuery, useLazyGetQuoteOptionsQuery, useGetQuoteOptionsForUnutilisedLimitQuery } =
  quoteApi;

import { HardPullCreditPolicyData, SoftPullCreditPolicyData } from '@harmoney/api-interfaces';
import { CollapsibleCard, TextLink } from '@harmoney/ui-design-system';

interface Props {
  softPullCreditPolicyData: SoftPullCreditPolicyData | null;
  softPullInstanceId: string;
  hardPullCreditPolicyData: HardPullCreditPolicyData | null;
  hardPullInstanceId: string;
}

export const CreditPolicyDetails = ({
  softPullCreditPolicyData,
  softPullInstanceId,
  hardPullCreditPolicyData,
  hardPullInstanceId,
}: Props) => {
  const operateURL = process.env.NEXT_PUBLIC_CAMUNDA_OPERATE_ENDPOINT;

  function getCreditPolicyReason(creditMetrics, metricCode, bureau) {
    if (creditMetrics?.missingMetrics?.includes(metricCode)) {
      return `${bureau} not exist`;
    } else if (creditMetrics?.[metricCode] === null) {
      return `${bureau} passed`;
    } else if (creditMetrics?.[metricCode] === undefined) {
      return `${bureau} skipped check`;
    } else {
      return `${bureau} = ${creditMetrics?.[metricCode].toString()}`;
    }
  }

  return (
    <CollapsibleCard title="Credit File Rules" className="mb-6">
      <div className="grid grid-cols-2 px-4">
        <div className="odd:border-r-grey-1 p-4 odd:border-r-2">
          <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
            <strong className="text-primary font-bold">Soft pull</strong>
          </div>
          {softPullCreditPolicyData && (
            <>
              <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
                <div className="grid grid-cols-2">
                  <span>Credit Score:</span>
                  <div>
                    <div
                      className={
                        softPullCreditPolicyData?.creditScoreRulesResult?.toString() === 'Passed'
                          ? 'text-success'
                          : 'text-error'
                      }
                    >
                      {softPullCreditPolicyData?.creditScoreRulesResult?.toString() === 'Passed'
                        ? '✅ Passed'
                        : '❌ Failed'}
                    </div>
                    <div className="mt-1">
                      {getCreditPolicyReason(softPullCreditPolicyData.creditMetrics, 'AUCF01.SCC201', 'Equifax')}
                    </div>
                    <div className="text-grey-4 pt-2 text-xs">
                      {softPullCreditPolicyData?.creditScoreRulesDecision?.declineCheckResult?.threshold?.toString()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
                <div className="grid grid-cols-2">
                  <span>Credit File Notes:</span>
                  <div>
                    <div
                      className={
                        softPullCreditPolicyData?.creditFileNotesRulesResult?.toString() === 'Passed'
                          ? 'text-success'
                          : 'text-error'
                      }
                    >
                      {softPullCreditPolicyData?.creditFileNotesRulesResult?.toString() === 'Passed'
                        ? '✅ Passed'
                        : '❌ Failed'}
                    </div>
                    <div className="mt-1">
                      <div>
                        {getCreditPolicyReason(softPullCreditPolicyData.creditMetrics, 'AUCF01.FNM001', 'Equifax')}
                      </div>
                      <div>
                        {getCreditPolicyReason(softPullCreditPolicyData.creditMetrics, 'AUCF02.FNM001', 'Illion')}
                      </div>
                    </div>
                    <div className="text-grey-4 pt-2 text-xs">
                      {softPullCreditPolicyData?.creditFileNotesRulesDecision?.declineCheckResult?.threshold?.toString()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
                <div className="grid grid-cols-2">
                  <span>Insolvency (Equifax):</span>
                  <div>
                    <div
                      className={
                        softPullCreditPolicyData?.insolvencyRulesResult?.toString() === 'Passed'
                          ? 'text-success'
                          : 'text-error'
                      }
                    >
                      {softPullCreditPolicyData?.insolvencyRulesResult?.toString() === 'Passed'
                        ? '✅ Passed'
                        : '❌ Failed'}
                    </div>
                    <div className="mt-1">
                      {getCreditPolicyReason(softPullCreditPolicyData.creditMetrics, 'AUCF01.PIM001', 'Equifax')}
                    </div>
                    <div className="text-grey-4 pt-2 text-xs">
                      {softPullCreditPolicyData?.insolvencyRulesDecision?.declineCheckResult?.threshold?.toString()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
                <div className="grid grid-cols-2">
                  <span>Hardship:</span>
                  <div>
                    <div
                      className={
                        softPullCreditPolicyData?.hardshipRulesResult?.toString() === 'Passed'
                          ? 'text-success'
                          : 'text-error'
                      }
                    >
                      {softPullCreditPolicyData?.hardshipRulesResult?.toString() === 'Passed'
                        ? '✅ Passed'
                        : '❌ Failed'}
                    </div>
                    <div className="mt-1">
                      <div>
                        {getCreditPolicyReason(softPullCreditPolicyData.creditMetrics, 'AUCF01.PHM002', 'Equifax')}
                      </div>
                      <div>
                        {getCreditPolicyReason(softPullCreditPolicyData.creditMetrics, 'AUCF02.PHM002', 'Illion')}
                      </div>
                    </div>
                    <div className="text-grey-4 pt-2 text-xs">
                      {softPullCreditPolicyData?.hardshipRulesDecision?.declineCheckResult?.threshold?.toString()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
                <div className="grid grid-cols-2">
                  <span>Judgments:</span>
                  <div>
                    <div
                      className={
                        softPullCreditPolicyData?.judgmentsRulesResult?.toString() === 'Passed'
                          ? 'text-success'
                          : 'text-error'
                      }
                    >
                      {softPullCreditPolicyData?.judgmentsRulesResult?.toString() === 'Passed'
                        ? '✅ Passed'
                        : '❌ Failed'}
                    </div>
                    <div className="mt-1">
                      <div>
                        {getCreditPolicyReason(softPullCreditPolicyData.creditMetrics, 'AUCF01.JMM001', 'Equifax')}
                      </div>
                      <div>
                        {getCreditPolicyReason(softPullCreditPolicyData.creditMetrics, 'AUCF02.JMM001', 'Illion')}
                      </div>
                    </div>
                    <div className="text-grey-4 pt-2 text-xs">
                      {softPullCreditPolicyData?.judgmentsRulesDecision?.declineCheckResult?.threshold?.toString()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
                <div className="grid grid-cols-2">
                  <span>Bankruptcy (Illion):</span>
                  <div>
                    <div
                      className={
                        softPullCreditPolicyData?.bankruptcyRulesResult?.toString() === 'Passed'
                          ? 'text-success'
                          : 'text-error'
                      }
                    >
                      {softPullCreditPolicyData?.bankruptcyRulesResult?.toString() === 'Passed'
                        ? '✅ Passed'
                        : '❌ Failed'}
                    </div>
                    <div className="mt-1">
                      {getCreditPolicyReason(softPullCreditPolicyData.creditMetrics, 'AUCF02.PIBM01', 'Illion')}
                    </div>
                    <div className="text-grey-4 pt-2 text-xs">
                      {softPullCreditPolicyData?.bankruptcyRulesDecision?.declineCheckResult?.threshold?.toString()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
                <div className="grid grid-cols-2">
                  <span>Defaults:</span>
                  <div>
                    <div
                      className={
                        softPullCreditPolicyData?.defaultRulesResult?.toString() === 'Passed'
                          ? 'text-success'
                          : 'text-error'
                      }
                    >
                      {softPullCreditPolicyData?.defaultRulesResult?.toString() === 'Passed'
                        ? '✅ Passed'
                        : '❌ Failed'}
                    </div>
                    <div className="mt-1">
                      <div>
                        {getCreditPolicyReason(softPullCreditPolicyData.creditMetrics, 'AUCF01.DFM001', 'Equifax')}
                      </div>
                      <div>
                        {getCreditPolicyReason(softPullCreditPolicyData.creditMetrics, 'AUCF02.DFM001', 'Illion')}
                      </div>
                    </div>
                    <div className="text-grey-4 pt-2 text-xs">
                      {softPullCreditPolicyData?.defaultRulesDecision?.declineCheckResult?.threshold?.toString()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
                <div className="grid grid-cols-2">
                  <span>Overall Credit Check:</span>
                  <span
                    className={
                      softPullCreditPolicyData?.creditCheckResult?.toString() === 'Passed'
                        ? 'text-success'
                        : 'text-error'
                    }
                  >
                    {softPullCreditPolicyData?.creditCheckResult?.toString() === 'Passed' ? '✅ Passed' : '❌ Failed'}
                  </span>
                </div>
              </div>
            </>
          )}
          <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
            <div className="grid grid-cols-2">
              <span>Link to operate:</span>
              {softPullInstanceId ? (
                <TextLink
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${operateURL}/processes/${softPullInstanceId}`}
                >
                  Credit file rules
                </TextLink>
              ) : (
                'N/A'
              )}
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
            <strong className="text-primary font-bold">Hard pull</strong>
          </div>
          {hardPullCreditPolicyData && (
            <>
              <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
                <div className="grid grid-cols-2">
                  <span>Credit File Notes:</span>
                  <div>
                    <div
                      className={
                        hardPullCreditPolicyData?.creditNotesHardPullResult?.toString() === 'Passed'
                          ? 'text-success'
                          : 'text-error'
                      }
                    >
                      {hardPullCreditPolicyData?.creditNotesHardPullResult?.toString() === 'Passed'
                        ? '✅ Passed'
                        : '❌ Failed'}
                    </div>
                    <div className="mt-1">
                      <div>
                        {getCreditPolicyReason(hardPullCreditPolicyData.creditMetrics, 'AUCF01.FNM001', 'Equifax')}
                      </div>
                      <div>
                        {getCreditPolicyReason(hardPullCreditPolicyData.creditMetrics, 'AUCF02.FNM001', 'Illion')}
                      </div>
                    </div>
                    <div className="text-grey-4 pt-2 text-xs">
                      {hardPullCreditPolicyData?.creditNotesHardPullDecision?.declineCheckResult?.threshold?.toString()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
                <div className="grid grid-cols-2">
                  <span>Insolvency (Equifax):</span>
                  <div>
                    <div
                      className={
                        hardPullCreditPolicyData?.insolvencyHardPullResult?.toString() === 'Passed'
                          ? 'text-success'
                          : 'text-error'
                      }
                    >
                      {hardPullCreditPolicyData?.insolvencyHardPullResult?.toString() === 'Passed'
                        ? '✅ Passed'
                        : '❌ Failed'}
                    </div>
                    <div className="mt-1">
                      {getCreditPolicyReason(hardPullCreditPolicyData.creditMetrics, 'AUCF01.PIM001', 'Equifax')}
                    </div>
                    <div className="text-grey-4 pt-2 text-xs">
                      {hardPullCreditPolicyData?.insolvencyHardPullDecision?.declineCheckResult?.threshold?.toString()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
                <div className="grid grid-cols-2">
                  <span>Hardship:</span>
                  <div>
                    <div
                      className={
                        hardPullCreditPolicyData?.hardshipHardPullResult?.toString() === 'Passed'
                          ? 'text-success'
                          : 'text-error'
                      }
                    >
                      {hardPullCreditPolicyData?.hardshipHardPullResult?.toString() === 'Passed'
                        ? '✅ Passed'
                        : '❌ Failed'}
                    </div>
                    <div className="mt-1">
                      <div>
                        {getCreditPolicyReason(hardPullCreditPolicyData.creditMetrics, 'AUCF01.PHM002', 'Equifax')}
                      </div>
                      <div>
                        {getCreditPolicyReason(hardPullCreditPolicyData.creditMetrics, 'AUCF02.PHM002', 'Illion')}
                      </div>
                    </div>
                    <div className="text-grey-4 pt-2 text-xs">
                      {hardPullCreditPolicyData?.hardshipHardPullDecision?.declineCheckResult?.threshold?.toString()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
                <div className="grid grid-cols-2">
                  <span>Judgments:</span>
                  <div>
                    <div
                      className={
                        hardPullCreditPolicyData?.judgmentsHardPullResult?.toString() === 'Passed'
                          ? 'text-success'
                          : 'text-error'
                      }
                    >
                      {hardPullCreditPolicyData?.judgmentsHardPullResult?.toString() === 'Passed'
                        ? '✅ Passed'
                        : '❌ Failed'}
                    </div>
                    <div className="mt-1">
                      <div>
                        {getCreditPolicyReason(hardPullCreditPolicyData.creditMetrics, 'AUCF01.JMM001', 'Equifax')}
                      </div>
                      <div>
                        {getCreditPolicyReason(hardPullCreditPolicyData.creditMetrics, 'AUCF02.JMM001', 'Illion')}
                      </div>
                    </div>
                    <div className="text-grey-4 pt-2 text-xs">
                      {hardPullCreditPolicyData?.judgmentsHardPullDecision?.declineCheckResult?.threshold?.toString()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
                <div className="grid grid-cols-2">
                  <span>Bankruptcy (Illion):</span>
                  <div>
                    <div
                      className={
                        hardPullCreditPolicyData?.bankruptcyHardPullResult?.toString() === 'Passed'
                          ? 'text-success'
                          : 'text-error'
                      }
                    >
                      {hardPullCreditPolicyData?.bankruptcyHardPullResult?.toString() === 'Passed'
                        ? '✅ Passed'
                        : '❌ Failed'}
                    </div>
                    <div className="mt-1">
                      {getCreditPolicyReason(hardPullCreditPolicyData.creditMetrics, 'AUCF02.PIBM01', 'Illion')}
                    </div>
                    <div className="text-grey-4 pt-2 text-xs">
                      {hardPullCreditPolicyData?.bankruptcyHardPullDecision?.declineCheckResult?.threshold?.toString()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
                <div className="grid grid-cols-2">
                  <span>Defaults:</span>
                  <div>
                    <div
                      className={
                        hardPullCreditPolicyData?.defaultHardPullResult?.toString() === 'Passed'
                          ? 'text-success'
                          : 'text-error'
                      }
                    >
                      {hardPullCreditPolicyData?.defaultHardPullResult?.toString() === 'Passed'
                        ? '✅ Passed'
                        : '❌ Failed'}
                    </div>
                    <div className="mt-1">
                      <div>
                        {getCreditPolicyReason(hardPullCreditPolicyData.creditMetrics, 'AUCF01.DFM001', 'Equifax')}
                      </div>
                      <div>
                        {getCreditPolicyReason(hardPullCreditPolicyData.creditMetrics, 'AUCF02.DFM001', 'Illion')}
                      </div>
                    </div>
                    <div className="text-grey-4 pt-2 text-xs">
                      {hardPullCreditPolicyData?.defaultHardPullDecision?.declineCheckResult?.threshold?.toString()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
                <span className="grid grid-cols-2">
                  <span>Overall Credit Check:</span>
                  <span
                    className={
                      hardPullCreditPolicyData?.creditCheckHardPullResult?.toString() === 'Passed'
                        ? 'text-success'
                        : 'text-error'
                    }
                  >
                    {hardPullCreditPolicyData?.creditCheckHardPullResult?.toString() === 'Passed'
                      ? '✅ Passed'
                      : '❌ Failed'}
                  </span>
                </span>
              </div>
            </>
          )}
          <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
            <span className="grid grid-cols-2">
              <span>Link to operate:</span>
              {hardPullInstanceId ? (
                <TextLink
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${operateURL}/processes/${hardPullInstanceId}`}
                >
                  Credit file rules
                </TextLink>
              ) : (
                'N/A'
              )}
            </span>
          </div>
        </div>
      </div>
    </CollapsibleCard>
  );
};

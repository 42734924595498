import React from 'react';
import { VaultAccountStatus, VaultLoanViewDto } from '@harmoney/api-interfaces';
import { ProgressBar } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';

type LoanProgressProps = {
  loanDetails: VaultLoanViewDto;
};

export const LoanProgress = ({ loanDetails }: LoanProgressProps) => {
  const validLoanStatuses: VaultAccountStatus[] = [
    VaultAccountStatus.ACCOUNT_STATUS_OPEN,
    VaultAccountStatus.ACCOUNT_STATUS_CLOSED,
    VaultAccountStatus.ACCOUNT_STATUS_PENDING_CLOSURE,
    VaultAccountStatus.ACCOUNT_STATUS_CANCELLED,
  ];

  if (!validLoanStatuses.includes(loanDetails?.loanInformation?.loanStatus as VaultAccountStatus)) {
    return null;
  }

  const { principal, principalDue, principalOverDue, principalWrittenOff, loanAmountTotalWithFee } =
    loanDetails.loanInformation;

  const pendingPrincipal = principal + principalDue + principalOverDue + principalWrittenOff;
  const principalPaid = loanAmountTotalWithFee - pendingPrincipal;
  const principalPaidForProgressBar = pendingPrincipal < 0 ? loanAmountTotalWithFee : principalPaid;
  const progress = (principalPaidForProgressBar / loanAmountTotalWithFee) * 100;

  return (
    <div className="border-b-grey-2 border-b p-4">
      <div className="text-base font-medium ">Remaining balance</div>
      <div className="flex flex-row items-center">
        <span className="flex-grow py-4 mr-2">
          <ProgressBar
            progress={progress}
            totalSteps={1}
            indicatorClassName="bg-gradient-to-r from-tertiary to-tertiary-darker-1"
          />
        </span>
        <span className="items-center text-lg font-medium">{formatCurrency(pendingPrincipal)}</span>
      </div>
    </div>
  );
};

import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { IdentityVerificationStatus } from '@harmoney/api-interfaces';
import { useFriendlyURL, useOneSDK } from '@harmoney/hooks';
import { useAppSelector, useLazyUpdateVerifiedUserProfileQuery } from '@harmoney/redux';
import {
  eventAnalytics,
  IDENTITY_SOFT_ID_FAIL,
  IDENTITY_SOFT_ID_STARTED,
  IDENTITY_SOFT_ID_SUCCESS,
} from '@harmoney/ui-app-shell';
import { Spinner } from '@harmoney/ui-design-system';
import { useLocalStorage } from '@mantine/hooks';
import { BranchEnum, LoanApplicationStatusEnum } from '@prisma/client';
import classNames from 'classnames';

import { CommonProps } from '../../common-props';

const MAX_ATTEMPTS = 3;

export function IdentityVerification({ taskId, completeTaskWithData, taskFriendlyURL }: CommonProps) {
  useFriendlyURL(taskFriendlyURL);

  const router = useRouter();
  const [initialized, setInitialised] = useState(false);
  const [hasReloaded, setHasReloaded] = useLocalStorage<boolean>({ key: 'idv_reloaded', defaultValue: false });
  const [showLoading, setShowLoading] = useState(false);
  const [trigger] = useLazyUpdateVerifiedUserProfileQuery();
  const userId = useAppSelector((state) => state.userId.value);

  useEffect(() => {
    if (hasReloaded) {
      setHasReloaded(false);
    }
  }, [hasReloaded, setHasReloaded]);

  useEffect(() => {
    const handleRouteChange = (url) => {
      const newPathname = url;
      const newURL = new URL(url, window.location.origin);
      // do a browser refresh when navigating from current page to dashboard to clean up onesdk session
      if (newPathname === '/' || newPathname === '/dashboard/') {
        window.location.href = newURL.href;
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  const config = {
    mode: 'production',
    recipe: {
      form: {
        provider: {
          name: 'react',
          googleApiKey: process.env.NEXT_PUBLIC_FRANKIEONE_GOOGLE_API_KEY,
        },
      },
    },
  };
  const { oneSDKInstance: oneSdk, error: errorOneSDK, loading, setComponents } = useOneSDK({ config });

  useEffect(() => {
    const handleFormFailure = (module: string, data) => {
      console.error(`Form ${module} failed`, data);
      router.reload();
    };

    const initOneSDK = () => {
      setInitialised(true);
      eventAnalytics.track(IDENTITY_SOFT_ID_STARTED, {
        userid_str: userId,
        taskid_str: taskId,
      });
      const oneSdkIndividual = oneSdk.individual();
      oneSdkIndividual.setProfileType(process.env.NEXT_PUBLIC_FRANKIEONE_RECIPE_SOFT);
      oneSdkIndividual.addConsent('general');
      oneSdkIndividual.addConsent('creditheader');
      oneSdkIndividual.addConsent('docs');

      const personal = oneSdk.component('form', {
        title: {
          label: "Let's get to know you",
        },
        descriptions: [
          {
            label: '',
          },
        ],
        name: 'PERSONAL',
        type: 'manual',
        cta: { label: 'Continue' },
        personal: {
          countries: {
            AUS: {
              default: {
                fields: [
                  {
                    name: 'givenName',
                    label: `First name`,
                    fieldType: 'input',
                    dataType: 'text',
                    rules: {
                      required: {
                        value: true,
                        message: 'Please enter your first name',
                      },
                      pattern: {
                        value: '^(?!\\s*$)[a-zA-Z -]+$',
                        message: 'Invalid Characters detected, please enter your name with values A-Z',
                      },
                    },
                  },
                  {
                    name: 'middleName',
                    label: `Middle Name`,
                    fieldType: 'input',
                    helperText: 'Only if shown on your ID',
                    dataType: 'text',
                    rules: {
                      required: {
                        value: false,
                      },
                      pattern: {
                        value: '^(?!\\s*$)[a-zA-Z -]+$',
                        message: 'Invalid Characters detected, please enter your name with values A-Z',
                      },
                    },
                  },
                  {
                    name: 'familyName',
                    label: `Last Name`,
                    fieldType: 'input',
                    dataType: 'text',
                    rules: {
                      required: {
                        value: true,
                        message: 'Please enter your last name',
                      },
                      pattern: {
                        value: '^(?!\\s*$)[a-zA-Z -]+$',
                        message: 'Invalid Characters detected, please enter your name with values A-Z',
                      },
                    },
                  },
                  {
                    name: 'dateOfBirth',
                    fieldType: 'date',
                    dataType: 'text',
                    hide: false,
                    label: 'What is your date of birth?',
                    calendarConfig: {
                      day: true,
                      month: true,
                      year: true,
                      age: {
                        min: 18,
                        max: 125,
                        message: 'To be eligible for a quote, you must be 18 years or older.',
                      },
                    },
                  },
                  {
                    name: 'address.fullAddress',
                    fieldType: 'address',
                    dataType: 'current_addr',
                    hide: false,
                    helperText: 'Please enter your current address and it cannot be a PO Box',
                    acceptedCountries: ['AUS'],
                    manualFieldConfig: [
                      {
                        fieldType: 'select',
                        dataType: 'text',
                        hide: true,
                        label: 'Country',
                        name: 'address.country',
                        options: [{ label: 'Australia', value: 'AUS' }],
                        rules: {
                          required: {
                            value: true,
                            message: `Please select your country`,
                          },
                        },
                      },
                      {
                        fieldType: 'input',
                        dataType: 'text',
                        hide: false,
                        label: 'Unit Number (optional)',
                        name: 'address.unitNumber',
                        rules: {
                          pattern: '/^[a-zA-Z0-9s-]+$/',
                          message: 'Please enter a valid unit number (letters, numbers, spaces, or hyphens).',
                        },
                      },
                      {
                        fieldType: 'input',
                        dataType: 'text',
                        hide: false,
                        label: 'Street Address',
                        name: 'address.streetAddress',
                        rules: {
                          required: {
                            value: true,
                            message: 'Please enter your street address',
                          },
                        },
                      },
                      {
                        fieldType: 'input',
                        dataType: 'text',
                        hide: false,
                        label: 'Suburb',
                        name: 'address.town',
                        rules: {
                          required: {
                            value: true,
                            message: 'Please enter your suburb',
                          },
                        },
                      },
                      {
                        fieldType: 'select',
                        dataType: 'text',
                        hide: false,
                        label: 'State',
                        name: 'address.state',
                        placeholder: 'Select Your State',
                        options: [
                          { label: 'WA', value: 'WA' },
                          { label: 'NSW', value: 'NSW' },
                          { label: 'TAS', value: 'TAS' },
                          { label: 'VIC', value: 'VIC' },
                          { label: 'QLD', value: 'QLD' },
                          { label: 'ACT', value: 'ACT' },
                          { label: 'NT', value: 'NT' },
                          { label: 'SA', value: 'SA' },
                        ],
                        rules: {
                          required: {
                            value: true,
                            message: 'Please enter your state',
                          },
                        },
                      },
                      {
                        fieldType: 'input',
                        dataType: 'text',
                        hide: false,
                        label: 'Postcode',
                        name: 'address.postalCode',
                        rules: {
                          required: {
                            value: true,
                            message: 'Please enter your postal code',
                          },
                          pattern: { value: '^[0-9]{4}$', message: 'Please enter a valid postcode (4 digits).' },
                        },
                      },
                    ],
                  },
                ],
              },
            },
          },
        },
      });

      const review = oneSdk.component('form', {
        name: 'REVIEW',
        type: 'manual',
        title: { label: 'Take a moment to check your details' },
        cta: { label: 'Continue' },
      });

      const loadingScreen = oneSdk.component('form', {
        name: 'LOADING',
        title: { label: 'Doing a quick check...' },
        descriptions: [
          {
            label: 'Hang tight!',
          },
        ],
      });
      const resultFail = oneSdk.component('form', {
        name: 'RESULT',
        type: 'manual',
        state: 'FAIL',
        title: { label: 'We had some trouble with the information you have provided:' },
        descriptions: [
          { label: 'Enter your full name exactly as it appears on your ID, including any middle names.' },
          { label: 'Make sure your date of birth matches your ID.' },
        ],
        cta: { label: 'Retry' },
      });

      setComponents([personal, review, loadingScreen, resultFail]);

      let ATTEMPT_COUNT = 0;

      const handleResult = async (result) => {
        const entityId = oneSdkIndividual.access('entityId').getValue();
        if (result.status.key == 'FAIL') {
          if (result?.risk?.class === 'UNACCEPTABLE' || result?.risk?.class === 'HIGH') {
            setShowLoading(true);
            await trigger({ branch: BranchEnum.AU, entityId });
            eventAnalytics.track(IDENTITY_SOFT_ID_SUCCESS, {
              userid_str: userId,
              taskid_str: taskId,
            });
            await completeTaskWithData({
              taskId,
              variables: {
                applicationStatus: LoanApplicationStatusEnum.quote_in_progress,
                verificationStatus: IdentityVerificationStatus.SUCCESS,
              },
            });
            return;
          }
          if (ATTEMPT_COUNT < MAX_ATTEMPTS) {
            resultFail.mount('#form-container');
          } else {
            setShowLoading(true);
            await trigger({ branch: BranchEnum.AU, entityId });
            eventAnalytics.track(IDENTITY_SOFT_ID_FAIL, {
              userid_str: userId,
              taskid_str: taskId,
            });
            await completeTaskWithData({
              taskId,
              variables: {
                applicationStatus: LoanApplicationStatusEnum.quote_unsuccessful,
                verificationStatus: IdentityVerificationStatus.TOO_MANY_TRIES,
              },
            });
          }
        } else if (
          result.status.key === 'PASS' ||
          result.status.key === 'PASS_MANUAL' ||
          result.status.key === 'REFER'
        ) {
          setShowLoading(true);
          await trigger({ branch: BranchEnum.AU, entityId });
          eventAnalytics.track(IDENTITY_SOFT_ID_SUCCESS, {
            userid_str: userId,
            taskid_str: taskId,
          });
          await completeTaskWithData({
            taskId,
            variables: {
              applicationStatus: LoanApplicationStatusEnum.quote_in_progress,
              verificationStatus: IdentityVerificationStatus.SUCCESS,
            },
          });
        } else {
          if (ATTEMPT_COUNT < MAX_ATTEMPTS) {
            resultFail.mount('#form-container');
          }
        }
      };

      personal.mount('#form-container');
      personal.on('form:personal:failed', (data) => {
        handleFormFailure('personal', data);
      });
      personal.on('form:personal:ready', (data) => {
        review.mount('#form-container');
      });

      review.on('form:review:ready', async () => {
        loadingScreen.mount('#form-container');
        const result = await oneSdkIndividual.submit({ verify: true });
        ATTEMPT_COUNT += 1;
        await handleResult(result);
      });

      resultFail.on('form:result:failed', () => {
        personal.mount('#form-container');
      });
    };

    if (!initialized && oneSdk) {
      initOneSDK();
    }
  }, [oneSdk, initialized, completeTaskWithData, taskId, router, setComponents, trigger, userId]);

  if (loading || showLoading) {
    return <Spinner />;
  }

  return <div id="form-container" className={classNames('width-[576px] height-[600px]')}></div>;
}

import { generateIcon } from '../../generateIcon';
import { IconVariant } from '../../Icon';

export const LegalFeesIcon = function ({ variant = 'grey-4' }: { variant?: IconVariant }) {
  const Component = generateIcon({
    viewBox: '0 0 24 24',
    variant: variant,
    path: (
      <>
        <g clipPath="url(#clip0_4222_53329)">
          <path d="M4 11.5003V15.5003C4 16.3303 4.67 17.0003 5.5 17.0003C6.33 17.0003 7 16.3303 7 15.5003V11.5003C7 10.6703 6.33 10.0003 5.5 10.0003C4.67 10.0003 4 10.6703 4 11.5003ZM10 11.5003V15.5003C10 16.3303 10.67 17.0003 11.5 17.0003C12.33 17.0003 13 16.3303 13 15.5003V11.5003C13 10.6703 12.33 10.0003 11.5 10.0003C10.67 10.0003 10 10.6703 10 11.5003ZM3.5 22.0003H19.5C20.33 22.0003 21 21.3303 21 20.5003C21 19.6703 20.33 19.0003 19.5 19.0003H3.5C2.67 19.0003 2 19.6703 2 20.5003C2 21.3303 2.67 22.0003 3.5 22.0003ZM16 11.5003V15.5003C16 16.3303 16.67 17.0003 17.5 17.0003C18.33 17.0003 19 16.3303 19 15.5003V11.5003C19 10.6703 18.33 10.0003 17.5 10.0003C16.67 10.0003 16 10.6703 16 11.5003ZM10.57 1.49031L2.67 5.65031C2.26 5.86031 2 6.29031 2 6.75031C2 7.44031 2.56 8.00031 3.25 8.00031H19.76C20.44 8.00031 21 7.44031 21 6.75031C21 6.29031 20.74 5.86031 20.33 5.65031L12.43 1.49031C11.85 1.18031 11.15 1.18031 10.57 1.49031Z" />
        </g>
        <defs>
          <clipPath id="clip0_4222_53329">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </>
    ),
  });
  return <Component />;
};

import { errors } from '@harmoney/ui-utils';
import { BranchEnum } from '@prisma/client';
import { z } from 'zod';

// regex to allow only digits in phone number
const phoneNumberRegex = /^\d+$/;

export type Country = {
  countryCode: string;
  countryPhoneLabel: string;
  minLength?: number;
  maxLength?: number;
  firstDigit?: string;
};

interface PhoneNumberVerificationForm {
  branch: BranchEnum;
  prefix: string;
  RegionalText: Record<BranchEnum, Country>;
}

export const createFormSchema = ({ branch, prefix, RegionalText }: PhoneNumberVerificationForm) => {
  return z.object({
    mobilePhoneNumber: z
      .string({
        required_error: errors.invalid('phone number'),
        invalid_type_error: errors.invalid('phone number'),
      })
      .min(RegionalText[branch]?.minLength, errors.invalid('phone number'))
      .max(RegionalText[branch]?.maxLength, errors.invalid('phone number'))
      .regex(phoneNumberRegex, errors.invalid('phone number'))
      .transform((value) => {
        // if value starts with 0, remove it
        if (value.startsWith('0')) {
          return prefix + value.slice(1);
        }
        return prefix + value;
      })
      .refine((value) => {
        return value.startsWith(`${RegionalText[branch]?.countryCode}${RegionalText[branch]?.firstDigit}`);
      }, errors.invalid('phone number'))
      .refine((value) => {
        const auValueLengthWithPrefix =
          RegionalText[BranchEnum.AU]?.countryCode.length + RegionalText[BranchEnum.AU]?.minLength;
        if (branch === BranchEnum.AU && value.length !== auValueLengthWithPrefix) {
          return false;
        }
        return true;
      }, errors.invalid('phone number')),
  });
};

import { useAppDispatch } from '@harmoney/redux';

import { setShowChangeSummary } from '../../../../redux/slice/admin';

import { Header } from './Header';

interface PaymentPlanFormHeaderProps {
  headerTitle: string;
}

export const PaymentPlanFormHeaderWithoutForm = ({ headerTitle }: PaymentPlanFormHeaderProps) => {
  const dispatch = useAppDispatch();

  const handleCancel = () => {
    dispatch(setShowChangeSummary(false));
  };

  return <Header headerTitle={headerTitle} onCancelClick={handleCancel} />;
};

import { AssignTaskByUserDto, CompleteTaskByIdDto, TaskResponseDto } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const tasklistApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTasks: builder.query<TaskResponseDto[], void>({
      query: () => ({
        url: '/tasks-list/all',
        method: 'GET',
      }),
      providesTags: ['InternalTask'],
    }),
    getUnassignedTasks: builder.query<TaskResponseDto[], { group: string }>({
      query: ({ group }) => ({
        url: `/tasks-list/unassigned-task`,
        method: 'GET',
        params: { group },
      }),
      providesTags: ['InternalTask'],
    }),
    getUserTasks: builder.query<TaskResponseDto[], { group: string }>({
      query: ({ group }) => ({
        url: `/tasks-list/my-tasks`,
        method: 'GET',
        params: { group },
      }),
      providesTags: ['InternalTask'],
    }),
    findTaskById: builder.query<TaskResponseDto, string>({
      query: (taskId) => ({
        url: `/tasks-list/${taskId}`,
        method: 'GET',
      }),
      providesTags: ['InternalTask'],
    }),

    assignTaskByUser: builder.mutation<TaskResponseDto, { taskId: string; data: AssignTaskByUserDto }>({
      query: ({ taskId, data }) => ({
        url: `/tasks-list/${taskId}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['InternalTask'],
    }),
    completeTaskById: builder.mutation<TaskResponseDto, { taskId: string; variableData: CompleteTaskByIdDto }>({
      query: ({ taskId, variableData }) => ({
        url: `/tasks-list/${taskId}/complete`,
        method: 'PATCH',
        body: variableData,
      }),
      invalidatesTags: ['InternalTask'],
    }),
  }),
});

export const {
  useGetAllTasksQuery,
  useGetUnassignedTasksQuery,
  useGetUserTasksQuery,
  useFindTaskByIdQuery,
  useAssignTaskByUserMutation,
  useCompleteTaskByIdMutation,
} = tasklistApi;

import { generateIcon } from '../../generateIcon';
import { IconSize, IconVariant } from '../../Icon';

export const FlagIcon = function ({ variant = 'grey-2', size }: { variant?: IconVariant; size?: IconSize }) {
  const Component = generateIcon({
    viewBox: '0 0 24 24',
    variant: variant,
    props: {
      size,
    },
    path: (
      <path d="M8 18h1.5v-5H12l1 2h5V9h-3l-1-2H8v11Zm4 4q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z" />
    ),
  });
  return <Component />;
};

import { ControlledInput, DatePicker } from '@harmoney/ui-design-system';
import { parseUTCAndConvertToLocalTime } from '@harmoney/ui-utils';
import dayjs from 'dayjs';

interface RepaymentHolidayFieldsProps {
  contractualStartDate: Date;
  register: any;
}

export const RepaymentHolidayFields = ({ register, contractualStartDate }: RepaymentHolidayFieldsProps) => {
  return (
    <div>
      <p className="font-medium">Direct debit and monthly contractual schedule suspension</p>
      <DatePicker
        className="mb-4"
        {...register('effectiveDate')}
        label="Pause date"
        placeholderText="DD/MM/YYYY"
        minDate={dayjs().toDate()}
        flexiblePopperPlacement
        onKeyDown={(e) => e.preventDefault()}
      />
      <DatePicker
        className="mb-4"
        {...register('contractualPaymentDate')}
        label="Next contractual payment date (new)"
        placeholderText="DD/MM/YYYY"
        minDate={parseUTCAndConvertToLocalTime(contractualStartDate).toDate()}
        onKeyDown={(e) => e.preventDefault()}
        flexiblePopperPlacement
      />
      <ControlledInput
        type="number"
        className="mb-6"
        {...register('loanTerm')}
        isNumeric
        label="Number of months for the loan term"
      />
    </div>
  );
};

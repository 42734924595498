import { SimulationInputDto, SimulationStatusOutputDto } from '@harmoney/api-interfaces';
import { BPMNSimulation, Prisma } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const simulationApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getSimulationDetails: builder.query<BPMNSimulation & { existingVariables: Prisma.JsonValue }, string>({
      query: (loanApplicationId) => ({
        url: `/simulation/loan-applications/${loanApplicationId}/details`,
        method: 'GET',
      }),
    }),
    getSimulationStatus: builder.query<SimulationStatusOutputDto, string>({
      query: (batchId) => ({
        url: `/simulation/loan-applications/status`,
        method: 'GET',
        params: { batchId },
      }),
    }),
    simulateLoanApplications: builder.mutation<BPMNSimulation, SimulationInputDto>({
      query: (simulationInput) => ({
        url: `/simulation/loan-applications`,
        method: 'POST',
        body: simulationInput,
      }),
    }),
  }),
});

export const { useGetSimulationDetailsQuery, useGetSimulationStatusQuery, useSimulateLoanApplicationsMutation } =
  simulationApi;

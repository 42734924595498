import { CreditAuditUpsertDto } from '@harmoney/api-interfaces';
import { CreditAudit } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const creditAuditApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    saveAudit: builder.mutation<CreditAudit, CreditAuditUpsertDto>({
      query: (data) => ({
        url: '/co-audit',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'CreditAudit', id: 'AUDITS' }],
    }),
    getAllCreditAuditByApplicationId: builder.query<CreditAudit[], string>({
      query: (applicationId) => ({
        url: `/co-audit`,
        method: 'GET',
        params: {
          applicationId,
        },
      }),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'CreditAudit' as const, id })), { type: 'CreditAudit', id: 'AUDITS' }]
          : [{ type: 'CreditAudit', id: 'AUDITS' }],
    }),
  }),
});

export const { useSaveAuditMutation, useGetAllCreditAuditByApplicationIdQuery } = creditAuditApi;

import { useEffect, useState } from 'react';
import { IIncomeOverrideReasonFields, NetworthSourceEnum } from '@harmoney/api-interfaces';
import { IncomeAndExpense, LoanApplicationStatusEnum, NetworthSource } from '@prisma/client';
import classNames from 'classnames';
import { Entry } from 'contentful';

import { IncomeCategories } from './IncomeCategories';

interface IncomeDetailsProps {
  applicationStatus: LoanApplicationStatusEnum;
  incomeData: (IncomeAndExpense & {
    networthSource: NetworthSource;
  })[];
  overrideReasons?: Entry<IIncomeOverrideReasonFields>[];
  withForm?: boolean;
}

export const IncomeDetails = ({ incomeData, overrideReasons, applicationStatus, withForm }: IncomeDetailsProps) => {
  const [groupedIncome, setGroupedIncome] = useState<
    Record<
      string,
      (IncomeAndExpense & {
        networthSource: NetworthSource;
      })[]
    >
  >({});

  useEffect(() => {
    const groupedIncome: Record<
      string,
      (IncomeAndExpense & {
        networthSource: NetworthSource;
      })[]
    > = {};
    incomeData.forEach((income) => {
      if (!groupedIncome[income.networthSource.id]) {
        groupedIncome[income.networthSource.id] = [];
      }
      groupedIncome[income.networthSource.id].push(income);
    });
    setGroupedIncome(groupedIncome);
  }, [incomeData]);

  return (
    <div className={classNames(withForm ? 'grid-cols-1' : 'grid-cols-2', 'grid gap-2')}>
      {Object.keys(groupedIncome)
        .sort((a, b) => {
          const aNum = Number(a);
          const bNum = Number(b);
          if (aNum === NetworthSourceEnum.INCOME_CHANGE_ID) {
            return 1;
          }
          if (bNum === NetworthSourceEnum.INCOME_CHANGE_ID) {
            return -1;
          }
          return a.localeCompare(b);
        })
        .map((key) => {
          return (
            <div key={key} className={`row-span-${groupedIncome[key].length}`}>
              <IncomeCategories
                withForm={withForm}
                key={key}
                incomeData={groupedIncome[key]}
                overrideReasons={overrideReasons}
                applicationStatus={applicationStatus}
              />
            </div>
          );
        })}
    </div>
  );
};

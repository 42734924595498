import { UseFormRegister } from 'react-hook-form';
import { AccommodationExpenseEnum, ResidentialStatusEnum } from '@harmoney/api-interfaces';
import { AmountFrequency, Card, Label, Select } from '@harmoney/ui-design-system';
import { frequencyOptions, getAccommodationExpenseOptions } from '@harmoney/ui-utils';

import { getLabelForLivingType } from '../form.util';
import { FormSchema, FormSchemaForAccommodationExpense } from '../form-config';

interface Props {
  register: UseFormRegister<FormSchema>;
  livingType: ResidentialStatusEnum;
  watchAccommodationExpense: FormSchemaForAccommodationExpense['accommodationExpense'];
  shouldDisplaySharedExpense: boolean;
}

const IndividualExpense = ({ register }: Pick<Props, 'register'>) => (
  <Card>
    <Label className="mb-2">
      How much do <span className="font-medium">you</span> pay?
    </Label>
    <AmountFrequency
      register={register}
      inputKey="accommodationExpense.declaredAmount"
      selectKey="accommodationExpense.declaredFrequency"
      name="renting-housing"
      options={frequencyOptions}
    />
  </Card>
);

const SharedExpense = ({
  register,
  livingType,
  watchAccommodationExpense,
}: Pick<Props, 'register' | 'livingType' | 'watchAccommodationExpense'>) => {
  const labelForLivingType = getLabelForLivingType(livingType).label;
  const labelForTotalAmount = getLabelForLivingType(livingType).labelForTotalAmount;
  const isExpenseShared = watchAccommodationExpense?.situation === AccommodationExpenseEnum.SHARED;
  const isExpenseFullyPaid = watchAccommodationExpense?.situation === AccommodationExpenseEnum.FULL_PAID;

  const renderAmountFrequency = (props: { inputKey: string; selectKey: string; label: string; name: string }) => {
    const { inputKey, selectKey, label, name } = props;
    const showOptionalLabel =
      isExpenseShared && inputKey === 'declaredTotalAmount' && livingType !== ResidentialStatusEnum.RENTING;

    return (
      <div className="mt-4">
        {showOptionalLabel && (
          <Label className="mb-2">
            {labelForTotalAmount} <span className="text-grey-4">(Optional)</span>
          </Label>
        )}

        <AmountFrequency
          register={register}
          inputKey={`accommodationExpense.${inputKey}`}
          selectKey={`accommodationExpense.${selectKey}`}
          name={name}
          options={frequencyOptions}
          label={showOptionalLabel ? '' : label}
        />
      </div>
    );
  };

  return (
    <Card>
      <Label className="mb-2">
        Do you <span className="font-medium">share</span> {labelForLivingType}?
      </Label>
      <Select {...register('accommodationExpense.situation')} options={getAccommodationExpenseOptions(livingType)} />

      {isExpenseShared && (
        <>
          {renderAmountFrequency({
            inputKey: 'declaredTotalAmount',
            selectKey: 'declaredTotalFrequency',
            label: labelForTotalAmount,
            name: 'full-paid-accommodation-expense',
          })}
          {renderAmountFrequency({
            inputKey: 'declaredAmount',
            selectKey: 'declaredFrequency',
            label: 'Your share',
            name: 'shared-accommodation-expense',
          })}
        </>
      )}

      {isExpenseFullyPaid &&
        renderAmountFrequency({
          inputKey: 'declaredAmount',
          selectKey: 'declaredFrequency',
          label: labelForTotalAmount,
          name: 'full-paid-accommodation-expense',
        })}
    </Card>
  );
};

export const AccommodationExpenseCard = (props: Props) => {
  const { shouldDisplaySharedExpense } = props;

  return <>{shouldDisplaySharedExpense ? <SharedExpense {...props} /> : <IndividualExpense {...props} />}</>;
};

import React, { useMemo, useState } from 'react';
import { Button } from '@harmoney/ui-design-system';
import { itemsPerPageOptions } from '@harmoney/ui-utils';
import { Icon } from '@iconify/react';
import { rankItem } from '@tanstack/match-sorter-utils';
import {
  ColumnDef,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  OnChangeFn,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';

import { CustomiseColumnsDialog } from './application-hub/CustomiseColumnsDialog';
import { FixedWidthForFirstColumn } from './application-hub';
import GlobalFilter from './GlobalFilter';

export interface DataTableProps {
  title: string | React.ReactNode;
  data: any;
  columns: ColumnDef<any>[];
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  total: number;
  fixedWidthForFirstColumn?: FixedWidthForFirstColumn;
  setPagination: OnChangeFn<PaginationState>;
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
  defaultColumnVisibility?: Record<string, boolean>;
  customisedColumnsLocalStorageKey?: string;
}

const classesForStickyColumns = (id: string, fixedWidthForFirstColumn: FixedWidthForFirstColumn) => {
  if (!fixedWidthForFirstColumn) return '';

  const commonClasses = 'sticky z-10';

  const stickyClasses = {
    businessKey: `${commonClasses} ${fixedWidthForFirstColumn.minWidthWithPadding} left-0`,
    user_businessKey: `${commonClasses} ${fixedWidthForFirstColumn.minWidthWithPadding} ${fixedWidthForFirstColumn.leftWidthWithPadding} after:content-[''] after:absolute after:top-0 after:right-0 after:w-[1px] after:h-full after:bg-grey-2`,
  };

  return stickyClasses[id] || '';
};

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);
  // Store the itemRank info
  addMeta({
    itemRank,
  });
  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export const DEFAULT_FULL_COLUMN_VISIBILITY = {};

export const DataTable = ({
  title,
  columns,
  data,
  pageIndex,
  pageSize,
  total,
  setPagination,
  globalFilter,
  setGlobalFilter,
  pageCount,
  fixedWidthForFirstColumn,
  defaultColumnVisibility = DEFAULT_FULL_COLUMN_VISIBILITY,
  customisedColumnsLocalStorageKey,
}: DataTableProps) => {
  const defaultData = useMemo(() => [], []);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = React.useState(defaultColumnVisibility);
  const [isCustomiseColumnsDialogOpen, setIsCustomiseColumnsDialogOpen] = React.useState(false);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    columns: columns,
    data: data ?? defaultData,
    pageCount,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      globalFilter,
      pagination,
      columnVisibility,
    },
    onSortingChange: setSorting,
    manualPagination: true,
    manualFiltering: true,
    globalFilterFn: fuzzyFilter,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedRowModel: getFacetedRowModel(),
  });

  return (
    <>
      <div className="mb-6 flex flex-row items-center justify-between gap-4">
        <h1 className="mb-0">{title}</h1>
        <div className="flex flex-row gap-4">
          {Boolean(customisedColumnsLocalStorageKey) && (
            <Button variant="outline-secondary" onClick={() => setIsCustomiseColumnsDialogOpen(true)}>
              Column Selection
            </Button>
          )}
          <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} totalItems={total} />
        </div>
      </div>
      <div className="ml-8 mb-2 overflow-x-auto shadow-drop rounded-lg">
        <table className="mb-4 text-sm min-w-full">
          <thead className="bg-grey-1 text-grey-5 ">
            {table?.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b border-grey-2 align-top">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={`p-4 bg-grey-1 text-left ${classesForStickyColumns(header.id, fixedWidthForFirstColumn)}`}
                  >
                    <div
                      {...{
                        className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <Icon icon="mi:sort" />,
                        desc: <Icon icon="mi:sort" />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white">
            {table.getRowModel().rows.map((row, index) => {
              return (
                <tr key={row.id} className="border-b border-grey-2 last:border-none align-top">
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className={
                          (classNames({
                            'first-of-type:rounded-bl-lg last-of-type:rounded-br-lg':
                              table.getRowModel().rows.length - 1 === index,
                          }),
                          `p-4 bg-white ${classesForStickyColumns(cell.column.id, fixedWidthForFirstColumn)}`)
                        }
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex flex-col justify-between gap-4 md:flex-row md:items-center ml-8">
        <div className="flex flex-row gap-4">
          <Button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} size="small">
            First Page
          </Button>
          <Button
            onClick={() => {
              table.previousPage();
            }}
            disabled={!table.getCanPreviousPage()}
            size="small"
          >
            Previous
          </Button>
          <Button
            onClick={() => {
              table.nextPage();
            }}
            disabled={!table.getCanNextPage()}
            size="small"
          >
            Next
          </Button>
          <Button
            onClick={() => {
              table.setPageIndex(table.getPageCount() - 1);
            }}
            disabled={!table.getCanNextPage()}
            size="small"
          >
            Last Page
          </Button>
        </div>
        <div className="flex flex-row gap-4">
          <span>
            {`Page `}
            <strong>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </strong>
          </span>
          <span>
            {`| Go to page: `}
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className="w-6 rounded-md border border-gray-300 text-center"
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {itemsPerPageOptions.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
      <CustomiseColumnsDialog
        tableRef={table}
        isCustomiseColumnsDialogOpen={isCustomiseColumnsDialogOpen}
        setIsCustomiseColumnsDialogOpen={setIsCustomiseColumnsDialogOpen}
        setColumnVisibility={setColumnVisibility}
        customisedColumnsLocalStorageKey={customisedColumnsLocalStorageKey}
      />
    </>
  );
};

import { CollapsibleCard } from '@harmoney/ui-design-system';

import { CustomerApplicationList } from './CustomerApplicationList';

export const CustomerApplicationTable = ({ userId }: { userId: string }) => {
  return (
    <CollapsibleCard title="Applications" className="mb-6" disabled>
      <CustomerApplicationList userId={userId} />
    </CollapsibleCard>
  );
};

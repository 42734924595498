import { LottieCalculation } from '../Lotties/LottieCalculation';

export function QuoteLoading() {
  return (
    <div className="mt-12 flex flex-col items-center">
      <p className="mb-16 flex flex-col items-center gap-4">
        <span className="font-medium">Thanks for filling in your info!</span>
        <span className="text-sm">This won&apos;t take long.</span>
      </p>
      <LottieCalculation />
    </div>
  );
}

import { useEffect, useState } from 'react';
import { LoanPurposeQuestionDto } from '@harmoney/api-interfaces';
import { useTransformLoanPurposes } from '@harmoney/hooks';
import { useGetLoanPurposesForPersonalLoanQuery } from '@harmoney/redux';
import { ArrowCircleRightIcon, Button, Form, LoanPurposeSelector, useForm } from '@harmoney/ui-design-system';
import { errors } from '@harmoney/ui-utils';
import { z } from 'zod';

interface SelectPersonalLoanPurposeFormProps {
  onSubmit: (data: z.infer<typeof selectPersonalLoanPurposeSchema>, questions: LoanPurposeQuestionDto[]) => void;
  onCancel: () => void;
  label?: string;
  selectPlaceholder?: string;
  primaryCTAText?: string;
  secondaryCTAText?: string;
}

export const selectPersonalLoanPurposeSchema = z.object({
  loanPurpose: z.string().min(1, {
    message: 'Please select a loan purpose',
  }),
  questions: z
    .object({
      optionId: z.union([z.string(), z.boolean()]),
    })
    .refine(({ optionId }) => optionId, {
      message: errors.defaultRequiredField,
      path: ['optionId'],
    })
    .array(),
});

export const SelectPersonalLoanPurposeForm = ({
  label = 'What would you like to borrow for instead?',
  selectPlaceholder = 'Select a new loan purpose',
  primaryCTAText = 'Change',
  secondaryCTAText = 'Back',
  onSubmit,
  onCancel,
}: SelectPersonalLoanPurposeFormProps) => {
  const { data: loanPurposes } = useGetLoanPurposesForPersonalLoanQuery(null);
  const { primaryLoanPurposes, secondaryLoanPurposes } = useTransformLoanPurposes(loanPurposes, true);
  const [questions, setQuestions] = useState<LoanPurposeQuestionDto[]>([]);

  const form = useForm({
    mode: 'onSubmit',
    schema: selectPersonalLoanPurposeSchema,
    defaultValues: {
      loanPurpose: '',
      questions: [],
    },
  });

  const {
    resetField,
    watch,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;

  const watchLoanPurpose = watch('loanPurpose');

  useEffect(() => {
    if (!watchLoanPurpose) return;
    const { questions } = loanPurposes.find((loanPurpose) => loanPurpose.id === watchLoanPurpose);
    if (questions.length > 0) {
      setQuestions(questions);
    } else {
      setQuestions([]);
    }
    resetField('questions', { defaultValue: [] });
  }, [loanPurposes, resetField, watchLoanPurpose]);

  return (
    <Form
      form={form}
      onSubmit={(data) => {
        onSubmit(data, questions);
      }}
    >
      <LoanPurposeSelector
        label={label}
        name="loanPurpose"
        loanPurposes={primaryLoanPurposes.concat(secondaryLoanPurposes)}
        loanPurposeQuestions={questions}
        selectPlaceholder={selectPlaceholder}
      />
      <div className="flex w-full flex-col items-center my-4 gap-2 md:flex-row-reverse">
        <Button
          variant="primary"
          type="submit"
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          className="mt-4 mb-2"
          isLoading={isSubmitting || isSubmitSuccessful}
        >
          {primaryCTAText}
        </Button>
        <Button variant="secondary" onClick={onCancel} disabled={isSubmitting || isSubmitSuccessful}>
          {secondaryCTAText}
        </Button>
      </div>
    </Form>
  );
};

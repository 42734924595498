import { generateIcon } from '../generateIcon';

/**
 * @deprecated Check if it can be replaced with Iconify. If not, add it to icon.json.
 * Refer to icon document for details: https://63be80b51f526a24fc7865c5-tslqcdnosl.chromatic.com/?path=/docs/docs-icons--docs
 */
export const StaticProgressIcon = generateIcon({
  viewBox: '0 0 16 16',
  path: (
    <>
      <circle cx="8" cy="8" r="5.8" fill="none" stroke="#d9d9d9" strokeWidth="1.5" />
      <circle
        cx="8"
        cy="8"
        r="5.8"
        fill="none"
        stroke="#206EEE"
        strokeWidth="1.5"
        strokeDasharray="16.1"
        strokeDashoffset="16.1"
      />
    </>
  ),
});

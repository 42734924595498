import { useRenameLoanApplicationMutation } from '@harmoney/redux';
import { ArrowCircleRightIcon, Button, ControlledInput, Dialog, Form, useForm } from '@harmoney/ui-design-system';
import { z } from 'zod';

import { renameLoanDialogSchema } from './form-config';

interface RenameLoanDialogProps {
  applicationId: string;
  isRenameLoanDialogOpen: boolean;
  closeRenameLoanDialog: () => void;
}

export function RenameLoanDialog({
  applicationId,
  isRenameLoanDialogOpen,
  closeRenameLoanDialog,
}: RenameLoanDialogProps) {
  const [renameLoan] = useRenameLoanApplicationMutation();

  const form = useForm({
    mode: 'onSubmit',
    schema: renameLoanDialogSchema,
  });

  const {
    register,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;

  async function handleRenameLoanSubmit(data: z.infer<typeof renameLoanDialogSchema>) {
    await renameLoan({
      displayName: data.displayName,
      loanApplicationId: applicationId,
    });

    closeRenameLoanDialog();
  }

  return (
    <Dialog title="Rename loan" open={isRenameLoanDialogOpen} modal onOpenChange={closeRenameLoanDialog}>
      <Form form={form} onSubmit={handleRenameLoanSubmit} className="p-4">
        <ControlledInput {...register('displayName')} label="Rename loan" displayValidation />
        <div className="flex w-full flex-col items-center mt-4 gap-2 md:flex-row-reverse">
          <Button
            variant="primary"
            type="submit"
            isLoading={isSubmitting || isSubmitSuccessful}
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
          >
            Save
          </Button>
          <Button variant="secondary" onClick={closeRenameLoanDialog}>
            Cancel
          </Button>
        </div>
      </Form>
    </Dialog>
  );
}

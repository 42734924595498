import { CollectionStatusDto } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const collectionStatusApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCollectionStatus: builder.query<CollectionStatusDto[], string>({
      query: (loanApplicationId) => ({
        url: `/collection-status/${loanApplicationId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetCollectionStatusQuery } = collectionStatusApi;

import { RepeatRefreshedLoansResultDto } from '@harmoney/api-interfaces';
import { ArrowCircleRightIcon, Button, Dialog, Divider } from '@harmoney/ui-design-system';
import { formatCurrency, formatFrequency } from '@harmoney/utilities';
import { RepaymentFrequencyEnum } from '@prisma/client';

import { formatProviderName } from '../financial-profile/FinancialConfirmation/components/Debts/util';

interface LoanBalanceAffordableModalProps {
  quoteDetails: RepeatRefreshedLoansResultDto;
  isOpen: boolean;
  onModalOpen: () => void;
  onContinue: () => void;
}

const getTotalOutstandingBalance = (
  consolidatedDebts: LoanBalanceAffordableModalProps['quoteDetails']['consolidatedDebts']
) => {
  return consolidatedDebts.reduce((total, debt) => total + debt.outstandingBalance, 0);
};

const DebtsToConsolidate = ({
  consolidatedDebts,
  establishmentFee,
  hasChanged,
  extraLimitLoanPurpose,
  hasExtraLimitLoanAmountUpdated,
  extraLimitLoanAmount,
  extraLimitOriginalLoanAmount,
}: {
  consolidatedDebts: LoanBalanceAffordableModalProps['quoteDetails']['consolidatedDebts'];
  establishmentFee: number;
  hasChanged: boolean;
  extraLimitLoanPurpose?: string;
  hasExtraLimitLoanAmountUpdated?: boolean;
  extraLimitLoanAmount?: number;
  extraLimitOriginalLoanAmount?: number;
}) => {
  const totalOutstandingBalance = getTotalOutstandingBalance(consolidatedDebts);

  const renderListItem = (
    label: string,
    value: string | number,
    updated?: React.ReactNode,
    original?: React.ReactNode
  ) => (
    <li className="flex justify-between my-2">
      <span>
        {label} {updated}
      </span>
      <div className="flex flex-col items-end">
        <span className="font-medium">{value}</span>
        {original}
      </div>
    </li>
  );

  return (
    <>
      <div className="bg-grey-1 p-4">
        <p>As of today your current Harmoney loan balance has changed, so we&rsquo;ve updated your quote.</p>
        <strong className="font-medium">Debts to consolidate</strong>
        <ul>
          {consolidatedDebts.map(
            ({ id, provider, networthSourceName, outstandingBalance, originalOutstandingBalance, isUpdated }) => (
              <li key={id} className="flex justify-between text-sm mt-2">
                <span>
                  {formatProviderName(provider, '', networthSourceName)}{' '}
                  {isUpdated && <small className="text-grey-4">(Updated)</small>}
                </span>
                <div className="flex flex-col items-end">
                  <span>{formatCurrency(outstandingBalance)}</span>
                  {isUpdated && originalOutstandingBalance && (
                    <small className="text-grey-4 flex flex-col items-end">
                      <span>(Original amount</span>
                      <span>{formatCurrency(originalOutstandingBalance)})</span>
                    </small>
                  )}
                </div>
              </li>
            )
          )}
        </ul>
      </div>

      <ul className="px-4 text-sm">
        {renderListItem(
          'Debts to consolidate',
          formatCurrency(totalOutstandingBalance),
          hasChanged && <small className="text-grey-4">(Updated)</small>
        )}
        {extraLimitLoanPurpose &&
          renderListItem(
            extraLimitLoanPurpose,
            formatCurrency(extraLimitLoanAmount),
            hasExtraLimitLoanAmountUpdated && <small className="text-grey-4">(Updated)</small>,
            hasExtraLimitLoanAmountUpdated && extraLimitOriginalLoanAmount && (
              <small className="text-grey-4 flex flex-col items-end">
                <span>(Original amount</span>
                <span>{formatCurrency(extraLimitOriginalLoanAmount)})</span>
              </small>
            )
          )}
        {renderListItem('Establishment fee', formatCurrency(establishmentFee))}
        <Divider className="text-grey-2" />
        {renderListItem('Total', formatCurrency(totalOutstandingBalance + establishmentFee))}
      </ul>
    </>
  );
};

const RepaymentDetails = ({
  interestRate,
  repaymentAmount,
  repaymentFrequency,
  termInMonths,
  isRepaymentAmountUpdated,
}: {
  interestRate: number;
  repaymentAmount: number;
  repaymentFrequency: RepaymentFrequencyEnum;
  termInMonths: number;
  isRepaymentAmountUpdated: boolean;
}) => {
  const renderDetail = (label: string, value: string | number, extra?: React.ReactNode) => (
    <li className="flex justify-between my-2">
      <span>{label}</span>
      <span className="text-base font-medium">{value}</span>
    </li>
  );

  return (
    <ul className="px-4 py-2 text-sm ">
      {renderDetail('Your interest rate', `${interestRate}% p.a.`)}
      {renderDetail(
        'Your repayments',
        `${formatCurrency(repaymentAmount)}/${formatFrequency(repaymentFrequency)}`,
        isRepaymentAmountUpdated && <small className="text-grey-4">(Updated)</small>
      )}
      {renderDetail('Your terms', `${termInMonths / 12} years`)}
    </ul>
  );
};

export const LoanBalanceAffordableModal = ({
  quoteDetails,
  isOpen,
  onModalOpen,
  onContinue,
}: LoanBalanceAffordableModalProps) => {
  const {
    consolidatedDebts,
    interestRate,
    establishmentFee,
    repaymentAmount,
    repaymentFrequency,
    isRepaymentAmountUpdated,
    termInMonths,
    hasChanged,
    extraLimitLoanPurpose,
    hasExtraLimitLoanAmountUpdated,
    extraLimitLoanAmount,
    extraLimitOriginalLoanAmount,
  } = quoteDetails;

  return (
    <>
      <Dialog
        title="Your quote has been updated"
        open={isOpen}
        onOpenChange={onModalOpen}
        modal
        contentClassName="max-h-[90vh] overflow-y-auto"
      >
        <DebtsToConsolidate
          consolidatedDebts={consolidatedDebts}
          establishmentFee={establishmentFee}
          hasChanged={hasChanged}
          extraLimitLoanPurpose={extraLimitLoanPurpose}
          hasExtraLimitLoanAmountUpdated={hasExtraLimitLoanAmountUpdated}
          extraLimitLoanAmount={extraLimitLoanAmount}
          extraLimitOriginalLoanAmount={extraLimitOriginalLoanAmount}
        />
        <Divider className="text-grey-2" />
        <RepaymentDetails
          interestRate={interestRate}
          repaymentAmount={repaymentAmount}
          repaymentFrequency={repaymentFrequency}
          termInMonths={termInMonths}
          isRepaymentAmountUpdated={isRepaymentAmountUpdated}
        />
        <Divider className="text-grey-2" />
        <div className="p-4">
          <Button
            variant="primary"
            type="submit"
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
            className="mb-4 md:mb-0"
            isFullWidth
            onClick={onContinue}
          >
            Continue
          </Button>
        </div>
      </Dialog>
    </>
  );
};

import { SaveUserActivityDto } from '@harmoney/api-interfaces';
import { UserActivity } from '@prisma/client';

import { emptySplitApi } from './empty-api';

export const userActivityApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    saveUserActivity: builder.mutation<UserActivity, SaveUserActivityDto>({
      query: (data) => ({
        url: `/user-activity`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['RepeatEligibility'],
    }),
  }),
});

export const { useSaveUserActivityMutation } = userActivityApi;

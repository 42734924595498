import Image from 'next/image';
import { useEffect, useMemo, useState } from 'react';
import { AssetDto, FORM_KEY, NetworthSourceEnum, NetworthSourceKind } from '@harmoney/api-interfaces';
import { FinancialConfirmationSectionEnum, useFinancialConfirm, useFriendlyURL } from '@harmoney/hooks';
import { useGetFinancialSummaryQuery, useGetNetworthSourcesQuery, useGetVariablesQuery } from '@harmoney/redux';
import {
  ArrowCircleRightIcon,
  Button,
  CollapsibleHeader,
  Divider,
  Form,
  Label,
  useForm,
} from '@harmoney/ui-design-system';
import { capitalizeTitle } from '@harmoney/ui-utils';
import { useScrollIntoView } from '@mantine/hooks';
import { isEmpty, kebabCase, some } from 'lodash';

import { CommonProps } from '../../common-props';

import { AssetItem } from './AssetItem';
import { getDefaultValues, rootAssetSchema } from './form-config';
import { AssetsOrderMap, correctOrderOfAssetTypes, getAssetDetailsByNetWorthSourceId } from './utils';

const ASSET_NO_ID = NetworthSourceEnum.ASSET_NO_ID;

export const UpdateAsset = ({ taskId, completeTaskWithData, taskFriendlyURL }: CommonProps) => {
  useFriendlyURL(taskFriendlyURL);
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({ offset: 150 });
  const [elementId, setElementId] = useState(null);
  const { data: variables } = useGetVariablesQuery(taskId);
  const { data: financialSummary } = useGetFinancialSummaryQuery(
    { id: variables?.financialProfileId?.toString(), loanApplicationId: variables?.loanApplicationId?.toString() },
    {
      skip: !variables?.loanApplicationId?.toString() || !variables?.financialProfileId?.toString(),
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: assetTypes } = useGetNetworthSourcesQuery(NetworthSourceKind.ASSET);

  const [otherAssetOpen, setOtherAssetOpen] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState({ update: false, cancel: false });
  const { confirmSection } = useFinancialConfirm();
  const [assetDisplayOrder, setAssetDisplayOrder] = useState<number[]>([]);

  const prefilledData = useMemo(() => {
    if (financialSummary?.assets) {
      return financialSummary.assets;
    }
    return [];
  }, [financialSummary]);

  const NETWORTH_CODE_NO_ASSETS = 'no_assets';
  const form = useForm({
    mode: 'onTouched',
    schema: rootAssetSchema,
    defaultValues: {
      assets: {},
    },
  });

  const {
    watch,
    setValue,
    getValues,
    resetField,
    formState: { errors },
  } = form;

  const watchForm = watch();
  const watchAssets = watchForm.assets;

  const handleSubmit = async (data) => {
    try {
      const hasAssets = some(data.assets, (item) => item.length > 0);
      if (!hasAssets) {
        setFormTouched(true);
        setIsSubmitting({ update: false, cancel: false });
        return;
      }

      const transformedData = Object.entries(data.assets).flatMap(([key, value]) => {
        if (key === NETWORTH_CODE_NO_ASSETS) {
          return { ...value[0], networthSourceId: ASSET_NO_ID, declaredAmount: null };
        }
        return value;
      });
      // find the ones that exist in prefilledData but does not exist in transformedData and set needDelete to true
      const deleteData = prefilledData
        .filter((item) => !transformedData.find((newItem) => newItem.id === item.id))
        .map((item) => ({ ...item, needDelete: true }));
      transformedData.push(...deleteData);

      setIsSubmitting({ update: true, cancel: false });

      await completeTaskWithData({
        taskId,
        formKey: FORM_KEY.ASSET_UPDATE,
        formData: { assets: transformedData },
      });
      confirmSection(FinancialConfirmationSectionEnum.Assets);
    } catch (error) {
      console.error('Update asset error', error);
      setIsSubmitting({ update: false, cancel: false });
    }
  };

  const disableNoAssetOption = useMemo(() => {
    return Object.entries(watchAssets).flat().length > 0 || watchAssets[ASSET_NO_ID]?.length > 0;
  }, [Object.entries(watchAssets), watchAssets[ASSET_NO_ID]]);

  const handleCancel = async () => {
    try {
      setIsSubmitting({ update: false, cancel: true });
      await completeTaskWithData({ taskId });
    } catch (error) {
      console.error(`Failed to complete task [taskId: ${taskId}], error: ${error}`);
      setIsSubmitting({ update: false, cancel: false });
    }
  };

  const handleAddAsset = (networthSourceId: NetworthSourceEnum) => {
    setOtherAssetOpen(false);
    const assetItems = getValues(`assets.${networthSourceId}`);
    let newAssetItem;
    if (networthSourceId === NetworthSourceEnum.ASSET_NO_ID) {
      newAssetItem = {
        networthSourceId: NetworthSourceEnum.ASSET_NO_ID as NetworthSourceEnum.ASSET_NO_ID,
        declaredAmount: null,
      };
      setValue(`assets.${networthSourceId}`, [newAssetItem]);
      setAssetDisplayOrder([...assetDisplayOrder, networthSourceId]);
      return;
    }
    newAssetItem = getDefaultValues(networthSourceId);
    if (assetItems && assetItems.length > 0) {
      setValue(`assets.${networthSourceId}`, [...assetItems, newAssetItem]);
    } else {
      setValue(`assets.${networthSourceId}`, [newAssetItem]);
      setAssetDisplayOrder([...assetDisplayOrder, networthSourceId]);
    }
    setElementId(newAssetItem.elementId);
  };

  const handleRemoveAsset = (networthSourceId: NetworthSourceEnum, index: number) => {
    const assetItems = getValues(`assets.${networthSourceId}`);
    const updatedAssetItems = assetItems.filter((_, i) => i !== index);
    if (isEmpty(updatedAssetItems)) {
      const assets = getValues('assets');
      delete assets[networthSourceId];
      setValue('assets', assets);
      setAssetDisplayOrder(assetDisplayOrder.filter((id) => id !== networthSourceId));
    } else {
      resetField(`assets.${networthSourceId}`);
      setValue(`assets.${networthSourceId}`, updatedAssetItems);
    }
  };

  useEffect(() => {
    if (prefilledData) {
      const assets = {};
      const order = [...assetDisplayOrder];
      prefilledData.forEach((item) => {
        const asset = { ...item, elementId: item.id };
        if (asset.networthSourceId === ASSET_NO_ID) {
          assets[ASSET_NO_ID] = [asset];
        } else {
          if (assets[asset.networthSourceId]) {
            assets[asset.networthSourceId].push(asset);
          } else {
            assets[asset.networthSourceId] = [asset];
          }
        }
        // if the asset is not in the order, add it
        if (!order.includes(asset.networthSourceId)) {
          order.push(asset.networthSourceId);
        }
      });
      setValue('assets', assets);
      setAssetDisplayOrder(order);
    }
  }, [financialSummary, setValue, prefilledData, setAssetDisplayOrder]);

  useEffect(() => {
    if (targetRef) {
      scrollIntoView();
    }
  }, [scrollIntoView, targetRef, elementId]);

  useEffect(() => {
    if (!isEmpty(errors) && !isEmpty(errors?.assets)) {
      const errorFields = Object.keys(errors.assets);
      const firstIncomeIdWithError = assetDisplayOrder.find((assetId) => errorFields.includes(String(assetId)));
      const erroredElementIdx = errors.assets[firstIncomeIdWithError]?.findIndex(
        (item) => item && typeof item === 'object' && Object.keys(item).length > 0
      );

      const erroredAssetData = watchAssets[firstIncomeIdWithError]?.[erroredElementIdx];

      setElementId(erroredAssetData?.id || erroredAssetData?.elementId);

      if (targetRef) {
        scrollIntoView();
      }
    }
  }, [errors, scrollIntoView, assetDisplayOrder, targetRef, watchAssets]);

  return (
    <div>
      <h1>
        What <span className="text-primary">assets</span> do you own?
      </h1>
      <Form form={form} onSubmit={handleSubmit}>
        {watchAssets &&
          assetDisplayOrder.map((networthSourceId) => {
            const assets = watchAssets[networthSourceId];
            if (!assetTypes || isEmpty(assets)) {
              return null;
            }

            return (
              <CollapsibleHeader
                key={networthSourceId}
                open={true}
                title={getAssetDetailsByNetWorthSourceId(assetTypes, Number(networthSourceId)).name}
                code={getAssetDetailsByNetWorthSourceId(assetTypes, Number(networthSourceId)).code}
              >
                {(assets as AssetDto[]).map((asset, index) => (
                  <div
                    key={`asset-${networthSourceId}-${index}`}
                    ref={asset['elementId'] === elementId ? targetRef : null}
                  >
                    <AssetItem
                      networthSourceId={Number(networthSourceId)}
                      index={index}
                      isNoAsset={asset.networthSourceId === ASSET_NO_ID ? true : false}
                      removeItem={handleRemoveAsset}
                      form={form}
                    />
                    {(assets as AssetDto[]).length - 1 === index && Number(networthSourceId) !== ASSET_NO_ID && (
                      <div className="text-center my-4 px-4">
                        <Button
                          onClick={() => handleAddAsset(Number(networthSourceId))}
                          size="medium"
                          variant="outline-secondary"
                        >
                          + Another{' '}
                          {assetTypes && getAssetDetailsByNetWorthSourceId(assetTypes, Number(networthSourceId))?.name}
                        </Button>
                      </div>
                    )}
                  </div>
                ))}
              </CollapsibleHeader>
            );
          })}

        <CollapsibleHeader
          code="More Assets"
          title={
            !isEmpty(watchAssets) && !otherAssetOpen
              ? 'Do you have any other assets?'
              : otherAssetOpen
                ? 'Select an asset'
                : 'Select the assets that you own'
          }
          open={otherAssetOpen}
          chevron
          onCollapseChange={() => {
            setOtherAssetOpen(!otherAssetOpen);
            setFormTouched(false);
          }}
          valid={formTouched}
          disabled={watchAssets[ASSET_NO_ID]?.length > 0}
        >
          {assetTypes?.length > 0 &&
            correctOrderOfAssetTypes(assetTypes, AssetsOrderMap)?.map((networthSource) => (
              <div key={networthSource.id}>
                <button
                  type="button"
                  onClick={() => handleAddAsset(networthSource.id)}
                  className={`hover:bg-grey-1 flex w-full cursor-pointer items-center justify-between space-x-4 bg-white p-4 ${
                    networthSource.id === ASSET_NO_ID && disableNoAssetOption
                      ? 'bg-grey-1 hover:bg-grey-1 cursor-not-allowed'
                      : 'bg-white  cursor-pointer '
                  }`}
                  disabled={networthSource.id === ASSET_NO_ID && disableNoAssetOption}
                >
                  <div
                    key={`asset-${networthSource.id}`}
                    className={`flex cursor-pointer ${networthSource.id === ASSET_NO_ID && disableNoAssetOption ? 'cursor-not-allowed' : ''}`}
                  >
                    <Image
                      src={`/assets/images/${kebabCase(networthSource.code)}.svg`}
                      className="grayscale"
                      alt={`${networthSource.code}_image`}
                      width={24}
                      height={24}
                    />
                    <Label
                      className={`ml-2 ${networthSource.id === ASSET_NO_ID && disableNoAssetOption ? '!text-grey-4' : ''}`}
                    >
                      {capitalizeTitle(networthSource.name)}
                    </Label>
                    <Divider className="text-grey-2 m-0 p-0" />
                  </div>
                </button>
              </div>
            ))}
        </CollapsibleHeader>

        {formTouched && <p className="text-error">Please select an option</p>}

        <div className="flex flex-col gap-2 items-center">
          <Button
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
            variant="primary"
            type="submit"
            className="mt-6"
            hasShadow
            isLoading={isSubmitting.update}
            disabled={isSubmitting.cancel}
          >
            Save
          </Button>
          <Button
            variant="tertiary"
            onClick={handleCancel}
            isLoading={isSubmitting.cancel}
            disabled={isSubmitting.update}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

import { ChangeEvent } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { Card, ControlledInput, ToggleGroup } from '@harmoney/ui-design-system';
import { toggleYesNoOptions } from '@harmoney/ui-utils';

import { FormSchema } from '../form-config';

interface Props {
  register: UseFormRegister<FormSchema>;
  hasDependants: boolean;
  onFormChange: (e: ChangeEvent<HTMLButtonElement | HTMLSelectElement>) => void;
}

export const DependantsCard = ({ register, hasDependants, onFormChange }: Props) => (
  <Card>
    <ToggleGroup
      {...register('dependants.hasDependants', { onChange: onFormChange })}
      label="Do you have children or dependants?"
      options={toggleYesNoOptions}
    />
    {hasDependants && (
      <ControlledInput
        {...register('dependants.numberOfDependants')}
        type="number"
        label="How many children or dependants do you have?"
        className="mt-4"
      />
    )}
  </Card>
);

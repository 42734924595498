import { useCallback, useState } from 'react';
import { LoanPurposeQuestionDto } from '@harmoney/api-interfaces';
import { useFriendlyURL, useTransformLoanPurposes } from '@harmoney/hooks';
import { useGetLoanPurposesForPersonalLoanQuery, useGetUserQuery } from '@harmoney/redux';
import {
  AmountInput,
  ArrowCircleRightIcon,
  Button,
  Card,
  Checkbox,
  Form,
  Label,
  RadioSelectSelector,
  ToggleGroup,
  useForm,
} from '@harmoney/ui-design-system';
import { formatCurrency, LoanProductName } from '@harmoney/utilities';
import { LoanPurposeQuestionType } from '@prisma/client';
import { z } from 'zod';

import { CommonProps } from '../../common-props';

import { createFormSchema, defaultValues } from './form-config';

export function LoanPurposeForRepeat({ taskId, completeTaskWithData, taskFriendlyURL }: CommonProps) {
  const minimumLoanAmount = 2000,
    maximumLoanAmount = 70000;
  const { data: loanPurposes, isLoading: isLoanPurposesLoading } = useGetLoanPurposesForPersonalLoanQuery(null);
  const { primaryLoanPurposes, secondaryLoanPurposes } = useTransformLoanPurposes(loanPurposes);

  const debtConLoanPurpose = primaryLoanPurposes?.find((loanPurpose) => loanPurpose.label === 'Debt Consolidation');
  const formSchema = createFormSchema(minimumLoanAmount, maximumLoanAmount, debtConLoanPurpose?.value);
  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues,
  });
  const {
    watch,
    resetField,
    register,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;
  const watchLoanPurpose = watch('loanPurpose');
  const [questions, setQuestions] = useState<LoanPurposeQuestionDto[]>([]);
  const { data: userData } = useGetUserQuery();

  const handleSelectedLoanPurpose = useCallback(() => {
    if (!watchLoanPurpose.primary) return;
    const { questions } = loanPurposes.find((loanPurpose) => loanPurpose.id === watchLoanPurpose.primary);
    if (questions.length > 0) {
      setQuestions(questions);
    } else {
      setQuestions([]);
      resetField('questions', { defaultValue: [] });
    }
  }, [loanPurposes, resetField, watchLoanPurpose.primary]);

  const handleSubmit = async (data: z.infer<typeof formSchema>) => {
    const loanPurposeId = watchLoanPurpose.primary;
    const loanPurposeData = [
      {
        id: loanPurposeId,
        answers: data.questions.map(({ optionId }, index) => ({
          questionId: questions[index].id,
          optionId: typeof optionId === 'boolean' ? null : optionId,
        })),
        requestedAmount: data.amount,
      },
    ];

    let loanProductName = '';

    if (loanPurposeId === debtConLoanPurpose?.value) {
      loanProductName = LoanProductName.DEBT_CONSOLIDATION;
      await completeTaskWithData({
        taskId,
        variables: { loanProductName, loanPurposes: loanPurposeData },
      });
    } else {
      loanProductName = LoanProductName.PERSONAL_LOAN;
      await completeTaskWithData({
        taskId,
        variables: { requestedAmount: data.amount, loanProductName, loanPurposes: loanPurposeData },
      });
    }
  };

  useFriendlyURL(taskFriendlyURL);

  const isSelectedLoanPurposeDebtCon = watchLoanPurpose.primary === debtConLoanPurpose?.value;
  const hasSelectedALoanPurpose = Boolean(watchLoanPurpose.primary);

  return (
    <>
      <h1>
        Hi <span className="text-primary">{userData?.preferredName ?? ''}</span>, tell us more
      </h1>

      <Form form={form} onSubmit={handleSubmit}>
        {!isLoanPurposesLoading && primaryLoanPurposes.length > 0 && secondaryLoanPurposes.length > 0 && (
          <Card>
            <Label className="mb-2">What do you need a loan for?</Label>
            <RadioSelectSelector
              name="loanPurpose"
              radioOptions={primaryLoanPurposes}
              selectOptions={secondaryLoanPurposes}
              onChange={handleSelectedLoanPurpose}
            />
          </Card>
        )}

        {questions.length > 0 &&
          questions.map(({ id, questionType, name, options }, index) => (
            <Card key={id}>
              {questionType === LoanPurposeQuestionType.YESNO && (
                <>
                  <Label className="mb-2">{name}</Label>
                  <ToggleGroup
                    name={`questions.${index}.optionId`}
                    options={options.map((option) => ({ label: option.name, value: option.id }))}
                  />
                </>
              )}
              {questionType === LoanPurposeQuestionType.CHECK && (
                <Checkbox name={`questions.${index}.optionId`} label={name} />
              )}
            </Card>
          ))}

        {hasSelectedALoanPurpose && !isSelectedLoanPurposeDebtCon && (
          <Card>
            <Label className="mb-2">How much would you like to borrow?</Label>
            <AmountInput
              {...register('amount')}
              maxLength={6}
              validationMessage={`An amount between ${formatCurrency(
                minimumLoanAmount
              )} and ${formatCurrency(maximumLoanAmount)}`}
            />
          </Card>
        )}

        <Button
          type="submit"
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          isLoading={isSubmitting || isSubmitSuccessful}
          hasShadow
        >
          Continue
        </Button>
      </Form>
    </>
  );
}

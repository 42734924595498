import { generateIcon } from '../generateIcon';

/**
 * @deprecated Check if it can be replaced with Iconify. If not, add it to icon.json.
 * Refer to icon document for details: https://63be80b51f526a24fc7865c5-tslqcdnosl.chromatic.com/?path=/docs/docs-icons--docs
 */
export const GraphIcon = generateIcon({
  viewBox: '0 0 19 20',
  path: (
    <>
      <path
        d="M3.5 19.4512C3.22386 19.4512 3 19.2273 3 18.9512L3 3.49986C3 3.22372 3.22385 2.99986 3.5 2.99986L5.98067 2.99986C6.25682 2.99986 6.48067 3.22372 6.48067 3.49986L6.48068 18.9512C6.48068 19.2273 6.25682 19.4512 5.98068 19.4512L3.5 19.4512Z"
        fill="white"
      />
      <path
        d="M8.75977 19.4512C8.48362 19.4512 8.25977 19.2273 8.25977 18.9512L8.25976 7.50932C8.25976 7.23318 8.48362 7.00932 8.75976 7.00932L11.2404 7.00932C11.5166 7.00932 11.7404 7.23318 11.7404 7.50932L11.7404 18.9512C11.7404 19.2273 11.5166 19.4512 11.2404 19.4512L8.75977 19.4512Z"
        fill="white"
      />
      <path
        d="M14.0195 19.4512C13.7434 19.4512 13.5195 19.2273 13.5195 18.9512L13.5195 9.915C13.5195 9.63885 13.7434 9.415 14.0195 9.415L16.5002 9.415C16.7763 9.415 17.0002 9.63885 17.0002 9.915L17.0002 18.9512C17.0002 19.2273 16.7764 19.4512 16.5002 19.4512L14.0195 19.4512Z"
        fill="white"
      />
    </>
  ),
});

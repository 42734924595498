export enum LoanPurposeNameEnum {
  DEBT_CONSOLIDATION = 'debt consolidation',
  GROWING_MY_BUSINESS = 'growing my business',
  WEDDING = 'wedding',
  EDUCATION = 'education',
  LEGAL_FEES = 'legal fees',
  CLEARING_OVERDRAFT = 'clearing overdraft',
  TAX_BILL = 'tax bill',
  LOAN_TO_FAMILY_MEMBER = 'loan to family member',
  COMPUTER = 'computer',
  BOAT = 'boat',
  CARAVAN = 'caravan',
  SOMETHING_ELSE = 'something else',
  IMPROVING_MY_HOME = 'improving my home',
  GOING_ON_HOLIDAY = 'going on holiday',
  USED_VEHICLE = 'used vehicle',
  NEW_VEHICLE = 'new vehicle',
  HOUSEHOLD_ITEMS = 'household items',
  MEDICAL_EXPENSES = 'medical expenses',
  FUNERAL_EXPENSES = 'funeral expenses',
}

export enum SelectedLoanPurposeEnum {
  IMPROVING_MY_HOME = 'improving my home',
  USED_VEHICLE = 'used vehicle',
  GOING_ON_HOLIDAY = 'going on holiday',
  DEBT_CONSOLIDATION = 'debt consolidation',
  HOUSEHOLD_ITEMS = 'household items',
  NEW_VEHICLE = 'new vehicle',
  MEDICAL_EXPENSES = 'medical expenses',
  WEDDING = 'wedding',
  EDUCATION = 'education',
  GROWING_MY_BUSINESS = 'growing my business',
  LEGAL_FEES = 'legal fees',
  CARAVAN = 'caravan',
  COMPUTER = 'computer',
  FUNERAL_EXPENSES = 'funeral expenses',
  TAX_BILL = 'tax bill',
  BOAT = 'boat',
}

export enum LoanPurposeDataRobotEnum {
  'debt consolidation' = 'DEBT_CONSOLIDATION',
  'growing my business' = 'GROWING_MY_BUSINESS',
  'wedding' = 'WEDDING',
  'education' = 'EDUCATION',
  'legal fees' = 'LEGAL_FEES',
  'clearing overdraft' = 'CLEARING_OVERDRAFT',
  'tax bill' = 'TAX_BILL',
  'loan to family member' = 'LOAN_TO_FAMILY_MEMBER',
  'computer' = 'COMPUTER',
  'boat' = 'BOAT',
  'caravan' = 'CARAVAN',
  'something else' = 'SOMETHING_ELSE',
  'improving my home' = 'IMPROVING_MY_HOME',
  'going on holiday' = 'GOING_ON_HOLIDAY',
  'used vehicle' = 'USED_VEHICLE',
  'new vehicle' = 'NEW_VEHICLE',
  'household items' = 'HOUSEHOLD_ITEMS',
  'medical expenses' = 'MEDICAL_EXPENSES',
  'funeral expenses' = 'FUNERAL_EXPENSES',
}

export const loanPurposeNameMapper = {
  'debt consolidation': 'Debt Consolidation',
  'growing my business': 'Business Cash Flow',
  wedding: 'Wedding Expenses',
  education: 'Education Expenses',
  'legal fees': 'Legal Fees',
  'clearing overdraft': 'Clear Overdraft',
  'tax bill': 'Tax Bill',
  'loan to family member': 'Loan to Family Member',
  computer: 'Computer',
  boat: 'Purchase Boat',
  caravan: 'Purchase Caravan',
  'something else': 'Other',
  'improving my home': 'Home Improvements',
  'going on holiday': 'Holiday Expenses',
  'used vehicle': 'Purchase Used Vehicle',
  'new vehicle': 'Purchase New Vehicle',
  'household items': 'Household Items',
  'medical expenses': 'Medical Expenses',
  'funeral expenses': 'Funeral Expenses',
};

export enum LoanPurposeDisplayNameEnum {
  'debt consolidation' = 'Debt Consolidation',
  'personal loan' = 'Personal Loan',
  'growing my business' = 'Growing my business',
  'wedding' = 'Wedding',
  'education' = 'Education',
  'legal fees' = 'Legal fees',
  'clearing overdraft' = 'Clearing overdraft',
  'tax bill' = 'Tax bill',
  'loan to family member' = 'Loan to family member',
  'computer' = 'Tech',
  'boat' = 'Boat',
  'caravan' = 'Caravan',
  'something else' = 'Something else',
  'car loan' = 'Car',
  'improving my home' = 'Home renovations',
  'going on holiday' = 'Holiday',
  'used vehicle' = 'Used vehicle',
  'new vehicle' = 'New vehicle',
  'household items' = 'Household items',
  'medical expenses' = 'Medical',
  'funeral expenses' = 'Funeral',
}

import { useMemo } from 'react';
import { RepeatEligibilityCheckResultDto } from '@harmoney/api-interfaces';

export const useRepeatEligibility = (
  isEligibleForUnutilisedLimit: boolean,
  repeatEligibility: RepeatEligibilityCheckResultDto
): RepeatEligibilityCheckResultDto => {
  return useMemo(() => {
    if (isEligibleForUnutilisedLimit === false) {
      return repeatEligibility;
    }
    return { isEligible: false, showBorrowMoreCard: false };
  }, [isEligibleForUnutilisedLimit, repeatEligibility]);
};

import { GoBackToEnum } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

type VariablesType = {
  goBackTo: GoBackToEnum;
  [key: string]: any;
};

const editApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    goBack: builder.mutation<boolean, { taskId: string; variables: VariablesType }>({
      query: ({ taskId, variables }) => ({
        url: `/edit/${taskId}`,
        method: 'PATCH',
        body: { variables: JSON.stringify(variables) },
      }),
    }),
  }),
});

export const { useGoBackMutation } = editApi;

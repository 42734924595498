import React, { useEffect, useMemo, useState } from 'react';
import { CompleteTaskDto, FORM_KEY } from '@harmoney/api-interfaces';
import { useFriendlyURL, UserInformationConfirmationSectionEnum, useUserInformationConfirm } from '@harmoney/hooks';
import { useAppSelector, useGetUserQuery } from '@harmoney/redux';
import {
  Alert,
  ArrowCircleRightIcon,
  Button,
  ControlledInput,
  Form,
  Select,
  TextLink,
  ToggleGroup,
  useForm,
} from '@harmoney/ui-design-system';
import { openChatWindow, relationshipStatusOptions, toggleResidencyStatusOptions } from '@harmoney/ui-utils';
import { z } from 'zod';

import { CommonProps } from '../../common-props';
import { RegionalText } from '../../fulfilment';
import { InformationSummary, InfoSummaryField } from '../UserInformationSummary/components';

import { createFormSchema } from './form-config';

export function UpdateUserInformation({ taskId, completeTaskWithData, taskFriendlyURL }: CommonProps) {
  const [isSubmitting, setIsSubmitting] = useState({ update: false, cancel: false });
  const { confirmSection } = useUserInformationConfirm();
  const token = useAppSelector((state) => state.accessToken.value);
  const { data: userData } = useGetUserQuery(null, { skip: !token });

  const { prefix, phoneNumber, branch } = useMemo(() => {
    const branch = userData?.branch;
    const prefix = RegionalText[branch]?.countryCode;
    const phoneNumber = userData?.mobilePhoneNumber?.slice(3);
    return { prefix, phoneNumber, branch };
  }, [userData]);

  const formSchema = createFormSchema({ branch, prefix, RegionalText });
  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
  });
  const { reset, register } = form;

  useEffect(() => {
    reset({
      residencyStatus: userData?.residencyStatus,
      relationshipStatus: userData?.relationshipStatus,
      mobilePhoneNumber: phoneNumber,
    });
  }, [userData, phoneNumber, reset]);

  const handleSave = async (formValue: z.infer<typeof formSchema>) => {
    try {
      setIsSubmitting({ update: true, cancel: false });
      const completeTaskDto: CompleteTaskDto = {
        taskId,
        formKey: FORM_KEY.USER_INFORMATION_UPDATE,
        formData: {
          relationshipStatus: formValue.relationshipStatus,
          residencyStatus: formValue.residencyStatus,
          mobilePhoneNumber: formValue.mobilePhoneNumber,
        },
      };
      await completeTaskWithData(completeTaskDto);
      confirmSection(UserInformationConfirmationSectionEnum.UserInformation);
    } catch (e) {
      console.error('Failed to complete task [taskId: %s, formKey: %s]', taskId, FORM_KEY.USER_INFORMATION_UPDATE);
      setIsSubmitting({ update: false, cancel: false });
    }
  };

  const handleCancel = async () => {
    try {
      setIsSubmitting({ update: false, cancel: true });
      await completeTaskWithData({ taskId });
    } catch (e) {
      console.error('Failed to complete task [taskId: %s]', taskId);
      setIsSubmitting({ update: false, cancel: false });
    }
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      <h1>
        Update your <span className="text-primary">information</span>
      </h1>
      <Form form={form} onSubmit={handleSave}>
        <InformationSummary title="Personal">
          <ToggleGroup
            {...register('residencyStatus')}
            label="Australian citizen or permanent resident"
            options={toggleResidencyStatusOptions(branch)}
            className="mb-4"
          />
          <Select
            {...register('relationshipStatus')}
            label="Your current relationship status"
            options={relationshipStatusOptions}
            value={userData?.relationshipStatus}
          />
        </InformationSummary>
        <InformationSummary title="Contact" showDivider>
          <ControlledInput
            {...register('mobilePhoneNumber')}
            label={`${RegionalText[branch]?.countryPhoneLabel} phone number`}
            type="number"
            isNumeric
            prefix={RegionalText[branch]?.countryCode}
          />
          <div>
            <InfoSummaryField label="Email" value={userData?.email} />
            <Alert variant="info" className="mb-0 mt-4">
              Need to update your email?
              <br />
              <span className="text-secondary cursor-pointer" onClick={() => openChatWindow()}>
                Chat with us
              </span>{' '}
              or call <TextLink href="tel:1300042766">1300 042 766</TextLink> to get it updated.
            </Alert>
          </div>
        </InformationSummary>
        <div className="flex flex-col gap-6 items-center">
          <Button
            type="submit"
            alignIcon="end"
            variant="primary"
            icon={<ArrowCircleRightIcon size="large" />}
            isLoading={isSubmitting.update}
            disabled={isSubmitting.cancel}
          >
            Save
          </Button>
          <Button
            variant="tertiary"
            onClick={handleCancel}
            isLoading={isSubmitting.cancel}
            disabled={isSubmitting.update}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </>
  );
}

export enum LoanProductName {
  PERSONAL_LOAN = 'Personal loan',
  DEBT_CONSOLIDATION = 'Debt consolidation',
}

export function isPersonalLoan(name: string): boolean {
  return name?.toUpperCase() === LoanProductName.PERSONAL_LOAN?.toUpperCase();
}

export function isDebtCon(name: string): boolean {
  return name?.toUpperCase() === LoanProductName.DEBT_CONSOLIDATION?.toUpperCase();
}

export function isMainProcess(name: string): boolean {
  const LoanProductNames: string[] = Object.values<string>(LoanProductName);
  return LoanProductNames.includes(name);
}

export enum DecisionMetricsEnum {
  ATM_CREDITS_MONTHLY_ONCE_OFF = 'DM107',
  ATM_DEBITS_MONTHLY_ONCE_OFF = 'DM108',
  AUTOMOTIVE_MONTHLY = 'DM102',
  BENEFITS_MONTHLY = 'DM038',
  BUY_NOW_PAY_LATER_MONTHLY = 'DM085',
  CENTRELINK_MONTHLY = 'DM002',
  CHILD_CARE_MONTHLY = 'DM075',
  CHILD_SUPPORT_INCOME_MONTHLY = 'DM103',
  CHILD_SUPPORT_PAYMENTS_MONTHLY = 'DM104',
  CHILDRENS_RETAIL = 'DM082',
  COLLECTION_AND_CONSOLIDATION_MONTHLY = 'DM014',
  CREDIT_CARD_REPAYMENTS_DEBITS_ONLY_MONTHLY = 'DM109',
  CREDIT_CARD_REPAYMENTS_MONTHLY = 'DM015',
  DAYS_SINCE_LAST_SALARY_PAYMENT = 'LT007',
  DEBT_COLLECTION_MONTHLY = 'DM018',
  DEBT_CONSOLIDATION_MONTHLY = 'DM019',
  DEPARTMENT_STORES_MONTHLY = 'DM053',
  DINING_OUT_MONTHLY = 'DM058',
  DISHONOURS_MONTHLY = 'DM020',
  DONATIONS = 'DM123',
  EDUCATION_AND_CHILDCARE_MONTHLY = 'DM040',
  EDUCATION_MONTHLY = 'DM076',
  EFTPOS_TERMINALS_MONTHLY = 'DM021',
  EMPLOYER = 'LT005',
  ENTERTAINMENT_MONTHLY = 'DM057',
  EXPECTED_PAY_DATE_FOR_LARGEST_INCOME_SOURCE = 'DM008',
  FEES_MONTHLY = 'DM022',
  FINANCIAL_SERVICES_DEBITS = 'DM128',
  GAMBLING_AVERAGE_MONTHLY_SPEND = 'CF013',
  GAMBLING_EXPENDITURE_MONTHLY = 'DM007',
  GAMBLING_PERCENTAGE_OF_INCOME = 'MN008',
  GOVERNMENT_AND_COUNCIL_SERVICES = 'DM084',
  GROCERIES_MONTHLY = 'DM023',
  GYMS_AND_OTHER_MEMBERSHIPS_MONTHLY = 'DM060',
  HEALTH_MONTHLY = 'DM054',
  HOME_IMPROVEMENT_MONTHLY = 'DM056',
  INCOME_FREQUENCY_COUNT = 'CF001',
  INSURANCE_DEBITS_ONLY_MONTHLY = 'DM061',
  INSURANCE_MONTHLY = 'DM024',
  LEISURE_AND_ENTERTAINMENT_MONTHLY = 'TF004',
  NUMBER_OF_DISHONOURS = 'DM004',
  NUMBER_OF_SACC_PROVIDERS = 'DM012',
  ONLINE_RETAIL_AND_SUBSCRIPTION_SERVICES = 'DM083',
  OTHER_CREDITS_MONTHLY = 'DM016',
  OTHER_INCOME = 'DM127',
  OTHER_LOANS_NON_SACC_LOANS_MONTHLY = 'DM041',
  OVERDRAWN_MONTHLY = 'DM027',
  PENSION_MONTHLY = 'TF011',
  PERCENTAGE_OF_INCOME_SPENT_ON_DAY_OF_DEPOSIT = 'DM006',
  PERSONAL_AND_MEDICAL_MONTHLY = 'MY004',
  PERSONAL_CARE_MONTHLY = 'DM052',
  PET_CARE_MONTHLY = 'DM059',
  RATES = 'DM125',
  RENT_EXCLUDING_STRATA = 'DM126',
  RENT_MONTHLY = 'DM028',
  RENT_MONTHLY_DEBITS_ONLY = 'DM098',
  RENTAL_INCOME_MONTHLY = 'DM115',
  RETAIL_MONTHLY = 'DM062',
  SACC_LOAN_REPAYMENTS_LAST_30_DAYS = 'DM010',
  SACC_LOANS_FUNDED_STATEMENT_PERIOD = 'DM009',
  SACC_REPAYMENTS_FOR_ONGOING_LOANS_PREDICTED_NEXT_30_DAYS = 'DM011',
  SCHOOL_FEES = 'TSB004',
  SHORT_TERM_LOANS_MONTHLY = 'DM087',
  STRATA_PAYMENTS = 'DM129',
  SUBSCRIPTION_TV_MONTHLY = 'DM029',
  SUPERANNUATION_MONTHLY = 'DM030',
  TAX_DEBITS_ONLY_MONTHLY = 'DM101',
  TAX_MONTHLY = 'DM031',
  TELECOMMUNICATIONS_MONTHLY = 'DM032',
  THIRD_PARTY_PAYMENT_PROVIDERS = 'DM122',
  TRANSFERS = 'TF008',
  TRANSPORT_MONTHLY = 'DM033',
  TRAVEL_MONTHLY = 'DM055',
  UNCATEGORISED_DEBITS_MONTHLY = 'DM071',
  UTILITIES_MONTHLY = 'DM035',
  VEHICLES_AND_TRANSPORT_MONTHLY = 'TF003',
  WAGES_MONTHLY = 'DM001',
  WAGES_PAID_OUT_MONTHLY = 'DM036',
  WORK_COVER_MONTHLY = 'DM037',
}

import { formatCurrency } from '@harmoney/utilities';

interface QuoteSummaryProps {
  primaryPurpose: string;
  primaryPurposeAmount: number;
  establishmentFee: number;
  totalLoanAmount: number;
  interestRate: number;
  secondaryPurpose?: string;
  secondaryPurposeAmount?: number;
}

export function QuoteSummary({
  primaryPurpose,
  primaryPurposeAmount,
  establishmentFee,
  totalLoanAmount,
  interestRate,
  secondaryPurpose,
  secondaryPurposeAmount,
}: QuoteSummaryProps) {
  return (
    <>
      <ul className="mb-4">
        <li className="mb-2 flex justify-between">
          <span>{primaryPurpose || 'Loan amount'}</span>
          <span>{formatCurrency(primaryPurposeAmount)}</span>
        </li>
        {secondaryPurpose && secondaryPurposeAmount && (
          <li className="mb-2 flex justify-between">
            <span>{secondaryPurpose}</span>
            <span>{formatCurrency(secondaryPurposeAmount)}</span>
          </li>
        )}
        <li className="mb-2 flex justify-between">
          <span>Establishment fee</span>
          <span>{formatCurrency(establishmentFee)}</span>
        </li>
        <li className="border-grey-5 mb-2 flex justify-between border-y py-2 font-bold">
          <span>Loan total</span>
          <span>{formatCurrency(totalLoanAmount)}</span>
        </li>
        <li className="flex justify-between font-bold">
          <span>Your fixed interest rate</span>
          <span>{interestRate}%</span>
        </li>
      </ul>
    </>
  );
}

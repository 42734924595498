import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, DatePicker, DecimalAmountInput } from '@harmoney/ui-design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';

export const ExtraPaymentItem = ({ index, payOffAmount, maturityDate, ...props }) => {
  const { register } = useFormContext();

  const controls = useMemo(() => {
    const controls = [];

    controls.push(
      <div key={index}>
        <DecimalAmountInput
          className="mb-4"
          {...register(`extraPayments[${index}].paymentAmount`)}
          label="Payment amount"
        />
        <DatePicker
          className="mb-4"
          {...register(`extraPayments[${index}].schedulePaymentDate`)}
          label="Schedule payment on"
          placeholderText="DD/MM/YYYY"
          minDate={dayjs().toDate()}
          maxDate={dayjs(maturityDate).toDate()}
          flexiblePopperPlacement
          onKeyDown={(e) => e.preventDefault()}
        />
      </div>
    );

    if (index !== 0) {
      controls.push(
        <span className="w-100 mx-4 mb-3 flex justify-end">
          <Button
            size="medium"
            variant="text"
            onClick={() => props.removeItem(index)}
            className="inline-flex items-center"
          >
            Remove
          </Button>
        </span>
      );
    }

    return controls;
  }, [index, props, register]);

  return controls.length > 0 ? (
    <div
      className={classNames(
        props.isError ? 'border-error' : 'border-grey-2',
        ' border-1 rounded-lg border border-inherit p-6',
        { 'mb-6': index !== props.totalPayments - 1 }
      )}
      key={index}
    >
      {controls}
    </div>
  ) : null;
};

import React from 'react';
import { Alert, ArrowCircleRightIcon, Button } from '@harmoney/ui-design-system';

export type ReferencesStatus = 'processing' | 'success' | 'error' | 'partial-success' | 'partial-processing';

type Props = {
  referencesStatus: ReferencesStatus;
  onClick: () => void;
  isSubmitting?: boolean;
};

export const BankStatementSummaryAction = ({ referencesStatus, onClick, isSubmitting }: Props) => {
  if (referencesStatus === 'processing') {
    return (
      <>
        <Alert variant="info">
          <p className="text-sm">While your bank statements are processing, you can continue.</p>
        </Alert>

        <div className="text-center">
          <Button
            type="submit"
            alignIcon="end"
            variant="primary"
            icon={<ArrowCircleRightIcon size="large" />}
            hasShadow
            onClick={onClick}
            isLoading={isSubmitting}
          >
            Continue
          </Button>
        </div>
      </>
    );
  }

  if (referencesStatus === 'success') {
    return (
      <div className="text-center">
        <Button
          type="submit"
          alignIcon="end"
          variant="primary"
          icon={<ArrowCircleRightIcon size="large" />}
          hasShadow
          onClick={onClick}
          isLoading={isSubmitting}
        >
          Continue
        </Button>
      </div>
    );
  }

  if (referencesStatus === 'partial-processing') {
    return (
      <>
        <Alert variant="info">
          <p className="text-sm">
            Make sure to connect the account where you <strong>receive your income</strong> and resolve any errors when
            possible before continuing.
          </p>
        </Alert>

        <div className="text-center">
          <Button
            type="submit"
            alignIcon="end"
            variant="primary"
            icon={<ArrowCircleRightIcon size="large" />}
            hasShadow
            onClick={onClick}
            isLoading={isSubmitting}
          >
            Continue
          </Button>
        </div>
      </>
    );
  }

  if (referencesStatus === 'partial-success') {
    return (
      <div className="text-center">
        <p className="text-left">
          Before you skip, please make sure that the successfully connected bank statements contain your proof of
          income.
        </p>
        <Button type="submit" variant="text" onClick={onClick} isLoading={isSubmitting}>
          SKIP
        </Button>
      </div>
    );
  }

  if (referencesStatus === 'error') {
    return null;
  }
};

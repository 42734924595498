/* tslint:disable */
/* eslint-disable */
/**
 * Frankie Financial API
 * ------   This API allows developers to integrate the Frankie Financial Compliance Utility into their applications. The API allows:   - Checking name, address, date of birth against national databases   - Validating Australian driver's licences, passports, medicare, visas and other Australian national ID documents   - Validating Australian electricity bills   - Validating NZ driver's licences   - Validating Chinese bank cards and national ID card   - Validating International passports and national ID documents   - PEP, Sanctions, Watchlist and adverse media checking   - Australian visa checks    - Fraud list and fraud background checks   - ID validation and selfie check comparisons.    ------     Industry specific services    - Comparing Australian electricity retailers for a better deal.  ------     KYB specific services    - Query organisation ownership   - Perform KYC & AML checks on shareholders, beneficial owners and office bearers.   - Query credit score and credit reports   - International company searches   - International company profiles    ------   The full version of this documentation along with supplemental articles can be found here:   - https://apidocs.frankiefinancial.com/  ------ Sandbox base URL is:   - https://api.demo.frankiefinancial.io/compliance/v1.2      - All calls are the same as production, only with test data. You can download the latest test data here: https://apidocs.frankiefinancial.com/docs/test-data    - Full Swagger definition, along with test data for playing in the sandbox can be obtained once initial commercial discussions have commenced.    - Production and optional UAT access will be opened up only to those with a signed commercial contract.    ------   Contact us at hello@frankiefinancial.com to speak with a sales rep about issuing a Customer ID and Sandbox api key.
 *
 * OpenAPI spec version: 1.14.0
 * Contact: dev-support@frankiefinancial.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * Indicates the status of a check result as set by a user. - \"UNKNOWN\": The user has not decided so the actual check result applies as normal. - \"TRUE_POSITIVE\": The check result has been acknowledged as correct but the final effect (accept/reject) has not been decided. - \"TRUE_POSITIVE_ACCEPT\": The check result is correct but will be ignored. This is also known as 'whitelisting' - \"TRUE_POSITIVE_REJECT\": The check result is correct and will be used. - \"FALSE_POSITIVE\": The check result is not applicable and will be ignored. - \"STALE\": The check result will become invisible, will not be considered   and will not count towards due diligence requirements.
 * @export
 * @enum {string}
 */
export enum EnumCheckResultManualStatus {
  UNKNOWN = 'UNKNOWN',
  TRUEPOSITIVE = 'TRUE_POSITIVE',
  TRUEPOSITIVEACCEPT = 'TRUE_POSITIVE_ACCEPT',
  TRUEPOSITIVEREJECT = 'TRUE_POSITIVE_REJECT',
  FALSEPOSITIVE = 'FALSE_POSITIVE',
  STALE = 'STALE',
}

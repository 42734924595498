import { AssetAndLiability, NetworthSource } from '@prisma/client';

export enum LiabilityProvider {
  Harmoney = 'Harmoney',
}

export function isHarmoneyDebt(
  debt: Partial<AssetAndLiability> & { networthSource: Partial<NetworthSource> }
): boolean {
  return debt.provider?.startsWith(LiabilityProvider.Harmoney) && debt.networthSource.name === 'personal loan';
}

export function isNonHarmoneyDebt(
  debt: Partial<AssetAndLiability> & { networthSource: Partial<NetworthSource> }
): boolean {
  return !isHarmoneyDebt(debt);
}

export const isHarmoneyStellare2DebtInCreditFile = (organisation: string, bureauAccountId: string) => {
  return ['HARMONEY AUSTRALIA PTY LTD', 'HARMONEY AU'].includes(organisation) && bureauAccountId?.match(/^L\d{11}$/);
};

import { useEffect, useState } from 'react';
import { loanVariationTypeMapper } from '@harmoney/api-interfaces';
import {
  useGetUserByIdQuery,
  useGetUserProfileForAdminQuery,
  useLazyGetAppliedCsvByIdQuery,
  useLazyGetVariationRecordByIdQuery,
} from '@harmoney/redux';
import { Button, CollapsibleCard, IconV2 } from '@harmoney/ui-design-system';
import { DATE_FORMAT } from '@harmoney/ui-utils';
import { LoanVariation } from '@prisma/client';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { downloadCSV } from './utils';

export interface LoanVariationHistoryProps {
  historyData: LoanVariation[];
  sendData?: (data: LoanVariation, fullName: string) => void;
}

const TableRow = ({ tableRow, sendData }) => {
  const { data: userProfileData } = useGetUserProfileForAdminQuery(tableRow.userId as string, {
    skip: !tableRow.userId,
  });
  const [trigger] = useLazyGetAppliedCsvByIdQuery();
  const [refetchVariationRecord] = useLazyGetVariationRecordByIdQuery();
  const { data: userData } = useGetUserByIdQuery(tableRow.userId as string, { skip: !tableRow.userId });

  const [fullName, setFullName] = useState<string>('');

  useEffect(() => {
    if (userProfileData && userProfileData?.firstName) {
      const { firstName, middleName, lastName } = userProfileData;
      const fullNameParts = [firstName, middleName, lastName].filter(
        (part) => typeof part === 'string' && part.trim() !== ''
      );
      if (fullNameParts.length > 0) {
        setFullName(fullNameParts.join(' '));
      }
    } else {
      setFullName(userData?.email);
    }
  }, [userProfileData, userData]);

  const handleDownloadCSV = async () => {
    const csvData = await trigger({
      loanApplicationId: tableRow.loanApplicationId,
      simulationId: tableRow.id,
    }).unwrap();
    const fileName = `${tableRow.type}_${tableRow.loanApplicationId}_${tableRow.completedAt}`;
    if (csvData) {
      downloadCSV(csvData, fileName);
    }
  };

  const handleSendData = async () => {
    const variationData = await refetchVariationRecord(tableRow.id).unwrap();
    sendData(variationData, fullName);
  };

  return (
    <tr className="border-b-2 border-b-grey-1">
      <td className="p-4">
        <Button variant="text" className="font-body font-normal capitalize tracking-normal" onClick={handleSendData}>
          {loanVariationTypeMapper[tableRow.type]}
        </Button>
      </td>
      <td className="p-4">{`${dayjs(tableRow.completedAt).format(DATE_FORMAT)}`}</td>
      <td className="p-4">{fullName}</td>
      <td className="p-4">
        <Button
          variant="text"
          icon={<IconV2 icon="material-symbols:download-rounded" width={24} height={24} />}
          alignIcon="start"
          isFullWidth={true}
          onClick={handleDownloadCSV}
          className="font-body justify-start font-normal capitalize tracking-normal"
        >
          Download amortisation schedule (CSV)
        </Button>
      </td>
    </tr>
  );
};

export const LoanVariationHistory = ({ historyData, sendData }: LoanVariationHistoryProps) => {
  const [showAllRows, setShowAllRows] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <CollapsibleCard title="Variation History" className="mb-32" defaultOpen>
      <div className="overflow-auto max-h-[600px]" id="variation-history">
        <table className="w-full border-collapse bg-white text-sm">
          <thead className="text-left">
            <tr className="border-b-2 border-b-grey-1">
              <th className="p-4">Type</th>
              <th className="p-4">Committed On</th>
              <th className="p-4">Committed By</th>
              <th className="p-4">Amortisation schedule</th>
            </tr>
          </thead>
          <tbody>
            {historyData?.slice(0, 2).map((row, index) => <TableRow key={index} tableRow={row} sendData={sendData} />)}
            {historyData?.length > 2 && (
              <tr className={classNames(open ? 'border-b-2 border-b-grey-1' : '')}>
                <td className="p-4" colSpan={4}>
                  <Button
                    onClick={() => {
                      setShowAllRows(!showAllRows);
                      setOpen(!open);
                    }}
                    variant="text"
                    isFullWidth={true}
                    alignIcon="end"
                    icon={
                      <IconV2
                        icon="ic:round-chevron-right"
                        width={24}
                        className={classNames(open ? '-rotate-90' : 'rotate-90', 'transition-all text-grey-4')}
                      />
                    }
                    className="font-body justify-between font-normal capitalize tracking-normal !text-grey-4 hover:!text-grey-4 active:!text-grey-4 focus-visible:!text-grey-4"
                  >{`${historyData?.length - 2} variations`}</Button>
                </td>
              </tr>
            )}
            {showAllRows &&
              historyData
                ?.slice(2)
                .map((row, index) => <TableRow key={index + 2} tableRow={row} sendData={sendData} />)}
          </tbody>
        </table>
      </div>
    </CollapsibleCard>
  );
};

import { CommonProps } from '../../common-props';
import { Wizard } from '../../Wizard';
import LoanAgreementGeneralTerms from '../LoanAgreementGeneralTerms/LoanAgreementGeneralTerms';
import LoanDisclosure from '../LoanAgreementGeneralTerms/LoanDisclosure/LoanDisclosure';
import { PaymentForUnutilisedLimit } from '../Payment/PaymentForUnutilisedLimit';

export function LoanDocWizardForUnutilisedLimit({ ...props }: CommonProps) {
  const components = [
    { component: PaymentForUnutilisedLimit, urlPart: 'payment' },
    { component: LoanAgreementGeneralTerms, urlPart: 'loan-agreement-general-terms' },
    { component: LoanDisclosure, urlPart: 'loan-agreement-details' },
  ];

  return (
    <Wizard>
      {components.map(({ component: Component, urlPart }, index) => (
        <Component key={index} {...props} taskFriendlyURL={`${props.taskFriendlyURL}/${urlPart}`} />
      ))}
    </Wizard>
  );
}

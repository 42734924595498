import { ComparisonOperator, errors } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import { z } from 'zod';

export const defaultValues = {
  secondaryPurposeId: '',
  secondaryPurposeAmount: null,
};

export const createFormSchema = (maximumLoanAmount: number) =>
  z.object({
    secondaryPurposeId: z.string().min(1, {
      message: 'Please select an option',
    }),
    secondaryPurposeAmount: z
      .number({
        required_error: errors.amountComparison(ComparisonOperator.GREATER_THAN, 0),
        invalid_type_error: errors.amountComparison(ComparisonOperator.GREATER_THAN, 0),
      })
      .gte(1, {
        message: errors.amountComparison(ComparisonOperator.GREATER_THAN, 0),
      })
      .lte(maximumLoanAmount, {
        message: `You can borrow up to ${formatCurrency(maximumLoanAmount)}`,
      }),
  });

import { isNumber } from 'lodash';

import { formatCurrency } from '../format';

/**
 * The function `currencyOrDash` checks if the provided value is a valid number. If it is, the function
 * returns the value formatted as a currency string (e.g., "$5"). If the value is not a valid number,
 * the function returns a dash ("-").
 * @param {number | null | undefined} value - The `value` parameter can be of type `number`, `null`,
 * or `undefined`.
 * @returns {string} - Returns the value formatted as a currency string or a dash ("-") if the value
 * is not a valid number.
 */
export const currencyOrDash = (value: number | null | undefined): string => {
  return isNumber(value) ? formatCurrency(value) : '-';
};

import { useRouter } from 'next/router';
import React from 'react';
import { ArrowCircleRightIcon, Button, Card } from '@harmoney/ui-design-system';
import { clearLocalStorageByPattern, PATTERN_LOAN_QUOTE_DETAILS_KEY } from '@harmoney/ui-utils';

type Props = {
  subtitle: string;
  description: string;
  ctaText: string;
  mainProcessData: { processId: string };
};

const ProductCard = ({ subtitle, description, ctaText, mainProcessData }: Props) => {
  const router = useRouter();

  const handleClick = () => {
    clearLocalStorageByPattern(PATTERN_LOAN_QUOTE_DETAILS_KEY);
    router.push(`journey?journeyId=${mainProcessData?.processId}`);
  };

  return (
    <Card className="flex flex-col justify-between items-center pt-4 md:px-16 h-full">
      <div className="flex flex-col items-center justify-center gap-1 py-3 mb-2 flex-grow md:w-[280px]">
        <p className="m-0 text-center font-semibold">{subtitle}</p>
        <p className="text-center text-sm text-grey-4">{description}</p>
      </div>
      {mainProcessData?.processId && (
        <div className="w-full">
          <Button
            onClick={handleClick}
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
            isFullWidth
            variant="primary"
          >
            {ctaText}
          </Button>
        </div>
      )}
    </Card>
  );
};

export default ProductCard;

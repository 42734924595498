/* tslint:disable */
/* eslint-disable */
/**
 * Frankie Financial API
 * ------   This API allows developers to integrate the Frankie Financial Compliance Utility into their applications. The API allows:   - Checking name, address, date of birth against national databases   - Validating Australian driver's licences, passports, medicare, visas and other Australian national ID documents   - Validating Australian electricity bills   - Validating NZ driver's licences   - Validating Chinese bank cards and national ID card   - Validating International passports and national ID documents   - PEP, Sanctions, Watchlist and adverse media checking   - Australian visa checks    - Fraud list and fraud background checks   - ID validation and selfie check comparisons.    ------     Industry specific services    - Comparing Australian electricity retailers for a better deal.  ------     KYB specific services    - Query organisation ownership   - Perform KYC & AML checks on shareholders, beneficial owners and office bearers.   - Query credit score and credit reports   - International company searches   - International company profiles    ------   The full version of this documentation along with supplemental articles can be found here:   - https://apidocs.frankiefinancial.com/  ------ Sandbox base URL is:   - https://api.demo.frankiefinancial.io/compliance/v1.2      - All calls are the same as production, only with test data. You can download the latest test data here: https://apidocs.frankiefinancial.com/docs/test-data    - Full Swagger definition, along with test data for playing in the sandbox can be obtained once initial commercial discussions have commenced.    - Production and optional UAT access will be opened up only to those with a signed commercial contract.    ------   Contact us at hello@frankiefinancial.com to speak with a sales rep about issuing a Customer ID and Sandbox api key.
 *
 * OpenAPI spec version: 1.14.0
 * Contact: dev-support@frankiefinancial.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * Customer concession card details
 * @export
 * @interface EICDetailsConcessionCard
 */
export interface EICDetailsConcessionCard {
  /**
   * The type of evidence used to prove eligibility for concessions
   * @type {string}
   * @memberof EICDetailsConcessionCard
   */
  concessionEvidenceType: EICDetailsConcessionCardConcessionEvidenceTypeEnum;
  /**
   * Concessions linked to the customer's concession card
   * @type {string}
   * @memberof EICDetailsConcessionCard
   */
  concessionType: EICDetailsConcessionCardConcessionTypeEnum;
  /**
   * Customer Reference Number (CRN) on the concession card
   * @type {string}
   * @memberof EICDetailsConcessionCard
   */
  customerReferenceNumber: string;
  /**
   * Concession card end expiry date
   * @type {string}
   * @memberof EICDetailsConcessionCard
   */
  endDate: string;
  /**
   * First name on the concession card
   * @type {string}
   * @memberof EICDetailsConcessionCard
   */
  firstName: string;
  /**
   * Last name on the concession card
   * @type {string}
   * @memberof EICDetailsConcessionCard
   */
  lastName: string;
  /**
   * Concession card start date
   * @type {string}
   * @memberof EICDetailsConcessionCard
   */
  startDate: string;
}

/**
 * @export
 * @enum {string}
 */
export enum EICDetailsConcessionCardConcessionEvidenceTypeEnum {
  PensionerConcessionCard = 'Pensioner Concession Card',
  GoldRepatriationHealthCard = 'Gold Repatriation Health Card',
  HealthCareCard = 'Health Care Card',
  NSWLifeSupportRebateWithoutConcessionCard = 'NSW Life Support Rebate Without Concession Card',
  QueenslandSeniorsCard = 'Queensland Seniors Card',
}
/**
 * @export
 * @enum {string}
 */
export enum EICDetailsConcessionCardConcessionTypeEnum {
  LifeSupport = 'Life Support',
  MedicalCooling = 'Medical Cooling',
  ServiceToPropertyCharge = 'Service to Property Charge',
  OffPeakElectricity = 'Off-Peak Electricity',
  AnnualElectricity = 'Annual Electricity',
  TransferFeeWaiver = 'Transfer Fee Waiver',
  ExcessElectricity = 'Excess Electricity',
  ControlledLoad = 'Controlled Load',
  LowIncomeHousehold = 'Low Income Household',
  MedicalEnergy = 'Medical Energy',
  NSWGovernmentLifeSupportRebate = 'NSW Government Life Support Rebate',
  QueenslandElectricityRebate = 'Queensland Electricity Rebate',
  WinterGas = 'Winter Gas',
  ExcessGasConsumption = 'Excess Gas Consumption',
}

type Props = {
  children: React.ReactNode;
  title: string;
};

export const Section = ({ children, title }: Props) => (
  <div className="flex flex-col gap-1">
    <h4 className="mb-0 font-semibold">{title}</h4>
    {children}
  </div>
);

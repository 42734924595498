import { useGetPreQualifiedQuoteDataQuery, useGetVariablesQuery } from '@harmoney/redux';

export const usePreQualifiedQuoteData = (taskId: string) => {
  const { data: variables } = useGetVariablesQuery(taskId, {
    refetchOnMountOrArgChange: true,
  });

  const preQualifiedQuoteId: string = variables?.['preQualifiedQuoteId']?.toString();

  const { data: preQualifiedQuoteData } = useGetPreQualifiedQuoteDataQuery(
    { id: preQualifiedQuoteId },
    {
      skip: !preQualifiedQuoteId,
    }
  );

  return preQualifiedQuoteData;
};

import React from 'react';
import { formatCurrency, formatFrequency } from '@harmoney/utilities';
import { AssetAndLiabilityFrequencyEnum, IncomeAndExpenseFrequencyEnum, RepaymentFrequencyEnum } from '@prisma/client';
import classNames from 'classnames';

export interface PaymentCardProps {
  title: string;
  amount: number;
  frequency: IncomeAndExpenseFrequencyEnum | AssetAndLiabilityFrequencyEnum | RepaymentFrequencyEnum | 'one-off';
  date: string;
  icon: React.ReactNode;
  className?: string;
}

export const PaymentCard: React.FC<PaymentCardProps> = ({ title, amount, frequency, date, icon, className }) => (
  <div className={classNames('border border-grey-2 rounded-lg p-4 flex bg-white', className)}>
    <div className="mr-4 text-lg">{icon}</div>
    <div>
      <h3 className="text-sm font-medium m-0">{title}</h3>
      <p className="m-0">
        <strong className="text-lg font-medium">{formatCurrency(amount)}</strong>
        {frequency && <span className="text-sm">{`/${formatFrequency(frequency)}`}</span>}
      </p>
      <small>{date}</small>
    </div>
  </div>
);

import { NetworthSourceDto, NetworthSourceKind } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const networthSourceApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getNetworthSources: builder.query<NetworthSourceDto[], NetworthSourceKind | void>({
      query: (kind = NetworthSourceKind.INCOME) => ({
        url: `/financial-profile/networth-sources?kind=${kind}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetNetworthSourcesQuery } = networthSourceApi;

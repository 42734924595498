import { forwardRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import classNames from 'classnames';

import { CommonOptionProps } from '../../types';
import { CheckRadioIcon } from '../Icon';
import { ValidationMessage } from '../ValidationMessage';

import styles from './RadioRange.module.scss';

export interface RadioRangeProps {
  options: CommonOptionProps[];
  name: string;
}

export const RadioRange = forwardRef<HTMLDivElement, RadioRangeProps>(
  ({ options, name, ...restProps }: RadioRangeProps, ref) => {
    const { control, formState, getFieldState, register } = useFormContext();
    const { invalid } = getFieldState(name, formState);

    const {
      field: { onChange, value: fieldValue, onBlur },
    } = useController({
      name,
      control,
    });
    return (
      <>
        <RadixRadioGroup.Root
          ref={ref}
          aria-labelledby={name}
          className={classNames(styles['root'], invalid && styles['root-invalid'])}
          defaultValue={fieldValue}
          value={fieldValue}
          onValueChange={onChange}
          onBlur={onBlur}
          id={name}
          data-testid={name}
          {...restProps}
        >
          {options.map(({ value, label }) => (
            <label
              key={value}
              data-testid={value}
              className={classNames(styles['item'], value === fieldValue && styles['item-checked'])}
            >
              <RadixRadioGroup.Item value={value} {...register(name)} className="bg-transparent">
                <CheckRadioIcon size="medium" />
              </RadixRadioGroup.Item>
              <div className="table-caption w-full items-center">{label}</div>
            </label>
          ))}
        </RadixRadioGroup.Root>
        {invalid && <ValidationMessage name={name} />}
      </>
    );
  }
);

import { useEffect, useMemo, useState } from 'react';
import { Frequency, RepaymentFrequency } from '@harmoney/api-interfaces';
import { useFormatQuoteOptions, useFriendlyURL, useQuoteData } from '@harmoney/hooks';
import {
  useAppSelector,
  useGetLoanApplicationQuery,
  useGetQuoteOptionsQuery,
  useGetUserQuery,
  useGetVariablesQuery,
  useSubmitLoanApplicationMutation,
} from '@harmoney/redux';
import { eventAnalytics, LOAN_APPLICATION_SUBMITTED } from '@harmoney/ui-app-shell';
import { Button, Form, Spinner, useForm } from '@harmoney/ui-design-system';
import { LoanApplicationStatusEnum } from '@prisma/client';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { CommonProps } from '../../common-props';
import {
  calculateEstablishmentFee,
  LoanPeriodAlert,
  QuoteDisclaimer,
  QuoteIntroduction,
  QuoteSummary,
  RepaymentOptions,
} from '../shared';

import { LoanPurposeAmount } from './components';
import { createFormSchema, defaultValues } from './form-config';

dayjs.extend(relativeTime);

export function ValidQuoteV3({ taskId, completeTask, taskFriendlyURL }: CommonProps) {
  useFriendlyURL(taskFriendlyURL);
  const userId = useAppSelector((state) => state.userId.value);
  const [borrowingLimit, setBorrowingLimit] = useState<{ lowerLimit: number; upperLimit: number }>({
    lowerLimit: 0,
    upperLimit: 0,
  });
  const formSchema = createFormSchema(borrowingLimit.lowerLimit, borrowingLimit.upperLimit);
  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues,
  });
  const {
    watch,
    register,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;
  const watchFields = watch();

  const { data: userData } = useGetUserQuery();
  const { data: variables } = useGetVariablesQuery(taskId);
  const { quoteData } = useQuoteData(taskId);
  const { data: loanApplicationData } = useGetLoanApplicationQuery(variables?.loanApplicationId.toString(), {
    skip: !variables,
    refetchOnMountOrArgChange: true,
  });
  const [submitLoanApplication] = useSubmitLoanApplicationMutation();

  const { totalAcceptedAmount, establishmentFee, totalLoanAmount } = useMemo(() => {
    const totalAccepted = watchFields.amount;
    const fee = calculateEstablishmentFee(totalAccepted, quoteData);
    const totalLoan = totalAccepted + fee;
    return { totalAcceptedAmount: totalAccepted, establishmentFee: fee, totalLoanAmount: totalLoan };
  }, [watchFields.amount, quoteData]);

  const { data: quoteOptionsData } = useGetQuoteOptionsQuery(
    {
      loanApplicationId: variables?.loanApplicationId.toString(),
      loanAmount: totalAcceptedAmount,
      establishmentFee,
    },
    {
      skip: !variables || !totalAcceptedAmount || !establishmentFee,
      refetchOnMountOrArgChange: true,
    }
  );

  const formattedQuoteOptions = useFormatQuoteOptions(
    watchFields.repaymentFrequency as RepaymentFrequency,
    quoteOptionsData
  );

  const isSameLoanLimitAcrossOptions = useMemo(() => {
    return quoteOptionsData?.every(({ loanLimitFromUmi }) => loanLimitFromUmi === quoteOptionsData[0].loanLimitFromUmi);
  }, [quoteOptionsData]);

  useEffect(() => {
    if (!quoteData || !loanApplicationData) return;

    form.setValue('amount', +quoteData.maximumBorrowingLimit);
    form.setValue('termInMonths', +quoteData.termInMonths);
    setBorrowingLimit({
      lowerLimit: quoteData.minimumBorrowingLimit,
      upperLimit: loanApplicationData.quotePresentedAmount,
    });
  }, [form, quoteData, loanApplicationData]);

  const handleSubmit = async () => {
    await submitLoanApplication({
      id: variables.loanApplicationId.toString(),
      termInMonths: +form.getValues('termInMonths'),
      fundedAmount: +totalAcceptedAmount,
      repaymentFrequency: form.getValues('repaymentFrequency') as unknown as Frequency,
      status: LoanApplicationStatusEnum.application_in_progress,
    });

    eventAnalytics.track(LOAN_APPLICATION_SUBMITTED, {
      userid_str: userId,
      taskid_str: taskId,
    });
    completeTask({ taskId });
  };

  if (!quoteData) return <Spinner />;

  return (
    <>
      <QuoteIntroduction
        preferredName={userData?.preferredName}
        interestRate={loanApplicationData?.finalInterestRate as unknown as number}
      />

      <Form form={form} onSubmit={handleSubmit}>
        {loanApplicationData?.loanApplicationPurposes?.length > 0 && (
          <LoanPurposeAmount
            remainingAmount={borrowingLimit.upperLimit - watchFields.amount}
            loanApplicationData={loanApplicationData}
            register={register}
          />
        )}

        {!isSameLoanLimitAcrossOptions && <LoanPeriodAlert className="mb-4" />}

        <RepaymentOptions formattedQuoteOptions={formattedQuoteOptions} register={register} />

        {watchFields.repaymentFrequency && (
          <QuoteSummary
            primaryPurpose={loanApplicationData?.loanApplicationPurposes[0]?.loanPurpose?.displayName}
            primaryPurposeAmount={watchFields.amount}
            establishmentFee={establishmentFee}
            totalLoanAmount={totalLoanAmount}
            interestRate={loanApplicationData?.finalInterestRate as unknown as number}
          />
        )}

        <QuoteDisclaimer />

        <div className="flex justify-center">
          <Button variant="primary" isLoading={isSubmitting || isSubmitSuccessful} type="submit" hasShadow>
            Submit application
          </Button>
        </div>
      </Form>
    </>
  );
}

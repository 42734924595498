import { SpokeDto } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const spokesApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllSpokes: builder.query<SpokeDto[], void>({
      query: () => ({
        url: `/spokes`,
        method: 'GET',
      }),
    }),
    getSpoke: builder.query<SpokeDto, string>({
      query: (spokeName) => ({
        url: `/spokes/${spokeName}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetAllSpokesQuery, useGetSpokeQuery } = spokesApi;

import { generateIcon } from '../generateIcon';

export const ArrowCircleRightIcon = generateIcon({
  viewBox: '0 0 32 32',
  path: (
    <>
      <path
        d="M0 16C7.72516e-07 7.16344 7.16345 -7.72516e-07 16 0C24.8366 7.72516e-07 32 7.16345 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 -7.72516e-07 24.8366 0 16Z"
        fill="white"
      />
      <path
        className="animate-swing arrow-circle-right-icon"
        d="M22 15.6L16.4 10M22 15.6L16.4 21.2M22 15.6H6"
        stroke="#FF4B4A"
        strokeWidth="2.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export enum FORM_KEY {
  USER_INFORMATION_UPDATE = 'USER_INFORMATION_UPDATE',
  DEBT_UPDATE = 'DEBT_UPDATE',
  ASSET_UPDATE = 'ASSET_UPDATE',
  INCOME_UPDATE = 'INCOME_UPDATE',
  DEBT_CONSOLIDATION_USER_SELECTION_UPDATE = 'DEBT_CONSOLIDATION_USER_SELECTION_UPDATE',
}

export interface CompleteTaskDto {
  taskId: string;
  variables?: { [key: string]: string | number | boolean | object };
  formKey?: FORM_KEY;
  formData?: { [key: string]: string | number | boolean | object };
}

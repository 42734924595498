import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { IdentityVerificationStatus } from '@harmoney/api-interfaces';
import { useFriendlyURL, useOneSDK } from '@harmoney/hooks';
import { useAppSelector } from '@harmoney/redux';
import {
  eventAnalytics,
  IDENTITY_HARD_ID_FAIL,
  IDENTITY_HARD_ID_STARTED,
  IDENTITY_HARD_ID_SUCCESS,
} from '@harmoney/ui-app-shell';
import { Spinner } from '@harmoney/ui-design-system';
import { LoanApplicationStatusEnum } from '@prisma/client';
import classNames from 'classnames';

import { CommonProps } from '../../common-props';

const MAX_ATTEMPTS = 3;

const driverLicenceConfig = {
  fields: [
    {
      fieldType: 'select',
      name: 'country',
      hide: true,
      options: [
        {
          label: 'Australia',
          value: 'AUS',
        },
      ],
    },
  ],
};

export function IdentityDocumentVerification({ taskId, completeTaskWithData, taskFriendlyURL }: CommonProps) {
  useFriendlyURL(taskFriendlyURL);
  const router = useRouter();
  const [initialized, setInitialised] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const userId = useAppSelector((state) => state.userId.value);

  const config = {
    mode: 'production',
    recipe: {
      form: {
        provider: {
          name: 'react',
          googleApiKey: process.env.NEXT_PUBLIC_FRANKIEONE_GOOGLE_API_KEY,
        },
      },
    },
  };

  useEffect(() => {
    const hasReloaded = localStorage.getItem('idv_reloaded');
    if (hasReloaded !== 'true') {
      localStorage.setItem('idv_reloaded', 'true');
      router.reload();
    }
  }, [router]);

  useEffect(() => {
    const handleRouteChange = (url) => {
      const newPathname = url;
      const newURL = new URL(url, window.location.origin);
      // do a browser refresh when navigating from current page to dashboard to clean up onesdk session
      if (newPathname === '/' || newPathname === '/dashboard/') {
        window.location.href = newURL.href;
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  const { oneSDKInstance: oneSdk, error: errorOneSDK, loading, setComponents } = useOneSDK({ config });

  useEffect(() => {
    const handleFormFailure = (module: string, data) => {
      console.error(`Form ${module} failed`, data);
      router.reload();
    };

    const initOneSDK = () => {
      setInitialised(true);
      eventAnalytics.track(IDENTITY_HARD_ID_STARTED, {
        userid_str: userId,
        taskid_str: taskId,
      });
      const oneSdkIndividual = oneSdk.individual();
      oneSdkIndividual.setProfileType(process.env.NEXT_PUBLIC_FRANKIEONE_RECIPE);
      oneSdkIndividual.addConsent('general');
      oneSdkIndividual.addConsent('creditheader');
      oneSdkIndividual.addConsent('docs');

      const document = oneSdk.component('form', {
        name: 'DOCUMENT',
        type: 'manual',
        title: { label: "Let's verify your identity" },
        cta: { label: 'Continue' },
        numberOfIDs: 1,
        descriptions: [
          {
            label: 'We have to confirm you are you.',
          },
          {
            label: "Choose which ID you'd like to provide",
          },
        ],
        documents: [
          {
            type: 'DRIVERS_LICENCE',
            subtitle: '',
            icon: '/assets/images/driver-licence.svg',
            countries: {
              default: {
                default: driverLicenceConfig,
              },
              AUS: {
                default: driverLicenceConfig,
                ACT: driverLicenceConfig,
                NSW: driverLicenceConfig,
                QLD: driverLicenceConfig,
                TAS: driverLicenceConfig,
                NT: driverLicenceConfig,
                WA: driverLicenceConfig,
                VIC: driverLicenceConfig,
                SA: driverLicenceConfig,
              },
            },
          },
          {
            type: 'PASSPORT',
            icon: '/assets/images/passport.svg',
          },
        ],
      });

      const review = oneSdk.component('form', {
        name: 'REVIEW',
        type: 'manual',
        title: { label: 'Take a moment to check your details' },
        hiddenForms: ['personal'],
        cta: { label: 'Continue' },
      });

      const resultFail = oneSdk.component('form', {
        name: 'RESULT',
        type: 'manual',
        state: 'FAIL',
        title: { label: 'We had some trouble verifying your identity' },
        descriptions: [{ label: 'Please enter your information exactly as it appears on your ID.' }],
        cta: { label: 'Retry' },
      });

      const loadingScreen = oneSdk.component('form', {
        name: 'LOADING',
        title: { label: 'Doing a quick check...' },
        descriptions: [
          {
            label: 'Hang tight!',
          },
        ],
      });

      setComponents([document, review, resultFail, loadingScreen]);

      document.mount('#form-document-container');
      document.on('form:document:failed', (data) => {
        handleFormFailure('document', data);
      });
      document.on('form:document:ready', () => {
        console.log('review mounted');
        review.mount('#form-document-container');
      });

      let ATTEMPT_COUNT = 0;

      const handleResult = async (result) => {
        if (result.status.key == 'FAIL') {
          if (result?.risk?.class === 'UNACCEPTABLE' || result?.risk?.class === 'HIGH') {
            setShowLoading(true);
            eventAnalytics.track(IDENTITY_HARD_ID_SUCCESS, {
              userid_str: userId,
              taskid_str: taskId,
            });
            await completeTaskWithData({
              taskId,
              variables: {
                applicationStatus: LoanApplicationStatusEnum.quote_offered,
                verificationStatus: IdentityVerificationStatus.SUCCESS,
              },
            });
            return;
          }
          if (ATTEMPT_COUNT < MAX_ATTEMPTS) {
            resultFail.mount('#form-document-container');
          } else {
            setShowLoading(true);
            eventAnalytics.track(IDENTITY_HARD_ID_FAIL, {
              userid_str: userId,
              taskid_str: taskId,
            });
            await completeTaskWithData({
              taskId,
              variables: {
                applicationStatus: LoanApplicationStatusEnum.quote_unsuccessful,
                verificationStatus: IdentityVerificationStatus.TOO_MANY_TRIES,
              },
            });
          }
        } else if (
          result.status.key === 'PASS' ||
          result.status.key === 'PASS_MANUAL' ||
          result.status.key === 'REFER'
        ) {
          setShowLoading(true);
          eventAnalytics.track(IDENTITY_HARD_ID_SUCCESS, {
            userid_str: userId,
            taskid_str: taskId,
          });
          await completeTaskWithData({
            taskId,
            variables: {
              applicationStatus: LoanApplicationStatusEnum.quote_offered,
              verificationStatus: IdentityVerificationStatus.SUCCESS,
            },
          });
        } else {
          if (ATTEMPT_COUNT < MAX_ATTEMPTS) {
            resultFail.mount('#form-document-container');
          }
        }
      };

      review.on('form:review:ready', async () => {
        loadingScreen.mount('#form-document-container');
        const result = await oneSdkIndividual.submit({ verify: true });
        ATTEMPT_COUNT += 1;
        await handleResult(result);
      });

      resultFail.on('form:result:failed', () => {
        document.mount('#form-document-container');
      });
    };

    if (!initialized && oneSdk) {
      initOneSDK();
    }
  }, [oneSdk, initialized, completeTaskWithData, taskId, router, setComponents]);

  if (loading || showLoading) {
    return <Spinner />;
  }

  return <div id="form-document-container" className={classNames('width-[576px] height-[600px]')}></div>;
}

import { LottieCalculation } from '../Lotties/LottieCalculation';

import styles from './BiometricLoading.module.scss';

export function BiometricLoading() {
  return (
    <div className="mt-12 flex flex-col items-center">
      <p className="mb-16 flex flex-col items-center">
        <span className="text-lg">We are</span>
        <span className={styles['text-animation']}></span>
      </p>
      <LottieCalculation />
      <p className="fixed bottom-[15%] px-12 md:bottom-[20%]">
        This process usually takes from few seconds up to few minutes...
      </p>
    </div>
  );
}

import { IncomeAndExpenseFrequencyEnum } from '@prisma/client';

/**
 * @deprecated
 * This AmountFormatter is deprecated and should not be used in new code.
 * Use formatCurrency(value) from utilities lib instead as it supports the same functionality
 * e.g.
 *     formatCurrency(1234) = '1,234'
 *     formatCurrency(1234.5) = '1,234.50' => '1.234.5' if using AmountFormatter.format(value) instead but it is not recommended by UX
 *     formatCurrency(1234.56) = '1,234.56'
 *     formatCurrency(1234.567) = '1,234.57'
 */
export const AmountFormatter = new Intl.NumberFormat('en-NZ', {
  style: 'currency',
  currency: 'NZD',
  minimumFractionDigits: 0,
});

/**
 * @deprecated
 * This CurrencyFormatter is deprecated and should not be used in new code.
 * Use formatCurrency(value, 2) from utilities lib instead as it supports the same functionality
 * e.g.
 *     formatCurrency(1234) => '1,234.00'
 *     formatCurrency(1234.5) => '1,234.50'
 *     formatCurrency(1234.56) => '1,234.56'
 *     formatCurrency(1234.567) => '1,234.57'
 */
export const CurrencyFormatter = new Intl.NumberFormat('en-NZ', {
  style: 'currency',
  currency: 'NZD',
});

/**
 * @deprecated
 * This NumberFormatter is deprecated and should not be used in new code.
 * Use formatNumber(value, 2) from utilities lib instead as it supports the same functionality
 * e.g.
 *     formatNumber(1234) = '1,234.00' => can change to '1,234' if using formatNumber(value) instead
 *     formatNumber(1234.5) = '1,234.50' => remains the same if using formatNumber(value) instead
 *     formatNumber(1234.56) = '1,234.56' => remains the same if using formatNumber(value) instead
 *     formatNumber(1234.567) = '1,234.57' => remains the same if using formatNumber(value) instead
 */
export const NumberFormatter = (value: number): string => {
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
};

/**
 * @deprecated
 * This NumberFormatterNoDecimal is deprecated and should not be used in new code.
 * Use formatNumber(value, 0) from utilities lib instead as it supports the same functionality
 * e.g.
 *     formatNumber(1234, 0) => '1,234'
 *     formatNumber(1234.5, 0) => '1,235'
 *     formatNumber(1234.56, 0) => '1,235'
 *     formatNumber(1234.567, 0) => '1,235'
 */
export const NumberFormatterNoDecimal = (value: number): string => {
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
};

/**
 * @deprecated
 * This FrequencyFormatter is deprecated and should not be used in new code.
 * Use formatFrequency(value) from utilities lib instead as it supports the same functionality
 * e.g.
 *     formatFrequency('weekly') => 'week'
 *     formatNumber('fortnightly') => 'fortnight'
 *     formatNumber('monthly') => 'month'
 */
export const FrequencyFormatter = (frequency: IncomeAndExpenseFrequencyEnum): string => {
  return frequency?.toLowerCase().replace(/ly\b/g, '');
};

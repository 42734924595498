import { useEffect, useState } from 'react';
import { RepeatRefreshedLoansResultDto } from '@harmoney/api-interfaces';
import { LiabilityProvider } from '@harmoney/utilities';

interface UseDisplayDebtConAffordableModalParams {
  refreshedLoanData?: RepeatRefreshedLoansResultDto;
  onUnaffordable?: (params?: any) => void;
}

export const useDisplayDebtConAffordableModal = ({
  refreshedLoanData,
  onUnaffordable,
}: UseDisplayDebtConAffordableModalParams) => {
  const [showLoanDetailsAffordableModal, setShowLoanDetailsAffordableModal] = useState(false);

  useEffect(() => {
    if (refreshedLoanData?.hasChanged) {
      if (refreshedLoanData?.isAffordable) {
        const didAnExistingHmyLoanAmountIncrease = refreshedLoanData?.consolidatedDebts
          .filter((cd) => cd.provider.startsWith(LiabilityProvider.Harmoney))
          .some((cd) => cd.outstandingBalance > cd.originalOutstandingBalance);

        if (didAnExistingHmyLoanAmountIncrease) {
          setShowLoanDetailsAffordableModal(true);
        } else {
          setShowLoanDetailsAffordableModal(false);
        }
      } else {
        onUnaffordable();
      }
    } else {
      setShowLoanDetailsAffordableModal(false);
    }
  }, [refreshedLoanData]);

  return { showLoanDetailsAffordableModal, setShowLoanDetailsAffordableModal };
};

import { ResidentialStatusEnum } from '@harmoney/api-interfaces';

export const primaryLivingSituationOptions = [
  {
    label: `
            <span class='block'>I own the property I live in</span>
            <span class='text-sm text-grey-4'>Mortgage or fully owned</span>
            `,
    value: ResidentialStatusEnum.HOME_OWNER,
  },
  {
    label: `
            <span class='block'>I'm a tenant</span>
            <span class='text-sm text-grey-4'>Renting a property</span>
          `,
    value: ResidentialStatusEnum.RENTING,
  },
  {
    label: `
            <span class='block'>I'm a boarder</span>
            <span class='text-sm text-grey-4'>Renting a room</span>
          `,
    value: ResidentialStatusEnum.BOARDER,
  },
];
export const otherLivingSituationOptions = [
  {
    label: 'Other situations',
    value: '',
  },
  {
    label: 'I live with my parents',
    value: ResidentialStatusEnum.LIVE_WITH_PARENTS,
  },
  {
    label: 'I live in government-provided housing',
    value: ResidentialStatusEnum.GOVERNMENT_HOUSING,
  },
  {
    label: 'I live in company-provided housing',
    value: ResidentialStatusEnum.COMPANY_HOUSING,
  },
];

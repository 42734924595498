import { RepaymentFrequencyEnum } from '@prisma/client';
import bigDecimal from 'js-big-decimal';

/**
 * Converts a monthly amount to a different frequency for repayment calculations.
 * @param amount - The monthly amount.
 * @param frequency - The frequency to convert to.
 * @returns The converted amount.
 */
export function convertFromRepaymentMonthlyAmount(
  amount: number,
  frequency: RepaymentFrequencyEnum | 'yearly'
): number {
  let result = 0;
  switch (frequency) {
    case RepaymentFrequencyEnum.weekly:
      result = amount / 4;
      break;
    case RepaymentFrequencyEnum.fortnightly:
      result = amount / 2;
      break;
    case RepaymentFrequencyEnum.monthly:
    default:
      result = amount;
  }
  const resultBigDecimal = new bigDecimal(result);
  return parseFloat(resultBigDecimal.round(2, bigDecimal.RoundingModes.CEILING).getValue());
}

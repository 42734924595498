import { LOCAL_QUOTE_DETAILS_KEY } from '@harmoney/api-interfaces';

export const PATTERN_LOAN_QUOTE_DETAILS_KEY = new RegExp(`^${LOCAL_QUOTE_DETAILS_KEY}-[a-zA-Z0-9-]+$`);

/**
 * Clears all localStorage keys that match the specified regular expression pattern.
 *
 * @param {RegExp} pattern - The regular expression to match keys.
 * @returns {Object} - An object containing the count of removed keys and an array of removed key names.
 */
export const clearLocalStorageByPattern = (pattern: RegExp) => {
  if (typeof window === 'undefined' || !window.localStorage) {
    console.warn('localStorage is not available.');
    return { removedCount: 0, removedKeys: [] };
  }

  if (!(pattern instanceof RegExp)) {
    console.error('Pattern must be a RegExp object.');
    return { removedCount: 0, removedKeys: [] };
  }

  const keysToRemove = [];

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && pattern.test(key)) {
      keysToRemove.push(key);
    }
  }

  keysToRemove.forEach((key) => {
    localStorage.removeItem(key);
  });

  return { removedCount: keysToRemove.length, removedKeys: keysToRemove };
};

import { accountTypeToDebtType } from '@harmoney/api-interfaces';
import { ExtendedPreQualifiedQuote } from '@harmoney/redux';
import { Card } from '@harmoney/ui-design-system';
import { DATE_FORMAT } from '@harmoney/ui-utils';
import { currencyOrDash, dayjsSydney } from '@harmoney/utilities';
import { lowerCase } from 'lodash';

import { Field } from './shared';

interface PreQualifiedQuoteFinancialProfileDetailsProps {
  preQualifiedQuote: ExtendedPreQualifiedQuote;
}

export const PreQualifiedQuoteFinancialProfileDetails = ({
  preQualifiedQuote,
}: PreQualifiedQuoteFinancialProfileDetailsProps) => {
  return (
    <div>
      <div className="grid grid-cols-2 gap-2">
        <div className="flex flex-col gap-2">
          <Card className="shadow-none border border-grey-2 !p-0 !mb-0 h-fit">
            <div className="p-4 space-y-2">
              <span className="font-medium">Income</span>
              <Field className="!pb-2">
                <span>Work code:</span>
                <span>{preQualifiedQuote.employmentCode || 'N/A'}</span>
              </Field>
              <Field className="!pb-2">
                <span>Gross annual income:</span>
                <span>${preQualifiedQuote.grossAnnualIncome?.toLocaleString() || 'N/A'}</span>
              </Field>
            </div>
          </Card>
          <Card className="shadow-none border border-grey-2 !p-0 !mb-0 h-fit">
            <div className="p-4 space-y-2">
              <span className="font-medium">Expenses</span>
              <Field className="!pb-2">
                <span>Monthly housing expenses:</span>
                <span>{currencyOrDash(preQualifiedQuote.monthlyHousingExpense)}</span>
              </Field>
              <Field className="!pb-2">
                <span>Living expenses (HEM Benchmark):</span>
                <span>{currencyOrDash(preQualifiedQuote?.variables?.benchmarkValue)}</span>
              </Field>
            </div>
          </Card>
        </div>

        <div className="flex flex-col gap-2 col-start-2">
          {preQualifiedQuote?.liabilities &&
            preQualifiedQuote?.liabilities.map((liability) => (
              <Card key={liability.bureauAccountId} className="shadow-none border border-grey-2 !p-0 !mb-0">
                <div className="p-4 space-y-2">
                  <Field className="!pb-2">
                    <span>Debt type:</span>
                    <span className="first-letter:capitalize">
                      {lowerCase(
                        Object.keys(accountTypeToDebtType).find((debtType) =>
                          accountTypeToDebtType[debtType].includes(liability.accountType)
                        )
                      )}{' '}
                      ({liability.accountType})
                    </span>
                  </Field>
                  <Field className="!pb-2">
                    <span>Provider:</span>
                    <span>{liability.organisation}</span>
                  </Field>
                  {liability.accountOpenDate && (
                    <Field className="!pb-2">
                      <span>Opened on:</span>
                      <span>{dayjsSydney(liability.accountOpenDate).format(DATE_FORMAT)}</span>
                    </Field>
                  )}
                  {liability.termOfLoan > 0 && (
                    <Field className="!pb-2">
                      <span>Term of loan:</span>
                      <span>{liability.termOfLoan} months</span>
                    </Field>
                  )}
                  {liability.accountType.startsWith('C') && (
                    <Field className="!pb-2">
                      <span>Credit limit:</span>
                      <span>{currencyOrDash(liability.creditLimit as number)}</span>
                    </Field>
                  )}
                </div>
              </Card>
            ))}
        </div>
      </div>
    </div>
  );
};

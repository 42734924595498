import { ComponentProps, ReactNode } from 'react';
import classNames from 'classnames';

export type LabelProps = ComponentProps<'label'> & {
  /**
   * Label value to show
   */
  children: ReactNode;
};

export const Label = ({ children, className, ...restProps }: LabelProps) => {
  return (
    <label className={classNames('leading-base text-grey-5 block', className)} {...restProps}>
      {children}
    </label>
  );
};

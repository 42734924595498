import { IconVariant } from '../../Icon';

import { BoatIcon } from './BoatIcon';
import { CaravanIcon } from './CaravanIcon';
import { CarIcon } from './CarIcon';
import { ClearingOverdraftIcon } from './ClearingOverdraftIcon';
import { ComputerIcon } from './ComputerIcon';
import { DebtConIcon } from './DebtConIcon';
import { DefaultIcon } from './DefaultIcon';
import { EducationIcon } from './EducationIcon';
import { FlagIcon } from './FlagIcon';
import { FuneralExpensesIcon } from './FuneralExpensesIcon';
import { GoingOnHolidayIcon } from './GoingOnHolidayIcon';
import { GrowingMyBusinessIcon } from './GrowingMyBusinessIcon';
import { HouseholdItemsIcon } from './HouseholdItemsIcon';
import { ImprovingMyHomeIcon } from './ImprovingMyHomeIcon';
import { LegalFeesIcon } from './LegalFeesIcon';
import { LoanToFamilyMemberIcon } from './LoanToFamilyMemberIcon';
import { MedicalExpensesIcon } from './MedicalExpensesIcon';
import { MoreIcon } from './MoreIcon';
import { NewVehicleIcon } from './NewVehicleIcon';
import { TaxBillIcon } from './TaxBillIcon';
import { UsedVehicleIcon } from './UsedVehicleIcon';
import { WeddingIcon } from './WeddingIcon';

export const getIconComponent = (iconName: string, variant: IconVariant) => {
  switch (iconName) {
    case 'improving-my-home':
      return <ImprovingMyHomeIcon variant={variant} />;
    case 'going-on-holiday':
      return <GoingOnHolidayIcon variant={variant} />;
    case 'growing-my-business':
      return <GrowingMyBusinessIcon variant={variant} />;
    case 'car':
      return <CarIcon variant={variant} />;
    case 'wedding':
      return <WeddingIcon variant={variant} />;
    case 'education':
      return <EducationIcon variant={variant} />;
    case 'flag':
      return <FlagIcon variant={variant} />;
    case 'medical-expenses':
      return <MedicalExpensesIcon variant={variant} />;
    case 'funeral-expenses':
      return <FuneralExpensesIcon variant={variant} />;
    case 'legal-fees':
      return <LegalFeesIcon variant={variant} />;
    case 'clearing-overdraft':
      return <ClearingOverdraftIcon variant={variant} />;
    case 'tax-bill':
      return <TaxBillIcon variant={variant} />;
    case 'loan-to-family-member':
      return <LoanToFamilyMemberIcon variant={variant} />;
    case 'computer':
      return <ComputerIcon variant={variant} />;
    case 'boat':
      return <BoatIcon variant={variant} />;
    case 'caravan':
      return <CaravanIcon variant={variant} />;
    case 'more-loan-purposes':
      return <MoreIcon />;
    case 'used-vehicle':
      return <UsedVehicleIcon variant={variant} />;
    case 'new-vehicle':
      return <NewVehicleIcon variant={variant} />;
    case 'household-items':
      return <HouseholdItemsIcon variant={variant} />;
    case 'debt-con':
      return <DebtConIcon variant={variant} />;
    case 'debt-consolidation':
      return <DebtConIcon variant={variant} />;
    default:
      return <DefaultIcon variant={variant} />;
  }
};

import { RelationshipStatusEnum } from '@prisma/client';

/* The `relationshipStatusMapper` is a constant variable that maps the enum values of
`RelationshipStatusEnum` to their corresponding string representations. */
export const relationshipStatusMapper: Record<RelationshipStatusEnum, string> = {
  [RelationshipStatusEnum.DE_FACTO]: 'De Facto',
  [RelationshipStatusEnum.MARRIED]: 'Married',
  [RelationshipStatusEnum.SINGLE]: 'Single',
  [RelationshipStatusEnum.WIDOWED]: 'Widowed',
  [RelationshipStatusEnum.DIVORCED]: 'Divorced',
  [RelationshipStatusEnum.SEPARATED]: 'Separated',
};

/* The `relationshipStatusReverseMapper` is a constant variable that maps string values to the corresponding
enum values of the `RelationshipStatusEnum` enum. */
export const relationshipStatusReverseMapper: Record<string, RelationshipStatusEnum> = {
  'De Facto': RelationshipStatusEnum.DE_FACTO,
  Married: RelationshipStatusEnum.MARRIED,
  Single: RelationshipStatusEnum.SINGLE,
  Widowed: RelationshipStatusEnum.WIDOWED,
  Divorced: RelationshipStatusEnum.DIVORCED,
  Separated: RelationshipStatusEnum.SEPARATED,
};

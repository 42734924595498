import { Fragment } from 'react';
import { FinancialProfileDto } from '@harmoney/api-interfaces';
import { Card, Divider, Label } from '@harmoney/ui-design-system';
import { AmountFormatter, amountOrDash, capitalizeTitle } from '@harmoney/ui-utils';

export const Assets = ({ financialSummary }: { financialSummary: FinancialProfileDto }) => {
  return (
    <Card className="!p-0">
      <div className="flex items-center justify-between">
        <Label className="p-4 text-lg font-medium">Assets</Label>
        {/* <Button size="medium" className="mr-4" variant="text">
          Edit
        </Button> */}
      </div>
      <Divider className="text-grey-2 m-0 p-0" />

      {financialSummary?.assets.length > 0 ? (
        financialSummary?.assets.map((asset, i) => (
          <Fragment key={`assets-${i}`}>
            <div className="flex flex-row items-center justify-between p-4 ">
              {asset.assetName ? (
                <span>{capitalizeTitle(asset.assetName)}</span>
              ) : asset.networthSourceName === `i don't own any assets ` ? (
                <span>Assets</span>
              ) : (
                <span>{capitalizeTitle(asset.networthSourceName)}</span>
              )}
              {asset.declaredAmount > 0 ? (
                <span className="font-medium">{AmountFormatter.format(asset.declaredAmount)}</span>
              ) : (
                <span className="font-medium">{amountOrDash(asset.declaredAmount)}</span>
              )}
            </div>
            <Divider className="text-grey-1 m-0 px-4" />
          </Fragment>
        ))
      ) : (
        <span className="flex flex-row items-center justify-between p-4">No assets</span>
      )}
    </Card>
  );
};

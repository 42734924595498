import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FileUpload } from '@harmoney/ui-design-system';

interface FileUploadCardProps {
  item: FileUpload;
  index: number;
  verification: boolean;
}
export function FileUploadCard({ item, index, verification }: FileUploadCardProps) {
  const [hasError, setHasError] = useState(false);
  const { label, helpText, multipleFiles, category, keyName } = item;
  const { setValue, watch } = useFormContext();
  const formData = watch();
  const handleFilesSelected = (files: File[]) => {
    if (files.length > 0) {
      setValue(`items[${index}].documentStatus`, false);
      setValue(`items[${index}].files`, files);
      setValue(`items[${index}].category`, category);
    } else {
      setValue(`items[${index}].documentStatus`, true);
    }
  };

  useEffect(() => {
    const files = formData.items[index]?.files;
    const allowEmpty = formData[keyName] ?? false;
    if (!files) {
      setHasError(true);
    }
    if (files && files.length === 0) {
      formData?.items[index]?.documentStatus ? setHasError(false) : setHasError(true);
    }
    if (files && files.length > 0) {
      formData?.items[index]?.documentStatus ? setHasError(true) : setHasError(false);
    }

    if (allowEmpty) {
      setHasError(false);
    }

    if (hasError && !allowEmpty && !formData?.errorList?.includes(keyName)) {
      formData?.errorList?.push(keyName);
    }

    if (!hasError && formData?.errorList?.includes(keyName)) {
      (formData.errorList as string[])?.splice(formData?.errorList?.indexOf(keyName), 1);
    }
  }, [formData, verification]);

  return (
    <>
      <div key={index} className="mb-6 items-center gap-x-4">
        <FileUpload
          onFilesSelected={handleFilesSelected}
          keyName={keyName}
          label={label}
          hasError={hasError && verification}
          helpText={helpText}
          showCheckBox={item.showCheckBox}
          maxSize={10}
          multipleFiles={multipleFiles}
        />
        {hasError && verification && (
          <p className="text-error leading-sm ml-2 mt-1 pb-2 text-sm">Please upload a document</p>
        )}
      </div>
    </>
  );
}

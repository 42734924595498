import Image from 'next/image';
import { useMemo, useRef, useState } from 'react';
import { EmploymentType, IncomeDto } from '@harmoney/api-interfaces';
import { useFriendlyURL } from '@harmoney/hooks';
import {
  useAppSelector,
  useGetNetworthSourcesQuery,
  useSubmitIncomeByCOMutation,
  useSubmitIncomeMutation,
} from '@harmoney/redux';
import { eventAnalytics, FINANCIALPROFILE_INCOME_PROVIDED } from '@harmoney/ui-app-shell';
import {
  ArrowCircleRightIcon,
  Button,
  CollapsibleHeader,
  Divider,
  Form,
  Label,
  useForm,
} from '@harmoney/ui-design-system';
import { capitalizeTitle, errors, scrollToNearest } from '@harmoney/ui-utils';
import dayjs from 'dayjs';
import { isEmpty, kebabCase, map, slice, some } from 'lodash';
import { z } from 'zod';

import { CommonProps, ExtraAdminProps } from '../../common-props';
import { IncomeItem } from '../Income/IncomeItem/IncomeItem';
import { initialFormValues } from '../Income/InitialFormValues';

const NETWORTH_CODE_NO_INCOME = 'no_income';
const NETWORTH_CODE_SALARY = 'salary';
const NETWORTH_CODE_SELF_EMPLOYED = 'self_employed';
const NETWORTH_CODE_RENTAL_INCOME = 'rental_income';

// Types of form schema for the different incomes

const IncomeSchemaBase = z.object({
  networthSourceId: z.number().optional(),
  declaredAmount: z.coerce
    .number({ invalid_type_error: errors.defaultValidAmount })
    .gte(1, { message: errors.defaultValidAmount }),
  frequency: z.string().optional(),
});

const IncomeSchemaOne = IncomeSchemaBase.extend({
  employmentCode: z.string().min(1, { message: errors.defaultRequiredField }),
  employmentType: z.string().min(1, { message: errors.defaultRequiredField }),
  startEmploymentMonth: z.string().min(1, { message: errors.defaultRequiredField }),
  startEmploymentYear: z.string().min(1, { message: errors.defaultRequiredField }),
  seasonalWorkingMonths: z.string().optional(),
}).refine(
  (data) => {
    if (data.employmentType === EmploymentType.SEASONAL && isEmpty(data.seasonalWorkingMonths)) {
      return false;
    }
    return true;
  },
  {
    message: errors.defaultRequiredField,
    path: ['seasonalWorkingMonths'],
  }
);

const IncomeSchemaTwo = IncomeSchemaBase.extend({
  benefitType: z.string().min(1, { message: errors.defaultRequiredField }),
  benefitName: z.string().optional(),
}).refine(
  (data) => {
    if (data.benefitType === 'Other' && isEmpty(data.benefitName)) {
      return false;
    }
    return true;
  },
  {
    message: `${errors.requiredField('Benefit Name')}`,
    path: ['benefitName'],
  }
);

const IncomeSchemaThree = IncomeSchemaBase.extend({
  isRentalIncomeShared: z.string().min(1, { message: errors.defaultRequiredField }),
});

const IncomeSchemaFour = IncomeSchemaBase.extend({
  otherIncomeType: z.string().min(1, { message: errors.requiredField('Income name') }),
});

const IncomeSchemaFive = IncomeSchemaBase.extend({
  hiddenValidationField: z.boolean(),
});

const IncomeSchemaSix = IncomeSchemaBase.extend({
  selfEmploymentType: z.string().min(1, { message: errors.defaultRequiredField }),
});

const incomeSchema = z
  .union([IncomeSchemaOne, IncomeSchemaTwo, IncomeSchemaThree, IncomeSchemaFour, IncomeSchemaFive, IncomeSchemaSix])
  .refine(
    (data) => {
      if (data.declaredAmount > 0 && isEmpty(data.frequency)) return false;
      return true;
    },
    {
      message: errors.defaultRequiredFrequency,
      path: ['frequency'],
    }
  );

export function IncomeV2({
  taskId,
  completeTask,
  taskFriendlyURL,
  isCustomerFacing = true,
  financialProfileId,
  customerId,
}: CommonProps & ExtraAdminProps) {
  const rootSchema = z.object({
    incomes: z.record(z.array(incomeSchema)),
  });

  const userId = useAppSelector((state) => state.userId.value);
  const form = useForm({
    mode: 'onTouched',
    schema: rootSchema,
    defaultValues: {
      incomes: {},
    },
  });
  const { register, watch, setValue, getValues, reset, resetField } = form;

  const { data: incomeTypes } = useGetNetworthSourcesQuery();
  const [submitIncome] = useSubmitIncomeMutation();
  const [submitIncomeByCO] = useSubmitIncomeByCOMutation({
    fixedCacheKey: `${customerId}-${financialProfileId}-${userId}`,
  });

  const watchForm = watch();
  const watchIncomes = watchForm.incomes;

  const [formTouched, setFormTouched] = useState(false);
  const [otherIncomeOpen, setOtherIncomeOpen] = useState(false);
  const [noIncomeData, setNoIncomeData] = useState(null);
  const [disableCollapsible, setDisableCollapsible] = useState(false);
  const [primaryIncomeTypes, setPrimaryIncomeTypes] = useState([]);
  const [secondaryIncomeTypes, setSecondaryIncomeTypes] = useState([]);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [disableNoIncome, setDisableNoIncome] = useState(false);
  const pageRef = useRef(null);

  const handleSubmit = async (data) => {
    data['incomes'] = isEmpty(data['incomes']) ? noIncomeData : data['incomes'];
    const hasIncome = some(data.incomes, (item) => item.length > 0);
    if (!hasIncome) {
      setFormSubmitting(false);

      setFormTouched(true);
      return;
    }

    const hasSelfEmployedIncome = Object.entries(data.incomes).some(([key, value]) => {
      if (key === NETWORTH_CODE_SELF_EMPLOYED) {
        return (value as any[]).length > 0;
      }
    });

    setFormSubmitting(true);

    const getDate = (month, year) => {
      const firstDateOfMonth = dayjs(`${+year}-${+month}-01`).toDate();
      return firstDateOfMonth;
    };

    const transformedData: IncomeDto[] = Object.entries(data.incomes).flatMap(([key, value]) => {
      if (key === NETWORTH_CODE_SALARY) {
        return (value as any[]).map((item) => {
          if (!isCustomerFacing) {
            item.creditOfficerAmount = item.declaredAmount;
            item.creditOfficerFrequency = item.frequency;
            item.declaredAmount = null;
            item.frequency = null;
          }
          return {
            ...item,
            employmentStartDate: getDate(item.startEmploymentMonth, item.startEmploymentYear),
          };
        });
      }

      if (key === NETWORTH_CODE_NO_INCOME) {
        return value[0];
      }

      if (!isCustomerFacing) {
        return (value as any[]).map((item) => {
          item.creditOfficerAmount = item.declaredAmount;
          item.creditOfficerFrequency = item.frequency;
          item.declaredAmount = null;
          item.frequency = null;
          item.overrideReason = 'Income not declared by the customer';
          return item;
        });
      }

      return value;
    });

    if (isCustomerFacing) {
      await submitIncome({
        incomes: transformedData,
        taskId: taskId,
      });

      eventAnalytics.track(FINANCIALPROFILE_INCOME_PROVIDED, {
        userid_str: userId,
        taskid_str: taskId,
      });
      await completeTask({ taskId, variables: { hasSelfEmployedIncome } });
    } else {
      await submitIncomeByCO({
        incomes: transformedData,
        financialProfileId,
        customerId,
      });
      setFormSubmitting(false);
    }
  };

  useMemo(() => {
    const filteredIncomeTypes = isCustomerFacing
      ? incomeTypes?.filter((income) => income.code !== NETWORTH_CODE_RENTAL_INCOME)
      : incomeTypes?.filter((income) => income.code !== NETWORTH_CODE_NO_INCOME);
    setPrimaryIncomeTypes(filteredIncomeTypes?.slice(0, 4));
    setSecondaryIncomeTypes(map(slice(filteredIncomeTypes, 4), (obj) => ({ ...obj, isDisabled: false })));
  }, [incomeTypes, isCustomerFacing]);

  const [expandCollapsible, setExpandCollapsible] = useState<boolean[]>([false, false, false, false]);

  // on item checked set the form initial value
  const onToggle = async (item, index, noIncomeIndex) => {
    setFormTouched(false);
    scrollToNearest(pageRef, 'center');
    // set collapsible state to track the expandible div's
    await setExpandCollapsible((prevState) => {
      let newState;
      if (index === noIncomeIndex) {
        newState = prevState.map(() => false);
        newState[index] = !prevState[index];
        reset({ incomes: {} });
      } else {
        if (prevState[index]) {
          resetField(`incomes.${item.code}`);
        }
        newState = [...prevState];
        newState[index] = !prevState[index];
      }
      return newState;
    });

    if (item.code === NETWORTH_CODE_NO_INCOME) {
      setOtherIncomeOpen(false);
      setDisableCollapsible(!disableCollapsible);
      setNoIncomeData((prevState) =>
        isEmpty(prevState) ? { [`incomes.${item.code}`]: [initialFormValues(item)] } : null
      );
    } else {
      expandCollapsible[index]
        ? setValue(`incomes.${item.code}`, [])
        : setValue(`incomes.${item.code}`, [initialFormValues(item, isCustomerFacing)]);
      const expandedCount = expandCollapsible.filter(Boolean).length;
      setDisableNoIncome(!expandCollapsible[index] || expandedCount > 1);
    }
  };

  const onAddItem = (item) => {
    const allItems = watchIncomes[item.code];
    allItems.push(initialFormValues(item, isCustomerFacing));
    setValue(`incomes.${item.code}`, allItems);
  };

  const onRemoveItem = async (code, index) => {
    const incomeItems = await getValues(`incomes.${code}`);
    const updatedIncomeItems = incomeItems.filter((_, i) => i !== index);
    await resetField(`incomes.${code}`);
    await setValue(`incomes.${code}`, updatedIncomeItems);
  };

  // On add other incomes to primary incomes
  const onAddOtherIncomes = (income) => {
    if (!primaryIncomeTypes.includes(income)) {
      setOtherIncomeOpen(!otherIncomeOpen);
      //add secondary income above 'I don't earn'
      const primaryIncomes = primaryIncomeTypes.slice(0);
      const primaryIncomeInsertIndex = isCustomerFacing ? primaryIncomes.length - 1 : primaryIncomes.length;
      primaryIncomes.splice(primaryIncomeInsertIndex, 0, income);
      setPrimaryIncomeTypes(primaryIncomes);
      onToggle(income, primaryIncomeInsertIndex, isCustomerFacing ? primaryIncomeInsertIndex + 1 : undefined);
      setSecondaryIncomeTypes((prevData) => {
        return prevData.map((data) => {
          if (data.id === income.id) {
            return { ...data, isDisabled: true };
          }
          return data;
        });
      });
    }
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <div ref={pageRef}>
      {isCustomerFacing && (
        <h1>
          What kind of <span className="text-primary">income</span> do you have?
        </h1>
      )}
      <Form form={form} onSubmit={handleSubmit}>
        {primaryIncomeTypes?.map((income, incomeIndex) => (
          <div key={income.id}>
            <CollapsibleHeader
              checkbox={true}
              disabled={income.code === NETWORTH_CODE_NO_INCOME ? disableNoIncome : disableCollapsible}
              title={income.name}
              code={income.code}
              valid={formTouched}
              onCollapseChange={() =>
                onToggle(income, incomeIndex, isCustomerFacing ? primaryIncomeTypes.length - 1 : undefined)
              }
              open={expandCollapsible[incomeIndex]}
            >
              {watchIncomes[income.code] &&
                watchIncomes[income.code].map((item, index) => (
                  <div key={index}>
                    <IncomeItem
                      formData={watchIncomes}
                      register={register}
                      reference={pageRef}
                      index={index}
                      removeItem={onRemoveItem}
                      code={income.code}
                    ></IncomeItem>
                    {income.code === NETWORTH_CODE_NO_INCOME ||
                      (watchIncomes[income.code].length - 1 === index && (
                        <Button
                          className="mx-4 mb-3"
                          alignIcon="start"
                          onClick={() => onAddItem(income)}
                          size="small"
                          variant="text"
                        >
                          + Another {income.name === NETWORTH_CODE_SELF_EMPLOYED ? 'self employment' : income.name}
                        </Button>
                      ))}
                  </div>
                ))}
            </CollapsibleHeader>
          </div>
        ))}
        {/* Select other incomes options */}
        <CollapsibleHeader
          disabled={disableCollapsible}
          chevron
          title={'Other incomes'}
          valid={formTouched}
          open={otherIncomeOpen}
          onCollapseChange={() => setOtherIncomeOpen(!otherIncomeOpen)}
        >
          <>
            {secondaryIncomeTypes?.map((income, i, arr) => (
              <div key={income.id}>
                <button
                  type="button"
                  onClick={() => onAddOtherIncomes(income)}
                  className={`hover:bg-grey-1 flex w-full cursor-pointer items-center justify-between space-x-4 bg-white p-4 ${
                    income.isDisabled ? 'bg-grey-1 hover:bg-grey-1 cursor-not-allowed' : ''
                  }`}
                  disabled={income.isDisabled}
                >
                  <div
                    key={`income-${income.id}`}
                    className={`flex cursor-pointer items-center space-x-4 ${
                      income.isDisabled ? 'cursor-not-allowed' : ''
                    }`}
                  >
                    <Image
                      src={`/assets/images/${kebabCase(income.code)}.svg`}
                      className="grayscale"
                      alt={`${income.code}_image`}
                      width={18}
                      height={18}
                    />
                    <Label className={` ${income.isDisabled ? 'cursor-not-allowed' : ''}`}>
                      {capitalizeTitle(income.name)}
                    </Label>
                    <Divider className="text-grey-2 m-0 p-0" />
                  </div>
                </button>
                {i !== arr.length - 1 && <Divider className="text-grey-2 my-0" />}
              </div>
            ))}
          </>
        </CollapsibleHeader>
        {formTouched && <p className="text-error">Please select an option</p>}

        <Button
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          type="submit"
          className="mt-6"
          hasShadow
          isLoading={formSubmitting}
        >
          {isCustomerFacing ? 'Continue' : 'Save new income'}
        </Button>
      </Form>
    </div>
  );
}

import { useState } from 'react';
import { useBankStatementRefStatus } from '@harmoney/hooks';
import {
  useGetBankStatementReferencesByLoanApplicationIdQuery,
  useGetVariablesQuery,
  useRetryBankStatementReferenceMutation,
} from '@harmoney/redux';
import { Button, Card } from '@harmoney/ui-design-system';

import { CommonProps } from '../../common-props';
import { BankStatementSummaryRender } from '../BankStatementSummaryRender/BankStatementSummaryRender';

import { BankStatementSummaryAction } from './BankStatementSummaryAction';

export function BankStatementSummary({ taskId, completeTask }: CommonProps) {
  const { data: variables } = useGetVariablesQuery(taskId);
  const { data: bankStatementReferences } = useGetBankStatementReferencesByLoanApplicationIdQuery(
    variables?.loanApplicationId?.toString(),
    {
      skip: !variables,
      pollingInterval: 5000,
    }
  );
  const [updateReferenceRetry] = useRetryBankStatementReferenceMutation();

  const { allReferencesStatus } = useBankStatementRefStatus(bankStatementReferences);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleBankAdditionClick = () => {
    completeTask({ taskId, variables: { requestToAddBankStatement: true } });
  };

  const handleContinueClick = () => {
    setIsSubmitting(true);
    completeTask({ taskId, variables: { requestToAddBankStatement: false, retryBankStatement: false } });
  };

  const handleRetryClick = async (appReference: string) => {
    await updateReferenceRetry({ appReference });
    completeTask({ taskId, variables: { retryBankStatement: true } });
  };

  return (
    <>
      <h1>
        <span className="text-primary">Add all</span> of your bank statements
      </h1>

      <Card className="!p-4">
        <p>If you use multiple banks, please connect them individually.</p>
        <div className="p-2 text-center">
          <Button size="large" variant="text" onClick={handleBankAdditionClick}>
            Add another bank
          </Button>
        </div>
      </Card>

      <BankStatementSummaryRender
        bankStatementReferences={bankStatementReferences}
        handleRetryClick={handleRetryClick}
      />

      <BankStatementSummaryAction
        referencesStatus={allReferencesStatus}
        onClick={handleContinueClick}
        isSubmitting={isSubmitting}
      />
    </>
  );
}

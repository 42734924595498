import { IconV2 } from '../../Icon';
import { Label } from '../../Label';

interface FilenameBadgeProps {
  items: File[];
  onRemoveFile: (index: number) => void;
}

export const FilenameBadge = ({ items, onRemoveFile }: FilenameBadgeProps) => {
  const handleRemoveFile = (index: number) => {
    onRemoveFile(index);
  };

  return (
    <>
      {items.map((item: File, index: number) => (
        <Label
          key={index}
          className="leading-sm bg-grey-1 text-grey-5 mb-2 flex items-center justify-between rounded-lg bg-gray-200 p-2 px-3 text-center text-sm font-normal sm:w-full"
          style={{ maxWidth: '100%', display: 'flex', alignItems: 'center' }}
        >
          <span className="mr-2 max-w-[90%] truncate">{item?.name}</span>
          <IconV2
            icon="ic:round-close"
            width={30}
            className="h-3 w-3 fill-current text-blue-500"
            onClick={() => handleRemoveFile(index)}
          />
        </Label>
      ))}
    </>
  );
};

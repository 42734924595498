import React from 'react';
import { useQuoteData } from '@harmoney/hooks';
import { useGetQuoteOptionsQuery, useSubmitLoanApplicationMutation } from '@harmoney/redux';
import { Spinner } from '@harmoney/ui-design-system';

import { CommonProps } from '../../common-props';
import { createFormSchema, defaultValues, PersonalLoanQuote } from '../PersonalLoanQuote';

/**
 * @deprecated this component is not used anymore, could be deleted from 27/01/2025
 *
 **/
export function ValidQuoteV4(props: CommonProps) {
  const { quoteData } = useQuoteData(props.taskId);
  const [submitLoanApplication] = useSubmitLoanApplicationMutation();

  if (!quoteData) return <Spinner />;

  return (
    <PersonalLoanQuote
      {...props}
      isUnutilisedLimitLoan={false}
      createFormSchema={createFormSchema}
      defaultValues={defaultValues}
      submitLoanApplication={(data) => submitLoanApplication(data).unwrap()}
      useGetQuoteOptionsQuery={useGetQuoteOptionsQuery}
      quoteData={quoteData}
    />
  );
}

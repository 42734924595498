import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useMergedRef } from '@mantine/hooks';
import * as Progress from '@radix-ui/react-progress';
import classNames from 'classnames';

export type ProgressBarProps = {
  progress: number;
  className?: string;
  totalSteps?: number;
  indicatorClassName?: string;
};

export const ProgressBar = forwardRef<HTMLDivElement, ProgressBarProps>(
  ({ progress = 0, className, totalSteps, indicatorClassName }: ProgressBarProps, ref) => {
    // get the progress bar width
    const progressRef = useRef<HTMLDivElement>(null);
    const [progressWidth, setProgressWidth] = useState<number>(0);
    useEffect(() => {
      if (progressRef.current) {
        setProgressWidth(progressRef.current.offsetWidth);
      }
      // listen for window resize and update the progress bar width
      const handleResize = () => {
        if (progressRef.current) {
          setProgressWidth(progressRef.current.offsetWidth);
        }
      };
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [progressRef]);

    const mergedRef = useMergedRef(progressRef, ref);

    const positions = useMemo(() => {
      if (!totalSteps) return null;
      return [...Array(totalSteps - 1)].map((_, index) => {
        // spacing between each step
        const spacing = progressWidth / totalSteps;
        // first step is at 0
        if (index === 0) {
          return Math.round(spacing);
        } else {
          // position of each step in the progress bar starting from the left
          return Math.round(spacing * index + spacing);
        }
      });
    }, [progressWidth, totalSteps]);

    return (
      <Progress.Root
        className={classNames(className, 'rounded-pill bg-grey-2 relative h-3 w-full overflow-hidden')}
        value={progress}
        ref={mergedRef}
      >
        <Progress.Indicator
          className={classNames(
            indicatorClassName,
            'bg-primary rounded-l-pill h-full w-full transition-transform duration-500 ease-in-out'
          )}
          style={{ transform: `translateX(-${100 - progress}%)` }}
        />
        {totalSteps &&
          positions?.map((position, index) => (
            <span key={index} className="absolute top-0 h-full w-1 bg-white" style={{ left: `${position}px` }}></span>
          ))}
      </Progress.Root>
    );
  }
);

import Image from 'next/image';
import React from 'react';
import classNames from 'classnames';
import { cubicBezier, motion } from 'framer-motion';

export interface BouncyLoaderProps {
  withLogo?: boolean;
  withGradient?: boolean;
}

export const BouncyLoader: React.FC<BouncyLoaderProps> = ({ withLogo = true, withGradient = true }) => {
  const easing = cubicBezier(0.5, 0.05, 0.5, 0.95);
  const commonClasses = 'absolute inset-0 size-32 rounded-full m-auto';
  return (
    <>
      <motion.div
        key="loading0"
        className={classNames(
          commonClasses,
          withGradient ? 'bg-gradient-to-tl from-primary-darker-1 to-primary' : 'bg-primary'
        )}
        style={{ originX: 0.5, originY: 'top', scale: 0.25 }}
        initial={{ opacity: 1, x: 100, scale: 0.25 }}
        animate={{
          opacity: 1,
          y: [30, -50, 30],
          transition: { duration: 1.2, repeat: Infinity, ease: easing },
        }}
        exit={{ x: 0, width: 0, height: 0 }}
      />
      <motion.div
        key="loading1"
        className={classNames(commonClasses, {
          'bg-primary': withLogo || !withGradient,
          'bg-gradient-to-bl from-primary-darker-1 to-primary': withGradient && !withLogo,
        })}
        style={{ originX: 0.5, originY: 'top' }}
        initial={{ opacity: 1 }}
        animate={{
          opacity: 1,
          y: [-60, -100, -60],
          transition: { duration: 1.1, repeat: Infinity, ease: easing },
        }}
      >
        {withLogo && (
          <Image
            src="/assets/logos/hmy-coral-logo.svg"
            alt="Harmoney Logo"
            width="105"
            height="24"
            className="absolute inset-0 m-auto"
          />
        )}
      </motion.div>
      <motion.div
        key="loading2"
        className={classNames(
          commonClasses,
          withGradient ? 'bg-gradient-to-tr from-primary-darker-1 to-primary' : 'bg-primary'
        )}
        style={{ originX: 0.5, originY: 'top', scale: 0.5 }}
        initial={{ opacity: 1, x: -110, scale: 0.5 }}
        animate={{
          opacity: 1,
          y: [5, -70, 5],
          transition: { duration: 1, repeat: Infinity, ease: easing },
        }}
        exit={{ x: 0, width: 0, height: 0 }}
      />
    </>
  );
};

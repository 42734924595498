import { Fragment } from 'react';
import { FinancialProfileDto } from '@harmoney/api-interfaces';
import { Card, Divider, Label } from '@harmoney/ui-design-system';
import { AmountFormatter, amountOrDash, FrequencyFormatter } from '@harmoney/ui-utils';
import { capitalize } from 'lodash';

export const Debts = ({ financialSummary }: { financialSummary: FinancialProfileDto }) => {
  return (
    <Card className="!p-0">
      <div className="flex items-center justify-between">
        <Label className="p-4 text-lg font-medium">Debts</Label>
      </div>
      <Divider className="text-grey-2 m-0 p-0" />
      {financialSummary?.liabilities.length > 0 ? (
        financialSummary?.liabilities.map((liability, i) => {
          const {
            declaredAmount,
            repaymentAmount,
            frequency,
            networthSourceName,
            provider,
            isMortgageShared,
            debtType,
            creditLimit,
            paysOutstandingBalance,
            isJointMortgage,
            isResidence,
            jointMortgageAmount,
            jointMortgageFrequency,
          } = liability;
          return (
            <Fragment key={`liability-${i}`}>
              <div className="flex flex-row items-center justify-between p-4 pb-2">
                <div>
                  {debtType ? (
                    <Label className="text-base">{capitalize(debtType)}</Label>
                  ) : networthSourceName === `i don't have any debts` ? (
                    <Label className="text-base">Debts</Label>
                  ) : (
                    <Label className="text-base">{capitalize(networthSourceName)}</Label>
                  )}
                  {provider && networthSourceName === 'credit card' ? (
                    <Label className="text-xs">
                      ({capitalize(provider)} -{' '}
                      {paysOutstandingBalance === 'Yes' ? 'Paid off monthly' : 'Unpaid balance'})
                    </Label>
                  ) : (
                    provider && <Label className="text-xs">({capitalize(provider)})</Label>
                  )}
                  {isMortgageShared && <span className="text-xs">Shared mortgage</span>}
                </div>
                {declaredAmount && (
                  <span className="font-medium">
                    {AmountFormatter.format(declaredAmount)}/{FrequencyFormatter(frequency)}
                  </span>
                )}
                {repaymentAmount > 0 ? (
                  <span className="font-medium">
                    {AmountFormatter.format(repaymentAmount)}/{FrequencyFormatter(frequency)}
                  </span>
                ) : (
                  <span className="font-medium">{amountOrDash(repaymentAmount)}</span>
                )}
              </div>
              {isJointMortgage && networthSourceName === 'mortgage' && (
                <div key={`liability-jointMortgage-${i}`} className="flex flex-col px-4 pb-4">
                  <div className="mb-2 flex flex-row justify-between">
                    <span className="text-sm">Shared mortgage</span>
                    <span className="font-medium">{'Yes'}</span>
                  </div>
                  <div className="mb-2 flex flex-row justify-between">
                    <span className="text-sm">Mortgage contribution received</span>
                    <span className="font-medium">
                      {AmountFormatter.format(jointMortgageAmount)}/{FrequencyFormatter(jointMortgageFrequency)}
                    </span>
                  </div>
                  <div className="flex flex-row justify-between">
                    <span className="text-sm">Live on this property</span>
                    <span className="font-medium">{isResidence ? 'Yes' : 'No'}</span>
                  </div>
                </div>
              )}
              {creditLimit && (
                <div
                  key={`liability-creditLimit-${i}`}
                  className="flex flex-row items-center justify-between px-4 pb-4"
                >
                  <span className="text-sm">Credit Limit</span>
                  <span className="font-medium">{AmountFormatter.format(creditLimit)}</span>
                </div>
              )}
              {liability.currentBalance && (
                <div
                  key={`liability-currentBalance-${i}`}
                  className="flex flex-row items-center justify-between px-4 pb-4"
                >
                  <span className="text-sm">Current Balance</span>
                  <span className="font-medium">{AmountFormatter.format(liability.currentBalance)}</span>
                </div>
              )}
              {liability.outstandingBalance && (
                <div
                  key={`liability-outstandingBalance-${i}`}
                  className="flex flex-row items-center justify-between px-4 pb-4"
                >
                  <span className="text-sm">Outstanding Balance</span>
                  <span className="font-medium">{AmountFormatter.format(liability.outstandingBalance)}</span>
                </div>
              )}
              <Divider className="text-grey-1 m-0 px-4" />
            </Fragment>
          );
        })
      ) : (
        <span className="flex flex-row items-center justify-between p-4">No liabilities</span>
      )}
    </Card>
  );
};

var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"46daee41ed1515c98f6b7997b730f3c23434880b"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://11103a94f15449cf806632f67e701f7c@o4504636208513024.ingest.sentry.io/4504636239511552',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampler: (samplingContext) => {
    if (samplingContext.parentSampled !== undefined) {
      return samplingContext.parentSampled;
    }

    if (process.env.NEXT_PUBLIC_ENVIRONMENT_NAME !== 'production') {
      return 0.0;
    }
    if (samplingContext.transactionContext?.name?.includes('/admin')) {
      return 0.0;
    }
    return 0.2;
  },
  enabled: process.env.NEXT_PUBLIC_ENVIRONMENT_NAME === 'production',
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  // beforeSend is the last step that's evaluated before sending the event to Sentry
  beforeSend: (event) => {
    // We want to disable expected errors that's only seen on ios
    if (event.exception?.values?.[0]?.value?.includes('Non-Error promise rejection captured with keys')) {
      return null;
    }
    return event;
  },
});

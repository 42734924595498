import { LoanVariationSimulationDto } from '@harmoney/api-interfaces';
import { LoanVariation } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const loanVariationApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    simulateVariation: builder.mutation<string, { loanApplicationId: string; data: LoanVariationSimulationDto }>({
      query: ({ loanApplicationId, data }) => {
        return {
          url: `/loan-variations/${loanApplicationId}/simulations`,
          method: 'POST',
          body: data,
          responseHandler: 'text',
        };
      },
    }),
    getSimulationCsvById: builder.query<string, { loanApplicationId: string; simulationId: string }>({
      query: ({ loanApplicationId, simulationId }) => ({
        url: `/loan-variations/${loanApplicationId}/simulations/${simulationId}/csv`,
        method: 'GET',
        responseHandler: 'text',
      }),
    }),
    getAppliedCsvById: builder.query<string, { loanApplicationId: string; simulationId: string }>({
      query: ({ loanApplicationId, simulationId }) => ({
        url: `/loan-variations/${loanApplicationId}/applied/${simulationId}/csv`,
        method: 'GET',
        responseHandler: 'text',
      }),
    }),
    applySimulatedVariation: builder.mutation<void, { loanApplicationId: string; simulationId: string; note: string }>({
      query: ({ loanApplicationId, simulationId, note }) => {
        return {
          url: `/loan-variations/${loanApplicationId}/simulations/${simulationId}/apply`,
          method: 'POST',
          body: { note },
        };
      },
    }),
    getVariationRecordById: builder.query<LoanVariation, string>({
      query: (simulationId) => ({
        url: `/loan-variations/${simulationId}`,
        method: 'GET',
      }),
    }),
    getAllVariationsByLoanApplicationId: builder.query<LoanVariation[], string>({
      query: (loanApplicationId) => ({
        url: `/loan-variations/${loanApplicationId}/all-applied-variations`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useSimulateVariationMutation,
  useLazyGetSimulationCsvByIdQuery,
  useLazyGetAppliedCsvByIdQuery,
  useApplySimulatedVariationMutation,
  useGetVariationRecordByIdQuery,
  useLazyGetVariationRecordByIdQuery,
  useGetAllVariationsByLoanApplicationIdQuery,
} = loanVariationApi;

import { generateIcon } from '../../generateIcon';
import { IconVariant } from '../../Icon';

export const BoatIcon = function ({ variant = 'grey-4' }: { variant?: IconVariant }) {
  const Component = generateIcon({
    viewBox: '0 0 24 24',
    variant: variant,
    path: (
      <>
        <g clipPath="url(#clip0_4222_53356)">
          <path d="M11 13.0003V3.59034C11 3.10034 10.37 2.90034 10.09 3.30034L3.55 12.7103C3.32 13.0403 3.56 13.5003 3.96 13.5003H10.5C10.78 13.5003 11 13.2803 11 13.0003ZM20.99 12.9803C20.72 7.07034 15.9 2.32034 13.4 1.23034C13.03 1.07034 12.63 1.43034 12.73 1.82034C13.03 2.95034 13.49 5.10034 13.49 7.50034C13.49 9.94034 13 11.8903 12.71 12.8503C12.61 13.1703 12.85 13.5003 13.19 13.5003H20.47C20.76 13.5003 21 13.2603 20.99 12.9803ZM20.62 15.0003H3.38C2.65 15.0003 2.16 15.7603 2.46 16.4203C2.89 17.3403 3.53 18.1303 4.32 18.7303C4.7 18.5703 5.06 18.3503 5.38 18.1003C5.73 17.8103 6.25 17.8103 6.61 18.1003C7.28 18.6303 8.1 19.0003 9 19.0003C9.9 19.0003 10.72 18.6303 11.39 18.0903C11.74 17.8103 12.26 17.8103 12.61 18.0903C13.28 18.6303 14.1 19.0003 15 19.0003C15.9 19.0003 16.72 18.6303 17.39 18.0903C17.74 17.8003 18.26 17.8103 18.62 18.0903C18.94 18.3503 19.29 18.5703 19.68 18.7203C20.47 18.1203 21.11 17.3303 21.54 16.4103C21.84 15.7603 21.35 15.0003 20.62 15.0003ZM22 22.0003C22 21.4503 21.55 21.0003 21 21.0003C20.13 21.0003 19.27 20.7603 18.47 20.3003C18.18 20.1403 17.82 20.1303 17.53 20.3003C15.94 21.2003 14.06 21.2003 12.47 20.3003C12.18 20.1403 11.82 20.1403 11.53 20.3003C9.94 21.2003 8.06 21.2003 6.47 20.3003C6.18 20.1403 5.82 20.1403 5.53 20.3003C4.73 20.7603 3.87 21.0003 3 21.0003C2.45 21.0003 2 21.4503 2 22.0003C2 22.5503 2.45 23.0003 3 23.0003C4.15 23.0003 5.3 22.6903 6.33 22.0603C7.99 23.1703 10.11 23.0703 11.91 22.2003C13.82 23.2503 16.08 23.2703 18 22.2503C18.95 22.7503 19.97 23.0003 21 23.0003C21.55 23.0003 22 22.5503 22 22.0003Z" />
        </g>
        <defs>
          <clipPath id="clip0_4222_53356">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </>
    ),
  });
  return <Component />;
};

import { useMemo } from 'react';
import { useAppSelector, useGetAllLoanApplicationsByUserIdQuery } from '@harmoney/redux';
import { Spinner } from '@harmoney/ui-design-system';

import { CustomerApplicationListItem } from './CustomerApplicationListItem';

interface CustomerApplicationListProps {
  userId: string;
}

export const CustomerApplicationList = ({ userId }: CustomerApplicationListProps) => {
  const token = useAppSelector((state) => state?.accessToken?.value);
  const { data: loanApplicationsData, isLoading: isLoanApplicationsLoading } = useGetAllLoanApplicationsByUserIdQuery(
    userId as string,
    {
      skip: !userId || !token,
    }
  );

  const originalApplications = useMemo(() => {
    if (!loanApplicationsData) {
      return [];
    }
    const loanApplications = loanApplicationsData
      ?.slice()
      .filter((application) => !application.originalLoanApplicationId)
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    return loanApplications.map((application) => ({
      ...application,
      children: loanApplicationsData
        .filter((child) => child.originalLoanApplicationId === application.id)
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
    }));
  }, [loanApplicationsData]);

  if (isLoanApplicationsLoading) {
    return <Spinner />;
  }

  return (
    <div className="">
      <ul>
        <li className="border-grey-2 grid grid-cols-10 border-b p-4">
          <span className="col-span-2 mr-3 font-medium">Application number</span>
          <span className="col-span-1 ml-3 font-medium">Product</span>
          <span className="col-span-1 mx-1 font-medium">Opportunity</span>
          <span className="col-span-1 mx-1 font-medium">Loan purpose</span>
          <span className="col-span-1 ml-3 font-medium">Amount</span>
          <span className="col-span-1 ml-3 font-medium">Created on</span>
          <span className="col-span-1 mx-1 font-medium">Loan number</span>
          <span className="col-span-2 ml-6 font-medium">Status</span>
        </li>
      </ul>
      <ul>
        {originalApplications.map((loanApplication) => (
          <CustomerApplicationListItem key={loanApplication.id} loanApplication={loanApplication} />
        ))}
      </ul>
    </div>
  );
};

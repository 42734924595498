import { useRouter } from 'next/router';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  accountTypeToDebtType,
  FORM_KEY,
  Liability,
  NetworthSourceDto,
  NetworthSourceEnum,
  NetworthSourceKind,
} from '@harmoney/api-interfaces';
import { useFriendlyURL } from '@harmoney/hooks';
import {
  useAppDispatch,
  useAppSelector,
  useGetConsolidatedLiabilitiesQuery,
  useGetLoanProductByTaskIdQuery,
  useGetNetworthSourcesQuery,
  useGetUndisclosedDebtsQuery,
  useGetUserQuery,
  useGetVariablesQuery,
  useSaveLiabilitiesMutation,
} from '@harmoney/redux';
import { eventAnalytics, FINANCIALPROFILE_DEBTS_PROVIDED, isProduction } from '@harmoney/ui-app-shell';
import {
  Alert,
  ArrowCircleRightIcon,
  Button,
  CollapsibleHeader,
  Divider,
  Form,
  IconV2,
  Label,
  useForm,
} from '@harmoney/ui-design-system';
import { capitalizeTitle } from '@harmoney/ui-utils';
import { formatCurrency, isBooleanStringTrue, isDebtCon, type LoanProductName } from '@harmoney/utilities';
import { DevTool } from '@hookform/devtools';
import { useScrollIntoView } from '@mantine/hooks';
import { AssetAndLiabilityRecordOriginEnum } from '@prisma/client';
import { isArray, isBoolean, isEmpty, lowerCase, map, slice } from 'lodash';

import { setDebtFormData } from '../../../redux/slice/debt-form';
import { CommonProps } from '../../common-props';

import { DebtItem } from './DebtItem/DebtItem';
import { FormSchema, getFormSchemaByProductNameAndRelationshipStatus } from './form-config';
import { initialDebtFormValues } from './initialDebtFormValues';
import { NETWORTH_CODE_CAR_LOAN, NETWORTH_CODE_NO_DEBT, transformSubmittedData } from './util';

type DebtCode =
  | 'personal_loan'
  | 'credit_card'
  | 'overdraft'
  | 'other_debts'
  | 'mortgage'
  | 'buy_now_pay_later'
  | 'line_of_credit'
  | 'car_loan';

enum DebtTypeIcon {
  mortgage = 'ic:outline-house',
  personal_loan = 'ic:outline-money',
  credit_card = 'ic:outline-credit-card',
  fast_loan = 'ic:outline-timer',
  car_loan = 'ic:outline-directions-car',
  no_debt = 'ic:outline-block',
  overdraft = 'ic:outline-article',
  line_of_credit = 'ic:outline-restart-alt',
  other_debts = 'ic:outline-add-to-photos',
  buy_now_pay_later = 'hmy:bnpl',
}

export function Debt({ taskId, completeTaskWithData, taskFriendlyURL }: CommonProps) {
  useFriendlyURL(taskFriendlyURL);
  const router = useRouter();
  const userId = useAppSelector((state) => state.userId.value);
  const { data: user } = useGetUserQuery();
  const debtFormData = useAppSelector((state) => state.debtForm.data);
  const dispatch = useAppDispatch();

  const relationshipStatus = user?.relationshipStatus;

  const { data: debtTypes, isLoading } = useGetNetworthSourcesQuery(NetworthSourceKind.LIABILITY);
  const { data: loanProduct, isLoading: isLoanProductInfoLoading } = useGetLoanProductByTaskIdQuery(taskId);
  const {
    data: consolidatedLiabilities,
    isSuccess,
    isLoading: isPrefillLoading,
  } = useGetConsolidatedLiabilitiesQuery(null);

  const { data: variables } = useGetVariablesQuery(taskId);
  const loanApplicationId = variables?.loanApplicationId?.toString();

  const { data: undisclosedDebts, isLoading: isUndisclosedDebtsLoading } = useGetUndisclosedDebtsQuery(
    loanApplicationId,
    { skip: !loanApplicationId }
  );

  const [saveLiabilities] = useSaveLiabilitiesMutation();
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({ offset: 100 });

  const [primaryDebtTypes, setPrimaryDebtTypes] = useState<NetworthSourceDto[]>([]);
  const [secondaryDebtTypes, setSecondaryDebtTypes] = useState<(NetworthSourceDto & { isDisabled: boolean })[]>([]);
  const [otherDebtOpen, setOtherDebtOpen] = useState(false);
  const [noDebtData, setNoDebtData] = useState(null);
  const [formTouched, setFormTouched] = useState(false);
  const [disableCollapsible, setDisableCollapsible] = useState(false);
  const [expandCollapsible, setExpandCollapsible] = useState<boolean[]>([false, false, false, false]);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const debtConsolidationControl = ['mortgage', 'buy_now_pay_later', 'no_debt', 'other_debts'];
  const [debtConErrorMessage, setDebtConErrorMessage] = useState<string | React.ReactNode>(undefined);
  const [tmpExpandState, setTmpExpandState] = useState<boolean[]>([]);
  const [elementId, setElementId] = useState(null);
  const storedFormData = useRef({});

  const initialDebts = useMemo(() => {
    const debts = {};

    if (debtFormData) {
      return JSON.parse(debtFormData);
    }
    if (isEmpty(consolidatedLiabilities?.liability)) {
      return {};
    } else {
      if (isSuccess) {
        (consolidatedLiabilities?.liability as unknown as Liability[])?.map((liability: Liability) => {
          const debtType = Object.keys(accountTypeToDebtType).find((debtType) =>
            accountTypeToDebtType[debtType].includes(liability.accountType)
          );
          if (!debts[debtType]) {
            debts[debtType] = [];
          }
          let providerSuffix = '';
          switch (liability.accountType) {
            case 'PF':
              providerSuffix = ' (Fixed)';
              break;
            case 'PR':
              providerSuffix = ' (Revolving)';
              break;
            default:
              break;
          }

          // include creditLimit as property only for credit card
          const debt = () => {
            const returnObj = {
              provider: `${liability.organisation}${providerSuffix}`,
              networthSourceId: debtTypes?.find((debt) => debt.code === debtType).id,
              recordOrigin: AssetAndLiabilityRecordOriginEnum.prefill,
              creditFileReference: `${liability?.accountType}_${liability?.bureauAccountId}`,
              paidOff: isBoolean(liability.paidOff) ? liability.paidOff.toString() : undefined,
              interestRate: liability.interestRate,
            };
            if (debtType === 'credit_card') {
              return {
                ...returnObj,
                creditLimit: +liability.creditLimit > 0 ? liability.creditLimit : undefined,
                interestRate: undefined,
                outstandingBalance: null,
                paysOutstandingBalance: null,
              };
            }
            if (debtType === 'mortgage') {
              return {
                ...returnObj,
                isJointMortgageFromCreditFile: isBoolean(liability?.isJointMortgage) ? liability.isJointMortgage : null,
              };
            }
            return returnObj;
          };

          const elementId = `${debtType}-${debts[debtType].length}`;

          return debts[debtType].push({
            ...debt(),
            elementId,
          });
        });
      }
    }

    if (!isEmpty(undisclosedDebts)) {
      const debtType = NETWORTH_CODE_CAR_LOAN;
      if (!debts[debtType]) {
        debts[debtType] = [];
      }
      undisclosedDebts.map((debt) => {
        debts[debtType].push({
          provider: debt.provider,
          networthSourceId: debtTypes?.find((debt) => debt.code === debtType).id,
          recordOrigin: AssetAndLiabilityRecordOriginEnum.prefill,
          isFromJointBankAccount: debt.isFromJointBankAccount,
          paidOff: undefined,
          ownership: undefined,
          bankStatementAmount: debt.bankStatementAmount,
          bankStatementRepaymentAmount: debt.bankStatementRepaymentAmount,
          bankStatementRepaymentFrequency: debt.bankStatementRepaymentFrequency,
          bankReference: debt.bankReference,
        });
      });
    }

    return debts;
  }, [consolidatedLiabilities?.liability, isSuccess, debtTypes, debtFormData, undisclosedDebts]);

  const isInitialDebtsPrefill = useMemo(() => {
    if (!isEmpty(initialDebts)) {
      return Object.values(initialDebts).some(
        (arr) => isArray(arr) && arr.some((item) => item.recordOrigin === 'prefill')
      );
    }
  }, [initialDebts]);

  const form = useForm({
    mode: 'onSubmit',
    schema: getFormSchemaByProductNameAndRelationshipStatus(loanProduct?.name as LoanProductName, relationshipStatus),
    defaultValues: {
      debts: initialDebts,
    },
  });
  const {
    register,
    watch,
    setValue,
    getValues,
    reset,
    resetField,
    formState: { errors },
  } = form;

  const watchForm = watch();
  const watchDebts = watchForm.debts;

  useEffect(() => {
    if (!isEmpty(errors)) {
      console.error('errors', errors);
    }
  }, [errors]);

  useEffect(() => {
    if (targetRef) {
      scrollIntoView();
    }
  }, [scrollIntoView, targetRef, elementId]);

  useEffect(() => {
    if (!isEmpty(errors) && !isEmpty(errors?.debts)) {
      const errorFields = Object.keys(errors.debts);
      const firstFormFieldKeyWithError = primaryDebtTypes.find((primaryDebt) =>
        errorFields.includes(primaryDebt.code)
      )?.code;

      const index = errors.debts[firstFormFieldKeyWithError]?.findIndex(
        (item) => item && typeof item === 'object' && Object.keys(item).length > 0
      );
      setElementId(`${firstFormFieldKeyWithError}-${index}`);

      if (targetRef) {
        scrollIntoView();
      }
    }
  }, [errors, scrollIntoView, primaryDebtTypes, targetRef, elementId]);

  useMemo(() => {
    let debtTypesToUse = debtTypes;
    if (isDebtCon(loanProduct?.name)) {
      debtTypesToUse = debtTypes?.filter((debt) => debt.code !== NETWORTH_CODE_NO_DEBT);
    }
    if (!isEmpty(initialDebts)) {
      const sortedInitialDebts = Object.keys(initialDebts)
        .map((debtType) => debtTypesToUse?.find((debt) => debt.code === debtType))
        .sort((a, b) => a.id - b.id);
      reset({ debts: initialDebts });

      const flattenInitialDebts = Object.values(initialDebts).flatMap((debt) => debt as any);
      if (flattenInitialDebts.some((debt) => debt.recordOrigin === 'prefill')) {
        setPrimaryDebtTypes(sortedInitialDebts);
        setSecondaryDebtTypes(
          map(debtTypes, (obj) => ({
            ...obj,
            isDisabled: sortedInitialDebts.map((debt) => debt.code).includes(obj.code),
          })).filter((debt) => debt.code !== NETWORTH_CODE_NO_DEBT)
        );
      } else {
        setPrimaryDebtTypes(debtTypesToUse?.slice(0, 4));
        setSecondaryDebtTypes(map(slice(debtTypesToUse, 4), (obj) => ({ ...obj, isDisabled: false })));
      }
    } else {
      setPrimaryDebtTypes(debtTypesToUse?.slice(0, 4));
      setSecondaryDebtTypes(map(slice(debtTypesToUse, 4), (obj) => ({ ...obj, isDisabled: false })));
    }
    setExpandCollapsible((prevState) => {
      if (isEmpty(initialDebts)) {
        return prevState.map(() => false);
      } else {
        if (isInitialDebtsPrefill) {
          return Array.from(
            { length: Object.keys(initialDebts).length },
            (_, i) => i < Object.keys(initialDebts).length
          );
        } else {
          return prevState.map((_, i) => (initialDebts[debtTypesToUse[i]?.code] ? true : false));
        }
      }
    });
  }, [debtTypes, initialDebts, reset, loanProduct?.name, isInitialDebtsPrefill]);

  const onToggle = useCallback(
    (item: NetworthSourceDto, index: number, noDebtIndex: number) => {
      setFormTouched(false);

      // set collapsible state to track the expandible div's
      setExpandCollapsible((prevState) => {
        let newState;

        if (index === noDebtIndex) {
          if (expandCollapsible.some((item) => item === true && item !== expandCollapsible[index])) {
            setTmpExpandState(prevState);
          }

          newState = prevState[index] === true ? tmpExpandState : prevState.map(() => false);

          newState[index] = !prevState[index];
          if (newState[index]) {
            storedFormData.current = watchDebts;
            reset({ debts: {} });
          } else {
            newState[index] = tmpExpandState[index];
            reset({ debts: storedFormData.current });
          }
          return newState;
        } else {
          if (prevState[index]) {
            resetField(`debts.${item.code as DebtCode}`);
          }
          newState = [...prevState];
          newState[index] = !prevState[index];
        }
        return newState;
      });

      const { id, code } = item;

      if (code === NETWORTH_CODE_NO_DEBT) {
        setOtherDebtOpen(false);
        setDisableCollapsible(!disableCollapsible);

        if (!isEmpty(watchDebts)) {
          storedFormData.current = watchDebts;
          reset({ debts: {} });
        }
        setNoDebtData((prevState) =>
          isEmpty(prevState)
            ? {
                [`debts.${code}`]: [
                  initialDebtFormValues({ id, code, relationshipStatus, loanProduct: loanProduct?.name }),
                ],
              }
            : null
        );
        if (
          tmpExpandState.filter((_, i) => index !== i).some((item) => item === true) &&
          !Object.values(storedFormData.current).some((arr) => isArray(arr) && arr.length > 0)
        ) {
          reset({ debts: storedFormData.current });
          setNoDebtData(null);
        }
      } else {
        if (expandCollapsible[index]) {
          const debts = watchDebts;
          delete debts[code as DebtCode];
          reset({ debts });
        } else {
          const elementId = `${item.code}-0`;
          setValue(`debts.${code as DebtCode}`, [
            {
              ...initialDebtFormValues({ id, code, relationshipStatus, loanProduct: loanProduct?.name }),
              elementId,
            },
          ]);
          setElementId(elementId);
        }
      }
    },
    [
      disableCollapsible,
      expandCollapsible,
      reset,
      resetField,
      setValue,
      relationshipStatus,
      loanProduct,
      storedFormData,
      watchDebts,
      tmpExpandState,
    ]
  );

  const handleSubmit = async (data: FormSchema) => {
    data['debts'] = isEmpty(data['debts']) ? noDebtData : data['debts'];

    const noDebtToConsolidate = isDebtCon(loanProduct?.name)
      ? checkingIfNoDebtToConsolidate(data.debts, loanProduct?.maximumLoanAmount)
      : false;
    const isDebtConAmountUnderMinimum = isDebtCon(loanProduct?.name)
      ? checkIfDebtsAmountIsUnderMinBorrowingLimit(
          data.debts,
          loanProduct?.minimumLoanAmount,
          loanProduct?.maximumLoanAmount
        )
      : false;

    if (isDebtCon(loanProduct?.name) && (isEmpty(data.debts) || noDebtToConsolidate || isDebtConAmountUnderMinimum)) {
      setFormSubmitting(false);
      setFormTouched(true);
      if (noDebtToConsolidate) {
        setDebtConErrorMessage(
          <>
            <span className="text-sm">
              None of your debts are eligible for consolidation. Currently, we do not consolidate:
            </span>
            <ul className="list-disc ml-4">
              <li>Mortgage</li>
              <li>Buy now pay later</li>
              <li>Unspecified debt type</li>
              <li>Credit cards where outstanding balance is paid off each month</li>
              <li>Debts over {formatCurrency(loanProduct?.maximumLoanAmount)}</li>
            </ul>
          </>
        );
      } else if (isDebtConAmountUnderMinimum) {
        setDebtConErrorMessage(
          <p className="text-sm">
            {`For a debt consolidation loan, your eligible debts need to add up to the minimum loan amount of ${formatCurrency(loanProduct?.minimumLoanAmount)}.`}
          </p>
        );
      }
      return;
    } else if (!isDebtCon(loanProduct?.name) && isEmpty(data.debts)) {
      setFormSubmitting(false);
      setFormTouched(true);
      return;
    } else {
      setFormTouched(false);
    }

    setFormSubmitting(true);

    const transformedData = transformSubmittedData(data);

    eventAnalytics.track(FINANCIALPROFILE_DEBTS_PROVIDED, {
      userid_str: userId,
      taskid_str: taskId,
    });
    await completeTaskWithData({ taskId, formKey: FORM_KEY.DEBT_UPDATE, formData: { liabilities: transformedData } });
  };

  useEffect(() => {
    const handleRouteChange = () => {
      dispatch(setDebtFormData(''));
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  const onAddItem = (item) => {
    const allItems = watchDebts[item.code];
    const { id, code } = item;
    const elementId = `${item.code}-${allItems.length}`;

    const newItems = [
      ...allItems,
      {
        ...initialDebtFormValues({ id, code, relationshipStatus, loanProduct: loanProduct.name }),
        recordOrigin: 'manual',
        elementId,
      },
    ];

    setElementId(elementId);
    setValue(`debts.${item.code as DebtCode}`, newItems);
  };

  const onRemoveItem = (code: DebtCode, index: number) => {
    const debtItems = getValues(`debts.${code}`);
    const updatedDebtItems = (debtItems as any).filter((_, i) => i !== index);
    resetField(`debts.${code}`);
    setValue(`debts.${code}`, updatedDebtItems as any);
  };

  const splitDebts = ({ insertIndex, debt }) => {
    const primaryDebts = primaryDebtTypes.slice(0);
    primaryDebts.splice(insertIndex, 0, debt);
    setPrimaryDebtTypes(primaryDebts);
    onToggle(debt, insertIndex, insertIndex + 1);
  };

  // On add other debts to primary assets
  const onAddOtherDebts = (debt: NetworthSourceDto) => {
    if (!primaryDebtTypes.includes(debt)) {
      setOtherDebtOpen(!otherDebtOpen);
      if (isEmpty(initialDebts)) {
        splitDebts({
          insertIndex: isDebtCon(loanProduct?.name) ? primaryDebtTypes.length : primaryDebtTypes.length - 1,
          debt,
        });
      } else {
        splitDebts({ insertIndex: primaryDebtTypes.length, debt });
      }

      setSecondaryDebtTypes((prevData) => {
        return prevData.map((data) => {
          if (data.id === debt.id) {
            return { ...data, isDisabled: true };
          }
          return data;
        });
      });
    }
  };

  useEffect(() => {
    const handleRouteChange = () => {
      if (!isEmpty(watchDebts) && !noDebtData) {
        dispatch(setDebtFormData(JSON.stringify(watchDebts)));
      }
      if (noDebtData) {
        dispatch(setDebtFormData(''));
      }
    };
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events, watchDebts, noDebtData]);

  if (isLoading || isPrefillLoading || isLoanProductInfoLoading || isUndisclosedDebtsLoading) return null;

  return (
    <>
      <h1>
        {isInitialDebtsPrefill || isDebtCon(loanProduct?.name)
          ? 'Can you tell us about your '
          : 'Are you currently managing any '}
        <span className="text-primary">debts</span>?
      </h1>
      {((isInitialDebtsPrefill && !isDebtCon(loanProduct?.name)) || undisclosedDebts) && (
        <Alert variant="info">
          <span>These are the debts we found on your credit file/bank statement.</span>
        </Alert>
      )}
      {isDebtCon(loanProduct?.name) && (
        <Alert variant="info">
          <span>
            Make sure the information you provide is precise as this will influence your consolidation amount.
          </span>
        </Alert>
      )}
      <Form form={form} onSubmit={handleSubmit}>
        {primaryDebtTypes?.map((debt, debtIndex) => (
          <Fragment key={debt?.id}>
            <CollapsibleHeader
              valid={
                isDebtCon(loanProduct?.name) && debtConsolidationControl.includes(debt?.code) ? false : formTouched
              }
              checkbox={true}
              title={debt?.name}
              code={debt?.code}
              disabledActive={
                initialDebts?.[debt?.code]?.length > 0 &&
                initialDebts?.[debt?.code]?.some((item) => item.recordOrigin === 'prefill')
              }
              disabled={debt?.code === NETWORTH_CODE_NO_DEBT ? false : disableCollapsible}
              onCollapseChange={() =>
                onToggle(
                  debt,
                  debtIndex,
                  isInitialDebtsPrefill || isDebtCon(loanProduct?.name) ? -1 : primaryDebtTypes.length - 1
                )
              }
              withIconify={DebtTypeIcon[debt?.code]}
              open={
                expandCollapsible[debtIndex] ||
                (initialDebts[debt?.code] && isInitialDebtsPrefill && isEmpty(noDebtData))
              }
            >
              {isDebtCon(loanProduct?.name) && debtConsolidationControl.includes(debt?.code) && (
                <DebtConAlert code={debt?.code} />
              )}
              {watchDebts?.[debt?.code]?.map((item, index) => (
                <div key={index} ref={item.elementId === elementId ? targetRef : null}>
                  <DebtItem
                    formData={watchDebts}
                    register={register}
                    removeItem={onRemoveItem}
                    index={index}
                    code={debt?.code}
                    item={item}
                    loanProduct={loanProduct}
                    relationshipStatus={relationshipStatus}
                  />
                  {debt.code === NETWORTH_CODE_NO_DEBT ||
                    (watchDebts[debt?.code].length - 1 === index && (
                      <Button
                        className="mx-4 mb-3"
                        onClick={() => onAddItem(debt)}
                        alignIcon="start"
                        size="small"
                        variant="text"
                        key={`add_another_${debt?.code}`}
                      >
                        + Another {debt?.name}
                      </Button>
                    ))}
                </div>
              ))}
            </CollapsibleHeader>
          </Fragment>
        ))}
        {/* Select other debts options */}
        <CollapsibleHeader
          onCollapseChange={() => setOtherDebtOpen(!otherDebtOpen)}
          chevron
          disabled={disableCollapsible}
          valid={formTouched}
          title={isEmpty(initialDebts) ? 'More debts' : 'Do you have any other debts?'}
          withIconify="ic:outline-format-list-bulleted"
          open={otherDebtOpen}
        >
          <>
            {secondaryDebtTypes?.map((debt, index) => (
              <div key={`debt_${debt.id}`}>
                <button
                  type="button"
                  onClick={() => onAddOtherDebts(debt)}
                  className={`hover:bg-grey-1 flex w-full cursor-pointer items-center justify-between space-x-4 bg-white p-4 ${
                    debt.isDisabled ? 'cursor-not-allowed hover:bg-white' : ''
                  }`}
                  disabled={debt.isDisabled}
                >
                  <div
                    key={`debt-${debt.id}`}
                    className={`flex cursor-pointer items-center space-x-4 ${
                      debt.isDisabled ? 'cursor-not-allowed' : ''
                    }`}
                  >
                    <IconV2
                      icon={DebtTypeIcon[debt.code]}
                      width={24}
                      className={debt.isDisabled ? 'text-grey-2' : 'text-grey-3'}
                    />
                    <Label className={`${debt.isDisabled ? '!text-grey-2 cursor-not-allowed' : ''}`}>
                      {capitalizeTitle(debt.name)}
                    </Label>
                    <Divider className="text-grey-2 m-0 p-0" />
                  </div>
                </button>
                {index !== secondaryDebtTypes.length - 1 && <Divider className="text-grey-2 my-0" />}
              </div>
            ))}
          </>
        </CollapsibleHeader>
        {/* Select other debts options */}
        {formTouched && !isDebtCon(loanProduct?.name) && <p className="text-error">Please select an option</p>}
        {formTouched && isDebtCon(loanProduct?.name) && <Alert variant="error">{debtConErrorMessage}</Alert>}

        <Button
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          type="submit"
          className="mt-6"
          hasShadow
          isLoading={formSubmitting}
        >
          Continue
        </Button>
      </Form>
      {!isProduction() && (
        <DevTool
          control={form.control}
          placement="bottom-left"
          styles={{
            button: { width: '50px', height: '50px' },
            panel: { zIndex: 10000, overflow: 'visible', height: '765px', width: '230px' },
          }}
        />
      )}
    </>
  );
}

const DebtConAlert = ({ code }) => {
  if (code === 'mortgage' || code === 'buy_now_pay_later') {
    return (
      <Alert variant="info" className="mb-4 mx-4" key={`alert_provide_${code}`}>
        <p className="text-sm">{`We don't provide debt consolidation for ${lowerCase(code)}.`}</p>
      </Alert>
    );
  }
  if (code === 'other_debts') {
    return (
      <Alert variant="info" className="mb-4 mx-4" key={`alert_provide_${code}`}>
        <p className="text-sm">
          We currently only offer debt consolidation for specific debt types. Any debts listed here won&apos;t be
          available for consolidation.
        </p>
      </Alert>
    );
  }
  return null;
};

function getDebtsToConsolidate(debts: FormSchema['debts'], maxBorrowingLimit: number) {
  const debtConsolidationControl = ['mortgage', 'buy_now_pay_later', 'other_debts'];
  const debtsToConsolidate = [];
  if (debts) {
    Object.entries(debts).forEach(([key, value]) => {
      if (!debtConsolidationControl.includes(key)) {
        (
          value as unknown as FormSchema['debts'][
            | 'credit_card'
            | 'personal_loan'
            | 'overdraft'
            | 'car_loan'
            | 'line_of_credit']
        )
          .filter((item) => !isBooleanStringTrue(item.paidOff))
          .filter((item) => (item as unknown as any).outstandingBalance <= maxBorrowingLimit)
          .filter(
            (item) =>
              item.networthSourceId !== NetworthSourceEnum.LIABILITY_CREDIT_CARD_ID ||
              (item.networthSourceId === NetworthSourceEnum.LIABILITY_CREDIT_CARD_ID &&
                !isBooleanStringTrue(item.paysOutstandingBalance))
          )
          .map((item) => {
            debtsToConsolidate.push(item);
          });
      }
    });
  }
  return debtsToConsolidate;
}

function checkingIfNoDebtToConsolidate(debts: FormSchema['debts'], maxBorrowingLimit: number) {
  let noDebtToConsolidate = true;
  const debtsToConsolidate = getDebtsToConsolidate(debts, maxBorrowingLimit);

  if (debtsToConsolidate.length > 0) {
    noDebtToConsolidate = false;
  }
  return noDebtToConsolidate;
}

function checkIfDebtsAmountIsUnderMinBorrowingLimit(
  debts: FormSchema['debts'],
  minBorrowingLimit: number,
  maxBorrowingLimit: number
) {
  let isDebtsAmountUnderMinimum = false;
  const debtsToConsolidate = getDebtsToConsolidate(debts, maxBorrowingLimit);
  if (debtsToConsolidate.length > 0) {
    const totalDebtsAmount = debtsToConsolidate.reduce((acc, item) => acc + item.outstandingBalance, 0);
    if (totalDebtsAmount < minBorrowingLimit) {
      isDebtsAmountUnderMinimum = true;
    }
  }
  return isDebtsAmountUnderMinimum;
}

import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { TaskDetailDto } from '@harmoney/api-interfaces';

export const useTaskFriendlyURL = (taskDetail: TaskDetailDto) => {
  const [TaskFriendlyURL, setTaskFriendlyURL] = useState(null);
  const router = useRouter();

  useEffect(() => {
    if (!taskDetail) {
      setTaskFriendlyURL(null);
      return;
    }
    const baseUrl = taskDetail.rootPath ? `${taskDetail.rootPath}/` : '';
    const path = `${taskDetail.urlMap?.[taskDetail.taskDefinitionId]}`;
    setTaskFriendlyURL(path ? `${baseUrl}${path}` : 'undefined');
    return () => {
      setTaskFriendlyURL(null);
    };
  }, [taskDetail, router]);

  return TaskFriendlyURL;
};

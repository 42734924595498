import { VaultLoanInformationDto } from '@harmoney/api-interfaces';
import { Badge, CollapsibleCard } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';

import { Field } from '../shared';

interface Props {
  loanInformationData: VaultLoanInformationDto;
  collectionStatus?: string;
}

export const CollectionsInformationDetails = ({ loanInformationData, collectionStatus }: Props) => {
  const arrearsStatusBadgeVariant = {
    'Good Standing': 'success',
    'Bad Standing': 'action',
  };

  if (!loanInformationData) return null;

  return (
    <CollapsibleCard title="Collections information" className="mb-6">
      <div className="grid grid-cols-2 px-4">
        <div className="border-r-grey-1 border-r-2 p-4">
          <Field>
            <span>Arrears status:</span>
            <Badge
              variant={arrearsStatusBadgeVariant[loanInformationData?.arrearsStatus]}
              label={loanInformationData?.arrearsStatus}
            />
          </Field>
          <Field>
            <span>Collections status</span>
            <span>{collectionStatus ? collectionStatus : 'No status'}</span>
          </Field>
          <Field>
            <span>Sub status:</span>
            <span>N/A</span>
          </Field>
          <Field>
            <span>Write off reason:</span>
            <span className="font-medium">{loanInformationData?.writeOffReason}</span>
          </Field>
        </div>
        <div className="p-4">
          <Field>
            <span>Written off amount:</span>
            <span>{formatCurrency(loanInformationData?.writtenOffAmount) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Written off principal:</span>
            <span>{formatCurrency(loanInformationData?.principalWrittenOff) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Written off interest:</span>
            <span>{formatCurrency(loanInformationData?.interestWrittenOff) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Written off penalities:</span>
            <span>{formatCurrency(loanInformationData?.penaltiesWrittenOff) ?? 'N/A'}</span>
          </Field>
        </div>
      </div>
    </CollapsibleCard>
  );
};

import { useRouter } from 'next/router';
import React, { useMemo, useState } from 'react';
import { OriginationType, UnutilisedLimitEligibilityDto } from '@harmoney/api-interfaces';
import { LoanApplicationQueryResponse, useAppSelector, useGetLatestOriginationProcessIdQuery } from '@harmoney/redux';
import { ArrowCircleRightIcon, Button, Card, ProgressBar } from '@harmoney/ui-design-system';
import { clearLocalStorageByPattern, PATTERN_LOAN_QUOTE_DETAILS_KEY } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import { Icon } from '@iconify/react';
import { useLocalStorage } from '@mantine/hooks';

type Props = {
  loanApplication: LoanApplicationQueryResponse & {
    unutilisedLimitEligibility: UnutilisedLimitEligibilityDto;
  };
};

export const UnutilisedLimitCard = ({ loanApplication }: Props) => {
  const [cardClosed, setCardClosed] = useLocalStorage({
    key: `unutilised-limit-card-${loanApplication.id}-${loanApplication?.unutilisedLimitEligibility?.displayTitle}-dismissed`,
    defaultValue: false,
  });
  const accessToken = useAppSelector((state) => state.accessToken.value);
  const router = useRouter();
  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  const { data: origination, isLoading: isProcessLoading } = useGetLatestOriginationProcessIdQuery(null, {
    skip: !accessToken,
  });
  const remainingAmount = useMemo(() => loanApplication?.unutilisedLimitEligibility?.amount, [loanApplication]);

  if (
    !loanApplication.unutilisedLimitEligibility?.isEligible &&
    !loanApplication.unutilisedLimitEligibility?.displayMessage
  ) {
    return null;
  }
  if (cardClosed) {
    return null;
  }

  const handleClick = () => {
    clearLocalStorageByPattern(PATTERN_LOAN_QUOTE_DETAILS_KEY);
    router.push(
      `journey?journeyId=${origination?.processId}&originalLoanApplicationId=${loanApplication.id}&originationType=${OriginationType.UNUTILISED_LIMIT}`
    );
  };

  const handleClose = () => {
    setCardClosed(true);
  };

  return (
    <Card className="flex flex-col !p-0 overflow-auto">
      <div className="flex flex-row justify-between px-2 pt-4">
        <div className="flex flex-row items-center">
          <span className="font-medium text-lg ml-2">Borrow more</span>
        </div>
        {loanApplication.unutilisedLimitEligibility?.displayMessage && !cardClosed && (
          <Icon
            icon="ic:close"
            className="text-grey-4 cursor-pointer"
            width={24}
            height={24}
            onClick={() => handleClose()}
          />
        )}
      </div>
      {loanApplication.unutilisedLimitEligibility?.isEligible && (
        <>
          <div className="mx-4 grid grid-cols-2 mt-4">
            <div className="col-span-1 flex flex-col items-stretch justify-start space-y-0">
              <span className="text-xs">Borrowed amount</span>
              <span className="text-lg font-medium">{formatCurrency(loanApplication.fundedAmount)}</span>
            </div>
            <div className="col-span-1 flex flex-col items-stretch justify-center space-y-0 text-end">
              <span className="text-xs">Available limit</span>
              <span className="text-lg font-medium">{formatCurrency(remainingAmount)}</span>
            </div>
          </div>
          <div className="mx-4 mt-4">
            <ProgressBar
              progress={
                ((loanApplication.fundedAmount + loanApplication.establishmentFee) /
                  loanApplication.quotePresentedAmount) *
                100
              }
              className="bg-gradient-to-r from-tertiary to-tertiary-darker-1"
              indicatorClassName="!bg-grey-2"
            />
          </div>
          <div className="flex flex-col items-stretch justify-center gap-4 p-4">
            <div className="flex flex-col gap-4 text-grey-4 text-xs">
              Approved for {formatCurrency(loanApplication.quotePresentedAmount)} at{' '}
              {loanApplication.finalInterestRate.toString()}% (p.a.)
              <br />
              No establishment fee | Expires on {loanApplication?.unutilisedLimitEligibility?.reason}
            </div>
            {!isProcessLoading && (
              <Button
                variant="primary"
                className="mx-auto !mt-4"
                alignIcon="end"
                icon={<ArrowCircleRightIcon size="large" />}
                onClick={handleClick}
              >
                Borrow more
              </Button>
            )}
          </div>
          <div
            className="border-t-grey-2 border-t p-4 flex align-center justify-between justify-items-start"
            onClick={() => setOpenMoreInfo(!openMoreInfo)}
          >
            <Button variant="text" className="mx-auto !mt-0 ml-0" size="medium">
              {!openMoreInfo ? 'More info' : 'Less info'}
            </Button>
            <Icon
              icon={!openMoreInfo ? 'ic:baseline-keyboard-arrow-down' : 'ic:baseline-keyboard-arrow-up'}
              className="mx-auto mr-0 text-secondary"
              width={24}
            />
          </div>
          {openMoreInfo && (
            <div className="p-4 max-w-md">
              <p className="font-medium">Will it be a separate loan if I borrow more?</p>
              <p className="mt-4 flex">
                Yes, you&rsquo;ll get a new loan the next time you borrow. This way we can offer you flexibility to
                access funds when you need them and the ability to choose your own loan terms with no additional setup
                fee.
              </p>
              <p className="font-medium mt-4 flex">What happens after the expiry date?</p>
              <p className="mt-4 flex">
                Once your funds expire, you will no longer have access to them and will need to apply for a new quote.
              </p>
            </div>
          )}
        </>
      )}

      {!loanApplication.unutilisedLimitEligibility?.isEligible && (
        <div className="flex flex-col items-stretch justify-center gap-4 p-4 max-w-md">
          {loanApplication.unutilisedLimitEligibility?.displayTitle && (
            <span>{loanApplication.unutilisedLimitEligibility?.displayTitle}</span>
          )}
          {loanApplication.unutilisedLimitEligibility?.displayMessage && (
            <div className="flex flex-col gap-4 text-grey-4">
              {loanApplication.unutilisedLimitEligibility?.displayMessage}
            </div>
          )}
          {loanApplication.unutilisedLimitEligibility?.displayCatchupOnYourPayments && (
            <Button
              variant="text"
              className="mx-auto !mt-4 !max-w-md"
              onClick={() => router.push(`extra-payments?loanApplicationId=${loanApplication.id}&from=catchup`)}
            >
              Catch up on your repayments
            </Button>
          )}
        </div>
      )}
    </Card>
  );
};

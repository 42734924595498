import { useMemo } from 'react';
import { useGetVariablesQuery } from '@harmoney/redux';
import { isEmpty } from 'lodash';

export const useQuoteData = (taskId: string) => {
  const { data: variables, isLoading, refetch } = useGetVariablesQuery(taskId, { refetchOnMountOrArgChange: true });

  const quoteData = useMemo(() => {
    const quoteFromCamundaAvailable = !isEmpty(variables?.['offer']?.['quote']) || !isEmpty(variables?.['quote']);
    if (!isLoading && quoteFromCamundaAvailable) {
      return variables?.['offer']?.['quote'] || variables['quote'];
    }
    // refetch if quote is not available
    if (!isLoading && !quoteFromCamundaAvailable) {
      refetch();
    }
  }, [variables, isLoading]);

  return { quoteData, variables, isLoading };
};

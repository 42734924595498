import { LoanApplicationStatusEnum } from '@prisma/client';

/* The `loanStatusMapper` is a constant variable that maps the enum values of
`LoanApplicationStatusEnum` to their corresponding string representations. */
export const loanStatusMapper: Record<LoanApplicationStatusEnum, string> = {
  [LoanApplicationStatusEnum.quote_in_progress]: 'Quote - in progress',
  [LoanApplicationStatusEnum.quote_in_review]: 'Quote - in review',
  [LoanApplicationStatusEnum.quote_offered]: 'Quote - offered',
  [LoanApplicationStatusEnum.quote_unsuccessful]: 'Quote - unsuccessful',
  [LoanApplicationStatusEnum.quote_expired]: 'Quote - expired',
  [LoanApplicationStatusEnum.quote_cancelled]: 'Quote - cancelled',
  [LoanApplicationStatusEnum.application_in_progress]: 'Application - in progress',
  [LoanApplicationStatusEnum.application_successful]: 'Application - successful',
  [LoanApplicationStatusEnum.application_unsuccessful]: 'Application - unsuccessful',
  [LoanApplicationStatusEnum.application_expired]: 'Application - expired',
  [LoanApplicationStatusEnum.application_cancelled]: 'Application - cancelled',
};

export const loanStatusBadgeMapper: Record<
  LoanApplicationStatusEnum,
  'success' | 'secondary' | 'tertiary' | 'action' | 'warning'
> = {
  [LoanApplicationStatusEnum.quote_in_progress]: 'secondary',
  [LoanApplicationStatusEnum.quote_in_review]: 'warning',
  [LoanApplicationStatusEnum.quote_offered]: 'success',
  [LoanApplicationStatusEnum.quote_unsuccessful]: 'action',
  [LoanApplicationStatusEnum.quote_expired]: 'tertiary',
  [LoanApplicationStatusEnum.quote_cancelled]: 'tertiary',
  [LoanApplicationStatusEnum.application_in_progress]: 'secondary',
  [LoanApplicationStatusEnum.application_successful]: 'success',
  [LoanApplicationStatusEnum.application_unsuccessful]: 'action',
  [LoanApplicationStatusEnum.application_expired]: 'tertiary',
  [LoanApplicationStatusEnum.application_cancelled]: 'tertiary',
};

/* The `loanStatusReverseMapper` is a constant variable that maps string values to the corresponding
enum values of the `LoanApplicationStatusEnum` enum. */
export const loanStatusReverseMapper: Record<string, LoanApplicationStatusEnum> = {
  'Quote - in progress': LoanApplicationStatusEnum.quote_in_progress,
  'Quote - in review': LoanApplicationStatusEnum.quote_in_review,
  'Quote - offered': LoanApplicationStatusEnum.quote_offered,
  'Quote - unsuccessful': LoanApplicationStatusEnum.quote_unsuccessful,
  'Quote - expired': LoanApplicationStatusEnum.quote_expired,
  'Quote - cancelled': LoanApplicationStatusEnum.quote_cancelled,
  'Application - in progress': LoanApplicationStatusEnum.application_in_progress,
  'Application - successful': LoanApplicationStatusEnum.application_successful,
  'Application - unsuccessful': LoanApplicationStatusEnum.application_unsuccessful,
  'Application - expired': LoanApplicationStatusEnum.application_expired,
  'Application - cancelled': LoanApplicationStatusEnum.application_cancelled,
};

export const loanStatusEmojiMapper: Record<LoanApplicationStatusEnum, string> = {
  [LoanApplicationStatusEnum.quote_in_progress]: '⏳',
  [LoanApplicationStatusEnum.quote_in_review]: '🤔',
  [LoanApplicationStatusEnum.quote_offered]: '🚀',
  [LoanApplicationStatusEnum.quote_unsuccessful]: '😢',
  [LoanApplicationStatusEnum.quote_expired]: '🚫',
  [LoanApplicationStatusEnum.quote_cancelled]: '❌',
  [LoanApplicationStatusEnum.application_in_progress]: '🚀',
  [LoanApplicationStatusEnum.application_successful]: '💰',
  [LoanApplicationStatusEnum.application_unsuccessful]: '😢',
  [LoanApplicationStatusEnum.application_expired]: '🚫',
  [LoanApplicationStatusEnum.application_cancelled]: '❌',
};

export const loanStatusShortLabelMapper = Object.keys(loanStatusMapper).reduce(
  (acc, key) => {
    const value = loanStatusMapper[key as LoanApplicationStatusEnum];
    // Remove the prefix before the first dash and trim whitespace
    const withoutTitle = value.split('-')[1].trim();
    acc[key as LoanApplicationStatusEnum] = withoutTitle;
    return acc;
  },
  {} as Record<LoanApplicationStatusEnum, string>
);

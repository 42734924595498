import { RepaymentFrequencyEnum } from '@prisma/client';
import { RepaymentFrequency } from '../common';

export interface Quote {
  requestedAmount: number;
  termInMonths: number;
  interestRate: number;
  maximumBorrowingLimit: number;
  minimumBorrowingLimit: number;
  establishmentFeeThreshold: number;
  establishmentFeeUnderThreshold: number;
  establishmentFeeOverThreshold: number;
  loanLimitFromUmiForTerms: LoanLimitFromUmiForTerms;
  borrowingLimitForLoanPurpose: number;
  maximumProductLimit?: number;
  loanLimitFromRisk: number;
}

export interface QuoteOption {
  loanAmount: number;
  establishmentFee: number;
  termInMonths: number;
  interestRate: number;
  loanLimitFromUmi: number;
  repaymentAmount: number;
  repaymentFrequency: RepaymentFrequency | RepaymentFrequencyEnum;
}

export interface LoanLimitFromUmiForTerms {
  loanLimitFromUmiForThreeYears: number;
  loanLimitFromUmiForFiveYears: number;
  loanLimitFromUmiForSevenYears: number;
}

export interface EstablishmentFeeWithTerms {
  establishmentFeeForThreeYears: number;
  establishmentFeeForFiveYears: number;
  establishmentFeeForSevenYears: number;
}

export type ProgressStatus = 'completed' | 'in-progress' | 'not-started';

export type UiQuoteStatus = {
  displayOrder: number;
  name: string;
  taskDefinitionIds: string[];
};

export interface LocalQuoteDetails extends QuoteOption {
  primaryPurpose: string;
  primaryPurposeId: string;
  loanApplicationId: string;
  quotePresentedAmount: number;
}

export const LOCAL_QUOTE_DETAILS_KEY = 'local-quote-details';

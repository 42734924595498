import { kebabCase } from 'lodash';

export const initialFormExpenseValues = ({ id, code }) => {
  const commomValues = {
    networthSourceId: id,
    declaredAmount: null,
    frequency: '',
  };
  const formConfig = {
    'other-expenses': {
      otherExpenseType: '',
      ...commomValues,
    },
    'no-expense': {
      networthSourceId: id,
    },
  };

  const defaultValue = {
    ...commomValues,
  };

  const expenseFormValues = formConfig[kebabCase(code)];

  return expenseFormValues || defaultValue;
};

import dayjs from 'dayjs';
import { capitalize, trim } from 'lodash';

/**
 *
 * The function stringToBool() takes one parameters:
 * @param {value} string - The value accepts Yes/No.

 */
export function stringToBool(value: string): boolean {
  return value?.toLowerCase() === 'yes';
}

/**
 *
 * The function replaceChar() takes two parameters:
 * @param {value} string - The value that needs to be formated.
 * @param {char} string - default is space

 */
export function replaceChar(value: string, char = ' '): string {
  return value?.replace(/_/g, char);
}

/**
 *
 * The function capitalizeTitle() takes one parameters:
 * @param {title} string - the title should be string.
 *
 */

export function capitalizeTitle(title: string): string {
  if (title && title.includes('/')) {
    const [beforeSlash, afterSlash] = title.split('/');
    const result = `${capitalize(trim(beforeSlash))}/${capitalize(trim(afterSlash))}`;
    return result;
  }
  return capitalize(trim(title));
}

export function isNonZeroPositiveInteger(value: number): boolean {
  return value !== 0 && value > 0;
}

/**
 *
 * The function scrollToNearest() takes one parameters:
 * @param {ref} ref - The ref of the element that needs to be scrolled to.
 */

export function scrollToNearest(ref, blockValue?: string) {
  const block = blockValue ? blockValue : 'nearest';
  const element = ref.current;
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: block });
  }
}

export function getExpiryDateByDays(date, numberOfDays = 60) {
  const startDate = dayjs(date);
  const expiryDate = startDate.add(numberOfDays, 'day').format('DD MMMM YYYY');
  return expiryDate;
}

import { NetworthSourceEnum } from '@harmoney/api-interfaces';
import { CurrencyFormatter } from '@harmoney/ui-utils';
import { AssetAndLiability, NetworthSource } from '@prisma/client';

interface AssetDetailsProps {
  assetData: (AssetAndLiability & {
    networthSource: NetworthSource;
  })[];
}

export const AssetDetails = ({ assetData }: AssetDetailsProps) => {
  return (
    <div className="grid grid-cols-2 px-4">
      {assetData?.map((asset) => {
        return (
          <div key={asset.id} className="odd:border-r-grey-1 p-4 odd:border-r-2">
            <span className="grid grid-cols-2">
              <span>Asset type:</span>
              <span className="font-medium">{asset?.networthSource.name ?? 'N/A'}</span>
            </span>
            {asset?.assetName && (
              <span className="grid grid-cols-2">
                <span>Asset Name:</span>
                <span>{asset?.assetName}</span>
              </span>
            )}
            {asset?.networthSource.id !== NetworthSourceEnum.ASSET_NO_ID && (
              <span className="grid grid-cols-2">
                <span>Amount:</span>
                <span>{CurrencyFormatter.format(asset.declaredAmount)}</span>
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};

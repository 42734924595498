import { generateIcon } from '../generateIcon';

/**
 * @deprecated Check if it can be replaced with Iconify. If not, add it to icon.json.
 * Refer to icon document for details: https://63be80b51f526a24fc7865c5-tslqcdnosl.chromatic.com/?path=/docs/docs-icons--docs
 */
export const ClockLightningIcon = generateIcon({
  viewBox: '0 0 24 24',
  variant: 'none',
  path: (
    <>
      <g clipPath="url(#clip0_23_1407)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 1H15V3H9V1ZM13 8V14H11V8H13ZM20.45 5.97L19.03 7.39C19.6535 8.17069 20.1512 9.05675 20.4903 10.0169C20.3283 10.0057 20.1648 10 20 10C19.4533 10 18.9213 10.0627 18.4106 10.1812C17.3275 7.71758 14.8668 6 12 6C8.13 6 5 9.13 5 13C5 16.87 8.13 20 12 20C12.5473 20 13.0798 19.9374 13.5908 19.819C13.8753 20.4648 14.2545 21.0596 14.711 21.5856C13.8558 21.8548 12.9452 22 12 22C7.02 22 3 17.97 3 13C3 8.03 7.03 4 12 4C14.12 4 16.07 4.74 17.62 5.98L19.04 4.56C19.55 4.98 20.02 5.46 20.45 5.97Z"
          fill="#206EEE"
        />
        <path
          d="M18.5663 23.5936C18.3214 23.5936 18.1325 23.3767 18.1675 23.1318L18.7972 18.6961H16.3484C15.7327 18.6961 16.1176 18.1713 16.1315 18.1503C17.0131 16.5901 18.3354 14.2813 20.0845 11.2029C20.1545 11.077 20.2944 11 20.4343 11C20.6792 11 20.8681 11.2169 20.8331 11.4618L20.2035 15.8975H22.6592C22.9391 15.8975 23.093 16.0304 22.9391 16.3593C20.6372 20.3752 19.3009 22.719 18.9161 23.3907C18.8462 23.5166 18.7132 23.5936 18.5663 23.5936Z"
          fill="#206EEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_23_1407">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});

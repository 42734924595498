import { LoanVariationPurposeEnum } from '@prisma/client';

export enum LoanVariationOptionsEnum {
  CONTRACTUAL_PAYMENT_DATE = 'CONTRACTUAL_PAYMENT_DATE',
  REPAYMENT_HOLIDAY = 'REPAYMENT_HOLIDAY',
}

export const loanVariationOptions = [
  { label: 'Select an option', value: '' },
  {
    label: 'Change contractual payment date',
    value: LoanVariationOptionsEnum.CONTRACTUAL_PAYMENT_DATE,
  },
  {
    label: 'Repayment holiday',
    value: LoanVariationOptionsEnum.REPAYMENT_HOLIDAY,
  },
];

export const loanVariationPurposeOptions = [
  { label: 'Select an option', value: '' },
  { label: 'Hardship', value: LoanVariationPurposeEnum.HARDSHIP },
  { label: 'Collections', value: LoanVariationPurposeEnum.COLLECTIONS },
  { label: 'Customer service', value: LoanVariationPurposeEnum.CUSTOMER_SERVICE },
  { label: 'Other', value: LoanVariationPurposeEnum.OTHER },
];

import { Badge } from '@harmoney/ui-design-system';

type Props = {
  tags: string[];
  label?: string;
};

export const TagDisplay = ({ tags, label }: Props) => (
  <>
    {label && <span>{label}</span>}
    <span className="flex flex-row flex-wrap gap-2">
      {tags?.map((tag) => (
        <Badge variant="secondary" label={tag} key={tag} />
      ))}
    </span>
  </>
);

import { useLocalStorage } from '@mantine/hooks';

export enum FinancialConfirmationSectionEnum {
  BankAccounts = 'BankAccounts',
  LivingSituation = 'LivingSituation',
  EssentialExpenses = 'EssentialExpenses',
  Income = 'Income',
  Assets = 'Assets',
  Debts = 'Debts',
  IncomeChange = 'IncomeChange',
  ExpenseChange = 'ExpenseChange',
}

export const FINANCIAL_CONFIRMATION_SECTION_LIST = [
  FinancialConfirmationSectionEnum.BankAccounts,
  FinancialConfirmationSectionEnum.LivingSituation,
  FinancialConfirmationSectionEnum.EssentialExpenses,
  FinancialConfirmationSectionEnum.Income,
  FinancialConfirmationSectionEnum.Assets,
  FinancialConfirmationSectionEnum.Debts,
  FinancialConfirmationSectionEnum.IncomeChange,
  FinancialConfirmationSectionEnum.ExpenseChange,
];

const defaultValue = [FinancialConfirmationSectionEnum.BankAccounts];

const PARTNER_INCOME_DEFAULT = {
  partnerIncomeAmount: null,
  partnerIncomeFrequency: null,
  hasPartnerIncome: '',
  partnerIncomePayType: '',
};

export const useFinancialConfirm = () => {
  const [confirmedSections, setConfirmedSections] = useLocalStorage({
    key: 'financial-confirmation',
    defaultValue: defaultValue,
  });
  const [localPartnerIncome, setLocalPartnerIncome] = useLocalStorage({
    key: 'partner-income',
    defaultValue: PARTNER_INCOME_DEFAULT,
  });
  const [timestampTriggeringScrollIntoView, setTimestampTriggeringScrollIntoView] = useLocalStorage({
    key: 'timestamp-triggering-scroll-into-view',
    defaultValue: null,
  });
  const confirmSection = (section: FinancialConfirmationSectionEnum) => {
    setTimestampTriggeringScrollIntoView(Date.now());
    if (!confirmedSections.includes(section)) setConfirmedSections([...confirmedSections, section]);
  };
  const resetFinancialConfirmation = () => {
    setConfirmedSections(defaultValue);
  };
  const resetLocalPartnerIncome = () => {
    setLocalPartnerIncome(PARTNER_INCOME_DEFAULT);
  };
  return {
    confirmedSections,
    confirmSection,
    resetFinancialConfirmation,
    localPartnerIncome,
    setLocalPartnerIncome,
    resetLocalPartnerIncome,
    timestampTriggeringScrollIntoView,
  };
};

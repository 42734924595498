import { OriginationTypeEnum } from '@prisma/client';

export enum OriginationType {
  UNUTILISED_LIMIT = 'unutilised-limit',
  REPEAT = 'repeat',
}

export const OriginationTypeMap = {
  null: null,
  [OriginationTypeEnum.UNUTILISED_LIMIT]: OriginationType.UNUTILISED_LIMIT,
  [OriginationTypeEnum.REPEAT]: OriginationType.REPEAT,
};

export const opportunityMapper: Record<OriginationTypeEnum & null, string> = {
  null: 'New loan',
  [OriginationTypeEnum.UNUTILISED_LIMIT]: 'Unutilised limit',
  [OriginationTypeEnum.REPEAT]: 'Repeat loan',
};

import { Button, getIconComponent } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';
import { kebabCase } from 'lodash';

interface PurposeCardProps {
  purpose: {
    name: string;
    displayName: string;
    amount: number;
  };
  onEdit: () => void;
}

export const PurposeCard = ({ purpose, onEdit }: PurposeCardProps) => (
  <div className="flex justify-between">
    <div className="flex">
      <span className="mr-2">{getIconComponent(kebabCase(purpose.name), 'grey-4')}</span>
      <span>{purpose.displayName}</span>
    </div>
    <div className="flex flex-col items-end">
      <span>{formatCurrency(purpose.amount)}</span>
      <Button variant="text" className="mt-2" onClick={onEdit}>
        Edit
      </Button>
    </div>
  </div>
);
